import { Canceler } from 'axios';
import React, { FC, useContext, useEffect, useRef } from 'react';
import { CancelToken } from '@rexlabs/api-client';
import { withModel } from '@rexlabs/model-generator';
import { ReportSettingsModel } from 'types/models/report-settings';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { compose } from 'utils/compose';
import reportSettingsModel from 'src/features/reports/models/report-settings';
import withError from 'view/containers/with-error';
import { ReportSettingsContext } from './report-settings-provider';
import { Auth0Context } from 'src/auth0-provider';

interface CheckReportPeriodLimitProps {
  reportSettingsModel: ReportSettingsModel;
  errorModal: WithErrorModalProps;
}

const CheckReportPeriodLimit: FC<CheckReportPeriodLimitProps> = ({
  children,
  reportSettingsModel,
  errorModal: { open: openError }
}) => {
  const canceler = useRef<Canceler | null>(null);
  const { permissions } = useContext(Auth0Context);
  const { selectedProjectsInSubRegion, period, setIsOutOfBounds } = useContext(
    ReportSettingsContext
  );

  useEffect(() => {
    if (
      !selectedProjectsInSubRegion ||
      !permissions.includes('reports:ignore-subregion-published-date') ||
      !period
    ) {
      return;
    }

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    reportSettingsModel
      .checkReportPeriodLimit({
        queryParams: {
          sub_region_ids: Object.keys(selectedProjectsInSubRegion).map(
            (id) => +id
          ),
          period
        },
        config: { cancelToken }
      })
      .then((res) => {
        if (!res.data) return;
        setIsOutOfBounds(res.data.out_of_bounds);
      })
      .catch(openError);

    return () => {
      canceler.current?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, selectedProjectsInSubRegion, permissions]);

  return <>{children}</>;
};

export default compose(
  withModel(reportSettingsModel),
  withError.withPropName('errorModal')
)(CheckReportPeriodLimit);
