import React, { SVGProps } from 'react';
import { COLORS } from '../../theme';

const ArrowDown = (props: SVGProps<null>) => {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 0.999924L7.5 6.99992L13.5 0.999924"
        stroke={props.stroke || COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDown;
