import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import Divider from 'view/components/divider';
import YearToDate from './year-to-date';
import SalesOverTime from './sales-over-time';
import CorridorMonthlyFigures from './corridor-monthly-figures';
import MarketShare from './market-share';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  },
  reportRow: {
    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`,
    padding: `${PADDINGS.XS} 0`
  }
});

@styled(defaultStyles)
class DetailedReport extends PureComponent {
  render() {
    const {
      projects,
      primaryProject,
      salesOverTime,
      monthByMonth,
      salesPerformance,
      yearToDate
    } = this.props;

    return (
      <Fragment>
        {/* Sales Over Time Stats */}
        <SalesOverTime
          primary={primaryProject}
          projects={projects}
          salesOverTime={salesOverTime}
        />

        <CorridorMonthlyFigures monthByMonth={monthByMonth} />

        <Divider />

        <MarketShare salesPerformance={salesPerformance} />

        {/* Date Stats */}
        <Box flex={1} flexDirection={'row'} flexWrap={'wrap'}>
          <YearToDate
            projects={projects}
            primaryProject={primaryProject}
            yearToDate={yearToDate}
            period="calendar"
          />

          <YearToDate
            projects={projects}
            primaryProject={primaryProject}
            yearToDate={yearToDate}
            period="financial"
          />
        </Box>
      </Fragment>
    );
  }
}

export default DetailedReport;
