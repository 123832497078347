import React, { Component, Fragment } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import { Heading, SubHeading, Body } from 'view/components/text';
import periodsModel from 'data/models/entities/periods';
import projectsModel from 'data/models/entities/projects';
import statsModel from 'data/models/entities/stats';
import { formatNumber, formatPeriod } from 'utils/format';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import Table from 'view/components/table';
import LoadingLayout from 'view/layouts/loading';
import StageLabel from '../../components/stage-label';
import uuid from 'uuid';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import ReportTitle from 'view/components/report-title';
import ReportFooter from 'view/components/report-footer';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from '../../components/arrow';
import withReportSidebar from 'view/containers/with-report-sidebar';
import { toQuri } from 'src/utils/query';

function getPrimaryProjectId(props) {
  return _.get(props, 'match.query.primaryProject');
}

function getPeriod(props) {
  return _.get(props, 'match.query.period');
}

function getProjectIds(props) {
  return _.get(props, 'match.query.projects');
}

function getPeriodQuery(props) {
  return toQuri([
    {
      field: 'end_date',
      op: '==',
      value: getPeriod(props)
    }
  ]);
}

const periodsQuery = query`{
  ${periodsModel} (q: ${getPeriodQuery}) {
    id
    end_date
  }
}`;

const projectsQuery = query`{
  ${projectsModel} (ids: ${getProjectIds}) {
    id
    title
  }
}`;

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  },
  listContainer: {
    maxHeight: '80rem',
    overflowY: 'auto',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  }
});

@withReportSidebar(false)
@withQuery(periodsQuery)
@withQuery(projectsQuery)
@withModel(statsModel)
@styled(defaultStyles)
class PriceListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingPriceList: true,

      currentProject: getPrimaryProjectId(props),

      priceList: {}
    };

    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    const { stats, whereabouts } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (
      !_.get(query, 'period') ||
      !_.get(query, 'projects') ||
      !_.get(query, 'primaryProject')
    ) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    stats
      .fetchPriceList({
        args: {
          projects: getProjectIds(this.props),
          projectId: getPrimaryProjectId(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) =>
        this.setState({
          fetchingPriceList: false,
          priceList: res.data
        })
      )
      .catch(console.error);
  }

  formatCharacteristics = (listing) => {
    const list = [
      listing.is_corner ? 'CNR' : null,
      listing.is_park_wetlands ? 'PRK' : null,
      listing.is_substation ? 'SUB' : null,
      listing.is_powerlines ? 'PWL' : null,
      listing.is_irregular ? 'IRR' : null,
      listing.is_nearby_amenity ? 'AME' : null,
      listing.is_fall ? 'FAL' : null,
      listing.is_fill ? 'FIL' : null,
      listing.is_double_storey ? 'DST' : null
    ];

    const filteredList = list.filter((x) => x);

    return _.isEmpty(filteredList) ? '' : filteredList.join('/');
  };

  loading() {
    const { projects, periods } = this.props;
    const { fetchingPriceList } = this.state;

    const arr = [
      fetchingPriceList,
      _.get(projects, 'list.status') !== 'loaded',
      _.get(periods, 'list.status') !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const { styles: s, periods, projects } = this.props;
    const { priceList, currentProject } = this.state;

    const loading = this.loading();
    const maxRequests = 6;
    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const currentPriceList = _.get(priceList, currentProject);
    const project = _.find(_.get(projects, 'list.items'), {
      id: parseInt(currentProject)
    });
    const primaryProject = _.find(_.get(projects, 'list.items'), {
      id: parseInt(getPrimaryProjectId(this.props))
    });

    const period = _.head(_.get(periods, 'list.items'));

    const stages = _.get(currentPriceList, 'stages');
    const stageList = _.chunk(stages, _.ceil(stages.length / 2));

    const soldListings = _.get(currentPriceList, 'sold');
    const availableListings = _.get(currentPriceList, 'available');
    const depositListings = _.get(currentPriceList, 'deposit');

    const listingColumns = [
      {
        Header: () => <p style={{ color: COLORS.BLUE_GREEN }}>Allotment</p>,
        width: 150,
        accessor: 'lot_number',
        fixed: 'left',
        style: {
          background: COLORS.BLUE.LIGHT,
          boxShadow: '4px 0px 3px -3px #ccc',
          border: 'none !important',
          fontWeight: '500'
        }
      },
      {
        Header: 'Frontage',
        accessor: 'frontage',
        width: 150
      },
      {
        Header: 'Depth',
        accessor: 'depth',
        width: 150
      },
      {
        Header: 'Size',
        accessor: 'lot_size',
        width: 150
      },
      {
        Header: 'Price',
        width: 150,
        Cell: (price) => `$${formatNumber(price.original.price)}`
      },
      {
        Header: '$/m2',
        width: 150,
        Cell: (price) => `$${formatNumber(price.original.price_per_m2)}`
      },
      {
        Header: 'Incentives/Rebate',
        width: 150,
        Cell: (row) => `$${formatNumber(_.get(row, 'original.rebate', 0) || 0)}`
      },
      {
        Header: 'Negotiation Buffer',
        width: 150,
        Cell: (row) =>
          `$${formatNumber(_.get(row, 'original.negotiation_buffer', 0) || 0)}`
      },
      {
        Header: 'Titled',
        width: 150,
        Cell: (row) => (row.original.titled ? 'Titled' : '')
      },
      {
        Header: 'Characteristics',
        width: 150,
        Cell: (row) => this.formatCharacteristics(row.original)
      },
      {
        Header: 'Price Change',
        width: 150,
        Cell: (row) =>
          `$${formatNumber(_.get(row, 'original.price_change', 0))}`
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 150
      }
    ];

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle period={period} project={primaryProject} />
        </Portal>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <Box flex={1} justifyContent={'space-between'}>
              <Heading>
                {_.get(project, 'title')} Pricelist -{' '}
                {formatPeriod(_.get(period, 'end_date'), true)}
              </Heading>
              <Box>
                <ActionMenu
                  Button={(button) => (
                    <Body
                      onClick={() => this.setState({ actionMenu: true })}
                      style={{
                        cursor: 'pointer',
                        color: COLORS.GREY.DARK,
                        userSelect: 'none'
                      }}
                    >
                      Change Estate &nbsp;
                      {button.isOpen ? <UpArrow /> : <DownArrow />}
                    </Body>
                  )}
                  items={_.map(_.get(projects, 'list.items'), (p) => {
                    return {
                      label: p.title,
                      onClick: () => this.setState({ currentProject: p.id })
                    };
                  })}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <SubHeading>Expected Title Release</SubHeading>
          </Box>

          <Box p={PADDINGS.S} pt={PADDINGS.TINY}>
            <Box flex={1} flexDirection="row" flexWrap={'wrap'}>
              {stageList.map((stages) => {
                return (
                  <Box flex={1} flexDirection={'column'} key={uuid()}>
                    {stages.map((stage) => {
                      return (
                        <StageLabel
                          stage={stage.stage_no}
                          title={stage.title || '-'}
                          key={uuid()}
                        />
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <SubHeading>Sold Allotments</SubHeading>
          </Box>

          <Box {...s('listContainer')}>
            <Table
              data={soldListings}
              columns={listingColumns}
              pageSize={soldListings.length || 5}
            />
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <SubHeading>Deposited Allotments</SubHeading>
          </Box>

          <Box {...s('listContainer')}>
            <Table
              data={depositListings}
              columns={listingColumns}
              pageSize={depositListings.length || 5}
            />
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <SubHeading>Remaining Allotments</SubHeading>
          </Box>

          <Box {...s('listContainer')}>
            <Table
              data={availableListings}
              columns={listingColumns}
              pageSize={availableListings.length || 5}
            />
          </Box>
        </Box>
        <ReportFooter
          prevText="Estate Detailed Stats"
          prevRoute={ROUTES.APP.REPORT_DETAILED}
          nextText="Definitions Appendix"
          nextRoute={ROUTES.APP.REPORT_DEFINITIONS}
        />
      </Fragment>
    );
  }
}

export default PriceListScreen;
