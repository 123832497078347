import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Body } from 'view/components/text';
import { COLORS, PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    borderBottom: '1px solid grey',
    cursor: 'pointer',
    '& h1': {
      color: 'green'
    }
  },
  heading: {
    color: COLORS.BLUE_GREEN,
    userSelect: 'none',
    padding: `${PADDINGS.XXS} 0`,
    fontSize: '2.2rem'
  }
});

@styled(defaultStyles)
class CollapsibleHeader extends PureComponent {
  render() {
    const { styles: s, onClick, children } = this.props;
    return (
      <div {...s('container')} onClick={onClick}>
        <Body {...s('heading')}>{children}</Body>
      </div>
    );
  }
}

export default CollapsibleHeader;
