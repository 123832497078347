// Convert hex -> rgb
export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

// Convert rgb -> hex
export const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const componentToHex = (c) => {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

// Get a color from a gradient
export const getColorFromValue = (current, min, max) => {
  // Gradient point 1 - 2
  const lowerColors = [
    [206, 78, 79],
    [242, 139, 101]
  ];

  // Gradient point 2 - 3
  const middleColors = [
    [242, 139, 101],
    [251, 200, 61]
  ];

  // Gradient point 3 - 4
  const upperColors = [
    [251, 200, 61],
    [131, 200, 120]
  ];

  // Calculate the offset
  const maxDiff = max - min;
  const currDiff = max - current;
  const offset = 1 - (maxDiff === 0 ? 0.5 : (maxDiff - currDiff) / maxDiff);

  if (offset < 1 / 3) {
    const weight = 1 - offset / (1 / 3);
    return getGradientPoint(lowerColors[0], lowerColors[1], weight);
  } else if (offset >= 1 / 3 && offset < 2 / 3) {
    const weight = 1 - (offset - 1 / 3) / (1 / 3);
    return getGradientPoint(middleColors[0], middleColors[1], weight);
  } else {
    const weight = 1 - (offset - 2 / 3) / (1 / 3);
    return getGradientPoint(upperColors[0], upperColors[1], weight);
  }
};

// Calculate a point between 2 colors in a gradient
export const getGradientPoint = (color1, color2, weight) => {
  const w1 = weight;
  const w2 = 1 - w1;
  return [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)
  ];
};

export const hexFromArray = (array) => {
  return rgbToHex(array[0], array[1], array[2]);
};
