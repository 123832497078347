import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS } from 'src/theme';
import Box from '@rexlabs/box';
import VividCheckbox from '@rexlabs/checkbox';
import { Tiny } from 'view/components/text';
import * as PropTypes from 'prop-types';

const defaultStyles = StyleSheet({
  checkbox: {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    alignItems: 'center',
    padding: `${PADDINGS.XXS} 0`,
    color: COLORS.GREY.DARK,
    '> p': {
      margin: PADDINGS.XXS
    }
  }
});

@styled(defaultStyles)
class Checkbox extends PureComponent {
  static defaultProps = {
    value: false,
    label: ''
  };

  render() {
    const { styles: s, value, label } = this.props;
    return (
      <Box flex={1} {...s('checkbox')}>
        <VividCheckbox
          value={value}
          styles={StyleSheet({
            input: {
              '& + label': {
                borderRadius: '25%'
              }
            }
          })}
        />
        <Tiny>{label}</Tiny>
      </Box>
    );
  }
}

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

export default Checkbox;
