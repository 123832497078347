import React, { Fragment, useMemo } from 'react';
import Box from '@rexlabs/box';
import { maxBy, minBy } from 'lodash';

import { HeatMapItem } from '../heat-map';

import { MarketShareDatum } from 'types/graph';
import Table, { DataColumn } from '../table';
import { HeatMapItemProps } from '../heat-map/heat-map-item';
import { StyleSheet } from '@rexlabs/styling';
import { Project } from '../selector/project-selector';
import { COLORS, FONT } from 'src/features/reports/theme';
import { getShortMonthWithYear } from 'utils/format';

const styles = StyleSheet({
  heatMap: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 800,
    fontSize: 12,
    color: COLORS.BLUE.DARK
  }
});

interface MarketShareTableProps {
  reports: MarketShareDatum[][];
  projects: Array<Project[]>;
  reportInterval: number;
  period: string;
}

interface MarketShareColumn extends MarketShareDatum {
  available_last_month_heat: Pick<
    HeatMapItemProps,
    'lowestHeat' | 'highestHeat'
  >;
  sold_last_n_month_heat: Pick<HeatMapItemProps, 'lowestHeat' | 'highestHeat'>;
}

const getColumns = (
  slideRangeMonth: number,
  period: string
): DataColumn<MarketShareColumn>[] => {
  const soldMonth =
    slideRangeMonth === 0
      ? getShortMonthWithYear(period)
      : `LAST ${slideRangeMonth + 1} MONTHS`;

  return [
    {
      key: 'group',
      title: 'DEPTH',
      sortable: false
    },
    {
      key: 'available_count',
      title: `AVAILABLE (${getShortMonthWithYear(period)})`,
      render: (row: MarketShareColumn) => {
        return row.available_count > 0 ? (
          <HeatMapItem
            {...row.available_last_month_heat}
            className={styles.heatMap}
            heatValue={row.available_count}
          >
            <Box alignItems="center" padding={8}>
              <p>{`${row.available_percent}% (${row.available_count})`}</p>
            </Box>
          </HeatMapItem>
        ) : (
          '-'
        );
      },
      sortable: false
    },
    {
      key: 'sold_count',
      title: `SOLD (${soldMonth})`,
      render: (row: MarketShareColumn) => {
        return row.sold_count > 0 ? (
          <HeatMapItem
            {...row.sold_last_n_month_heat}
            heatValue={row.sold_count}
            className={styles.heatMap}
          >
            <Box alignItems="center" padding={8}>
              <p>{`${row.sold_percent}% (${row.sold_count})`}</p>
            </Box>
          </HeatMapItem>
        ) : (
          '-'
        );
      },
      sortable: false
    }
  ];
};

const MarketShareTable = ({
  reports,
  projects,
  reportInterval,
  period
}: MarketShareTableProps) => {
  const formattedReport = useMemo(() => {
    return reports.map((report) => {
      // 0 === '-' so no need to include
      const filteredAvailable = report.filter(
        (datum) => datum.available_count !== 0
      );
      const availableLowestHeat =
        minBy(filteredAvailable, 'available_count')?.available_count ?? 0;
      const availableHighestHeat =
        maxBy(filteredAvailable, 'available_count')?.available_count ?? 0;
      // 0 === '-' so no need to include
      const filteredSold = report.filter((datum) => datum.sold_percent !== 0);
      const soldLowestHeat = minBy(filteredSold, 'sold_count')?.sold_count ?? 0;
      const soldHighestHeat =
        maxBy(filteredSold, 'sold_count')?.sold_count ?? 0;

      return report.map(
        (marketShareProps) =>
          ({
            ...marketShareProps,
            available_last_month_heat: {
              lowestHeat: availableLowestHeat,
              highestHeat: availableHighestHeat
            },
            sold_last_n_month_heat: {
              lowestHeat: soldLowestHeat,
              highestHeat: soldHighestHeat
            }
          } as MarketShareColumn)
      );
    });
  }, [reports]);

  return (
    <Box flexDirection="row">
      {formattedReport.length > 0 && (
        <Fragment>
          <Table
            key="left-table"
            title={
              projects[0][0].key === 'all_projects'
                ? 'ALL PROJECTS'
                : projects[0][0].label.toUpperCase()
            }
            titleStyle={{ paddingLeft: 'calc((100% / 3) + 8px)' }}
            data={formattedReport[0]}
            columns={getColumns(reportInterval, period)}
            disableLazy
            tableStyle={{ width: '60%' }}
          />
          <Table
            key="right-table"
            title={
              projects[1][0].key === 'no_projects'
                ? 'SELECT A PROJECT BELOW'
                : projects[1][0].label.toUpperCase()
            }
            data={formattedReport[1]}
            columns={getColumns(reportInterval, period).slice(1)}
            disableLazy
            tableStyle={{ borderLeft: 0, width: '40%' }}
          />
        </Fragment>
      )}
    </Box>
  );
};

export default MarketShareTable;
