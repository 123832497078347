import React from 'react';
import { withModel } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'routes';

const withPermission =
  (requiredPermission?: string, guestable?: boolean) => (Component) => {
    class WithPermissions extends React.PureComponent<{ session: any }> {
      componentDidMount(): void {
        const permissions: string[] = this.props.session.permissions;
        if (
          requiredPermission &&
          !guestable &&
          !permissions?.includes(requiredPermission)
        ) {
          push(ROUTES.APP.HOME, {});
        }
      }

      can = (permission: string) =>
        this.props.session.permissions?.includes(permission);

      render() {
        return <Component {...this.props} can={this.can} />;
      }
    }
    return withModel(sessionModel)(WithPermissions);
  };

export default withPermission;
