import React from 'react';
import _ from 'lodash';

import {
  RenderMatchedRoutes,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import { push } from '@rexlabs/whereabouts';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { TextProvider } from '@rexlabs/text';
import { LayoutProvider } from '@rexlabs/box';
import { PortalTarget } from '@rexlabs/portal';

import ModalTarget from 'view/components/portal/modal';
import { Snackbar } from '../components/snackbar';

import { SideBar } from 'src/features/reports/hoc';
import withPermission from 'view/containers/with-permissions';
import withError from 'view/containers/with-error';

import sessionModel from 'data/models/custom/session';
import ROUTES from 'src/features/reports/routes';
import COMPONENTS_THEME from 'src/features/reports/theme/components';
import { COLORS, PADDINGS, TEXTS, LAYOUTS } from 'src/features/reports/theme';
import {
  ReportSettingsProvider,
  SnackbarProvider,
  CheckReportPeriodLimit
} from '../providers';
import { LoadReport } from '../hoc';

const defaultStyles = StyleSheet({
  container: {
    display: 'initial',
    overflow: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: COLORS.BACKGROUND
  },

  wrapSidebar: {
    position: 'absolute',
    // left: 0,
    top: 200,
    bottom: 0,
    width: '25rem',
    background: COLORS.WHITE,
    padding: PADDINGS.S
  },

  main: {
    paddingLeft: '25rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  header: {
    display: 'flex',
    height: '20rem',
    background: COLORS.PRIMARY_DARK,
    width: '100%',
    alignItems: 'center'
  },

  footer: {
    height: '25rem',
    background: COLORS.BLUE_GREEN,
    width: '100%',
    alignItems: 'center'
  },

  wrapContent: {
    overflow: 'auto',
    justifyContent: 'center',
    position: 'relative',
    padding: `0 ${PADDINGS.S}`
  },

  content: {
    width: '100%'
  },

  created: {
    marginTop: PADDINGS.S,
    paddingTop: PADDINGS.XXS,
    borderTop: `1px solid ${COLORS.GREY.MEDIUM}`,
    color: COLORS.GREY.MEDIUM
  },

  wrapToggle: {
    cursor: 'pointer',
    margin: `0 ${PADDINGS.S}`,
    height: 40,
    width: 40,
    ':hover': {
      opacity: '0.6'
    }
  },

  link: {
    lineHeight: 0
  }
});

const ReportLayoutWrapped = () => {
  const isDevelopmentMode = process.env.NODE_ENV === 'development';

  return (
    <StylesProvider components={COMPONENTS_THEME} debug={isDevelopmentMode}>
      <TextProvider text={TEXTS}>
        <LayoutProvider layout={LAYOUTS}>
          <SnackbarProvider>
            <ReportSettingsProvider>
              <CheckReportPeriodLimit>
                <LoadReport>
                  <PortalTarget name="custom-dialogs" />
                  <ModalTarget />
                  <Snackbar />
                  <SideBar>
                    <RenderMatchedRoutes
                      routes={ROUTES.APP}
                      NotFound={() => {
                        push(ROUTES.APP.REPORT_OVERVIEW, {});
                        return null;
                      }}
                    />
                  </SideBar>
                </LoadReport>
              </CheckReportPeriodLimit>
            </ReportSettingsProvider>
          </SnackbarProvider>
        </LayoutProvider>
      </TextProvider>
    </StylesProvider>
  );
};

export default _.flow([
  styled(defaultStyles),
  withModel(sessionModel),
  withWhereabouts,
  withError.withPropName('errorModal'),
  withPermission('view:reports', true)
])(ReportLayoutWrapped);
