/* eslint-disable max-lines */
import React, { useState, Fragment, CSSProperties } from 'react';
import Box from '@rexlabs/box';
import { Column, Grid } from '@rexlabs/grid';
import SectionColumn from 'view/components/grid/section-column';
import { COLORS, PADDINGS } from 'src/theme';
import { Body, Heading, SubHeading, Tiny } from 'view/components/text';
import ActionMenu from '@rexlabs/action-menu';
import { properCase, formatNumber } from 'utils/format';
import { DownArrow, UpArrow } from 'view/components/arrow';
import ReportBox from 'view/components/summary/report-box';
import TextButton from 'view/components/button/text';
import { styled, StyleSheet } from '@rexlabs/styling';
import { compose } from 'utils/compose';
import UntiteldDot from 'assets/untitled-dot.svg';
import TiteldDot from 'assets/titled-dot.svg';
import MarketIndicator from 'view/components/market-indicator';
import { Pie } from 'view/components/charts';
import _ from 'lodash';
import GA from 'react-ga';
import SectionContainer from 'view/components/section-container';

type OverallCorridorProps = {
  overviewStats: OverviewStats;
};

type DecoratorProps = {
  styles: (...args: string[]) => any;
};

export interface OverviewStats {
  overview: Overview;
  current: OverviewPeriodStats;
  quarterly: OverviewPeriodStats;
  yearly: OverviewPeriodStats;
}
export interface Overview {
  untitled_listings: number;
  titled_listings: number;
  outstanding_contracts: number;
  contract_fallovers: number;
  ave_size_sold: number;
  ave_size_available: number;
  med_size_sold: number;
  med_size_available: number;
  months_supply: number;
}
export interface OverviewPeriodStats {
  gross_sales: number;
  net_sales: number;
  average_sale_price: number;
  average_available_price: number;
  median_sale_price: number;
  median_available_price: number;
  gross_sales_month: number;
  net_sales_month: number;
}

const defaultStyles: Record<string, CSSProperties> = {
  row: {
    padding: `${PADDINGS.XS} 0`
  },
  divider: {
    paddingTop: PADDINGS.S,
    marginBottom: PADDINGS.S,
    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`
  }
};

const OverallCorridor: React.FC<OverallCorridorProps & DecoratorProps> = ({
  overviewStats,
  styles: s
}) => {
  // Set State
  const currentSalesPeriod = 'current';
  const [currentEstatePeriod, setCurrentEstatePeriod] = useState('current');
  const [priceMedian, setPriceMedian] = useState(false);
  const [sizeMedian, setSizeMedian] = useState(false);

  const currentSalesPeriodStats: OverviewPeriodStats =
    overviewStats[currentSalesPeriod];
  const currentEstatePeriodStats: OverviewPeriodStats =
    overviewStats[currentEstatePeriod];

  return (
    <>
      <Grid gutter={0} columns={2}>
        <Column width={1}>
          <Box flex={1} flexDirection={'column'}>
            <Grid columns={1}>
              <SectionColumn width={1}>
                <Box p={PADDINGS.S}>
                  <Box
                    flex={1}
                    mb={PADDINGS.S}
                    justifyContent={'space-between'}
                  >
                    <SubHeading>Overall Corridor</SubHeading>
                  </Box>
                  <Grid columns={3}>
                    <Column width={1}>
                      <ReportBox title={'Gross sales'}>
                        <Heading>
                          {_.get(currentSalesPeriodStats, 'gross_sales', 0) ||
                            0}
                        </Heading>
                      </ReportBox>
                    </Column>
                    <Column width={2}>
                      <ReportBox title={'Net sales'}>
                        <Heading>
                          {_.get(currentSalesPeriodStats, 'net_sales', 0) || 0}
                        </Heading>
                      </ReportBox>
                    </Column>
                  </Grid>
                </Box>
              </SectionColumn>
            </Grid>
            <SectionContainer m={PADDINGS.XXS} height={'100%'}>
              <Box
                p={PADDINGS.S}
                height={'100%'}
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box flex={1} mb={PADDINGS.S} justifyContent={'space-between'}>
                  <SubHeading>Ave. per estate</SubHeading>
                  <Box>
                    <ActionMenu
                      Button={(button) => (
                        <Body
                          style={{
                            cursor: 'pointer',
                            color: COLORS.GREY.DARK,
                            userSelect: 'none'
                          }}
                        >
                          {properCase(currentEstatePeriod)}
                          &nbsp;
                          {button.isOpen ? <UpArrow /> : <DownArrow />}
                        </Body>
                      )}
                      items={_.map(
                        ['Current', 'Quarterly', 'Yearly'],
                        (value) => ({
                          label:
                            value.toLowerCase() === currentEstatePeriod ? (
                              <span style={{ color: COLORS.BLUE_GREEN }}>
                                {value}
                              </span>
                            ) : (
                              value
                            ),
                          onClick: () => {
                            GA.event({
                              category: 'Overview',
                              action: 'Toggle Average Per Estate Period',
                              label: value.toLowerCase()
                            });
                            setCurrentEstatePeriod(value.toLowerCase());
                          }
                        })
                      )}
                    />
                  </Box>
                </Box>
                <Grid columns={3}>
                  <Column width={1}>
                    <ReportBox title={'Gross sales per month'}>
                      <Heading>
                        {_.get(currentEstatePeriodStats, 'gross_sales_month')}
                      </Heading>
                    </ReportBox>
                  </Column>
                  <Column width={2}>
                    <ReportBox title={'Net sales per month'}>
                      <Heading>
                        {_.get(currentEstatePeriodStats, 'net_sales_month')}
                      </Heading>
                    </ReportBox>
                  </Column>
                </Grid>
                <Box {...s('divider')} />
                {priceMedian ? (
                  <Grid columns={2}>
                    <Column width={1}>
                      <ReportBox title={'Med. price sold'}>
                        <Heading>
                          <Fragment>
                            $
                            {formatNumber(
                              _.get(
                                currentEstatePeriodStats,
                                'median_sale_price'
                              )
                            )}
                          </Fragment>
                        </Heading>
                      </ReportBox>
                    </Column>
                    <Column width={1}>
                      <ReportBox title={'Med. price available'}>
                        <Heading>
                          {currentEstatePeriod === 'current' ? (
                            <Fragment>
                              $
                              {formatNumber(
                                _.get(
                                  currentEstatePeriodStats,
                                  'median_available_price'
                                )
                              )}
                            </Fragment>
                          ) : (
                            '-'
                          )}
                        </Heading>
                      </ReportBox>
                    </Column>
                  </Grid>
                ) : (
                  <Grid columns={2}>
                    <Column width={1}>
                      <ReportBox title={'Ave. price sold'}>
                        <Heading>
                          <Fragment>
                            $
                            {formatNumber(
                              _.get(
                                currentEstatePeriodStats,
                                'average_sale_price'
                              )
                            )}
                          </Fragment>
                        </Heading>
                      </ReportBox>
                    </Column>
                    <Column width={1}>
                      <ReportBox title={'Ave. price available'}>
                        <Heading>
                          {currentEstatePeriod === 'current' ? (
                            <Fragment>
                              $
                              {formatNumber(
                                _.get(
                                  currentEstatePeriodStats,
                                  'average_available_price'
                                )
                              )}
                            </Fragment>
                          ) : (
                            '-'
                          )}
                        </Heading>
                      </ReportBox>
                    </Column>
                  </Grid>
                )}
                <Box
                  flex={1}
                  justifyContent={'space-between'}
                  alignItems={'baseline'}
                >
                  <Tiny>&nbsp;</Tiny>
                  <TextButton
                    onClick={(e) => {
                      e.preventDefault();
                      GA.event({
                        category: 'Overview',
                        action: 'Toggle Average Per Estate Average/Median',
                        label: priceMedian ? 'average' : 'median'
                      });
                      setPriceMedian(!priceMedian);
                    }}
                  >
                    Show {priceMedian ? 'Average' : 'Median'}
                  </TextButton>
                </Box>
              </Box>
            </SectionContainer>
          </Box>
        </Column>

        {/* CORRIDOR OVERVIEW */}
        <Column width={1}>
          <Grid columns={1}>
            <SectionColumn width={1}>
              <Box p={PADDINGS.S} flex={1} flexDirection={'column'}>
                <SubHeading>Corridor overview</SubHeading>

                <Body style={{ fontWeight: 'bold' }}>Lot availability</Body>
                <Grid {...s('row')} columns={3}>
                  <Column width={1}>
                    <ReportBox title={'Untitled Lots'}>
                      <Heading>
                        {formatNumber(
                          _.get(overviewStats, 'overview.untitled_listings', 0)
                        )}
                      </Heading>
                      <UntiteldDot style={{ margin: '1rem' }} />
                    </ReportBox>
                  </Column>
                  <Column width={1}>
                    <ReportBox title={'Titled Lots'}>
                      <Heading>
                        {formatNumber(
                          _.get(overviewStats, 'overview.titled_listings', 0)
                        )}
                      </Heading>
                      <TiteldDot style={{ margin: '1rem' }} />
                    </ReportBox>
                  </Column>
                  <Column width={1}>
                    <Pie
                      width={70}
                      height={70}
                      data={[
                        {
                          name: 'Untitled',
                          value: _.get(
                            overviewStats,
                            'overview.untitled_listings',
                            0
                          )
                        },
                        {
                          name: 'Titled',
                          value: _.get(
                            overviewStats,
                            'overview.titled_listings',
                            0
                          )
                        }
                      ]}
                      dataKey={'value'}
                      outerRadius={35}
                    />
                  </Column>
                </Grid>

                <Grid
                  {...s('row')}
                  gutter={0}
                  columns={2}
                  style={{
                    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`
                  }}
                >
                  <Column width={1}>
                    <ReportBox title={'Months Supply'}>
                      <Heading style={{ fontSize: '3rem' }}>
                        {_.get(
                          overviewStats,
                          'overview.months_supply',
                          0
                        ).toFixed(2)}
                      </Heading>
                    </ReportBox>
                  </Column>
                  <Column width={1}>
                    <Box flex={1} alignItems={'flex-end'}>
                      <MarketIndicator
                        value={_.get(
                          overviewStats,
                          'overview.months_supply',
                          0
                        )}
                      />
                    </Box>
                  </Column>
                </Grid>

                <Grid
                  {...s('row')}
                  gutter={0}
                  columns={2}
                  style={{
                    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`
                  }}
                >
                  <Column width={1}>
                    <ReportBox title={'Outstanding Contracts'}>
                      <Heading>
                        {_.get(
                          overviewStats,
                          'overview.outstanding_contracts',
                          0
                        )}
                      </Heading>
                    </ReportBox>
                  </Column>
                  <Column width={1}>
                    <ReportBox title={'Back on Market'}>
                      <Heading>
                        {_.get(overviewStats, 'overview.contract_fallovers', 0)}
                      </Heading>
                    </ReportBox>
                  </Column>
                </Grid>

                {sizeMedian ? (
                  <Grid
                    {...s('row')}
                    gutter={0}
                    columns={2}
                    style={{ paddingBottom: 0 }}
                  >
                    <Column width={1}>
                      <ReportBox title={'Med. Size Sold'}>
                        <Heading>
                          {formatNumber(
                            _.get(overviewStats, 'overview.med_size_sold', 0)
                          )}{' '}
                          m2
                        </Heading>
                      </ReportBox>
                    </Column>
                    <Column width={1}>
                      <ReportBox title={'Med. Size Available'}>
                        <Heading>
                          {formatNumber(
                            _.get(
                              overviewStats,
                              'overview.med_size_available',
                              0
                            )
                          )}{' '}
                          m2
                        </Heading>
                      </ReportBox>
                    </Column>
                  </Grid>
                ) : (
                  <Grid
                    {...s('row')}
                    gutter={0}
                    columns={2}
                    style={{ paddingBottom: 0 }}
                  >
                    <Column width={1}>
                      <ReportBox title={'Ave. Size Sold'}>
                        <Heading>
                          {formatNumber(
                            _.get(overviewStats, 'overview.ave_size_sold', 0)
                          )}{' '}
                          m2
                        </Heading>
                      </ReportBox>
                    </Column>
                    <Column width={1}>
                      <ReportBox title={'Ave. Size Available'}>
                        <Heading>
                          {formatNumber(
                            _.get(
                              overviewStats,
                              'overview.ave_size_available',
                              0
                            )
                          )}{' '}
                          m2
                        </Heading>
                      </ReportBox>
                    </Column>
                  </Grid>
                )}

                <Box
                  flex={1}
                  justifyContent={'space-between'}
                  alignItems={'baseline'}
                >
                  <Tiny>
                    Allotments without a specified price are ignored from price
                    and size averages
                  </Tiny>
                  <TextButton
                    onClick={(e) => {
                      e.preventDefault();
                      GA.event({
                        category: 'Overview',
                        action: 'Toggle Lot Availability Average/Median',
                        label: priceMedian ? 'average' : 'median'
                      });
                      setSizeMedian(!sizeMedian);
                    }}
                  >
                    Show {sizeMedian ? 'Average' : 'Median'}
                  </TextButton>
                </Box>
              </Box>
            </SectionColumn>
          </Grid>
        </Column>
      </Grid>
    </>
  );
};

const decorator = compose<unknown>(styled(StyleSheet(defaultStyles)));

export default decorator(OverallCorridor);
