/*
|-------------------------------------------------------------------------------
| App Styling Constants
|-------------------------------------------------------------------------------
|
| The theme of an app encompasses:
|  1. Design Rules
|  2. Style Utilities
|
| These are here so we can apply discipline to styling our app, and avoid
| spaghetti styling code.
|
*/

import { insertCss, reset } from '@rexlabs/styling';

export const COLORS = {
  PRIMARY_DARK: '#202322',
  BACKGROUND: '#edf2f6',
  WHITE: '#fff',

  BLACK: '#000',

  GREY: Object.assign('#aaa', {
    DARK: '#868481',
    MEDIUM_DARK: '#999999',
    MEDIUM: '#bebebe',
    LIGHT: '#dfdfdf',
    LIGHER: '#eef2f6'
  }),

  RED: Object.assign('#e30000', {
    LIGHT: '#ffcfd0',
    NEGATIVE: '#DD4053',
    DARK: '#CE4E4F'
  }),

  ORANGE: Object.assign('#F28B65', {
    REPORT: '#E0A729'
  }),

  YELLOW: '#FBC83D',

  PINK: '#f02a4e',

  GREEN: Object.assign('#184d00', {
    LIGHT: '#d8f1d5',
    POSITIVE: '#00A093'
  }),

  BLUE_GREEN: '#00a093',

  BLUE: {
    LIGHT: '#f4f8ff'
  },

  GRAPH: {
    BLUE: {},
    GREEN: {
      LIGHT: '#ACEEDF',
      DARK: '#00A093'
    },
    GREY: {
      LIGHT: '#BEBEBE',
      DARK: '#868481'
    }
  }
};

export const PADDINGS = {
  TINY: '.4rem',
  XXS: '.8rem',
  XS: '1.6rem',
  S: '2.4rem',
  M: '3.2rem',
  L: '4rem',
  XL: '4.8rem',
  XXL: '5.6rem'
};

export const MARGINS = {
  TINY: '.5rem',
  XXS: '1rem'
};

export const FONT = {
  WEIGHTS: {
    NORMAL: 400,
    SEMIBOLD: 500,
    BOLD: 800
  },

  FAMILIES: {
    DEFAULT: 'Avenir, "Open Sans", sans-serif'
  }
};

export const BORDER_RADIUS = {
  INPUT: '.4rem',
  DIALOGS: '.3rem',
  CONTAINER: '.3rem',
  STATUS_BOX: '.7rem'
};

export const EASINGS = {
  MODAL_FADE: 'cubic-bezier(0.175, 0.885, 0.32, 1.15)'
};

export const SHADOWS = {
  CONTAINER: '0 0.15rem 1rem rgba(0, 0, 0, .2)',
  SUMMARY: '0 .5rem 1.5rem rgba(138, 160, 174, .27)'
};

export const TEXTS = {
  RESET: {
    lineHeight: 'inherit'
  },

  BIGHEADING: {
    fontSize: '6.2rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    lineHeight: '6.2rem'
  },

  HEADING: {
    fontSize: '3.6rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },

  SUBHEADING: {
    fontSize: '2.8rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },

  BODY: {
    fontSize: '2rem'
  },

  SMALL: {
    fontSize: '1.6rem'
  },

  TINY: {
    fontSize: '1.4rem'
  },

  LABEL: {
    fontSize: '1.4rem',
    fontWeight: FONT.WEIGHTS.BOLD
  },

  GREY: {
    color: COLORS.GREY.DARK
  }
};

export const LAYOUTS = {};

export const UTILS = {
  TRUNCATE: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export function initTheme() {
  reset();
  insertCss(`
    html {
      min-width: 1024px;
      font-size: 62.5% !important;
      height: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
    }

    body {
      font-size: 1.6rem;
      color: ${COLORS.PRIMARY_DARK};
      font-weight: ${FONT.WEIGHTS.NORMAL};
      line-height: 1.4;
      font-family: ${FONT.FAMILIES.DEFAULT};
      background: ${COLORS.BACKGROUND};
    }

    *, *:before, *:after {
      box-sizing: border-box;
    }

    input, select, textarea, button {
      text: inherit;
    }
    
     a {
      text-decoration: none;
     }
  `);
}
