import React, { useContext, useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { compose } from 'lodash/fp';
import { COLORS, FONT } from 'src/features/reports/theme';
import { DefaultButton } from '../button';
import ReportFilter from '../report-filter/primary';
import { SaveReport } from '../modals';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';

const defaultStyles = StyleSheet({
  container: {
    height: 133
  },
  title: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    color: COLORS.GREY.MEDIUM,
    fontSize: 12,
    fontWeight: 500
  },
  reportName: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 700,
    fontSize: 28,
    lineHeight: 1.2
  },
  save: {
    width: 84
  }
});

interface ReportTitleProps {
  text?: string;
  styles: any;
  hideFilters?: boolean;
}

function ReportTitle({ styles: s, text, hideFilters }: ReportTitleProps) {
  const {
    selectedReport,
    selectedProjectsInSubRegion,
    standardSubRegions,
    premiumSubRegions
  } = useContext(ReportSettingsContext);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const reportName = useMemo(() => {
    if (selectedReport) return selectedReport.name;
    if (selectedProjectsInSubRegion) {
      const subRegionIds = Object.keys(selectedProjectsInSubRegion).map(
        (key) => key
      );
      if (subRegionIds.length === 1) {
        const selectedSubRegion = [
          ...standardSubRegions,
          ...premiumSubRegions
        ].find((subRegion) => subRegion.id === +subRegionIds[0]);

        // Sub region may be deleted
        if (!selectedSubRegion) return 'Custom Report';

        // If not all projects are selected
        if (
          selectedSubRegion.projects.length !==
          selectedProjectsInSubRegion[selectedSubRegion.id].length
        ) {
          return 'Custom Report';
        }

        return selectedSubRegion.name;
      }
    }
    return 'Custom Report';
  }, [
    selectedReport,
    selectedProjectsInSubRegion,
    standardSubRegions,
    premiumSubRegions
  ]);

  return (
    <Box alignItems="center" justifyContent="space-between" {...s('container')}>
      <Box flexDirection="row" alignItems="center">
        <Box flex={1} flexDirection="column" mr="24px">
          <p {...s('title')}>{text}</p>
          <h1 {...s('reportName')}>{reportName}</h1>
        </Box>
        {!hideFilters && (
          <DefaultButton {...s('save')} onClick={() => setShowSaveModal(true)}>
            {selectedReport ? 'Edit' : 'Save'}
          </DefaultButton>
        )}
      </Box>
      {!hideFilters && (
        <>
          <ReportFilter />
          {showSaveModal && (
            <SaveReport onHide={() => setShowSaveModal(false)} />
          )}
        </>
      )}
    </Box>
  );
}

export default compose(styled(defaultStyles))(ReportTitle);
