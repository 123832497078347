import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const InfoCircleOutline = (props: SVGProps<null>) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99895 5.07506C7.89162 5.07506 7.80451 5.16217 7.80529 5.2695C7.80529 5.37684 7.8924 5.46395 7.99973 5.46395C8.10706 5.46395 8.19418 5.37684 8.19418 5.2695C8.19418 5.16217 8.10706 5.07506 7.99895 5.07506"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15.1863V15.1863C4.13367 15.1863 1 12.0526 1 8.18628V8.18628C1 4.31995 4.13367 1.18628 8 1.18628V1.18628C11.8663 1.18628 15 4.31995 15 8.18628V8.18628C15 12.0526 11.8663 15.1863 8 15.1863Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99973 8.18628V12.0752"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoCircleOutline;
