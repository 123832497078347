import React, { useMemo, SVGProps, FC } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, Styles, styled } from '@rexlabs/styling';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { Tiny, Label } from 'view/components/text';
import { VividTooltipStateful } from 'src/features/reports/components/vivid';
import { COLORS } from 'src/features/reports/theme';

export interface FlagTooltipType {
  Icon: FC<SVGProps<unknown>>;
  title: string;
  description: string;
  extra?: React.ReactNode;
}

export interface FlagTooltipProps extends FlagTooltipType {
  styles: Styles;
}

const defaultStyles = StyleSheet({
  content: {
    width: 'auto',
    minWidth: 100,
    maxWidth: 500
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'DM Sans',
    color: COLORS.BLUE.DARK
  },
  icon: {
    fontSize: 13,
    marginRight: 13
  },
  cellIcon: {
    marginRight: 3,
    width: 16
  },
  description: {
    fontSize: 16,
    fontFamily: 'DM Sans',
    color: COLORS.BLUE.DARK
  },
  infoTooltip: {
    marginTop: 6
  },
  titleContainer: {
    marginBottom: 15
  }
});

const Content = ({
  Icon,
  title,
  description,
  extra,
  styles: s
}: FlagTooltipProps) => {
  return (
    <Box {...s('content')}>
      <Box {...s('titleContainer')} alignItems="center">
        <Label {...s('icon')}>
          <Icon width={30} height={30} />
        </Label>
        <Label {...s('title')}>{title}</Label>
      </Box>
      <Tiny {...s('description')}>{description}</Tiny>
      {extra && <Box>{extra}</Box>}
    </Box>
  );
};

const FlagTooltip = (props: FlagTooltipProps) => {
  const { Icon } = props;
  return (
    <VividTooltipStateful
      distance={5}
      Arrow={null}
      openOn="HOVER"
      closeOn="HOVER"
      hoverTimeout={0}
      placement={PLACEMENTS.BOTTOM_START}
      Content={Content}
      contentProps={props}
    >
      <Icon width={14} height={14} />
    </VividTooltipStateful>
  );
};

export default styled(defaultStyles)(FlagTooltip);
