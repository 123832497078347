import React, { CSSProperties } from 'react';
import Box from '@rexlabs/box';

import { COLORS, FONT } from 'src/features/reports/theme';
import { styled, Styles, StyleSheet } from '@rexlabs/styling';

export type HeaderCellProps = {
  title: string;
  styles: Styles;
  containerStyle?: CSSProperties;
};

const styles = StyleSheet({
  cell: {
    borderRight: 0,
    borderBottom: `1px solid ${COLORS.GREY.POWDER}`,
    width: '100%',
    padding: 8,
    cursor: 'pointer',
    backgroundColor: COLORS.BLUE.LIGHT
  },
  value: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 400,
    fontSize: 12,
    color: COLORS.BLUE.DARK,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const TableTitle = ({ title, styles: s, containerStyle }: HeaderCellProps) => {
  return (
    <Box
      style={containerStyle}
      {...s('cell')}
      alignItems="center"
      justifyContent="space-between"
    >
      <p {...s('value')}>{title}</p>
    </Box>
  );
};

export default styled(styles)(TableTitle);
