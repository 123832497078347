import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const PauseOutline = (props: SVGProps<null>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 20V11"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 20V11"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6667 31H9.33333C4.73096 31 1 27.269 1 22.6667V9.33333C1 4.73096 4.73096 1 9.33333 1H22.6667C27.269 1 31 4.73096 31 9.33333V22.6667C31 27.269 27.269 31 22.6667 31Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PauseOutline;
