import React, { FC, useContext, useEffect, useState } from 'react';

import Box from '@rexlabs/box';
import { Link, withWhereabouts } from '@rexlabs/react-whereabouts';
import { StyleSheet, styled, Styles, keyframes } from '@rexlabs/styling';
import { Portal } from '@rexlabs/portal';

import { Auth0Context } from 'src/auth0-provider';
import { COLORS, FONT } from '../theme';
import { Star } from '../components/icons';
import { PrimaryButton } from '../components/button';
import ROUTES from 'src/features/reports/routes';
import { compose } from 'utils/compose';
import { Whereabouts } from 'types/common';
import { ReportQueryParams } from 'types/params';

const fadeIn = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opactity: 1
  }
});

const styles = StyleSheet({
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(6px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 201,
    animation: `${fadeIn} 0.3s ease-in-out`
  },
  container: {
    backgroundColor: COLORS.WHITE,
    padding: '24px',
    borderRadius: '4px',
    maxWidth: '740px'
  },
  premium: {
    color: COLORS.BLUE.DARK,
    fontSize: '28px',
    fontWeight: 700,
    fontFamily: FONT.FAMILIES.DEFAULT,
    margin: '0 13px'
  },
  text: {
    color: COLORS.BLACK,
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: FONT.FAMILIES.DEFAULT,
    marginBottom: '24px'
  },
  highlight: {
    color: '#2376D6',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  contact: {
    color: COLORS.WHITE,
    lineHeight: '42px',
    // Work around so that a tag will fill the button
    margin: '0 -1.2rem',
    padding: '0 1.2rem'
  },
  goBack: {
    lineHeight: '40px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    color: COLORS.BLUE.DARK,
    fontWeight: 500,
    padding: '0 1.2rem',
    border: `1px solid ${COLORS.BUTTON.BORDER.DEFAULT}`,
    borderRadius: 4,
    transition: 'all 0.2s ease-in-out',
    display: 'inline-block',
    '&:hover': {
      opacity: 0.7,
      borderColor: COLORS.BLUE.PRIMARY
    }
  }
});

interface PagePermissionProps {
  permissionsNeeded: string[];
  styles: Styles;
  whereabouts: Whereabouts<ReportQueryParams>;
}

const PagePermission: FC<PagePermissionProps> = ({
  permissionsNeeded,
  styles: s,
  whereabouts,
  children
}) => {
  const { hasPermission } = useContext(Auth0Context);
  const [isPermitted, setIsPermitted] = useState(true);

  useEffect(() => {
    // Stored in a state to trigger rerender
    setIsPermitted(hasPermission(permissionsNeeded));
  }, [permissionsNeeded, hasPermission]);

  return (
    <>
      {!isPermitted && (
        <Portal target="custom-dialogs">
          <div {...s('backdrop')}>
            <div {...s('container')}>
              <Box flexDirection="row" alignItems="center" mb="24px">
                <Star />
                <h3 {...s('premium')}>Premium Feature</h3>
                <Star />
              </Box>
              <p {...s('text')}>
                To view Product Trends, you need premium access for this
                subregion.
              </p>
              <p {...s('text')}>
                Contact your account owner to upgrade your subscription, or you
                can reach GRIP’s dedicated client support team at{' '}
                <a
                  href="mailto:support@gripinsights.com.au"
                  target="blank"
                  {...s('highlight')}
                >
                  support@gripinsights.com.au
                </a>
                .
              </p>
              <Box flexDirection="row" justifyContent="flex-end">
                <Box mr="19px">
                  <Link
                    to={ROUTES.APP.REPORT_OVERVIEW}
                    query={whereabouts.query}
                    {...s('goBack')}
                  >
                    Go to Key Insights
                  </Link>
                </Box>
                <PrimaryButton color={COLORS.BLUE.DARK}>
                  <a
                    href="mailto:support@gripinsights.com.au"
                    target="blank"
                    {...s('contact')}
                  >
                    Contact Support
                  </a>
                </PrimaryButton>
              </Box>
            </div>
          </div>
        </Portal>
      )}
      {children}
    </>
  );
};

export default compose<Pick<PagePermissionProps, 'permissionsNeeded'>>(
  styled(styles),
  withWhereabouts
)(PagePermission);
