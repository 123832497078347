/* eslint-disable max-lines */
import {
  PricingMatrixReport,
  ProjectPerformanceOverviewAverageResponse,
  ProjectPerformanceOverviewReport,
  ProjectPriceRange
} from 'types/graph';

export const fetchProjectPriceRangeMock = (): Promise<{
  data: ProjectPriceRange[];
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          group: 'Sub Region 1',
          subGroup: 'Project 1',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 300000,
          n: 83,
          values: [0, 190000, 300000, 320000, 439000]
        },
        {
          group: 'Sub Region 2',
          subGroup: 'Project 2',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 319000,
          n: 63,
          values: [0, 293000, 319000, 347500, 498000]
        },
        {
          group: 'Sub Region 1',
          subGroup: 'Project 3',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 253000,
          n: 62,
          values: [196000, 248000, 253000, 277000, 325000]
        },
        {
          group: 'Sub Region 2',
          subGroup: 'Project 4',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 237258,
          n: 29,
          values: [200510, 218697, 237258, 280000, 320740]
        },
        {
          group: 'Sub Region 1',
          subGroup: 'Project 5',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 278000,
          n: 6,
          values: [0, 252500, 278000, 278000, 285500]
        },
        {
          group: 'Sub Region 2',
          subGroup: 'Project 11',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 302000,
          n: 24,
          values: [215000, 226000, 302000, 316625, 340000]
        },
        {
          group: 'Sub Region 2',
          subGroup: 'Project 6',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 254400,
          n: 6,
          values: [194900, 202650, 254400, 285150, 305900]
        },
        {
          group: 'Sub Region 2',
          subGroup: 'Project 7',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 307500,
          n: 32,
          values: [223000, 278000, 307500, 321250, 390000]
        },
        {
          group: 'Sub Region 2',
          subGroup: 'Project 8',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 223000,
          n: 14,
          values: [210000, 223000, 223000, 224500, 312000]
        },
        {
          group: 'Sub Region 1',
          subGroup: 'Project 9',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 278000,
          n: 5,
          values: [0, 260000, 278000, 280000, 315000]
        },
        {
          group: 'Sub Region 2',
          subGroup: 'Project 10',
          quantiles: [0, 0.25, 0.5, 0.75, 1],
          median: 316500,
          n: 4,
          values: [315000, 315000, 316500, 329000, 362000]
        }
      ]
    });
  });
};

export const fetchProjectPerfDataMock = (): Promise<{
  data: ProjectPerformanceOverviewReport;
}> => {
  return new Promise((resolve) => {
    resolve({
      data: {
        monthly: [
          {
            gross_sales: 0.56,
            gross_sales_untitled: 10,
            gross_sales_titled: 0,
            end_date: '2019-03-01'
          },
          {
            gross_sales: 1.06,
            gross_sales_untitled: 19,
            gross_sales_titled: 0,
            end_date: '2019-04-01'
          },
          {
            gross_sales: 1.28,
            gross_sales_untitled: 23,
            gross_sales_titled: 0,
            end_date: '2019-05-01'
          },
          {
            gross_sales: 1.33,
            gross_sales_untitled: 22,
            gross_sales_titled: 2,
            end_date: '2019-06-01'
          },
          {
            gross_sales: 2.17,
            gross_sales_untitled: 34,
            gross_sales_titled: 5,
            end_date: '2019-07-01'
          },
          {
            gross_sales: 2.17,
            gross_sales_untitled: 26,
            gross_sales_titled: 13,
            end_date: '2019-08-01'
          },
          {
            gross_sales: 2.17,
            gross_sales_untitled: 35,
            gross_sales_titled: 4,
            end_date: '2019-09-01'
          },
          {
            gross_sales: 2.56,
            gross_sales_untitled: 43,
            gross_sales_titled: 3,
            end_date: '2019-10-01'
          },
          {
            gross_sales: 3.61,
            gross_sales_untitled: 55,
            gross_sales_titled: 10,
            end_date: '2019-11-01'
          },
          {
            gross_sales: 1.78,
            gross_sales_untitled: 25,
            gross_sales_titled: 7,
            end_date: '2019-12-01'
          },
          {
            gross_sales: 2.33,
            gross_sales_untitled: 38,
            gross_sales_titled: 4,
            end_date: '2020-01-01'
          },
          {
            gross_sales: 1.44,
            gross_sales_untitled: 20,
            gross_sales_titled: 6,
            end_date: '2020-02-01'
          },
          {
            gross_sales: 4.17,
            gross_sales_untitled: 55,
            gross_sales_titled: 20,
            end_date: '2020-03-01'
          },
          {
            gross_sales: 1.83,
            gross_sales_untitled: 23,
            gross_sales_titled: 10,
            end_date: '2020-04-01'
          },
          {
            gross_sales: 3.44,
            gross_sales_untitled: 53,
            gross_sales_titled: 9,
            end_date: '2020-05-01'
          },
          {
            gross_sales: 5.22,
            gross_sales_untitled: 60,
            gross_sales_titled: 34,
            end_date: '2020-06-01'
          },
          {
            gross_sales: 7.78,
            gross_sales_untitled: 76,
            gross_sales_titled: 64,
            end_date: '2020-07-01'
          },
          {
            gross_sales: 4.67,
            gross_sales_untitled: 56,
            gross_sales_titled: 28,
            end_date: '2020-08-01'
          },
          {
            gross_sales: 5.78,
            gross_sales_untitled: 56,
            gross_sales_titled: 48,
            end_date: '2020-09-01'
          },
          {
            gross_sales: 8.83,
            gross_sales_untitled: 108,
            gross_sales_titled: 51,
            end_date: '2020-10-01'
          },
          {
            gross_sales: 8.67,
            gross_sales_untitled: 108,
            gross_sales_titled: 48,
            end_date: '2020-11-01'
          },
          {
            gross_sales: 8.22,
            gross_sales_untitled: 136,
            gross_sales_titled: 12,
            end_date: '2020-12-01'
          },
          {
            gross_sales: 9.61,
            gross_sales_untitled: 163,
            gross_sales_titled: 10,
            end_date: '2021-01-01'
          },
          {
            gross_sales: 8.61,
            gross_sales_untitled: 144,
            gross_sales_titled: 11,
            end_date: '2021-02-01'
          }
        ],
        quarterly: [
          { quarter: 'Q2 2019', gross_sales: 3.67 },
          { quarter: 'Q3 2019', gross_sales: 6.5 },
          { quarter: 'Q4 2019', gross_sales: 7.94 },
          { quarter: 'Q1 2020', gross_sales: 7.94 },
          { quarter: 'Q2 2020', gross_sales: 10.5 },
          { quarter: 'Q3 2020', gross_sales: 18.22 },
          { quarter: 'Q4 2020', gross_sales: 25.72 },
          { quarter: 'Q1 2021', gross_sales: 18.22 }
        ]
      }
    });
  });
};

export const fetchProjectPerfAverageMock = (): Promise<{
  data: ProjectPerformanceOverviewAverageResponse;
}> => {
  return new Promise((resolve) => {
    resolve({
      data: {
        outstanding_contracts: 51,
        gross_sales: 155,
        median_price: 293000,
        median_lot_size: 400,
        median_price_per_m2: 717,
        newly_released: 157,
        net_sales: 90
      }
    });
  });
};

export const fetchPricingMatrixMock = (): Promise<{
  data: PricingMatrixReport[];
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          frontage: '10.5',
          depth: '32',
          area: '336',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': {
              title: 'Project 4',
              listings_count: '2',
              max_price: '324000',
              max_price_minus_buffer: '324000',
              max_price_minus_incentives: '324000',
              max_price_minus_buffer_and_incentives: '324000',
              min_price: '324000',
              min_price_minus_buffer: '324000',
              min_price_minus_incentives: '324000',
              min_price_minus_buffer_and_incentives: '324000',
              max_price_per_m2: 964,
              max_price_per_m2_minus_buffer: 964,
              max_price_per_m2_minus_incentives: 964,
              max_price_per_m2_minus_buffer_and_incentives: 964,
              min_price_per_m2: 964,
              min_price_per_m2_minus_buffer: 964,
              min_price_per_m2_minus_incentives: 964,
              min_price_per_m2_minus_buffer_and_incentives: 964
            },
            'Project 5': {
              title: 'Project 5',
              listings_count: '3',
              max_price: '305000',
              max_price_minus_buffer: '305000',
              max_price_minus_incentives: '305000',
              max_price_minus_buffer_and_incentives: '305000',
              min_price: '305000',
              min_price_minus_buffer: '305000',
              min_price_minus_incentives: '305000',
              min_price_minus_buffer_and_incentives: '305000',
              max_price_per_m2: 908,
              max_price_per_m2_minus_buffer: 908,
              max_price_per_m2_minus_incentives: 908,
              max_price_per_m2_minus_buffer_and_incentives: 908,
              min_price_per_m2: 908,
              min_price_per_m2_minus_buffer: 908,
              min_price_per_m2_minus_incentives: 908,
              min_price_per_m2_minus_buffer_and_incentives: 908
            },
            'Project 6': null,
            'Project 7': null,
            'Project 8': {
              title: 'Project 8',
              listings_count: '1',
              max_price: '271500',
              max_price_minus_buffer: '271500',
              max_price_minus_incentives: '271500',
              max_price_minus_buffer_and_incentives: '271500',
              min_price: '271500',
              min_price_minus_buffer: '271500',
              min_price_minus_incentives: '271500',
              min_price_minus_buffer_and_incentives: '271500',
              max_price_per_m2: 808,
              max_price_per_m2_minus_buffer: 808,
              max_price_per_m2_minus_incentives: 808,
              max_price_per_m2_minus_buffer_and_incentives: 808,
              min_price_per_m2: 808,
              min_price_per_m2_minus_buffer: 808,
              min_price_per_m2_minus_incentives: 808,
              min_price_per_m2_minus_buffer_and_incentives: 808
            },
            'Project 9': null,
            'Project 10': null
          }
        },
        {
          frontage: '8.5',
          depth: '30',
          area: '255',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': null,
            'Project 5': {
              title: 'Project 5',
              listings_count: '1',
              max_price: '235000',
              max_price_minus_buffer: '235000',
              max_price_minus_incentives: '235000',
              max_price_minus_buffer_and_incentives: '235000',
              min_price: '235000',
              min_price_minus_buffer: '235000',
              min_price_minus_incentives: '235000',
              min_price_minus_buffer_and_incentives: '235000',
              max_price_per_m2: 922,
              max_price_per_m2_minus_buffer: 922,
              max_price_per_m2_minus_incentives: 922,
              max_price_per_m2_minus_buffer_and_incentives: 922,
              min_price_per_m2: 922,
              min_price_per_m2_minus_buffer: 922,
              min_price_per_m2_minus_incentives: 922,
              min_price_per_m2_minus_buffer_and_incentives: 922
            },
            'Project 6': null,
            'Project 7': null,
            'Project 8': null,
            'Project 9': null,
            'Project 10': null
          }
        },
        {
          frontage: '8.5',
          depth: '32',
          area: '272',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': null,
            'Project 5': {
              title: 'Project 5',
              listings_count: '2',
              max_price: '255000',
              max_price_minus_buffer: '255000',
              max_price_minus_incentives: '255000',
              max_price_minus_buffer_and_incentives: '255000',
              min_price: '250000',
              min_price_minus_buffer: '250000',
              min_price_minus_incentives: '250000',
              min_price_minus_buffer_and_incentives: '250000',
              max_price_per_m2: 938,
              max_price_per_m2_minus_buffer: 938,
              max_price_per_m2_minus_incentives: 938,
              max_price_per_m2_minus_buffer_and_incentives: 938,
              min_price_per_m2: 919,
              min_price_per_m2_minus_buffer: 919,
              min_price_per_m2_minus_incentives: 919,
              min_price_per_m2_minus_buffer_and_incentives: 919
            },
            'Project 6': null,
            'Project 7': null,
            'Project 8': null,
            'Project 9': null,
            'Project 10': null
          }
        },
        {
          frontage: '12.5',
          depth: '28',
          area: '350',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': null,
            Accolade: null,
            'Project 6': {
              title: 'Project 6',
              listings_count: '1',
              max_price: '287000',
              max_price_minus_buffer: '287000',
              max_price_minus_incentives: '287000',
              max_price_minus_buffer_and_incentives: '287000',
              min_price: '287000',
              min_price_minus_buffer: '287000',
              min_price_minus_incentives: '287000',
              min_price_minus_buffer_and_incentives: '287000',
              max_price_per_m2: 820,
              max_price_per_m2_minus_buffer: 820,
              max_price_per_m2_minus_incentives: 820,
              max_price_per_m2_minus_buffer_and_incentives: 820,
              min_price_per_m2: 820,
              min_price_per_m2_minus_buffer: 820,
              min_price_per_m2_minus_incentives: 820,
              min_price_per_m2_minus_buffer_and_incentives: 820
            },
            'Project 7': null,
            'Project 8': null,
            'Project 9': null,
            'Project 10': null
          }
        },
        {
          frontage: '12.5',
          depth: '32',
          area: '400',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': null,
            Accolade: null,
            'Project 6': {
              title: 'Project 6',
              listings_count: '4',
              max_price: '317000',
              max_price_minus_buffer: '317000',
              max_price_minus_incentives: '317000',
              max_price_minus_buffer_and_incentives: '317000',
              min_price: '317000',
              min_price_minus_buffer: '317000',
              min_price_minus_incentives: '317000',
              min_price_minus_buffer_and_incentives: '317000',
              max_price_per_m2: 793,
              max_price_per_m2_minus_buffer: 793,
              max_price_per_m2_minus_incentives: 793,
              max_price_per_m2_minus_buffer_and_incentives: 793,
              min_price_per_m2: 793,
              min_price_per_m2_minus_buffer: 793,
              min_price_per_m2_minus_incentives: 793,
              min_price_per_m2_minus_buffer_and_incentives: 793
            },
            'Project 7': null,
            'Project 8': null,
            'Project 9': {
              title: 'Project 9',
              listings_count: '2',
              max_price: '310000',
              max_price_minus_buffer: '310000',
              max_price_minus_incentives: '310000',
              max_price_minus_buffer_and_incentives: '310000',
              min_price: '310000',
              min_price_minus_buffer: '310000',
              min_price_minus_incentives: '310000',
              min_price_minus_buffer_and_incentives: '310000',
              max_price_per_m2: 775,
              max_price_per_m2_minus_buffer: 775,
              max_price_per_m2_minus_incentives: 775,
              max_price_per_m2_minus_buffer_and_incentives: 775,
              min_price_per_m2: 775,
              min_price_per_m2_minus_buffer: 775,
              min_price_per_m2_minus_incentives: 775,
              min_price_per_m2_minus_buffer_and_incentives: 775
            },
            'Project 10': null
          }
        },
        {
          frontage: '16',
          depth: '32',
          area: '512',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': null,
            Accolade: null,
            'Project 6': {
              title: 'Project 6',
              listings_count: '4',
              max_price: '367000',
              max_price_minus_buffer: '367000',
              max_price_minus_incentives: '367000',
              max_price_minus_buffer_and_incentives: '367000',
              min_price: '366000',
              min_price_minus_buffer: '366000',
              min_price_minus_incentives: '366000',
              min_price_minus_buffer_and_incentives: '366000',
              max_price_per_m2: 717,
              max_price_per_m2_minus_buffer: 717,
              max_price_per_m2_minus_incentives: 717,
              max_price_per_m2_minus_buffer_and_incentives: 717,
              min_price_per_m2: 715,
              min_price_per_m2_minus_buffer: 715,
              min_price_per_m2_minus_incentives: 715,
              min_price_per_m2_minus_buffer_and_incentives: 715
            },
            'Project 7': null,
            'Project 8': null,
            'Project 9': null,
            'Project 10': null
          }
        },
        {
          frontage: '14',
          depth: '32',
          area: '448',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': null,
            Accolade: null,
            'Project 6': null,
            'Project 7': {
              title: 'Project 7',
              listings_count: '1',
              max_price: '269000',
              max_price_minus_buffer: '269000',
              max_price_minus_incentives: '269000',
              max_price_minus_buffer_and_incentives: '269000',
              min_price: '269000',
              min_price_minus_buffer: '269000',
              min_price_minus_incentives: '269000',
              min_price_minus_buffer_and_incentives: '269000',
              max_price_per_m2: 600,
              max_price_per_m2_minus_buffer: 600,
              max_price_per_m2_minus_incentives: 600,
              max_price_per_m2_minus_buffer_and_incentives: 600,
              min_price_per_m2: 600,
              min_price_per_m2_minus_buffer: 600,
              min_price_per_m2_minus_incentives: 600,
              min_price_per_m2_minus_buffer_and_incentives: 600
            },
            'Project 8': null,
            'Project 9': {
              title: 'Project 9',
              listings_count: '2',
              max_price: '335500',
              max_price_minus_buffer: '335500',
              max_price_minus_incentives: '335500',
              max_price_minus_buffer_and_incentives: '335500',
              min_price: '335500',
              min_price_minus_buffer: '335500',
              min_price_minus_incentives: '335500',
              min_price_minus_buffer_and_incentives: '335500',
              max_price_per_m2: 749,
              max_price_per_m2_minus_buffer: 749,
              max_price_per_m2_minus_incentives: 749,
              max_price_per_m2_minus_buffer_and_incentives: 749,
              min_price_per_m2: 749,
              min_price_per_m2_minus_buffer: 749,
              min_price_per_m2_minus_incentives: 749,
              min_price_per_m2_minus_buffer_and_incentives: 749
            },
            'Project 10': null
          }
        },
        {
          frontage: '8.5',
          depth: '28',
          area: '238',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': null,
            Accolade: null,
            'Project 6': null,
            'Project 7': null,
            'Project 8': null,
            'Project 9': {
              title: 'Project 9',
              listings_count: '1',
              max_price: '225000',
              max_price_minus_buffer: '225000',
              max_price_minus_incentives: '225000',
              max_price_minus_buffer_and_incentives: '225000',
              min_price: '225000',
              min_price_minus_buffer: '225000',
              min_price_minus_incentives: '225000',
              min_price_minus_buffer_and_incentives: '225000',
              max_price_per_m2: 945,
              max_price_per_m2_minus_buffer: 945,
              max_price_per_m2_minus_incentives: 945,
              max_price_per_m2_minus_buffer_and_incentives: 945,
              min_price_per_m2: 945,
              min_price_per_m2_minus_buffer: 945,
              min_price_per_m2_minus_incentives: 945,
              min_price_per_m2_minus_buffer_and_incentives: 945
            },
            'Project 10': null
          }
        },
        {
          frontage: '10.5',
          depth: '28',
          area: '294',
          estates: {
            'Project 1': null,
            'Project 2': null,
            'Project 3': null,
            'Project 4': null,
            Accolade: null,
            'Project 6': null,
            'Project 7': null,
            'Project 8': null,
            'Project 9': {
              title: 'Project 9',
              listings_count: '2',
              max_price: '240500',
              max_price_minus_buffer: '240500',
              max_price_minus_incentives: '240500',
              max_price_minus_buffer_and_incentives: '240500',
              min_price: '240500',
              min_price_minus_buffer: '240500',
              min_price_minus_incentives: '240500',
              min_price_minus_buffer_and_incentives: '240500',
              max_price_per_m2: 818,
              max_price_per_m2_minus_buffer: 818,
              max_price_per_m2_minus_incentives: 818,
              max_price_per_m2_minus_buffer_and_incentives: 818,
              min_price_per_m2: 818,
              min_price_per_m2_minus_buffer: 818,
              min_price_per_m2_minus_incentives: 818,
              min_price_per_m2_minus_buffer_and_incentives: 818
            },
            'Project 10': null
          }
        }
      ]
    });
  });
};
