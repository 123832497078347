import React, { Fragment, PureComponent } from 'react';
import Box from '@rexlabs/box';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import { TextInput } from '@rexlabs/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import CalendarSvg from 'assets/calendar.svg';

import 'react-dates/lib/css/_datepicker.css';

const defaultStyles = StyleSheet({
  calendarWrapper: {
    position: 'absolute',
    zIndex: 1
  }
});

@styled(defaultStyles)
class DayPicker extends PureComponent {
  state = {
    focused: false
  };

  static defaultProps = {
    formatter: (value) =>
      moment.isMoment(value) ? value.format('DD/MM/YYYY') : value
  };

  handleInputFocus = () => {
    this.setState({ focused: true });
  };

  handleDateChange = (date) => {
    this.setState({
      focused: false
    });

    this.props.onChange({
      target: { value: date },
      persist: _.noop
    });
  };

  handleDateOutsideClick = () => {
    this.setState({
      focused: false
    });
  };

  handleInputChange = (e) => {
    this.props.onChange({
      target: { value: e.target.value },
      persist: _.noop
    });
  };

  handleInputBlur = (e) => {
    const date = moment(e.target.value, 'DD/MM/YYYY');
    this.props.onChange({
      target: { value: date.isValid() ? date : '' },
      persist: _.noop
    });
  };

  getMomentOrEmpty = (value) => {
    const date = moment(value, 'DD/MM/YYYY');
    return date.isValid() ? date : '';
  };

  render() {
    const { styles: s, value, formatter } = this.props;
    const { focused } = this.state;

    return (
      <Fragment>
        <TextInput
          controlled
          name={this.props.name}
          value={formatter(value)}
          onFocus={this.handleInputFocus}
          onChange={this.handleInputChange}
          onBlur={this.handleInputBlur}
          suffix={<CalendarSvg />}
          placeholder={'dd/mm/yyyy'}
        />

        {focused && (
          <Box {...s('calendarWrapper')}>
            <DayPickerSingleDateController
              hideKeyboardShortcutsPanel
              focused={true}
              onOutsideClick={this.handleDateOutsideClick}
              onDateChange={this.handleDateChange} // PropTypes.func.isRequired,
              onFocusChange={(focusedInput) => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              date={
                moment.isMoment(value) ? value : this.getMomentOrEmpty(value)
              }
            />
          </Box>
        )}
      </Fragment>
    );
  }
}

export default DayPicker;
