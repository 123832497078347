import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      Lot Journey portrays the transition of each allotment’s status across two
      periods. It allows you to consider valuable details that sit behind the
      ‘end of month’ numbers which you can view by{' '}
      <span className="bold">hovering over the chart.</span>
    </>
  );
};

export default InfoTooltip;
