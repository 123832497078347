import React from 'react';
import Box from '@rexlabs/box';
import { Heading } from 'view/components/text';
import { COLORS, PADDINGS } from 'src/theme';
import _ from 'lodash';
import { Grid } from '@rexlabs/grid';
import SectionColumn from 'view/components/grid/section-column';
import Table from 'view/components/table/react-table';
import { Project } from 'data/types/projects';
import { Breakdown, AverageSales } from 'data/types/overview';
import DarkAlertSVG from 'assets/dark-alert.svg';
import { TooltipStateful, PLACEMENTS } from '@rexlabs/tooltip';

export interface EstateBreakdownProps {
  breakdownStats: Breakdown[];
  primary?: Project;
  period: any;
}

const EstateBreakdown = ({
  breakdownStats,
  primary,
  period
}: EstateBreakdownProps) => {
  // Make sure the primary estate is on top.
  // Then order by gross sales per month from highest to lowest.
  const breakdownOrdered = _.orderBy(
    breakdownStats,
    [
      (value) => (value?.project_id === primary?.id ? 0 : 1),
      'gross_sales_month'
    ],
    ['asc', 'desc']
  );

  const periodDate = new Date(period.end_date);
  const currentDate = new Date();

  const formattedPeriod = periodDate.toLocaleString('en-us', {
    month: 'long'
  });

  const monthsDiff = (period: Date, current: Date) => {
    const years = current.getFullYear() - period.getFullYear();
    return current.getMonth() + 12 * years - period.getMonth();
  };

  // Some stats can only be displayed if viewed on or before the 15th day of the month after the period.
  // e.g. For a June report, stats will be hidden after the 15th of July.
  const getIfCanView = (row) => {
    const diff = monthsDiff(periodDate, currentDate);
    if (diff <= 1 || (diff === 2 && currentDate.getDate() <= 15)) {
      return row?.value ?? '-';
    }

    return (
      <TooltipStateful
        placement={'top'}
        openOn="HOVER"
        closeOn="HOVER"
        hoverTimeout={100}
        closeDuration={100}
        Content={() => (
          <div style={{ padding: '0.5rem' }}>
            Open current months report to see this data
          </div>
        )}
      >
        <DarkAlertSVG />
      </TooltipStateful>
    );
  };

  return (
    <Box>
      {/* ESTATE BREAKDOWN */}
      <Grid gutter={0} columns={1}>
        <SectionColumn width={1}>
          <Heading style={{ padding: PADDINGS.S }}>Estate Breakdown</Heading>
          <Table
            getTdProps={(state, rowInfo) => ({
              style: {
                color:
                  rowInfo?.original?.project_id === primary?.id
                    ? COLORS.BLUE_GREEN
                    : 'inherit'
              }
            })}
            data={breakdownOrdered}
            columns={[
              {
                Header: () => (
                  <p style={{ color: COLORS.BLUE_GREEN }}>Estate</p>
                ),
                accessor: 'title',
                fixed: 'left',
                width: 150,
                style: {
                  background: COLORS.BLUE.LIGHT,
                  boxShadow: '4px 0px 3px -3px #ccc',
                  border: 'none !important'
                }
              },
              {
                Header: 'Suburb',
                width: 150,
                accessor: 'suburb'
              },
              {
                Header: `Gross Sales (${formattedPeriod})`,
                width: 150,
                accessor: 'gross_sales_month'
              },
              {
                Header: `Net Sales (${formattedPeriod})`,
                width: 150,
                accessor: 'net_sales_month'
              },
              {
                Header: 'Back on Market',
                width: 150,
                accessor: 'contract_fallovers'
              },
              {
                Header: 'Outstanding Contracts',
                width: 150,
                accessor: 'outstanding_contracts'
              },
              {
                Header: 'Ave. Gross Sales (3 month)',
                width: 150,
                accessor: 'average_quarterly_sales',
                sortMethod: (a, b) => (a.value > b.value ? 1 : -1),
                Cell: (row) => {
                  const value: AverageSales = row?.value ?? '-';
                  return (
                    <span>
                      {value.value}
                      {value.supply_issue ? '^' : ''}
                    </span>
                  );
                }
              },
              {
                Header: 'Ave. Gross Sales (12 month)',
                width: 150,
                accessor: 'average_yearly_sales',
                sortMethod: (a, b) => (a.value > b.value ? 1 : -1),
                Cell: (row) => {
                  const value: AverageSales = row?.value;
                  return (
                    <span>
                      {value.value ?? '-'}
                      {value.supply_issue ? '^' : ''}
                    </span>
                  );
                }
              },
              {
                Header: 'Titled Available Stock',
                width: 150,
                accessor: 'titled_listings'
              },
              {
                Header: 'Untitled Available Stock',
                width: 150,
                accessor: 'untitled_listings'
              },
              {
                Header: 'Total Available Stock',
                width: 150,
                accessor: 'remaining_stock'
              },
              {
                Header: 'Est. Total # Allotments',
                width: 150,
                accessor: 'estimated_lots',
                Cell: getIfCanView
              },
              {
                Header: 'Number of Lots Remaining',
                width: 150,
                accessor: 'estimated_remaining',
                Cell: getIfCanView
              },
              {
                Header: 'Estate Forecast Completion',
                width: 150,
                accessor: 'estimated_completion',
                Cell: getIfCanView
              },
              {
                Header: 'Project Status',
                width: 150,
                id: 'projectStatus',
                accessor: 'project_status'
              }
            ]}
            defaultPageSize={breakdownOrdered.length || 5}
          />
          <Box p={PADDINGS.XXS} flex={1} flexDirection={'column'}>
            <span>^ = supply issue</span>
            <span>* = no price list received</span>
            <span>
              <DarkAlertSVG /> = open current months report to view this data
            </span>
          </Box>
        </SectionColumn>
      </Grid>
    </Box>
  );
};

export default EstateBreakdown;
