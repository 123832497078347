/* eslint-disable max-lines */
import React, { Fragment, ReactNode, createElement, useMemo } from 'react';
import { Grid } from '@nivo/axes';
import {
  CartesianMarkers,
  Container,
  SvgWrapper,
  // @ts-expect-error error due to nivo core types
  bindDefs,
  useDimensions,
  useMotionConfig
} from '@nivo/core';
import { StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { BoxPlotAnnotations } from './box-plot-annotations';
import { BoxPlotLegends } from './box-plot-legends';
import {
  BoxPlotCustomLayerProps,
  BoxPlotDatum,
  BoxPlotLayer,
  BoxPlotLayerId,
  BoxPlotSvgProps
} from './types';
import { svgDefaultProps } from './props';
import { useAutoHeight, useBoxPlot, useBoxPlotTransition } from './hooks';
import BoxPlotItemLabel from './box-plot-item-label';
import BoxPlotGroupLabel from './box-plot-group-label';
import { reverse, uniqBy } from 'lodash';
import { Axis } from './box-plot-axis';
import { AXIS_SPACE } from './constants';
import { COLORS } from 'src/features/reports/theme';

const styles = StyleSheet({
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '12px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '6px',
    backgroundColor: COLORS.GREY.DARK
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: `${COLORS.GREY.DARK}D9` // 0.85 opacity
  }
});

type InnerBoxPlotProps<RawDatum extends BoxPlotDatum> = Omit<
  BoxPlotSvgProps<RawDatum>,
  'animate' | 'motionConfig' | 'renderWrapper' | 'theme'
>;

const InnerBoxPlot = <RawDatum extends BoxPlotDatum>({
  data,
  value = svgDefaultProps.value,
  groupBy = svgDefaultProps.groupBy,
  groups = svgDefaultProps.groups,
  subGroupBy = svgDefaultProps.subGroupBy,
  subGroups = svgDefaultProps.subGroups,
  quantiles = svgDefaultProps.quantiles,

  margin: partialMargin,
  width,
  height,
  axisBottom,

  layout = svgDefaultProps.layout,
  minValue = svgDefaultProps.minValue,
  maxValue = svgDefaultProps.maxValue,

  valueScale = svgDefaultProps.valueScale,
  indexScale = svgDefaultProps.indexScale,

  padding = svgDefaultProps.padding,
  innerPadding = svgDefaultProps.innerPadding,

  opacity = svgDefaultProps.opacity,
  activeOpacity = svgDefaultProps.activeOpacity,
  inactiveOpacity = svgDefaultProps.inactiveOpacity,

  enableGridX = svgDefaultProps.enableGridX,
  enableGridY = svgDefaultProps.enableGridY,
  gridXValues,
  gridYValues,

  layers = svgDefaultProps.layers as BoxPlotLayer<RawDatum>[],
  boxPlotComponent = svgDefaultProps.boxPlotComponent,

  colorBy = svgDefaultProps.colorBy,
  colors = svgDefaultProps.colors,
  defs = svgDefaultProps.defs,
  fill,

  borderRadius = svgDefaultProps.borderRadius,

  percentileColor = svgDefaultProps.percentileColor,

  medianWidth = svgDefaultProps.medianWidth,
  medianColor = svgDefaultProps.medianColor,

  whiskerWidth = svgDefaultProps.whiskerWidth,
  whiskerColor = svgDefaultProps.whiskerColor,
  whiskerEndSize = svgDefaultProps.whiskerEndSize,

  markers = svgDefaultProps.markers,

  legendLabel,
  tooltipLabel = svgDefaultProps.tooltipLabel,

  valueFormat,

  isInteractive = svgDefaultProps.isInteractive,
  tooltip = svgDefaultProps.tooltip,
  onClick,
  onMouseEnter,
  onMouseLeave,

  annotations = svgDefaultProps.annotations,
  legends = svgDefaultProps.legends,

  role = svgDefaultProps.role,
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
  isFocusable = svgDefaultProps.isFocusable,
  boxPlotAriaLabel,
  boxPlotAriaLabelledBy,
  boxPlotAriaDescribedBy
}: InnerBoxPlotProps<RawDatum>) => {
  const { animate, config: springConfig } = useMotionConfig();
  const { autoHeight } = useAutoHeight({
    // @ts-expect-error data type is BoxPlotSummary[] but RawDatum is generic
    boxPlots: data,
    innerPadding,
    margin: partialMargin,
    layout
  });
  const { outerWidth, outerHeight, margin, innerWidth, innerHeight } =
    useDimensions(width, autoHeight, partialMargin);

  const {
    boxPlots,
    xScale,
    yScale,
    getTooltipLabel,
    getMedianColor,
    getWhiskerColor,
    legendsData,
    activeItem,
    setActiveItem,
    getPercentileColor
  } = useBoxPlot<RawDatum>({
    data,
    value,
    groupBy,
    groups,
    subGroupBy,
    subGroups,
    quantiles,
    width: innerWidth,
    height: innerHeight,
    layout,
    minValue,
    maxValue,
    valueScale,
    indexScale,
    padding,
    innerPadding,
    colorBy,
    colors,
    opacity,
    activeOpacity,
    inactiveOpacity,
    percentileColor,
    medianColor,
    whiskerColor,
    legendLabel,
    tooltipLabel,
    valueFormat,
    legends
  });

  const transition = useBoxPlotTransition({
    boxPlots,
    getPercentileColor,
    getMedianColor,
    getWhiskerColor,
    animate,
    springConfig
  });

  const commonProps = useMemo(
    () => ({
      borderRadius,
      medianWidth,
      whiskerWidth,
      whiskerEndSize,
      padding,
      innerPadding,
      isInteractive,
      onClick,
      onMouseEnter,
      onMouseLeave,
      getTooltipLabel,
      tooltip,
      isFocusable,
      ariaLabel: boxPlotAriaLabel,
      ariaLabelledBy: boxPlotAriaLabelledBy,
      ariaDescribedBy: boxPlotAriaDescribedBy,
      activeItem,
      setActiveItem
    }),
    [
      borderRadius,
      medianWidth,
      whiskerWidth,
      whiskerEndSize,
      padding,
      innerPadding,
      isInteractive,
      onClick,
      onMouseEnter,
      onMouseLeave,
      tooltip,
      getTooltipLabel,
      isFocusable,
      boxPlotAriaLabel,
      boxPlotAriaLabelledBy,
      boxPlotAriaDescribedBy,
      activeItem,
      setActiveItem
    ]
  );

  const boundDefs = bindDefs(defs, boxPlots, fill, {
    dataKey: 'data',
    targetKey: 'fill'
  });

  const layerById: Record<BoxPlotLayerId, ReactNode> = {
    annotations: null,
    axes: null,
    boxPlots: null,
    boxPlotItemLabel: null,
    boxPlotGroupLabel: null,
    grid: null,
    legends: null,
    markers: null
  };

  if (layers.includes('annotations')) {
    layerById.annotations = (
      <BoxPlotAnnotations
        key="annotations"
        boxPlots={boxPlots}
        annotations={annotations}
      />
    );
  }

  if (layers.includes('boxPlots')) {
    layerById.boxPlots = (
      <Fragment key="boxPlots">
        {transition((animatedProps, boxPlot) =>
          createElement(boxPlotComponent, {
            ...commonProps,
            boxPlot,
            layout,
            animatedProps
          })
        )}
      </Fragment>
    );
  }

  if (layers.includes('boxPlotItemLabel')) {
    layerById.boxPlotItemLabel = (
      <Fragment key="boxPlotItemLabel">
        {boxPlots.map((plot) => (
          <BoxPlotItemLabel key={plot.key} {...plot} />
        ))}
      </Fragment>
    );
  }

  if (layers.includes('boxPlotGroupLabel')) {
    layerById.boxPlotGroupLabel = (
      <Fragment key="boxPlotGroupLabel">
        {uniqBy(reverse(boxPlots.slice()), 'group').map((plot) => (
          <BoxPlotGroupLabel key={plot.key} {...plot} />
        ))}
      </Fragment>
    );
  }

  if (layers.includes('grid')) {
    layerById.grid = (
      <Grid
        key="grid"
        width={innerWidth}
        height={innerHeight}
        xScale={enableGridX ? xScale : null}
        yScale={enableGridY ? yScale : null}
        xValues={gridXValues}
        yValues={gridYValues}
      />
    );
  }

  if (layers.includes('legends')) {
    layerById.legends = (
      <BoxPlotLegends
        key="legends"
        width={innerWidth}
        height={innerHeight}
        legends={legendsData}
      />
    );
  }

  if (layers.includes('markers')) {
    layerById.markers = (
      <CartesianMarkers<number | string, number>
        key="markers"
        markers={markers}
        width={innerWidth}
        height={innerHeight}
        xScale={xScale as (v: number | string) => number}
        yScale={yScale as (v: number) => number}
      />
    );
  }

  const layerContext: BoxPlotCustomLayerProps<RawDatum> = useMemo(
    () => ({
      ...commonProps,
      layout,
      margin,
      width,
      height: autoHeight,
      innerWidth,
      innerHeight,
      padding,
      innerPadding,
      boxPlots,
      onClick,
      onMouseEnter,
      onMouseLeave,
      tooltip,
      getTooltipLabel,
      xScale,
      yScale
    }),
    [
      commonProps,
      layout,
      margin,
      width,
      autoHeight,
      innerWidth,
      innerHeight,
      padding,
      innerPadding,
      boxPlots,
      onClick,
      onMouseEnter,
      onMouseLeave,
      tooltip,
      getTooltipLabel,
      xScale,
      yScale
    ]
  );

  return (
    <Box>
      <Box style={styles} height={height}>
        <SvgWrapper
          width={outerWidth}
          height={outerHeight}
          margin={margin}
          defs={boundDefs}
          role={role}
          ariaLabel={ariaLabel}
          ariaLabelledBy={ariaLabelledBy}
          ariaDescribedBy={ariaDescribedBy}
          isFocusable={isFocusable}
        >
          {layers.map((layer, i) => {
            if (typeof layer === 'function') {
              return (
                <Fragment key={i}>
                  {createElement(layer, layerContext)}
                </Fragment>
              );
            }
            return layerById?.[layer] ?? null;
          })}
        </SvgWrapper>
      </Box>
      <svg width={outerWidth} height={60 + AXIS_SPACE}>
        <Axis
          axis="x"
          x={margin.left}
          y={AXIS_SPACE}
          scale={xScale}
          length={innerWidth}
          ticksPosition="after"
          {...axisBottom}
        />
      </svg>
    </Box>
  );
};

export const BoxPlot = <RawDatum extends BoxPlotDatum>({
  isInteractive = svgDefaultProps.isInteractive,
  animate = svgDefaultProps.animate,
  motionConfig = svgDefaultProps.motionConfig,
  theme,
  renderWrapper,
  ...otherProps
}: BoxPlotSvgProps<RawDatum>) => (
  <Container
    {...{
      animate,
      isInteractive,
      motionConfig,
      renderWrapper,
      theme
    }}
  >
    <InnerBoxPlot<RawDatum> isInteractive={isInteractive} {...otherProps} />
  </Container>
);
