import React from 'react';
import Box from '@rexlabs/box';
import { MapViewModal } from 'src/features/reports/components/modals';
import {
  LoadReport,
  PeriodFilter,
  SubRegionProjectSelector,
  UserMenu
} from '../report-settings';
import Divider from './divider';

function ReportFilter() {
  return (
    <Box flexDirection="row" alignItems="center">
      <LoadReport />
      <Divider />
      <Box mr={16}>
        <SubRegionProjectSelector />
      </Box>
      <Box mr={16}>
        <PeriodFilter />
      </Box>
      <MapViewModal />
      <Divider />
      <UserMenu />
    </Box>
  );
}

export default ReportFilter;
