import React, { ChangeEventHandler, forwardRef } from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';

import { TextInput } from '@rexlabs/text-input';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';

import { COLORS } from 'src/theme';
import { getShortMonthWithFullYear } from 'utils/format';
import CalendarSvg from 'assets/calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

// Override styling of DatePicker because it is using rem
// So the text and all are small because we have html {font-size: 62.5%}
// Which is 1rem = 10px
// Added extra styling as well to match our theme
const styles = StyleSheet({
  container: {
    '& .react-datepicker': {
      fontSize: '1em'
    },
    '& .react-datepicker__triangle': {
      display: 'none'
    },
    '& .react-datepicker__month': {
      margin: '0.4em'
    },
    '& .react-datepicker__month .react-datepicker__month-text ': {
      width: '5rem',
      lineHeight: '3rem',
      margin: '2px 10px'
    },
    '& .react-datepicker__day-name, .react-datepicker__day': {
      width: '1.9em',
      lineHeight: '1.9em',
      margin: '0.166em'
    },
    '& .react-datepicker__current-month': {
      fontSize: '1em'
    },
    '& .react-datepicker__navigation': {
      top: '0.4em'
    },
    '& .react-datepicker-year-header': {
      fontSize: '1em'
    },
    '& .react-datepicker__navigation--previous': {
      borderRightColor: '#ccc',
      left: '1em'
    },
    '& .react-datepicker__navigation--next': {
      borderLeftColor: '#ccc',
      right: '1em'
    },
    '& .react-datepicker__month-text--keyboard-selected': {
      backgroundColor: COLORS.BLUE_GREEN
    },
    '& .react-datepicker__navigation-icon::before': {
      borderWidth: '2px 2px 0 0'
    },
    '& .react-datepicker__month-text.react-datepicker__month--selected:hover': {
      backgroundColor: COLORS.BLUE_GREEN,
      opacity: 0.85
    }
  }
});

interface MonthFormInputProps {
  styles: Styles;
  name: string;
  value: string;
  onChange: (payload: {
    target: { value: string };
    persist: () => void;
  }) => void;
}

interface CustomInputProps {
  onClick: () => void;
}

const MonthFormInput = ({
  styles: s,
  name,
  onChange,
  value
}: MonthFormInputProps) => {
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({
      target: { value: e.target.value },
      persist: _.noop
    });
  };

  const CustomInput = forwardRef(({ onClick }: CustomInputProps, ref) => (
    <TextInput
      controlled
      readOnly
      ref={ref}
      onClick={onClick}
      name={name}
      onChange={handleInputChange}
      value={getShortMonthWithFullYear(value)}
      suffix={<CalendarSvg />}
    />
  ));

  return (
    <div {...s('container')}>
      <DatePicker
        selected={new Date(value)}
        onChange={(date) => {
          onChange({
            target: { value: date.toString() },
            persist: _.noop
          });
        }}
        dateFormat="MM/dd/yyyy"
        showMonthYearPicker
        // @ts-expect-error document says it needs to be forwardRef and it throws an error if not
        customInput={<CustomInput />}
      />
    </div>
  );
};

export default styled(styles)(MonthFormInput);
