import { filter, uniqueId } from 'lodash';
import React, { createContext, FC, useReducer } from 'react';

export interface SnackbarMessage {
  id: string;
  content: string;
}

interface SnackbarState {
  messages: SnackbarMessage[];
}

interface SnackbarActions {
  addMessage: (content: string) => void;
  removeMessage: (id: SnackbarMessage['id']) => void;
}

const initialState: SnackbarState = {
  messages: []
};

const ACTIONS = {
  ADD_MESSAGE: 'ADD_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE'
};

const reducer = (
  state: SnackbarState,
  action: { type: string; payload: unknown }
): SnackbarState => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, payload as SnackbarMessage]
      };
    case ACTIONS.REMOVE_MESSAGE:
      return {
        ...state,
        messages: filter(
          state.messages,
          (message: SnackbarMessage) => message.id !== (payload as string)
        )
      };
    default:
      return state;
  }
};

export const SnackbarContext = createContext<SnackbarState & SnackbarActions>(
  null
);

const SnackbarProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    ...state,
    addMessage: (content: string) => {
      const id = uniqueId();
      dispatch({
        type: ACTIONS.ADD_MESSAGE,
        payload: {
          content,
          id
        }
      });
    },
    removeMessage: (id: string) => {
      dispatch({ type: ACTIONS.REMOVE_MESSAGE, payload: id });
    }
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
