import React, { PureComponent } from 'react';
import Button from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/theme';
import LoadingSpinner from '@rexlabs/loading-spinner';

const clearButtonStyles = StyleSheet({
  container: {
    background: 'white',
    color: COLORS.PRIMARY_DARK,
    height: '4.2rem',
    padding: '0 1.2rem',
    border: `1px solid ${COLORS.GREY.LIGHT}`,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    fontWeight: FONT.WEIGHTS.NORMAL,
    textDecoration: 'none'
  }
});

class ClearButton extends PureComponent {
  render() {
    return (
      <Button
        isInverted
        Loading={() => <LoadingSpinner colors={[COLORS.BLUE_GREEN]} />}
        styles={clearButtonStyles}
        {...this.props}
      />
    );
  }
}

export default ClearButton;
