import React from 'react';
import Box from '@rexlabs/box';
import SquareLegend from '../icons/square-legend';

const CustomTootip = ({ pathComponents, label, color }) => {
  return (
    <Box flex={1} alignItems="center" style={{ gap: 5 }}>
      <SquareLegend fill={color} />
      {`${pathComponents[1]} (${label})`}
    </Box>
  );
};

export default CustomTootip;
