import React, {
  useState,
  useContext,
  useLayoutEffect,
  FC,
  useCallback
} from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { setAuthToken } from './utils/api-client';
import LoadingLayout from './view/layouts/loading';
import { store } from './store';
import session from 'data/models/custom/session';
import { Permissions } from 'types/common';
import { intersection } from 'lodash';

interface DecodedJwtData {
  aud: string[];
  azp: string;
  email: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  permissions: Permissions['permissions'];
  scope: string;
  sub: string;
}

interface User {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  user_metadata?: {
    accepted_terms_v1: string;
    stripe_customer_id: string;
  };
}

interface Auth0ProviderState {
  isAuthenticated: boolean;
  user: null | User;
  permissions: string[];
  loading: boolean;
  popupOpen: boolean;
  guest: boolean;
}

interface Auth0ProviderActions {
  loginWithPopup: (options: PopupLoginOptions) => Promise<void>;
  handleRedirectCallback: () => Promise<void>;
  // This is small letter first because it is from @auth0/auth0-spa-js can't really change it
  getIdTokenClaims: (options: getIdTokenClaimsOptions) => Promise<IdToken>;
  loginWithRedirect: (options: RedirectLoginOptions) => Promise<void>;
  getTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>;
  getTokenWithPopup: (options: GetTokenWithPopupOptions) => Promise<string>;
  logout: (options: LogoutOptions) => void;
  hasPermission: (permissionsNeeded: string[]) => boolean;
}

interface Auth0ProviderProps extends Auth0ClientOptions {
  onRedirectCallback: (appState?: unknown) => void;
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<
  Auth0ProviderState & Auth0ProviderActions
>(null);
export const useAuth0 = () => useContext(Auth0Context);

export const Auth0Provider: FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<Auth0Client | null>(null);
  const [loading, setLoading] = useState(true);
  const [guest, setGuest] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);

  useLayoutEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      // Check if guest token
      if (window.location.search.includes('token=')) {
        const urlParams = new URLSearchParams(window.location.search);
        const guestToken = urlParams.get('token');
        setAuthToken(guestToken);
        setGuest(true);
        setIsAuthenticated(true);
        setLoading(false);
      } else {
        const isAuthenticated = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser();
          const token = await auth0FromHook.getTokenSilently();
          try {
            const jwtData = token.split('.')[1];
            const decodedJwtJsonData = window.atob(jwtData);
            const decodedJwtData = JSON.parse(
              decodedJwtJsonData
            ) as DecodedJwtData;
            setPermissions(decodedJwtData.permissions);
          } catch (error) {
            console.error(error);
            setPermissions([]);
          }
          setAuthToken(token);
          setUser(user);
          try {
            await store.dispatch(session.actionCreators.init({ token: token }));
          } catch (e) {
            setIsError(true);
            auth0FromHook.logout({
              federated: true,
              returnTo: window.location.origin
            });
          }
        }

        setLoading(false);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params: PopupLoginOptions = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    const token = await auth0Client.getTokenSilently();
    setUser(user);
    setAuthToken(token);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    const token = await auth0Client.getTokenSilently();
    setLoading(false);
    setAuthToken(token);
    setIsAuthenticated(true);
    setUser(user);
  };

  const logout = (options: LogoutOptions) => {
    auth0Client.logout({ ...options, federated: true });
    setAuthToken(null);
    setUser(null);
    setIsAuthenticated(null);
  };

  const hasPermission = useCallback(
    (permissionsNeeded: string[]) => {
      return (
        intersection(permissions, permissionsNeeded).length ===
        permissionsNeeded.length
      );
    },
    [permissions]
  );

  if (loading) {
    return <LoadingLayout />;
  }

  // Show nothing while logging out
  if (isError) {
    return null;
  }

  if (!isAuthenticated) {
    auth0Client.loginWithRedirect({
      redirect_uri: window.location.origin
    });
    return null;
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        guest,
        permissions,
        loginWithPopup,
        handleRedirectCallback,
        // This is small letter first because it is from @auth0/auth0-spa-js can't really change it
        getIdTokenClaims: (options: getIdTokenClaimsOptions) =>
          auth0Client.getIdTokenClaims(options),
        loginWithRedirect: (options: RedirectLoginOptions) =>
          auth0Client.loginWithRedirect(options),
        getTokenSilently: (options: GetTokenSilentlyOptions) =>
          auth0Client.getTokenSilently(options),
        getTokenWithPopup: (options: GetTokenWithPopupOptions) =>
          auth0Client.getTokenWithPopup(options),
        logout: (options: LogoutOptions) => logout(options),
        hasPermission
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
