import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      The tiles (squares) in Performance Overview highlight the key months end
      figures for the corridor.
      <br />
      <br />
      By <span className="bold">selecting a tile</span> you can change the data
      you are viewing. To smooth the chart trend – a{' '}
      <span className="bold">quarterly toggle</span> is available.{' '}
      <span style={{ fontStyle: 'italic' }}>
        Tile stats are static for the month selected.
      </span>
      <br />
      <br />
      <span className="bold">Available</span> means any allotment not yet sold.
      Those noted as deposited or on hold are not yet considered sold.
      <br />
      <br />
      The <span className="bold">average sales</span> figure adjusts for
      outliers by excluding projects with &lt;100 allotments remaining and those
      currently assessed as having low reliability.
    </>
  );
};

export default InfoTooltip;
