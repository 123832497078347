import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import { Heading, Body } from 'view/components/text';
import { Portal } from '@rexlabs/portal';
import Gradient from 'view/components/gradient';
import Divider from 'view/components/divider';
import ReportTitle from 'view/components/report-title';
import AllStockHeatMap from './all-stock-heat-map';
import LotSizeHeatMap from './lot-size-heat-map';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  }
});

@styled(defaultStyles)
class PricingComparison extends PureComponent {
  render() {
    const {
      styles: s,
      projects,
      period,
      primaryProject,
      stats,
      settings
    } = this.props;

    const allStats = _.map(
      _.get(stats, 'all_lot_size_stats'),
      (value, key) => ({
        ...{
          dimension: key
        },
        ...value
      })
    );

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle
            period={period}
            project={primaryProject}
            settings={settings}
          />
        </Portal>

        <Box {...s('sectionContainer')} style={{ padding: PADDINGS.S }}>
          <Heading>Colour / Pricing Legend</Heading>
          <Box style={{ height: '5.5rem ' }} mt={15} mb={15}>
            <Gradient />
          </Box>
          <Box flex={1} justifyContent={'space-between'}>
            <Body grey>Premium</Body>
            <Body grey>Affordable</Body>
          </Box>
        </Box>

        <Divider small />

        <LotSizeHeatMap
          projects={projects}
          primary={primaryProject}
          stats={stats}
          accessor={'available'}
        />
        <LotSizeHeatMap
          projects={projects}
          primary={primaryProject}
          stats={stats}
          accessor={'sold'}
        />

        <Divider small />

        <AllStockHeatMap
          stats={allStats}
          projects={projects}
          primary={primaryProject}
        />
      </Fragment>
    );
  }
}

export default PricingComparison;
