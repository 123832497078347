import React, { useCallback } from 'react';
import { CustomLayer } from '@nivo/line';
import { COLORS } from 'src/features/reports/theme';

const Balanced: CustomLayer = ({
  lineGenerator,
  points,
  yScale,
  innerWidth
}) => {
  // @ts-expect-error wrong type from nivo
  const getY = useCallback((y: number) => yScale(y), [yScale]);

  return (
    <>
      <path
        pointerEvents="none"
        d={lineGenerator([
          { x: 0, y: getY(3) },
          { x: innerWidth, y: getY(3) },
          { x: innerWidth, y: getY(6) },
          { x: 0, y: getY(6) }
        ])}
        fill={`${COLORS.GREY.MEDIUM}26`}
      />
      {points?.length > 0 && (
        <>
          <rect
            x="6"
            y={getY(6) + 7}
            width="70"
            height="17"
            fill={COLORS.WHITE}
            rx={4}
            style={{ filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08))' }}
          />
          <text
            pointerEvents="none"
            fill={COLORS.BLUE.DARK}
            fontSize="12px"
            fontWeight={500}
            fontFamily="DM Sans"
            transform={`translate(10, ${getY(6) + 20})`}
          >
            BALANCED
          </text>
        </>
      )}
    </>
  );
};

export default Balanced;
