import React, { PureComponent, Fragment } from 'react';
import { Grid } from '@rexlabs/grid';
import SectionColumn from 'view/components/grid/section-column';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Heading, SubHeading } from 'view/components/text';

const defaultStyles = StyleSheet({
  container: {
    padding: '2rem'
  },
  heading: {
    marginBottom: '2rem'
  },
  ul: {
    listStyleType: 'disc',
    marginLeft: '3rem',
    marginBottom: '2rem'
  }
});

@styled(defaultStyles)
class ChangeLogScreen extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return (
      <Fragment>
        <Grid gutter={0} columns={12}>
          <SectionColumn width={12}>
            <Box {...s('container')}>
              <Heading {...s('heading')}>Change Log</Heading>
              <SubHeading>15 May 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Add sub-region to project information tab</li>
              </ul>

              <SubHeading>14 May 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Update worksheet period info on update worksheet</li>
                <li>Fix update period worksheet updating to the wrong date</li>
                <li>Fix project form not formatting numbers on submit</li>
                <li>
                  Allotments sold to date should now include sales volume offset
                </li>
                <li>Update &apos;Edit&apos; buttons to &apos;Save&apos;</li>
                <li>Copy from last now converts BOM to Available</li>
                <li>
                  Fix issue where copy from last will copy the wrong worksheet
                </li>
                <li>Update price change functionality</li>
              </ul>

              <SubHeading>1 May 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Add navigation footer to reports</li>
                <li>Copy from last sheet no longer copies sold lots</li>
                <li>Fix issue where $/m2 was sometimes not calculating</li>
                <li>
                  Fix Ave. Sold Price vs Ave. Sold Size displaying incorrectly
                  if all values are the same
                </li>
              </ul>

              <SubHeading>29 April 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Add Sub-Regions</li>
                <li>Fix lot validation</li>
                <li>Fix worksheet status update</li>
                <li>Fix worksheet calendar switcher</li>
                <li>Fix project date validations</li>
                <li>Add more descriptive error messages</li>
              </ul>

              <SubHeading>4 April 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Add lot list ordering</li>
              </ul>

              <SubHeading>2 April 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Fix depth auto calculations</li>
                <li>Add depth override warning</li>
                <li>Disable $/m2 override</li>
                <li>Fix bug that was breaking some form validations</li>
                <li>Fix update worksheet period</li>
                <li>Fix month select not highlighting the correct month</li>
                <li>Fix create worksheet from month select</li>
                <li>Make stage titles required</li>
                <li>
                  Lot incentives & negotiation buffer should correctly default
                  to 0
                </li>
                <li>Remove estimated date</li>
                <li>Remove Exp List Price fields</li>
                <li>Add price change auto calculation</li>
                <li>Enforce lot size validation</li>
                <li>Add mass edit lots</li>
                <li>Add new lot dot on lot list</li>
              </ul>

              <SubHeading>13 March 2019</SubHeading>
              <ul {...s('ul')}>
                <li>
                  Add background colour calculations to sold price vs sold size
                  table
                </li>
              </ul>

              <SubHeading>12 March 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Format sold price vs sold size table values</li>
                <li>Fix quarterly & yearly market share calculations</li>
              </ul>

              <SubHeading>11 March 2019</SubHeading>
              <ul {...s('ul')}>
                <li>
                  Correct gross sales for month, net sales for month, &
                  outstanding deposits on view worksheet screen
                </li>
                <li>Round large decimals</li>
                <li>Fix formatting of lot availability graph</li>
                <li>
                  Fall back to stage number if there is no stage title in lot
                  availability stock level table
                </li>
              </ul>

              <SubHeading>07 March 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Fix &apos;load more&apos; buttons</li>
                <li>Improve performance on view worksheet screen</li>
                <li>Fix iOS scrolling on lot availability table</li>
              </ul>

              <SubHeading>06 March 2019</SubHeading>
              <ul {...s('ul')}>
                <li>
                  Copy from last worksheet now only copies the last worksheet
                  with a price list
                </li>
                <li>
                  Overview graphs over time now show the full 12 month period
                </li>
                <li>
                  Report settings now only show periods & projects with
                  published worksheets
                </li>
                <li>Report calculation tweaks and fixes</li>
                <li>Add loading screen to report settings</li>
              </ul>

              <SubHeading>05 March 2019</SubHeading>
              <ul {...s('ul')}>
                <li>
                  Display &apos;-&apos; as stage title in pricelist report when
                  no stage title is set
                </li>
                <li>Fix entry level price formatting on availablity report</li>
                <li>Fix deposts not showing in pricelist report</li>
                <li>Update report worksheet & listing scopes</li>
                <li>Fix scrolling on report lists on iOS devices</li>
                <li>Make report sidebar hideable</li>
                <li>Fix modals displaying incorrectly on iOS devices</li>
                <li>Update size price bracket chart margins</li>
                <li>Add legend to sales performance chart</li>
                <li>
                  Fix issue where pressing enter in a form will close the form
                  instead of submitting
                </li>
                <li>Fix issue where stage data won&apos;t be saved</li>
              </ul>

              <SubHeading>28 Febuary 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Disable editing of published worksheets/stages/lots</li>
              </ul>

              <SubHeading>27 Febuary 2019</SubHeading>
              <ul {...s('ul')}>
                <li>
                  Refresh worksheet stats & related stage lots when a new
                  worksheet is added
                </li>
                <li>
                  Create worksheet from last sheet now also copies the empty
                  stages
                </li>
                <li>
                  Fix issue where disabling copy from last sheet didn&apos;t
                  work
                </li>
                <li>Fix stage number not displaying on lot list</li>
                <li>Fix view period worksheets select boxes not selecting</li>
                <li>
                  Don&apos;t allow copy from last sheet & no price list recieved
                  to be both ticked on create worksheet screen
                </li>
              </ul>

              <SubHeading>26 Febuary 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Fix worksheet form date validation</li>
                <li>Fix create missing worksheet submission</li>
                <li>Update lot notes to 255 characters</li>
                <li>Fix number formatting on edit project form</li>
                <li>Round depth auto calculation up</li>
                <li>Add duplicate lot option in lot list action menu</li>
              </ul>

              <SubHeading>25 Febuary 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Add new hideable side menu</li>
                <li>
                  New worksheet form now has a dedicated period select (month
                  select) and estimated date select (date select)
                </li>
                <li>
                  Copy from last sheet is now a checkbox on the button bar
                  instead of a button
                </li>
                <li>Fixed delete lots function</li>
                <li>Numeric fields in the lot form will now save correctly</li>
                <li>Improved error checking on form</li>
                <li>
                  Changed select menus to be scrollable to allow more than 5
                  options
                </li>
                <li>Fix project creation</li>
                <li>Truncate long project names in project list</li>
                <li>
                  Fix issue causing archive & trash date to display as
                  19/01/1970 on project list
                </li>
                <li>Form submission bar is now always visible</li>
                <li>
                  Project form & lot form should now scroll to any form
                  validation errors
                </li>
                <li>
                  Multiple users can now login under the same email at once
                </li>
              </ul>

              <SubHeading>11 Febuary 2019</SubHeading>
              <ul {...s('ul')}>
                <li>
                  &apos;Copy from Last Worksheet&apos; now copies all listings &
                  stages
                </li>
                <li>
                  Fix &apos;Copy from Last Worksheet&apos; breaking on empty
                  notes
                </li>
                <li>Radio boxes are now circles</li>
              </ul>

              <SubHeading>8 Febuary 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Add project summary to create/edit project form</li>
                <li>Fix project summary not showing on project list</li>
                <li>Add lot availability report</li>
                <li>Add definition report</li>
                <li>Add pricelist report</li>
                <li>Add detailed stats report</li>
                <li>Stat fixes on overview report</li>
                <li>Stat fixes on pricing comparison report</li>
                <li>Stat fixes on Size-price bracket report</li>
              </ul>

              <SubHeading>17 January 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Fix account id bug preventing project creation</li>
                <li>
                  Add account switcher when multiple accounts are available
                </li>
                <li>Add Size-price bracket report</li>
              </ul>

              <SubHeading>9 January 2019</SubHeading>
              <ul {...s('ul')}>
                <li>Update create project form range labels</li>
                <li>
                  Move set total allotment input & sales volume offset input to
                  Area section
                </li>
                <li>Add report select screen</li>
                <li>Add overview report</li>
                <li>Add pricing comparison report</li>
              </ul>

              <SubHeading>7 December 2018</SubHeading>
              <ul {...s('ul')}>
                <li>Enable delete selected stages button</li>
                <li>Add project date range validation</li>
                <li>Fix view projects worksheet list performance issue</li>
              </ul>

              <SubHeading>6 December 2018</SubHeading>
              <ul {...s('ul')}>
                <li>Update click area on lot form checkboxes</li>
                <li>Update click area on worksheet form checkboxes</li>
                <li>Display - for empty values on view worksheet</li>
                <li>Add loading state to view period worksheets</li>
                <li>Fix lot list select all checkbox</li>
                <li>Fix Stage does not exist error</li>
                <li>Update form validation</li>
                <li>Add greater than 0 validation on numerical fields</li>
                <li>Redirect to project on project create</li>
              </ul>

              <SubHeading>5 December 2018</SubHeading>
              <ul {...s('ul')}>
                <li>
                  Remove copy from last worksheet button from edit worksheet
                  form
                </li>
                <li>Update margins on all forms</li>
                <li>Add heading to lot form</li>
                <li>Update checkbox cursor</li>
                <li>Update stage no to text</li>
              </ul>

              <SubHeading>4 December 2018</SubHeading>
              <ul {...s('ul')}>
                <li>Form buttons now show loading spinner while submitting</li>
                <li>Value fixes to create project form</li>
                <li>Lots form auto calculation improvements</li>
                <li>Enable get last worksheet button on new worksheet form</li>
                <li>Remove headers from lists with no items</li>
                <li>Add loading state to Add Missing Worksheet modal</li>
                <li>Fix date select not pre-filling on update forms</li>
                <li>Update required fiends on stage form</li>
                <li>Stage form now auto focus&apos; stage number</li>
                <li>Update incorrect form mappings</li>
                <li>Enable delete worksheets on period view</li>
              </ul>

              <SubHeading>30 November 2018</SubHeading>
              <ul {...s('ul')}>
                <li>Added edit lot functionality</li>
                <li>Added click to edit on lots list</li>
                <li>
                  Updated worksheets list to order by period (latest first)
                </li>
                <li>Update lists click hitboxes</li>
              </ul>

              <SubHeading>29 November 2018</SubHeading>
              <ul {...s('ul')}>
                <li>Added ordering to the lots list</li>
                <li>Updated validation on the create list method</li>
                <li>
                  Updated the project list click hit boxes to cover the entire
                  list, not just the main text part
                </li>
                <li>
                  Updated modal paddings to be more consistent with each other
                </li>
                <li>Added a tick onto the checkboxes</li>
                <li>Fixed a couple errors on the store project method</li>
                <li>Fix update projects to work</li>
                <li>Fix formatting issue on stage list</li>
                <li>Add update stage</li>
              </ul>

              <SubHeading>28 November 2018</SubHeading>
              <ul {...s('ul')}>
                <li>Add Lot List</li>
                <li>Add incorrect login modal</li>
                <li>
                  Fix issue causing copy paste username/password not to work
                </li>
                <li>Fix for auto formatting & auto calculations</li>
                <li>Update incorrect required fields on new lot form</li>
                <li>Remove text suggestions from text boxes</li>
                <li>Fix add stage button on lot list</li>
                <li>Add add stage button on stage list</li>
                <li>Refresh lot list on new lot form submit</li>
                <li>Fix number formatting on average value stats</li>
                <li>Add related lots number to stage list</li>
                <li>Add change log</li>
              </ul>
            </Box>
          </SectionColumn>
        </Grid>
      </Fragment>
    );
  }
}

export default ChangeLogScreen;
