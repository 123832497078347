import React, {
  FormEventHandler,
  useContext,
  useEffect,
  useState
} from 'react';
import Box from '@rexlabs/box';
import { keyframes, styled, Styles, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { Portal } from '@rexlabs/portal';
import { COLORS, FONT } from 'src/features/reports/theme';
import { TextInput } from '../inputs';
import { DefaultButton, PrimaryButton } from '../button';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import withError from 'view/containers/with-error';
import { compose } from 'utils/compose';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import reportModel from 'src/features/reports/models/reports';
import { ReportModel } from 'types/models/report';
import useRouteConfig from 'src/features/reports/hooks/use-route-config';
import { Whereabouts } from 'types/common';
import { Report } from 'types/resource';

const fadeIn = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opactity: 1
  }
});

const styles = StyleSheet({
  title: {
    fontSize: 28,
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 700,
    lineHeight: '35px',
    color: COLORS.BLACK,
    marginBottom: 34
  },
  save: {
    width: 84,
    marginLeft: 16
  },
  cancel: {
    width: 101
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 320,
    animation: `${fadeIn} 0.3s ease-in-out`
  },
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: '4px'
  }
});

interface SaveReportProps {
  styles: Styles;
  onHide: () => void;
  errorModal: WithErrorModalProps;
  reportModel: ReportModel;
  whereabouts: Whereabouts<Record<string, string>>;
  // Following props are for load report dialog
  editReport?: Report;
  onEdit?: (newName: string) => Promise<void>;
}

const SaveReport = ({
  styles: s,
  errorModal: { open: openError },
  reportModel,
  whereabouts,
  editReport,
  onEdit,
  onHide
}: SaveReportProps) => {
  const { updateParams } = useRouteConfig<Record<string, string>>({
    whereabouts
  });
  const { addReport, updateReport } = reportModel;
  const {
    selectedReport,
    setSelectedReport,
    selectedProjectsInSubRegion,
    subRegionWithAllProjectsSelected,
    advanceFilters
  } = useContext(ReportSettingsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  const onSave: FormEventHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (editReport && onEdit) {
      await onEdit(name);
      setIsLoading(false);
      return;
    }

    try {
      if (selectedReport) {
        const res = await updateReport({
          queryParams: { name, id: selectedReport.id }
        });
        setSelectedReport(res.data);
      } else {
        const res = await addReport({
          queryParams: {
            name,
            settings: JSON.stringify({
              selectedProjectsInSubRegion,
              subRegionWithAllProjectsSelected,
              advanceFilters
            })
          }
        });
        updateParams({
          saved: res.data.id,
          project_ids: undefined,
          sub_region_with_all_projects_selected: undefined,
          regions: undefined,
          state: undefined,
          developer: undefined,
          suburb: undefined,
          local_government_area: undefined
        });
        setSelectedReport(res.data);
      }
      onHide();
    } catch (error) {
      openError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (editReport) {
      setName(editReport.name);
      return;
    }

    if (selectedReport) {
      setName(selectedReport.name);
    }
  }, [selectedReport, editReport]);

  return (
    <Portal target="custom-dialogs">
      <div {...s('backdrop')}>
        <Box p="24px" width="387px" {...s('container')}>
          <h3 {...s('title')}>
            {selectedReport || editReport ? 'Edit Saved Report' : 'Save Report'}
          </h3>
          <form onSubmit={onSave}>
            <TextInput
              placeholder="Report Name"
              value={name}
              onChange={(e) => setName((e.target as HTMLInputElement).value)}
            />
            <Box
              width="100%"
              flexDirection="row"
              justifyContent="flex-end"
              mt="47px"
            >
              <DefaultButton {...s('cancel')} onClick={onHide} type="button">
                Cancel
              </DefaultButton>
              <PrimaryButton
                {...s('save')}
                isLoading={isLoading}
                isDisabled={isLoading}
                color={COLORS.BLUE.DARK}
                type="submit"
              >
                Save
              </PrimaryButton>
            </Box>
          </form>
        </Box>
      </div>
    </Portal>
  );
};

export default compose<
  Omit<SaveReportProps, 'errorModal' | 'reportModel' | 'styles' | 'whereabouts'>
>(
  styled(styles),
  withError.withPropName('errorModal'),
  withModel(reportModel),
  withWhereabouts
)(SaveReport);
