import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { CustomLayer, Point } from '@nivo/line';
import { COLORS } from 'src/features/reports/theme';

const Circle = ({ x, y, serieColor }: Point) => {
  const animatedProps = useSpring({
    transform: `translate(${x}, ${y})`
  });

  return (
    <animated.g transform={animatedProps.transform} pointerEvents="none">
      <circle
        r={5}
        fill="#fff"
        stroke={serieColor}
        strokeWidth={2}
        pointerEvents="none"
      />
    </animated.g>
  );
};

// Points for second set of data
const SolidCirclePoints: CustomLayer = ({ points }) => {
  return points.map(
    (point) =>
      point.serieColor === COLORS.PINK.LIGHT && (
        <Circle key={point.id} {...point} />
      )
  );
};

export default SolidCirclePoints;
