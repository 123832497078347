import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext
} from 'react';
import config from 'config';
import Box from '@rexlabs/box';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { buildQueryString } from '@rexlabs/whereabouts';
import { Tiny } from 'view/components/text';
import CloseIcon from 'src/features/reports/components/icons/close-icon';
import { PADDINGS } from 'src/features/reports/theme';
import Modal from './modal';
import { DefaultButton } from '../button';
import { GlobeMapIcon } from '../icons';
import { compose } from 'utils/compose';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { isEmpty } from 'lodash';
import withError from 'view/containers/with-error';
import { Whereabouts } from 'types/common';
import { ReportParams } from 'types/params';
import useRouteConfig from 'src/features/reports/hooks/use-route-config';

const mapViewStyles = StyleSheet({
  title: {
    fontSize: '20px !important',
    fontFamily: 'DM Sans'
  },
  closeIcon: {
    cursor: 'pointer'
  },
  mapFrame: {
    height: '80vh',
    overflow: 'hidden'
  }
});

interface MapViewModalProps {
  styles: Styles;
  errorModal: WithErrorModalProps;
  whereabouts: Whereabouts<ReportParams>;
}

interface PostMessageResponse {
  subRegion: {
    id: number;
    properties: {
      name: string;
      parent: string; // North, East, South, West
      status: string;
    };
  };
}

interface PostMessageEventProps {
  origin: string;
  data: string; // parseable to PostMessageResponse
}

// In the case that we need a different map url,
// we may need to consider passing the url as prop.
const MapViewModal = ({
  styles: s,
  errorModal: { open: openError },
  whereabouts
}: MapViewModalProps) => {
  const { updateParams } = useRouteConfig<ReportParams>({ whereabouts });
  const [mapViewOpen, setMapViewOpen] = useState<boolean>(false);
  const {
    standardSubRegions,
    premiumSubRegions,
    setSelectedProjectsInSubRegion,
    setSelectedReport,
    setSubRegionWithAllProjectsSelected
  } = useContext(ReportSettingsContext);

  const regionProjects = useMemo(() => {
    return [...standardSubRegions, ...premiumSubRegions]
      .filter((region) => region.projects.length > 0)
      .map((region) => {
        const mappedProjects = region.projects.map((p) => ({
          name: p.title,
          key: p.id
        }));

        return {
          name: region.name,
          key: region.id,
          projects: mappedProjects
        };
      });
  }, [standardSubRegions, premiumSubRegions]);

  const handleMessage = useCallback(
    ({ origin, data }: PostMessageEventProps) => {
      try {
        if (origin === config.MAP_URL) {
          console.log('Received data from map', data);
          if (isEmpty(data)) return;

          const parsedData = JSON.parse(data) as PostMessageResponse;
          const subRegionName = parsedData.subRegion.properties.name;
          const subRegion = regionProjects.find(
            (r) => r.name === subRegionName
          );

          if (subRegion) {
            const newSelectedRegionProjects = {
              [subRegion.key]: subRegion.projects.map((project) => project.key)
            };

            setSelectedProjectsInSubRegion(newSelectedRegionProjects);
            setSubRegionWithAllProjectsSelected([subRegion.key]);
            setMapViewOpen(false);

            // Remove selected report when changing selected project ids
            updateParams({
              project_ids: buildQueryString(newSelectedRegionProjects),
              sub_region_with_all_projects_selected: [subRegion.key],
              saved: undefined
            });
            setSelectedReport(null);
            return;
          }

          openError('This sub region is not available.', 'Oops..', null);
        }
      } catch (e) {
        if (typeof e === 'string') {
          openError(e, 'Oops..', null);
        } else if (e instanceof Error) {
          openError(e.message, 'Oops..', null);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionProjects, setSelectedProjectsInSubRegion]
  );

  useEffect(() => {
    if (mapViewOpen) {
      window.addEventListener('message', handleMessage);
    } else window.removeEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  return (
    <>
      <Box onClick={() => setMapViewOpen(true)}>
        <DefaultButton selected={mapViewOpen}>
          <Box flexDirection="row" alignItems="center">
            <span style={{ marginRight: '12px' }}>Map View</span>
            <GlobeMapIcon />
          </Box>
        </DefaultButton>
      </Box>
      {mapViewOpen ? (
        <Modal width="85vw">
          <Box
            justifyContent="space-between"
            alignItems="center"
            padding={PADDINGS.XS}
            height={60}
          >
            <Tiny {...s('title')}>Select a subregion to run a report</Tiny>
            <CloseIcon
              onClick={() => setMapViewOpen(false)}
              {...s('closeIcon')}
            />
          </Box>

          <Box {...s('mapFrame')}>
            <iframe
              src={`${config.MAP_URL}/index.html?embed=true`}
              width="100%"
              height="100%"
            />
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default compose(
  withWhereabouts,
  styled(mapViewStyles),
  withError.withPropName('errorModal')
)(MapViewModal);
