import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import { HorizontalActionMenu } from 'view/components/action-menu';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import _ from 'lodash';
import { FONT } from 'src/theme';
import { Modal } from 'view/components/modal';
import EditProjectForm from 'view/forms/project-new';
import withError from 'view/containers/with-error';

import ActionModal from '../../modals/action';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500'
  },
  truncate: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  status: {
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  }
});

@withError.withPropName('errorModal')
@styled(defaultStyles)
class ProjectListItem extends PureComponent {
  state = {
    archiveModal: false,
    editModal: false
  };

  formatDate = (createdAt) => {
    const date = new Date(createdAt * 1000);
    return `${date.getDate()} ${date.toLocaleString('en-us', {
      month: 'long'
    })} ${date.getFullYear()}`;
  };

  showArchive = () => {
    this.setState({
      archiveModal: true
    });
  };

  showEdit = () => {
    this.setState({
      editModal: true
    });
  };

  archive = () => {
    const { project, projects, errorModal } = this.props;

    projects
      .archive({
        id: project.id
      })
      .then(() => {
        this.setState({
          archiveModal: false
        });
        this.props.projects.refreshList();
      })
      .catch((error) => {
        errorModal.open(error);
        this.props.projects.refreshList();
      });
  };

  view = (project) => {
    push(ROUTES.APP.PROJECT, {
      params: {
        projectId: project.id
      }
    });
  };

  render() {
    const { styles: s, project, toggle, checked } = this.props;
    return (
      <Box {...s('container')}>
        {this.state.archiveModal && (
          <ActionModal
            onClose={() => this.setState({ archiveModal: false })}
            onSubmit={this.archive}
            title={'Archive Project Listing(s)?'}
            submitText="Archive"
          >
            Are you sure you want to archive this Project Listing(s) and all
            associated worksheets?
          </ActionModal>
        )}
        {this.state.editModal && (
          <Modal>
            <EditProjectForm
              title="Edit Project"
              closeModal={() => this.setState({ editModal: false })}
              project={project}
            />
          </Modal>
        )}
        <Grid
          gutter={0}
          columns={24}
          style={{ alignItems: 'center' }}
          onClick={() => this.view(project)}
          {...s('grid')}
        >
          <Column
            width={1}
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
            {...s('checkbox')}
          >
            <Checkbox controlled={true} value={!!checked} />
          </Column>
          <Column width={4}>
            <Small {...s('text', 'truncate')}>{project.title}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text', 'truncate')}>
              {_.get(project, 'sub_region.name')}
            </Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{this.formatDate(project.created_at)}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('status')}>
              {_.get(project, 'status').split('_').join(' ')}
            </Small>
          </Column>
          <Column width={6}>
            <Small {...s('text', 'truncate')}>
              {_.get(project, 'summary')}
            </Small>
          </Column>
          <Column width={1} onClick={(e) => e.stopPropagation()}>
            <HorizontalActionMenu
              items={[
                {
                  label: 'View',
                  onClick: () => this.view(project)
                },
                {
                  label: 'Edit',
                  onClick: this.showEdit
                },
                {
                  label: 'Archive',
                  onClick: this.showArchive
                }
              ]}
            />
          </Column>
        </Grid>
      </Box>
    );
  }
}

export default ProjectListItem;
