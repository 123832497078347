import React, { Fragment, PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';
import Sidebar from 'view/components/report-sidebar';
import SettingSidebar from 'view/components/report-settings-sidebar';
import { animated } from 'react-spring';
import { Spring } from 'react-spring/renderprops';

import ToggleSvg from 'assets/hamburger.svg';
import { Portal } from '@rexlabs/portal';

const defaultStyles = StyleSheet({
  container: {
    display: 'initial',
    overflow: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: COLORS.BACKGROUND
  },

  wrapSidebar: {
    position: 'absolute',
    // left: 0,
    top: 200,
    bottom: 0,
    width: '25rem',
    background: COLORS.WHITE,
    padding: PADDINGS.S
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  header: {
    display: 'flex',
    height: '20rem',
    background: COLORS.PRIMARY_DARK,
    width: '100%',
    alignItems: 'center'
  },

  footer: {
    height: '25rem',
    background: COLORS.BLUE_GREEN,
    width: '100%',
    alignItems: 'center'
  },

  wrapContent: {
    overflow: 'auto',
    justifyContent: 'center',
    position: 'relative',
    padding: `0 ${PADDINGS.S}`
  },

  content: {
    width: '100%'
  },

  created: {
    marginTop: PADDINGS.S,
    paddingTop: PADDINGS.XXS,
    borderTop: `1px solid ${COLORS.GREY.MEDIUM}`,
    color: COLORS.GREY.MEDIUM
  },

  wrapToggle: {
    cursor: 'pointer',
    margin: `0 ${PADDINGS.S}`,
    height: 40,
    width: 40,
    ':hover': {
      opacity: '0.6'
    }
  },

  link: {
    lineHeight: 0
  }
});

export default function sideBar(settings = true, maxWidth = '120rem') {
  return function (Child) {
    return styled(defaultStyles)(
      class extends PureComponent {
        state = {
          hidden: window.innerWidth <= 1366
        };

        toggleSidebar = () => {
          const { hidden } = this.state;
          this.setState({ hidden: !hidden });
        };

        render() {
          const { hidden } = this.state;
          const { styles: s } = this.props;
          return (
            <Fragment>
              <Portal target={'report-sidebar-button'}>
                <ToggleSvg onClick={this.toggleSidebar} />
              </Portal>
              <Spring
                force
                config={{ tension: 2000, friction: 100, precision: 1 }}
                from={{
                  paddingLeft: hidden ? 0 : -250,
                  left: hidden ? 250 : 0
                }}
                to={{
                  paddingLeft: hidden ? -250 : 0,
                  left: hidden ? 0 : 250
                }}
              >
                {(springStyle) => (
                  <Box flex={1} flexDirection="row">
                    <animated.div
                      {...s('wrapSidebar')}
                      style={{ left: `${springStyle.paddingLeft}px` }}
                    >
                      {settings ? (
                        <SettingSidebar {...this.props} />
                      ) : (
                        <Sidebar {...this.props} />
                      )}
                    </animated.div>
                    <animated.div
                      {...s('main')}
                      style={{ paddingLeft: `${springStyle.left}px` }}
                    >
                      <Box {...s('wrapContent')} flex={1}>
                        <Box
                          {...s.with('content')({
                            maxWidth: maxWidth
                          })}
                        >
                          <Child {...this.props} />
                        </Box>
                      </Box>
                    </animated.div>
                  </Box>
                )}
              </Spring>
            </Fragment>
          );
        }
      }
    );
  };
}
