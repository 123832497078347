/* eslint-disable max-lines */
import {
  LotTypeList,
  ProductPriceMovementReport,
  ProductTypePerformanceReport,
  MarketShareDatum
} from 'types/graph';

export const fetchLotTypesMock = (): Promise<{
  data: LotTypeList;
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          id: '12.5x28',
          lot_type: '12.5m x 28m',
          number_of_lots_sold: '172 lots sold'
        },
        {
          id: '12.5x32',
          lot_type: '12.5m x 32m',
          number_of_lots_sold: '113 lots sold'
        },
        {
          id: '14x28',
          lot_type: '14m x 28m',
          number_of_lots_sold: '85 lots sold'
        },
        {
          id: '14x32',
          lot_type: '14m x 32m',
          number_of_lots_sold: '80 lots sold'
        },
        {
          id: '12.5x25',
          lot_type: '12.5m x 25m',
          number_of_lots_sold: '69 lots sold'
        },
        {
          id: '16x32',
          lot_type: '16m x 32m',
          number_of_lots_sold: '46 lots sold'
        },
        {
          id: '10.5x21',
          lot_type: '10.5m x 21m',
          number_of_lots_sold: '42 lots sold'
        },
        {
          id: '10.5x28',
          lot_type: '10.5m x 28m',
          number_of_lots_sold: '32 lots sold'
        },
        {
          id: '12.5x21',
          lot_type: '12.5m x 21m',
          number_of_lots_sold: '32 lots sold'
        },
        {
          id: '8.5x28',
          lot_type: '8.5m x 28m',
          number_of_lots_sold: '25 lots sold'
        },
        {
          id: '14x25',
          lot_type: '14m x 25m',
          number_of_lots_sold: '24 lots sold'
        },
        {
          id: '8.5x32',
          lot_type: '8.5m x 32m',
          number_of_lots_sold: '21 lots sold'
        },
        {
          id: '10.5x32',
          lot_type: '10.5m x 32m',
          number_of_lots_sold: '19 lots sold'
        },
        {
          id: '10.5x25',
          lot_type: '10.5m x 25m',
          number_of_lots_sold: '17 lots sold'
        },
        {
          id: '14x21',
          lot_type: '14m x 21m',
          number_of_lots_sold: '16 lots sold'
        },
        {
          id: '16x28',
          lot_type: '16m x 28m',
          number_of_lots_sold: '11 lots sold'
        },
        {
          id: '16x25',
          lot_type: '16m x 25m',
          number_of_lots_sold: '8 lots sold'
        },
        {
          id: '8.5x25',
          lot_type: '8.5m x 25m',
          number_of_lots_sold: '8 lots sold'
        },
        {
          id: '16x30',
          lot_type: '16m x 30m',
          number_of_lots_sold: '4 lots sold'
        },
        {
          id: '12.5x30',
          lot_type: '12.5m x 30m',
          number_of_lots_sold: '3 lots sold'
        },
        {
          id: '8.5x30',
          lot_type: '8.5m x 30m',
          number_of_lots_sold: '3 lots sold'
        },
        {
          id: '10.5x30',
          lot_type: '10.5m x 30m',
          number_of_lots_sold: '3 lots sold'
        },
        {
          id: '8.5x21',
          lot_type: '8.5m x 21m',
          number_of_lots_sold: '3 lots sold'
        },
        {
          id: '16x21',
          lot_type: '16m x 21m',
          number_of_lots_sold: '1 lots sold'
        },
        {
          id: '14x30',
          lot_type: '14m x 30m',
          number_of_lots_sold: '1 lots sold'
        }
      ]
    });
  });
};

export const fetchProductPriceMovementMock = (): Promise<{
  data: ProductPriceMovementReport;
}> => {
  return new Promise((resolve) => {
    resolve({
      data: {
        monthly: {
          all: {
            '2017-03-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-04-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-05-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-06-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-07-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-08-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-09-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-10-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-11-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-12-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-01-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-02-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-03-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-04-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-05-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-06-01': {
              average: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              },
              median: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              }
            },
            '2018-07-01': {
              average: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            '2018-08-01': {
              average: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            '2018-09-01': {
              average: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            '2018-10-01': {
              average: {
                price: 301750,
                price_incentives: 301750,
                price_buffer: 301750,
                price_incentives_buffer: 301750
              },
              median: {
                price: 301750,
                price_incentives: 301750,
                price_buffer: 301750,
                price_incentives_buffer: 301750
              }
            },
            '2018-11-01': {
              average: {
                price: 303142.85714285716,
                price_incentives: 303142.85714285716,
                price_buffer: 303142.85714285716,
                price_incentives_buffer: 303142.85714285716
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            },
            '2018-12-01': {
              average: {
                price: 303142.85714285716,
                price_incentives: 303142.85714285716,
                price_buffer: 303142.85714285716,
                price_incentives_buffer: 303142.85714285716
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            },
            '2019-01-01': {
              average: {
                price: 308416.6666666667,
                price_incentives: 308416.6666666667,
                price_buffer: 308416.6666666667,
                price_incentives_buffer: 308416.6666666667
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            },
            '2019-02-01': {
              average: {
                price: 305750,
                price_incentives: 305750,
                price_buffer: 305750,
                price_incentives_buffer: 305750
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            }
          },
          available: {
            '2017-03-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-04-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-05-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-06-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-07-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-08-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-09-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-10-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-11-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-12-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-01-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-02-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-03-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-04-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-05-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-06-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-07-01': {
              average: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            '2018-08-01': {
              average: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            '2018-09-01': {
              average: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            '2018-10-01': {
              average: {
                price: 301750,
                price_incentives: 301750,
                price_buffer: 301750,
                price_incentives_buffer: 301750
              },
              median: {
                price: 301750,
                price_incentives: 301750,
                price_buffer: 301750,
                price_incentives_buffer: 301750
              }
            },
            '2018-11-01': {
              average: {
                price: 303142.85714285716,
                price_incentives: 303142.85714285716,
                price_buffer: 303142.85714285716,
                price_incentives_buffer: 303142.85714285716
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            },
            '2018-12-01': {
              average: {
                price: 308416.6666666667,
                price_incentives: 308416.6666666667,
                price_buffer: 308416.6666666667,
                price_incentives_buffer: 308416.6666666667
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            },
            '2019-01-01': {
              average: {
                price: 311833.3333333333,
                price_incentives: 311833.3333333333,
                price_buffer: 311833.3333333333,
                price_incentives_buffer: 311833.3333333333
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            '2019-02-01': {
              average: {
                price: 305750,
                price_incentives: 305750,
                price_buffer: 305750,
                price_incentives_buffer: 305750
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            }
          },
          sold: {
            '2017-03-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-04-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-05-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-06-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-07-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-08-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-09-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-10-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-11-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2017-12-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-01-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-02-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-03-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-04-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-05-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-06-01': {
              average: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              },
              median: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              }
            },
            '2018-07-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-08-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-09-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-10-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-11-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            '2018-12-01': {
              average: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              },
              median: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              }
            },
            '2019-01-01': {
              average: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            },
            '2019-02-01': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            }
          }
        },
        quarterly: {
          all: {
            'Q2 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q3 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q4 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q1 2018': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q2 2018': {
              average: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              },
              median: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              }
            },
            'Q3 2018': {
              average: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            'Q4 2018': {
              average: {
                price: 302833.3333333333,
                price_incentives: 302833.3333333333,
                price_buffer: 302833.3333333333,
                price_incentives_buffer: 302833.3333333333
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            }
          },
          available: {
            'Q2 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q3 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q4 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q1 2018': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q2 2018': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q3 2018': {
              average: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              },
              median: {
                price: 332000,
                price_incentives: 332000,
                price_buffer: 332000,
                price_incentives_buffer: 332000
              }
            },
            'Q4 2018': {
              average: {
                price: 304676.4705882353,
                price_incentives: 304676.4705882353,
                price_buffer: 304676.4705882353,
                price_incentives_buffer: 304676.4705882353
              },
              median: {
                price: 305000,
                price_incentives: 305000,
                price_buffer: 305000,
                price_incentives_buffer: 305000
              }
            }
          },
          sold: {
            'Q2 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q3 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q4 2017': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q1 2018': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q2 2018': {
              average: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              },
              median: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              }
            },
            'Q3 2018': {
              average: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              },
              median: {
                price: 0,
                price_incentives: 0,
                price_buffer: 0,
                price_incentives_buffer: 0
              }
            },
            'Q4 2018': {
              average: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              },
              median: {
                price: 271500,
                price_incentives: 271500,
                price_buffer: 271500,
                price_incentives_buffer: 271500
              }
            }
          }
        }
      }
    });
  });
};

export const fetchProductTypePerformanceMock = (): Promise<{
  data: ProductTypePerformanceReport[];
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          dimension: '12.5x28',
          median_price: 278000,
          sold_count: 37,
          time_on_market: 5.2,
          market_share: 8.1
        },
        {
          dimension: '14x32',
          median_price: 328500,
          sold_count: 6,
          time_on_market: 1.5,
          market_share: 27.3
        },
        {
          dimension: '12.5x25',
          median_price: 237000,
          sold_count: 4,
          time_on_market: 1.7,
          market_share: 42.9
        },
        {
          dimension: '10.5x25',
          median_price: 200000,
          sold_count: 3,
          time_on_market: 0.3,
          market_share: 25
        },
        {
          dimension: '8.5x32',
          median_price: 199000,
          sold_count: 6,
          time_on_market: 0.6,
          market_share: 40
        },
        {
          dimension: '12.5x32',
          median_price: 310000,
          sold_count: 30,
          time_on_market: 5.3,
          market_share: 19.2
        },
        {
          dimension: '14x28',
          median_price: 281000,
          sold_count: 17,
          time_on_market: 2,
          market_share: 34.6
        },
        {
          dimension: '16x28',
          median_price: 344000,
          sold_count: 3,
          time_on_market: 0.3,
          market_share: 25
        },
        {
          dimension: '16x32',
          median_price: 355000,
          sold_count: 7,
          time_on_market: 6.9,
          market_share: 23.8
        },
        {
          dimension: '14x25',
          median_price: 297000,
          sold_count: 3,
          time_on_market: 0,
          market_share: 100
        },
        {
          dimension: '12.5x21',
          median_price: 240000,
          sold_count: 3,
          time_on_market: 1.3,
          market_share: 25
        },
        {
          dimension: '16x21',
          median_price: 289000,
          sold_count: 1,
          time_on_market: 0,
          market_share: 100
        },
        {
          dimension: '10.5x21',
          median_price: 190000,
          sold_count: 4,
          time_on_market: 0,
          market_share: 100
        },
        {
          dimension: '10.5x28',
          median_price: 237258,
          sold_count: 8,
          time_on_market: 5.5,
          market_share: 4.1
        },
        {
          dimension: '8.5x28',
          median_price: 224910,
          sold_count: 3,
          time_on_market: 5.1,
          market_share: 0
        },
        {
          dimension: '14x21',
          median_price: 223000,
          sold_count: 1,
          time_on_market: 1,
          market_share: 0
        }
      ]
    });
  });
};

export const fetchMarketShareMock = (): Promise<{
  data: MarketShareDatum[];
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          type: 'depth',
          group: '21',
          available_percent: 37,
          available_count: 7,
          sold_percent: 54,
          sold_count: 76
        },
        {
          type: 'depth',
          group: '25',
          available_percent: 75,
          available_count: 6,
          sold_percent: 55,
          sold_count: 65
        },
        {
          type: 'depth',
          group: '28',
          available_percent: 52,
          available_count: 15,
          sold_percent: 58,
          sold_count: 180
        },
        {
          type: 'depth',
          group: '30',
          available_percent: 0,
          available_count: 0,
          sold_percent: 43,
          sold_count: 3
        },
        {
          type: 'depth',
          group: '32',
          available_percent: 0,
          available_count: 0,
          sold_percent: 69,
          sold_count: 25
        }
      ]
    });
  });
};
