import React, { Fragment } from 'react';
import { ResponsiveSankey } from '@nivo/sankey';
import { isEmpty } from 'lodash';
import EmptyView from '../empty-view';
import { SankeyData } from 'types/graph';
import CustomLinkTooltip from './custom-link-tooltip';
import { NIVO_THEME } from 'src/features/reports/theme';
import CustomTooltip from './custom-tooltip';

interface SankeyChartProps {
  data: SankeyData;
}

const LotJourneySankeyChart = ({ data }: SankeyChartProps) => (
  <Fragment>
    {!isEmpty(data) ? (
      <ResponsiveSankey
        theme={NIVO_THEME}
        data={data}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        colors={(node) => node.nodeColor}
        align="justify"
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={18}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.8]]
        }}
        nodeBorderRadius={3}
        nodeTooltip={({ node }) => <CustomTooltip node={node} />}
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.1}
        linkContract={1}
        linkTooltip={({ link }) => <CustomLinkTooltip {...link} />}
        enableLinkGradient={true}
        labelPosition="inside"
        labelOrientation="horizontal"
        labelPadding={16}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1]]
        }}
        legends={[]}
      />
    ) : (
      <EmptyView />
    )}
  </Fragment>
);

export default LotJourneySankeyChart;
