import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { Link, withWhereabouts } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT, PADDINGS } from 'src/theme';
import ROUTES from 'src/routes';
import { withModel } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import guestTokensModel from 'data/models/entities/guest-tokens';
import { PrimaryButton } from 'view/components/button';
import _ from 'lodash';
import Modal from './modal/modal';
import GuestTokenForm from '../forms/guest-token';
import ModalTarget from './portal/modal';
import ExportButton from './export-button';
import { Auth0Context } from '../../auth0-provider';

function getSettingId(props) {
  return _.get(props, 'match.params.id');
}

function getPeriod(props) {
  return _.get(props, 'match.query.period');
}

const defaultStyles = StyleSheet({
  container: {
    height: '100%'
  },

  wrapLogo: {
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },

  menu: {
    marginTop: PADDINGS.S,

    '& a': {
      color: COLORS.GREY,
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      paddingBottom: PADDINGS.XXS,
      width: '100%',
      display: 'flex',
      fontSize: '1.8rem',
      textDecoration: 'none'
    },

    '& a:hover, & a.active': {
      color: COLORS.BLUE_GREEN
    },

    '& li': {
      marginBottom: PADDINGS.XS
    },

    '& li ul': {
      paddingLeft: PADDINGS.XS
    },

    '& li li a': {
      fontWeight: FONT.WEIGHTS.NORMAL
    }
  },

  btn: {
    width: '100% !important',
    marginTop: '1rem'
  }
});

@withModel(sessionModel)
@withModel(guestTokensModel)
@withWhereabouts
@styled(defaultStyles)
class Sidebar extends PureComponent {
  static contextType = Auth0Context;
  state = {
    tokenModal: false
  };

  closeTokenModal = () => {
    this.setState({ tokenModal: false });
  };

  openTokenModal = () => {
    this.setState({ tokenModal: true });
  };

  render() {
    const { tokenModal } = this.state;
    const { styles: s, match, session, whereabouts } = this.props;

    const isGuest = !!_.get(whereabouts, 'query.token');

    return (
      <Box
        {...s('container')}
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <ModalTarget />
        <Box>
          <menu {...s('menu')}>
            <ul>
              <li>
                <Link
                  to={ROUTES.APP.REPORT_SETTINGS_OVERVIEW}
                  query={whereabouts.query}
                  params={{ id: getSettingId(this.props) }}
                >
                  Overview
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.APP.REPORT_SETTINGS_PRICING}
                  query={whereabouts.query}
                  params={{ id: getSettingId(this.props) }}
                >
                  Pricing Comparison
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.APP.REPORT_SETTINGS_BRACKETS}
                  query={whereabouts.query}
                  params={{ id: getSettingId(this.props) }}
                >
                  Size-price Brackets
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.APP.REPORT_SETTINGS_AVAILABILITY}
                  query={whereabouts.query}
                  params={{ id: getSettingId(this.props) }}
                >
                  Lot Availability
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.APP.REPORT_SETTINGS_DETAILED}
                  query={whereabouts.query}
                  params={{ id: getSettingId(this.props) }}
                >
                  Estate Detailed Stats
                </Link>
              </li>
              {!isGuest && (
                <li>
                  <Link
                    to={ROUTES.APP.REPORT_SETTINGS_PRICELIST}
                    query={whereabouts.query}
                    params={{ id: getSettingId(this.props) }}
                  >
                    Pricelists
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={ROUTES.APP.REPORT_SETTINGS_DEFINITIONS}
                  query={whereabouts.query}
                  params={{ id: getSettingId(this.props) }}
                >
                  Definitions Appendix
                </Link>
              </li>
            </ul>
          </menu>
        </Box>
        <Box {...s('wrapLogout')}>
          {_.get(session, 'admin') && (
            <PrimaryButton {...s('btn')} onClick={this.openTokenModal}>
              Generate Token
            </PrimaryButton>
          )}
          {!isGuest && (
            <ExportButton
              isSetting
              match={match}
              style={{ width: '100%', marginTop: '1rem' }}
            />
          )}
          {!!_.get(session, 'accountId') && (
            <PrimaryButton
              {...s('btn')}
              onClick={() => session.logout({ logout: this.context.logout })}
            >
              Logout
            </PrimaryButton>
          )}
        </Box>
        {tokenModal && (
          <Modal width={650}>
            <GuestTokenForm
              closeModal={this.closeTokenModal}
              settingId={getSettingId(this.props)}
              period={getPeriod(this.props)}
            />
          </Modal>
        )}
      </Box>
    );
  }
}

export default Sidebar;
