import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      The tiles (squares) in Project Performance Overview highlight the key
      months end figures for the corridor or project selected in the bottom-left
      dropdown box.
      <br />
      <br />
      By <span className="bold">selecting a tile</span> you can change the data
      you are viewing. To smooth the chart trend – a{' '}
      <span className="bold">quarterly toggle</span> is available.{' '}
      <span className="italic">
        Tile stats are static for the month selected.
      </span>
      <br />
      <br />
      The dropdown lists at the bottom of the table allow you to select a
      specific project/s - displaying a{' '}
      <span className="bold">live side-by-side comparison</span> of your chosen
      data set.
    </>
  );
};

export default InfoTooltip;
