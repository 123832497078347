import React, { useMemo } from 'react';
import { ResponsiveTreeMap, TreeMapNodeDatum } from '@nivo/treemap';
import CustomTootip from './custom-tooltip';
import { TreeMapNode, TreeNodeProps } from './treemap-node';

export interface SalesPerformanceDatum {
  id: string;
  children: {
    value: number;
  }[];
}

interface SalesPerformanceProps {
  data: SalesPerformanceDatum[];
}

export const SalesPerformanceChart = ({ data }: SalesPerformanceProps) => {
  // Nivo TreeMap requires `hierarchical data object` instead of array
  // which means that data will have to be wrapped in a parent object.
  // Here, I just use the `children` as the only prop on the parent obj.
  // Ex. { children: [...projects data] }.
  const graphDataParentObj = useMemo(
    () => ({
      children: data
    }),
    [data]
  );

  return (
    <ResponsiveTreeMap
      data={graphDataParentObj}
      identity="id"
      value="value"
      margin={{ top: 10, right: 10, bottom: 10, left: 5 }}
      orientLabel={false}
      label={(d) => {
        return `${d.value}%`;
      }}
      labelSkipSize={40}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 3]]
      }}
      parentLabelSize={20}
      parentLabelPosition="top"
      parentLabelTextColor={{
        from: 'color',
        modifiers: [['darker', 3]]
      }}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1]]
      }}
      colors={{ scheme: 'reds' }}
      // @ts-expect-error nivo treemap types not updated
      tooltip={({ node }: { node: TreeMapNodeDatum }) => (
        <CustomTootip {...node} />
      )}
      nodeComponent={(props: TreeNodeProps) => <TreeMapNode {...props} />}
    />
  );
};
