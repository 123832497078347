/*
|-------------------------------------------------------------------------------
| App Routes
|-------------------------------------------------------------------------------
|
| The main routes for the App should be centralised, configured & exposed here.
|
| Documentation for configuring routes can be found in the `react-whereabouts`
| readme (https://git.io/vNyTN).
|
*/

import { ReactNode } from 'react';

export interface PathConfig {
  config: {
    path: string;
    exact?: boolean;
    Component: ReactNode;
  };
}

export const ROUTE_NAMES = {
  '/app/report/overview': 'KEY INSIGHTS',
  '/app/report/product-trends': 'PRODUCT TRENDS',
  '/app/report/project-positioning': 'PROJECT POSITIONING',
  '/app/report/project-breakdown': 'PROJECT BREAKDOWN'
};

export default {
  APP: {
    REPORT_OVERVIEW: {
      config: {
        path: '/app/report/overview',
        exact: true,
        Component: require('src/features/reports/screens/overview').default
      }
    },

    REPORT_PRODUCT_TRENDS: {
      config: {
        path: '/app/report/product-trends',
        exact: true,
        Component: require('src/features/reports/screens/product-trends')
          .default
      }
    },

    REPORT_PROJECT_POSITIONING: {
      config: {
        path: '/app/report/project-positioning',
        exact: true,
        Component: require('src/features/reports/screens/project-positioning')
          .default
      }
    },

    REPORT_PROJECT_BREAKDOWN: {
      config: {
        path: '/app/report/project-breakdown',
        exact: true,
        Component: require('src/features/reports/screens/project-breakdown')
          .default
      }
    }
  }
};
