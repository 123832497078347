import React from 'react';
import Box from '@rexlabs/box';
import _ from 'lodash';
import fp from 'lodash/fp';
import { COLORS, PADDINGS } from 'theme';
import { SubHeading } from 'view/components/text';
import Table from 'view/components/table';
import HeatBox from 'view/components/heat-map/heat-box';
import SectionContainer from 'view/components/section-container';
import { Project } from 'data/types/projects';
import { properCase } from 'utils/format';

export interface LotSizeHeatMapProps {
  projects: Project[];
  primary?: Project;
  stats: any;
  accessor: string;
}

const sortMethod = (a, b) =>
  (a?.['max_price'] ?? 0) < (b?.['max_price'] ?? 0) ? 1 : -1;

const LotSizeHeatMap = ({
  projects,
  primary,
  stats,
  accessor
}: LotSizeHeatMapProps) => {
  const lotSizeStats = _.map(
    _.get(stats, `${accessor}_lot_size_stats`),
    (value, key) => ({
      ...{
        dimension: key
      },
      ...value
    })
  );

  const titleColumns = [
    {
      Header: () => <p style={{ color: COLORS.BLUE_GREEN }}>W & D</p>,
      width: 150,
      accessor: 'dimension',
      fixed: 'left',
      style: {
        background: COLORS.BLUE.LIGHT,
        boxShadow: '4px 0px 3px -3px #ccc',
        border: 'none !important',
        fontWeight: '600'
      }
    },
    {
      Header: 'Area',
      width: 100,
      accessor: 'area',
      fixed: 'left',
      style: {
        background: COLORS.BLUE.LIGHT,
        boxShadow: '4px 0px 3px -3px #ccc',
        border: 'none !important',
        fontWeight: '500'
      }
    }
  ];

  const getHeader = (p: Project) => (
    <p
      style={{
        color: p.id === primary?.id ? COLORS.BLUE_GREEN : 'inherit'
      }}
    >
      {p.title}
    </p>
  );

  const getCell = (p: Project) => (item) => {
    const hasBackground = _.values(item.original.projects).length > 1;

    if (item.original == null || item.original.projects[p.id] == null) {
      return null;
    }

    return (
      <HeatBox
        min={item.original.projects[p.id].min_price}
        max={item.original.projects[p.id].max_price}
        globalMin={item.original.min}
        globalMax={item.original.max}
        ppm2={item.original.projects[p.id].ppsm}
        total={item.original.projects[p.id]['total']}
        priceChange={_.get(item, `original.projects.${p.id}.price_change`)}
        percentageChange={_.get(
          item,
          `original.projects.${p.id}.percentage_change`
        )}
        hasBackground={hasBackground}
      />
    );
  };

  const sortedProjects: Project[] = fp.sortBy((p: Project) =>
    p.id === primary?.id ? 0 : 1
  )(projects);
  const projectIds = fp.map('id')(projects);
  const values: unknown = fp.map((p: Project) => ({
    Header: getHeader(p),
    width: 242,
    accessor: `projects.${p.id}`,
    sortMethod: sortMethod,
    id: p.id,
    Cell: getCell(p)
  }))(sortedProjects);

  const columns = fp.concat(values)(titleColumns);

  return (
    <SectionContainer m={PADDINGS.XXS}>
      <Box p={PADDINGS.S}>
        <SubHeading>
          {properCase(accessor)} Stock - Standard Lot Size Heat Map
        </SubHeading>
      </Box>
      <Table
        getTdProps={(state, rowInfo, column) => {
          if (_.indexOf(projectIds, column.id) !== -1) {
            return {
              style: {
                padding: 0,
                margin: 0
              }
            };
          }
          return {};
        }}
        data={lotSizeStats}
        columns={columns}
        defaultSorted={[
          {
            id: 'area',
            desc: false
          }
        ]}
        defaultPageSize={lotSizeStats.length}
      />
    </SectionContainer>
  );
};

export default LotSizeHeatMap;
