export const CALCULATION = [
  {
    label: 'Median',
    key: 'median'
  },
  {
    label: 'Average',
    key: 'average'
  }
];

export const STATUS = [
  {
    label: 'All',
    key: 'all'
  },
  {
    label: 'Available',
    key: 'available'
  },
  {
    label: 'Sold',
    key: 'sold'
  }
];

export const BONUS = [
  {
    label: 'Incentives',
    key: 'incentives'
  },
  {
    label: 'Buffer',
    key: 'buffer'
  }
];
