import { BoxPlotItem } from './box-plot-item';
import { BoxPlotTooltip, BoxPlotTooltipLabel } from './box-plot-tooltip';
import { InheritedColorConfig, OrdinalColorScaleConfig } from '@nivo/colors';
import { ScaleBandSpec, ScaleLinearSpec } from '@nivo/scales';
import { ComputedBoxPlotSummary } from './types';

export const defaultProps = {
  value: 'value',
  groupBy: 'group',
  groups: null,
  subGroupBy: null,
  subGroups: null,

  quantiles: [0, 0.25, 0.5, 0.75, 1],

  layout: 'vertical' as const,

  minValue: 'auto' as const,
  maxValue: 'auto' as const,

  valueScale: { type: 'linear' } as ScaleLinearSpec,
  indexScale: { type: 'band', round: true } as ScaleBandSpec,

  padding: 0.1,
  innerPadding: 6,

  opacity: 1,
  activeOpacity: 1,
  inactiveOpacity: 0.25,

  axisBottom: {},
  axisLeft: {},
  enableGridX: false,
  enableGridY: true,

  valueFormat: (value: number) => value.toPrecision(4),

  colorBy: 'subGroup' as const,
  colors: { scheme: 'nivo' } as OrdinalColorScaleConfig,

  borderRadius: 0,

  percentileColor: {
    from: 'color'
  } as InheritedColorConfig<ComputedBoxPlotSummary>,

  medianWidth: 3,
  medianColor: {
    from: 'color',
    modifiers: [['darker', 2.0]]
  } as InheritedColorConfig<ComputedBoxPlotSummary>,

  whiskerWidth: 2,
  whiskerColor: {
    from: 'color'
  } as InheritedColorConfig<ComputedBoxPlotSummary>,
  whiskerEndSize: 0,

  isInteractive: true,
  tooltip: BoxPlotTooltip,
  tooltipLabel: BoxPlotTooltipLabel,

  legends: [],
  annotations: [],
  markers: []
};

export const svgDefaultProps = {
  ...defaultProps,
  layers: [
    'grid',
    'axes',
    'boxPlots',
    'markers',
    'legends',
    'annotations',
    'boxPlotItemLabel',
    'boxPlotGroupLabel'
  ],
  boxPlotComponent: BoxPlotItem,

  defs: [],
  fill: [],

  animate: true,
  motionConfig: 'default',

  role: 'img',
  isFocusable: false
};
