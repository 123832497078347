import React, { useMemo } from 'react';
import Box from '@rexlabs/box';

import { COLORS, FONT } from 'src/features/reports/theme';
import { ArrowDown } from '../icons';
import { DataColumn, TableSortProps } from '.';
import { StyleSheet } from '@rexlabs/styling';

export type HeaderCellProps<T> = {
  sortable?: boolean;
  column: DataColumn<T>;
  onSort: (sort: TableSortProps) => void;
  sort: TableSortProps;
};

const styles = StyleSheet({
  cell: {
    borderRight: `1px solid ${COLORS.GREY.POWDER}`,
    '&:last-child': {
      borderRight: 'none'
    },
    width: '100%',
    padding: 8,
    cursor: 'pointer',
    minWidth: 0
  },
  value: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 400,
    fontSize: 12,
    color: COLORS.BLUE.DARK,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  activeCell: {
    backgroundColor: COLORS.BLUE.PRIMARY,
    '& p': {
      color: 'white'
    }
  },
  cellIcon: {
    transition: 'all 0.2s ease-in-out',
    minWidth: 10,
    minHeight: 6
  }
});

const HeaderCell = <T,>({ column, onSort, sort }: HeaderCellProps<T>) => {
  const onSortAction = () => {
    onSort({
      sortField: column.key,
      dir: sort.dir === 'asc' ? 'desc' : 'asc'
    });
  };

  const isSortable = useMemo(() => column.sortable ?? true, [column.sortable]);

  const currentSortedField = useMemo(
    () => column.key === sort.sortField,
    [column.key, sort.sortField]
  );

  const isDescending = useMemo(
    () => currentSortedField && sort.dir === 'desc',
    [currentSortedField, sort.dir]
  );

  const arrowAngle = useMemo(
    () => (currentSortedField ? (isDescending ? 0 : 180) : 0),
    [currentSortedField, isDescending]
  );

  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      onClick={isSortable ? onSortAction : undefined}
      className={`${styles.cell} ${
        currentSortedField ? styles.activeCell : ''
      }`}
      style={{
        cursor: isSortable ? 'pointer' : 'normal'
      }}
    >
      <p
        className={styles.value}
        style={{ color: currentSortedField && COLORS.WHITE }}
      >
        {column.title}
      </p>

      {isSortable && (
        <ArrowDown
          className={styles.cellIcon}
          style={{ transform: `rotate(${arrowAngle}deg)` }}
          width={10}
          height={6}
          stroke={currentSortedField ? COLORS.WHITE : COLORS.BLUE.DARK}
        />
      )}
    </Box>
  );
};

export default HeaderCell;
