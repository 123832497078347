import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Canceler } from 'axios';
import { max, maxBy } from 'lodash';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { CancelToken } from '@rexlabs/api-client';

import Panel from 'src/features/reports/components/panel';
import { SolidXAxis, Line, Loading } from '../graph';
import Graphs from './custom-layers/graphs';
import Average from './custom-layers/average';
import { ToggleButtonGroup } from '../toggle-button';
import Categories from './categories';

import withError from 'view/containers/with-error';
import { TIME_INTERVAL } from 'src/features/reports/constants/common-graph-filters';
import { getShortMonthWithYear } from 'utils/format';
import { compose } from 'utils/compose';
import keyInsightsModel from 'src/features/reports/models/key-insights';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { KeyInsightsModel } from 'types/models/key-insights';
import { COLORS, NIVO_THEME } from 'src/features/reports/theme';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { PerformanceOverviewParams } from 'types/params';
import { PerformanceOverviewGraph } from 'types/graph';
import { Auth0Context } from 'src/auth0-provider';
import { INSIGHTS_PAGE } from 'src/features/reports/constants';
import InfoTooltip from './info-tooltip';

interface PerformanceOverviewProps {
  keyInsightsModel: KeyInsightsModel;
  errorModal: WithErrorModalProps;
}

const PerformanceOverview = ({
  keyInsightsModel,
  errorModal
}: PerformanceOverviewProps) => {
  const { open: openError } = errorModal;
  const { hasPermission } = useContext(Auth0Context);
  const { selectedProjectIds, period, isFirstLoad } = useContext(
    ReportSettingsContext
  );
  const { fetchPerformanceOverviewSummary, fetchPerformanceOverviewGraph } =
    keyInsightsModel;
  const canceler = useRef<Canceler>(null);
  const [timeInterval, setTimeInterval] = useState<'monthly' | 'quarterly'>(
    'monthly'
  );
  const [category, setCategory] = useState<{
    label: string;
    key: PerformanceOverviewParams['report'];
  }>({ label: 'GROSS SALES', key: 'gross_sales' });
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState<PerformanceOverviewGraph | null>(null);

  const isTwoLines = useMemo(() => {
    return ['gross_sales', 'available'].includes(category.key);
  }, [category]);

  const formattedReport = useMemo(() => {
    if (!report || !report[timeInterval]) return [];
    const interval = report[timeInterval];
    let result = [
      {
        id: category.label,
        data: []
      }
    ];
    if (isTwoLines) {
      result = [
        {
          id: 'Titled',
          data: []
        },
        {
          id: 'Untitled',
          data: []
        }
      ];
    }
    interval.forEach((data) => {
      const endDate =
        timeInterval === 'monthly'
          ? getShortMonthWithYear(data.end_date)
          : data.quarter;
      if (isTwoLines) {
        result[0].data.push({
          x: endDate,
          y: data[`${category.key}_titled`]
        });
        result[1].data.push({
          x: endDate,
          y: data[`${category.key}_untitled`]
        });
      } else {
        result[0].data.push({
          x: endDate,
          y: data[category.key]
        });
      }
    });
    return result;
  }, [report, isTwoLines, timeInterval, category]);

  // Calculate max value with the condition of treating null as zeros
  const maxY = useMemo(() => {
    // Minimum is 20, so that we will have a bit of space
    if (!formattedReport.length) return 20;

    if (formattedReport.length === 1) {
      return maxBy(formattedReport[0].data, 'y')?.y ?? 20;
    }

    const stacked = formattedReport[1].data.map((datum, index) => {
      const y1: number = datum.y === null ? 0 : datum.y;
      const y2: number =
        formattedReport[0].data[index].y === null
          ? 0
          : formattedReport[0].data[index].y;

      return y1 + y2;
    });

    return max(stacked) + 20;
  }, [formattedReport]);

  useEffect(() => {
    if ((!selectedProjectIds.length || !period) && !isFirstLoad) return;

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    fetchPerformanceOverviewGraph({
      queryParams: {
        project_ids: selectedProjectIds,
        period,
        report: category.key
      },
      hasPermission: !isFirstLoad && hasPermission([INSIGHTS_PAGE]),
      config: { cancelToken }
    })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;
        setReport(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        openError(error);
        setIsLoading(false);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    period,
    category,
    fetchPerformanceOverviewGraph,
    openError,
    hasPermission,
    isFirstLoad
  ]);

  return (
    <Panel
      title="Performance Overview"
      tooltip={{
        title: 'Performance Overview',
        description: <InfoTooltip />
      }}
      featuredValues={
        <Categories
          selected={category}
          onClick={(selected: {
            label: string;
            key: PerformanceOverviewParams['report'];
          }) => setCategory(selected)}
          fetchPerformanceOverviewSummary={fetchPerformanceOverviewSummary}
          project_ids={selectedProjectIds}
          period={period}
          errorModal={errorModal}
          hasPermission={hasPermission([INSIGHTS_PAGE])}
          isFirstLoad={isFirstLoad}
        />
      }
    >
      <Loading isLoading={isLoading} />
      <Box width="100%" flexDirection="row" justifyContent="center">
        <Box width="236px" mb="24px" mt="22px">
          <ToggleButtonGroup
            value={[timeInterval]}
            group={TIME_INTERVAL}
            onToggle={(key: 'monthly' | 'quarterly') => setTimeInterval(key)}
          />
        </Box>
      </Box>
      <Box width="100%" height="610px">
        <ResponsiveLine
          data={formattedReport}
          theme={NIVO_THEME}
          colors={[COLORS.BLUE.PRIMARY, COLORS.PINK.LIGHT]}
          margin={{
            top: 10,
            right: 20,
            bottom: isTwoLines ? 85 : 55,
            left: 70
          }}
          yScale={{
            type: 'linear',
            max: maxY,
            min: 'auto'
          }}
          enableSlices="x"
          enableGridX={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 9,
            legend: 'MONTH',
            tickPadding: 0,
            legendOffset: 45,
            legendPosition: 'middle'
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 12,
            legend: 'COUNT',
            legendOffset: -65,
            legendPosition: 'middle',
            format: (count) =>
              count < 1 ? count : Math.floor(count) === count && count
          }}
          legends={
            isTwoLines
              ? [
                  {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 85,
                    itemsSpacing: 24,
                    itemWidth: 64,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    symbolSize: 12,
                    symbolShape: 'circle'
                  }
                ]
              : undefined
          }
          layers={[
            // Default layers
            'grid',
            'markers',
            'axes',
            'crosshair',
            'slices',
            'mesh',
            'legends',
            // Custom layers
            Line,
            Graphs,
            SolidXAxis,
            Average
          ]}
        />
      </Box>
    </Panel>
  );
};

export default compose(
  withModel(keyInsightsModel),
  withError.withPropName('errorModal')
)(PerformanceOverview);
