import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';
import { Body } from './text';

const defaultStyles = StyleSheet({
  container: {},
  stage: {
    borderRight: `1px solid ${COLORS.GREY.LIGHT}`,
    padding: PADDINGS.XS,
    paddingLeft: 0,
    color: COLORS.BLUE_GREEN,
    width: '10rem'
  },
  title: {
    padding: PADDINGS.XS
  }
});

@styled(defaultStyles)
class StageLabel extends PureComponent {
  render() {
    const { styles: s, stage, title } = this.props;
    return (
      <Box flex={1} flexDirection={'row'} {...s('container')}>
        <Body {...s('stage')}>Stage {stage}</Body>
        <Body {...s('title')} grey>
          {title}
        </Body>
      </Box>
    );
  }
}

export default StageLabel;
