import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import { HorizontalActionMenu } from 'view/components/action-menu';
import lotsModel from 'data/models/entities/lots';
import { withModel } from '@rexlabs/model-generator';
import _ from 'lodash';
import { formatNumericValue, formatCurrency } from 'utils/format';
import LotModal from 'view/modals/lot';
import ActionModal from '../../modals/action';
import NewDot from 'view/components/new-dot';

const defaultStyles = StyleSheet({
  text: {
    // fontWeight: '500'
  },
  status: {
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  }
});

@withModel(lotsModel)
@styled(defaultStyles)
class ProjectLotListItem extends PureComponent {
  state = {
    selected: false,
    archiveModal: false
  };

  formatDate = (createdAt) => {
    const date = new Date(createdAt);
    return `${date.getDate()} ${date.toLocaleString('en-us', {
      month: 'long'
    })} ${date.getFullYear()}`;
  };

  deleteLot = () => {
    const { lot, lots, projectId, worksheetId, onDelete } = this.props;

    lots
      .trashItem({
        id: lot.id,
        args: {
          worksheetId: worksheetId,
          projectId: projectId
        }
      })
      .then(() => {
        onDelete();
        this.setState({ deleteModal: false });
      })
      .catch((e) => console.error(e));
  };

  enableEditModal = () => this.setState({ editModal: true });

  toggleLot = (e) => {
    const { toggle, lot } = this.props;
    e.stopPropagation();
    toggle(lot);
  };

  render() {
    const {
      styles: s,
      lot,
      checked,
      projectId,
      worksheetId,
      stages,
      lots,
      onDuplicate,
      onEdit,
      published
    } = this.props;

    const menuyItems = published
      ? [
          {
            label: 'View',
            onClick: () => this.setState({ editModal: true })
          }
        ]
      : [
          {
            label: 'Edit',
            onClick: () => this.setState({ editModal: true })
          },
          {
            label: 'Duplicate',
            onClick: () => onDuplicate(lot)
          },
          {
            label: 'Delete',
            onClick: () => this.setState({ deleteModal: true })
          }
        ];

    const isChecked = !!checked;
    const hasPrice = !!_.get(lot, 'price');
    const isLotTitled = _.get(lot, 'titled', false) ? 'Titled' : '-';

    const lotSize = formatNumericValue({
      value: _.get(lot, 'lot_size', 0),
      suffix: ' m2'
    });

    const frontage = formatNumericValue({
      value: _.get(lot, 'frontage', 0),
      suffix: ' m'
    });

    const depth = formatNumericValue({
      value: _.get(lot, 'depth', 0),
      suffix: ' m'
    });

    const price = formatCurrency(_.get(lot, 'price', 0));

    const pricePerM2 = hasPrice
      ? formatCurrency(_.get(lot, 'price_per_m2', 0))
      : '-';

    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={39}
          style={{ alignItems: 'center' }}
          onClick={this.enableEditModal}
          {...s('grid')}
        >
          <Column width={1}>
            {_.get(lot, 'meta.is_new') && <NewDot tooltip="New Lot" />}
          </Column>
          <Column width={1} onClick={this.toggleLot} {...s('checkbox')}>
            <Checkbox controlled value={isChecked} />
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{_.get(lot, 'stage.stage_no')}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{_.get(lot, 'lot_number')}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{lotSize}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{frontage}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{price}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{_.get(lot, 'status')}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{isLotTitled}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{depth}</Small>
          </Column>
          <Column width={3}>
            <Small {...s('text')}>{pricePerM2}</Small>
          </Column>
          <Column width={2} onClick={(e) => e.stopPropagation()}>
            <HorizontalActionMenu items={menuyItems} />
          </Column>
        </Grid>
        {this.state.editModal && (
          <LotModal
            projectId={projectId}
            worksheetId={worksheetId}
            stages={stages}
            published={published}
            closeModal={() => this.setState({ editModal: false })}
            onSubmit={() => {
              lots.refreshList({ id: 'test' });
              onEdit();
              this.setState({ editModal: false });
            }}
            lot={lot}
          />
        )}
        {this.state.deleteModal && (
          <ActionModal
            onClose={() => this.setState({ deleteModal: false })}
            onSubmit={this.deleteLot}
            title={'Delete Lot'}
            submitText={'Delete'}
          >
            Are you sure you want to delete this lot?
          </ActionModal>
        )}
      </Box>
    );
  }
}

export default ProjectLotListItem;
