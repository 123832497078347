import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import { Heading, SubHeading, Body } from 'view/components/text';
import { formatNumber, formatPeriod } from 'utils/format';
import Table from 'view/components/table';
import StageLabel from '../../components/stage-label';
import uuid from 'uuid';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from '../../components/arrow';
import GA from 'react-ga';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  },
  listContainer: {
    maxHeight: '80rem',
    overflowY: 'auto',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  }
});

@styled(defaultStyles)
class PriceList extends PureComponent {
  state = {
    currentProject: this.props?.primary?.id ?? this.props?.projects?.[0]?.id
  };

  formatCharacteristics = (listing) => {
    const list = [
      listing.is_corner ? 'CNR' : null,
      listing.is_park_wetlands ? 'PRK' : null,
      listing.is_substation ? 'SUB' : null,
      listing.is_powerlines ? 'PWL' : null,
      listing.is_irregular ? 'IRR' : null,
      listing.is_nearby_amenity ? 'AME' : null,
      listing.is_fall ? 'FAL' : null,
      listing.is_fill ? 'FIL' : null
    ];

    const filteredList = list.filter((x) => x);

    return _.isEmpty(filteredList) ? '' : filteredList.join('/');
  };

  render() {
    const { styles: s, period, projects, priceList } = this.props;

    const { currentProject } = this.state;

    const currentPriceList = _.get(priceList, currentProject);
    const project = _.find(projects, {
      id: parseInt(currentProject)
    });

    const stages = _.get(currentPriceList, 'stages');
    const stageList = _.chunk(stages, _.ceil(stages?.length / 2));

    const soldListings = _.get(currentPriceList, 'sold');
    const availableListings = _.get(currentPriceList, 'available');
    const depositListings = _.get(currentPriceList, 'deposit');

    const listingColumns = [
      {
        Header: () => <p style={{ color: COLORS.BLUE_GREEN }}>Allotment</p>,
        width: 150,
        accessor: 'lot_number',
        fixed: 'left',
        style: {
          background: COLORS.BLUE.LIGHT,
          boxShadow: '4px 0px 3px -3px #ccc',
          border: 'none !important',
          fontWeight: '500'
        },
        sortMethod: new Intl.Collator(undefined, {
          numeric: true,
          sensitivity: 'base'
        }).compare
      },
      {
        Header: 'Frontage',
        accessor: 'frontage',
        width: 150
      },
      {
        Header: 'Depth',
        accessor: 'depth',
        width: 150
      },
      {
        Header: 'Size',
        accessor: 'lot_size',
        width: 150
      },
      {
        Header: 'Price',
        width: 150,
        Cell: (price) =>
          price.original.price ? `$${formatNumber(price.original.price)}` : '-'
      },
      {
        Header: '$/m2',
        width: 150,
        Cell: (price) =>
          price.original.price_per_m2
            ? `$${formatNumber(price.original.price_per_m2)}`
            : '-'
      },
      {
        Header: 'Incentives/Rebate',
        width: 150,
        Cell: (row) => `$${formatNumber(_.get(row, 'original.rebate', 0) || 0)}`
      },
      {
        Header: 'Negotiation Buffer',
        width: 150,
        Cell: (row) =>
          `$${formatNumber(_.get(row, 'original.negotiation_buffer', 0) || 0)}`
      },
      {
        Header: 'Titled',
        width: 150,
        Cell: (row) => (row.original.titled ? 'Titled' : '')
      },
      {
        Header: 'Characteristics',
        width: 150,
        Cell: (row) => this.formatCharacteristics(row.original)
      },
      {
        Header: 'Price Change',
        width: 150,
        Cell: (row) => `$${formatNumber(row.original?.price_change ?? 0)}`
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 150
      }
    ];

    return (
      <Fragment>
        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <Box flex={1} justifyContent={'space-between'}>
              <Heading>
                {_.get(project, 'title')} Pricelist -{' '}
                {formatPeriod(_.get(period, 'end_date'), true)}
              </Heading>
              <Box>
                <ActionMenu
                  Button={(button) => (
                    <Body
                      style={{
                        cursor: 'pointer',
                        color: COLORS.GREY.DARK,
                        userSelect: 'none'
                      }}
                    >
                      Change Estate &nbsp;
                      {button.isOpen ? <UpArrow /> : <DownArrow />}
                    </Body>
                  )}
                  items={_.map(projects, (p) => {
                    return {
                      label: p.title,
                      onClick: () => {
                        GA.event({
                          category: 'Pricelist',
                          action: 'Toggle Pricelist Estate',
                          label: p.title.toLowerCase()
                        });
                        this.setState({ currentProject: p.id });
                      }
                    };
                  })}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <SubHeading>Expected Title Release</SubHeading>
          </Box>

          <Box p={PADDINGS.S} pt={PADDINGS.TINY}>
            <Box flex={1} flexDirection="row" flexWrap={'wrap'}>
              {stageList.map((stages) => {
                return (
                  <Box flex={1} flexDirection={'column'} key={uuid()}>
                    {stages.map((stage) => {
                      return (
                        <StageLabel
                          stage={stage.stage_no}
                          title={stage.title || '-'}
                          key={uuid()}
                        />
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <SubHeading>Sold Allotments</SubHeading>
          </Box>

          <Box {...s('listContainer')}>
            <Table
              getTableProps={() => ({
                style: {
                  overflow: 'visible'
                }
              })}
              data={soldListings}
              columns={listingColumns}
              pageSize={soldListings?.length || 5}
              defaultSorted={[
                {
                  id: 'lot_number',
                  desc: false
                }
              ]}
            />
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <SubHeading>Deposited Allotments</SubHeading>
          </Box>

          <Box {...s('listContainer')}>
            <Table
              getTableProps={() => ({
                style: {
                  overflow: 'visible'
                }
              })}
              data={depositListings}
              columns={listingColumns}
              pageSize={depositListings?.length || 5}
              defaultSorted={[
                {
                  id: 'lot_number',
                  desc: false
                }
              ]}
            />
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <SubHeading>Remaining Allotments</SubHeading>
          </Box>

          <Box {...s('listContainer')}>
            <Table
              getTableProps={() => ({
                style: {
                  overflow: 'visible'
                }
              })}
              data={availableListings}
              columns={listingColumns}
              pageSize={availableListings?.length || 5}
              defaultSorted={[
                {
                  id: 'lot_number',
                  desc: false
                }
              ]}
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
}

export default PriceList;
