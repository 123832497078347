import React, { PureComponent, Fragment } from 'react';
import ErrorModal from 'view/components/modal/error';
import { autobind, decorate } from 'core-decorators';
import _ from 'lodash';

const withErrorCore = (propName) => (WrappedComponent) => {
  @autobind
  class WithError extends PureComponent {
    state = {
      open: false,
      title: 'Oops!',
      message: 'It looks like something went wrong. Please try again!',
      onClose: null
    };

    open(message, title, onClose) {
      if (__DEV__) {
        console.warn('open error modal', message, title);
      }
      this.setState({
        open: true,
        title: title || this.state.title,
        message:
          message && message.message
            ? message.message
            : message || this.state.message,
        onClose: onClose
      });
    }

    close() {
      if (this.state.onClose) {
        this.state.onClose();
      }
      this.setState({ open: false });
    }

    @decorate(_.memoize)
    getProps(isOpen) {
      return {
        isOpen,
        open: this.open,
        close: this.close,
        Error: () =>
          this.state.open && (
            <ErrorModal
              error
              title={this.state.title}
              onClose={this.close}
              closeText={'close'}
            >
              {this.state.message}
            </ErrorModal>
          )
      };
    }

    render() {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            {...{
              [propName]: this.getProps(this.state.open)
            }}
          />
          {this.state.open && (
            <ErrorModal
              error
              title={this.state.title}
              onClose={this.close}
              closeText={'close'}
            >
              {this.state.message}
            </ErrorModal>
          )}
        </Fragment>
      );
    }
  }

  return WithError;
};

const withError = withErrorCore('error');
withError.withPropName = (propName) => {
  return withErrorCore(propName);
};

export default withError;
