/* eslint-disable max-lines */
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import { styled, Styles, StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/features/reports/theme';
import { HeatMapItem } from '../heat-map';
import { abbrNum, numberWithCommas } from 'utils/format';
import { ArrowDown } from '../icons';
import { MatrixPrice, PricingMatrixReport } from 'types/graph';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { filter, flatMap, maxBy, minBy, sortBy, sum, upperFirst } from 'lodash';
import LazyLoad, { LazyLoadProps } from 'react-lazyload';

const styles = StyleSheet({
  table: {
    border: `1px solid ${COLORS.GREY.POWDER}`,
    borderBottom: 'none',
    width: 'max-content',
    maxHeight: '500px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: COLORS.GREY.DARK
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: `${COLORS.GREY.DARK}D9` // 0.85 opacity
    }
  },
  label: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 500,
    fontSize: '14px',
    color: COLORS.BLUE.DARK,
    padding: '8px'
  },
  th: {
    fontSize: '12px'
  },
  lotSize: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.BLUE.PRIMARY,
    outline: 'none',
    border: 'none',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& path': {
      stroke: COLORS.WHITE
    }
  },
  cell: {
    borderRight: `1px solid ${COLORS.GREY.POWDER}`,
    '&:last-child': {
      borderRight: 'none'
    }
  },
  value: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 500,
    fontSize: '10px',
    color: COLORS.BLACK
  },
  size: {
    opacity: 0.6
  },
  bold: {
    fontWeight: 700
  },
  heatMap: {
    width: '100%',
    minHeight: '59px',
    padding: '8px'
  },
  lotSizeColumn: {
    width: '96px',
    position: 'sticky',
    left: 0,
    zIndex: 1,
    height: '100%'
  },
  projectColumn: {
    width: '140px',
    height: '100%'
  },
  projectHeader: {
    backgroundColor: '#F7F9FF'
  },
  lotSizeItem: {
    backgroundColor: COLORS.WHITE,
    minHeight: '59px',
    padding: '8px'
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: 'max-content',
    minHeight: '59px',
    borderBottom: `1px solid ${COLORS.GREY.POWDER}`
  },
  headerRow: {
    height: '34px',
    minHeight: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 2
  }
});

const LazyLoadWrapper: FC<{ disabled?: boolean } & LazyLoadProps> = ({
  disabled,
  children,
  ...rest
}) => {
  if (disabled) return <>{children}</>;
  return <LazyLoad {...rest}>{children}</LazyLoad>;
};

interface PricingMatrixTableProps {
  styles: Styles;
  report: PricingMatrixReport[];
  status: 'available' | 'sold';
  bonus: string[];
}

const PricingMatrixTable = ({
  styles: s,
  report,
  status,
  bonus
}: PricingMatrixTableProps) => {
  const { selectedProjects } = useContext(ReportSettingsContext);
  const [isDescending, setIsDescending] = useState(false);

  const sortedReport = useMemo(() => {
    let result = sortBy(report, (lot) => +lot.area);
    if (isDescending) {
      result = result.reverse();
    }

    return result;
  }, [isDescending, report]);

  // Estate list that is sorted in backend
  const estates = useMemo(() => {
    if (report.length === 0) return [];
    return Object.keys(report[0].estates);
  }, [report]);

  const getPriceRange = useCallback(
    (prices: MatrixPrice) => {
      let range = {
        total: [],
        perSquareMeter: []
      };
      if (bonus.length === 0) {
        range = {
          total: [prices.min_price, prices.max_price],
          perSquareMeter: [prices.min_price_per_m2, prices.max_price_per_m2]
        };
      } else if (bonus.length === 2) {
        range = {
          total: [
            prices.min_price_minus_buffer_and_incentives,
            prices.max_price_minus_buffer_and_incentives
          ],
          perSquareMeter: [
            prices.min_price_per_m2_minus_buffer_and_incentives,
            prices.max_price_per_m2_minus_buffer_and_incentives
          ]
        };
      } else if (bonus.includes('incentives')) {
        range = {
          total: [
            prices.min_price_minus_incentives,
            prices.max_price_minus_incentives
          ],
          perSquareMeter: [
            prices.min_price_per_m2_minus_incentives,
            prices.max_price_per_m2_minus_incentives
          ]
        };
      } else if (bonus.includes('buffer')) {
        range = {
          total: [prices.min_price_minus_buffer, prices.max_price_minus_buffer],
          perSquareMeter: [
            prices.min_price_per_m2_minus_buffer,
            prices.max_price_per_m2_minus_buffer
          ]
        };
      }

      return range;
    },
    [bonus]
  );

  const getPriceRangeElemets = useCallback(
    (prices: MatrixPrice) => {
      const priceRange = getPriceRange(prices);
      return (
        <>
          <p {...s('value', 'bold')}>
            ${abbrNum(+priceRange.total[0])}
            {+priceRange.total[0] !== +priceRange.total[1]
              ? ` - $${abbrNum(+priceRange.total[1])}`
              : ''}
          </p>
          <p {...s('value')}>
            $/m2: ${numberWithCommas(+priceRange.perSquareMeter[0])}
            {+priceRange.perSquareMeter[0] !== +priceRange.perSquareMeter[1]
              ? ` - $${numberWithCommas(+priceRange.perSquareMeter[1])}`
              : ''}
          </p>
        </>
      );
    },
    [s, getPriceRange]
  );

  const getHeatRange = useCallback(
    (projects: PricingMatrixReport['estates'], projectName: string) => {
      const flatProjects = filter(
        flatMap(Object.keys(projects).map((key) => projects[key]))
      );

      if (flatProjects.length === 0) {
        return {
          highestHeat: 0,
          lowestHeat: 0,
          heatValue: 0,
          disabled: true
        };
      }

      const highestHeat = +getPriceRange(
        maxBy(flatProjects, (project) => +getPriceRange(project).total[1])
      ).total[1];

      const lowestHeat = +getPriceRange(
        minBy(flatProjects, (project) => +getPriceRange(project).total[0])
      ).total[0];

      const heatValue =
        sum(getPriceRange(projects[projectName]).total.map(Number)) / 2;

      return {
        highestHeat,
        lowestHeat,
        heatValue,
        disabled: flatProjects.length === 1
      };
    },
    [getPriceRange]
  );

  return (
    <Box width="100%" flexDirection="row" justifyContent="center">
      <div {...s('table')}>
        <div {...s('row', 'headerRow')}>
          <button
            {...s('cell', 'label', 'th', 'lotSize', 'lotSizeColumn')}
            onClick={() => setIsDescending((prev) => !prev)}
          >
            LOT SIZE
            <ArrowDown
              style={{
                transition: 'all 0.2s ease-in-out',
                transform: `rotate(${isDescending ? 180 : 0}deg)`
              }}
              width="8px"
              height="5px"
            />
          </button>
          {estates.map((estate) => (
            <p
              key={estate}
              {...s('cell', 'label', 'th', 'projectHeader', 'projectColumn')}
            >
              {estate}
            </p>
          ))}
        </div>
        {sortedReport.map((lot, index) => (
          <LazyLoadWrapper
            // Initially render 8 items
            disabled={index <= 8}
            key={`${lot.frontage}x${lot.depth}`}
            height={59}
            overflow
            resize
          >
            <div {...s('row')}>
              <Box {...s('cell', 'lotSizeColumn', 'lotSizeItem')}>
                <p {...s('value', 'bold')}>{lot.area}m2</p>
                <p {...s('value')}>
                  {lot.frontage}m x {lot.depth}m
                </p>
              </Box>
              {estates.map((estate) =>
                lot.estates[estate] ? (
                  <HeatMapItem
                    key={estate}
                    {...s('cell', 'heatMap', 'projectColumn')}
                    {...getHeatRange(lot.estates, estate)}
                  >
                    {getPriceRangeElemets(lot.estates[estate])}
                    <p {...s('value')}>
                      {upperFirst(status)}: {lot.estates[estate].listings_count}
                    </p>
                  </HeatMapItem>
                ) : (
                  <Box
                    key={estate}
                    {...s('cell', 'heatMap', 'projectColumn')}
                  />
                )
              )}
            </div>
          </LazyLoadWrapper>
        ))}
      </div>
    </Box>
  );
};

export default styled(styles)(PricingMatrixTable);
