import {
  computeScale,
  ScaleBandSpec,
  ScaleLinearSpec,
  ScaleLogSpec,
  ScaleSymlogSpec,
  ScaleTimeSpec
} from '@nivo/scales';
import { flatMap } from 'lodash';
import { BANDWIDTH, GROUP_SPACE } from '../constants';
import { BoxPlotSummary, ComputedBoxPlotSummary } from '../types';
import { getIndexScale } from './common';

type Params = {
  data: BoxPlotSummary[];
  formatValue: (value: number) => string;
  getTooltipLabel: (datum: BoxPlotSummary) => string;
  innerPadding: number;
  subGroups: string[];
  valueScale: (value: number, index?: number, array?: number[]) => number;
  layout: 'vertical' | 'horizontal';
  height: number;
};

const generateComputedBoxPlotSummaries = ({
  data,
  getTooltipLabel,
  innerPadding = 0,
  valueScale,
  formatValue,
  layout,
  height
}: Params): ComputedBoxPlotSummary[] => {
  const vertical = layout === 'vertical';
  return data.map((datum, index) => {
    const { group, subGroup, groupIndex, subGroupIndex, values } = datum;
    const key = `${groupIndex}.${subGroupIndex}`;
    const coords = values.map(valueScale).map((v) => v ?? 0);
    const intervals = [0, 1, 2, 3].map(
      (i) => Math.abs(coords[i + 1] - coords[i]) ?? 0
    );
    const indexCoordinate =
      height -
      BANDWIDTH * (index + 1) -
      innerPadding * index -
      GROUP_SPACE * groupIndex;
    // top-left of rectangle and width/height depend on the layout
    // (this conditional inside the loop is not ideal, but typical loops will be short)
    const position = vertical
      ? {
          x: indexCoordinate,
          y: datum.values[3] ?? 0,
          width: BANDWIDTH,
          height: intervals[1] + intervals[2]
        }
      : {
          x: datum.values[1] ?? 0,
          y: indexCoordinate,
          width: intervals[1] + intervals[2],
          height: BANDWIDTH
        };
    return {
      key,
      group,
      subGroup,
      data: datum,
      formatted: {
        n: String(datum.n),
        median: formatValue(datum.median),
        extrema: datum.extrema.map(formatValue),
        values: datum.values.map(formatValue),
        quantiles: datum.quantiles.map((v) => String(100 * v))
      },
      ...position,
      coordinates: {
        index: indexCoordinate,
        values: values.map((v) => valueScale(v) ?? 0)
      },
      bandwidth: BANDWIDTH,
      label: getTooltipLabel(datum),
      layout
    } as ComputedBoxPlotSummary;
  });
};

export const generateBoxPlots = ({
  data,
  layout,
  groups,
  subGroups,
  formatValue,
  minValue,
  maxValue,
  width,
  height,
  padding,
  innerPadding,
  valueScale: valueScaleConfig,
  indexScale: indexScaleConfig,
  getTooltipLabel
}: {
  data: BoxPlotSummary[];
  layout: string;
  groups: string[] | null;
  subGroups: string[] | null;
  formatValue: (value: number) => string;
  minValue: 'auto' | number;
  maxValue: 'auto' | number;
  width: number;
  height: number;
  padding: number;
  innerPadding: number;
  valueScale: ScaleLinearSpec | ScaleLogSpec | ScaleSymlogSpec | ScaleTimeSpec;
  indexScale: ScaleBandSpec;
  getTooltipLabel: (datum: BoxPlotSummary) => string;
}) => {
  const [axis, otherAxis, size] =
    layout === 'vertical'
      ? (['y', 'x', width] as const)
      : (['x', 'y', height] as const);
  const indexScale = getIndexScale(
    groups ?? [],
    padding,
    indexScaleConfig,
    size,
    otherAxis
  );

  const valueScaleSpec = {
    max: maxValue,
    min: minValue,
    ...valueScaleConfig
  };

  const values = flatMap(data.map((datum: BoxPlotSummary) => datum.values));
  const min = values.reduce(
    (acc: number, value: number) => Math.min(acc, value),
    Infinity
  );
  const max = values.reduce(
    (acc: number, value: number) => Math.max(acc, value),
    -Infinity
  );

  const valueScale = computeScale(
    valueScaleSpec as
      | ScaleLinearSpec
      | ScaleLogSpec
      | ScaleSymlogSpec
      | ScaleTimeSpec,
    { all: [min, max], min, max },
    axis === 'x' ? width : height,
    axis
  );

  const [xScale, yScale] =
    layout === 'vertical' ? [indexScale, valueScale] : [valueScale, indexScale];

  const params = {
    data,
    groups,
    subGroups,
    getTooltipLabel,
    innerPadding,
    valueScale,
    formatValue,
    layout,
    height
  } as Params;
  const boxPlots = generateComputedBoxPlotSummaries(params);
  return { xScale, yScale, boxPlots };
};
