import React, { useContext } from 'react';
import { SnackbarContext } from 'src/features/reports/providers/snackbar-provider';
import SnackbarItem from './snackbar-item';

const Snackbar = () => {
  const { messages } = useContext(SnackbarContext);
  return (
    <>
      {messages.map((message, index) => (
        <SnackbarItem key={message.id} index={index} {...message} />
      ))}
    </>
  );
};

export default Snackbar;
