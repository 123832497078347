import React, { PureComponent } from 'react';
import _ from 'lodash';
import Box from '@rexlabs/box';
import { push } from '@rexlabs/whereabouts';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';

import { Small, Tiny } from 'view/components/text';
import { Check as Checkbox } from 'view/components/checkbox';
import { HorizontalActionMenu } from 'view/components/action-menu';
import StatusBox from 'view/components/status-box/status-box';
import ActionModal from 'view/modals/action';
import WorksheetModal from 'view/modals/worksheets-new';

import worksheetsModel from 'data/models/entities/worksheets';
import projectsModel from 'data/models/entities/projects';
import ROUTES from 'src/routes';
import { FONT } from 'src/theme';
import { formatPeriod, formatDate } from 'utils/format';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500'
  },
  status: {
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  },
  date: {
    fontStyle: 'italic'
  }
});

@withModel(worksheetsModel)
@withModel(projectsModel)
@styled(defaultStyles)
class ProjectWorksheetListItem extends PureComponent {
  state = {
    viewModal: false,
    deleteModal: false
  };

  deleteWorksheet = () => {
    const { worksheets, project, worksheet } = this.props;
    worksheets
      .trashItem({
        id: worksheet.id,
        args: {
          projectId: project.id
        }
      })
      .then(() => this.setState({ deleteModal: false }))
      .catch((e) => console.error(e));
  };

  view = (project, worksheet) => {
    push(ROUTES.APP.WORKSHEET, {
      params: {
        projectId: project.id,
        worksheetId: worksheet.id
      }
    });
  };

  render() {
    const {
      styles: s,
      worksheets,
      worksheet,
      project,
      toggle,
      checked
    } = this.props;

    const flags =
      _.map(worksheet?.flags?.data, (flag) => flag.name).join() || '--';

    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={24}
          style={{ alignItems: 'center' }}
          {...s('grid')}
          onClick={() => this.view(project, worksheet)}
        >
          <Column
            width={1}
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
            {...s('checkbox')}
          >
            <Checkbox controlled={true} value={!!checked} />
          </Column>
          <Column width={1} />
          <Column width={5}>
            <Small {...s('text')}>
              {formatPeriod(worksheet.period.end_date)}
            </Small>
          </Column>
          <Column width={4}>
            {worksheet.status === 'draft' ? (
              <StatusBox text={'Draft'} />
            ) : (
              <StatusBox published text={'Published'} />
            )}
          </Column>
          <Column width={4}>
            <Tiny>{flags}</Tiny>
          </Column>
          <Column width={4}>
            <Small {...s('date')}>{formatDate(worksheet.created_at)}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('date')}>{formatDate(worksheet.updated_at)}</Small>
          </Column>
          <Column width={1} onClick={(e) => e.stopPropagation()}>
            <HorizontalActionMenu
              items={[
                {
                  label: 'View',
                  onClick: () => this.view(project, worksheet)
                },
                {
                  label: 'Edit',
                  onClick: () => this.setState({ editModal: true })
                },
                {
                  label: 'Delete',
                  onClick: () => this.setState({ deleteModal: true })
                }
              ]}
            />
          </Column>
        </Grid>
        {this.state.deleteModal && (
          <ActionModal
            onClose={() => this.setState({ deleteModal: false })}
            onSubmit={this.deleteWorksheet}
            title={'Delete Worksheet'}
            submitText={'Delete'}
          >
            Are you sure you want to delete this worksheet?
          </ActionModal>
        )}
        {this.state.editModal && (
          <WorksheetModal
            worksheet={worksheet}
            projectId={worksheet.project_id}
            onSubmit={() => worksheets.refreshList({ id: worksheet.id })}
            closeModal={() => this.setState({ editModal: false })}
          />
        )}
      </Box>
    );
  }
}

export default ProjectWorksheetListItem;
