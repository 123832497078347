import React, { PureComponent } from 'react';
import { PrimaryButton } from 'view/components/button';
import Cross from 'src/assets/cross.svg';

class AddButton extends PureComponent {
  render() {
    const { onClick, children } = this.props;
    return (
      <PrimaryButton
        green
        iconRight={<Cross style={{ margin: '0.8rem' }} />}
        onClick={onClick}
      >
        {children}
      </PrimaryButton>
    );
  }
}

export default AddButton;
