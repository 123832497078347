import React, { useCallback, useMemo } from 'react';
import { CustomLayerProps } from '@nivo/line';
import { COLORS } from 'src/features/reports/theme';

const OverSupplied = ({
  lineGenerator,
  points: steppingPoints,
  yScale
}: CustomLayerProps) => {
  // @ts-expect-error wrong type from nivo
  const getY = useCallback((y: number) => yScale(y), [yScale]);

  // Group points that are above 6
  const groupedPoints = useMemo(() => {
    if (!steppingPoints.length) return [];
    const result = [];
    let group = [];
    steppingPoints.forEach((point, index) => {
      // Less than, because y axis count starts from top to bottom
      // E.g y axis coordinate of 10 is less than 3 because 10 is closer to the top
      if (point.y <= getY(6) && point.y !== null) {
        group.push(point);
        if (index !== steppingPoints.length - 1) return;
      }

      // Push group if there is no more next point to add
      // Then empty it for a new group
      if (group.length > 0) {
        // Add closing points
        result.push([
          { x: group[0].x, y: getY(6) },
          ...group,
          { x: group[group.length - 1].x, y: getY(6) }
        ]);
        group = [];
      }
    }, []);

    return result;
  }, [steppingPoints, getY]);

  return (
    <>
      {groupedPoints.map((group, index) => (
        <path
          pointerEvents="none"
          key={index}
          d={lineGenerator(group)}
          fill={`${COLORS.BLUE.PRIMARY}40`}
        />
      ))}
      {steppingPoints?.length > 0 && (
        <>
          <rect
            x="6"
            y="7"
            width="93"
            height="17"
            fill={COLORS.WHITE}
            rx={4}
            style={{
              filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))'
            }}
          />
          <text
            pointerEvents="none"
            fill={COLORS.BLUE.DARK}
            fontSize="12px"
            fontWeight={500}
            fontFamily="DM Sans"
            transform="translate(10, 20)"
          >
            OVERSUPPLIED
          </text>
        </>
      )}
    </>
  );
};

export default OverSupplied;
