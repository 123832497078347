import React, { useState } from 'react';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { compose, filter, first, keys, map } from 'lodash/fp';
import { COLORS, PADDINGS } from 'src/theme';
import { SubHeading, Body } from 'view/components/text';
import { formatUnderscore, getShortMonth } from 'utils/format';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from 'view/components/arrow';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis
} from 'recharts';
import SectionContainer from 'view/components/section-container';
import { inArray } from 'src/utils/array';
import GA from 'react-ga';

export interface CorridorMonthlyFiguresProps {
  monthByMonth: any;
}

const CorridorMonthlyFigures = ({
  monthByMonth
}: CorridorMonthlyFiguresProps) => {
  const [currentMonthByMonth, setCurrentMonthByMonth] =
    useState('gross_sales_month');
  const monthByMonthLabels = {
    gross_sales_month: 'Gross Sales per Month',
    net_sales_month: 'Net Sales per Month',
    contract_fallovers: 'Back on Market per Month',
    outstanding_contracts: 'Outstanding Contracts per Month',
    lots_available: 'Lots Available at end of Month',
    months_supply: 'Months Supply'
  };

  const excludedKeys = [
    'period',
    'gross_sales_quarter',
    'market_share_month',
    'market_share_quarter',
    'market_share_year'
  ];
  const availableMonthByMonthStats = compose(
    filter((key: string) => !inArray(excludedKeys, key)),
    keys,
    first
  )(monthByMonth);

  const items = map((stat: string) => ({
    label:
      stat === currentMonthByMonth ? (
        <span style={{ color: COLORS.BLUE_GREEN }}>
          {monthByMonthLabels[stat]}
        </span>
      ) : (
        monthByMonthLabels[stat]
      ),
    onClick: () => {
      GA.event({
        category: 'Estate Detailed Stats',
        action: 'Toggle Monthly Figure Stat',
        label: monthByMonthLabels[stat]?.toLowerCase()
      });
      setCurrentMonthByMonth(stat);
    }
  }))(availableMonthByMonthStats);

  return (
    <SectionContainer m={PADDINGS.XXS}>
      <Box
        p={PADDINGS.S}
        flex={1}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
      >
        <SubHeading>Corridor Monthly Figures</SubHeading>
        <Box>
          <ActionMenu
            Button={(button) => (
              <Body
                style={{
                  cursor: 'pointer',
                  color: COLORS.GREY.DARK,
                  userSelect: 'none'
                }}
              >
                {formatUnderscore(
                  currentMonthByMonth === 'contract_fallovers'
                    ? 'Back on Market'
                    : formatUnderscore(currentMonthByMonth)
                )}
                &nbsp;
                {button.isOpen ? <UpArrow /> : <DownArrow />}
              </Body>
            )}
            items={items}
          />
        </Box>
      </Box>
      <ResponsiveContainer width={'100%'} height={300}>
        <LineChart
          data={monthByMonth}
          margin={{
            right: 24
          }}
        >
          <XAxis
            dataKey={'period'}
            tickFormatter={(date) =>
              new Date(date).toLocaleString('en-us', { month: 'short' })
            }
          />
          <YAxis />
          <CartesianGrid vertical={false} />
          <Tooltip labelFormatter={getShortMonth} />
          <Legend
            verticalAlign="top"
            align="left"
            iconType="line"
            height={40}
            wrapperStyle={{
              marginLeft: 24
            }}
          />
          <Line
            name={
              currentMonthByMonth === 'contract_fallovers'
                ? 'Back on Market'
                : formatUnderscore(currentMonthByMonth)
            }
            dataKey={currentMonthByMonth}
            stroke={COLORS.GRAPH.GREEN.DARK}
          />
        </LineChart>
      </ResponsiveContainer>
    </SectionContainer>
  );
};

export default CorridorMonthlyFigures;
