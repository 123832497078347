import React from 'react';
import SquareLegend from '../icons/square-legend';
import Box from '@rexlabs/box';
import { StyleSheet } from '@rexlabs/styling';
import { FONT, COLORS, MARGINS } from 'src/features/reports/theme';

const tooltipStyles = StyleSheet({
  container: {
    background: COLORS.WHITE,
    borderRadius: 2,
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontSize: 16,
    width: 'fit-content',
    padding: 10,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
  },
  text: {
    color: COLORS.BLUE.DARK,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  count: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  row: {
    marginBottom: MARGINS.TINY,
    '&:first-child': {
      marginBottom: 0
    }
  },
  note: {
    marginTop: MARGINS.TINY,
    fontSize: '12px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    color: COLORS.GREY.MEDIUM,
    width: 200
  }
});

interface CustomTooltipProps {
  points: Array<{ id: string; value: string; color: string; label: string }>;
  note?: string;
}

const CustomTooltip = ({ points, note }: CustomTooltipProps) => (
  // Points are reversed from sliceTooltip prop of line graph so we need reverse here
  <Box className={tooltipStyles.container} flexDirection="column-reverse">
    {note && <p className={tooltipStyles.note}>{note}</p>}
    {points.map((point) => (
      <Box
        className={tooltipStyles.row}
        key={point.id}
        flexDirection="row"
        alignItems="center"
      >
        {point.color && (
          <SquareLegend fill={point.color} style={{ marginRight: 8 }} />
        )}
        <p className={tooltipStyles.text}>
          {point.label}:{' '}
          <span className={tooltipStyles.count}>{point.value}</span>
        </p>
      </Box>
    ))}
  </Box>
);

export default CustomTooltip;
