import React, { PureComponent } from 'react';
import { Form, FormField, ReactForms } from 'view/components/form';
import { Heading } from '../components/text';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';
import ButtonBar from 'view/components/modal/button-bar';
import { PrimaryButton, TextButton } from 'view/components/button';
import { COLORS, PADDINGS } from 'src/theme';
import { createValidationRules } from '../../utils/form';
import settingsModel from 'data/models/entities/settings';
import { withModel } from '@rexlabs/model-generator';
import _ from 'lodash';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import { withWhereabouts } from '@rexlabs/react-whereabouts';

const defaultStyles = StyleSheet({
  title: {
    color: COLORS.GREY.DARK
  },
  section: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`,
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`,
    ':last-child': {
      borderBottom: 'none'
    }
  },
  header: {
    marginBottom: `${PADDINGS.XS}`
  },
  checkbox: {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    alignItems: 'center',
    padding: `${PADDINGS.XXS} 0`,
    color: COLORS.GREY.DARK,
    '> p': {
      margin: PADDINGS.TINY
    }
  }
});

@styled(defaultStyles)
@withModel(settingsModel)
@withWhereabouts
class ReportSettingsFrom extends PureComponent {
  submitForm = (values, props) => {
    const { settings, primary, projects, period } = this.props;
    const { name } = values;

    return settings
      .createItem({
        data: {
          primary_project: primary,
          projects: projects,
          name: name
        }
      })
      .then((response) => {
        const settingId = _.get(response, 'data.id');

        push(ROUTES.APP.REPORT_SETTINGS_OVERVIEW, {
          params: {
            id: settingId
          },
          query: {
            period: period
          }
        });
      })
      .catch(console.error);
  };

  validate = createValidationRules({
    name: 'string|required'
  });

  render() {
    const { styles: s } = this.props;

    return (
      <ReactForms
        handleSubmit={this.submitForm}
        validate={this.validate}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(props) => {
          const { isSubmitting, submitForm } = props;
          return (
            <Form name={'report-settings-form'}>
              <Box {...s('section')}>
                <Heading>Report Settings</Heading>
                <FormField
                  sendImmediate
                  label={'Setting Name'}
                  name={'name'}
                  Input={TextInput}
                />
              </Box>
              <ButtonBar>
                <TextButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.closeModal();
                  }}
                >
                  Cancel
                </TextButton>
                <PrimaryButton
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={submitForm}
                >
                  Create
                </PrimaryButton>
              </ButtonBar>
            </Form>
          );
        }}
      </ReactForms>
    );
  }
}

export default ReportSettingsFrom;
