import React, { useState, useMemo } from 'react';
import SectionContainer from 'view/components/section-container';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'theme';
import { SubHeading, Body } from 'view/components/text';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from 'view/components/arrow';
import { Tooltip, PieChart, Pie, Cell } from 'recharts';
import Divider from 'view/components/divider';
import { getColorFromValue, hexFromArray } from 'utils/colors';
import GA from 'react-ga';

export interface MarketShareProps {
  salesPerformance: any;
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles = {
  pie: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center'
  }
};

const MarketShare = ({
  salesPerformance,
  styles: s
}: MarketShareProps & DecoratorProps) => {
  const [currentPie, setCurrentPieValue] = useState<string>('This Month');

  const setCurrentPie = (newVal: string) => {
    GA.event({
      category: 'Estate Detailed Stats',
      action: 'Toggle Market Share Period',
      label: newVal.toLowerCase()
    });
    setCurrentPieValue(newVal);
  };

  const ms = useMemo(() => {
    const marketShareBrackets = (marketShare: any) => {
      // Sort the market share from highest -> lowest
      const marketShareSorted = _.orderBy(marketShare, ['value'], ['desc']);
      // Take the top 5 values
      const topCount = 5;
      const marketShareTopValues = _.take(marketShareSorted, topCount);
      // Take the remaining
      const marketShareSecondary = _.drop(marketShareSorted, topCount);

      // Calculate the remaining value from the remaining array
      const secondaryPercentage = _.sumBy(marketShareSecondary, 'value');

      // Add an other entry to the top values array
      const marketShareTop =
        marketShareSecondary.length > 0
          ? _.concat(marketShareTopValues, {
              name: 'Other',
              value: secondaryPercentage
            })
          : marketShareTopValues;

      return {
        top: marketShareTop,
        other: marketShareSecondary
      };
    };

    const marketShareMonth = _.map(salesPerformance, (value) => {
      return {
        name: _.get(value, 'project'),
        value: _.get(value, 'market_share_month')
      };
    });

    const marketShareQuarter = _.map(salesPerformance, (value) => {
      return {
        name: _.get(value, 'project'),
        value: _.get(value, 'market_share_quarter')
      };
    });

    const marketShareYear = _.map(salesPerformance, (value) => {
      return {
        name: _.get(value, 'project'),
        value: _.get(value, 'market_share_year')
      };
    });

    return {
      'This Month': marketShareBrackets(marketShareMonth),
      '3 Months': marketShareBrackets(marketShareQuarter),
      '12 Months': marketShareBrackets(marketShareYear)
    };
  }, [salesPerformance]);

  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    name,
    value
  }) => {
    const RADIAN = Math.PI / 180;

    // const radius = innerRadius + (outerRadius - innerRadius);
    const radius = outerRadius + innerRadius + 45;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <g>
        <text x={x} y={y} fill="black" textAnchor={'middle'}>
          <tspan>{name}</tspan>
        </text>
        <text x={x} y={y + 20} fill="black" textAnchor={'middle'}>
          <tspan>{`${value}%`}</tspan>
        </text>
      </g>
    );
  };

  const renderCustomOtherLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value
  }) => {
    const RADIAN = Math.PI / 180;

    // const radius = innerRadius + (outerRadius - innerRadius);
    const radius = outerRadius + innerRadius + 45;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <g>
        <text x={x} y={y} fill="black" textAnchor={'middle'}>
          <tspan>{`${value}%`}</tspan>
        </text>
      </g>
    );
  };

  return (
    <SectionContainer m={PADDINGS.XXS}>
      <Box
        p={PADDINGS.S}
        flex={1}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
      >
        <SubHeading>Market Share {currentPie}</SubHeading>
        <Box>
          <ActionMenu
            Button={(button) => (
              <Body
                style={{
                  cursor: 'pointer',
                  color: COLORS.GREY.DARK,
                  userSelect: 'none'
                }}
              >
                Change Period&nbsp;
                {button.isOpen ? <UpArrow /> : <DownArrow />}
              </Body>
            )}
            items={_.map(['This Month', '3 Months', '12 Months'], (item) => ({
              label: item,
              onClick: () => setCurrentPie(item)
            }))}
          />
        </Box>
      </Box>
      <Box
        flex={1}
        flexDirection={'row'}
        justifyContent={'center'}
        flexWrap={'wrap'}
      >
        <Box {...s('pie')}>
          {ms[currentPie].other.length > 0 && <SubHeading>Top 5</SubHeading>}
          <Box style={{}}>
            <PieChart
              width={550}
              height={450}
              margin={{ top: 20, right: 10, bottom: 25, left: 10 }}
            >
              <Pie data={ms[currentPie].top} label={renderCustomLabel}>
                {ms[currentPie].top.map((entry, index) => (
                  <Cell
                    key={`market-share-${index}`}
                    fill={hexFromArray(
                      getColorFromValue(index, 0, ms[currentPie].top.length)
                    )}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `${value}%`} />
              {/* <Legend verticalAlign='top' /> */}
            </PieChart>
          </Box>
        </Box>
        {ms[currentPie].other.length > 0 && (
          <Box {...s('pie')}>
            <SubHeading>Others</SubHeading>
            <Box
              style={{
                height: '100%'
              }}
            >
              <PieChart
                width={350}
                height={450}
                margin={{ top: 10, right: 0, bottom: 25, left: 30 }}
              >
                <Pie
                  data={ms[currentPie].other}
                  cy={200}
                  label={renderCustomOtherLabel}
                >
                  {ms[currentPie].other.map((entry, index) => (
                    <Cell
                      key={`market-share-${index}`}
                      fill={hexFromArray(
                        getColorFromValue(index, 0, ms[currentPie].other.length)
                      )}
                    />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${value}%`} />
              </PieChart>
            </Box>
          </Box>
        )}
      </Box>
    </SectionContainer>
  );
};

export default styled(StyleSheet(defaultStyles))(MarketShare);
