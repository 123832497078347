import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from 'view/components/text';
import { Check as Checkbox } from 'view/components/checkbox';
import _ from 'lodash';
import { FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500'
  },
  truncate: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  status: {
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  }
});

@styled(defaultStyles)
class RegionProjectAddItem extends PureComponent {
  formatDate = (createdAt) => {
    const date = new Date(createdAt * 1000);
    return `${date.getDate()} ${date.toLocaleString('en-us', {
      month: 'long'
    })} ${date.getFullYear()}`;
  };

  render() {
    const { styles: s, project, toggle, checked } = this.props;
    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={12}
          style={{ alignItems: 'center ' }}
          {...s('grid')}
          onClick={toggle}
        >
          <Column
            width={1}
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
            {...s('checkbox')}
          >
            <Checkbox controlled={true} value={!!checked} />
          </Column>
          <Column width={3}>
            <Small {...s('text', 'truncate')}>{_.get(project, 'title')}</Small>
          </Column>
          <Column width={2}>
            <Small {...s('text')}>
              {this.formatDate(_.get(project, 'created_at'))}
            </Small>
          </Column>
          <Column width={2}>
            <Small {...s('status')}>
              {_.get(project, 'status').split('_').join(' ')}
            </Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{_.get(project, 'summary')}</Small>
          </Column>
        </Grid>
      </Box>
    );
  }
}

export default RegionProjectAddItem;
