import React, { useContext, useEffect, useState } from 'react';
import Box from '@rexlabs/box';
import { styled, Styles, StyleSheet, keyframes } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/features/reports/theme';
import { CloseIcon } from '../icons';
import {
  SnackbarContext,
  SnackbarMessage
} from 'src/features/reports/providers/snackbar-provider';

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
    right: '-342px'
  },
  '100%': {
    opactity: 1,
    right: '0px'
  }
});

const styles = StyleSheet({
  container: {
    backgroundColor: COLORS.BLUE.DARK,
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.16), 0px 8px 16px rgba(11, 30, 73, 0.2)',
    borderRadius: '10px 0 0 10px',
    width: '342px',
    position: 'fixed',
    zIndex: 50,
    paddingRight: '16px',
    animation: `${fadeIn} 0.3s ease-in-out`,
    transition: 'all 0.3s ease-in-out'
  },
  closeContainer: {
    cursor: 'pointer',
    minWidth: '49px',
    '&:hover': {
      opacity: 0.8
    }
  },
  close: {
    width: '16px',
    height: '16px',
    '& path': {
      stroke: COLORS.WHITE,
      strokeWidth: 2
    }
  },
  text: {
    flex: 1,
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: COLORS.WHITE,
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 500,
    fontSize: '16px',
    whiteSpace: 'nowrap'
  }
});

interface SnackbarItemProps extends SnackbarMessage {
  styles: Styles;
  index: number;
}

const SnackbarItem = ({ styles: s, content, id, index }: SnackbarItemProps) => {
  const { removeMessage } = useContext(SnackbarContext);
  const [isHidden, setIsHidden] = useState(false);

  const removeHandler = () => {
    setIsHidden(true);
    // Finish animation first
    setTimeout(() => removeMessage(id), 300);
  };

  // Hide after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(removeHandler, 5000);
    return () => clearTimeout(timeout);
    // It will cause loop when I add removeHandler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      style={{
        bottom: `${index * 53 + 41}px`,
        right: isHidden ? '-342px' : '0px',
        opacity: isHidden ? 0 : 1
      }}
      flexDirection="row"
      alignItems="center"
      {...s('container')}
    >
      <Box
        width="49px"
        height="43px"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        onClick={removeHandler}
        {...s('closeContainer')}
      >
        <CloseIcon {...s('close')} />
      </Box>
      <span {...s('text')}>{content}</span>
    </Box>
  );
};

export default styled(styles)(SnackbarItem);
