import React, { Component } from 'react';
import _ from 'lodash';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';

import ButtonBar from 'view/components/modal/button-bar';
import StageModal from 'view/modals/stage';
import withError from 'view/containers/with-error';
import withAction from 'view/containers/with-action';
import ErrorListModal from 'view/components/modal/error-list';
import { PrimaryButton, TextButton } from 'view/components/button';
import { Form, ReactForms } from 'view/components/form';

import { createValidationRules } from 'utils/form';
import { inArray } from 'utils/array';
import { api } from 'utils/api-client';
import { formatNumber, stripNumber, cleanNumbers } from 'utils/format';

import lotsModel from 'data/models/entities/lots';
import worksheetsModel from 'data/models/entities/worksheets';
import {
  MIN_PRICE_RANGE,
  MAX_PRICE_RANGE,
  MAX_LOT_SIZE,
  MIN_LOT_SIZE,
  OUT_OF_LOTSIZE_RANGE_ERROR,
  OUT_OF_PRICE_RANGE_ERROR
} from 'data/defaults';

import LotFormFields from './lot-form-fields';
import { COLORS, PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  title: {
    color: COLORS.GREY.DARK
  },
  section: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`,
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`,
    ':last-child': {
      borderBottom: 'none'
    }
  },
  header: {
    marginBottom: `${PADDINGS.XS}`
  },
  checkbox: {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    alignItems: 'center',
    padding: `${PADDINGS.XXS} 0`,
    color: COLORS.GREY.DARK,
    '> p': {
      margin: PADDINGS.TINY
    }
  }
});

@withError.withPropName('errorModal')
@withAction.withPropName('actionModal')
@withModel(worksheetsModel)
@withModel(lotsModel)
@styled(defaultStyles)
class LotForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addStageModal: false,
      selectValue: '',
      last: {},
      corner: false,
      irregular: false,
      powerlines: false,
      nearbyAmenity: false,
      parkWetlands: false,
      substation: false,
      fill: false,
      fall: false,
      lotOrientation: '',
      doubleStorey: false,

      depthOverride: false,
      priceOverride: false,
      statusOverride: false,
      frontageDepthOverride: false,
      lotSizeOverride: false,

      fetchingLast: false,
      stageOptions: [],

      errors: []
    };

    this.details = React.createRef();
    this.area = React.createRef();
    this.pricing = React.createRef();
    this.characteristics = React.createRef();
  }

  handleKeyPress = (e, field) => {
    if (e.key === ' ') {
      this.toggle(field);
    }
  };

  submitForm = (values, props) => {
    const { projectId, worksheetId, errorModal, closeModal, onSubmit, lot } =
      this.props;
    const isUpdate = !!_.get(lot, 'id');

    const price = parseInt(stripNumber(values.price), 10);
    const negotiationBuffer = parseInt(stripNumber(values.negotiationBuffer));
    const priceChange = parseInt(stripNumber(values.priceChange));
    const pricePerM2 = parseInt(stripNumber(values.dollarPerM2));
    const rebate = parseInt(stripNumber(values.incentives));

    const payload = {
      lot_number: values.lotNumber,
      titled: values.titled === 'true' ? 1 : 0,
      stage_no: values.stage,
      status: values.status,

      depth: stripNumber(values.depth) || null,
      lot_size: parseInt(stripNumber(values.lotSize), 10) || null,
      lot_orientation: values.lotOrientation || '',
      frontage: stripNumber(values.frontage) || null,

      price: price || null,
      negotiation_buffer: negotiationBuffer || 0,
      price_change: price ? priceChange || 0 : null,
      price_per_m2: pricePerM2 || null,
      rebate: rebate || 0,

      is_corner: this.state.corner || false,
      is_irregular: this.state.irregular || false,
      is_powerlines: this.state.powerlines || false,
      is_nearby_amenity: this.state.nearbyAmenity || false,
      is_park_wetlands: this.state.parkWetlands || false,
      is_substation: this.state.substation || false,
      is_fill: this.state.fill || false,
      is_fall: this.state.fall || false,
      is_double_storey: this.state.doubleStorey || false,

      notes: values.notes || null
    };

    // We're not using model generator here as the refreshList call after a create or update takes too long when the
    // list gets too long. At the time Core did not want to paginate the list.
    const promise = isUpdate
      ? api.put(
          `/projects/${projectId}/worksheets/${worksheetId}/listings/${lot.id}`,
          payload
        )
      : api.post(
          `/projects/${projectId}/worksheets/${worksheetId}/listings`,
          payload
        );

    return promise
      .then(() => {
        // Refresh values
        onSubmit();
        // Close the modal
        closeModal();
      })
      .catch((e) => {
        errorModal.open(e);
      });
  };

  validate = (values, props) => {
    // Clean values before validation
    values = cleanNumbers(values, [
      'price',
      'incentives',
      'negotiationBuffer',
      'priceChange',
      'priceChange',
      'dollarPerM2',
      'depth',
      'lotSize',
      'lotSize',
      'frontage'
    ]);

    const validation = createValidationRules({
      stage: 'string|required',
      lotNumber: 'string|required',
      status: 'required',

      price: 'integer|min:1',
      incentives: 'integer|min:0|required',
      negotiationBuffer: 'integer|min:0',
      priceChange: 'integer',
      // dollarPerM2: 'integer|min:1',

      depth: 'numeric|min:1',
      lotSize: 'integer|min:1|required',
      frontage: 'numeric|min:1',
      notes: 'string|max:255'
    })(values, props);

    const first = _.head(Object.keys(validation));
    const details = ['stage', 'lotNumber', 'status', 'titled', 'notes'];
    const area = ['lotSize', 'frontage', 'depth'];
    const pricing = [
      'price',
      'incentives',
      'negotiationBuffer',
      'priceChange',
      'dollarPerM2'
    ];

    if (first) {
      if (details.indexOf(first) !== -1 && this.details) {
        this.details.current.scrollIntoView();
      }

      if (area.indexOf(first) !== -1 && this.area) {
        this.area.current.scrollIntoView();
      }

      if (pricing.indexOf(first) !== -1 && this.pricing) {
        this.pricing.current.scrollIntoView();
      }
    }

    return validation;
  };

  calculateDepth = (frontage, lotSize, props) => {
    const { values, setFieldValue } = props;
    const { depthOverride } = this.state;

    if (depthOverride && _.get(values, 'depth', '').length > 0) {
      return;
    }
    if (stripNumber(frontage) < 1) return;

    const value = stripNumber(lotSize) / stripNumber(frontage);
    setFieldValue(
      'depth',
      formatNumber(value.toFixed(1).replace(/\.0+$/, '')),
      false
    );
  };

  calculateDollarPerM2 = (price, size, props) => {
    const { setFieldValue } = props;

    if (stripNumber(size) < 1) {
      return;
    }

    if (!price) {
      setFieldValue('dollarPerM2', '', false);
      return;
    }

    const value = Math.round(stripNumber(price) / stripNumber(size));
    setFieldValue('dollarPerM2', formatNumber(value), false);
  };

  toggle = (field) => {
    if (this.props.published) {
      return;
    }

    this.setState({ [field]: !this.state[field] });
  };

  setStateProxy = (newState) => this.setState(newState);

  fetchStageOptions = () => {
    const { stages } = this.props;
    // Fix to retrieve all stages
    const recordsToFetch = 300;
    stages.fetchList({ per_page: recordsToFetch }).then((response) => {
      const allStages = response.data;

      if (allStages.length > 0) {
        this.setState({
          stageOptions: allStages
            .map((stage) => stage.item)
            .sort((a, b) => a.stage_no - b.stage_no)
            .map((val) => {
              return {
                value: val.stage_no.toString(),
                label: val.stage_no.toString()
              };
            })
        });
      }
    });
  };

  componentDidMount() {
    this.fetchStageOptions();
  }

  componentWillMount() {
    const { lots, lot, projectId, worksheetId } = this.props;
    if (lot) {
      this.setState({ fetchingLast: true });
      lots
        .fetchLastListing({
          projectId: projectId,
          worksheetId: worksheetId,
          lotNumber: _.get(lot, 'lot_number'),
          include: 'period'
        })
        .then((res) => {
          this.setState({ last: res.data, fetchingLast: false });
        })
        .catch(() => this.setState({ fetchingLast: false }));

      this.setState({
        corner: lot.is_corner,
        irregular: lot.is_irregular,
        powerlines: lot.is_powerlines,
        nearbyAmenity: lot.is_nearby_amenity,
        parkWetlands: lot.is_park_wetlands,
        substation: lot.is_substation,
        fill: lot.is_fill,
        fall: lot.is_fall,
        lotOrientation: lot.lot_orientation,
        doubleStorey: lot.is_double_storey
      });
    }
  }

  render() {
    const {
      stages,
      projectId,
      worksheetId,
      lot,
      published,
      errorModal: { Error },
      actionModal: { Action, ...actionModal }
    } = this.props;
    const { last, depthOverride, fetchingLast, stageOptions } = this.state;

    const isUpdate = !!_.get(lot, 'id');
    const stage = _.get(lot, 'stage');

    const stageNumbers = stageOptions.concat({
      value: 'add_stage',
      label: '+ Add Stage'
    });

    return (
      <Box>
        <ReactForms
          handleSubmit={this.submitForm}
          validate={this.validate}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {(props) => {
            const { isSubmitting, setFieldValue, values, submitForm } = props;

            return (
              <Form name="lot-form">
                <LotFormFields
                  state={this.state}
                  setState={this.setStateProxy}
                  isUpdate={isUpdate}
                  published={published}
                  stage={stage}
                  stageOptions={stageNumbers}
                  projectId={projectId}
                  worksheetId={worksheetId}
                  lot={lot}
                  actionModal={actionModal}
                  last={last}
                  setFieldValue={setFieldValue}
                  depthOverride={depthOverride}
                  pricing={this.pricing}
                  details={this.details}
                  area={this.area}
                  values={values}
                  fetchingLast={fetchingLast}
                />

                <ButtonBar>
                  <TextButton
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.closeModal();
                    }}
                  >
                    Cancel
                  </TextButton>
                  {!published && (
                    <PrimaryButton
                      green
                      form="lot-form"
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting}
                      type={'button'}
                      onClick={(e) => {
                        e.preventDefault();

                        const {
                          last,
                          priceOverride,
                          statusOverride,
                          frontageDepthOverride,
                          lotSizeOverride
                        } = this.state;

                        // Run extra validation
                        // Ensure the require fields are set
                        if (!values.incentives) {
                          setFieldValue('incentives', '0', false);
                        }
                        if (!values.negotiationBuffer) {
                          setFieldValue('negotiationBuffer', '0', false);
                        }

                        const errors = [];

                        // Validate last lot
                        if (
                          !statusOverride &&
                          last &&
                          !inArray(
                            ['Sold', 'Back on Market'],
                            _.get(last, 'status')
                          ) &&
                          _.get(values, 'status') === 'Back on Market'
                        ) {
                          errors.push(
                            '"Back on Market" should be attributed ONLY to SOLD allotments that were marked as SOLD on a previous sheet.'
                          );
                        }

                        if (
                          last &&
                          _.get(last, 'status') === 'Sold' &&
                          !inArray(
                            ['Back on Market', 'BOM/Sold'],
                            _.get(values, 'status')
                          )
                        ) {
                          errors.push(
                            'The allotment you have entered has previously been marked as sold. Status should be "Back on Market" or "BOM/Sold"'
                          );
                        }

                        // Check if price is empty or out of range
                        if (!priceOverride && !values.price) {
                          errors.push(
                            'No price entered, please confirm that pricing information is unavailable for this allotment'
                          );
                        } else if (!priceOverride) {
                          if (
                            values.price < MIN_PRICE_RANGE ||
                            values.price > MAX_PRICE_RANGE
                          ) {
                            errors.push(OUT_OF_PRICE_RANGE_ERROR);
                          }
                        }

                        // Check if lotSize is empty or out of range
                        if (!lotSizeOverride) {
                          if (
                            values.lotSize < MIN_LOT_SIZE ||
                            values.lotSize > MAX_LOT_SIZE
                          ) {
                            errors.push(OUT_OF_LOTSIZE_RANGE_ERROR);
                          }
                        }

                        // Confirm null frontage & depth
                        if (
                          !frontageDepthOverride &&
                          !values.frontage &&
                          !values.depth
                        ) {
                          errors.push(
                            'No frontage or depth entered, please confirm supervisor approval that frontage and depth information is unavailable for this allotment'
                          );
                        } else if (!values.frontage && values.depth) {
                          errors.push(
                            'No frontage entered, please confirm supervisor approval that frontage information is unavailable for this allotment'
                          );
                        } else if (!values.depth && values.frontage) {
                          errors.push(
                            'No depth entered, please confirm supervisor approval that depth information is unavailable for this allotment'
                          );
                        }

                        if (errors.length > 0) {
                          this.setState({
                            errors: errors
                          });
                          return;
                        }

                        submitForm();
                      }}
                    >
                      {isUpdate ? 'Update' : 'Create'}
                    </PrimaryButton>
                  )}
                </ButtonBar>
                {this.state.errors.length > 0 && (
                  <ErrorListModal
                    title={'Validation Error'}
                    items={this.state.errors}
                    onCancel={() => this.setState({ errors: [] })}
                    onSubmit={() => {
                      this.setState({ errors: [] });
                      submitForm();
                    }}
                  />
                )}
                {this.state.addStageModal && (
                  <StageModal
                    projectId={projectId}
                    worksheetId={worksheetId}
                    closeModal={() => {
                      this.setState({
                        addStageModal: false
                      });
                      this.fetchStageOptions();
                      stages.refreshList();
                    }}
                    onSubmit={(stage) => {
                      setFieldValue('stage', _.get(stage, 'stage_no'), true);
                    }}
                  />
                )}
              </Form>
            );
          }}
        </ReactForms>
        <Action />
        <Error />
      </Box>
    );
  }
}

export default LotForm;
