import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import PropTypes from 'prop-types';
import { PADDINGS } from 'src/theme';
import { Small } from 'view/components/text';
import { formatNumber } from 'utils/format';
import { getColorFromValue } from 'utils/colors';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%',
    padding: PADDINGS.XXS
  },

  row: {
    padding: PADDINGS.TINY
  }
});

@styled(defaultStyles)
class SimpleHeatBox extends PureComponent {
  render() {
    const {
      styles: s,
      min,
      max,
      globalMin,
      globalMax,
      total,
      hasBackground
    } = this.props;

    const average = (parseInt(min) + parseInt(max)) / 2;
    const background = getColorFromValue(average, globalMin, globalMax);

    return (
      <Box
        {...s('container')}
        style={{
          backgroundColor: hasBackground
            ? `rgb(${background[0]}, ${background[1]}, ${background[2]})`
            : '#FFFFFF'
        }}
        flex={1}
        justifyContent={'center'}
        alignItems={'middle'}
      >
        <Box
          {...s('row')}
          flex={1}
          flexDirection={'row'}
          alignItems={'flex-end'}
        >
          <Small style={{ fontWeight: 'bold', paddingRight: PADDINGS.XS }}>
            {min !== max
              ? '$' + formatNumber(min) + ' - $' + formatNumber(max)
              : '$' + formatNumber(max)}
          </Small>

          <Small style={{ fontWeight: 'bold' }}>({total})</Small>
        </Box>
      </Box>
    );
  }
}

SimpleHeatBox.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  globalMin: PropTypes.number.isRequired,
  globalMax: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  hasBackground: PropTypes.boolean
};

export default SimpleHeatBox;
