import React, { useMemo } from 'react';
import { maxBy, minBy } from 'lodash';
import { styled, Styles, StyleSheet } from '@rexlabs/styling';
import { DEPTHS, FRONTAGES, MARKET } from './constants';
import { COLORS, FONT } from 'src/features/reports/theme';
import { ProductTypePerformanceReport } from 'types/graph';
import { HeatMapItem } from '../heat-map';
import { numberWithCommas } from 'utils/format';

const styles = StyleSheet({
  table: {
    border: `1px solid ${COLORS.GREY.POWDER}`,
    width: '100%'
  },
  cell: {
    borderRight: `1px solid ${COLORS.GREY.POWDER}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:last-child': {
      borderRight: 'none'
    }
  },
  row: {
    height: '60px',
    borderBottom: `1px solid ${COLORS.GREY.POWDER}`,
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '146px repeat(5, 1fr)',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  label: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 500,
    fontSize: '14px',
    color: COLORS.BLUE.DARK,
    textAlign: 'center',
    padding: '8px'
  },
  th: {
    fontSize: '12px'
  },
  value: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 500,
    fontSize: '10px',
    color: COLORS.BLACK,
    textAlign: 'center'
  },
  bold: {
    fontWeight: 700
  },
  heatMap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px'
  }
});

interface ProductTypePerformanceTableProps {
  styles: Styles;
  report: ProductTypePerformanceReport[];
  market: typeof MARKET[number]['key'];
}

const ProductTypePerformanceTable = ({
  styles: s,
  report,
  market
}: ProductTypePerformanceTableProps) => {
  const formattedReport: Record<string, ProductTypePerformanceReport> =
    useMemo(() => {
      const result = {};
      report.forEach((data) => {
        Object.assign(result, { [`${data.dimension}`]: data });
      });
      return result;
    }, [report]);

  const range = useMemo(() => {
    if (!report.length) return { lowestHeat: 0, highestHeat: 0 };
    if (market === 'time_on_market') {
      return {
        lowestHeat: maxBy(report, 'time_on_market').time_on_market,
        highestHeat: minBy(report, 'time_on_market').time_on_market
      };
    }
    return {
      lowestHeat: minBy(report, 'market_share').market_share,
      highestHeat: maxBy(report, 'market_share').market_share
    };
  }, [report, market]);

  return (
    <div {...s('table')}>
      <div {...s('row')} style={{ height: 'auto' }}>
        <p {...s('cell', 'label', 'th')}>FRONTAGE X DEPTH</p>
        {DEPTHS.map((depth) => (
          <p key={depth} {...s('cell', 'label', 'bold', 'th')}>
            {depth}
          </p>
        ))}
      </div>
      {FRONTAGES.map((frontage) => (
        <div key={frontage} {...s('row')}>
          <p {...s('cell', 'label', 'bold')}>{frontage}</p>
          {DEPTHS.map((depth) => (
            <div key={depth} {...s('cell')}>
              {formattedReport[`${frontage}x${depth}`] && (
                <HeatMapItem
                  {...s('heatMap')}
                  {...range}
                  heatValue={formattedReport[`${frontage}x${depth}`][market]}
                >
                  <p {...s('value', 'bold')}>
                    {market === 'time_on_market'
                      ? 'Time on Market'
                      : 'Market Share'}
                    : {formattedReport[`${frontage}x${depth}`][market]}
                    {market === 'market_share' ? '%' : ''}
                  </p>
                  <p {...s('value')}>
                    Sold: {formattedReport[`${frontage}x${depth}`].sold_count}
                  </p>
                  <p {...s('value')}>
                    Median Price: $
                    {numberWithCommas(
                      formattedReport[`${frontage}x${depth}`].median_price
                    )}
                  </p>
                </HeatMapItem>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default styled(styles)(ProductTypePerformanceTable);
