import React, { FC } from 'react';
import _ from 'lodash';
import Box from '@rexlabs/box';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { compose } from 'utils/compose';

import { COLORS, FONT } from 'src/features/reports/theme';
import ROUTES from 'src/features/reports/routes';
import SidebarOption from 'src/features/reports/components/sidebar/sidebar-option';

import GlobeIcon from 'assets/report-menu-icons/globe-icon.svg';
import TrendsIcon from 'assets/report-menu-icons/trends-icon.svg';
import PositionIcon from 'assets/report-menu-icons/position-icon.svg';
import BreakdownIcon from 'assets/report-menu-icons/breakdown-icon.svg';
import { Whereabouts } from 'types/common/whereabouts';
import { ReportQueryParams } from 'types/params/report-query-params';
import BurgerIcon from '../icons/burger-icon';
import GripLogo from '../icons/grip-logo';

const defaultStyles = StyleSheet({
  container: {
    color: 'white'
  },
  wrapLogo: {
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  menu: {
    '& a': {
      color: COLORS.WHITE,
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      width: '100%',
      display: 'flex',
      fontSize: '1.8rem',
      textDecoration: 'none',
      height: 53,
      alignItems: 'center',
      transition: 'all 0.2s ease-in-out'
    },

    '& svg': {
      stroke: COLORS.WHITE
    },

    '& a:hover, &a:hover svg': {
      backgroundColor: '#fcc7a259',
      stroke: COLORS.WHITE
    },

    '& a.active, & a.active svg': {
      color: COLORS.BLUE.DARK,
      backgroundColor: COLORS.PINK.LIGHT,
      stroke: COLORS.BLUE.DARK
    },

    '& li li a': {
      fontWeight: FONT.WEIGHTS.NORMAL
    }
  },
  reportLogo: {
    paddingTop: 24,
    paddingBottom: 30,
    height: 156
  }
});

interface SidebarProps {
  isSidebarOpen: boolean;
  onToggle: () => void;
  styles: Styles;
  whereabouts: Pick<Whereabouts<ReportQueryParams>, 'query'>;
}

const Sidebar: FC<SidebarProps> = ({
  styles: s,
  whereabouts,
  isSidebarOpen,
  onToggle
}) => {
  return (
    <Box {...s('container')}>
      <Box
        flex={1}
        justifyContent="center"
        alignItems="center"
        {...s.with('reportLogo')({
          width: !isSidebarOpen ? 60 : '100%',
          paddingTop: !isSidebarOpen ? 20 : 24
        })}
      >
        <Box
          onClick={onToggle}
          style={{ cursor: 'pointer' }}
          justifyContent="center"
        >
          {isSidebarOpen ? <GripLogo /> : <BurgerIcon />}
        </Box>
      </Box>

      <Box>
        <menu {...s('menu')}>
          <SidebarOption
            text="Key Insights"
            to={ROUTES.APP.REPORT_OVERVIEW}
            query={whereabouts.query}
            IconComponent={<GlobeIcon />}
            iconOnly={!isSidebarOpen}
          />
          <SidebarOption
            text="Product Trends"
            to={ROUTES.APP.REPORT_PRODUCT_TRENDS}
            query={whereabouts.query}
            IconComponent={<TrendsIcon />}
            iconOnly={!isSidebarOpen}
          />
          <SidebarOption
            text="Project Positioning"
            to={ROUTES.APP.REPORT_PROJECT_POSITIONING}
            query={whereabouts.query}
            IconComponent={<PositionIcon />}
            iconOnly={!isSidebarOpen}
          />
          <SidebarOption
            text="Project Breakdown"
            to={ROUTES.APP.REPORT_PROJECT_BREAKDOWN}
            query={whereabouts.query}
            IconComponent={<BreakdownIcon />}
            iconOnly={!isSidebarOpen}
          />
        </menu>
      </Box>
    </Box>
  );
};

export default compose<Omit<SidebarProps, 'styles' | 'whereabouts'>>(
  styled(defaultStyles),
  withWhereabouts
)(Sidebar);
