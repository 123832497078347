import React, { SVGProps } from 'react';

const UserIcon = (props: SVGProps<null>) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.9965 4.00346C20.5845 7.5914 20.5845 13.4086 16.9965 16.9965C13.4086 20.5845 7.59138 20.5845 4.00346 16.9965C0.415514 13.4086 0.415514 7.59138 4.00346 4.00346C7.5914 0.415514 13.4086 0.415514 16.9965 4.00346"
        stroke="#0B1E49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9172 17.9081C15.5252 16.0298 13.2609 14.5833 10.4996 14.5833C7.73824 14.5833 5.47403 16.0298 5.08203 17.9081"
        stroke="#0B1E49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6655 6.29275C13.8615 7.48873 13.8615 9.4278 12.6655 10.6238C11.4695 11.8198 9.53046 11.8198 8.33448 10.6238C7.13851 9.4278 7.13851 7.48873 8.33448 6.29275C9.53046 5.09678 11.4695 5.09678 12.6655 6.29275"
        stroke="#0B1E49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
