import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import LoadingSpinner from '@rexlabs/loading-spinner';
import Box from '@rexlabs/box';
import { Grid, Column } from '@rexlabs/grid';
import { TextInput } from '@rexlabs/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';

import { Heading, SubHeading, Tiny } from 'view/components/text';
import { FormField } from 'view/components/form';
import { SelectInput } from 'view/components/input/select';
import { DollarInput } from 'view/components/input/text';
import { Check as Checkbox } from 'view/components/checkbox';

import lotsModel from 'data/models/entities/lots';
import { formatNumber, formatPeriod, stripNumber } from 'utils/format';
import { COLORS, PADDINGS } from 'src/theme';

import {
  MIN_LOT_SIZE,
  MAX_LOT_SIZE,
  MIN_PRICE_RANGE,
  MAX_PRICE_RANGE,
  OUT_OF_LOTSIZE_RANGE_ERROR,
  OUT_OF_PRICE_RANGE_ERROR
} from 'data/defaults';

const defaultStyles = StyleSheet({
  title: {
    color: COLORS.GREY.DARK
  },
  section: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`,
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`,
    ':last-child': {
      borderBottom: 'none'
    }
  },
  header: {
    marginBottom: `${PADDINGS.XS}`
  },
  checkbox: {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    alignItems: 'center',
    padding: `${PADDINGS.XXS} 0`,
    color: COLORS.GREY.DARK,
    '> p': {
      margin: PADDINGS.TINY
    }
  }
});

const closeText = 'Oops, better check that';
const submitText = `I know what I'm doing`;

// Need to extract these options on a separate file to reduce code lines.
// Some options also are being used on other files.
// https://rexsoftware.atlassian.net/browse/BLKCORE-156
const lotOrientationOptions = [
  {
    value: 'north',
    label: 'North'
  },
  {
    value: 'south',
    label: 'South'
  },
  {
    value: 'east',
    label: 'East'
  },
  {
    value: 'west',
    label: 'West'
  }
];

const statusOptions = [
  {
    value: 'Available',
    label: 'Available'
  },
  {
    value: 'On Hold',
    label: 'On Hold'
  },
  {
    value: 'Deposit',
    label: 'Deposit'
  },
  {
    value: 'Sold',
    label: 'Sold'
  },
  {
    value: 'Back on Market',
    label: 'Back on Market'
  },
  {
    value: 'NFS',
    label: 'NFS'
  },
  {
    value: 'BOM/Sold',
    label: 'BOM/Sold'
  }
];

@styled(defaultStyles)
@withModel(lotsModel)
class LotFormFields extends PureComponent {
  handleKeyPress = (e, field) => {
    if (e.key === ' ') {
      this.toggle(field);
    }
  };

  calculateDepth = (frontage, lotSize) => {
    const { values, setFieldValue, depthOverride } = this.props;

    if (depthOverride && _.get(values, 'depth', '').length > 0) {
      return;
    }
    if (stripNumber(frontage) < 1) return;

    const value = stripNumber(lotSize) / stripNumber(frontage);
    setFieldValue(
      'depth',
      formatNumber(value.toFixed(1).replace(/\.0+$/, '')),
      false
    );
  };

  calculateDollarPerM2 = (price, size) => {
    const { setFieldValue } = this.props;

    if (stripNumber(size) < 1) {
      return;
    }

    if (!price) {
      setFieldValue('dollarPerM2', '', false);
      return;
    }

    const value = Math.round(stripNumber(price) / stripNumber(size));
    setFieldValue('dollarPerM2', formatNumber(value), false);
  };

  calculatePriceChange = (lastLot, price) => {
    if (
      _.isEmpty(lastLot) ||
      !price ||
      !_.get(lastLot, 'price') ||
      parseInt(_.get(lastLot, 'price')) === 0
    ) {
      return null;
    }

    const change = stripNumber(price) - _.get(lastLot, 'price');
    return change.toFixed(2).replace(/(\.0+|0)$/, '');
  };

  toggle = (field) => {
    const { published, setState, state } = this.props;

    if (published) {
      return;
    }

    setState({ [field]: !state[field] });
  };

  onStageChange = (e) => {
    const { setFieldValue, setState } = this.props;

    if (e.target.value === 'add_stage') {
      setFieldValue('stage', '');
      setState({
        addStageModal: true
      });
    } else {
      setState({
        selectValue: e.target.value
      });
    }
  };

  onLotNumberBlur = (e) => {
    const { projectId, worksheetId, setState, setFieldValue, values, lots } =
      this.props;
    setState({ fetchingLast: true });
    lots
      .fetchLastListing({
        projectId: projectId,
        worksheetId: worksheetId,
        lotNumber: e.target.value,
        include: 'period'
      })
      .then((res) => {
        setState({ last: res.data, fetchingLast: false });
        const priceChange = this.calculatePriceChange(res.data, values.price);
        if (priceChange) {
          setFieldValue('priceChange', formatNumber(priceChange), false);
        }
      })
      .catch(() => this.props.setState({ fetchingLast: false }));
  };

  onLotSizeFocus = (e) => {
    const { setFieldValue } = this.props;

    setFieldValue('lotSize', stripNumber(e.target.value), false);
  };

  setLotSize = (lotSize) => {
    const { values, setFieldValue } = this.props;

    this.calculateDepth(values.frontage, lotSize);
    this.calculateDollarPerM2(values.price, lotSize);

    setFieldValue('lotSize', formatNumber(lotSize), false);
  };

  onLotSizeBlur = (e) => {
    if (!_.isEmpty(e.target.value)) {
      const { actionModal, state, setState } = this.props;

      // `lotSizeOverride` is a flag to check whether we're allowing the value of lotSize
      // to have a value beyond the min/max range.

      const isOutOfRange =
        e.target.value < MIN_LOT_SIZE || e.target.value > MAX_LOT_SIZE;

      if (!state.lotSizeOverride && isOutOfRange) {
        actionModal.notify({
          onSubmit: () => {
            setState({ lotSizeOverride: true }, () =>
              this.setLotSize(e.target.value)
            );
            actionModal.close();
          },
          message: OUT_OF_LOTSIZE_RANGE_ERROR,
          onCancel: () => this.setLotSize(e.target.value),
          submitText,
          closeText,
          red: true
        });
      } else {
        this.setLotSize(e.target.value);
      }
    }
  };

  onFrontageFocus = (e) => {
    const { setFieldValue } = this.props;
    setFieldValue('frontage', stripNumber(e.target.value), false);
  };

  onFrontageBlur = (e) => {
    const { values, setFieldValue } = this.props;
    if (!e.target.value || e.target.value === '') {
      setFieldValue('depth', '', false);
      return;
    }
    this.calculateDepth(e.target.value, values.lotSize);
    setFieldValue('frontage', formatNumber(e.target.value), false);
  };

  onDepthKeyDown = (e) => {
    const { setState, state } = this.props;

    // Make sure the key is a proper input value
    // We don't want to check for values like tab/escape/ctrl
    const inp = String.fromCharCode(e.keyCode);
    if (!state.editedDepth && /[a-zA-Z0-9-_ ]/.test(inp)) {
      setState({ editedDepth: true });
    }
  };

  onDepthBlur = (e) => {
    const { values, setState, state, setFieldValue, actionModal } = this.props;

    if (!e.target.value) {
      this.calculateDepth(values.frontage, values.lotSize);
      setState({ depthOverride: false });
    } else if (state.editedDepth && !state.depthOverride) {
      actionModal.open(
        () => {
          setState({ depthOverride: true });
          actionModal.close();
        },
        'Are you sure that you would like to manually enter the allotment depth? Depth is auto-calculated by dividing Frontage by Lot Size.',
        'Override Depth',
        true,
        _.identity,
        () => {
          this.calculateDepth(values.frontage, values.lotSize);
          setState({ editedDepth: false, depthOverride: false });
        }
      );
    } else {
      setFieldValue('depth', formatNumber(e.target.value), false);
    }
  };

  setPrice = (price) => {
    const { last, values, setFieldValue } = this.props;

    this.calculateDollarPerM2(price, values.lotSize);
    setFieldValue('price', formatNumber(price), false);
    setFieldValue(
      'priceChange',
      formatNumber(this.calculatePriceChange(last, price)),
      false
    );
  };

  onPriceBlur = (e) => {
    if (!_.isEmpty(e.target.value)) {
      const { actionModal, state, setState } = this.props;

      // `priceOverride` is a flag to check whether we're allowing the value of price
      // to have a value beyond the min/max range.

      const isOutOfRange =
        e.target.value < MIN_PRICE_RANGE || e.target.value > MAX_PRICE_RANGE;

      if (!state.priceOverride && isOutOfRange) {
        actionModal.notify({
          onSubmit: () => {
            setState({ priceOverride: true }, () =>
              this.setPrice(e.target.value)
            );
            actionModal.close();
          },
          message: OUT_OF_PRICE_RANGE_ERROR,
          onCancel: () => this.setPrice(e.target.value),
          submitText,
          closeText,
          red: true
        });
      } else {
        this.setPrice(e.target.value);
      }
    }
  };

  getNoteProps = _.memoize((published) => ({
    charLimit: 255,
    showCharLimit: true,
    placeholder: published ? '' : 'Max 255 Characters',
    disabled: published
  }));

  render() {
    const {
      styles: s,
      setFieldValue,
      isUpdate,
      published,
      stage,
      stageOptions,
      lot,
      state,
      last,
      details,
      area,
      pricing,
      fetchingLast
    } = this.props;

    return (
      <Fragment>
        <Box {...s('section')}>
          <Heading {...s('header')}>
            {isUpdate ? (published ? 'View' : 'Save') : 'Create'} Lot
          </Heading>
          <div ref={details} />
          <SubHeading grey>Details</SubHeading>
          <Grid columns={12}>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Stage"
                name="stage"
                initialValue={_.get(stage, 'stage_no', '').toString()}
                Input={SelectInput}
                onChange={this.onStageChange}
                inputProps={{
                  options: stageOptions,
                  disabled: published
                }}
              />
            </Column>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Lot Number"
                Input={TextInput}
                inputProps={{
                  disabled: published,
                  suffix: fetchingLast && (
                    <LoadingSpinner
                      styles={{ spinner: { width: '2rem', height: '100%' } }}
                    />
                  )
                }}
                name={'lotNumber'}
                initialValue={_.get(lot, 'lot_number')}
                onBlur={this.onLotNumberBlur}
              />
            </Column>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Status"
                name="status"
                initialValue={_.get(lot, 'status', 'Available')}
                Input={SelectInput}
                inputProps={{
                  options: statusOptions,
                  disabled: published
                }}
              />
            </Column>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Titled"
                name="titled"
                initialValue={_.get(lot, 'titled') ? 'true' : 'false'}
                Input={SelectInput}
                inputProps={{
                  options: [
                    {
                      value: 'true',
                      label: 'True'
                    },
                    {
                      value: 'false',
                      label: 'False'
                    }
                  ],
                  disabled: published
                }}
              />
            </Column>
          </Grid>

          <Grid columns={2}>
            <Column width={1}>
              <FormField
                sendImmediate
                label="Notes"
                name="notes"
                initialValue={_.get(lot, 'notes')}
                Input={TextInput}
                inputProps={this.getNoteProps(published)}
              />
            </Column>
            <Column width={1}>
              <Box flex={1} alignItems={'center'} pl={'0.5rem'}>
                {(_.get(last, 'status') === 'Sold' ||
                  _.get(last, 'status') === 'BOM/Sold') && (
                  <small style={{ color: COLORS.RED }}>
                    Last sold {formatPeriod(_.get(last, 'period.end_date'))}
                  </small>
                )}
              </Box>
            </Column>
          </Grid>
        </Box>
        <Box {...s('section')}>
          <div ref={area} />
          <SubHeading grey>Area</SubHeading>
          <Grid columns={12}>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Lot Size (m2)"
                name="lotSize"
                initialValue={formatNumber(_.get(lot, 'lot_size'))}
                Input={TextInput}
                inputProps={{
                  disabled: published
                }}
                onFocus={this.onLotSizeFocus}
                onBlur={this.onLotSizeBlur}
              />
            </Column>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Frontage (m)"
                name="frontage"
                initialValue={formatNumber(_.get(lot, 'frontage'))}
                Input={TextInput}
                inputProps={{
                  disabled: published
                }}
                onFocus={this.onFrontageFocus}
                onBlur={this.onFrontageBlur}
              />
            </Column>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Depth (m)"
                name="depth"
                initialValue={formatNumber(_.get(lot, 'depth'))}
                Input={TextInput}
                inputProps={{
                  disabled: published,
                  onKeyDown: this.onDepthKeyDown
                }}
                onBlur={this.onDepthBlur}
              />
            </Column>
            <Column width={3} />
          </Grid>
        </Box>
        <Box {...s('section')}>
          <div ref={pricing} />
          <SubHeading grey>Pricing</SubHeading>
          <Grid columns={12}>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Price"
                name="price"
                initialValue={formatNumber(_.get(lot, 'price'))}
                Input={DollarInput}
                inputProps={{
                  disabled: published
                }}
                onFocus={(e) => {
                  setFieldValue('price', stripNumber(e.target.value), false);
                }}
                onBlur={this.onPriceBlur}
              />
            </Column>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Incentives"
                name="incentives"
                initialValue={formatNumber(_.get(lot, 'rebate'))}
                Input={DollarInput}
                inputProps={{
                  disabled: published
                }}
                onFocus={(e) => {
                  setFieldValue(
                    'incentives',
                    stripNumber(e.target.value),
                    false
                  );
                }}
                onBlur={(e) => {
                  const value = e.target.value || 0;
                  setFieldValue('incentives', formatNumber(value), false);
                }}
              />
            </Column>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Negotiation Buffer"
                name="negotiationBuffer"
                initialValue={formatNumber(_.get(lot, 'negotiation_buffer'))}
                Input={DollarInput}
                inputProps={{
                  disabled: published
                }}
                onFocus={(e) => {
                  setFieldValue(
                    'negotiationBuffer',
                    stripNumber(e.target.value),
                    false
                  );
                }}
                onBlur={(e) => {
                  const value = e.target.value || 0;
                  setFieldValue(
                    'negotiationBuffer',
                    formatNumber(value),
                    false
                  );
                }}
              />
            </Column>
            <Column width={3}>
              <FormField
                sendImmediate
                label="Price Change"
                name="priceChange"
                initialValue={formatNumber(_.get(lot, 'price_change'))}
                Input={DollarInput}
                inputProps={{
                  disabled: published
                }}
                onFocus={(e) => {
                  setFieldValue(
                    'priceChange',
                    stripNumber(e.target.value),
                    false
                  );
                }}
                onBlur={(e) => {
                  setFieldValue(
                    'priceChange',
                    formatNumber(e.target.value),
                    false
                  );
                }}
              />
            </Column>
          </Grid>
          <Grid columns={12}>
            <Column width={3}>
              <FormField
                sendImmediate
                label="$/m2"
                name="dollarPerM2"
                initialValue={
                  _.get(lot, 'price_per_m2')
                    ? formatNumber(_.get(lot, 'price_per_m2'))
                    : Math.round(
                        _.get(lot, 'price') / _.get(lot, 'lot_size')
                      ) || ''
                }
                Input={DollarInput}
                inputProps={{
                  disabled: true,
                  styles: {
                    disabled: {
                      opacity: 1
                    }
                  }
                }}
                onFocus={(e) => {
                  setFieldValue(
                    'dollarPerM2',
                    stripNumber(e.target.value),
                    false
                  );
                }}
                onBlur={(e) => {
                  setFieldValue(
                    'dollarPerM2',
                    formatNumber(e.target.value),
                    false
                  );
                }}
              />
            </Column>
            <Column width={9} />
          </Grid>
        </Box>
        <Box {...s('section')}>
          <div ref={this.characteristics} />
          <SubHeading grey>Characteristics</SubHeading>
          <Grid columns={5}>
            <Column
              onKeyPress={(e) => this.handleKeyPress(e, 'corner')}
              width={1}
              onClick={() => this.toggle('corner')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.corner} />
                <Tiny>Corner</Tiny>
              </Box>
            </Column>
            <Column
              onKeyPress={(e) => this.handleKeyPress(e, 'fall')}
              width={1}
              onClick={() => this.toggle('fall')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.fall} />
                <Tiny>Fall</Tiny>
              </Box>
            </Column>
            <Column
              onKeyPress={(e) => this.handleKeyPress(e, 'fill')}
              width={1}
              onClick={() => this.toggle('fill')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.fill} />
                <Tiny>Fill</Tiny>
              </Box>
            </Column>
            <Column
              width={1}
              onKeyPress={(e) => this.handleKeyPress(e, 'irregular')}
              onClick={() => this.toggle('irregular')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.irregular} />
                <Tiny>Irregular</Tiny>
              </Box>
            </Column>
            <Column
              onKeyPress={(e) => this.handleKeyPress(e, 'nearbyAmenity')}
              width={1}
              onClick={() => this.toggle('nearbyAmenity')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.nearbyAmenity} />
                <Tiny>Nearby Amenity</Tiny>
              </Box>
            </Column>
          </Grid>
          <Grid columns={5}>
            <Column
              onKeyPress={(e) => this.handleKeyPress(e, 'parkWetlands')}
              width={1}
              onClick={() => this.toggle('parkWetlands')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.parkWetlands} />
                <Tiny>Park / Wetlands</Tiny>
              </Box>
            </Column>
            <Column
              onKeyPress={(e) => this.handleKeyPress(e, 'powerlines')}
              width={1}
              onClick={() => this.toggle('powerlines')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.powerlines} />
                <Tiny>Powerlines</Tiny>
              </Box>
            </Column>
            <Column
              onKeyPress={(e) => this.handleKeyPress(e, 'substation')}
              width={1}
              onClick={() => this.toggle('substation')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.substation} />
                <Tiny>Substation</Tiny>
              </Box>
            </Column>
            <Column
              onKeyPress={(e) => this.handleKeyPress(e, 'doubleStorey')}
              width={1}
              onClick={() => this.toggle('doubleStorey')}
            >
              <Box flex={1} {...s('checkbox')}>
                <Checkbox value={state.doubleStorey} />
                <Tiny>Double Storey</Tiny>
              </Box>
            </Column>
            <Column width={1} />
          </Grid>

          <FormField
            sendImmediate
            label="Orientation"
            name="lotOrientation"
            initialValue={_.get(lot, 'lot_orientation', '')}
            Input={SelectInput}
            inputProps={{
              options: lotOrientationOptions,
              disabled: published
            }}
          />
        </Box>
      </Fragment>
    );
  }
}

LotFormFields.PropTypes = {
  setFieldValue: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  published: PropTypes.bool.isRequired,
  stage: PropTypes.object.isRequired,
  lot: PropTypes.object.isRequired,
  actionModal: PropTypes.object.isRequired,
  stageOptions: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  worksheetId: PropTypes.number.isRequired,
  values: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  last: PropTypes.object.isRequired,
  fetchingLast: PropTypes.bool
};

export default LotFormFields;
