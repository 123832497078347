import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      All columns can sort the data set by clicking the heading.
      <br />
      <br />
      Hover over ‘flags’ beside project names for more information.
      <br />
      <br />
      <span className="bold">Sales Summary</span>
      <br />
      <br />
      Displays the sales performance of each project.
      <br />
      <br />
      Note: The 3 & 12 month averages are not displayed if, during that time, a
      project has &lt;100 allotments remaining, is assessed as having low
      reliability or is on hold.
      <br />
      <br />
      <span className="bold">Available Stock</span>
      <br />
      <br />
      Displays relevant supply metrics for each project.
      <br />
      <br />
      Latest Release displays the month when the project most recently had a
      stage release.{' '}
      <span className="italic">
        Stage release = 8 or more newly released allotments in an individual
        Stage.
      </span>
      <br />
      <br />
      Months to Title calculates the number of months until titles are expected
      in the most recently released stage. This information is only displayed if
      a project has had a release within the last 3 months from today’s date.
      <br />
      <br />
      <span className="bold">Total Supply</span>
      <br />
      <br />
      Displays the size of each project and the estimated number of lots
      remaining to be sold.
      <br />
      <br />
      Forecast completion estimates consider historical sales averages broken
      down by project size.
      <br />
      <br />
      <span className="bold">Median Price/Size</span>
      <br />
      <br />
      Displays the median price, size and $/m2 for each project’s sold &
      available lots.
    </>
  );
};

export default InfoTooltip;
