import React from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS, SHADOWS, BORDER_RADIUS } from 'src/theme';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  }
});

function SectionContainer({ styles: s, children, ...rest }) {
  return (
    <Box {...s('sectionContainer')} {...rest}>
      {children}
    </Box>
  );
}

export default styled(defaultStyles)(SectionContainer);
