import React, { useMemo } from 'react';
import _ from 'lodash';

import Box from '@rexlabs/box';
import { TextInput } from '@rexlabs/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';
import { Grid, Column } from '@rexlabs/grid';

import projectsModel from 'data/models/entities/projects';
import sessionsModel from 'data/models/custom/session';
import stagesModel from 'data/models/entities/stages';

import ButtonBar from 'view/components/modal/button-bar';
import withError from 'view/containers/with-error';
import MonthRangePicker, {
  DEFAULT_RANGE_OBJ,
  EMPTY_RANGE
} from 'view/components/calendar/month-range';
import Checkbox from 'view/components/checkbox/check';
import { Heading, Tiny } from 'view/components/text';
import { PrimaryButton, TextButton } from 'view/components/button';
import { ReactForms, Form, FormField } from 'view/components/form';

import { compose } from 'utils/compose';
import { createValidationRules } from 'utils/form';
import { formatDateToString, formatDateToObject } from 'utils/format';
import { COLORS, PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`
  },
  title: {
    color: COLORS.GREY.DARK
  },
  section: {
    padding: '2rem',
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`,
    ':last-child': {
      borderBottom: 'none'
    }
  },
  checkbox: {
    alignItems: 'center'
  },
  buttonBar: {
    paddingRight: 0
  }
});

const validate = (values) => {
  const validation = createValidationRules({
    stageNo: 'string|required',
    ...(!values.isTitled
      ? { 'expectedTitleRelease.from.year': 'required' }
      : {}),
    ...(values.isTitled ? { 'actualTitleRelease.from.year': 'required' } : {})
  });

  return validation(values);
};

const StageForm = ({
  styles: s,
  stage,
  stages,
  projectId,
  worksheetId,
  onSubmit,
  closeModal,
  published,
  errorModal: { Error, ...errorModal }
}) => {
  const { expectedTitleRelease, actualTitleRelease, isTitled, isUpdate } =
    useMemo(() => {
      const hasStage = !!stage;

      if (hasStage) {
        const {
          estimated_title_release_start_date: estReleaseStart,
          estimated_title_release_end_date: estReleaseEnd,
          actual_title_release_start_date: actualReleaseStart,
          actual_title_release_end_date: actualReleaseEnd
        } = stage;

        // BE's response data is always `string` and so we're destructuring it here from `2022-01-01` to `{ year: 2022, month: 1 }`.
        const estTitleReleaseStart = estReleaseStart
          ? formatDateToObject(estReleaseStart)
          : DEFAULT_RANGE_OBJ;
        const estTitleReleaseEnd = estReleaseEnd
          ? formatDateToObject(estReleaseEnd)
          : DEFAULT_RANGE_OBJ;

        const actualTitleReleaseStart = actualReleaseStart
          ? formatDateToObject(actualReleaseStart)
          : DEFAULT_RANGE_OBJ;
        const actualTitleReleaseEnd = actualReleaseEnd
          ? formatDateToObject(actualReleaseEnd)
          : DEFAULT_RANGE_OBJ;

        // MonthRangePicker value format is `{ from: { year, month }, to: { year, month }}`
        return {
          expectedTitleRelease: {
            from: estTitleReleaseStart,
            to: estTitleReleaseEnd
          },
          actualTitleRelease: {
            from: actualTitleReleaseStart,
            to: actualTitleReleaseEnd
          },
          isTitled: !!actualReleaseStart && !!actualReleaseEnd,
          isUpdate: true
        };
      }

      return {
        expectedTitleRelease: EMPTY_RANGE,
        actualTitleRelease: EMPTY_RANGE,
        isTitled: false,
        isUpdate: false
      };
    }, [stage]);

  const handleSubmit = (values) => {
    const promise = isUpdate ? stages.updateItem : stages.createItem;

    const payload = {
      stage_no: values.stageNo,
      estimated_title_release_start_date: formatDateToString(
        values.expectedTitleRelease.from,
        true
      ),
      estimated_title_release_end_date: formatDateToString(
        values.expectedTitleRelease.to
      ),
      actual_title_release_start_date: formatDateToString(
        values.actualTitleRelease.from,
        true
      ),
      actual_title_release_end_date: formatDateToString(
        values.actualTitleRelease.to
      )
    };

    return promise({
      ...(isUpdate ? { id: stage.id } : {}),
      data: payload,
      args: {
        projectId: projectId,
        worksheetId: worksheetId,
        ...(isUpdate && { stage_no: values.stageNo })
      }
    })
      .then((response) => {
        if (onSubmit) {
          onSubmit(response?.data);
        }
        closeModal();
      })
      .catch(errorModal.open);
  };

  return (
    <Box {...s('container')}>
      <ReactForms
        handleSubmit={handleSubmit}
        validate={validate}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {(props) => {
          const { isSubmitting, submitForm, values } = props;

          return (
            <Form name="stage-form">
              <Heading>
                {isUpdate ? (published ? 'View' : 'Edit') : 'Add'} Stage
              </Heading>
              <Grid columns={2}>
                <Column width={2}>
                  <FormField
                    sendImmediate
                    label="Stage No."
                    name="stageNo"
                    Input={TextInput}
                    initialValue={stage?.stage_no}
                    inputProps={{
                      autoFocus: true,
                      disabled: published
                    }}
                  />

                  <FormField
                    sendImmediate
                    label="Expected Title Release"
                    name="expectedTitleRelease"
                    Input={MonthRangePicker}
                    initialValue={expectedTitleRelease}
                    inputProps={{
                      disabled: values.isTitled
                    }}
                  />
                  {/**
                   * As an improvement suggestion for help texts: we could make a wrapper component for the `<Field />` component
                   * So that we could use <FormField helpText="some info here" />
                   * On Rex App: shell/src/view/components/form/field.js
                   */}
                  {isUpdate && stage?.title && (
                    <Tiny>Historic title release: {stage?.title}</Tiny>
                  )}

                  <FormField
                    sendImmediate
                    label="Titled"
                    name="isTitled"
                    Input={Checkbox}
                    initialValue={isTitled}
                  />

                  <FormField
                    sendImmediate
                    label="Actual Title Release"
                    name="actualTitleRelease"
                    Input={MonthRangePicker}
                    initialValue={actualTitleRelease}
                    inputProps={{
                      disabled: !values.isTitled
                    }}
                  />
                </Column>
              </Grid>

              <ButtonBar {...s('buttonBar')}>
                <TextButton type="button" onClick={closeModal}>
                  Cancel
                </TextButton>
                {!published && (
                  <PrimaryButton
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}
                    green
                  >
                    {isUpdate ? 'Edit' : 'Add'}
                  </PrimaryButton>
                )}
              </ButtonBar>
            </Form>
          );
        }}
      </ReactForms>

      <Error />
    </Box>
  );
};

export default compose<unknown>(
  withError.withPropName('errorModal'),
  withModel(sessionsModel),
  withModel(projectsModel),
  withModel(stagesModel),
  styled(defaultStyles)
)(StageForm);
