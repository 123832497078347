import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MonthYearType } from '../components/slider/month-range-slider';
import { ReportSettingsContext } from '../providers/report-settings-provider';

// Use for month range slider
// Default value is last month of the period
const useMonthRangeFilter = () => {
  const { period } = useContext(ReportSettingsContext);

  const [dateFilter, setDateFilter] = useState<MonthYearType>({
    year: 0,
    month: 0
  });

  const range = useMemo(
    () =>
      period && dateFilter.month !== 0 && dateFilter.year !== 0
        ? {
            startPeriod: moment(
              `${dateFilter.year}-${dateFilter.month}-1`
            ).format('YYYY-MM-DD'),
            endPeriod: moment(period).format('YYYY-MM-DD')
          }
        : { startPeriod: null, endPeriod: null },
    // 'period' is already added as a dependency in the useEffect which will change dateFilter
    // Therefore this will run as well then so no need to add 'period'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateFilter]
  );

  useEffect(() => {
    if (!period) return;
    const defaultPeriod = moment(period);
    setDateFilter({
      year: defaultPeriod.year(),
      month: defaultPeriod.month() + 1
    });
  }, [period]);

  return { ...range, dateFilter, setDateFilter };
};

export default useMonthRangeFilter;
