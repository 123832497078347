import React from 'react';

import { PagePermission } from 'src/features/reports/hoc';
import LotJourney from 'src/features/reports/components/lot-journey';
import SupplyOverview from 'src/features/reports/components/supply-overview';
import { PerformanceOverview } from 'src/features/reports/components/performance-overview';
import { ProductOverview } from 'src/features/reports/components/product-overview';
import { SalesPerformance } from 'src/features/reports/components/sales-performance';
import { INSIGHTS_PAGE } from '../constants';

function ReportOverviewCore() {
  return (
    <PagePermission permissionsNeeded={[INSIGHTS_PAGE]}>
      <LotJourney />
      <PerformanceOverview />
      <SupplyOverview />
      <ProductOverview />
      <SalesPerformance />
    </PagePermission>
  );
}

export default ReportOverviewCore;
