import React from 'react';
import Box from '@rexlabs/box';
import { SubHeading } from 'view/components/text';
import { COLORS, PADDINGS } from 'theme';
import _ from 'lodash';
import { formatNumber, formatPeriod } from 'utils/format';
import {
  CartesianGrid,
  Legend,
  Line as GraphLine,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { compose } from 'lodash/fp';
import SectionContainer from 'view/components/section-container';

interface PriceSizeGraphProps {
  stats: any;
  period: any;
}

const PriceSizeGraph = ({ stats, period }: PriceSizeGraphProps) => {
  const minValueInObject = (obj, property) => {
    return _(obj)
      .map((value) => {
        const filteredValues = _(value)
          .values()
          .filter((v) => _.get(v, property) !== 0);
        if (filteredValues.isEmpty()) {
          return 0;
        }
        return filteredValues.minBy(property)[property];
      })
      .filter((value) => value !== 0)
      .min();
  };

  const statsWithAverages = _.get(stats, 'historic_by_date');

  const minSoldPrice = minValueInObject(
    statsWithAverages,
    'average_sold_price'
  );
  const maxSoldPrice = _(statsWithAverages)
    .map((v) => _(v).map('average_sold_price').max())
    .max();

  const minSoldSize = minValueInObject(
    statsWithAverages,
    'average_sold_lot_size'
  );
  const maxSoldSize = _(statsWithAverages)
    .map((v) => _(v).map('average_sold_lot_size').max())
    .max();
  const soldSizeRange =
    maxSoldSize === minSoldSize ? 20 : maxSoldSize - minSoldSize;

  const grossSales = _.map(statsWithAverages, (stat, key) => ({
    ...stat,
    period: formatPeriod(key, true)
  }));

  const date = new Date(_.get(period, 'end_date'));
  const dateRange = _.range(0, 12).map((offset) => {
    const newDate = new Date(date.getFullYear(), date.getMonth() - offset, 1);
    return formatPeriod(newDate, true);
  });

  const grossSalesPerMonthData = _.map(dateRange, (date) => {
    const dateObj = { period: date };
    const result = _.find(grossSales, dateObj);
    if (result) {
      return { ...result, dateObj };
    }
    return dateObj;
  }).reverse();
  return (
    <SectionContainer m={PADDINGS.XXS} flexDirection={'column'}>
      <Box m={PADDINGS.S}>
        <SubHeading>Ave. Sold Price vs Ave. Sold Size</SubHeading>
      </Box>
      <ResponsiveContainer width={'100%'} height={500}>
        <LineChart
          data={grossSalesPerMonthData}
          margin={{ top: 0, right: 40, left: 40, bottom: 20 }}
        >
          <XAxis dataKey={'period'} />
          <YAxis
            type="number"
            domain={[
              Math.floor(minSoldPrice / 1000) * 1000 - 1000,
              Math.ceil(maxSoldPrice / 1000) * 1000 + 1000
            ]}
            tickFormatter={(v) => '$' + formatNumber(v)}
            allowDataOverflow={true}
            yAxisId="1"
          />
          <YAxis
            type="number"
            domain={[
              Math.floor(minSoldSize - soldSizeRange * 0.1),
              Math.ceil(maxSoldSize + soldSizeRange * 0.1)
            ]}
            allowDataOverflow={true}
            orientation="right"
            yAxisId="2"
            tickFormatter={(v) => formatNumber(v) + ' m2'}
          />
          <CartesianGrid vertical={false} />
          <Tooltip
            formatter={(value, name) => {
              if (name.toLowerCase().includes('price')) {
                return `$${formatNumber(value)}`;
              }
              return `${formatNumber(value)} m2`;
            }}
          />
          <Legend verticalAlign="top" height={40} iconType="plainline" />
          {/* Price */}
          <GraphLine
            dataKey={(raw) => {
              const key = 'average.average_sold_price';
              const value = _.get(raw, key);
              return value > 0 ? value : null;
            }}
            name={'Ave. Price for Corridor'}
            stroke={COLORS.BLUE_GREEN}
            strokeWidth={2}
            activeDot={{ r: 6 }}
            yAxisId={'1'}
          />
          <GraphLine
            dataKey={(raw) => {
              const key = 'primary.average_sold_price';
              const value = _.get(raw, key);
              return value > 0 ? value : null;
            }}
            name={'Ave. Price for Primary'}
            stroke={COLORS.ORANGE.REPORT}
            strokeWidth={2}
            activeDot={{ r: 6 }}
            yAxisId={'1'}
          />
          {/* Size */}
          <GraphLine
            dataKey={(raw) => {
              const key = 'average.average_sold_lot_size';
              const value = _.get(raw, key);
              return value > 0 ? value : null;
            }}
            name={'Ave. Size for Corridor'}
            strokeDasharray={'5 5'}
            stroke={COLORS.BLUE_GREEN}
            strokeWidth={2}
            activeDot={{ r: 6 }}
            yAxisId={'2'}
          />
          <GraphLine
            dataKey={(raw) => {
              const key = 'primary.average_sold_lot_size';
              const value = _.get(raw, key);
              return value > 0 ? value : null;
            }}
            name={'Ave. Size for Primary'}
            strokeDasharray={'5 5'}
            stroke={COLORS.ORANGE.REPORT}
            strokeWidth={2}
            activeDot={{ r: 6 }}
            yAxisId={'2'}
          />
        </LineChart>
      </ResponsiveContainer>
    </SectionContainer>
  );
};

export default compose()(PriceSizeGraph);
