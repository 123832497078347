import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%'
  },
  toRight: {
    backgroundImage: 'linear-gradient(to right, #CE4E4F, #FBC83D, #83C878)'
  },
  toLeft: {
    backgroundImage: 'linear-gradient(to left, #CE4E4F, #FBC83D, #83C878)'
  }
});

@styled(defaultStyles)
class Gradient extends PureComponent {
  render() {
    const { styles: s, radius, left } = this.props;
    return (
      <Box
        {...s('container', left ? 'toLeft' : 'toRight')}
        style={{ borderRadius: radius }}
      >
        &nbsp;
      </Box>
    );
  }
}

export default Gradient;
