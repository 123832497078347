import React, { PureComponent } from 'react';
import _ from 'lodash';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';

import ActionModal from 'view/modals/action';
import StageModal from 'view/modals/stage';
import { Check as Checkbox } from 'view/components/checkbox';
import { HorizontalActionMenu } from 'view/components/action-menu';
import { Small } from 'view/components/text';

import stagesModel from 'data/models/entities/stages';
import { FONT } from 'src/theme';
import { formatDateStringToRangeDisplay } from 'utils/format';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  }
});

@withModel(stagesModel)
@styled(defaultStyles)
class ProjectStageListItem extends PureComponent {
  state = {
    deleteModal: false
  };

  deleteStage = () => {
    const { stages, stage, projectId, worksheetId } = this.props;
    stages
      .trashItem({
        id: stage.id,
        args: {
          worksheetId: worksheetId,
          projectId: projectId
        }
      })
      .then(() => this.setState({ deleteModal: false }))
      .catch((e) => console.error(e));
  };

  render() {
    const {
      styles: s,
      stage,
      checked,
      projectId,
      worksheetId,
      onUpdate,
      published
    } = this.props;

    const menuItems = published
      ? [
          {
            label: 'View',
            onClick: () => this.setState({ editModal: true })
          }
        ]
      : [
          {
            label: 'Edit',
            onClick: () => this.setState({ editModal: true })
          },
          {
            label: 'Delete',
            onClick: () => this.setState({ deleteModal: true })
          }
        ];

    const {
      estimated_title_release_start_date: estReleaseStart,
      estimated_title_release_end_date: estReleaseEnd,
      actual_title_release_start_date: actualReleaseStart,
      actual_title_release_end_date: actualReleaseEnd
    } = stage;

    const hasExpectedReleaseDate = estReleaseStart && estReleaseEnd;
    const hasActualReleaseDate = actualReleaseStart && actualReleaseEnd;

    const expectedReleaseDate = hasExpectedReleaseDate
      ? formatDateStringToRangeDisplay(estReleaseStart, estReleaseEnd)
      : 'N/A';

    const actualReleaseDate = hasActualReleaseDate
      ? formatDateStringToRangeDisplay(actualReleaseStart, actualReleaseEnd)
      : 'N/A';

    return (
      <Box {...s('container')} key={stage.id} onClick={this.props.toggle}>
        {this.state.deleteModal && (
          <ActionModal
            onClose={() => this.setState({ deleteModal: false })}
            onSubmit={this.deleteStage}
            title={'Delete Stage'}
            submitText={'Delete'}
          >
            Are you sure you want to delete this Stage?
          </ActionModal>
        )}
        <Grid
          gutter={0}
          columns={24}
          {...s('grid')}
          style={{ alignItems: 'center' }}
        >
          <Column width={1} {...s('checkbox')}>
            <Checkbox controlled={true} value={checked || false} />
          </Column>
          <Column width={3}>
            <Small bold>{stage.stage_no}</Small>
          </Column>
          <Column width={6}>
            <Small>{expectedReleaseDate}</Small>
          </Column>
          <Column width={6}>
            <Small>{actualReleaseDate}</Small>
          </Column>
          <Column width={7}>
            <Small>{_.get(stage, 'total_listings', 0)}</Small>
          </Column>
          <Column width={1} onClick={(e) => e.stopPropagation()}>
            <HorizontalActionMenu items={menuItems} />
          </Column>
        </Grid>
        {this.state.editModal && (
          <StageModal
            projectId={projectId}
            worksheetId={worksheetId}
            stage={stage}
            published={published}
            closeModal={() => {
              this.setState({ editModal: false }, onUpdate);
            }}
          />
        )}
      </Box>
    );
  }
}

export default ProjectStageListItem;
