import React, { useMemo } from 'react';
import { CustomLayer } from '@nivo/line';
import UnderSupplied from './under-supplied';
import OverSupplied from './over-supplied';

const SteppingLine: CustomLayer = (props) => {
  const { series, lineGenerator } = props;

  const steppingPoints = useMemo(() => {
    const result = [];
    if (!series[0]?.data) return result;

    const middleX =
      (series[0].data[1].position.x - series[0].data[0].position.x) / 2;

    series[0].data.forEach((point, index) => {
      if (series[0].data.length - 1 === index || index === 0) {
        result.push(point.position);
        if (series[0].data.length - 1 === index) return;
      }

      const adjustedX = series[0].data[index + 1].position.x - middleX;

      if (point.position.y !== null) {
        result.push({ ...point.position, x: adjustedX });
      }

      // Step to the right
      result.push({
        ...point.position,
        x: adjustedX
      });

      if (
        point.position.y !== series[0].data[index + 1].position.y &&
        series[0].data[index + 1].position.y !== null
      ) {
        // Step up or down based on next y value
        result.push({
          ...point.position,
          x: adjustedX,
          y: series[0].data[index + 1].position.y
        });
      }
    });
    return result;
  }, [series]);

  return (
    <>
      <path
        d={lineGenerator(steppingPoints)}
        fill="none"
        strokeWidth={1}
        stroke={series[0] ? series[0].color : ''}
      />
      <UnderSupplied {...props} points={steppingPoints} />
      <OverSupplied {...props} points={steppingPoints} />
    </>
  );
};

export default SteppingLine;
