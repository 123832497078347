import React from 'react';
import { ArrowDown } from '../icons';
import SquareLegend from '../icons/square-legend';
import { StyleSheet } from '@rexlabs/styling';
import { SankeyLinkDatum } from '@nivo/sankey';
import { SankeyLink, SankeyNode } from 'types/graph';
import { FONT } from 'src/features/reports/theme';

const tooltipStyles = StyleSheet({
  container: {
    background: 'rgb(238, 238, 238)',
    borderRadius: 2,
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    gap: 5,
    '& > p': {
      fontWeight: 'bold'
    },
    padding: 5,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
  },
  arrow: {
    transform: 'rotate(270deg)',
    width: 12
  }
});

const CustomLinkTooltip = ({
  source,
  target,
  value
}: SankeyLinkDatum<SankeyNode, SankeyLink>) => (
  <div className={tooltipStyles.container}>
    <SquareLegend fill={source.color} />
    <p>{source.label}</p>
    <ArrowDown className={tooltipStyles.arrow} />
    <SquareLegend fill={target.color} />
    <p>
      {target.label} ({value})
    </p>
  </div>
);

export default CustomLinkTooltip;
