import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { PADDINGS } from 'src/theme';
import { SubHeading, Body } from 'view/components/text';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { getShortMonth } from 'utils/format';
import SectionContainer from 'view/components/section-container';

export interface AvailabilityVsSupplyProps {
  availableSupply: any;
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles = {
  leftLabel: {
    textOrientation: 'sideways',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)'
  },
  rightLabel: {
    textOrientation: 'sideways',
    writingMode: 'vertical-lr'
  }
};

const AvailabilityVsSupply = ({
  availableSupply,
  styles: s
}: AvailabilityVsSupplyProps & DecoratorProps) => {
  return (
    <SectionContainer m={PADDINGS.XXS}>
      <Box p={PADDINGS.S}>
        <SubHeading>Lots Available vs Months Supply</SubHeading>
      </Box>
      <Box flexDirection={'row'}>
        <Box alignItems={'center'} ml={PADDINGS.TINY}>
          <Body {...s('leftLabel')}># of Lots</Body>
        </Box>
        <ResponsiveContainer width={'100%'} height={300}>
          <LineChart
            data={availableSupply}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 12
            }}
          >
            <XAxis
              dataKey="period"
              tickFormatter={(date) =>
                new Date(date).toLocaleString('en-us', { month: 'short' })
              }
            />
            <YAxis type={'number'} yAxisId="1" />
            <YAxis type={'number'} orientation="right" yAxisId="2" />
            <CartesianGrid vertical={false} />
            <Tooltip labelFormatter={getShortMonth} />
            <Legend verticalAlign="top" iconType="line" height={40} />
            <Line
              name={'Titled Lots'}
              dataKey={'titled_listings'}
              stroke="#00A093"
              yAxisId="1"
            />
            <Line
              name={'Untitled Lots'}
              dataKey={'untitled_listings'}
              stroke="#ACEEDF"
              yAxisId="1"
            />
            <Line
              name={'Months Supply'}
              dataKey={'months_supply'}
              stroke="#5BB5CF"
              yAxisId="2"
            />
          </LineChart>
        </ResponsiveContainer>
        <Box alignItems={'center'} mr={PADDINGS.TINY}>
          <Body {...s('rightLabel')}>Months Supply</Body>
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default styled(StyleSheet(defaultStyles))(AvailabilityVsSupply);
