import React, { CSSProperties } from 'react';
import { COLORS } from 'src/features/reports/theme';

const dividerStyles: CSSProperties = {
  width: 1,
  height: 49,
  backgroundColor: COLORS.GREY.BORDER,
  margin: '0 16px'
};

const Divider = () => {
  return <div style={dividerStyles} />;
};

export default Divider;
