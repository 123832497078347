import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const GlobeMapIcon = (props: SVGProps<null>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99971 7.5C9.99971 7.5 12.3344 5.58333 12.3344 3.83333C12.3344 2.54467 11.289 1.5 9.99971 1.5C8.71037 1.5 7.66504 2.54467 7.66504 3.83333C7.66504 5.58333 9.99971 7.5 9.99971 7.5Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.001 3.73342C10.093 3.73342 10.1677 3.80809 10.167 3.90009C10.167 3.99209 10.0923 4.06676 10.0003 4.06676C9.90832 4.06676 9.83366 3.99209 9.83366 3.90009C9.83366 3.80809 9.90832 3.73342 10.001 3.73342"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.68733 3.51657C4.52 3.68057 2 6.2919 2 9.4999C2 12.8139 4.686 15.4999 8 15.4999C11.314 15.4999 14 12.8139 14 9.4999C14 7.7319 13.232 6.1479 12.0153 5.05257"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33331 14.8788V14.8314C5.33331 13.4981 7.99998 13.6554 7.99998 12.1648C7.99998 10.8314 5.99998 10.8314 5.99998 8.83143C5.99998 6.9461 4.66664 6.83143 2.66664 6.83143H2.62598"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9796 13.9799C11.879 13.9412 11.779 13.9005 11.6836 13.8445C10.7263 13.2845 10.405 12.0552 10.9643 11.0979C11.5243 10.1405 12.7536 9.81921 13.711 10.3785C13.7816 10.4199 13.841 10.4719 13.905 10.5199"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GlobeMapIcon;
