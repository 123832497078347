import React, { ComponentProps } from 'react';
import Button from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/features/reports/theme';
import _ from 'lodash';

const getButtonStyle = _.memoize((selected?: boolean) =>
  StyleSheet({
    container: {
      transition: 'all 0.2s ease-in-out',
      backgroundColor: selected ? COLORS.BLUE.PRIMARY : COLORS.WHITE,
      borderColor: selected ? COLORS.BLUE.PRIMARY : COLORS.BUTTON.BORDER.DEFAULT
    },
    content: {
      color: selected ? COLORS.WHITE : COLORS.BLUE.DARK,
      fontFamily: FONT.FAMILIES.DEFAULT,
      transition: 'all 0.2s ease-in-out',
      // Styling for svg icon
      '& svg > *': {
        stroke: selected ? COLORS.WHITE : COLORS.BLUE.DARK
      }
    }
  })
);

export interface DefaultButtonProps extends ComponentProps<typeof Button> {
  selected?: boolean;
}

export default function DefaultButton({
  selected,
  ...rest
}: DefaultButtonProps) {
  return <Button styles={getButtonStyle(selected)} {...rest} />;
}
