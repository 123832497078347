import React, { SVGProps } from 'react';
import { COLORS } from '../../theme';

const CalendarIcon = (props: SVGProps<null>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1115 1.5V4.44731"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.88889 1.5V4.44731"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.65813H15"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4444 2.97383H2.55556C1.69611 2.97383 1 3.63329 1 4.44748V14.0262C1 14.8404 1.69611 15.4999 2.55556 15.4999H13.4444C14.3039 15.4999 15 14.8404 15 14.0262V4.44748C15 3.63329 14.3039 2.97383 13.4444 2.97383Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
