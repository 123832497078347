import { ComputedDatum, SunburstCustomLayerProps } from '@nivo/sunburst';
import { COLORS, FONT } from 'src/features/reports/theme';
import React, { useCallback } from 'react';
import { round } from 'lodash';

interface RawDatum {
  id: string;
  children: Array<{
    id: string;
    children: Array<{ id: string; color: string; value: number }>;
  }>;
}

const CustomLegend = ({
  centerX,
  centerY,
  nodes
}: SunburstCustomLayerProps<RawDatum>) => {
  const polarToCartesian = useCallback(
    (node: ComputedDatum<RawDatum>) => {
      const angleInRadians = (node.arc.endAngle + node.arc.startAngle) / 2;
      let radius = 0;
      let textAnchor = 'middle';

      // Meaning inner circle
      if (node.path.length === 2) {
        radius = node.arc.innerRadius - 5;
      } else {
        radius = node.arc.outerRadius + 5;
      }

      // Calculate coordinate based on angle and radius
      const x = centerX + radius * Math.cos(angleInRadians - Math.PI / 2);
      const y = centerY + radius * Math.sin(angleInRadians - Math.PI / 2);

      if (node.path.length === 2) {
        textAnchor = x > centerX ? 'end' : 'start';
      } else {
        textAnchor = x > centerX ? 'start' : 'end';
      }

      // I used round here because the calculation of x and y include decimals
      if (round(x) === round(centerX)) {
        textAnchor = 'middle';
      }

      return {
        x,
        y,
        textAnchor
      };
    },
    [centerX, centerY]
  );

  return (
    <>
      {nodes.map((node) => {
        const { x, y, textAnchor } = polarToCartesian(node);
        if (!node.percentage) return null;
        return (
          <text
            key={node.id}
            x={x}
            y={y}
            textAnchor={textAnchor}
            dominantBaseline="central"
            style={{
              fontSize: '12px',
              fontWeight: 700,
              fontFamily: FONT.FAMILIES.DEFAULT,
              fill: COLORS.BLUE.DARK
            }}
          >
            {node.id}: {round(node.percentage, 1)}%
          </text>
        );
      })}
    </>
  );
};

export default CustomLegend;
