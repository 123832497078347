import React, { PureComponent } from 'react';
import { PrimaryButton, TextButton } from 'view/components/button';
import { Modal, ButtonBar } from 'view/components/modal';
import { Heading } from '../components/text';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';
import { withQuery, query } from '@rexlabs/model-generator';
import projectsModel from 'data/models/entities/projects';
import LoadingSpinner from '@rexlabs/loading-spinner';
import List from '@rexlabs/list';
import RegionProjectAddItem from '../components/list/region-project-add-item';
import RegionProjectAddHeader from '../components/list/region-project-add-header';
import _ from 'lodash';

function getRegionId(props) {
  return _.get(props, 'match.params.regionId');
}

function getRegionQuery(props) {
  return 'sub_region_id.eq(null)';
}

const projectsQuery = query`{
  ${projectsModel} (q: ${getRegionQuery}) {
    id
    account_id
    sub_region_id
    title
    density
    website
    developer
    sales_office_address
    suburb
    postcode
    status
    local_government_area
    sales_agency
    precinct_structure_plan
    image_id
    estimated_lots
    estimated_number_of_stages
    estimated_start_date
    estimated_completion_date
    archived_at
    trashed_at
    active
    sales_volume_offset
    summary
    settings
    created_at
  }
}`;

@withQuery(projectsQuery)
class RegionProjectsModal extends PureComponent {
  state = {
    checked: {},
    submitting: false
  };

  selectAll = () => {
    const { projects } = this.props;
    const projectList = _.get(projects, 'list.items', []);
    const { checked } = this.state;

    if (_.filter(checked, (value) => value).length === projectList.length) {
      this.setState({
        checked: {}
      });
    } else {
      const toggledState = projectList.reduce((obj, item) => {
        return { ...obj, [item.id]: true };
      }, {});
      this.setState({
        checked: toggledState
      });
    }
  };

  toggleItem = (id) => {
    const { checked } = this.state;
    this.setState({
      checked: {
        ...checked,
        ...{
          [id]: !checked[id]
        }
      }
    });
  };

  renderHeader = () => {
    const { projects } = this.props;
    const { checked } = this.state;
    const projectList = _.get(projects, 'list.items', []);

    return (
      <RegionProjectAddHeader
        onSelectAll={this.selectAll}
        checked={
          _.filter(checked, (value) => value).length === projectList.length
        }
      />
    );
  };

  renderItem = (item) => {
    const { checked } = this.state;
    const { projects } = this.props;
    return (
      <RegionProjectAddItem
        project={item}
        // regionProjects={regionProjects}
        checked={_.get(checked, item.id)}
        toggle={() => this.toggleItem(item.id)}
        onLoadMore={() => projects.fetchMore()}
      />
    );
  };

  isLoading = () => {
    const { projects } = this.props;
    return _.get(projects, 'list.status') === 'loading';
  };

  addProjects = () => {
    const { checked } = this.state;
    const { projects, closeModal } = this.props;

    this.setState({ submitting: true });

    const ids = _(checked)
      .map((value, key) => ({ key: key, value: value }))
      .filter((value) => value.value)
      .map((x) => x.key)
      .value();

    const regionId = getRegionId(this.props);

    const payload = {
      sub_region_id: parseInt(regionId)
    };

    Promise.all(
      _.map(ids, (id) =>
        projects.updateItem({
          id: id,
          data: payload
        })
      )
    )
      .then(() => {
        closeModal();
        this.setState({ submitting: false });
      })
      .catch(console.error);
  };

  render() {
    const { projects, closeModal } = this.props;
    const { submitting } = this.state;
    if (this.isLoading()) {
      return (
        <Modal>
          <Box p={PADDINGS.L} flex={1} justifyContent={'center'}>
            <LoadingSpinner />
          </Box>
        </Modal>
      );
    }

    const projectItems = _.get(projects, 'list.items');

    return (
      <Modal>
        <Box p={PADDINGS.XS}>
          <Heading>Add Projects to Sub-Region</Heading>
        </Box>
        <List
          items={projectItems}
          Header={this.renderHeader}
          renderItem={(item) => this.renderItem(item)}
        />
        <ButtonBar style={{ paddingRight: PADDINGS.M }}>
          <TextButton
            type={'button'}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cancel
          </TextButton>
          <PrimaryButton
            green
            onClick={this.addProjects}
            isLoading={submitting}
            isDisabled={submitting}
          >
            Add
          </PrimaryButton>
        </ButtonBar>
      </Modal>
    );
  }
}

export default RegionProjectsModal;
