import React, { useMemo } from 'react';
import { ResponsiveLine, Serie } from '@nivo/line';
import { COLORS, NIVO_THEME } from 'src/features/reports/theme';
import SteppingLine from './custom-layers/stepping-line';
import Balanced from './custom-layers/balanced';
import { CustomTooltip } from '../graph';

export interface LineChartProps {
  data: Serie[];
}

const MonthsSupplyLineChart = ({ data }: LineChartProps) => {
  const maxY = useMemo(() => {
    if (!data[0]?.data.length) return 8;
    return data[0].data.some((datum) => datum.y > 8) ? 12 : 8;
  }, [data]);

  return (
    <ResponsiveLine
      colors={COLORS.BLACK}
      theme={NIVO_THEME}
      data={data}
      margin={{ top: 10, right: 50, bottom: 35, left: 57 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        max: maxY,
        min: 0
      }}
      yFormat=""
      enableGridY={false}
      enableGridX={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 9,
        tickPadding: 0,
        legendOffset: 45,
        legend: null
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 15,
        tickRotation: 0,
        legend: "MONTH'S SUPPLY",
        legendOffset: -50,
        legendPosition: 'middle'
      }}
      sliceTooltip={({ slice }) => (
        <CustomTooltip
          points={slice.points.map((point) => ({
            id: point.id,
            label: "Month's Supply",
            value: `${point.data.y}`,
            color: ''
          }))}
        />
      )}
      enableSlices="x"
      useMesh
      layers={[
        // Default layers
        'grid',
        'markers',
        'axes',
        'crosshair',
        'slices',
        'mesh',
        'legends',
        // Custom layers
        SteppingLine,
        Balanced
      ]}
    />
  );
};

export default MonthsSupplyLineChart;
