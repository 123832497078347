import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import Gradient from './gradient';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Tiny } from './text';
import { COLORS, PADDINGS } from '../../theme';

const defaultStyles = StyleSheet({
  gradientContainer: {
    height: '1rem'
  },
  dot: {
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    background: COLORS.BLUE_GREEN,
    position: 'relative',
    top: '-1.5rem'
  }
});

@styled(defaultStyles)
class MarketIndicator extends PureComponent {
  static defaultProps = {
    value: 1
  };

  normaliseValue = (value) => {
    if (value < 1) {
      return 0;
    }

    if (value > 11) {
      return 12;
    }

    return value;
  };

  getTextColor = (value) => {
    if (value < 3) {
      return COLORS.GREEN.POSITIVE;
    }

    if (value >= 3 && value < 6) {
      return COLORS.ORANGE;
    }

    return COLORS.RED.DARK;
  };

  getText = (value) => {
    if (value <= 2) {
      return 'Under Supply';
    }

    if (value > 2 && value < 6) {
      return 'Balanced Supply';
    }

    return 'Oversupply';
  };

  render() {
    const { styles: s, value } = this.props;
    // Calculate dot offset
    // value / 12 to get the percentage
    // - 5 for the dot offset
    const offset = (this.normaliseValue(value) / 12) * 100 - 5;
    const color = this.getTextColor(value);
    const text = this.getText(value);

    return (
      <Box flex={1} flexDirection={'column'} {...s('container')}>
        <Box style={{ height: '1rem' }}>
          <Box {...s('gradientContainer')}>
            <Gradient left radius={5} />
          </Box>
          <Box {...s('dot')} style={{ left: `${offset}%` }} />
        </Box>
        <Box flex={1} alignItems={'center'} justifyContent={'center'}>
          <Box
            style={{
              color: color,
              border: `1px solid ${color}`,
              borderRadius: '2px',
              padding: `${PADDINGS.TINY} ${PADDINGS.XS}`,
              margin: PADDINGS.XS
            }}
          >
            <Tiny>{text}</Tiny>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default MarketIndicator;
