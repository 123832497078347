import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      createItem: (type, args) => api.post('/guest-tokens', args)
    }
  }
};

const actionCreators = {};

const GuestTokensGenerator = new Generator('guestTokens', config);
export default GuestTokensGenerator.createEntityModel({ actionCreators });
