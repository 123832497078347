import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchItem: (type, { ...args }, id) => api.get(`/stats/export/${id}`, args)
    }
  }
};

const ExportsGenerator = new Generator('exports', config);
export default ExportsGenerator.createEntityModel({});
