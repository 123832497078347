import React, { PureComponent } from 'react';
import { RenderMatchedRoutes, Link } from '@rexlabs/react-whereabouts';
import { push } from '@rexlabs/whereabouts';
import { withModel } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import ROUTES from 'src/routes';
import { COLORS, PADDINGS, TEXTS } from 'src/theme';
import Box from '@rexlabs/box';
import Sidebar from 'view/components/sidebar';
import { ModalTarget } from 'view/components/portal';
import { PortalTarget } from '@rexlabs/portal';
import { Spring, animated } from 'react-spring/renderprops';

import SwitchLayout from './switch';
import ToggleSvg from 'assets/hamburger.svg';
import coreLogoDark from 'assets/core-logo-dark.png';
import LoadingLayout from './loading';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { TextProvider } from '@rexlabs/text';
import ELEMENT_STYLE_COMPONENTS from 'theme/components';

const defaultStyles = StyleSheet({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: COLORS.BACKGROUND,
    overflow: 'auto',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  },

  wrapSidebar: {
    position: 'fixed',
    top: 90,
    bottom: 0,
    width: '19rem',
    background: COLORS.WHITE,
    padding: PADDINGS.S
  },

  wrapToggle: {
    cursor: 'pointer',
    margin: `0 ${PADDINGS.S}`,
    height: 40,
    width: 40,
    ':hover': {
      opacity: '0.6'
    }
  },

  main: {
    paddingLeft: '19rem'
  },

  header: {
    position: 'sticky',
    top: 0,
    height: '9rem',
    background: COLORS.PRIMARY_DARK,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    zIndex: 1
  },

  link: {
    lineHeight: 0
  },

  wrapContent: {
    position: 'static !important',
    overflow: 'auto',
    justifyContent: 'center',
    padding: `0 ${PADDINGS.S}`
  },

  content: {
    width: '100%',
    maxWidth: '120rem',
    height: '100%'
  }
});

@withModel(sessionModel)
@styled(defaultStyles)
class AppShell extends PureComponent {
  state = {
    // 1366 is the width of a landscape ipad pro
    hidden: window.innerWidth <= 1366
  };

  toggleSidebar = () => this.setState({ hidden: !this.state.hidden });

  render() {
    const { session, styles: s } = this.props;
    const { hidden } = this.state;

    if (!session.ready) {
      return <LoadingLayout />;
    }

    if (!session.accountId) {
      return <SwitchLayout {...this.props} />;
    }

    return (
      <Box {...s('container')}>
        <ModalTarget />
        <Box {...s('header')}>
          <PortalTarget name="header">
            {({ children }) => (
              <Box {...s('header')}>
                <Box {...s('wrapToggle')}>
                  <ToggleSvg onClick={this.toggleSidebar} />
                </Box>
                .
                <Link to={ROUTES.APP.HOME} {...s('link')}>
                  <img src={coreLogoDark} style={{ width: 175 }} />
                </Link>
                {children}
              </Box>
            )}
          </PortalTarget>
        </Box>

        <Box
          style={{
            width: '100%'
          }}
        >
          <Spring
            native
            force
            config={{ tension: 2000, friction: 100, precision: 1 }}
            from={{ left: hidden ? 0 : -190, transform: 'rotate(-180deg)' }}
            to={{ left: hidden ? -190 : 0, transform: 'rotate(0deg)' }}
          >
            {(styles) => (
              <animated.div {...s('wrapSidebar')} style={{ left: styles.left }}>
                <Sidebar {...this.props} />
                <Box
                  style={{
                    position: 'absolute',
                    left: 190,
                    top: 0,
                    zIndex: 100
                  }}
                />
              </animated.div>
            )}
          </Spring>

          <Spring
            native
            force
            config={{ tension: 2000, friction: 100, precision: 1 }}
            from={{ paddingLeft: hidden ? 190 : 0 }}
            to={{ paddingLeft: hidden ? 0 : 190 }}
          >
            {(styles) => (
              <animated.div style={{ ...{ width: '100%' }, ...styles }}>
                <Box flex={1} flexDirection="column">
                  <Box
                    {...s('wrapContent')}
                    flex={1}
                    style={{ position: 'static' }}
                  >
                    <Box {...s('content')}>
                      <Box {...s('breadcrumbs')} />
                      <RenderMatchedRoutes
                        routes={ROUTES.APP}
                        NotFound={() => {
                          push(ROUTES.APP.HOME, {});
                          return null;
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </animated.div>
            )}
          </Spring>
        </Box>
      </Box>
    );
  }
}

export default () => {
  return (
    <StylesProvider components={ELEMENT_STYLE_COMPONENTS} debug={__DEV__}>
      <TextProvider text={TEXTS}>
        <AppShell />
      </TextProvider>
    </StylesProvider>
  );
};
