import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'rc-slider';
import { isEqual } from 'lodash';

import { COLORS } from 'src/features/reports/theme';

import { QuarterYearType } from './quarter-range-slider';
import { MonthYearType } from './month-range-slider';

import 'rc-slider/assets/index.css';

export type SliderValueType = QuarterYearType | MonthYearType;

export interface RangeSliderProps {
  onChange: (value: SliderValueType) => void;
  sliderData: SliderValueType[];
  value?: SliderValueType;
  disabled?: boolean;
}

function RangeSlider({
  sliderData,
  value,
  onChange,
  disabled
}: RangeSliderProps) {
  const onSliderMoved = (index: number) => {
    onChange(sliderData[index]);
  };

  const slideValue = useMemo(
    () => sliderData.findIndex((m) => isEqual(m, value)),
    [sliderData, value]
  );

  const styleProps = useMemo(
    () => ({
      style: { height: 10, padding: 0 },
      railStyle: { height: 10, backgroundColor: COLORS.GREY.POWDER },
      handleStyle: {
        marginTop: -9,
        width: 28,
        height: 28,
        opacity: 1,
        border: 'none',
        boxShadow:
          '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(11, 30, 73, 0.12)',
        backgroundColor: disabled ? COLORS.GREY.POWDER : COLORS.BLUE.PRIMARY
      },
      trackStyle: {
        height: 10,
        backgroundColor: disabled ? COLORS.GREY.POWDER : COLORS.BLUE.PRIMARY
      }
    }),
    [disabled]
  );

  return (
    <Slider
      {...styleProps}
      disabled={disabled}
      onChange={onSliderMoved}
      reverse
      allowCross={false}
      min={0}
      max={sliderData.length - 1}
      value={slideValue}
    />
  );
}

export default RangeSlider;
