import React, { FC, CSSProperties } from 'react';
import LazyLoad, { LazyLoadProps } from 'react-lazyload';
import Box from '@rexlabs/box';
import { COLORS, FONT } from 'src/features/reports/theme';
import { DataColumn } from '.';
import { isNumber, isString } from 'lodash';
import { StyleSheet } from '@rexlabs/styling';

const LazyLoadWrapper: FC<{ disabled?: boolean } & LazyLoadProps> = ({
  disabled,
  children,
  ...rest
}) => {
  if (disabled) return <>{children}</>;
  return <LazyLoad {...rest}>{children}</LazyLoad>;
};

interface TableRowProps<T> {
  rowData: T;
  columns: DataColumn<T>[];
  height?: number;
  disableLazy?: boolean;
  divisionStyle: CSSProperties['gridTemplateColumns'];
}

const style = StyleSheet({
  cell: {
    borderRight: `1px solid ${COLORS.GREY.POWDER}`,
    '&:last-child': {
      borderRight: 'none'
    },
    width: '100%'
  },
  value: {
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 400,
    fontSize: 12,
    color: COLORS.BLUE.DARK,
    width: '100%'
  },
  row: {
    display: 'grid',
    flexWrap: 'nowrap',
    width: '100%',
    minHeight: 34,
    borderBottom: `1px solid ${COLORS.GREY.POWDER}`
  }
});

export const TableRow = <T,>({
  rowData,
  columns,
  height,
  disableLazy = false,
  divisionStyle
}: TableRowProps<T>) => {
  return (
    <LazyLoadWrapper height={height} overflow resize disabled={disableLazy}>
      <Box
        className={style.row}
        height={height}
        style={{ gridTemplateColumns: divisionStyle }}
      >
        {columns.map((column, idx) => {
          const cellValue = column.render
            ? column.render(rowData)
            : rowData[column.key];

          const isCustomRender =
            !column.render || isString(cellValue) || isNumber(cellValue);

          return (
            <Box
              key={`cell${idx}`}
              alignItems="center"
              className={style.cell}
              style={{
                padding: isCustomRender ? 8 : 0
              }}
            >
              <p
                className={style.value}
                style={{ fontWeight: idx === 0 ? 800 : 400 }}
              >
                {cellValue || '-'}
              </p>
            </Box>
          );
        })}
      </Box>
    </LazyLoadWrapper>
  );
};

export default TableRow;
