import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Canceler } from 'axios';
import { CancelToken } from '@rexlabs/api-client';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import { compose } from 'utils/compose';

import useMonthRangeFilter from 'src/features/reports/hooks/use-month-range-filter';
import keyInsightsModel from 'src/features/reports/models/key-insights';
import Panel from 'src/features/reports/components/panel';
import { ToggleButtonGroup } from 'src/features/reports/components/toggle-button';
import MonthRangeSlider, {
  MonthYearType
} from 'src/features/reports/components/slider/month-range-slider';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';

import { KeyInsightsModel } from 'types/models/key-insights';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import withError from 'view/containers/with-error';
import { Loading } from '../graph';
import { FILTER } from './constants';
import { SalesPerformanceDatum } from 'types/graph/sales-performance';
import { SalesPerformanceChart } from './sales-performance-chart';
import { Auth0Context } from 'src/auth0-provider';
import { INSIGHTS_PAGE } from 'src/features/reports/constants';
import { round } from 'lodash';
import InfoTooltip from './info-tooltip';

// Nivo TreeMap requires `hierarchical data object` instead of array
// which means that data will have to be wrapped in a parent object.
// The root object is displayed as a visible container with a background
// color hence we are hiding it here via opacity.
const defaultStyles = StyleSheet({
  treemapContainer: {
    '& svg > g > g:first-of-type': {
      opacity: 0
    }
  }
});

interface SalesPerformanceProps {
  keyInsightsModel: KeyInsightsModel;
  errorModal: WithErrorModalProps;
  styles: Styles;
}

const SalesPerformance = ({
  styles: s,
  keyInsightsModel,
  errorModal: { open: openError }
}: SalesPerformanceProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const canceler = useRef<Canceler | null>(null);
  const { period, selectedProjectIds, isFirstLoad } = useContext(
    ReportSettingsContext
  );
  const { dateFilter, setDateFilter, startPeriod, endPeriod } =
    useMonthRangeFilter();
  const [filter, setFilter] = useState<typeof FILTER[number]['key']>('gross');
  const [report, setReport] = useState<SalesPerformanceDatum[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const graphData = useMemo(() => {
    return report
      .filter((item) => item.percentage > 0)
      .map((item) => ({
        id: item.name,
        children: [{ value: round(item.percentage, 1) }]
      }));
  }, [report]);

  useEffect(() => {
    if (
      (!selectedProjectIds.length || !startPeriod || !endPeriod) &&
      !isFirstLoad
    ) {
      return;
    }

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    keyInsightsModel
      .fetchSalesPerformance({
        queryParams: {
          project_ids: selectedProjectIds,
          start_period: startPeriod,
          end_period: endPeriod
        },
        hasPermission: !isFirstLoad && hasPermission([INSIGHTS_PAGE]),
        config: { cancelToken }
      })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;
        setReport(res.data[filter]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        openError(err);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    startPeriod,
    endPeriod,
    keyInsightsModel,
    openError,
    hasPermission,
    filter,
    isFirstLoad
  ]);

  return (
    <Panel
      title="Sales Performance"
      tooltip={{
        title: 'Sales Performance',
        description: <InfoTooltip />
      }}
    >
      <Loading isLoading={isLoading} />
      <Box width="262px" m="0 auto 34px" pt="12px">
        <ToggleButtonGroup
          group={FILTER}
          value={[filter]}
          onToggle={(key: typeof FILTER[number]['key']) => setFilter(key)}
        />
      </Box>
      <Box width="588px" m="0 auto 34px">
        <MonthRangeSlider
          value={dateFilter}
          onChange={(value: MonthYearType) => setDateFilter(value)}
          numOfMonthsBack={11}
          latestMonth={period}
        />
      </Box>

      <Box height={500} width="auto" {...s('treemapContainer')}>
        <SalesPerformanceChart data={graphData} />
      </Box>
    </Panel>
  );
};

export default compose(
  styled(defaultStyles),
  withModel(keyInsightsModel),
  withError.withPropName('errorModal')
)(SalesPerformance);
