import React, { useEffect, createContext, useReducer, Fragment } from 'react';
import _ from 'lodash';
import GA from 'react-ga';

import Box, { LayoutProvider } from '@rexlabs/box';
import {
  RenderMatchedRoutes,
  Link,
  withWhereabouts
} from '@rexlabs/react-whereabouts';
import { push } from '@rexlabs/whereabouts';
import { withModel } from '@rexlabs/model-generator';
import { PortalTarget } from '@rexlabs/portal';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { TextProvider } from '@rexlabs/text';

import ModalTarget from 'view/components/portal/modal';
import withPermission from 'view/containers/with-permissions';
import withError from 'view/containers/with-error';
import SplashLayout from 'view/layouts/splash';
import SwitchLayout from 'view/layouts/switch';

import sessionModel from 'data/models/custom/session';
import coreLogoDark from 'assets/core-logo-dark.png';
import ROUTES from 'src/routes';
import COMPONENTS_THEME from 'theme/components';
import { COLORS, PADDINGS, TEXTS, LAYOUTS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    display: 'initial',
    overflow: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: COLORS.BACKGROUND
  },

  wrapSidebar: {
    position: 'absolute',
    // left: 0,
    top: 200,
    bottom: 0,
    width: '25rem',
    background: COLORS.WHITE,
    padding: PADDINGS.S
  },

  header: {
    display: 'flex',
    height: '20rem',
    background: COLORS.PRIMARY_DARK,
    width: '100%',
    alignItems: 'center'
  },

  content: {
    width: '100%'
  },

  wrapToggle: {
    cursor: 'pointer',
    margin: `0 ${PADDINGS.S}`,
    height: 40,
    width: 40,
    ':hover': {
      opacity: '0.6'
    }
  },

  link: {
    lineHeight: 0
  }
});

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

const ReportLayoutLegacyCore = (props) => {
  const { session, styles: s, whereabouts } = props;

  useEffect(() => {
    GA.pageview(window.location.pathname + window.location.search);
  });

  const isGuest =
    !!_.get(whereabouts, 'query.token') && !_.get(session, 'apiToken');

  if (!isGuest && !session.accountId) {
    return <SwitchLayout {...props} />;
  }

  if (!localStorage.getItem('splash')) {
    return <SplashLayout {...props} />;
  }

  if (isGuest) {
    delete ROUTES.APP.REPORT_SETTINGS_PRICELIST;
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'dismiss':
        return {
          ...state,
          ...{
            draftModalDismissed: {
              [action.id]: true
            }
          }
        };

      default:
        return state;
    }
  };

  return (
    <StateProvider initialState={{}} reducer={reducer}>
      <Box {...s('container')}>
        <Box {...s('header')}>
          <div
            style={{
              position: 'sticky',
              top: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Box {...s('wrapToggle')}>
              <PortalTarget name="report-sidebar-button">
                {({ children }) => <Fragment>{children}</Fragment>}
              </PortalTarget>
            </Box>

            <Link to={ROUTES.APP.HOME} {...s('link')}>
              <img src={coreLogoDark} style={{ width: 175 }} />
            </Link>

            <PortalTarget name="header">
              {({ children }) => <Box>{children}</Box>}
            </PortalTarget>
          </div>
        </Box>

        <RenderMatchedRoutes
          routes={ROUTES.APP}
          NotFound={() => {
            push(ROUTES.APP.REPORT, {});
            return null;
          }}
        />

        <ModalTarget />
      </Box>
    </StateProvider>
  );
};

const ReportLayoutWrapped = (props) => {
  return (
    <StylesProvider components={COMPONENTS_THEME} debug={__DEV__}>
      <TextProvider text={TEXTS}>
        <LayoutProvider layout={LAYOUTS}>
          <ReportLayoutLegacyCore {...props} />
        </LayoutProvider>
      </TextProvider>
    </StylesProvider>
  );
};

export default _.flow([
  styled(defaultStyles),
  withModel(sessionModel),
  withWhereabouts,
  withError.withPropName('errorModal'),
  withPermission('view:reports', true)
])(ReportLayoutWrapped);
