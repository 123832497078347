import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { Heading, SubHeading } from 'view/components/text';
import { PADDINGS } from 'theme';
import { StyleSheet, styled } from '@rexlabs/styling';
import PrimaryButton from '../../components/button/primary';
import { Link } from '@rexlabs/react-whereabouts';
import ROUTES from 'routes';
import SectionColumn from '../../components/grid/section-column';
import { Portal } from '@rexlabs/portal';

const defaultStyles = StyleSheet({
  btn: {
    padding: `${PADDINGS.XS} 0`,
    width: '100%'
  },
  content: {
    width: '100%',
    maxWidth: '120rem'
  },
  wrapContent: {
    overflow: 'auto',
    justifyContent: 'center',
    position: 'relative',
    padding: `0 ${PADDINGS.S}`
  }
});

@styled(defaultStyles)
class ReportMenu extends PureComponent {
  fullExport = () => {};

  render() {
    const { styles: s } = this.props;
    return (
      <Fragment>
        <Portal target="header">
          <Box
            style={{
              height: '100%',
              paddingLeft: PADDINGS.L
            }}
            flex={1}
            alignItems={'center'}
          >
            <Heading style={{ color: 'white' }}>Configure Report</Heading>
          </Box>
        </Portal>
        <Box {...s('wrapContent')} flex={1}>
          <Box {...s('content')}>
            <SectionColumn width={12}>
              <Box p={PADDINGS.S}>
                <Box pb={PADDINGS.XS}>
                  <SubHeading>Select an Option</SubHeading>
                </Box>
                <Link to={ROUTES.APP.REPORT_NEW}>
                  <PrimaryButton
                    green
                    style={{ width: '100%', margin: `${PADDINGS.XXS} 0` }}
                  >
                    Create New Report
                  </PrimaryButton>
                </Link>
                <Link to={ROUTES.APP.REPORT_OVERVIEW}>
                  <PrimaryButton
                    green
                    style={{ width: '100%', margin: `${PADDINGS.XXS} 0` }}
                  >
                    Load From Settings
                  </PrimaryButton>
                </Link>
              </Box>
            </SectionColumn>
          </Box>
        </Box>
      </Fragment>
    );
  }
}

export default ReportMenu;
