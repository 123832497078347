import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { compose } from 'utils/compose';
import { COLORS, FONT } from 'src/features/reports/theme';
import { Star } from '../icons';
import ArrowRight from '../icons/arrow-right';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { SelectedProjectsInSubRegion } from '../report-settings/sub-region-project-selector';
import { SubRegionWithProjects } from 'types/resource';
import { buildQueryString } from '@rexlabs/whereabouts';
import useRouteConfig from 'src/features/reports/hooks/use-route-config';
import { Whereabouts } from 'types/common';
import { ReportParams } from 'types/params';

const styles = StyleSheet({
  title: {
    fontSize: 12,
    marginBottom: 14
  },
  input: {
    border: `1px solid ${COLORS.BLUE.DARK}`,
    borderRadius: '4px',
    padding: '0 16px',
    color: COLORS.BLUE.DARK,
    fontSize: '16px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    width: '100%',
    outline: 'none',
    lineHeight: '33px',
    marginBottom: 18
  },
  list: {
    maxHeight: 367,
    overflowY: 'auto'
  },
  subRegion: {
    backgroundColor: COLORS.GREY.POWDER,
    borderRadius: 4,
    padding: '10px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    marginBottom: 10,
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: 0
    }
  },
  starContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  star: {
    marginRight: 11
  },
  name: {
    fontSize: 14,
    color: COLORS.BLUE.DARK,
    fontWeight: 500,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  projects: {
    fontSize: 10,
    color: COLORS.BLUE.DARK,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  helper: {
    fontSize: 10,
    fontFamily: FONT.FAMILIES.DEFAULT,
    color: COLORS.GREY.HELPER,
    marginBottom: 18
  },
  empty: {
    color: COLORS.BLUE.DARK,
    fontSize: 10,
    fontFamily: FONT.FAMILIES.DEFAULT,
    textAlign: 'center'
  }
});

interface SubRegionsProps {
  styles: Styles;
  whereabouts: Pick<Whereabouts<ReportParams>, 'query' | 'path'>;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const SubRegions = ({
  styles: s,
  whereabouts,
  setIsVisible
}: SubRegionsProps) => {
  const {
    premiumSubRegions,
    standardSubRegions,
    setSelectedProjectsInSubRegion,
    setSubRegionWithAllProjectsSelected,
    setSelectedReport,
    isFirstLoad,
    setFirstLoad,
    isSubRegionsLoading
  } = useContext(ReportSettingsContext);

  const { updateParams } = useRouteConfig({ whereabouts });
  const [keyword, setKeyword] = useState('');

  const filterSubRegion = useCallback(
    (subRegions: SubRegionWithProjects[]) => {
      return subRegions
        .filter((subRegion) => {
          const lowerCaseKeyword = keyword.toLowerCase();
          const projectsMatch = subRegion.projects.find((project) =>
            project.title.toLowerCase().includes(lowerCaseKeyword)
          );
          return (
            subRegion.name.toLowerCase().includes(lowerCaseKeyword) ||
            projectsMatch
          );
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    [keyword]
  );

  const filteredPremium = useMemo(
    () => filterSubRegion(premiumSubRegions),
    [filterSubRegion, premiumSubRegions]
  );

  const filteredStandard = useMemo(
    () => filterSubRegion(standardSubRegions),
    [filterSubRegion, standardSubRegions]
  );

  const onClick = (
    selectedProjectsInSubRegion: SelectedProjectsInSubRegion,
    subRegionWithAllProjectsSelected: SubRegionWithProjects['id'][]
  ) => {
    setSelectedProjectsInSubRegion(selectedProjectsInSubRegion);
    setSubRegionWithAllProjectsSelected(subRegionWithAllProjectsSelected);

    // Remove selected report when changing selected project ids
    // and use previous advance filters
    updateParams({
      project_ids: buildQueryString(selectedProjectsInSubRegion),
      sub_region_with_all_projects_selected: subRegionWithAllProjectsSelected,
      saved: undefined
    });
    setSelectedReport(null);

    setIsVisible(false);
    if (isFirstLoad) {
      setFirstLoad(false);
    }
  };

  return (
    <div>
      <h4 {...s('title')}>SUBREGIONS</h4>
      <input
        {...s('input')}
        placeholder="Search by project or subregion name..."
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
      />
      {keyword.length > 0 && (
        <p {...s('helper')}>
          Search results for projects and subregions containing &apos;{keyword}
          &apos;
        </p>
      )}
      {isSubRegionsLoading ? (
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          height={367}
        >
          <LoadingSpinner />
        </Box>
      ) : (
        [...filteredPremium, ...filteredStandard].length === 0 && (
          <Box
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={335}
          >
            <p {...s('empty')}>
              Oops, we could not find any subregions that match your search
              criteria
            </p>
          </Box>
        )
      )}
      <div {...s.with('list')({ maxHeight: keyword.length > 0 ? 335 : 367 })}>
        {filteredPremium.map((subRegion) => (
          <div
            key={subRegion.id}
            {...s('subRegion')}
            onClick={() =>
              onClick(
                {
                  [subRegion.id]: subRegion.projects.map(
                    (project) => project.id
                  )
                },
                [subRegion.id]
              )
            }
          >
            <div {...s('starContainer')}>
              <Star width="14" height="14" {...s('star')} />
              <div>
                <p {...s('name')}>{subRegion.name}</p>
                <p {...s('projects')}>
                  {subRegion.projects.length} project
                  {subRegion.projects.length > 1 ? 's' : ''}
                </p>
              </div>
            </div>
            <ArrowRight />
          </div>
        ))}
        {filteredStandard.map((subRegion) => (
          <div
            key={subRegion.id}
            {...s('subRegion')}
            onClick={() =>
              onClick(
                {
                  [subRegion.id]: subRegion.projects.map(
                    (project) => project.id
                  )
                },
                [subRegion.id]
              )
            }
          >
            <div {...s('starContainer')}>
              <div>
                <p {...s('name')}>{subRegion.name}</p>
                <p {...s('projects')}>
                  {subRegion.projects.length} project
                  {subRegion.projects.length > 1 ? 's' : ''}
                </p>
              </div>
            </div>
            <ArrowRight />
          </div>
        ))}
      </div>
    </div>
  );
};

export default compose<Pick<SubRegionsProps, 'setIsVisible'>>(
  styled(styles),
  withWhereabouts
)(SubRegions);
