import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { regionId, ...args }) =>
        api.get(`/regions/${regionId}/projects`, args)
    }
  }
};

const RegionProjectsGenerator = new Generator('regionProjects', config);
export default RegionProjectsGenerator.createEntityModel();
