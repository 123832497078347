import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import { SubHeading } from 'view/components/text';
import Table from 'view/components/table';
import Divider from 'view/components/divider';
import { formatNumber, formatPeriod } from 'utils/format';
import PopularCharacteristics from './popular-characteristics';
import AvailabilityVsSupply from './availability-vs-supply';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  }
});

@styled(defaultStyles)
class LotAvailability extends PureComponent {
  getTimeLabel = (string) => {
    if (string === 'yearly') {
      return '12 Months';
    }

    if (string === 'quarterly') {
      return '3 Months';
    }

    return 'This Month';
  };

  render() {
    const {
      styles: s,
      stock,
      soldCharacteristics,
      availableCharacteristics,
      availableSupply
    } = this.props;

    return (
      <Fragment>
        <Box {...s('sectionContainer')}>
          <Box
            p={PADDINGS.S}
            flex={1}
            justifyContent={'space-between'}
            alignItems={'baseline'}
          >
            <SubHeading>Stock Levels Available</SubHeading>
          </Box>

          <Table
            data={stock}
            columns={[
              {
                Header: () => (
                  <p style={{ color: COLORS.BLUE_GREEN }}>Estate</p>
                ),
                width: 150,
                accessor: 'project',
                fixed: 'left',
                style: {
                  background: COLORS.BLUE.LIGHT,
                  boxShadow: '4px 0px 3px -3px #ccc',
                  border: 'none !important',
                  fontWeight: '500'
                },
                Footer: 'Totals'
              },
              {
                Header: 'Entry Price',
                width: 150,
                accessor: 'entry',
                Cell: (row) => (
                  <span>{row.value ? `$${formatNumber(row.value)}` : '-'}</span>
                )
              },
              {
                Header: 'Titled Lots',
                width: 150,
                accessor: 'titled_listings',
                Footer: _.sumBy(stock, 'titled_listings')
              },
              {
                Header: 'Untitled Lots',
                width: 150,
                accessor: 'untitled_listings',
                Footer: _.sumBy(stock, 'untitled_listings')
              },
              {
                Header: 'Total Lots',
                width: 150,
                accessor: 'total_listings',
                Footer: _.sumBy(stock, 'total_listings')
              },
              {
                Header: 'Back on Market',
                width: 150,
                accessor: 'bom_listings',
                Footer: _.sumBy(stock, 'bom_listings')
              },
              {
                Header: 'New Lots This Month',
                width: 150,
                accessor: 'new_listings',
                Footer: _.sumBy(stock, 'new_listings')
              },
              {
                Header: 'Most Recent Stage',
                width: 150,
                accessor: 'recent_stage',
                Cell: (row) =>
                  `${formatPeriod(row.value?.period, true)} (Stage ${
                    row.value?.stage_no
                  })`,
                sortMethod: (a, b) =>
                  new Date(a.period) > new Date(b.period) ? 1 : -1
              },
              {
                Header: 'Titles Expected',
                width: 150,
                accessor: 'titles_expected'
              }
            ]}
            defaultPageSize={stock.length}
            pageSize={stock.length}
          />
        </Box>

        <Divider />

        <PopularCharacteristics
          stats={soldCharacteristics}
          title={'Most Popular Sold Allotment Characteristics'}
        />
        <PopularCharacteristics
          stats={availableCharacteristics}
          title={'Most Popular Available Allotment Characteristics'}
        />

        <AvailabilityVsSupply availableSupply={availableSupply} />
      </Fragment>
    );
  }
}

export default LotAvailability;
