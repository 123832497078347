import React, { PureComponent } from 'react';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';

export const pickerLang = {
  months: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  from: 'From',
  to: 'To'
};
class MonthPicker extends PureComponent {
  constructor(props, context) {
    super(props, context);

    const date = new Date();
    this.state = {
      value: { year: date.getFullYear(), month: date.getMonth() + 1 }
    };

    this.pickerRef = React.createRef();
  }

  render() {
    // Initial date on calendar
    const { value } = this.state;
    const { disabled, ...props } = this.props;

    // Date range of calendar
    // Will default to current year if not supplied
    const from = this.props.from || new Date().getFullYear();
    const to = this.props.to || new Date().getFullYear();

    // Add onClick to button component
    const button = React.cloneElement(this.props.button, {
      onClick: this.handleClickMonthBox
    });

    return (
      <ul>
        <li>
          <div className="edit">
            {disabled ? (
              button
            ) : (
              <Picker
                ref={this.pickerRef}
                years={{
                  min: from,
                  max: to
                }}
                value={value}
                lang={pickerLang.months}
                onDismiss={this.handleAMonthDismiss}
                {...props}
              >
                {button}
              </Picker>
            )}
          </div>
        </li>
      </ul>
    );
  }

  handleClickMonthBox = () => {
    this.pickerRef.current.show();
  };

  handleAMonthDismiss = (value) => {
    this.setState({ value: value });
    if (this.props.onHide) {
      this.props.onHide();
    }
  };
}

export default MonthPicker;
