import React, { PureComponent } from 'react';
import { Modal } from 'view/components/modal';
import WorksheetForm from 'view/forms/worksheet-new';

class WorksheetModal extends PureComponent {
  render() {
    const { closeModal, projectId, onSubmit, period, projects, worksheet } =
      this.props;

    return (
      <Modal hasButtonBar visibleOverflow>
        <WorksheetForm
          onSubmit={onSubmit}
          title="Create Worksheet"
          projectId={projectId}
          closeModal={closeModal}
          period={period || false}
          projcts={projects}
          worksheet={worksheet}
        />
      </Modal>
    );
  }
}

export default WorksheetModal;
