import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  buttonBar: {
    position: 'sticky',
    background: COLORS.WHITE,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: `${PADDINGS.S} ${PADDINGS.XL}`,
    '& *': {
      margin: '0.5rem'
    }
  }
});

const ButtonBar = ({ styles: s, className, ...props }) => {
  return <Box {...s.with('buttonBar')(className)} {...props} />;
};

export default styled(defaultStyles)(ButtonBar);
