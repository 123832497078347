/* eslint-disable max-lines */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Point, ResponsiveLine } from '@nivo/line';
import { ceil, floor, maxBy, minBy, random } from 'lodash';
import { Canceler } from 'axios';

import { withModel } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';

import SecondSetPoints from './custom-layers/points';
import {
  SolidCirclePoints,
  SolidXAxis,
  DashedLine,
  Loading,
  Legend,
  CustomTooltip
} from '../graph';
import { ToggleButtonGroup } from '../toggle-button';

import { COLORS, NIVO_THEME } from 'src/features/reports/theme';
import { abbrNum, getShortMonthWithYear, numberWithCommas } from 'utils/format';
import { CALCULATION, LOT_SIZE, LOT_TYPE } from './filters';
import keyInsightsModel from 'src/features/reports/models/key-insights';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { compose } from 'utils/compose';
import withError from 'view/containers/with-error';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { BreakdownList, ProductBreakdown } from 'types/graph/product-overview';
import { INSIGHTS_PAGE, TIME_INTERVAL } from 'src/features/reports/constants';
import { KeyInsightsModel } from 'types/models/key-insights';
import { Auth0Context } from 'src/auth0-provider';
import { CancelToken } from '@rexlabs/api-client';

const styles = StyleSheet({
  graphContainer: {
    position: 'relative'
  },
  floatingGraph: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1
  },
  filtersContainer: {
    gap: '24px'
  }
});

interface Filters {
  calculation: string[];
  timeInterval: string[];
  lotSize: string[];
  lotType: string[];
}

interface BreakdownProps {
  styles: Styles;
  keyInsightsModel: KeyInsightsModel;
  errorModal: WithErrorModalProps;
}

const Breakdown = ({
  styles: s,
  keyInsightsModel,
  errorModal: { open: openError }
}: BreakdownProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const canceler = useRef<Canceler | null>(null);
  const { selectedProjectIds, period, isFirstLoad } = useContext(
    ReportSettingsContext
  );
  const [filters, setFilters] = useState<Filters>({
    calculation: ['median'],
    timeInterval: ['monthly'],
    lotSize: ['price_per_m2'],
    lotType: ['traditional', 'small']
  });
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState<ProductBreakdown | null>(null);

  // Change legend based on selected calculation and lot size
  const graphData = useMemo(() => {
    if (!report) return [];
    const lotSize = filters.lotSize.includes('price_per_m2') ? '$/m2' : 'Size';
    const calculation = filters.calculation.includes('median')
      ? 'Median'
      : 'Average';

    const filtered = report[filters.timeInterval[0]][
      filters.lotType.length === 2 ? 'all' : filters.lotType[0]
    ] as BreakdownList;

    const firstSet = {
      id: `${calculation} Price`,
      data: Object.keys(filtered).map((key) => ({
        x: filters.timeInterval.includes('monthly')
          ? getShortMonthWithYear(key)
          : key,
        y: filtered[key][filters.calculation[0]].price,
        excluded_lots: filtered[key][filters.calculation[0]].excluded_lots
      }))
    };

    const secondSet = {
      id: `${calculation} ${lotSize}`,
      data: Object.keys(filtered).map((key) => ({
        x: filters.timeInterval.includes('monthly')
          ? getShortMonthWithYear(key)
          : key,
        y: filtered[key][filters.calculation[0]][filters.lotSize],
        excluded_lots: filtered[key][filters.calculation[0]].excluded_lots
      }))
    };

    return [firstSet, secondSet];
  }, [filters, report]);

  useEffect(() => {
    if ((!period || selectedProjectIds.length === 0) && !isFirstLoad) return;

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    keyInsightsModel
      .fetchProductOverview({
        queryParams: {
          project_ids: selectedProjectIds,
          period,
          report: 'breakdown'
        },
        hasPermission: !isFirstLoad && hasPermission([INSIGHTS_PAGE]),
        config: { cancelToken }
      })
      .then((res) => {
        if (res.data) {
          setReport(res.data as ProductBreakdown);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        openError(error);
        setIsLoading(false);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    period,
    keyInsightsModel,
    openError,
    hasPermission,
    isFirstLoad
  ]);

  return (
    <Box {...s('container')}>
      <Loading isLoading={isLoading} />
      <Box
        {...s('filtersContainer')}
        flexDirection="row"
        justifyContent="center"
        mt="24px"
      >
        <Box width="236px">
          <ToggleButtonGroup
            value={filters.calculation}
            group={CALCULATION}
            onToggle={(key: string) =>
              setFilters((prev) => ({ ...prev, calculation: [key] }))
            }
          />
        </Box>
        <Box width="236px">
          <ToggleButtonGroup
            value={filters.timeInterval}
            group={TIME_INTERVAL}
            onToggle={(key: string) =>
              setFilters((prev) => ({ ...prev, timeInterval: [key] }))
            }
          />
        </Box>
        <Box width="236px">
          <ToggleButtonGroup
            value={filters.lotSize}
            group={LOT_SIZE}
            onToggle={(key: string) =>
              setFilters((prev) => ({ ...prev, lotSize: [key] }))
            }
          />
        </Box>
        <Box width="236px">
          <ToggleButtonGroup
            collapsed
            value={filters.lotType}
            group={LOT_TYPE}
            onToggle={(key: string) =>
              setFilters((prev) => ({
                ...prev,
                // Filter only when both are selected as we can't have empty
                lotType: prev.lotType.includes(key)
                  ? prev.lotType.length === 2
                    ? prev.lotType.filter((prevKey) => prevKey !== key)
                    : prev.lotType
                  : [...prev.lotType, key]
              }))
            }
          />
        </Box>
      </Box>
      <Box {...s('graphContainer')} width="100%" height="615px">
        <Box {...s('floatingGraph')} width="100%" height="615px">
          <ResponsiveLine
            data={graphData}
            theme={NIVO_THEME}
            colors={[COLORS.BLUE.PRIMARY, COLORS.PINK.LIGHT]}
            margin={{ top: 50, right: 75, bottom: 40, left: 70 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              // Calculate min max with respect to the second set of values
              // Because the first set values are 360,000 up and the second set is only around 1,000
              // Since we are hiding the first set of values here the calculation is based on the second set
              max: graphData[1] ? ceil(maxBy(graphData[1].data, 'y')?.y) : 0,
              min: graphData[1] ? floor(minBy(graphData[1].data, 'y')?.y) : 0
            }}
            yFormat=""
            enableSlices="x"
            enableGridX={false}
            axisTop={null}
            axisLeft={null}
            axisBottom={null}
            axisRight={{
              tickSize: 0,
              tickPadding: 12,
              legend: filters.lotSize.includes('price_per_m2')
                ? '$/M2'
                : 'SIZE',
              legendOffset: 70,
              legendPosition: 'middle',
              format: (value) =>
                `${
                  filters.lotSize.includes('price_per_m2') ? '$' : ''
                }${numberWithCommas(value)}${
                  filters.lotSize.includes('size') ? ' m2' : ''
                }${filters.lotSize.includes('price_per_m2') ? '/m2' : ''}`
            }}
            sliceTooltip={({ slice }) => (
              <CustomTooltip
                points={slice.points.map((point) => ({
                  id: point.id,
                  label: point.serieId as string,
                  value: `${
                    !(point.serieId as string).includes('Size') ? '$' : ''
                  }${numberWithCommas(
                    filters.calculation.includes('average')
                      ? Math.round(point.data.y as number)
                      : point.data.y
                  )}${(point.serieId as string).includes('Size') ? ' m2' : ''}${
                    (point.serieId as string).includes('$/m2') ? '/m2' : ''
                  }`,
                  color: point.serieColor
                }))}
                note={
                  slice.points.find(
                    (point) =>
                      (
                        point.data as Point['data'] & {
                          excluded_lots: number;
                        }
                      ).excluded_lots > 0
                  )
                    ? 'An allotment(s) price is unavailable within this period'
                    : ''
                }
              />
            )}
            layers={[
              // Default layers
              'markers',
              'axes',
              'areas',
              'crosshair',
              'slices',
              'mesh',
              'legends',
              // Custom layers,
              DashedLine,
              SecondSetPoints,
              SolidXAxis
            ]}
          />
        </Box>
        <ResponsiveLine
          data={graphData[0] ? [graphData[0]] : []}
          theme={NIVO_THEME}
          colors={[COLORS.BLUE.PRIMARY]}
          margin={{ top: 50, right: 75, bottom: 40, left: 70 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto'
          }}
          yFormat=""
          enableSlices="x"
          enableGridX={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 9,
            legend: null,
            tickPadding: 0,
            legendOffset: 45,
            legendPosition: 'middle'
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 12,
            legend: `${filters.calculation[0].toUpperCase()} PRICE`,
            legendOffset: -65,
            legendPosition: 'middle',
            format: (value) => `$${abbrNum(value)}`.toUpperCase()
          }}
          layers={[
            // Default layers
            'markers',
            'axes',
            'areas',
            'crosshair',
            'slices',
            'mesh',
            'legends',
            'grid',
            'lines',
            // Custom layers
            SolidCirclePoints
          ]}
        />
      </Box>
      <Legend
        names={graphData.map((data) => data.id)}
        colors={[COLORS.BLUE.PRIMARY, COLORS.PINK.LIGHT]}
      />
    </Box>
  );
};

export default compose(
  withModel(keyInsightsModel),
  styled(styles),
  withError.withPropName('errorModal')
)(Breakdown);
