import React, { useMemo } from 'react';
import { animated } from '@react-spring/web';
import { ComputedBoxPlotSummary } from './types';
import { COLORS } from '../../theme';

const BoxPlotGroupLabel = ({ layout, x, y, data }: ComputedBoxPlotSummary) => {
  const transform = useMemo(() => {
    return layout === 'vertical'
      ? `translate(${x - 27}, 0)`
      : `translate(0, ${y - 27})`;
  }, [x, y, layout]);

  return (
    <animated.g transform={transform}>
      <animated.text
        fontSize={10}
        fontFamily="DM Sans"
        color={COLORS.BLUE.DARK}
      >
        {data.group}
      </animated.text>
    </animated.g>
  );
};

export default BoxPlotGroupLabel;
