import Validator from 'validatorjs';
import _ from 'lodash';

export function createValidationRules(definitions) {
  const rules = {};
  const attributeNames = {};
  const includes = {};

  for (const key in definitions) {
    const definition = definitions[key];

    if (Array.isArray(definition)) {
      rules[key] = definition[0];
      // ValidatorJS does not have a way of mapping wildcard field names
      // to attributes so this is a workaround to fix that
      if (/(\*)/.test(key)) {
        for (let i = 0; i < 100; i++) {
          attributeNames[`${key.split('.*')[0]}.${i}`] = definition[1];
          includes[`${key.split('.*')[0]}.${i}`] = definition[2];
        }
      } else {
        attributeNames[key] = definition[1];
        includes[key] = definition[2];
      }
    } else {
      rules[key] = definition;
    }
  }

  return (values) => {
    const newRules = rules;

    const validator = new Validator(values, newRules);
    validator.setAttributeNames(attributeNames);
    if (validator.fails()) {
      return getErrorMessages(validator);
    } else {
      return {};
    }
  };
}

function getErrorMessages(validator) {
  const errors = validator.errors.all();

  return _.reduce(
    errors,
    (acc, error, field) => {
      // Incase if the error key is a nested object like 'person.name.firstname'
      // and the specified 'name' prop on the FormField is 'person'
      // we remove the tail keys and just return the parent key.
      const { newError, newField } = checkObjectAndGetRootKey(error, field);
      // Change the field name in the error msg to proper case.
      // e.g. lotNumber => Lot Number
      const errMsg = _.replace(newError[0], newField, _.startCase(newField));
      _.set(acc, newField, errMsg);
      return acc;
    },
    {}
  );
}

function checkObjectAndGetRootKey(error, field) {
  const fieldParts = field.split('.');

  if (fieldParts.length > 0) {
    const rootKey = fieldParts[0];
    const newError = _.replace(error[0], field, rootKey);
    return { newError: [newError], newField: rootKey };
  }

  return { newError: error, newField: field };
}
