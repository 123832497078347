import React, { Fragment, PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';
import { Link, withWhereabouts } from '@rexlabs/react-whereabouts';
import { Tiny, Body } from 'view/components/text';
import { Arrow } from './arrow';
import PropTypes from 'prop-types';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    background: COLORS.BLUE_GREEN,
    height: '15rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapContent: {
    overflow: 'auto',
    padding: `0 ${PADDINGS.S}`,
    maxWidth: '120rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: COLORS.WHITE
  },
  created: {
    marginTop: PADDINGS.S,
    marginBottom: PADDINGS.S,
    paddingTop: PADDINGS.XXS,
    borderTop: `1px solid ${COLORS.GREY.MEDIUM}`,
    color: COLORS.GREY.MEDIUM,
    width: '100%',
    maxWidth: '120rem'
  }
});

@styled(defaultStyles)
@withWhereabouts
class ReportFooter extends PureComponent {
  render() {
    const {
      styles: s,
      whereabouts,
      prevText,
      prevRoute,
      nextText,
      nextRoute,
      settingId
    } = this.props;
    const date = new Date();
    return (
      <Fragment>
        <Box flex={0} justifyContent="center">
          <Box {...s('created')}>{`Created ${date.toDateString()}`}</Box>
        </Box>

        <Box {...s('container')}>
          <Box {...s('wrapContent')}>
            <div>
              {prevRoute && (
                <Link
                  to={prevRoute}
                  query={whereabouts.query}
                  params={settingId ? { id: settingId } : {}}
                >
                  <Box {...s('item')}>
                    <Arrow
                      style={{
                        borderColor: 'white',
                        transform: 'rotate(135deg) scale(2)',
                        marginRight: PADDINGS.XS
                      }}
                    />
                    <Box flex={1} flexDirection={'column'}>
                      <Tiny>Previous</Tiny>
                      <Body>{prevText}</Body>
                    </Box>
                  </Box>
                </Link>
              )}
            </div>

            <div>
              {nextRoute && (
                <Link
                  to={nextRoute}
                  query={whereabouts.query}
                  params={settingId ? { id: settingId } : {}}
                >
                  <Box {...s('item')}>
                    <Box
                      flex={1}
                      flexDirection={'column'}
                      style={{ textAlign: 'right' }}
                    >
                      <Tiny>Next</Tiny>
                      <Body>{nextText}</Body>
                    </Box>
                    <Arrow
                      style={{
                        borderColor: 'white',
                        transform: 'rotate(-45deg) scale(2)',
                        marginLeft: PADDINGS.XS
                      }}
                    />
                  </Box>
                </Link>
              )}
            </div>
          </Box>
        </Box>
      </Fragment>
    );
  }
}

ReportFooter.propTypes = {
  prevText: PropTypes.string,
  nextText: PropTypes.string,
  prevRoute: PropTypes.object,
  nextRoute: PropTypes.object
};

export default ReportFooter;
