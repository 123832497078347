import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, SHADOWS } from 'src/theme';
import { Heading, Small } from 'view/components/text';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.SUMMARY,
    borderRadius: '4px',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'center'
  },
  title: {
    color: COLORS.GREY.DARK
  },
  change: {
    marginLeft: '1rem'
  }
});

@styled(defaultStyles)
class TitledStatBox extends PureComponent {
  render() {
    const { styles: s, title, value, change } = this.props;
    const txtColor = change >= 0 ? COLORS.GREEN.POSITIVE : COLORS.RED.NEGATIVE;
    const isValidValue = value !== 'undefined' || value !== null;
    const statValue = isValidValue ? value : '-';

    return (
      <Box p={PADDINGS.XS} {...s('container')}>
        <Small {...s('title')}>{title}</Small>
        <Box style={{ display: 'inline-flex' }}>
          <Heading>{statValue}</Heading>
          {change && (
            <Heading style={{ color: txtColor }} {...s('change')}>
              ({change >= 0 ? '+' + change : change})
            </Heading>
          )}
        </Box>
      </Box>
    );
  }
}

export default TitledStatBox;
