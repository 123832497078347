import React, { Fragment, PureComponent } from 'react';
import _ from 'lodash';

import { query, withQuery, withModel } from '@rexlabs/model-generator';
import { Grid, Column } from '@rexlabs/grid';
import { styled, StyleSheet } from '@rexlabs/styling';
import { push } from '@rexlabs/whereabouts';
import { Portal } from '@rexlabs/portal';
import { Link } from '@rexlabs/react-whereabouts';
import List from '@rexlabs/list';
import LoadingSpinner from '@rexlabs/loading-spinner';
import Box from '@rexlabs/box';

import worksheetsModel from 'data/models/entities/worksheets';
import projectsModel from 'data/models/entities/projects';
import stagesModel from 'data/models/entities/stages';
import lotsModel from 'data/models/entities/lots';
import periodsModel from 'data/models/entities/periods';
import sessionModel from 'data/models/custom/session';

import { Modal } from 'view/components/modal';
import { formatPeriod } from 'utils/format';
import { Heading, SubHeading, Body, Small, Tiny } from 'view/components/text';
import { ActionButton, AddButton, ClearButton } from 'view/components/button';
import Breadcrumb from 'view/components/breadcrumb/breadcrumb';
import SectionColumn from 'view/components/grid/section-column';
import TitledStatBox from 'view/components/summary/titled-stat-box';
import ProjectStageItem from 'view/components/list/project-stage-item';
import ProjectStageHeader from 'view/components/list/project-stage-header';
import PrimaryButton from 'view/components/button/primary';
import MonthPicker from 'view/components/calendar/month';
import withError from 'view/containers/with-error';
import withAction from 'view/containers/with-action';
import LotModal from 'view/modals/lot';
import StageModal from 'view/modals/stage';
import LoadingLayout from 'view/layouts/loading';
import ProjectLotListItem from 'view/components/list/project-lot-list-item';
import ProjectLotListHeader from 'view/components/list/project-lot-list-header';
import WorksheetModal from 'view/modals/worksheets-new';
import ErrorLayout from 'view/layouts/error';
import withPermission from 'view/containers/with-permissions';
import MassEditLotForm from 'view/forms/mass-edit-lots';
import LotForm from 'view/forms/lot';

import { COLORS, PADDINGS } from 'src/theme';
import Cross from 'src/assets/cross-dark.svg';
import ROUTES from 'src/routes';
import { formatCurrency } from '../../../utils/format';

const STAGES_PER_PAGE = 5;

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: 'white',
    margin: PADDINGS.XS
  },
  headerButtons: {
    height: '100%',
    alignItems: 'center',
    padding: '4rem',
    '> *': {
      margin: '0.5rem'
    }
  },
  headerContainer: {
    backgroundColor: 'white',
    margin: PADDINGS.XS,
    justifyContent: 'space-between'
  },
  breadcrumb: {
    marginLeft: '0.8rem',
    marginTop: '0.8rem'
  },
  row: {
    justifyContent: 'space-between',
    ':first-child': {
      paddingBottom: '1.5rem'
    }
  },
  status: {
    color: COLORS.BLUE_GREEN,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  btn: {
    margin: '0.5rem'
  },
  loadMoreBtnContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem'
  }
});

function getWorksheetId(props) {
  return _.get(props, 'match.params.worksheetId');
}

function getProjectId(props) {
  return _.get(props, 'match.params.projectId');
}

const worksheetsQuery = query`{
  ${worksheetsModel} (id: ${getWorksheetId}, projectId: ${getProjectId}, include: "stats, period, flags") {
    id 
    project_id
    status
    no_price_list
    supply_issue
    notes
    stages
    stats
    period
    flags
  }
}`;

const projectsQuery = query`{
  ${projectsModel} (id: ${getProjectId}) {
    id
    title
  }
}`;

const stagesQuery = query`{
  ${stagesModel} (projectId: ${getProjectId}, worksheetId: ${getWorksheetId}) {
    id 
    stage_no
    title
    total_listings
    is_min_seven_star_nathers
    is_zero_net_carbon_certified
    is_passive_home
    is_greenstar
    is_enviro_development
  }
}`;

const lotsQuery = query`{
  ${lotsModel} (projectId: ${getProjectId}, worksheetId: ${getWorksheetId}, include: "stage,meta") {
    id
    stage_id
    price
    status
    lot_number
    lot_size
    lot_orientation
    price_per_m2
    frontage
    titled
    depth
    stage
    price_change
    negotiation_buffer
    rebate
    notes
    is_corner
    is_irregular
    is_powerlines
    is_nearby_amenity
    is_park_wetlands
    is_substation
    is_fill
    is_fall
    is_double_storey
    meta
  }
}`;

const publishedValue = (
  isPublished,
  value,
  isCurrency = false,
  suffix = ''
) => {
  if (_.isNumber(value)) {
    const newValue = isCurrency ? formatCurrency(value) : value;
    return isPublished ? `${newValue}${suffix}` : '-';
  }

  return '-';
};

@withPermission()
@withError.withPropName('errorModal')
@withAction.withPropName('actionModal')
@withQuery(worksheetsQuery)
@withQuery(projectsQuery)
@withQuery(stagesQuery)
@withQuery(lotsQuery)
@withModel(periodsModel)
@withModel(sessionModel)
@styled(defaultStyles)
class ViewWorksheetScreen extends PureComponent {
  state = {
    addLotModal: false,
    addStageModal: false,
    addWorksheetModal: false,
    checked: {},
    checkedLots: {},
    lastWorksheet: {},
    deleteStages: false,
    deleteLots: false,
    lastLot: null,
    allLoadedStages: [],
    stagePage: 1,

    order: {
      field: 'lot_number',
      direction: 'asc'
    }
  };

  getLastWorksheet(props) {
    const { worksheets } = props;
    const projectId = getProjectId(props);
    let lastWorksheet = {};
    worksheets
      .fetchList({
        id: 'fetch-worksheets',
        args: {
          projectId: projectId,
          includes: 'listings'
        }
      })
      .then((val) => {
        lastWorksheet = _.get(
          _.head(
            _.get(val, 'data', []).sort((a, b) => b.created_at - a.created_at)
          ),
          'item'
        );
        this.setState({
          lastWorksheet: lastWorksheet
        });
      });
  }

  componentDidMount() {
    this.getLastWorksheet(this.props);
  }

  componentDidUpdate(prevProps) {
    const { worksheets } = this.props;

    const { status: prevStatus, data: prevData } = _.get(
      prevProps.worksheets,
      'item'
    );
    const { status, data } = _.get(worksheets, 'item');

    // After the component is mounted, there are instances that the actual worsheets data is still not loaded,
    // which is dependency on querying stages. So I wait it here on componentDidUpdate.
    // When worksheet data is now present and the status is 'loaded', then we fetch stages associated with the current worksheet.
    // At the same time, we just call `fetchStages` only when the worksheet changes.
    if (prevData.id !== data.id && prevStatus !== status) {
      this.fetchStages();
    }
  }

  fetchStages() {
    const { stagePage } = this.state;
    const { stages } = this.props;

    // to avoid too much manipulation of data when refreshing, deleting or adding
    // stage records, we just replace the list with BE response data.
    const recordsToFetch = stagePage * STAGES_PER_PAGE;

    stages.fetchList({ per_page: recordsToFetch }).then((result) => {
      const mappedStages = _.map(result.data, (stage) => stage.item);
      this.setState({ allLoadedStages: mappedStages });
    });
  }

  fetchMoreStages() {
    this.setState({ stagePage: this.state.stagePage + 1 }, () =>
      this.fetchStages()
    );
  }

  findWorksheetAndRedirect(year, month) {
    const { worksheets, errorModal, actionModal } = this.props;
    const projectId = getProjectId(this.props);
    const targetDate = new Date(year, month - 1);

    // Get all project worksheets
    worksheets
      .fetchList({
        id: 'fetch-worksheets',
        args: {
          projectId: projectId,
          include: 'period'
        }
      })
      .then((val) => {
        const sheets = _.get(val, 'data');
        // Filter down to the correct period
        const targetSheet = sheets.filter((sheet) => {
          const sheetDate = new Date(sheet.item.period.end_date);
          return (
            sheetDate.getFullYear() === targetDate.getFullYear() &&
            sheetDate.getMonth() === targetDate.getMonth()
          );
        });
        if (targetSheet.length < 1) {
          // Error if no period is found
          actionModal.open(
            () => {
              worksheets
                .createItem({
                  data: {
                    account_id: this.props.session.accountId,
                    period: `${year}-${month}-1`,
                    status: 'draft',
                    project_id: projectId,
                    copy_from: true
                  }
                })
                .then((response) => {
                  const id = _.get(response, 'data.id');
                  actionModal.close();
                  push(ROUTES.APP.WORKSHEET, {
                    params: {
                      projectId: projectId,
                      worksheetId: id
                    }
                  });
                })
                .catch((err) => {
                  errorModal.open(err);
                  actionModal.close();
                });
            },
            'Worksheet not found for this period. Would you like to create it?',
            'Worksheet not found'
          );
        } else {
          // Redirect to worksheet
          push(ROUTES.APP.WORKSHEET, {
            params: {
              projectId: projectId,
              worksheetId: targetSheet[0].item.id
            }
          });
        }
      });
  }

  refreshValues = () => {
    const { lots, stages, worksheets } = this.props;
    const worksheet = _.get(worksheets, 'item.data');

    lots.refreshList();
    this.fetchStages();
    worksheets.refreshItem({
      id: worksheet.id,
      args: {
        projectId: worksheet.project_id,
        include: 'stats,period,flags'
      }
    });
  };

  getCheckedLotIds = (lots) => {
    return (
      _(lots)
        // Map from key => val to index => { val: val, id: id }
        .map((val, key) => ({ val: val, id: key }))
        // Filter down to only true values
        .filter((val) => val.val)
        // Grab the ids
        .map((val) => val.id)
        // Get the value
        .value()
    );
  };

  deleteLots = () => {
    const { checkedLots } = this.state;
    const { lots, errorModal } = this.props;

    this.setState({
      deleteLots: true
    });

    // Filter down to the checked projects
    const filteredLots = _.map(checkedLots, (value, key) => {
      return { id: key, value: value };
    }).filter((value) => value.value);

    Promise.all(
      filteredLots.map((lot) =>
        lots.trashItem({
          id: lot.id,
          args: {
            worksheetId: getWorksheetId(this.props),
            projectId: getProjectId(this.props)
          }
        })
      )
    )
      .then(() => {
        this.refreshValues();
        this.setState({
          deleteLots: false
        });
        this.props.actionModal.close();
      })
      .catch((e) => {
        this.refreshValues();
        this.setState({
          deleteLots: false
        });
        this.props.actionModal.close();
        errorModal.open(e);
      });
  };

  deleteStages = () => {
    const { checked } = this.state;
    const { stages, errorModal } = this.props;

    this.setState({
      deleteStages: true
    });

    const filteredStages = _.filter(checked, (val) => val.checked);
    if (
      _.filter(filteredStages, (val) => _.get(val, 'stage.total_listings') > 0)
        .length
    ) {
      errorModal.open('Cannot delete stages with lots attached');
      this.setState({
        deleteStages: false
      });
      this.props.actionModal.close();
      return;
    }

    Promise.all([
      _.map(filteredStages, (val) => {
        const stage = val.stage;
        return stages.trashItem({
          id: stage.id,
          args: {
            worksheetId: getWorksheetId(this.props),
            projectId: getProjectId(this.props)
          }
        });
      })
    ])
      .then(() => {
        this.setState(
          {
            checked: {},
            deleteStages: false
          },
          () => this.fetchStages()
        );

        this.props.actionModal.close();
      })
      .catch((e) => {
        errorModal.open(e);
        this.setState({
          deleteStages: false
        });
        this.props.actionModal.close();
      });
  };

  duplicateFrom = (lot) => {
    const badHeaders = ['id', 'lot_number', 'status'];
    const goodKeys = Object.keys(lot).filter(
      (property) => badHeaders.indexOf(property) === -1
    );

    const newLot = _.pick(lot, goodKeys);

    this.setState({
      lastLot: newLot,
      addLotModal: true
    });
  };

  toggleItem = (item) => {
    this.setState({
      checkedLots: {
        ...this.state.checkedLots,
        ...{
          [item.id]: !this.state.checkedLots[item.id]
        }
      }
    });
  };

  onOrder = (field) => {
    const { lots } = this.props;
    const { order } = this.state;

    if (field === order.field && order.direction === 'asc') {
      this.setState({
        order: {
          field: field,
          direction: 'desc'
        }
      });
      lots.fetchList({
        o: `${field}.desc`
      });
    } else {
      this.setState({
        order: {
          field: field,
          direction: 'asc'
        }
      });
      lots.fetchList({
        o: `${field}.asc`
      });
    }
  };

  handleLotListHeaderToggle = (e) => {
    e.preventDefault();

    const { lots } = this.props;

    if (
      _.filter(this.state.checkedLots, (value) => value).length ===
      lots.list.items.length
    ) {
      this.setState({
        checkedLots: {}
      });
    } else {
      const toggledState = lots.list.items.reduce((obj, item) => {
        return { ...obj, [item.id]: true };
      }, {});
      this.setState({
        checkedLots: toggledState
      });
    }
  };

  renderLotListHeader = () => {
    const { lots } = this.props;
    const checked =
      _.filter(this.state.checkedLots, (value) => value).length ===
      lots.list.items.length;

    return (
      <ProjectLotListHeader
        order={this.state.order}
        onOrder={this.onOrder}
        checked={checked}
        onSelectAll={this.handleLotListHeaderToggle}
      />
    );
  };

  renderLotListItem = (item) => {
    const { worksheets, stages, lots } = this.props;
    const worksheet = _.get(worksheets, 'item.data');
    const published = _.get(worksheet, 'status') === 'published';

    return (
      <ProjectLotListItem
        lot={item}
        published={published}
        checked={!!this.state.checkedLots[item.id]}
        projectId={getProjectId(this.props)}
        worksheetId={getWorksheetId(this.props)}
        stages={stages}
        lots={lots}
        onDuplicate={this.duplicateFrom}
        onDelete={this.refreshValues}
        onEdit={this.refreshValues}
        toggle={this.toggleItem}
      />
    );
  };

  render() {
    const {
      styles: s,
      worksheets,
      projects,
      stages,
      lots,
      can,
      errorModal: { Error },
      actionModal: { Action }
    } = this.props;
    const { allLoadedStages, checked } = this.state;

    const hasChecked =
      Object.entries(checked)
        .map((obj) => obj[1])
        .filter((isChecked) => isChecked.checked === true).length > 0;

    const worksheet = _.get(worksheets, 'item.data');
    const worksheetStats = _.get(worksheet, 'stats');
    const period = _.get(worksheets, 'item.data.period');
    const published = _.get(worksheet, 'status') === 'published';

    const isLoading =
      _.get(projects, 'item.status') === 'loading' ||
      _.get(worksheets, 'list.status') === 'loading';

    if (isLoading) {
      return <LoadingLayout />;
    }

    const isError = _.get(worksheets, 'item.status') === 'error';
    if (isError) {
      const msg = _.get(worksheets, 'item.errors[0]');
      return <ErrorLayout error={msg} />;
    }

    const periodDate = new Date(_.get(period, 'end_date'));
    const periodObj = {
      year: periodDate.getFullYear(),
      month: periodDate.getMonth() + 1
    };

    const hasCheckedLots =
      _.filter(this.state.checkedLots, (value) => value).length > 0;

    const flags = worksheets?.item?.data?.flags?.data;

    const averageSoldPrice = publishedValue(
      published,
      worksheetStats?.average_sold_price,
      true
    );

    const averageAvailablePrice = publishedValue(
      published,
      worksheetStats?.average_available_price,
      true
    );

    const averageAvailableLotSize = publishedValue(
      published,
      worksheetStats?.average_available_lot_size,
      false,
      ' m2'
    );

    const monthGrossSales = publishedValue(
      published,
      worksheetStats?.gross_sales_for_month
    );

    const outstandingDeposit = publishedValue(
      published,
      worksheetStats?.outstanding_deposit
    );

    const monthNetSales = publishedValue(
      published,
      worksheetStats?.net_sales_for_month
    );

    return (
      <Fragment>
        <Portal target="header">
          <Box {...s('headerButtons')} flex={1}>
            {can('create:projects') && (
              <Link to={ROUTES.APP.PROJECTS_CURRENT.NEW_PROJECT}>
                {({ onClick }) => (
                  <AddButton onClick={onClick}>Add Project</AddButton>
                )}
              </Link>
            )}
            <MonthPicker
              from={2000}
              to={2050}
              value={periodObj}
              onChange={(year, month) =>
                this.findWorksheetAndRedirect(year, month)
              }
              button={<ActionButton>Select Period</ActionButton>}
            />
          </Box>
        </Portal>
        <Grid gutter={0} columns={12}>
          <Column width={12}>
            <Box {...s('breadcrumb')}>
              <Breadcrumb
                crumbs={[
                  {
                    label: 'Dashboard',
                    route: '/app/projects/current'
                  },
                  {
                    label: 'Projects',
                    route: '/app/projects/current'
                  },
                  {
                    label: projects.item.data.title,
                    route: `/app/project/${projects.item.data.id}`
                  },
                  {
                    label: 'Worksheet'
                  }
                ]}
              />
            </Box>
          </Column>
        </Grid>

        <Grid gutter={0} columns={12}>
          <SectionColumn width={12}>
            <Box {...s('headerContainer')} flex={1}>
              <Box>
                <Small>{projects.item.data.title}</Small>
                <Heading>
                  Worksheet - {formatPeriod(_.get(period, 'end_date'))}
                </Heading>
              </Box>
              <ClearButton
                onClick={() => this.setState({ editWorksheetModal: true })}
              >
                Edit Worksheet
              </ClearButton>
            </Box>
          </SectionColumn>
        </Grid>

        <Grid gutter={0} columns={12}>
          <SectionColumn width={4}>
            <TitledStatBox
              title={'Gross Sales for Month'}
              value={monthGrossSales}
            />
          </SectionColumn>
          <SectionColumn width={4}>
            <TitledStatBox
              title={'Outstanding Deposits'}
              value={outstandingDeposit}
            />
          </SectionColumn>
          <SectionColumn width={4}>
            <TitledStatBox
              title={'Net Sales for Month'}
              value={monthNetSales}
            />
          </SectionColumn>
        </Grid>

        <Grid gutter={0} columns={12}>
          <SectionColumn width={4}>
            <TitledStatBox
              title={'Average Sold Price'}
              value={averageSoldPrice}
            />
          </SectionColumn>
          <SectionColumn width={4}>
            <TitledStatBox
              title={'Average Available Price'}
              value={averageAvailablePrice}
            />
          </SectionColumn>
          <SectionColumn width={4}>
            <TitledStatBox
              title={'Average Available Lot Size'}
              value={averageAvailableLotSize}
            />
          </SectionColumn>
        </Grid>

        <Grid columns={1}>
          <SectionColumn width={1}>
            <Box {...s('container')}>
              <SubHeading grey style={{ marginBottom: PADDINGS.S }}>
                Worksheet Details
              </SubHeading>

              <Grid columns={12}>
                <Column width={4}>
                  <Box>
                    <Tiny grey>Price List</Tiny>
                    <Body>
                      {worksheets.item.data.no_price_list
                        ? 'No price list received'
                        : 'Price list received'}
                    </Body>
                  </Box>
                </Column>
                <Column width={8}>
                  <Box>
                    <Tiny grey>Supply Issue</Tiny>
                    <Body>
                      {worksheets.item.data.supply_issue
                        ? 'Ignore Data for Average Sales Rates'
                        : 'No supply issue'}
                    </Body>
                  </Box>
                </Column>
              </Grid>

              <Box style={{ marginTop: '2rem' }}>
                <Grid columns={12}>
                  <Column width={12}>
                    <Box>
                      <Tiny grey>Notes</Tiny>
                      <Body>{worksheets.item.data.notes || '-'}</Body>
                    </Box>
                  </Column>
                </Grid>
              </Box>
            </Box>

            {flags?.length > 0 ? (
              <Box {...s('container')}>
                <SubHeading grey style={{ marginBottom: PADDINGS.S }}>
                  Flags
                </SubHeading>

                <ul>
                  {flags.map((flag, idx) => {
                    return <li key={`flag-${idx}`}>- {flag.name}</li>;
                  })}
                </ul>
              </Box>
            ) : null}
          </SectionColumn>
        </Grid>

        <Grid columns={1}>
          <SectionColumn width={1}>
            <Box {...s('headerContainer')} flex={1}>
              <Heading>Stages</Heading>
              {!published && (
                <Box style={{ display: 'inline-flex' }}>
                  <PrimaryButton
                    red
                    {...s('btn')}
                    isLoading={this.state.deleteStages}
                    isDisabled={this.state.deleteStages || !hasChecked}
                    onClick={() => {
                      this.props.actionModal.open(
                        this.deleteStages,
                        'Are you sure you want to delete these stages',
                        'Delete Stage(s)?',
                        true
                      );
                    }}
                  >
                    Delete Selected
                  </PrimaryButton>

                  <ClearButton
                    {...s('btn')}
                    iconRight={<Cross style={{ marginLeft: '0.8rem' }} />}
                    onClick={() => this.setState({ addStageModal: true })}
                  >
                    Add Stage
                  </ClearButton>
                </Box>
              )}
            </Box>
            <List
              items={allLoadedStages.sort((a, b) => b.id - a.id)}
              isLoading={_.get(stages, 'list.status') === 'loading'}
              endReached={_.get(stages, 'list.pagination.endReached')}
              LoadingView={LoadingSpinner}
              Header={() => (
                <ProjectStageHeader
                  checked={
                    _.filter(this.state.checked, (value) => value.checked)
                      .length === stages.list.items.length
                  }
                  onSelectAll={() => {
                    if (
                      _.filter(this.state.checked, (value) => value.checked)
                        .length === stages.list.items.length
                    ) {
                      this.setState({
                        checked: {}
                      });
                    } else {
                      const toggledState = stages.list.items.reduce(
                        (obj, item) => {
                          return {
                            ...obj,
                            [item.id]: {
                              checked: true,
                              stage: item
                            }
                          };
                        },
                        {}
                      );
                      this.setState({
                        checked: toggledState
                      });
                    }
                  }}
                />
              )}
              renderItem={(item) => {
                const id = item.id;
                const { checked } = this.state;
                return (
                  <ProjectStageItem
                    stage={item}
                    published={published}
                    checked={
                      this.state.checked[id]
                        ? this.state.checked[id].checked
                        : false
                    }
                    projectId={getProjectId(this.props)}
                    worksheetId={getWorksheetId(this.props)}
                    onUpdate={() => this.fetchStages()}
                    toggle={() =>
                      this.setState({
                        checked: {
                          ...this.state.checked,
                          ...{
                            [id]: {
                              checked: !_.get(checked[id], 'checked', false),
                              stage: item
                            }
                          }
                        }
                      })
                    }
                  />
                );
              }}
            />

            {!_.get(stages, 'list.pagination.endReached', true) &&
              allLoadedStages.length > 0 && (
                <Box {...s('loadMoreBtnContainer')} flex={1}>
                  <PrimaryButton
                    green
                    onClick={() => this.fetchMoreStages()}
                    isLoading={_.get(stages, 'list.status') === 'loading'}
                  >
                    Load More
                  </PrimaryButton>
                </Box>
              )}
          </SectionColumn>
        </Grid>

        <Grid columns={1}>
          <SectionColumn width={1}>
            <Box {...s('headerContainer')} flex={1}>
              <Heading>Lots</Heading>
              {!published && (
                <Box style={{ display: 'inline-flex' }}>
                  <PrimaryButton
                    {...s('btn')}
                    isDisabled={!hasCheckedLots}
                    onClick={() => this.setState({ checkedLots: {} })}
                  >
                    Clear Selection
                  </PrimaryButton>

                  <PrimaryButton
                    {...s('btn')}
                    green
                    isLoading={this.state.deleteLots}
                    isDisabled={this.state.deleteLots || !hasCheckedLots}
                    onClick={() => {
                      this.setState({ massEditLots: true });
                    }}
                  >
                    Edit Selected
                  </PrimaryButton>

                  <PrimaryButton
                    {...s('btn')}
                    red
                    isLoading={this.state.deleteLots}
                    isDisabled={this.state.deleteLots || !hasCheckedLots}
                    onClick={() => {
                      this.props.actionModal.open(
                        this.deleteLots,
                        'Are you sure you want to delete these lots',
                        'Delete lot(s)?',
                        true
                      );
                    }}
                  >
                    Delete Selected
                  </PrimaryButton>

                  <ClearButton
                    {...s('btn')}
                    iconRight={<Cross style={{ marginLeft: '0.8rem' }} />}
                    onClick={() => this.setState({ addLotModal: true })}
                  >
                    Add Lot
                  </ClearButton>
                </Box>
              )}
            </Box>
            <Box>
              <List
                isLoading={_.get(lots, 'list.status') === 'loading'}
                LoadingView={LoadingSpinner}
                alwaysRenderChildren={false}
                Header={this.renderLotListHeader}
                items={_.get(lots, 'list.items', [])}
                renderItem={(item) => this.renderLotListItem(item)}
              />
              {!_.get(lots, 'list.pagination.endReached', true) && (
                <Box {...s('loadMoreBtnContainer')} flex={1}>
                  <PrimaryButton
                    green
                    onClick={() => lots.fetchMore()}
                    isLoading={_.get(lots, 'list.status') === 'fetching'}
                  >
                    Load More
                  </PrimaryButton>
                </Box>
              )}
            </Box>
          </SectionColumn>
        </Grid>
        {this.state.massEditLots && (
          <Modal>
            <MassEditLotForm
              lots={lots}
              stages={stages}
              projectId={getProjectId(this.props)}
              worksheetId={getWorksheetId(this.props)}
              ids={this.getCheckedLotIds(this.state.checkedLots)}
              closeModal={(clearSelected) => {
                if (clearSelected) {
                  this.setState({
                    massEditLots: false,
                    checkedLots: {}
                  });
                } else {
                  this.setState({
                    massEditLots: false
                  });
                }
                lots.refreshList();
              }}
            />
          </Modal>
        )}
        {this.state.addStageModal && (
          <StageModal
            projectId={getProjectId(this.props)}
            worksheetId={getWorksheetId(this.props)}
            closeModal={() => {
              this.setState({ addStageModal: false }, this.fetchStages);
            }}
          />
        )}
        {this.state.addLotModal && (
          // _.get(worksheets, 'item.data.stages.data', [])
          <LotModal
            projectId={getProjectId(this.props)}
            worksheetId={getWorksheetId(this.props)}
            stages={stages}
            closeModal={() =>
              this.setState({ addLotModal: false, lastLot: null })
            }
            onSubmit={this.refreshValues}
            lot={this.state.lastLot}
          />
        )}
        {this.state.editWorksheetModal && (
          <WorksheetModal
            worksheet={_.get(worksheets, 'item.data')}
            projectId={getProjectId(this.props)}
            onSubmit={() => this.refreshValues()}
            closeModal={() => this.setState({ editWorksheetModal: false })}
          />
        )}
        {this.state.addLotNew && (
          <Modal>
            <LotForm stages={stages} />
          </Modal>
        )}
        <Error />
        <Action />
      </Fragment>
    );
  }
}

export default ViewWorksheetScreen;
