import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {}
  }
};

const actionCreators = {
  /// ///////////
  // SETTINGS //
  /// ///////////
  // Overview
  fetchOverviewSettingsOverview: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/overview/overview`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchOverviewSettingsEstateBreakdown: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/overview/breakdown`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchOverviewSettingsPriceSizeSnapshot: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/overview/priceSizeSnapshot`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  // Pricing
  fetchPricingSettingsStats: {
    request: ({ id, args }) => api.get(`/stats/settings/${id}/pricing`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  // Brackets
  fetchBracketsPriceBracketsSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/brackets/pricebracket`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchBracketSizeStatsSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/brackets/size`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchBracketPriceStatsSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/brackets/price`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchBracketFrontageStatsSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/brackets/frontage`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchBracketDepthStatsSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/brackets/depth`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchAvailabilityStockLevelsSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/availability/stock`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchAvailabilitySoldCharacteristicsSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/availability/soldCharacteristics`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchAvailabilityAvailableCharacteristicsSettings: {
    request: ({ id, args }) =>
      api.get(
        `/stats/settings/${id}/availability/availableCharacteristics`,
        args
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchAvailabilitySupplySettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/availability/availableSupply`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchDetailedSalesOverTimeSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/detailed/salesOverTime`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchDetailedYearToDateSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/detailed/yearToDate`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchDetailedMonthByMonthSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/detailed/monthByMonth`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchDetailedSalesPerformanceSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/detailed/salesPerformance`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  fetchPriceListSettings: {
    request: ({ id, args }) =>
      api.get(`/stats/settings/${id}/pricelist/pricelist`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },

  /// //////////
  // REGULAR //
  /// //////////
  fetchOverviewOverview: {
    request: ({ id, args }) => api.get('/stats/overview/overview', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchOverviewEstateBreakdown: {
    request: ({ id, args }) => api.get('/stats/overview/breakdown', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchOverviewPriceSizeSnapshot: {
    request: ({ id, args }) =>
      api.get('/stats/overview/priceSizeSnapshot', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchBracketsPriceBrackets: {
    request: ({ id, args }) => api.get('/stats/brackets/pricebracket', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchBracketSizeStats: {
    request: ({ id, args }) => api.get('/stats/brackets/size', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchBracketPriceStats: {
    request: ({ id, args }) => api.get('/stats/brackets/price', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchBracketFrontageStats: {
    request: ({ id, args }) => api.get('/stats/brackets/frontage', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchBracketDepthStats: {
    request: ({ id, args }) => api.get('/stats/brackets/depth', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchAvailabilityStockLevels: {
    request: ({ id, args }) => api.get('/stats/availability/stock', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchAvailabilitySoldCharacteristics: {
    request: ({ id, args }) =>
      api.get('/stats/availability/soldCharacteristics', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchAvailabilityAvailableCharacteristics: {
    request: ({ id, args }) =>
      api.get('/stats/availability/availableCharacteristics', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchAvailabilitySupply: {
    request: ({ id, args }) =>
      api.get('/stats/availability/availableSupply', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchDetailedSalesOverTime: {
    request: ({ id, args }) => api.get('/stats/detailed/salesOverTime', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchDetailedYearToDate: {
    request: ({ id, args }) => api.get('/stats/detailed/yearToDate', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchDetailedMonthByMonth: {
    request: ({ id, args }) => api.get('/stats/detailed/monthByMonth', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchDetailedSalesPerformance: {
    request: ({ id, args }) =>
      api.get('/stats/detailed/salesPerformance', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchPriceList: {
    request: ({ id, args }) => api.get('/stats/pricelist', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  export: {
    request: ({ args }) => api.post('/stats/export', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  exportSoldLots: {
    request: () => api.get('/export/all-sold-lots', {}),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  checkSettings: {
    request: ({ id, ...args }) => api.get(`/stats/settings/${id}/check`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ProjectsGenerator = new Generator('stats', config);
export default ProjectsGenerator.createEntityModel({ actionCreators });
