import { SankeyData } from 'types/graph';

const lotJourneyTheme = {
  'Available Last Month': '#7EA6FF',
  'Back on Market': '#7E84FF',
  'Newly Released': '#B2A6FF',
  'On Market': '#5AC9A5',
  'Gross Sales': '#F74A60',
  'Outstanding Deposit': '#FCA084'
};

export const infuseColorToData = ({ nodes, links }: SankeyData): SankeyData => {
  if (nodes.length === 0) {
    return { nodes: [], links: [] };
  }

  const coloredNodes = nodes.map((node) => {
    return { ...node, nodeColor: lotJourneyTheme[node.id] };
  });

  return { links, nodes: coloredNodes };
};
