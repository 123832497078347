import React, { Component, Fragment } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import periodsModel from 'data/models/entities/periods';
import projectsModel from 'data/models/entities/projects';
import statsModel from 'data/models/entities/stats';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import ReportTitle from 'view/components/report-title';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import { toQuri } from '../../../utils/query';
import Detailed from '../../components/reports/detailed';

function getPrimaryProjectId(props) {
  return _.get(props, 'match.query.primaryProject');
}

function getPeriod(props) {
  return _.get(props, 'match.query.period');
}

function getProjectIds(props) {
  return _.get(props, 'match.query.projects');
}

function getPeriodQuery(props) {
  return toQuri([
    {
      field: 'end_date',
      op: '==',
      value: getPeriod(props)
    }
  ]);
}

const periodsQuery = query`{
  ${periodsModel} (q: ${getPeriodQuery}) {
    id
    end_date
  }
}`;

const projectsQuery = query`{
  ${projectsModel} (ids: ${getProjectIds}) {
    id
    title
  }
}`;

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  },
  reportRow: {
    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`,
    padding: `${PADDINGS.XS} 0`
  },
  pie: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center'
  }
});

@withReportSidebar(false)
@withQuery(projectsQuery)
@withQuery(periodsQuery)
@withModel(statsModel)
@styled(defaultStyles)
class DetailedReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingSalesOverTime: true,
      fetchingMonthByMonth: true,
      fetchingSalesPerformance: true,
      fetchingYearToDate: true,

      salesOverTime: {},
      monthByMonth: {},
      salesPerformance: {},
      yearToDate: {},

      currentSalesComparison: 0,

      currentMonthByMonth: 'gross_sales_month',

      currentPie: 'This Month',

      currentFinancialProject: getPrimaryProjectId(this.props),
      currentCalendarProject: getPrimaryProjectId(this.props)
    };
  }

  componentDidMount() {
    const { stats, whereabouts } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (!_.get(query, 'period') || !_.get(query, 'projects')) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    this.setState({
      currentSalesComparison: parseInt(
        _.head(
          getProjectIds(this.props).filter(
            (p) => p !== getPrimaryProjectId(this.props)
          )
        )
      )
    });

    const args = {
      args: {
        projects: getProjectIds(this.props),
        primary: getPrimaryProjectId(this.props),
        period: getPeriod(this.props)
      }
    };

    stats
      .fetchDetailedSalesOverTime(args)
      .then((res) => {
        this.setState({
          fetchingSalesOverTime: false,
          salesOverTime: res.data
        });
      })
      .catch(console.error);

    stats
      .fetchDetailedMonthByMonth(args)
      .then((res) => {
        this.setState({
          fetchingMonthByMonth: false,
          monthByMonth: res.data
        });
      })
      .catch(console.error);

    stats
      .fetchDetailedSalesPerformance(args)
      .then((res) => {
        this.setState({
          fetchingSalesPerformance: false,
          salesPerformance: res.data
        });
      })
      .catch(console.error);

    stats
      .fetchDetailedYearToDate(args)
      .then((res) => {
        this.setState({
          fetchingYearToDate: false,
          yearToDate: res.data
        });
      })
      .catch(console.error);
  }

  loading() {
    const { projects, periods } = this.props;

    const {
      fetchingSalesOverTime,
      fetchingMonthByMonth,
      fetchingSalesPerformance,
      fetchingYearToDate
    } = this.state;

    const arr = [
      fetchingSalesOverTime,
      fetchingMonthByMonth,
      fetchingSalesPerformance,
      fetchingYearToDate,
      _.get(projects, 'list.status') !== 'loaded',
      _.get(periods, 'list.status') !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const { projects, periods } = this.props;
    const { salesOverTime, monthByMonth, salesPerformance, yearToDate } =
      this.state;

    const loading = this.loading();
    const maxRequests = 6;
    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const projectList = _.sortBy(_.get(projects, 'list.items'), (item) =>
      item.id === parseInt(getPrimaryProjectId(this.props)) ? 0 : 1
    );

    const primaryProject = _(projectList)
      .filter(
        (project) => project.id === parseInt(getPrimaryProjectId(this.props))
      )
      .head();

    const period = _.get(periods, 'item.data');

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle period={period} project={primaryProject} />
        </Portal>

        <Detailed
          projects={projectList}
          primaryProject={primaryProject}
          salesOverTime={salesOverTime}
          monthByMonth={monthByMonth}
          salesPerformance={salesPerformance}
          yearToDate={yearToDate}
        />

        <ReportFooter
          prevText="Lot Availability"
          prevRoute={ROUTES.APP.REPORT_LOT}
          nextText="Pricelists"
          nextRoute={ROUTES.APP.REPORT_PRICELISTS}
        />
      </Fragment>
    );
  }
}

export default DetailedReport;
