import React, { useMemo } from 'react';
import Button from '@rexlabs/button';
import { COLORS, FONT } from 'src/features/reports/theme';

const getStyles = ({
  checked,
  collapsed,
  isFirst,
  isLast
}: {
  checked: boolean;
  collapsed: boolean;
  isFirst: boolean;
  isLast: boolean;
}) => {
  let extraContainerStyle = {};
  if (!collapsed) {
    extraContainerStyle = {
      borderRightWidth: isLast ? '1px' : 0,
      borderRadius: 0,
      flex: 1,
      borderTopLeftRadius: isFirst ? '4px' : 0,
      borderBottomLeftRadius: isFirst ? '4px' : 0,
      borderTopRightRadius: isLast ? '4px' : 0,
      borderBottomRightRadius: isLast ? '4px' : 0
    };
  }

  return {
    container: {
      transition: 'all 0.2s ease-in-out',
      backgroundColor: checked ? COLORS.BLUE.PRIMARY : COLORS.WHITE,
      borderColor: COLORS.BLUE.PRIMARY,
      flex: 1,
      ...extraContainerStyle
    },
    content: {
      transition: 'all 0.2s ease-in-out',
      fontFamily: FONT.FAMILIES.DEFAULT,
      color: checked ? COLORS.WHITE : COLORS.BLUE.DARK
    }
  };
};

export interface ToggleButtonSingleProps {
  checked: boolean;
  label: string;
  /**
   * Use for storing the checked state of the button, it is useful for group of toggles
   * E.g const [filters, setFilters] = useState(['key_1', 'key_2'])
   * When key_1 is in the state, meaning it is selected/checked
   */
  key: string;
  /**
   * This is used to determine the style of the button
   * collapsed - meaning the buttons are separated WITH spaces in between
   * if not collapsed - buttons are sticked to each other
   */
  collapsed?: boolean;
  /**
   * Triggered when button is clicked
   */
  onClick: () => void;
  /**
   * Used to determine styling of border radius
   */
  isFirst: boolean;
  /**
   * Used to determine styling of border radius and border width
   */
  isLast: boolean;
}

function ToggleButtonSingle({
  checked,
  label,
  onClick,
  collapsed,
  isFirst,
  isLast
}: ToggleButtonSingleProps) {
  const styles = useMemo(
    () => getStyles({ checked, collapsed, isFirst, isLast }),
    [checked, collapsed, isFirst, isLast]
  );
  return (
    <Button styles={styles} onClick={onClick}>
      {label}
    </Button>
  );
}

export default ToggleButtonSingle;
