import React, { useCallback, useMemo } from 'react';
import { CustomLayerProps } from '@nivo/line';
import { COLORS } from 'src/features/reports/theme';

const UnderSupplied = ({
  lineGenerator,
  points: steppingPoints,
  yScale
}: CustomLayerProps) => {
  // @ts-expect-error wrong type from nivo
  const getY = useCallback((y: number) => yScale(y), [yScale]);

  // Group points that are below 3
  const groupedPoints = useMemo(() => {
    if (!steppingPoints.length) return [];
    const result = [];
    let group = [];
    steppingPoints.forEach((point, index) => {
      // Greater than, because y axis count starts from top to bottom
      // E.g y axis coordinate of 3 is greater than 10 because 10 is closer to the top
      if (point.y >= getY(3) && point.y !== null) {
        group.push(point);
        if (index !== steppingPoints.length - 1) return;
      }

      // Push group if there is no more next point to add
      // Then empty it for a new group
      if (group.length > 0) {
        // Add closing points
        result.push([
          { x: group[0].x, y: getY(3) },
          ...group,
          { x: group[group.length - 1].x, y: getY(3) }
        ]);
        group = [];
      }
    }, []);

    return result;
  }, [steppingPoints, getY]);

  return (
    <>
      {groupedPoints.map((group, index) => (
        <path
          pointerEvents="none"
          key={index}
          d={lineGenerator(group)}
          fill={`${COLORS.RED.PRIMARY}40`}
        />
      ))}
      {steppingPoints?.length > 0 && (
        <>
          <rect
            x="6"
            y={getY(3) + 7}
            width="100"
            height="17"
            fill={COLORS.WHITE}
            rx={4}
            style={{
              filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1))'
            }}
          />
          <text
            pointerEvents="none"
            fill={COLORS.BLUE.DARK}
            fontSize="12px"
            fontWeight={500}
            fontFamily="DM Sans"
            transform={`translate(10, ${getY(3) + 20})`}
          >
            UNDERSUPPLIED
          </text>
        </>
      )}
    </>
  );
};

export default UnderSupplied;
