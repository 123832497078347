import { isEmpty, unionBy } from 'lodash';
import { useCallback, useContext } from 'react';
import { Auth0Context } from 'src/auth0-provider';
import { AdvanceFilters } from 'types/params';
import { Project, SubRegionWithProjects } from 'types/resource';
import { SelectedProjectsInSubRegion } from '../components/report-settings/sub-region-project-selector';
import { ReportSettingsContext } from '../providers/report-settings-provider';

export const useAdvanceFilters = () => {
  const { hasPermission } = useContext(Auth0Context);
  const {
    setSubRegionWithAllProjectsSelected,
    premiumSubRegions,
    standardSubRegions,
    setAdvanceFilters
  } = useContext(ReportSettingsContext);

  const filterProjects = useCallback(
    (projects: Project[], advanceFilters: AdvanceFilters): Project[] => {
      const { regions } = advanceFilters;
      let result = projects;
      Object.keys(advanceFilters).forEach((key) => {
        if (key === 'regions') {
          if (regions && regions.length > 0) {
            result = result.filter((project) =>
              regions.includes(project.region)
            );
          }
        } else if (advanceFilters[key]) {
          result = result.filter(
            (project) => advanceFilters[key] === project[key]
          );
        }
      });

      return result;
    },
    []
  );

  const convertWithSubRegionKey = useCallback(
    (subRegions: SubRegionWithProjects[]) => {
      const result: SelectedProjectsInSubRegion = {};
      subRegions
        .filter((subRegion) => subRegion.projects.length)
        .forEach((subRegion) => {
          Object.assign(result, {
            [subRegion.id]: subRegion.projects.map((project) => project.id)
          });
        });

      return result;
    },
    []
  );

  // Update selected projects id's when subregion selected is in all projects
  const updateSubRegions = useCallback(
    (
      subRegions: SubRegionWithProjects[],
      selectedProjectsInSubRegion: SelectedProjectsInSubRegion,
      subRegionWithAllProjectsSelected: number[]
    ) => {
      if (subRegionWithAllProjectsSelected?.length > 0) {
        setSubRegionWithAllProjectsSelected(subRegionWithAllProjectsSelected);
        subRegionWithAllProjectsSelected.forEach((id) => {
          const matchedSubRegion = subRegions.find(
            (subRegion) => subRegion.id === +id
          );
          if (!matchedSubRegion) return;
          Object.assign(selectedProjectsInSubRegion, {
            [id]: matchedSubRegion.projects.map((project) => project.id)
          });
        });
      }
    },
    [setSubRegionWithAllProjectsSelected]
  );

  const getFilteredProjectsAndSelectedProjects = useCallback(
    (
      advanceFilters: AdvanceFilters,
      selectedProjectsInSubRegion: SelectedProjectsInSubRegion,
      subRegionWithAllProjectsSelected: number[]
    ) => {
      let selectedSubRegions = {};
      const allSubRegions = [...premiumSubRegions, ...standardSubRegions];

      if (allSubRegions.length === 0) return {};

      if (
        hasPermission(['reports:advanced-filtering']) &&
        !isEmpty(advanceFilters)
      ) {
        selectedSubRegions = convertWithSubRegionKey(
          allSubRegions.map((subRegion) => ({
            ...subRegion,
            projects: filterProjects(subRegion.projects, advanceFilters)
          }))
        );

        // Add selected project id's from params
        // This is needed as user may still manually select a sub region or project
        Object.keys(selectedSubRegions).forEach((key) => {
          if (selectedProjectsInSubRegion[key]?.length) {
            Object.assign(selectedSubRegions, {
              [key]: unionBy(
                selectedSubRegions[key],
                selectedProjectsInSubRegion[key],
                Number
              )
            });
          }
        });
      }

      // Append sub regions that maybe in the query and is not in generated sub regions(advance filters)
      const allSelectedProjects = {
        ...selectedProjectsInSubRegion,
        ...selectedSubRegions
      };

      updateSubRegions(
        allSubRegions,
        allSelectedProjects,
        subRegionWithAllProjectsSelected
      );

      setAdvanceFilters(advanceFilters);

      return allSelectedProjects;
    },
    [
      filterProjects,
      convertWithSubRegionKey,
      updateSubRegions,
      hasPermission,
      premiumSubRegions,
      standardSubRegions,
      setAdvanceFilters
    ]
  );

  return { getFilteredProjectsAndSelectedProjects, updateSubRegions };
};
