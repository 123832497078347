import _ from 'lodash';

// Check if an element is in an array
export const inArray = (array, value) => array.indexOf(value) !== -1;

// Sort the unique values and take the most right elements
// to get get the highest values
export const top = (array, n, accessor, zeros = true) => {
  const values = accessor ? _.map(array, (val) => _.get(val, accessor)) : array;
  return _.flow([
    _.uniq,
    _.sortBy,
    zeros ? _.identity : _.partialRight(_.filter, (val) => val !== 0),
    _.partialRight(_.takeRight, n)
  ])(values);
};
