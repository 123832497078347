import React, { PureComponent } from 'react';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { COLORS, PADDINGS } from 'src/theme';
import './style.css';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class Table extends PureComponent {
  static defaultProps = {
    getTheadThProps: () => ({
      style: {
        background: COLORS.BLUE.LIGHT,
        border: '0px !important',
        textAlign: 'left',
        color: COLORS.GREY.MEDIUM_DARK,
        padding: `${PADDINGS.XXS} ${PADDINGS.XXS} ${PADDINGS.S} ${PADDINGS.XXS} `,
        fontWeight: 'bold'
      }
    }),
    getTrGroupProps: () => ({
      style: {
        borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
      }
    }),
    getTheadProps: () => ({
      style: {
        boxShadow: 'none',
        background: COLORS.BLUE.LIGHT,
        border: 'none !important'
      }
    }),
    getTdProps: () => ({
      style: {
        padding: PADDINGS.XXS
      }
    }),
    showPagination: false
  };

  render() {
    return <ReactTableFixedColumns {...this.props} />;
  }
}

export default Table;
