import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import projectsModel from 'data/models/entities/projects';
import { withModel } from '@rexlabs/model-generator';
import { formatDate } from '../../../utils/format';
import _ from 'lodash';
import { FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500'
  },
  status: {
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  },
  truncate: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

@withModel(projectsModel)
@styled(defaultStyles)
class MissingWorksheetListItem extends PureComponent {
  render() {
    const { styles: s, project, checked } = this.props;
    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={24}
          style={{ alignItems: 'center' }}
          {...s('grid')}
          onClick={this.props.toggle}
        >
          <Column width={2} {...s('checkbox')}>
            <Checkbox value={checked || false} />
          </Column>
          <Column width={6}>
            <Small {...s('text', 'truncate')}>{_.get(project, 'title')}</Small>
          </Column>
          <Column width={6}>
            <Small {...s('text')}>
              {formatDate(_.get(project, 'created_at'))}
            </Small>
          </Column>
          <Column width={10}>
            <Small {...s('status')}>3</Small>
          </Column>
        </Grid>
      </Box>
    );
  }
}

export default MissingWorksheetListItem;
