import React, { Component, Fragment } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import projectsModel from 'data/models/entities/setting-projects';
import statsModel from 'data/models/entities/stats';
import { withQuery, withModel } from '@rexlabs/model-generator';
import LoadingLayout from 'view/layouts/loading';
import ROUTES from 'src/routes';
import ReportFooter from 'view/components/report-footer';
import Report from 'src/view/components/reports/size-price-brackets';
import withReportSidebar from 'view/containers/with-report-sidebar';
import withValidate from 'view/containers/with-validate-setting';
import { getSettingsId, getToken } from 'utils/reports';
import { getSettingsQuery, periodsQuery } from 'data/queries/settings';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  }
});

@withReportSidebar()
@withModel(projectsModel)
@withQuery(periodsQuery)
@withQuery(getSettingsQuery(getSettingsId, getToken))
@withModel(statsModel)
@styled(defaultStyles)
@withValidate
class SizePriceBrackets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Loading vars
      fetchingPriceSizeStats: true,
      fetchingMarketShareSize: true,
      fetchingMarketSharePrice: true,
      fetchingMarketShareDepth: true,
      fetchingMarketShareFrontage: true,

      // Stat vars
      priceSize: {},
      marketShareSize: {},
      marketSharePrice: {},
      marketShareDepth: {},
      marketShareFrontage: {},

      primary: {},
      fetchingPrimary: true
    };

    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    const { stats, whereabouts, settingProjects } = this.props;

    stats
      .fetchBracketsPriceBracketsSettings({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          priceSize: res.data,
          fetchingPriceSizeStats: false
        });
      });

    stats
      .fetchBracketSizeStatsSettings({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          marketShareSize: res.data,
          fetchingMarketShareSize: false
        });
      });

    stats
      .fetchBracketPriceStatsSettings({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          marketSharePrice: res.data,
          fetchingMarketSharePrice: false
        });
      });

    stats
      .fetchBracketFrontageStatsSettings({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          marketShareFrontage: res.data,
          fetchingMarketShareFrontage: false
        });
      });

    stats
      .fetchBracketDepthStatsSettings({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          marketShareDepth: res.data,
          fetchingMarketShareDepth: false
        });
      });

    settingProjects
      .fetchPrimary({
        settingId: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token')
        }
      })
      .then((res) => {
        this.setState({
          fetchingPrimary: false,
          primary: res.data
        });
      })
      .catch(this.handleError);
  }

  loading() {
    const {
      fetchingPriceSizeStats,
      fetchingMarketShareSize,
      fetchingMarketSharePrice,
      fetchingMarketShareDepth,
      fetchingMarketShareFrontage,
      fetchingPrimary
    } = this.state;

    const arr = [
      fetchingPriceSizeStats,
      fetchingMarketShareSize,
      fetchingMarketSharePrice,
      fetchingMarketShareDepth,
      fetchingMarketShareFrontage,
      fetchingPrimary
      // settingProjects.list.status === 'loading'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const { periods, settings } = this.props;
    const {
      priceSize,
      marketShareSize,
      marketSharePrice,
      marketShareDepth,
      marketShareFrontage,
      primary
    } = this.state;

    const loading = this.loading();
    const maxRequests = 6;

    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const period = _.get(periods, 'list.items.0');

    return (
      <Fragment>
        <Report
          settings={_.get(settings, 'item.data')}
          period={period}
          primary={primary}
          priceSize={priceSize}
          marketShareSize={marketShareSize}
          marketSharePrice={marketSharePrice}
          marketShareDepth={marketShareDepth}
          marketShareFrontage={marketShareFrontage}
        />

        <ReportFooter
          prevText="Pricing Comparison"
          prevRoute={ROUTES.APP.REPORT_SETTINGS_PRICING}
          nextText="Lot Availability"
          nextRoute={ROUTES.APP.REPORT_SETTINGS_AVAILABILITY}
          settingId={getSettingsId(this.props)}
        />
      </Fragment>
    );
  }
}

export default SizePriceBrackets;
