import React, { useState } from 'react';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import { Heading, SubHeading, Body } from 'view/components/text';
import Divider from 'view/components/divider';
import ReportBox from 'view/components/summary/report-box';
import SectionContainer from 'view/components/section-container';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from 'view/components/arrow';
import PopularStat from './popular-stat';
import GA from 'react-ga';

export interface PopularCharacteristicsProps {
  stats: any;
  title: string;
}

const PopularCharacteristics = ({
  stats,
  title
}: PopularCharacteristicsProps) => {
  const [currentCharacteristic, setCurrentCharacteristicValue] =
    useState('current');
  const characteristicStat = stats?.[currentCharacteristic];
  const setCurrentCharacteristic = (value: string) => {
    GA.event({
      category: 'Lot Availability',
      action: `Toggle ${title} Period`,
      label: value.toLowerCase()
    });
    setCurrentCharacteristicValue(value);
  };

  const getTimeLabel = (string: string) => {
    if (string === 'yearly') {
      return '12 Months';
    }

    if (string === 'quarterly') {
      return '3 Months';
    }

    return 'This Month';
  };

  return (
    <SectionContainer m={PADDINGS.XXS}>
      <Box
        p={PADDINGS.S}
        flex={1}
        justifyContent={'space-between'}
        alignItems={'baseline'}
      >
        <SubHeading>{title}</SubHeading>
        <Box
          style={{
            display: 'inline-flex'
          }}
        >
          <ActionMenu
            Button={(button) => (
              <Body style={{ cursor: 'pointer', color: COLORS.GREY.DARK }}>
                {getTimeLabel(currentCharacteristic)}&nbsp;
                {button.isOpen ? <UpArrow /> : <DownArrow />}
              </Body>
            )}
            items={[
              {
                label: 'This Month',
                onClick: () => setCurrentCharacteristic('current')
              },
              {
                label: '3 Months',
                onClick: () => setCurrentCharacteristic('quarterly')
              },
              {
                label: '12 Months',
                onClick: () => setCurrentCharacteristic('yearly')
              }
            ]}
          />
        </Box>
      </Box>

      {/* Stats row 1 */}
      <Box p={PADDINGS.S} flex={1}>
        <Box style={{ flexGrow: 1 }}>
          <ReportBox title="Frontage">
            <Heading>{characteristicStat?.frontage ?? 0}m</Heading>
          </ReportBox>
        </Box>
        <Box style={{ flexGrow: 1 }}>
          <ReportBox title="Depth">
            <Heading>{characteristicStat?.depth ?? 0}m</Heading>
          </ReportBox>
        </Box>
        <Box style={{ flexGrow: 2 }}>
          <ReportBox title="Lot Type">
            <PopularStat
              value={characteristicStat?.lot_type ?? '0 x 0'}
              maxLength={3}
            />
          </ReportBox>
        </Box>
      </Box>

      <Divider />

      {/* Stats row 2 */}
      <Box p={PADDINGS.S} flex={1}>
        <Box style={{ flexGrow: 1 }}>
          <ReportBox title="Price Point">
            <PopularStat value={characteristicStat?.price} maxLength={3} />
          </ReportBox>
        </Box>
        <Box style={{ flexGrow: 1 }}>
          <ReportBox title="Size">
            <PopularStat value={characteristicStat?.size} maxLength={3} />
          </ReportBox>
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default PopularCharacteristics;
