import React from 'react';
import fp from 'lodash/fp';
import Box from '@rexlabs/box';
import SectionContainer from 'view/components/section-container';
import { COLORS, PADDINGS } from 'src/theme';
import { SubHeading } from 'view/components/text';
import { Project } from 'data/types/projects';
import SimpleHeatBox from 'view/components/heat-map/simple-heat-box';
import Table from 'view/components/table';

export interface AllStockHeatMapProps {
  stats: any;
  projects: Project[];
  primary: Project;
}

const sortMethod = (a, b) =>
  parseInt(a?.max_price ?? 0) < parseInt(b?.map_price ?? 0) ? 1 : -1;

const AllStockHeatMap = ({
  stats,
  projects,
  primary
}: AllStockHeatMapProps) => {
  const projectIds = projects?.map((p: Project) => p.id);
  const columns = fp.compose(
    fp.concat([
      {
        Header: () => <p style={{ color: COLORS.BLUE_GREEN }}>Size Range</p>,
        width: 150,
        accessor: 'size_bracket',
        fixed: 'left',
        style: {
          background: COLORS.BLUE.LIGHT,
          boxShadow: '4px 0px 3px -3px #ccc',
          border: 'none !important'
        }
      }
    ]),
    fp.map((p: Project) => ({
      Header: () => (
        <p
          style={{
            color: p.id === primary.id ? COLORS.BLUE_GREEN : 'inherit'
          }}
        >
          {fp.find((item: Project) => item.id === p.id)(projects)?.title}
        </p>
      ),
      width: 225,
      accessor: `projects.${p.id}`,
      sortMethod: sortMethod,
      id: p.id,
      Cell: (item) => {
        const hasBackground = fp.values(item?.original?.projects)?.length > 1;

        if (item.original == null || item.original.projects[p.id] == null) {
          return null;
        }

        return (
          <SimpleHeatBox
            min={item.original.projects[p.id].min_price}
            max={item.original.projects[p.id].max_price}
            globalMin={item.original.min_price}
            globalMax={item.original.max_price}
            total={item.original.projects[p.id]['total']}
            hasBackground={hasBackground}
          />
        );
      }
    })),
    fp.sortBy((p: Project) => p.id !== primary.id)
  )(projects);

  return (
    <SectionContainer m={PADDINGS.XXS}>
      <Box p={PADDINGS.S}>
        <SubHeading>All Stock - Size Bracket Heat Map</SubHeading>
      </Box>
      <Table
        getTdProps={(state, rowInfo, column) => {
          if (fp.includes(column.id)(projectIds)) {
            return {
              style: {
                padding: 0,
                margin: 0
              }
            };
          }
          return {};
        }}
        data={fp.values(stats)}
        columns={columns}
        defaultPageSize={stats?.length}
      />
    </SectionContainer>
  );
};

export default AllStockHeatMap;
