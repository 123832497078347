export const TIME_INTERVAL = [
  {
    label: 'Month',
    key: 'monthly'
  },
  {
    label: 'Quarter',
    key: 'quarterly'
  }
];

export type TimeIntervalType = typeof TIME_INTERVAL[number]['key'];

export const ALL_PROJECTS = {
  key: 'all_projects',
  label: 'All projects'
};

export const NO_PROJECTS = {
  key: 'no_projects',
  label: 'No projects'
};
