import React, { PureComponent } from 'react';
import ErrorModal from '../components/modal/error';
import PropTypes from 'prop-types';

class AlertModal extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <ErrorModal error closeText="Ok" {...this.props}>
        {children}
      </ErrorModal>
    );
  }
}

AlertModal.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default AlertModal;
