import React, { FC, useCallback, useContext, useEffect } from 'react';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { withModel } from '@rexlabs/model-generator';
import reportModel from 'src/features/reports/models/reports';
import { compose } from 'utils/compose';
import { Whereabouts } from 'types/common';
import { ReportModel } from 'types/models/report';
import withError from 'view/containers/with-error';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { ReportSettingsContext } from '../providers/report-settings-provider';
import { parseQueryString } from '@rexlabs/whereabouts';
import { SelectedProjectsInSubRegion } from '../components/report-settings/sub-region-project-selector';
import { ReportParams } from 'types/params';
import { ReportSettings } from 'types/resource';
import { useAdvanceFilters } from '../hooks/use-advance-filters';

interface LoadReportProps {
  whereabouts: Whereabouts<ReportParams>;
  reportModel: ReportModel;
  errorModal: WithErrorModalProps;
}

const LoadReport: FC<LoadReportProps> = ({
  whereabouts,
  reportModel,
  errorModal: { open: openError },
  children
}) => {
  const {
    standardSubRegions,
    premiumSubRegions,
    setSelectedProjectsInSubRegion,
    setSelectedReport
  } = useContext(ReportSettingsContext);
  const { loadReport } = reportModel;
  const { getFilteredProjectsAndSelectedProjects, updateSubRegions } =
    useAdvanceFilters();

  useEffect(() => {
    const allSubRegions = [...standardSubRegions, ...premiumSubRegions];
    if (allSubRegions.length === 0) return;

    const {
      project_ids,
      saved,
      regions,
      state,
      suburb,
      developer,
      local_government_area,
      sub_region_with_all_projects_selected:
        subRegionWithAllProjectsSelectedQuery
    } = whereabouts.query;

    if (saved) {
      loadReport({ queryParams: { id: +saved } })
        .then((res) => {
          setSelectedReport(res.data);

          const {
            advanceFilters,
            selectedProjectsInSubRegion,
            subRegionWithAllProjectsSelected
          } = JSON.parse(res.data.settings) as ReportSettings;

          const selectedProjects = getFilteredProjectsAndSelectedProjects(
            advanceFilters,
            selectedProjectsInSubRegion,
            subRegionWithAllProjectsSelected
          );

          setSelectedProjectsInSubRegion(selectedProjects);
        })
        .catch((err) => {
          openError(err);
        });
    } else if (
      regions ||
      state ||
      suburb ||
      developer ||
      local_government_area
    ) {
      const advanceFilters = {
        regions,
        state,
        suburb,
        developer,
        local_government_area
      };

      const selectedProjectsInSubRegionQuery = parseQueryString(
        project_ids
      ) as SelectedProjectsInSubRegion;

      const selectedProjects = getFilteredProjectsAndSelectedProjects(
        advanceFilters,
        selectedProjectsInSubRegionQuery,
        subRegionWithAllProjectsSelectedQuery
      );

      setSelectedProjectsInSubRegion(selectedProjects);
    } else if (project_ids) {
      const selectedProjectsInSubRegionQuery = parseQueryString(
        project_ids
      ) as SelectedProjectsInSubRegion;

      updateSubRegions(
        allSubRegions,
        selectedProjectsInSubRegionQuery,
        subRegionWithAllProjectsSelectedQuery
      );

      setSelectedProjectsInSubRegion(selectedProjectsInSubRegionQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standardSubRegions, premiumSubRegions]);

  return <>{children}</>;
};

export default compose(
  withWhereabouts,
  withModel(reportModel),
  withError.withPropName('errorModal')
)(LoadReport);
