import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { PieChart, Pie, Cell } from 'recharts';
import PropTypes from 'prop-types';

const COLORS = ['#ACEEDF', '#00A093'];

class CompetitionPieChart extends PureComponent {
  static defaultProps = {
    isAnimationActive: false,
    labelLine: false,
    outerRadius: 100
  };

  render() {
    const { data, width, height, ...rest } = this.props;

    return (
      <Box>
        <PieChart width={width} height={height}>
          <Pie data={data} {...rest}>
            {data.map((entry, index) => (
              <Cell key={`pie-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </Box>
    );
  }
}

Pie.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  outerRadius: PropTypes.number.isRequired
};

export default CompetitionPieChart;
