import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS, FONT, TEXTS } from '../../../theme';
import Box from '@rexlabs/box';
import { Body, BigHeading } from '../text';

const defaultStyles = StyleSheet({
  outerBoxStyle: {
    border: '1px solid',
    borderColor: COLORS.GREY.LIGHT,
    padding: PADDINGS.XS,
    paddingLeft: PADDINGS.L,
    paddingRight: PADDINGS.L,
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%'
  },
  BodyTextStyle: {
    color: COLORS.GREY.DARK,
    fontWeight: FONT.WEIGHTS.SEMIBOLD
  },
  BigHeadingStyle: {
    lineHeight: TEXTS.BIGHEADING.lineHeight,
    marginLeft: '1.2rem'
  }
});

@styled(defaultStyles)
class StatBox extends PureComponent {
  render() {
    const { styles: s, text, number } = this.props;
    return (
      <Box flex={1} flexDirection="row" {...s('outerBoxStyle')}>
        <Box>
          <Body {...s('BodyTextStyle')}>{text}</Body>
        </Box>
        <Box {...s('BigHeadingStyle')}>
          <BigHeading>{number}</BigHeading>
        </Box>
      </Box>
    );
  }
}

export default StatBox;
