import React, { PureComponent } from 'react';
import { TextInput } from '@rexlabs/text-input';
import DollarSvg from 'assets/dollar.svg';

class DollarInput extends PureComponent {
  render() {
    return <TextInput prefix={<DollarSvg />} {...this.props} />;
  }
}

export default DollarInput;
