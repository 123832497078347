import React, { Dispatch, SetStateAction } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { FONT } from 'src/features/reports/theme';
import Modal from '../modals/modal';
import CloseIcon from './close-icon';
import SavedReports from './saved-reports';
import SubRegions from './sub-regions';

const styles = StyleSheet({
  title: {
    fontSize: '28px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 700
  },
  close: {
    background: 'none',
    outline: 'none',
    border: 'none',
    display: 'flex',
    padding: 0,
    cursor: 'pointer'
  },
  description: {
    fontSize: 12,
    fontFamily: FONT.FAMILIES.DEFAULT,
    marginBottom: 10
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '284px 392px',
    padding: '18px',
    gap: '18px'
  }
});

interface LoadReportModalProps {
  styles: Styles;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  closable: boolean;
}

const LoadReportModal = ({
  styles: s,
  setIsVisible,
  closable
}: LoadReportModalProps) => {
  return (
    <Modal width="778px">
      <Box p="24px">
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb="10px"
        >
          <h3 {...s('title')}>Load Report</h3>
          {closable && (
            <button {...s('close')} onClick={() => setIsVisible(false)}>
              <CloseIcon />
            </button>
          )}
        </Box>
        <p {...s('description')}>
          Select from a previously saved report or search for a subregion
          below...
        </p>
        <div {...s('grid')}>
          <SavedReports setIsVisible={setIsVisible} />
          <SubRegions setIsVisible={setIsVisible} />
        </div>
      </Box>
    </Modal>
  );
};

export default styled(styles)(LoadReportModal);
