import React, { Component, Fragment } from 'react';
import { COLORS, PADDINGS } from 'src/theme';
import _ from 'lodash';
import { query, withModel, withQuery } from '@rexlabs/model-generator';
import periodsModel from 'data/models/entities/periods';
import projectsModel from 'data/models/entities/projects';
import statsModel from 'data/models/entities/stats';
import lotsModel from 'data/models/entities/lots';
import LoadingLayout from 'view/layouts/loading';
import withError from 'view/containers/with-error';
import { styled, StyleSheet } from '@rexlabs/styling';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import Overview from 'view/components/reports/overview';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import { toQuri } from 'src/utils/query';

function getPrimaryProjectId(props) {
  return _.get(props, 'match.query.primaryProject');
}

function getPeriod(props) {
  return _.get(props, 'match.query.period');
}

function getProjectIds(props) {
  return _.get(props, 'match.query.projects');
}

function getPeriodQuery(props) {
  return toQuri([
    {
      field: 'end_date',
      op: '==',
      value: getPeriod(props)
    }
  ]);
}

const periodsQuery = query`{
  ${periodsModel} (q: ${getPeriodQuery}) {
    id
    end_date
  }
}`;

const projectsQuery = query`{
  ${projectsModel} (ids: ${getProjectIds}) {
    id
    title
    active
    developer
    sales_office_address
    sales_agency
    local_government_area
    total_area
    net_developable_area
    suburb
    postcode
    estimated_start_date
    estimated_completion_date
    total_area
    status
    created_at
    stats
    settings
    sales_volume_offset
    estimated_lots
    precinct_structure_plan  }
}`;

const defaultStyles = StyleSheet({
  row: {
    padding: `${PADDINGS.XS} 0`
  },
  divider: {
    paddingTop: PADDINGS.S,
    marginBottom: PADDINGS.S,
    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`
  }
});

@withError.withPropName('errorModal')
@withReportSidebar(false)
@withQuery(periodsQuery)
@withQuery(projectsQuery)
@withModel(lotsModel)
@withModel(statsModel)
@styled(defaultStyles)
class OverviewScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingLots: true,
      lots: {},
      sizeMedian: false,
      priceMedian: false,
      fetchingStats: true,
      stats: {},

      fetchingBreakdownStats: true,
      breakdownStats: {},

      fetchingOverviewStats: true,
      overviewStats: {}
    };

    this.isLoading = this.isLoading.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleError(e) {
    const { errorModal } = this.props;
    errorModal.open(
      `The following error has occurred: "${e}". Please check your settings and try again.`
    );
  }

  componentDidMount() {
    const { stats, whereabouts } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (!_.get(query, 'period') || !_.get(query, 'projects')) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    // Get stats
    stats
      .fetchOverviewOverview({
        args: {
          projects: getProjectIds(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) => {
        this.setState({
          fetchingOverviewStats: false,
          overviewStats: res.data
        });
      })
      .catch(this.handleError);

    stats
      .fetchOverviewEstateBreakdown({
        args: {
          projects: getProjectIds(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) => {
        this.setState({
          fetchingBreakdownStats: false,
          breakdownStats: res.data
        });
      })
      .catch(this.handleError);

    stats
      .fetchOverviewPriceSizeSnapshot({
        args: {
          projects: getProjectIds(this.props),
          period: getPeriod(this.props),
          primaryProject: getPrimaryProjectId(this.props)
        }
      })
      .then((res) => {
        this.setState({
          fetchingStats: false,
          stats: res.data
        });
      })
      .catch(this.handleError);
  }

  isLoading() {
    const { projects, periods } = this.props;

    const arr = [
      _.get(projects, 'list.status') === 'loading',
      _.get(periods, 'list.status') === 'loading',
      this.state.fetchingOverviewStats,
      this.state.fetchingBreakdownStats,
      this.state.fetchingStats
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const {
      periods,
      projects,
      errorModal: { Error }
    } = this.props;
    const { stats, breakdownStats, overviewStats } = this.state;
    const projectList = _.get(projects, 'list.items');
    const primaryProject = _(projectList)
      .filter(
        (project) => project.id === parseInt(getPrimaryProjectId(this.props))
      )
      .head();
    const period = _.head(_.get(periods, 'list.items'));
    const loading = this.isLoading();

    if (loading !== 0) {
      const currentRequests = loading ? 5 - loading : 0;
      return (
        <Fragment>
          <LoadingLayout current={currentRequests} max={5} />
          <Error />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Overview
          stats={stats}
          breakdownStats={breakdownStats}
          overviewStats={overviewStats}
          projects={projectList}
          primaryProject={primaryProject}
          period={period}
        />
        <ReportFooter
          nextText="Pricing Comparison"
          nextRoute={ROUTES.APP.REPORT_PRICING}
        />
      </Fragment>
    );
  }
}

export default OverviewScreen;
