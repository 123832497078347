import React from 'react';
import Box from '@rexlabs/box';
import { StyleSheet } from '@rexlabs/styling';
import { MARGINS } from 'src/features/reports/theme';
import _ from 'lodash';
import ToggleButtonSingle, {
  ToggleButtonSingleProps
} from './toggle-button-single';
import { Label } from 'view/components/text';

const labelStyles = StyleSheet({
  marginBottom: MARGINS.XXS
});

const getButtonContainerStyles = _.memoize((collapsed: boolean) =>
  StyleSheet({
    container: {
      gap: collapsed ? MARGINS.XXS : 0
    }
  })
);

export type ButtonGroupKeyLabelType = Pick<
  ToggleButtonSingleProps,
  'key' | 'label'
>;

interface ToggleButtonGroupProps {
  /**
   * An array of button with key and label
   * E.g. [{ key: 'key_1', value: 'value_1' }]
   */
  group: ButtonGroupKeyLabelType[];
  /**
   * This is used to determined whether a button is selected
   * E.g. the state is ['key_1']
   * It means that the button with the key 'key_1' is selected
   */
  value: string[];
  /**
   * Triggered when button is clicked
   * It will pass the key which can be used for storing state
   */
  onToggle: (key: string) => void;
  /**
   * This is used to determine the style of the button
   * collapsed - meaning the buttons are separated WITH spaces in between
   * if not collapsed - buttons are sticked to each other
   */
  collapsed?: boolean;
  /**
   * Label for the button group
   */
  label?: string;

  width?: number;
}

function ToggleButtonGroup({
  group,
  onToggle,
  value,
  collapsed,
  label,
  width
}: ToggleButtonGroupProps) {
  return (
    <Box flexDirection="column" width={width}>
      {label && <Label style={labelStyles}>{label}</Label>}
      <Box flexDirection="row" styles={getButtonContainerStyles(collapsed)}>
        {group.map((toggle, index) => (
          <ToggleButtonSingle
            {...toggle}
            checked={value.includes(toggle.key)}
            onClick={() => onToggle(toggle.key)}
            key={toggle.key}
            collapsed={collapsed}
            isFirst={index === 0}
            isLast={index === group.length - 1}
          />
        ))}
      </Box>
    </Box>
  );
}

export default ToggleButtonGroup;
