import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { Canceler } from 'axios';
import { compose } from 'utils/compose';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { CancelToken } from '@rexlabs/api-client';

import Panel from 'src/features/reports/components/panel';
import LotJourneySankeyChart from './lot-journey-sankey-chart';
import keyInsightsModel from 'src/features/reports/models/key-insights';

import withError from 'view/containers/with-error';

import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { KeyInsightsModel } from 'types/models/key-insights';
import { SankeyData } from 'types/graph';
import { Loading } from '../graph';
import { infuseColorToData } from 'utils/graph/lot-journey';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { Auth0Context } from 'src/auth0-provider';
import { INSIGHTS_PAGE } from 'src/features/reports/constants';
import InfoTooltip from './info-tooltip';

interface LotJourneyProps {
  keyInsightsModel: KeyInsightsModel;
  errorModal: WithErrorModalProps;
}

function LotJourney({
  keyInsightsModel,
  errorModal: { open: openError }
}: LotJourneyProps) {
  const { hasPermission } = useContext(Auth0Context);
  const canceler = useRef<Canceler | null>(null);
  const { selectedProjectIds, period, isFirstLoad } = useContext(
    ReportSettingsContext
  );
  const [{ nodes, links }, setLotJourneyData] = useState<SankeyData>({
    nodes: [],
    links: []
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ((!selectedProjectIds.length || !period) && !isFirstLoad) return;

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    keyInsightsModel
      .fetchLotJourney({
        queryParams: { project_ids: selectedProjectIds, period },
        hasPermission: !isFirstLoad && hasPermission([INSIGHTS_PAGE]),
        config: { cancelToken }
      })
      .then((response) => {
        // Empty when the api call got cancelled
        if (!response.data) return;
        const coloredData = infuseColorToData(response.data);
        setLotJourneyData(coloredData);
        setIsLoading(false);
      })
      .catch((error) => {
        openError(error);
        setIsLoading(false);
      });
  }, [
    selectedProjectIds,
    period,
    keyInsightsModel,
    openError,
    hasPermission,
    isFirstLoad
  ]);

  const hasLotJourneyData = useMemo(
    () => nodes.length > 0 && links.length > 0,
    [nodes, links]
  );

  return (
    <Panel
      title="Lot Journey"
      tooltip={{
        title: 'Lot Journey',
        description: <InfoTooltip />
      }}
    >
      <Loading isLoading={isLoading} />
      <Box width="100%" height={500}>
        {hasLotJourneyData && <LotJourneySankeyChart data={{ nodes, links }} />}
      </Box>
    </Panel>
  );
}

export default compose(
  withError.withPropName('errorModal'),
  withModel(keyInsightsModel)
)(LotJourney);
