import React, { useMemo, useState } from 'react';
import { Heading } from 'view/components/text';
import { PrimaryButton } from 'view/components/button';
import { Form, FormField, ReactForms } from 'view/components/form';
import { createValidationRules } from 'utils/form';
import { TextInput } from '@rexlabs/text-input';
import { PADDINGS, MARGINS } from 'src/theme';
import Box from '@rexlabs/box';
import regionsModel from 'data/models/entities/regions';
import { withModel } from '@rexlabs/model-generator';
import ButtonBar from 'view/components/modal/button-bar';
import _ from 'lodash';
import withError from 'view/containers/with-error';
import { SubRegion } from 'types/resource';
import { SubRegionModel } from 'types/models/sub-region';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import moment, { Moment } from 'moment';
import MonthFormInput from 'view/components/calendar/month-form-input';
import { CheckboxField } from './project-new';

interface RegionFormProps {
  regions: SubRegionModel;
  region: SubRegion;
  closeModal: () => void;
  errorModal: WithErrorModalProps;
  onSubmit: () => void;
}

interface FormValues {
  subRegion: string;
  publishedTo: Moment;
}

const RegionForm = ({
  region,
  closeModal,
  regions,
  onSubmit,
  errorModal
}: RegionFormProps) => {
  const { Error } = errorModal;

  const [isHidden, setIsHidden] = useState<boolean>(!!region?.is_hidden);

  const isUpdate = useMemo(() => !!_.get(region, 'id'), [region]);

  const handleSubmit = (values: FormValues) => {
    const payload = {
      name: _.get(values, 'subRegion'),
      published_to: moment(_.get(values, 'publishedTo')).format('YYYY-MM'),
      is_hidden: isHidden
    };

    const promise = isUpdate
      ? regions.updateItem({
          id: _.get(region, 'id'),
          data: payload
        })
      : regions.createItem({
          data: payload
        });

    return promise
      .then(() => {
        if (onSubmit) {
          onSubmit();
        }
        closeModal();
      })
      .catch((e) => errorModal.open(e));
  };

  const validate = (values: FormValues, props: RegionFormProps) =>
    createValidationRules({
      region: 'required|string',
      publishedTo: 'required|string'
    })(values, props);

  return (
    <Box p={PADDINGS.S}>
      <ReactForms
        handleSubmit={handleSubmit}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting, submitForm }) => {
          return (
            <Form>
              <Heading>{isUpdate ? 'Edit' : 'Add'} Sub-Region</Heading>
              <FormField
                sendImmediate
                label="Sub-Region"
                name="subRegion"
                initialValue={_.get(region, 'name', '')}
                Input={TextInput}
                inputProps={{
                  autoFocus: true
                }}
              />
              <FormField
                sendImmediate
                label="Published To"
                name="publishedTo"
                initialValue={(isUpdate
                  ? new Date(_.get(region, 'published_to', ''))
                  : new Date()
                ).toString()}
                Input={MonthFormInput}
                inputProps={{
                  name: 'publishedTo'
                }}
              />
              <Box mt={MARGINS.XXS} ml="-0.6rem">
                <CheckboxField
                  name="isHidden"
                  label="Hidden from report"
                  onClick={() => setIsHidden((prev) => !prev)}
                  value={isHidden}
                />
              </Box>
              <ButtonBar style={{ paddingRight: 0 }}>
                <PrimaryButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    closeModal();
                  }}
                >
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  green
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  onClick={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}
                >
                  {isUpdate ? ' Edit' : 'Add'}
                </PrimaryButton>
              </ButtonBar>
            </Form>
          );
        }}
      </ReactForms>
      <Error />
    </Box>
  );
};

export default _.flow([
  withError.withPropName('errorModal'),
  withModel(regionsModel)
])(RegionForm);
