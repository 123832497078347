import { toQuri } from 'utils/query';

export const getPeriod = (props) => props?.match?.query?.period;

export const getSettingsId = (props) => props?.match?.params?.id;

export const getToken = (props) => props?.match?.query?.token;

export const getPeriodQuery = (props) => {
  const period = getPeriod(props);

  if (!period) {
    return null;
  }

  return toQuri([
    {
      field: 'end_date',
      op: '==',
      value: period
    }
  ]);
};
