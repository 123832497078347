import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { AddButton, ClearButton, PrimaryButton } from 'view/components/button';
import { Portal } from '@rexlabs/portal';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import regionsModel from 'data/models/entities/regions';
import regionProjectsModel from 'data/models/entities/region-projects';
import projectsModel from 'data/models/entities/projects';
import { Heading } from 'view/components/text';
import List from '@rexlabs/list';
import _ from 'lodash';
import ROUTES from 'src/routes';
import LoadingLayout from '../../layouts/loading';
import { Link } from '@rexlabs/react-whereabouts';
import SectionContainer from '../../components/section-container';
import RegionProjectListItem from 'view/components/list/region-project-list-item';
import ProjectListHeader from 'view/components/list/project-list-header';
import Cross from 'src/assets/cross-dark.svg';
import { PADDINGS } from 'src/theme';
import withAction from 'view/containers/with-action';
import withError from 'view/containers/with-error';

function getRegionId(props) {
  return _.get(props, 'match.params.regionId');
}

const regionQuery = query`{
  ${regionsModel} (id: ${getRegionId}) {
    id
    name
  }
}`;

const projectQuery = query`{
  ${regionProjectsModel} (regionId: ${getRegionId}, include: "sub_region") {
    id
    account_id
    sub_region_id
    title
    density
    website
    developer
    sales_office_address
    suburb
    postcode
    status
    local_government_area
    sales_agency
    precinct_structure_plan
    image_id
    estimated_lots
    estimated_number_of_stages
    estimated_start_date
    estimated_completion_date
    archived_at
    trashed_at
    active
    sales_volume_offset
    summary
    settings
    created_at
    sub_region
    region
    state
  }
}`;

const defaultStyles = StyleSheet({
  btn: {
    margin: '0.5rem'
  }
});

@withAction.withPropName('actionModal')
@withError.withPropName('errorModal')
@withQuery(regionQuery)
@withQuery(projectQuery)
@withModel(projectsModel)
@styled(defaultStyles)
class View extends PureComponent {
  state = {
    checked: {}
  };

  isLoading = () => {
    const { regions, regionProjects } = this.props;

    return _.some([
      _.get(regions, 'item.status') === 'loading',
      _.get(regionProjects, 'list.status') === 'loading'
    ]);
  };

  selectAll = () => {
    const { regionProjects } = this.props;
    const projectList = _.get(regionProjects, 'list.items', []);

    if (
      _.filter(this.state.checked, (value) => value).length ===
      projectList.length
    ) {
      this.setState({
        checked: {}
      });
    } else {
      const toggledState = projectList.reduce((obj, item) => {
        return { ...obj, [item.id]: true };
      }, {});
      this.setState({
        checked: toggledState
      });
    }
  };

  toggleItem = (id) => {
    this.setState({
      checked: {
        ...this.state.checked,
        ...{
          [id]: !this.state.checked[id]
        }
      }
    });
  };

  renderHeader = () => {
    const { regionProjects } = this.props;
    const { checked } = this.state;
    const projectList = _.get(regionProjects, 'list.items', []);

    return (
      <ProjectListHeader
        onSelectAll={this.selectAll}
        checked={
          _.filter(checked, (value) => value).length === projectList.length
        }
      />
    );
  };

  renderItem = (item) => {
    const { checked } = this.state;
    const { regionProjects } = this.props;
    return (
      <RegionProjectListItem
        project={item}
        regionProjects={regionProjects}
        checked={_.get(checked, item.id)}
        toggle={() => this.toggleItem(item.id)}
      />
    );
  };

  componentDidUpdate(prevProps) {
    const { regionProjects } = this.props;

    const modalRegex = RegExp('/app/sub-region/[0-9]+/new');

    // Refresh regions on modal close
    if (
      modalRegex.test(_.get(prevProps, 'whereabouts.path')) &&
      !modalRegex.test(_.get(this.props, 'whereabouts.path'))
    ) {
      // Reset checked
      this.setState({ checked: {} });

      // Refresh list
      regionProjects.refreshList({ id: 'refresh-on-close' });
    }
  }

  removeProjects = () => {
    const { checked } = this.state;
    const { regionProjects, projects, actionModal, errorModal } = this.props;

    const ids = _(checked)
      .map((value, key) => ({ key: key, value: value }))
      .filter((value) => value.value)
      .map((x) => x.key)
      .value();

    const payload = {
      sub_region_id: null
    };

    actionModal.open(
      () => {
        Promise.all(
          _.map(ids, (id) =>
            projects.updateItem({
              id: id,
              data: payload
            })
          )
        )
          .then(() => {
            // Reset checked
            this.setState({ checked: {} });
            regionProjects.refreshList({ id: 'refresh-after-remove' });
            actionModal.close();
          })
          .catch(errorModal.open);
      },
      'Are you sure you want to remove these project(s) from the region?',
      'Remove Projects',
      true
    );
  };

  render() {
    const {
      styles: s,
      regions,
      regionProjects,
      actionModal: { Action },
      errorModal: { Error }
    } = this.props;
    if (this.isLoading()) {
      return <LoadingLayout />;
    }

    const region = _.get(regions, 'item.data');
    const projects = _.get(regionProjects, 'list.items');

    return (
      <Fragment>
        <Portal target="header">
          <Box
            style={{ height: '100%', alignItems: 'center', padding: '4rem' }}
            flex={1}
          >
            <Link to={ROUTES.APP.REGIONS.NEW_REGION}>
              {({ onClick }) => (
                <AddButton onClick={onClick}>Add Sub-Region</AddButton>
              )}
            </Link>
          </Box>
        </Portal>
        <Box>
          <Breadcrumb
            crumbs={[
              {
                label: 'Dashboard',
                route: '/'
              },
              {
                label: 'Sub-Regions',
                route: '/app/sub-regions'
              },
              {
                label: _.get(region, 'name')
              }
            ]}
          />
        </Box>
        <SectionContainer>
          <Box
            flex={1}
            style={{
              width: '100%',
              justifyContent: 'space-between',
              padding: PADDINGS.XS
            }}
          >
            <Heading>Sub-Region Projects</Heading>
            <Box style={{ display: 'inline-flex' }}>
              {_.filter(this.state.checked, (value) => value).length > 0 && (
                <PrimaryButton {...s('btn')} onClick={this.removeProjects} red>
                  Remove
                </PrimaryButton>
              )}
              <Link
                to={ROUTES.APP.REGION_PROJECTS.NEW_REGION_PROJECT}
                params={{ regionId: _.get(region, 'id') }}
              >
                {({ onClick }) => (
                  <ClearButton
                    onClick={onClick}
                    iconRight={<Cross style={{ marginLeft: '0.8rem' }} />}
                    {...s('btn')}
                  >
                    Add Project
                  </ClearButton>
                )}
              </Link>
            </Box>
          </Box>
          <List
            items={projects}
            isLoading={
              _.get(regionProjects, 'list.status') === 'loading' ||
              _.get(regionProjects, 'list.status') === 'refreshing'
            }
            endReached={_.get(regionProjects, 'list.pagination.endReached')}
            Header={this.renderHeader}
            renderItem={this.renderItem}
            onLoadMore={() => regionProjects.fetchMore()}
          />
        </SectionContainer>
        <Action />
        <Error />
      </Fragment>
    );
  }
}

export default View;
