import React, { useEffect, useRef, useState } from 'react';
import { CancelToken } from '@rexlabs/api-client';
import { Canceler } from 'axios';
import { PerformanceOverviewSummary } from 'types/graph';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { ReportQueryParams } from 'types/params';
import { KeyInsightsModel } from 'types/models/key-insights';
import FeaturedBox from '../panel/featured-box';
import { CATEGORIES } from './constants';

interface CategoriesProps extends ReportQueryParams {
  selected: { label: string; key: string };
  onClick: (key: { label: string; key: string }) => void;
  fetchPerformanceOverviewSummary: KeyInsightsModel['fetchPerformanceOverviewSummary'];
  errorModal: WithErrorModalProps;
  hasPermission: boolean;
  isFirstLoad: boolean;
}

const Categories = ({
  selected,
  onClick,
  fetchPerformanceOverviewSummary,
  project_ids,
  period,
  errorModal: { open: openError },
  hasPermission,
  isFirstLoad
}: CategoriesProps) => {
  const canceler = useRef<Canceler | null>(null);
  const [summary, setSummary] = useState<PerformanceOverviewSummary>({
    gross_sales: 0,
    gross_sales_titled: 0,
    gross_sales_untitled: 0,
    available_titled: 0,
    available_untitled: 0,
    outstanding_contracts: 0,
    net_sales: 0,
    back_on_market: 0,
    newly_released: 0,
    available: 0,
    average_sales: 0
  });

  useEffect(() => {
    if ((!project_ids.length || !period) && !isFirstLoad) return;

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    fetchPerformanceOverviewSummary({
      queryParams: { project_ids, period },
      hasPermission: !isFirstLoad && hasPermission,
      config: { cancelToken }
    })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;
        setSummary(res.data);
      })
      .catch((error) => {
        openError(error);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    project_ids,
    period,
    fetchPerformanceOverviewSummary,
    openError,
    hasPermission,
    isFirstLoad
  ]);

  return (
    <>
      {CATEGORIES.map((category) => (
        <FeaturedBox
          key={category.key}
          active={category.key === selected.key}
          onClick={() => onClick(category)}
          label={category.label.toUpperCase()}
          value={summary[category.key]}
          leftSubLabel={
            category.leftSubLabel
              ? {
                  ...category.leftSubLabel,
                  value: summary[`${category.key}_${category.leftSubLabel.key}`]
                }
              : undefined
          }
          rightSubLabel={
            category.rightSubLabel
              ? {
                  ...category.rightSubLabel,
                  value:
                    summary[`${category.key}_${category.rightSubLabel.key}`]
                }
              : undefined
          }
        />
      ))}
    </>
  );
};

export default Categories;
