/* eslint-disable max-lines */
import {
  MonthsSupplyResponse,
  PerformanceOverviewGraph,
  PerformanceOverviewSummary,
  ProductBreakdown,
  ProductSummary,
  ProjectCountResponse,
  SalesPerformanceResponse,
  SankeyData
} from 'types/graph';
import { ProductOverviewParams, SupplyOverviewParams } from 'types/params';

export const fetchLotJourneyMock = (): Promise<{ data: SankeyData }> => {
  return new Promise((resolve) => {
    resolve({
      data: {
        nodes: [
          { id: 'Available Last Month' },
          { id: 'Back on Market' },
          { id: 'Newly Released' },
          { id: 'On Market' },
          { id: 'Gross Sales' }
        ],
        links: [
          { source: 'Available Last Month', target: 'On Market', value: 199 },
          { source: 'Available Last Month', target: 'Gross Sales', value: 19 },
          { source: 'Back on Market', target: 'On Market', value: 7 },
          { source: 'Newly Released', target: 'On Market', value: 16 },
          { source: 'Newly Released', target: 'Gross Sales', value: 14 }
        ]
      }
    });
  });
};

export const fetchSupplyOverviewMock = ({
  report
}: Pick<SupplyOverviewParams, 'report'>): Promise<{
  data: MonthsSupplyResponse | ProjectCountResponse;
}> => {
  return new Promise((resolve) => {
    if (report === 'months_supply') {
      resolve({
        data: {
          average: 5.97,
          records: [
            { average: 18.6, end_date: '2019-03-01' },
            { average: 9.37, end_date: '2019-04-01' },
            { average: 7.39, end_date: '2019-05-01' },
            { average: 8.96, end_date: '2019-06-01' },
            { average: 7.23, end_date: '2019-07-01' },
            { average: 6.79, end_date: '2019-08-01' },
            { average: 7.67, end_date: '2019-09-01' },
            { average: 6.26, end_date: '2019-10-01' },
            { average: 3.95, end_date: '2019-11-01' },
            { average: 8.22, end_date: '2019-12-01' },
            { average: 5.62, end_date: '2020-01-01' },
            { average: 12.04, end_date: '2020-02-01' },
            { average: 4.81, end_date: '2020-03-01' },
            { average: 11.3, end_date: '2020-04-01' },
            { average: 5.45, end_date: '2020-05-01' },
            { average: 3.37, end_date: '2020-06-01' },
            { average: 1.9, end_date: '2020-07-01' },
            { average: 3.21, end_date: '2020-08-01' },
            { average: 3.52, end_date: '2020-09-01' },
            { average: 1.81, end_date: '2020-10-01' },
            { average: 1.87, end_date: '2020-11-01' },
            { average: 1.47, end_date: '2020-12-01' },
            { average: 1.07, end_date: '2021-01-01' },
            { average: 1.32, end_date: '2021-02-01' }
          ]
        }
      });
    } else {
      resolve({
        data: {
          total: 18,
          records: [
            { end_date: '2019-03-01', active: '6', flagged: '0', on_hold: '0' },
            { end_date: '2019-04-01', active: '6', flagged: '0', on_hold: '0' },
            { end_date: '2019-05-01', active: '6', flagged: '0', on_hold: '0' },
            { end_date: '2019-06-01', active: '6', flagged: '0', on_hold: '0' },
            { end_date: '2019-07-01', active: '7', flagged: '0', on_hold: '0' },
            { end_date: '2019-08-01', active: '7', flagged: '0', on_hold: '0' },
            { end_date: '2019-09-01', active: '7', flagged: '0', on_hold: '0' },
            { end_date: '2019-10-01', active: '7', flagged: '0', on_hold: '0' },
            { end_date: '2019-11-01', active: '7', flagged: '0', on_hold: '0' },
            { end_date: '2019-12-01', active: '7', flagged: '0', on_hold: '0' },
            { end_date: '2020-01-01', active: '7', flagged: '0', on_hold: '0' },
            { end_date: '2020-02-01', active: '7', flagged: '0', on_hold: '0' },
            { end_date: '2020-03-01', active: '7', flagged: '1', on_hold: '0' },
            { end_date: '2020-04-01', active: '6', flagged: '2', on_hold: '0' },
            { end_date: '2020-05-01', active: '5', flagged: '3', on_hold: '0' },
            { end_date: '2020-06-01', active: '7', flagged: '1', on_hold: '0' },
            { end_date: '2020-07-01', active: '7', flagged: '1', on_hold: '0' },
            { end_date: '2020-08-01', active: '8', flagged: '1', on_hold: '0' },
            { end_date: '2020-09-01', active: '9', flagged: '1', on_hold: '0' },
            { end_date: '2020-10-01', active: '9', flagged: '1', on_hold: '0' },
            {
              end_date: '2020-11-01',
              active: '10',
              flagged: '1',
              on_hold: '0'
            },
            { end_date: '2020-12-01', active: '9', flagged: '2', on_hold: '0' },
            {
              end_date: '2021-01-01',
              active: '11',
              flagged: '0',
              on_hold: '0'
            },
            { end_date: '2021-02-01', active: '11', flagged: '0', on_hold: '0' }
          ]
        }
      });
    }
  });
};

export const fetchProductOverviewMock = ({
  report
}: Pick<ProductOverviewParams, 'report'>): Promise<{
  data: ProductSummary | ProductBreakdown;
}> => {
  return new Promise((resolve) => {
    if (report === 'summary') {
      resolve({
        data: {
          small_lots: 2560,
          available_small_lots: 2106,
          sold_small_lots: 454,
          traditional_lots: 6021,
          available_traditional_lots: 4673,
          sold_traditional_lots: 1348
        }
      });
    } else {
      resolve({
        data: {
          monthly: {
            traditional: {
              '2017-03-01': {
                average: {
                  excluded_lots: 0,
                  price: 266011.94,
                  price_per_m2: 573.93,
                  size: 472.82
                },
                median: {
                  excluded_lots: 0,
                  price: 257000,
                  price_per_m2: 566.99,
                  size: 448
                }
              },
              '2017-04-01': {
                average: {
                  excluded_lots: 0,
                  price: 267810.68,
                  price_per_m2: 602.17,
                  size: 451.43
                },
                median: {
                  excluded_lots: 0,
                  price: 262000,
                  price_per_m2: 607.14,
                  size: 416
                }
              },
              '2017-05-01': {
                average: {
                  excluded_lots: 0,
                  price: 268967.03,
                  price_per_m2: 643.39,
                  size: 421.05
                },
                median: {
                  excluded_lots: 0,
                  price: 271200,
                  price_per_m2: 642.86,
                  size: 407
                }
              },
              '2017-06-01': {
                average: {
                  excluded_lots: 0,
                  price: 286411.24,
                  price_per_m2: 628.17,
                  size: 461.39
                },
                median: {
                  excluded_lots: 0,
                  price: 278000,
                  price_per_m2: 628.39,
                  size: 448
                }
              },
              '2017-07-01': {
                average: {
                  excluded_lots: 0,
                  price: 294278.21,
                  price_per_m2: 644.14,
                  size: 465.12
                },
                median: {
                  excluded_lots: 0,
                  price: 286800,
                  price_per_m2: 653.51,
                  size: 444.5
                }
              },
              '2017-08-01': {
                average: {
                  excluded_lots: 0,
                  price: 292958.33,
                  price_per_m2: 672.98,
                  size: 442.01
                },
                median: {
                  excluded_lots: 0,
                  price: 287250,
                  price_per_m2: 682.81,
                  size: 420.5
                }
              },
              '2017-09-01': {
                average: {
                  excluded_lots: 0,
                  price: 294290.86,
                  price_per_m2: 682.24,
                  size: 437.16
                },
                median: {
                  excluded_lots: 0,
                  price: 290250,
                  price_per_m2: 692.41,
                  size: 416
                }
              },
              '2017-10-01': {
                average: {
                  excluded_lots: 0,
                  price: 293049.53,
                  price_per_m2: 688.45,
                  size: 430.76
                },
                median: {
                  excluded_lots: 0,
                  price: 288100,
                  price_per_m2: 694.29,
                  size: 414
                }
              },
              '2017-11-01': {
                average: {
                  excluded_lots: 0,
                  price: 310737.16,
                  price_per_m2: 714.84,
                  size: 439.59
                },
                median: {
                  excluded_lots: 0,
                  price: 305000,
                  price_per_m2: 718.98,
                  size: 421
                }
              },
              '2017-12-01': {
                average: {
                  excluded_lots: 0,
                  price: 322210.4,
                  price_per_m2: 737.74,
                  size: 442.77
                },
                median: {
                  excluded_lots: 0,
                  price: 315000,
                  price_per_m2: 737.5,
                  size: 421
                }
              },
              '2018-01-01': {
                average: {
                  excluded_lots: 0,
                  price: 310886.9,
                  price_per_m2: 738.2,
                  size: 429.46
                },
                median: {
                  excluded_lots: 0,
                  price: 295000,
                  price_per_m2: 752.55,
                  size: 392
                }
              },
              '2018-02-01': {
                average: {
                  excluded_lots: 0,
                  price: 322416.51,
                  price_per_m2: 750.28,
                  size: 437.3
                },
                median: {
                  excluded_lots: 0,
                  price: 321100,
                  price_per_m2: 751.67,
                  size: 400
                }
              },
              '2018-03-01': {
                average: {
                  excluded_lots: 0,
                  price: 301776,
                  price_per_m2: 750.7,
                  size: 409.23
                },
                median: {
                  excluded_lots: 0,
                  price: 289000,
                  price_per_m2: 769.02,
                  size: 373
                }
              },
              '2018-04-01': {
                average: {
                  excluded_lots: 0,
                  price: 309615.85,
                  price_per_m2: 768.59,
                  size: 405.83
                },
                median: {
                  excluded_lots: 0,
                  price: 302000,
                  price_per_m2: 764.5,
                  size: 400
                }
              },
              '2018-05-01': {
                average: {
                  excluded_lots: 0,
                  price: 326760.64,
                  price_per_m2: 749.8,
                  size: 440.04
                },
                median: {
                  excluded_lots: 0,
                  price: 322000,
                  price_per_m2: 748.27,
                  size: 432
                }
              },
              '2018-06-01': {
                average: {
                  excluded_lots: 0,
                  price: 318868.92,
                  price_per_m2: 756.69,
                  size: 425.78
                },
                median: {
                  excluded_lots: 0,
                  price: 318000,
                  price_per_m2: 761.64,
                  size: 420
                }
              },
              '2018-07-01': {
                average: {
                  excluded_lots: 0,
                  price: 323957.38,
                  price_per_m2: 767.97,
                  size: 427.33
                },
                median: {
                  excluded_lots: 0,
                  price: 310000,
                  price_per_m2: 767.5,
                  size: 400
                }
              },
              '2018-08-01': {
                average: {
                  excluded_lots: 0,
                  price: 316568.42,
                  price_per_m2: 766.54,
                  size: 420.51
                },
                median: {
                  excluded_lots: 0,
                  price: 307000,
                  price_per_m2: 774.52,
                  size: 400
                }
              },
              '2018-09-01': {
                average: {
                  excluded_lots: 0,
                  price: 324932.76,
                  price_per_m2: 759.82,
                  size: 431.31
                },
                median: {
                  excluded_lots: 0,
                  price: 329000,
                  price_per_m2: 757.14,
                  size: 447
                }
              },
              '2018-10-01': {
                average: {
                  excluded_lots: 0,
                  price: 323000,
                  price_per_m2: 802.08,
                  size: 405.03
                },
                median: {
                  excluded_lots: 0,
                  price: 319500,
                  price_per_m2: 800.72,
                  size: 414.5
                }
              },
              '2018-11-01': {
                average: {
                  excluded_lots: 0,
                  price: 319166.13,
                  price_per_m2: 778.22,
                  size: 413.65
                },
                median: {
                  excluded_lots: 0,
                  price: 303000,
                  price_per_m2: 796.64,
                  size: 393
                }
              },
              '2018-12-01': {
                average: {
                  excluded_lots: 0,
                  price: 316533.33,
                  price_per_m2: 801.15,
                  size: 396.33
                },
                median: {
                  excluded_lots: 0,
                  price: 318500,
                  price_per_m2: 805.42,
                  size: 400
                }
              },
              '2019-01-01': {
                average: {
                  excluded_lots: 0,
                  price: 331305.88,
                  price_per_m2: 809.01,
                  size: 417.82
                },
                median: {
                  excluded_lots: 0,
                  price: 306500,
                  price_per_m2: 807.14,
                  size: 392
                }
              },
              '2019-02-01': {
                average: {
                  excluded_lots: 0,
                  price: 335206.67,
                  price_per_m2: 746.19,
                  size: 454
                },
                median: {
                  excluded_lots: 0,
                  price: 331850,
                  price_per_m2: 742.59,
                  size: 448.5
                }
              }
            },
            small: {
              '2017-03-01': {
                average: {
                  excluded_lots: 0,
                  price: 156235.29,
                  price_per_m2: 665.38,
                  size: 239.59
                },
                median: {
                  excluded_lots: 0,
                  price: 162000,
                  price_per_m2: 634.92,
                  size: 252
                }
              },
              '2017-04-01': {
                average: {
                  excluded_lots: 0,
                  price: 169576.92,
                  price_per_m2: 667.95,
                  size: 254.88
                },
                median: {
                  excluded_lots: 0,
                  price: 169000,
                  price_per_m2: 664.1,
                  size: 257
                }
              },
              '2017-05-01': {
                average: {
                  excluded_lots: 0,
                  price: 170357.14,
                  price_per_m2: 674.48,
                  size: 253.79
                },
                median: {
                  excluded_lots: 0,
                  price: 170000,
                  price_per_m2: 654.77,
                  size: 247
                }
              },
              '2017-06-01': {
                average: {
                  excluded_lots: 0,
                  price: 185866.67,
                  price_per_m2: 726.56,
                  size: 256.8
                },
                median: {
                  excluded_lots: 0,
                  price: 190000,
                  price_per_m2: 746.61,
                  size: 252
                }
              },
              '2017-07-01': {
                average: {
                  excluded_lots: 0,
                  price: 182421.05,
                  price_per_m2: 747.8,
                  size: 246.21
                },
                median: {
                  excluded_lots: 0,
                  price: 171000,
                  price_per_m2: 758.62,
                  size: 252
                }
              },
              '2017-08-01': {
                average: {
                  excluded_lots: 0,
                  price: 191250,
                  price_per_m2: 732.05,
                  size: 262.25
                },
                median: {
                  excluded_lots: 0,
                  price: 190500,
                  price_per_m2: 694.55,
                  size: 259
                }
              },
              '2017-09-01': {
                average: {
                  excluded_lots: 0,
                  price: 196692.31,
                  price_per_m2: 754.66,
                  size: 261.74
                },
                median: {
                  excluded_lots: 0,
                  price: 191000,
                  price_per_m2: 755.1,
                  size: 262
                }
              },
              '2017-10-01': {
                average: {
                  excluded_lots: 0,
                  price: 200738.1,
                  price_per_m2: 830.9,
                  size: 244.6
                },
                median: {
                  excluded_lots: 0,
                  price: 202000,
                  price_per_m2: 823.53,
                  size: 242
                }
              },
              '2017-11-01': {
                average: {
                  excluded_lots: 0,
                  price: 206833.33,
                  price_per_m2: 795.26,
                  size: 261.15
                },
                median: {
                  excluded_lots: 0,
                  price: 208000,
                  price_per_m2: 778.55,
                  size: 262
                }
              },
              '2017-12-01': {
                average: {
                  excluded_lots: 0,
                  price: 208960,
                  price_per_m2: 809.12,
                  size: 259.69
                },
                median: {
                  excluded_lots: 0,
                  price: 208000,
                  price_per_m2: 794.68,
                  size: 263
                }
              },
              '2018-01-01': {
                average: {
                  excluded_lots: 0,
                  price: 211847.83,
                  price_per_m2: 822.38,
                  size: 259.04
                },
                median: {
                  excluded_lots: 0,
                  price: 209000,
                  price_per_m2: 839.22,
                  size: 255
                }
              },
              '2018-02-01': {
                average: {
                  excluded_lots: 0,
                  price: 217869.05,
                  price_per_m2: 829.9,
                  size: 263.67
                },
                median: {
                  excluded_lots: 0,
                  price: 223500,
                  price_per_m2: 847.67,
                  size: 263
                }
              },
              '2018-03-01': {
                average: {
                  excluded_lots: 0,
                  price: 222083.33,
                  price_per_m2: 820.48,
                  size: 271.17
                },
                median: {
                  excluded_lots: 0,
                  price: 219000,
                  price_per_m2: 827.66,
                  size: 263.5
                }
              },
              '2018-04-01': {
                average: {
                  excluded_lots: 0,
                  price: 213615.38,
                  price_per_m2: 853.86,
                  size: 252.15
                },
                median: {
                  excluded_lots: 0,
                  price: 212000,
                  price_per_m2: 857.14,
                  size: 246.5
                }
              },
              '2018-05-01': {
                average: {
                  excluded_lots: 0,
                  price: 217194.44,
                  price_per_m2: 871.87,
                  size: 249.92
                },
                median: {
                  excluded_lots: 0,
                  price: 215000,
                  price_per_m2: 865.36,
                  size: 238
                }
              },
              '2018-06-01': {
                average: {
                  excluded_lots: 0,
                  price: 227125,
                  price_per_m2: 844.3,
                  size: 269.82
                },
                median: {
                  excluded_lots: 0,
                  price: 220000,
                  price_per_m2: 839.37,
                  size: 263
                }
              },
              '2018-07-01': {
                average: {
                  excluded_lots: 0,
                  price: 228846.15,
                  price_per_m2: 872.01,
                  size: 263
                },
                median: {
                  excluded_lots: 0,
                  price: 226000,
                  price_per_m2: 875.56,
                  size: 262
                }
              },
              '2018-08-01': {
                average: {
                  excluded_lots: 0,
                  price: 216153.85,
                  price_per_m2: 893.23,
                  size: 243.08
                },
                median: {
                  excluded_lots: 0,
                  price: 212000,
                  price_per_m2: 920.32,
                  size: 238
                }
              },
              '2018-09-01': {
                average: {
                  excluded_lots: 0,
                  price: 214928.57,
                  price_per_m2: 848.26,
                  size: 255.5
                },
                median: {
                  excluded_lots: 0,
                  price: 207000,
                  price_per_m2: 846.94,
                  size: 253.5
                }
              },
              '2018-10-01': {
                average: {
                  excluded_lots: 0,
                  price: 210000,
                  price_per_m2: 901.35,
                  size: 234
                },
                median: {
                  excluded_lots: 0,
                  price: 209000,
                  price_per_m2: 918.18,
                  size: 220
                }
              },
              '2018-11-01': {
                average: {
                  excluded_lots: 0,
                  price: 214000,
                  price_per_m2: 810.61,
                  size: 264
                },
                median: {
                  excluded_lots: 0,
                  price: 214000,
                  price_per_m2: 810.61,
                  size: 264
                }
              },
              '2018-12-01': {
                average: {
                  excluded_lots: 0,
                  price: 205000,
                  price_per_m2: 840.16,
                  size: 244
                },
                median: {
                  excluded_lots: 0,
                  price: 205000,
                  price_per_m2: 840.16,
                  size: 244
                }
              },
              '2019-01-01': {
                average: {
                  excluded_lots: 0,
                  price: 240500,
                  price_per_m2: 818.03,
                  size: 294
                },
                median: {
                  excluded_lots: 0,
                  price: 240500,
                  price_per_m2: 818.03,
                  size: 294
                }
              },
              '2019-02-01': {
                average: {
                  excluded_lots: 0,
                  price: 233166.67,
                  price_per_m2: 854.42,
                  size: 273.33
                },
                median: {
                  excluded_lots: 0,
                  price: 229500,
                  price_per_m2: 872.62,
                  size: 263
                }
              }
            },
            all: {
              '2017-03-01': {
                average: {
                  excluded_lots: 0,
                  price: 243795.24,
                  price_per_m2: 592.44,
                  size: 425.62
                },
                median: {
                  excluded_lots: 0,
                  price: 236000,
                  price_per_m2: 587.26,
                  size: 402
                }
              },
              '2017-04-01': {
                average: {
                  excluded_lots: 0,
                  price: 248011.63,
                  price_per_m2: 615.42,
                  size: 411.81
                },
                median: {
                  excluded_lots: 0,
                  price: 250100,
                  price_per_m2: 619.9,
                  size: 400
                }
              },
              '2017-05-01': {
                average: {
                  excluded_lots: 0,
                  price: 255819.05,
                  price_per_m2: 647.54,
                  size: 398.75
                },
                median: {
                  excluded_lots: 0,
                  price: 262000,
                  price_per_m2: 647.06,
                  size: 400
                }
              },
              '2017-06-01': {
                average: {
                  excluded_lots: 0,
                  price: 271909.62,
                  price_per_m2: 642.36,
                  size: 431.88
                },
                median: {
                  excluded_lots: 0,
                  price: 274500,
                  price_per_m2: 643.75,
                  size: 444
                }
              },
              '2017-07-01': {
                average: {
                  excluded_lots: 0,
                  price: 272368.04,
                  price_per_m2: 664.44,
                  size: 422.24
                },
                median: {
                  excluded_lots: 0,
                  price: 275000,
                  price_per_m2: 662.72,
                  size: 420
                }
              },
              '2017-08-01': {
                average: {
                  excluded_lots: 0,
                  price: 284114.13,
                  price_per_m2: 678.11,
                  size: 426.38
                },
                median: {
                  excluded_lots: 0,
                  price: 284200,
                  price_per_m2: 682.81,
                  size: 405.5
                }
              },
              '2017-09-01': {
                average: {
                  excluded_lots: 0,
                  price: 277373.78,
                  price_per_m2: 694.79,
                  size: 406.75
                },
                median: {
                  excluded_lots: 0,
                  price: 284000,
                  price_per_m2: 694.55,
                  size: 400
                }
              },
              '2017-10-01': {
                average: {
                  excluded_lots: 0,
                  price: 252451.83,
                  price_per_m2: 751.1,
                  size: 348.88
                },
                median: {
                  excluded_lots: 0,
                  price: 241000,
                  price_per_m2: 725.28,
                  size: 330
                }
              },
              '2017-11-01': {
                average: {
                  excluded_lots: 0,
                  price: 285291.33,
                  price_per_m2: 734.54,
                  size: 395.89
                },
                median: {
                  excluded_lots: 0,
                  price: 292900,
                  price_per_m2: 738.65,
                  size: 394.5
                }
              },
              '2017-12-01': {
                average: {
                  excluded_lots: 0,
                  price: 300668.21,
                  price_per_m2: 751.32,
                  size: 407.94
                },
                median: {
                  excluded_lots: 0,
                  price: 301700,
                  price_per_m2: 747.5,
                  size: 400
                }
              },
              '2018-01-01': {
                average: {
                  excluded_lots: 0,
                  price: 289598.13,
                  price_per_m2: 756.3,
                  size: 392.83
                },
                median: {
                  excluded_lots: 0,
                  price: 285000,
                  price_per_m2: 759.42,
                  size: 387
                }
              },
              '2018-02-01': {
                average: {
                  excluded_lots: 0,
                  price: 293337.09,
                  price_per_m2: 772.43,
                  size: 389.01
                },
                median: {
                  excluded_lots: 0,
                  price: 284100,
                  price_per_m2: 772.42,
                  size: 358
                }
              },
              '2018-03-01': {
                average: {
                  excluded_lots: 0,
                  price: 279006.67,
                  price_per_m2: 770.64,
                  size: 369.78
                },
                median: {
                  excluded_lots: 0,
                  price: 270000,
                  price_per_m2: 780.3,
                  size: 350
                }
              },
              '2018-04-01': {
                average: {
                  excluded_lots: 0,
                  price: 286504.63,
                  price_per_m2: 789.12,
                  size: 368.83
                },
                median: {
                  excluded_lots: 0,
                  price: 301700,
                  price_per_m2: 769.64,
                  size: 392
                }
              },
              '2018-05-01': {
                average: {
                  excluded_lots: 0,
                  price: 296419.23,
                  price_per_m2: 783.61,
                  size: 387.39
                },
                median: {
                  excluded_lots: 0,
                  price: 304900,
                  price_per_m2: 772.5,
                  size: 394
                }
              },
              '2018-06-01': {
                average: {
                  excluded_lots: 0,
                  price: 299259.53,
                  price_per_m2: 775.42,
                  size: 392.44
                },
                median: {
                  excluded_lots: 0,
                  price: 305000,
                  price_per_m2: 783.25,
                  size: 392
                }
              },
              '2018-07-01': {
                average: {
                  excluded_lots: 0,
                  price: 295533.33,
                  price_per_m2: 799.06,
                  size: 378.22
                },
                median: {
                  excluded_lots: 0,
                  price: 305000,
                  price_per_m2: 792.41,
                  size: 388
                }
              },
              '2018-08-01': {
                average: {
                  excluded_lots: 0,
                  price: 297920,
                  price_per_m2: 790.07,
                  size: 387.56
                },
                median: {
                  excluded_lots: 0,
                  price: 297200,
                  price_per_m2: 791.43,
                  size: 375
                }
              },
              '2018-09-01': {
                average: {
                  excluded_lots: 0,
                  price: 303543.06,
                  price_per_m2: 777.02,
                  size: 397.13
                },
                median: {
                  excluded_lots: 0,
                  price: 309750,
                  price_per_m2: 757.78,
                  size: 400
                }
              },
              '2018-10-01': {
                average: {
                  excluded_lots: 0,
                  price: 312727.27,
                  price_per_m2: 811.11,
                  size: 389.48
                },
                median: {
                  excluded_lots: 0,
                  price: 310000,
                  price_per_m2: 820,
                  size: 400
                }
              },
              '2018-11-01': {
                average: {
                  excluded_lots: 0,
                  price: 315879.69,
                  price_per_m2: 779.23,
                  size: 408.97
                },
                median: {
                  excluded_lots: 0,
                  price: 303000,
                  price_per_m2: 798.74,
                  size: 392.5
                }
              },
              '2018-12-01': {
                average: {
                  excluded_lots: 0,
                  price: 310663.16,
                  price_per_m2: 803.21,
                  size: 388.32
                },
                median: {
                  excluded_lots: 0,
                  price: 317000,
                  price_per_m2: 807.71,
                  size: 400
                }
              },
              '2019-01-01': {
                average: {
                  excluded_lots: 0,
                  price: 326261.11,
                  price_per_m2: 809.51,
                  size: 410.94
                },
                median: {
                  excluded_lots: 0,
                  price: 305750,
                  price_per_m2: 812.59,
                  size: 374
                }
              },
              '2019-02-01': {
                average: {
                  excluded_lots: 0,
                  price: 325930.3,
                  price_per_m2: 756.03,
                  size: 437.58
                },
                median: {
                  excluded_lots: 0,
                  price: 330000,
                  price_per_m2: 746.43,
                  size: 448
                }
              }
            }
          },
          quarterly: {
            traditional: {
              'Q2 2017': {
                average: {
                  excluded_lots: 0,
                  price: 274032.16,
                  price_per_m2: 623.6,
                  size: 444.8
                },
                median: {
                  excluded_lots: 0,
                  price: 269200,
                  price_per_m2: 622.99,
                  size: 440
                }
              },
              'Q3 2017': {
                average: {
                  excluded_lots: 0,
                  price: 293770.37,
                  price_per_m2: 671.76,
                  size: 444.09
                },
                median: {
                  excluded_lots: 0,
                  price: 288100,
                  price_per_m2: 682.81,
                  size: 422
                }
              },
              'Q4 2017': {
                average: {
                  excluded_lots: 0,
                  price: 310284.03,
                  price_per_m2: 716.3,
                  size: 438.42
                },
                median: {
                  excluded_lots: 0,
                  price: 304000,
                  price_per_m2: 718.75,
                  size: 420.5
                }
              },
              'Q1 2018': {
                average: {
                  excluded_lots: 0,
                  price: 313026.49,
                  price_per_m2: 746.62,
                  size: 426.99
                },
                median: {
                  excluded_lots: 0,
                  price: 303000,
                  price_per_m2: 756.04,
                  size: 399
                }
              },
              'Q2 2018': {
                average: {
                  excluded_lots: 0,
                  price: 318808.24,
                  price_per_m2: 757.87,
                  size: 424.72
                },
                median: {
                  excluded_lots: 0,
                  price: 315000,
                  price_per_m2: 758.68,
                  size: 406
                }
              },
              'Q3 2018': {
                average: {
                  excluded_lots: 0,
                  price: 321885.8,
                  price_per_m2: 764.82,
                  size: 426.43
                },
                median: {
                  excluded_lots: 0,
                  price: 312000,
                  price_per_m2: 767.5,
                  size: 400
                }
              },
              'Q4 2018': {
                average: {
                  excluded_lots: 0,
                  price: 320022.15,
                  price_per_m2: 792.51,
                  size: 406.43
                },
                median: {
                  excluded_lots: 0,
                  price: 313400,
                  price_per_m2: 800.83,
                  size: 400
                }
              },
              'Q1 2019': {
                average: {
                  excluded_lots: 0,
                  price: 333795.74,
                  price_per_m2: 768.91,
                  size: 440.91
                },
                median: {
                  excluded_lots: 0,
                  price: 330000,
                  price_per_m2: 775,
                  size: 448
                }
              },
              'Q1 2017': {
                average: {
                  excluded_lots: 0,
                  price: 266011.94,
                  price_per_m2: 573.93,
                  size: 472.82
                },
                median: {
                  excluded_lots: 0,
                  price: 257000,
                  price_per_m2: 566.99,
                  size: 448
                }
              }
            },
            small: {
              'Q2 2017': {
                average: {
                  excluded_lots: 0,
                  price: 174218.18,
                  price_per_m2: 685.6,
                  size: 255.13
                },
                median: {
                  excluded_lots: 0,
                  price: 174000,
                  price_per_m2: 668.07,
                  size: 252
                }
              },
              'Q3 2017': {
                average: {
                  excluded_lots: 0,
                  price: 191851.35,
                  price_per_m2: 748.01,
                  size: 257.86
                },
                median: {
                  excluded_lots: 0,
                  price: 190000,
                  price_per_m2: 751.6,
                  size: 262
                }
              },
              'Q4 2017': {
                average: {
                  excluded_lots: 0,
                  price: 204213.17,
                  price_per_m2: 816.09,
                  size: 252.51
                },
                median: {
                  excluded_lots: 0,
                  price: 204000,
                  price_per_m2: 813.64,
                  size: 261
                }
              },
              'Q1 2018': {
                average: {
                  excluded_lots: 0,
                  price: 217742.11,
                  price_per_m2: 825.11,
                  size: 264.92
                },
                median: {
                  excluded_lots: 0,
                  price: 219000,
                  price_per_m2: 828.24,
                  size: 263
                }
              },
              'Q2 2018': {
                average: {
                  excluded_lots: 0,
                  price: 219250,
                  price_per_m2: 858.09,
                  size: 256.76
                },
                median: {
                  excluded_lots: 0,
                  price: 217000,
                  price_per_m2: 857.14,
                  size: 262
                }
              },
              'Q3 2018': {
                average: {
                  excluded_lots: 0,
                  price: 222056.6,
                  price_per_m2: 870.94,
                  size: 256.13
                },
                median: {
                  excluded_lots: 0,
                  price: 217000,
                  price_per_m2: 876.07,
                  size: 256
                }
              },
              'Q4 2018': {
                average: {
                  excluded_lots: 0,
                  price: 209800,
                  price_per_m2: 870.97,
                  size: 242
                },
                median: {
                  excluded_lots: 0,
                  price: 209000,
                  price_per_m2: 840.16,
                  size: 244
                }
              },
              'Q1 2019': {
                average: {
                  excluded_lots: 0,
                  price: 235000,
                  price_per_m2: 845.33,
                  size: 278.5
                },
                median: {
                  excluded_lots: 0,
                  price: 235000,
                  price_per_m2: 845.33,
                  size: 278.5
                }
              },
              'Q1 2017': {
                average: {
                  excluded_lots: 0,
                  price: 156235.29,
                  price_per_m2: 665.38,
                  size: 239.59
                },
                median: {
                  excluded_lots: 0,
                  price: 162000,
                  price_per_m2: 634.92,
                  size: 252
                }
              }
            },
            all: {
              'Q2 2017': {
                average: {
                  excluded_lots: 0,
                  price: 257790.24,
                  price_per_m2: 633.69,
                  size: 413.93
                },
                median: {
                  excluded_lots: 0,
                  price: 263500,
                  price_per_m2: 630.41,
                  size: 400
                }
              },
              'Q3 2017': {
                average: {
                  excluded_lots: 0,
                  price: 278865.22,
                  price_per_m2: 682.91,
                  size: 416.86
                },
                median: {
                  excluded_lots: 0,
                  price: 283900,
                  price_per_m2: 687.5,
                  size: 400
                }
              },
              'Q4 2017': {
                average: {
                  excluded_lots: 0,
                  price: 279261.56,
                  price_per_m2: 745.49,
                  size: 384.05
                },
                median: {
                  excluded_lots: 0,
                  price: 286000,
                  price_per_m2: 739.93,
                  size: 392
                }
              },
              'Q1 2018': {
                average: {
                  excluded_lots: 0,
                  price: 288089.81,
                  price_per_m2: 767.16,
                  size: 384.57
                },
                median: {
                  excluded_lots: 0,
                  price: 279800,
                  price_per_m2: 770.51,
                  size: 356
                }
              },
              'Q2 2018': {
                average: {
                  excluded_lots: 0,
                  price: 294525.74,
                  price_per_m2: 782.31,
                  size: 383.75
                },
                median: {
                  excluded_lots: 0,
                  price: 302000,
                  price_per_m2: 776.62,
                  size: 392
                }
              },
              'Q3 2018': {
                average: {
                  excluded_lots: 0,
                  price: 298781.22,
                  price_per_m2: 789.38,
                  size: 387.02
                },
                median: {
                  excluded_lots: 0,
                  price: 305000,
                  price_per_m2: 775.51,
                  size: 393
                }
              },
              'Q4 2018': {
                average: {
                  excluded_lots: 0,
                  price: 313461.31,
                  price_per_m2: 797.18,
                  size: 396.64
                },
                median: {
                  excluded_lots: 0,
                  price: 309150,
                  price_per_m2: 803.42,
                  size: 400
                }
              },
              'Q1 2019': {
                average: {
                  excluded_lots: 0,
                  price: 326047.06,
                  price_per_m2: 774.9,
                  size: 428.18
                },
                median: {
                  excluded_lots: 0,
                  price: 325000,
                  price_per_m2: 775.75,
                  size: 423
                }
              },
              'Q1 2017': {
                average: {
                  excluded_lots: 0,
                  price: 243795.24,
                  price_per_m2: 592.44,
                  size: 425.62
                },
                median: {
                  excluded_lots: 0,
                  price: 236000,
                  price_per_m2: 587.26,
                  size: 402
                }
              }
            }
          }
        }
      });
    }
  });
};

export const fetchPerformanceOverviewSummaryMock = (): Promise<{
  data: PerformanceOverviewSummary;
}> => {
  return new Promise((resolve) => {
    resolve({
      data: {
        gross_sales: 33,
        gross_sales_titled: 0,
        gross_sales_untitled: 33,
        available_titled: 16,
        available_untitled: 206,
        outstanding_contracts: 0,
        net_sales: 26,
        back_on_market: 7,
        newly_released: 31,
        available: 222,
        average_sales: 1.4
      }
    });
  });
};

export const fetchPerformanceOverviewGraphMock = (): Promise<{
  data: PerformanceOverviewGraph;
}> => {
  return new Promise((resolve) => {
    resolve({
      data: {
        monthly: [
          {
            gross_sales: 10,
            gross_sales_untitled: 10,
            gross_sales_titled: 0,
            end_date: '2019-03-01'
          },
          {
            gross_sales: 19,
            gross_sales_untitled: 19,
            gross_sales_titled: 0,
            end_date: '2019-04-01'
          },
          {
            gross_sales: 23,
            gross_sales_untitled: 23,
            gross_sales_titled: 0,
            end_date: '2019-05-01'
          },
          {
            gross_sales: 24,
            gross_sales_untitled: 22,
            gross_sales_titled: 2,
            end_date: '2019-06-01'
          },
          {
            gross_sales: 39,
            gross_sales_untitled: 34,
            gross_sales_titled: 5,
            end_date: '2019-07-01'
          },
          {
            gross_sales: 39,
            gross_sales_untitled: 26,
            gross_sales_titled: 13,
            end_date: '2019-08-01'
          },
          {
            gross_sales: 39,
            gross_sales_untitled: 35,
            gross_sales_titled: 4,
            end_date: '2019-09-01'
          },
          {
            gross_sales: 46,
            gross_sales_untitled: 43,
            gross_sales_titled: 3,
            end_date: '2019-10-01'
          },
          {
            gross_sales: 65,
            gross_sales_untitled: 55,
            gross_sales_titled: 10,
            end_date: '2019-11-01'
          },
          {
            gross_sales: 32,
            gross_sales_untitled: 25,
            gross_sales_titled: 7,
            end_date: '2019-12-01'
          },
          {
            gross_sales: 42,
            gross_sales_untitled: 38,
            gross_sales_titled: 4,
            end_date: '2020-01-01'
          },
          {
            gross_sales: 26,
            gross_sales_untitled: 20,
            gross_sales_titled: 6,
            end_date: '2020-02-01'
          },
          {
            gross_sales: 75,
            gross_sales_untitled: 55,
            gross_sales_titled: 20,
            end_date: '2020-03-01'
          },
          {
            gross_sales: 33,
            gross_sales_untitled: 23,
            gross_sales_titled: 10,
            end_date: '2020-04-01'
          },
          {
            gross_sales: 62,
            gross_sales_untitled: 53,
            gross_sales_titled: 9,
            end_date: '2020-05-01'
          },
          {
            gross_sales: 94,
            gross_sales_untitled: 60,
            gross_sales_titled: 34,
            end_date: '2020-06-01'
          },
          {
            gross_sales: 140,
            gross_sales_untitled: 76,
            gross_sales_titled: 64,
            end_date: '2020-07-01'
          },
          {
            gross_sales: 84,
            gross_sales_untitled: 56,
            gross_sales_titled: 28,
            end_date: '2020-08-01'
          },
          {
            gross_sales: 104,
            gross_sales_untitled: 56,
            gross_sales_titled: 48,
            end_date: '2020-09-01'
          },
          {
            gross_sales: 159,
            gross_sales_untitled: 108,
            gross_sales_titled: 51,
            end_date: '2020-10-01'
          },
          {
            gross_sales: 156,
            gross_sales_untitled: 108,
            gross_sales_titled: 48,
            end_date: '2020-11-01'
          },
          {
            gross_sales: 148,
            gross_sales_untitled: 136,
            gross_sales_titled: 12,
            end_date: '2020-12-01'
          },
          {
            gross_sales: 173,
            gross_sales_untitled: 163,
            gross_sales_titled: 10,
            end_date: '2021-01-01'
          },
          {
            gross_sales: 155,
            gross_sales_untitled: 144,
            gross_sales_titled: 11,
            end_date: '2021-02-01'
          }
        ],
        quarterly: [
          {
            quarter: 'Q2 2019',
            gross_sales: 66,
            gross_sales_titled: 2,
            gross_sales_untitled: 64
          },
          {
            quarter: 'Q3 2019',
            gross_sales: 117,
            gross_sales_titled: 22,
            gross_sales_untitled: 95
          },
          {
            quarter: 'Q4 2019',
            gross_sales: 143,
            gross_sales_titled: 20,
            gross_sales_untitled: 123
          },
          {
            quarter: 'Q1 2020',
            gross_sales: 143,
            gross_sales_titled: 30,
            gross_sales_untitled: 113
          },
          {
            quarter: 'Q2 2020',
            gross_sales: 189,
            gross_sales_titled: 53,
            gross_sales_untitled: 136
          },
          {
            quarter: 'Q3 2020',
            gross_sales: 328,
            gross_sales_titled: 140,
            gross_sales_untitled: 188
          },
          {
            quarter: 'Q4 2020',
            gross_sales: 463,
            gross_sales_titled: 111,
            gross_sales_untitled: 352
          },
          {
            quarter: 'Q1 2021',
            gross_sales: 328,
            gross_sales_titled: 21,
            gross_sales_untitled: 307
          }
        ]
      }
    });
  });
};

export const fetchSalesPerformanceMock = (): Promise<{
  data: SalesPerformanceResponse;
}> => {
  return new Promise((resolve) => {
    resolve({
      data: {
        gross: [
          { name: 'Project 1', percentage: 54.55 },
          { name: 'Project 2', percentage: 21.21 },
          { name: 'Project 3', percentage: 9.09 },
          { name: 'Project 4 ', percentage: 9.09 },
          { name: 'Project 5', percentage: 3.03 },
          { name: 'Project 6', percentage: 3.03 },
          { name: 'Project 7', percentage: 0 },
          { name: 'Project 8', percentage: 0 },
          { name: 'Project 9', percentage: 0 },
          { name: 'Project 10', percentage: 0 },
          { name: 'Project 11', percentage: 0 },
          { name: 'Project 12', percentage: 0 },
          { name: 'Project 13', percentage: 0 },
          { name: 'Project 14', percentage: 0 },
          { name: 'Project 15', percentage: 0 },
          { name: 'Project 16', percentage: 0 },
          { name: 'Project 17', percentage: 0 },
          { name: 'Project 18', percentage: 0 },
          { name: 'Project 19', percentage: 0 },
          { name: 'Project 20', percentage: 0 },
          { name: 'Project 21', percentage: 0 },
          { name: 'Project 22', percentage: 0 },
          { name: 'Project 23', percentage: 0 }
        ],
        net: [
          { name: 'Project 1', percentage: 69.23 },
          { name: 'Project 2', percentage: 26.92 },
          { name: 'Project 3', percentage: 11.54 },
          { name: 'Project 4 ', percentage: 11.54 },
          { name: 'Project 7', percentage: 0 },
          { name: 'Project 8', percentage: 0 },
          { name: 'Project 9', percentage: 0 },
          { name: 'Project 10', percentage: 0 },
          { name: 'Project 11', percentage: 0 },
          { name: 'Project 12', percentage: 0 },
          { name: 'Project 13', percentage: 0 },
          { name: 'Project 14', percentage: 0 },
          { name: 'Project 15', percentage: 0 },
          { name: 'Project 16', percentage: 0 },
          { name: 'Project 17', percentage: 0 },
          { name: 'Project 18', percentage: 0 },
          { name: 'Project 19', percentage: 0 },
          { name: 'Project 20', percentage: 0 },
          { name: 'Project 21', percentage: 0 },
          { name: 'Project 22', percentage: 0 },
          { name: 'Project 23', percentage: 0 },
          { name: 'Project 6', percentage: -3.85 },
          { name: 'Project 5', percentage: -15.38 }
        ]
      }
    });
  });
};
