import settingsModel from 'data/models/entities/settings';
import { query } from '@rexlabs/model-generator';
import periodsModel from 'data/models/entities/periods';
import settingProjects from 'data/models/entities/setting-projects';
import { getSettingsId, getToken, getPeriodQuery } from 'utils/reports';

export const getSettingsQuery = (id, token) => query`{
  ${settingsModel} (id: ${id}, token: ${token}) {
    id
    name
    hidden
  }
}`;

export const periodsQuery = query`{
  ${periodsModel} (q: ${getPeriodQuery}, token: ${getToken}) {
    id
    end_date
  }
}`;

export const projectsQuery = query`{
  ${settingProjects} (settingId: ${getSettingsId}, token: ${getToken}) {
    id
    title
    active
    developer
    sales_office_address
    sales_agency
    local_government_area
    total_area
    net_developable_area
    suburb
    postcode
    estimated_start_date
    estimated_completion_date
    total_area
    status
    created_at
    stats
    settings
    sales_volume_offset
    estimated_lots
    precinct_structure_plan
  }
}`;
