import React, { Fragment, Component } from 'react';
import _ from 'lodash';

import Box from '@rexlabs/box';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { Grid, Column } from '@rexlabs/grid';
import { TextInput } from '@rexlabs/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { push } from '@rexlabs/whereabouts';
import { withModel, withQuery, query } from '@rexlabs/model-generator';

import AlertModal from 'view/modals/alert';
import withError from 'view/containers/with-error';
import ButtonBar from 'view/components/modal/button-bar';
import DayPicker from 'view/components/calendar/day';
import withErrorList from 'view/containers/with-error-list';
import { SubHeading, Body, Heading } from 'view/components/text';
import { PrimaryButton, TextButton } from 'view/components/button';
import { Form, FormField, ReactForms } from 'view/components/form';
import { SelectInput } from 'view/components/input/select';
import { DollarInput } from 'view/components/input/text';
import { Check as Checkbox } from 'view/components/checkbox';
import projectsModel from 'data/models/entities/projects';
import regionsModel from 'data/models/entities/regions';
import sessionsModel from 'data/models/custom/session';

import { createValidationRules } from 'utils/form';
import {
  formatNumber,
  stripNumber,
  cleanNumbers,
  getMomentOrNull
} from 'utils/format';

import { COLORS, PADDINGS } from 'src/theme';
import ROUTES from 'src/routes';
import { inArray } from 'src/utils/array';
import { Tiny } from '../components/text';

const defaultStyles = StyleSheet({
  title: {
    color: COLORS.GREY.DARK
  },
  section: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`,
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`,
    ':last-child': {
      borderBottom: 'none'
    }
  }
});

const regionsQuery = query`{
  ${regionsModel} {
    id
    name
  } 
}`;

const projectTypeOptions = [
  {
    value: null,
    label: '(Empty)'
  },
  {
    value: 'Test Project',
    label: 'Test Project'
  },
  {
    value: 'Privately Held',
    label: 'Privately Held'
  },
  {
    value: 'Developer Held',
    label: 'Developer Held'
  }
];

const regionOptions = [
  {
    label: 'West',
    value: 'West'
  },
  {
    label: 'North',
    value: 'North'
  },
  {
    label: 'South East',
    value: 'South East'
  },
  {
    label: 'Geelong',
    value: 'Geelong'
  },
  {
    label: 'Regional South West',
    value: 'Regional South West'
  },
  {
    label: 'Regional North West',
    value: 'Regional North West'
  },
  {
    label: 'Regional North East',
    value: 'Regional North East'
  },
  {
    label: 'Gippsland',
    value: 'Gippsland'
  },
  {
    label: 'Mornington Peninsula',
    value: 'Mornington Peninsula'
  }
];

const stateOptions = [
  {
    label: 'VIC',
    value: 'VIC'
  },
  {
    label: 'NSW',
    value: 'NSW'
  },
  {
    label: 'QLD',
    value: 'QLD'
  },
  {
    label: 'NT',
    value: 'NT'
  },
  {
    label: 'TAS',
    value: 'TAS'
  },
  {
    label: 'WA',
    value: 'WA'
  },
  {
    label: 'SA',
    value: 'SA'
  },
  {
    label: 'ACT',
    value: 'ACT'
  }
];

export const CheckboxField = ({ label, onClick, name, value }) => {
  return (
    <Box
      flex={1}
      alignItems={'center'}
      onClick={() => onClick(name)}
      style={{ cursor: 'pointer' }}
    >
      <Checkbox value={value} />
      <Tiny>{label}</Tiny>
    </Box>
  );
};

@withError.withPropName('errorModal')
@withModel(sessionsModel)
@withModel(projectsModel)
@withQuery(regionsQuery)
@withErrorList
@styled(defaultStyles)
class NewProjectForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlertModal: false,

      isZeroNetCarbonCertified: props.project?.is_zero_net_carbon_certified,
      isMinSevenStarNathers: props.project?.is_min_seven_star_nathers,
      isPassiveHome: props.project?.is_passive_home,
      isGreenstar: props.project?.is_greenstar,
      isEnviroDevelopment: props.project?.is_enviro_development
    };

    this.info = React.createRef();
    this.status = React.createRef();
    this.details = React.createRef();
    this.size = React.createRef();
    this.price = React.createRef();
  }

  handleSubmit = (values) => {
    const { projects, project } = this.props;

    const {
      isZeroNetCarbonCertified,
      isMinSevenStarNathers,
      isPassiveHome,
      isGreenstar,
      isEnviroDevelopment
    } = this.state;

    const isUpdate = !!project;

    const payload = {
      title: values.projectName,
      developer: values.developer || null,
      sales_office_address: values.address || null,
      suburb: values.suburb || null,
      sub_region_id: values.subRegion || null,
      postcode: values.postcode || null,
      estimated_start_date: values.startDate
        ? values.startDate.format('YYYY-MM-DD')
        : null,
      estimated_completion_date: values.endDate
        ? values.endDate.format('YYYY-MM-DD')
        : null,
      status: values.status,
      local_government_area: values.localGovernment || null,
      sales_agency: values.salesAgency || null,
      precinct_structure_plan: values.precinctStructurePlan || null,
      estimated_lots: stripNumber(values.numAllotments) || null,
      sales_volume_offset: stripNumber(values.salesVolumeOffset) || 0,
      total_area: stripNumber(values.totalArea) || null,
      net_developable_area: stripNumber(values.netDevelopableArea) || null,
      expected_lot_size_min: stripNumber(values.expectedSizeRangeMin) || null,
      expected_lot_size_max: stripNumber(values.expectedSizeRangeMax) || null,
      expected_price_range_min:
        stripNumber(values.expectedPriceRangeMin) || null,
      expected_price_range_max:
        stripNumber(values.expectedPriceRangeMax) || null,
      summary: values.summary || null,
      type: values.type || null,
      region: values.region || null,
      state: values.state || null,

      is_min_seven_star_nathers: isMinSevenStarNathers || false,
      is_zero_net_carbon_certified: isZeroNetCarbonCertified || false,
      is_passive_home: isPassiveHome || false,
      is_greenstar: isGreenstar || false,
      is_enviro_development: isEnviroDevelopment || false
    };

    const promise = isUpdate
      ? projects.updateItem({
          id: project.id,
          data: payload
        })
      : projects.createItem({
          data: payload
        });

    return promise
      .then((response) => {
        if (isUpdate) {
          this.props.closeModal();
        } else {
          push(ROUTES.APP.PROJECT, {
            params: {
              projectId: response.data.id
            }
          });
        }
      })
      .catch((e) => {
        this.props.errorModal.open(e);
      });
  };

  validate = (values, props) => {
    values = cleanNumbers(values, [
      'expectedPriceRangeMin',
      'expectedPriceRangeMax',
      'expectedListPriceMin',
      'expectedListPriceMax',
      'totalArea',
      'netDevelopableArea',
      'expectedSizeRangeMin',
      'expectedSizeRangeMax',
      'salesVolumeOffset',
      'numAllotments'
    ]);

    // Run the validation
    const validation = createValidationRules({
      projectName: 'required|min:3|max:100',
      developer: 'string',
      address: 'string',
      suburb: 'string',
      postcode: 'regex:/^\\d{4}$/',
      subRegion: 'integer',
      startDate: inArray(['on_market', 'on_hold', 'completed'], values.status)
        ? 'required|date'
        : 'date',
      endDate: 'date',
      status: 'required',
      localGovernment: 'string',
      precinctStructurePlan: 'string',
      salesAgency: 'string',
      numAllotments: 'required|integer|min:1',
      salesVolumeOffset: 'integer',
      totalArea: 'integer|min:0',
      netDevelopableArea: 'integer|min:0',
      expectedSizeRangeMin: 'integer|min:1',
      expectedSizeRangeMax: 'integer|min:1',
      expectedPriceRangeMin: 'integer|min:1',
      expectedPriceRangeMax: 'integer|min:1',
      expectedListPriceMin: 'integer|min:1',
      expectedListPriceMax: 'integer|min:1',
      summary: 'string'
    })(values, props);

    // Scroll to error
    const first = _.head(Object.keys(validation));
    const info = ['projectName', 'developer', 'address', 'suburb', 'postcode'];

    const status = ['startData', 'endDate', 'status'];

    const details = ['localGovernment', 'recinctStructurePlan', 'salesAgency'];

    const size = [
      'totalArea',
      'netDevelopableArea',
      'expectedSizeRangeMin',
      'expectedSizeRangeMax',
      'salesVolumeOffset',
      'numAllotments'
    ];

    const price = [
      'expectedPriceRangeMin',
      'expectedPriceRangeMax',
      'expectedListPriceMin',
      'expectedListPriceMax'
    ];

    if (first) {
      if (info.indexOf(first) !== -1) {
        this.info.current.scrollIntoView();
      }

      if (status.indexOf(first) !== -1) {
        this.status.current.scrollIntoView();
      }

      if (details.indexOf(first) !== -1) {
        this.details.current.scrollIntoView();
      }

      if (size.indexOf(first) !== -1) {
        this.size.current.scrollIntoView();
      }

      if (price.indexOf(first) !== -1) {
        this.price.current.scrollIntoView();
      }
    }

    if (_.values(validation).length > 0) {
      this.props.errorList.open({
        title: 'Validation Errors',
        items: _.values(validation),
        isAction: false
      });
    }

    return validation;
  };

  toggleField = (field) => {
    this.setState({ [field]: !this.state[field] });
  };

  render() {
    const {
      styles: s,
      title,
      project,
      regions,
      errorModal: { Error }
    } = this.props;
    const isUpdate = !!project;

    if (_.get(regions, 'list.status') === 'loading') {
      return (
        <Box
          flex={1}
          alignItems="center"
          justifyContent="center"
          p={PADDINGS.M}
          style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        >
          <Fragment>
            <LoadingSpinner />
            <Box style={{ marginLeft: PADDINGS.XS }}>
              <Body>Loading...</Body>
            </Box>
          </Fragment>
        </Box>
      );
    }

    return (
      <Box>
        <ReactForms
          handleSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {(props) => {
            const { isSubmitting, setFieldValue, submitForm } = props;

            return (
              <Form name="new-project">
                <Box {...s('section')}>
                  <Heading>{title}</Heading>
                  <Grid columns={12}>
                    <div ref={this.info} />
                    <Column width={6}>
                      <FormField
                        label="Project Name"
                        name="projectName"
                        initialValue={_.get(project, 'title', '')}
                        Input={TextInput}
                      />
                    </Column>
                    <Column width={6}>
                      <FormField
                        label="Developer"
                        name="developer"
                        initialValue={_.get(project, 'developer', '')}
                        Input={TextInput}
                      />
                    </Column>
                  </Grid>
                  <Grid>
                    <Column width={12}>
                      <FormField
                        label="Project Sales Office Address"
                        name="address"
                        initialValue={_.get(
                          project,
                          'sales_office_address',
                          ''
                        )}
                        Input={TextInput}
                      />
                    </Column>
                  </Grid>
                  <Grid columns={12}>
                    <Column width={6}>
                      <FormField
                        label="Suburb"
                        name="suburb"
                        Input={TextInput}
                        initialValue={_.get(project, 'suburb', '')}
                      />
                    </Column>
                    <Column width={6}>
                      <FormField
                        label="Postcode"
                        name="postcode"
                        Input={TextInput}
                        initialValue={_.get(project, 'postcode')}
                      />
                    </Column>
                  </Grid>
                  <Grid columns={12}>
                    <Column width={6}>
                      <FormField
                        name="subRegion"
                        label="Sub-Region"
                        Input={SelectInput}
                        initialValue={_.get(project, 'sub_region_id', '')}
                        inputProps={{
                          options: _.map(
                            _.get(regions, 'list.items', []),
                            (sub_region) => ({
                              value: sub_region.id,
                              label: sub_region.name
                            })
                          )
                        }}
                      />
                    </Column>
                    <Column width={3}>
                      <FormField
                        name="region"
                        label="Region"
                        Input={SelectInput}
                        initialValue={_.get(project, 'region', '')}
                        inputProps={{
                          options: regionOptions
                        }}
                      />
                    </Column>
                    <Column width={3}>
                      <FormField
                        name="state"
                        label="State"
                        Input={SelectInput}
                        initialValue={_.get(project, 'state', '')}
                        inputProps={{
                          options: stateOptions
                        }}
                      />
                    </Column>
                  </Grid>
                </Box>

                <Box {...s('section')}>
                  <div ref={this.status} />
                  <SubHeading {...s('title')}>Status</SubHeading>
                  <Grid>
                    <Column width={3}>
                      <FormField
                        label="Start Date"
                        name="startDate"
                        Input={DayPicker}
                        initialValue={getMomentOrNull(
                          _.get(project, 'estimated_start_date')
                        )}
                        inputProps={{
                          initialVisibleMonth: () =>
                            getMomentOrNull(
                              _.get(project, 'estimated_start_date')
                            )
                        }}
                      />
                    </Column>
                    <Column width={3}>
                      <FormField
                        label="Completion Date"
                        name="endDate"
                        initialValue={getMomentOrNull(
                          _.get(project, 'estimated_completion_date')
                        )}
                        inputProps={{
                          initialVisibleMonth: () =>
                            getMomentOrNull(
                              _.get(project, 'estimated_completion_date')
                            )
                        }}
                        Input={DayPicker}
                      />
                    </Column>
                    <Column width={6}>
                      <FormField
                        label="Project Status"
                        name="status"
                        Input={SelectInput}
                        initialValue={_.get(project, 'status', '')}
                        inputProps={{
                          options: [
                            {
                              value: 'pre_launch',
                              label: 'Pre Launch'
                            },
                            {
                              value: 'on_market',
                              label: 'On Market'
                            },
                            {
                              value: 'on_hold',
                              label: 'On Hold'
                            },
                            {
                              value: 'completed',
                              label: 'Completed'
                            }
                          ]
                        }}
                        useSlowField
                      />
                    </Column>
                  </Grid>
                  <Grid>
                    <Column width={3}>
                      <FormField
                        sendImmediate
                        label="Project Type"
                        name="type"
                        initialValue={_.get(project, 'type', '')}
                        Input={SelectInput}
                        inputProps={{
                          options: projectTypeOptions
                        }}
                      />
                    </Column>
                  </Grid>
                </Box>

                <Box {...s('section')}>
                  <div ref={this.details} />
                  <SubHeading {...s('title')}>Details</SubHeading>
                  <Grid columns={12}>
                    <Column width={6}>
                      <FormField
                        label="Local Government Area"
                        name="localGovernment"
                        Input={TextInput}
                        initialValue={_.get(
                          project,
                          'local_government_area',
                          ''
                        )}
                      />
                    </Column>
                    <Column width={6}>
                      <FormField
                        label="Sales Agency"
                        name="salesAgency"
                        initialValue={_.get(project, 'sales_agency', '')}
                        Input={TextInput}
                      />
                    </Column>
                  </Grid>
                  <Grid columns={12}>
                    <Column width={6}>
                      <FormField
                        label="Precinct Structure Plan"
                        name="precinctStructurePlan"
                        initialValue={_.get(
                          project,
                          'precinct_structure_plan',
                          ''
                        )}
                        Input={TextInput}
                      />
                    </Column>
                    <Column width={6} />
                  </Grid>
                </Box>

                <Box {...s('section')}>
                  <div ref={this.size} />
                  <SubHeading {...s('title')}>Size</SubHeading>
                  <Grid>
                    <Column width={3}>
                      <FormField
                        label="Total Area (ha)"
                        name="totalArea"
                        Input={TextInput}
                        initialValue={formatNumber(
                          _.get(project, 'settings.total_area', '')
                        )}
                        onFocus={(e) => {
                          setFieldValue(
                            'totalArea',
                            stripNumber(e.target.value),
                            false
                          );
                        }}
                        onBlur={(e) => {
                          setFieldValue(
                            'totalArea',
                            formatNumber(e.target.value),
                            false
                          );
                        }}
                      />
                    </Column>
                    <Column width={3}>
                      <FormField
                        label="Net Developable Area (ha)"
                        name="netDevelopableArea"
                        Input={TextInput}
                        initialValue={formatNumber(
                          _.get(project, 'settings.net_developable_area', '')
                        )}
                        onFocus={(e) => {
                          setFieldValue(
                            'netDevelopableArea',
                            stripNumber(e.target.value),
                            false
                          );
                        }}
                        onBlur={(e) => {
                          setFieldValue(
                            'netDevelopableArea',
                            formatNumber(e.target.value),
                            false
                          );
                        }}
                      />
                    </Column>
                    <Column width={3}>
                      <FormField
                        label="Exp. Size Range Min (m2)"
                        name="expectedSizeRangeMin"
                        Input={TextInput}
                        initialValue={formatNumber(
                          _.get(
                            project,
                            'settings.expected_lot_size_min',
                            '200'
                          )
                        )}
                        onFocus={(e) => {
                          setFieldValue(
                            'expectedSizeRangeMin',
                            stripNumber(e.target.value),
                            false
                          );
                        }}
                        onBlur={(e) => {
                          setFieldValue(
                            'expectedSizeRangeMin',
                            formatNumber(e.target.value),
                            false
                          );
                        }}
                      />
                    </Column>
                    <Column width={3}>
                      <FormField
                        label="Exp. Size Range Max (m2)"
                        name="expectedSizeRangeMax"
                        Input={TextInput}
                        initialValue={formatNumber(
                          _.get(
                            project,
                            'settings.expected_lot_size_max',
                            '700'
                          )
                        )}
                        onFocus={(e) => {
                          setFieldValue(
                            'expectedSizeRangeMax',
                            stripNumber(e.target.value),
                            false
                          );
                        }}
                        onBlur={(e) => {
                          setFieldValue(
                            'expectedSizeRangeMax',
                            formatNumber(e.target.value),
                            false
                          );
                        }}
                      />
                    </Column>
                  </Grid>
                  <Grid>
                    <Column width={3}>
                      <FormField
                        label="Est. Total # Allotments"
                        name="numAllotments"
                        Input={TextInput}
                        initialValue={formatNumber(
                          _.get(project, 'estimated_lots', '')
                        )}
                        onFocus={(e) => {
                          setFieldValue(
                            'numAllotments',
                            stripNumber(e.target.value),
                            false
                          );
                        }}
                        onBlur={(e) => {
                          setFieldValue(
                            'numAllotments',
                            formatNumber(e.target.value),
                            false
                          );
                        }}
                      />
                    </Column>
                    <Column width={3}>
                      <FormField
                        label="Sales Volume Offset"
                        name="salesVolumeOffset"
                        Input={TextInput}
                        initialValue={formatNumber(
                          _.get(project, 'sales_volume_offset', '')
                        )}
                        onFocus={(e) => {
                          setFieldValue(
                            'salesVolumeOffset',
                            stripNumber(e.target.value),
                            false
                          );
                        }}
                        onBlur={(e) => {
                          setFieldValue(
                            'salesVolumeOffset',
                            formatNumber(e.target.value),
                            false
                          );
                        }}
                      />
                    </Column>
                  </Grid>
                  <Column width={6} />
                </Box>

                <Box {...s('section')}>
                  <SubHeading grey>ESD</SubHeading>

                  <Grid columns={12}>
                    <Column width={12}>
                      <CheckboxField
                        name="isMinSevenStarNathers"
                        label="Minimum 7 star NatHERS energy rating"
                        onClick={this.toggleField}
                        value={this.state['isMinSevenStarNathers']}
                      />

                      <CheckboxField
                        name="isZeroNetCarbonCertified"
                        label="Zero Net Carbon Certification"
                        onClick={this.toggleField}
                        value={this.state['isZeroNetCarbonCertified']}
                      />

                      <CheckboxField
                        name="isPassiveHome"
                        label="Passive Home"
                        onClick={this.toggleField}
                        value={this.state['isPassiveHome']}
                      />

                      <CheckboxField
                        name="isGreenstar"
                        label="Greenstar"
                        onClick={this.toggleField}
                        value={this.state['isGreenstar']}
                      />

                      <CheckboxField
                        name="isEnviroDevelopment"
                        label="Envirodevelopment"
                        onClick={this.toggleField}
                        value={this.state['isEnviroDevelopment']}
                      />
                    </Column>
                  </Grid>
                </Box>

                <Box {...s('section')}>
                  <div ref={this.price} />
                  <SubHeading {...s('title')}>Price</SubHeading>
                  <Grid>
                    <Column width={3}>
                      <FormField
                        label="Exp. Price Range Min"
                        name="expectedPriceRangeMin"
                        Input={DollarInput}
                        initialValue={formatNumber(
                          _.get(
                            project,
                            'settings.expected_price_range_min',
                            '200000'
                          )
                        )}
                        onFocus={(e) => {
                          setFieldValue(
                            'expectedPriceRangeMin',
                            stripNumber(e.target.value),
                            false
                          );
                        }}
                        onBlur={(e) => {
                          setFieldValue(
                            'expectedPriceRangeMin',
                            formatNumber(e.target.value),
                            false
                          );
                        }}
                      />
                    </Column>
                    <Column width={3}>
                      <FormField
                        label="Exp. Price Range Max"
                        name="expectedPriceRangeMax"
                        Input={DollarInput}
                        initialValue={formatNumber(
                          _.get(
                            project,
                            'settings.expected_price_range_max',
                            '600000'
                          )
                        )}
                        onFocus={(e) => {
                          setFieldValue(
                            'expectedPriceRangeMax',
                            stripNumber(e.target.value),
                            false
                          );
                        }}
                        onBlur={(e) => {
                          setFieldValue(
                            'expectedPriceRangeMax',
                            formatNumber(e.target.value),
                            false
                          );
                        }}
                      />
                    </Column>
                    <Column width={6} />
                  </Grid>
                </Box>

                <Box {...s('section')}>
                  <FormField
                    label="Summary"
                    name="summary"
                    Input={TextInput}
                    initialValue={_.get(project, 'summary', '')}
                  />
                </Box>

                <ButtonBar>
                  <TextButton
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.closeModal();
                    }}
                  >
                    Cancel
                  </TextButton>
                  <PrimaryButton
                    green
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}
                  >
                    {isUpdate ? 'Save' : 'Create'}
                  </PrimaryButton>
                </ButtonBar>
              </Form>
            );
          }}
        </ReactForms>
        {this.state.showAlertModal && (
          <AlertModal
            title={'Error'}
            onClose={() => this.setState({ showAlertModal: false })}
          >
            <span>
              You have entered this allotment on the worksheet already
            </span>
          </AlertModal>
        )}
        <Error />
      </Box>
    );
  }
}

export default NewProjectForm;
