import React, { PureComponent } from 'react';

import Box from '@rexlabs/box';
import { Link } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';

import ROUTES from 'src/routes';
import { COLORS, FONT, PADDINGS } from 'src/theme';
import { Auth0Context } from 'src/auth0-provider';

import withPermission from 'view/containers/with-permissions';
import ExportReport from 'view/modals/export-report';
import { PrimaryButton } from 'view/components/button';

import sessionModel from 'data/models/custom/session';

const defaultStyles = StyleSheet({
  container: {
    height: '100%'
  },

  wrapLogo: {
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },

  fullWidth: {
    width: '100%'
  },

  menu: {
    '& a': {
      color: COLORS.GREY,
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      paddingBottom: PADDINGS.XXS,
      width: '100%',
      display: 'flex',
      fontSize: '1.8rem',
      textDecoration: 'none'
    },

    '& a:hover, & a.active': {
      color: COLORS.BLUE_GREEN
    },

    '& li ul': {
      paddingLeft: PADDINGS.XS
    },

    '& li li a': {
      fontWeight: FONT.WEIGHTS.NORMAL
    }
  },

  exportLink: {
    cursor: 'pointer'
  }
});

@withPermission()
@withModel(sessionModel)
@styled(defaultStyles)
class Sidebar extends PureComponent {
  state = {
    isExportModalOpen: false
  };

  static contextType = Auth0Context;

  render() {
    const { styles: s, session, can } = this.props;
    const { isExportModalOpen } = this.state;

    return (
      <Box
        {...s('container')}
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <menu {...s('menu')}>
            <ul>
              <li>
                <Link
                  to={ROUTES.APP.PROJECTS_CURRENT}
                  activeMatches={['/app/projects/(.*)']}
                >
                  Projects
                </Link>
                <ul>
                  <li>
                    <Link to={ROUTES.APP.PROJECTS_CURRENT}>Current</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.APP.PROJECTS_ARCHIVED}>Archived</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.APP.PROJECTS_TRASHED}>Trashed</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={ROUTES.APP.REGIONS}>Sub-Regions</Link>
              </li>
              {/* No need for this screen. Keeping code here in case they change their mind. */}
              {/* <li>
                <Link to={ROUTES.APP.PERIODS}>Periods</Link>
              </li> */}
              {can('view:reports') && (
                <li>
                  <Link to={ROUTES.APP.REPORT}>Report</Link>
                </li>
              )}
              <li>
                <Link
                  target={null}
                  onClick={() => this.setState({ isExportModalOpen: true })}
                  {...s('exportLink')}
                >
                  Export
                </Link>
              </li>
              <li>
                <Link to={ROUTES.APP.CHANGE_LOG}>Change Log</Link>
              </li>
            </ul>
          </menu>
        </Box>
        <Box>
          <Box
            mb={PADDINGS.XXS}
            {...s('fullWidth')}
            onClick={session.logoutAccount}
          >
            <PrimaryButton {...s('fullWidth')} onClick={session.logoutAccount}>
              Switch Account
            </PrimaryButton>
          </Box>
          <Box
            {...s('fullWidth')}
            onClick={() => session.logout({ logout: this.context.logout })}
          >
            <PrimaryButton {...s('fullWidth')}>Logout</PrimaryButton>
          </Box>
        </Box>

        {isExportModalOpen && (
          <ExportReport
            onClose={() => this.setState({ isExportModalOpen: false })}
          />
        )}
      </Box>
    );
  }
}

export default Sidebar;
