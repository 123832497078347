import React, { SVGProps } from 'react';

const ArrowRight = (props: SVGProps<null>) => {
  return (
    <svg
      width="4"
      height="8"
      viewBox="0 0 4 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.667969 6.66668L3.33464 4.00001L0.667969 1.33334"
        stroke="#0B1E49"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
