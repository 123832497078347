import React, { HTMLProps } from 'react';
import Box from '@rexlabs/box';
import { styled, Styles, StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/features/reports/theme';

const styles = StyleSheet({
  container: {
    position: 'relative',
    border: `1px solid ${COLORS.BLUE.DARK}`,
    borderRadius: '4px'
  },
  label: {
    color: `${COLORS.BLUE.DARK}A6`,
    transition: 'all 0.15s ease-in-out',
    position: 'absolute',
    fontSize: '16px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    top: 7.5,
    left: 12,
    backgroundColor: COLORS.WHITE,
    padding: '0 4px',
    pointerEvents: 'none'
  },
  filled: {
    top: -10,
    fontSize: 12,
    fontWeight: 700,
    color: COLORS.BLUE.DARK
  },
  input: {
    padding: '0 16px',
    color: COLORS.BLUE.DARK,
    fontSize: '16px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    width: '100%',
    outline: 'none',
    border: 'none',
    '&:focus + label': {
      top: -10,
      fontSize: 12,
      fontWeight: 700,
      color: COLORS.BLUE.DARK
    }
  }
});

interface TextInputProps extends HTMLProps<HTMLInputElement> {
  styles: Styles;
}

const TextInput = ({ styles: s, placeholder, ...rest }: TextInputProps) => {
  return (
    <Box
      {...s('container')}
      width="100%"
      height="40px"
      p="0"
      flexDirection="row"
      alignItems="center"
    >
      <input {...s('input')} {...rest} />
      <label {...s('label', { filled: !!rest.value })}>{placeholder}</label>
    </Box>
  );
};

export default styled(styles)(TextInput);
