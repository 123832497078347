import React, { useMemo } from 'react';
import { animated } from '@react-spring/web';
import { CustomLayer } from '@nivo/line';
import { useAnimatedPath } from '@nivo/core';

// Line graph for the first set of data, that is why there is a condition index === 0
const Line: CustomLayer = ({ series, lineGenerator }) => {
  const path = useMemo(
    () =>
      series[0]
        ? lineGenerator(series[0].data.map((datum) => datum.position))
        : [],
    [lineGenerator, series]
  );
  const animatedPath = useAnimatedPath(path);

  return (
    <animated.path
      d={animatedPath}
      fill="none"
      strokeWidth={2}
      stroke={series[0] ? series[0].color : ''}
    />
  );
};

export default Line;
