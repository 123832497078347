import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      fetchList: (type, { projectId, ...args }) =>
        api.get(`/projects/${projectId}/worksheets`, args),
      fetchItem: (type, { projectId, ...args }, id) =>
        api.get(`/projects/${projectId}/worksheets/${id}`, args),
      createItem: (type, args) =>
        api.post(`/projects/${args.project_id}/worksheets`, args),
      trashItem: (type, { projectId, ...args }, id) =>
        api.delete(`/projects/${projectId}/worksheets/${id}`),
      updateItem: (type, { projectId, ...args }, id) =>
        api.put(`/projects/${projectId}/worksheets/${id}`, args)
    }
  }
};

const actionCreators = {
  fetchLastWorksheet: {
    request: ({ projectId, ...args }) =>
      api.get(`/projects/${projectId}/worksheets/last?include=period`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const WorkSheetsGenerator = new Generator('worksheets', config);
export default WorkSheetsGenerator.createEntityModel({ actionCreators });
