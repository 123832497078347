import React, { PureComponent } from 'react';
import Modal from 'view/components/modal/modal';
import types from 'prop-types';
import EditSettingsForm from 'view/forms/edit-setting';

class EditReportSettingsModal extends PureComponent {
  static propTypes = {
    onClose: types.func.isRequired,
    settingId: types.number.isRequired
  };

  render() {
    return (
      <Modal visibleOverflow>
        <EditSettingsForm {...this.props} />
      </Modal>
    );
  }
}

export default EditReportSettingsModal;
