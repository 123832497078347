import React, { PureComponent } from 'react';
import { Modal } from 'view/components/modal';
import EditPeriodForm from 'view/forms/period-edit';

class NewProjectModal extends PureComponent {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal hasButtonBar>
        <EditPeriodForm
          period={this.props.period}
          title="Create Project"
          closeModal={closeModal}
        />
      </Modal>
    );
  }
}

export default NewProjectModal;
