import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { CustomLayer, Point } from '@nivo/line';

const Circle = ({ x, y, serieColor }: Point) => {
  const animatedProps = useSpring({
    transform: `translate(${x}, ${y})`
  });

  return (
    <animated.g transform={animatedProps.transform} pointerEvents="none">
      <circle
        r={5}
        fill="#fff"
        stroke={serieColor}
        strokeWidth={2}
        pointerEvents="none"
      />
    </animated.g>
  );
};

// Points that has a background to not show the intersection between lines
// Created this because pointColor from nivo sometimes doesn't work
const SolidCirclePoints: CustomLayer = ({ points }) => {
  return points.map((point) => <Circle key={point.id} {...point} />);
};

export default SolidCirclePoints;
