import { flatMap } from 'lodash';
import React, { createContext, FC, useReducer } from 'react';
import { AdvanceFilters } from 'types/params';
import {
  Project,
  Report,
  SubRegion,
  SubRegionWithProjects
} from 'types/resource';
import { SelectedProjectsInSubRegion } from '../components/report-settings/sub-region-project-selector';

export interface ReportSettingsState {
  standardSubRegions: SubRegionWithProjects[];
  premiumSubRegions: SubRegionWithProjects[];
  selectedProjectsInSubRegion: SelectedProjectsInSubRegion | null;
  subRegionWithAllProjectsSelected: SubRegion['id'][];
  selectedProjects: Project[];
  selectedProjectIds: number[];
  subRegionsPermissions: string[];
  period: string;
  latestAvailablePeriod: string;
  advanceFilters: AdvanceFilters;
  selectedReport: Report | null;
  isFirstLoad: boolean;
  isSubRegionsLoading: boolean;
  isOutOfBounds: boolean;
}

interface ReportSettingsActions {
  setSelectedProjectsInSubRegion: (
    projects: SelectedProjectsInSubRegion
  ) => void;
  setPeriod: (period: string) => void;
  setLatestAvailablePeriod: (latestPeriod: string) => void;
  setSubRegions: (
    subRegions: Pick<
      ReportSettingsState,
      'premiumSubRegions' | 'standardSubRegions'
    >
  ) => void;
  setSubRegionWithAllProjectsSelected: (subRegions: SubRegion['id'][]) => void;
  setSelectedReport: (report: Report) => void;
  setFirstLoad: (payload: boolean) => void;
  setSubRegionsLoading: (payload: boolean) => void;
  setIsOutOfBounds: (payload: boolean) => void;
  setAdvanceFilters: (payload: AdvanceFilters) => void;
}

const initialState: ReportSettingsState = {
  standardSubRegions: [],
  premiumSubRegions: [],
  selectedProjectsInSubRegion: null,
  subRegionWithAllProjectsSelected: [],
  selectedProjects: [],
  selectedProjectIds: [],
  subRegionsPermissions: [],
  period: '',
  latestAvailablePeriod: '',
  selectedReport: null,
  advanceFilters: {},
  isFirstLoad: false,
  isSubRegionsLoading: false,
  isOutOfBounds: false
};

const ACTIONS = {
  SET_SELECTED_PROJECTS_IN_SUB_REGION: 'SET_SELECTED_PROJECTS_IN_SUB_REGION',
  SET_SUB_REGION_WITH_ALL_PROJECTS_SELECTED:
    'SET_SUB_REGION_WITH_ALL_PROJECTS_SELECTED',
  SET_PERIOD: 'SET_PERIOD',
  SET_LATEST_AVAILABLE_PERIOD: 'SET_LATEST_AVAILABLE_PERIOD',
  SET_SUB_REGIONS: 'SET_SUB_REGIONS',
  SET_SELECTED_REPORT: 'SET_SELECTED_REPORT',
  SET_FIRST_LOAD: 'SET_FIRST_LOAD',
  SET_SUB_REGIONS_LOADING: 'SET_SUB_REGIONS_LOADING',
  SET_IS_OUT_OF_BOUNDS: 'SET_IS_OUT_OF_BOUNDS',
  SET_ADVANCE_FILTERS: 'SET_ADVANCE_FILTERS'
};

const reducer = (
  state: ReportSettingsState,
  action: { type: string; payload: unknown }
): ReportSettingsState => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_SELECTED_PROJECTS_IN_SUB_REGION: {
      const projects = payload as SelectedProjectsInSubRegion;

      const selectedProjectsInSubRegion = {};
      const subRegionsPermissions = [];
      // Convert to number ID's this is required because ID is number from backend
      // but whereabouts convert it to string
      Object.keys(projects).forEach((key) => {
        // Format is decided by backend sub-region:id
        subRegionsPermissions.push(`sub-region:${key}`);
        Object.assign(selectedProjectsInSubRegion, {
          [key]: projects[key].map(Number)
        });
      });

      // Get ID's of each project
      const projectIds = flatMap(
        Object.keys(
          selectedProjectsInSubRegion as SelectedProjectsInSubRegion
        ).map((subRegion) => selectedProjectsInSubRegion[subRegion])
      );

      const allSubRegions = [
        ...state.standardSubRegions,
        ...state.premiumSubRegions
      ];

      const selectedProjects: Project[] = flatMap(
        allSubRegions.map((subRegion) => subRegion.projects)
      ).filter((project) => projectIds.includes(project.id));

      const selectedProjectIds = selectedProjects.map((project) => project.id);

      return {
        ...state,
        selectedProjectsInSubRegion,
        selectedProjectIds,
        subRegionsPermissions,
        selectedProjects
      };
    }
    case ACTIONS.SET_SUB_REGION_WITH_ALL_PROJECTS_SELECTED:
      return {
        ...state,
        // Making sure they are all numbers, because if they are from query it will be string
        subRegionWithAllProjectsSelected: (payload as SubRegion['id'][]).map(
          (id) => +id
        )
      };
    case ACTIONS.SET_SELECTED_REPORT:
      return {
        ...state,
        selectedReport: payload as Report
      };
    case ACTIONS.SET_SUB_REGIONS:
      return {
        ...state,
        ...(payload as Pick<
          ReportSettingsState,
          'premiumSubRegions' | 'standardSubRegions'
        >)
      };
    case ACTIONS.SET_PERIOD:
      return {
        ...state,
        period: payload as string
      };
    case ACTIONS.SET_LATEST_AVAILABLE_PERIOD:
      return {
        ...state,
        latestAvailablePeriod: payload as string
      };
    case ACTIONS.SET_FIRST_LOAD:
      return {
        ...state,
        isFirstLoad: payload as boolean
      };
    case ACTIONS.SET_SUB_REGIONS_LOADING:
      return {
        ...state,
        isSubRegionsLoading: payload as boolean
      };
    case ACTIONS.SET_IS_OUT_OF_BOUNDS:
      return {
        ...state,
        isOutOfBounds: payload as boolean
      };
    case ACTIONS.SET_ADVANCE_FILTERS:
      return {
        ...state,
        advanceFilters: payload as AdvanceFilters
      };
    default:
      return state;
  }
};

export const ReportSettingsContext = createContext<
  ReportSettingsState & ReportSettingsActions
>(null);

const ReportSettingsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    ...state,
    setSelectedProjectsInSubRegion: (payload: SelectedProjectsInSubRegion) => {
      dispatch({
        type: ACTIONS.SET_SELECTED_PROJECTS_IN_SUB_REGION,
        payload
      });
    },
    setPeriod: (period: string) => {
      dispatch({ type: ACTIONS.SET_PERIOD, payload: period });
    },
    setLatestAvailablePeriod: (latestPeriod: string) => {
      dispatch({
        type: ACTIONS.SET_LATEST_AVAILABLE_PERIOD,
        payload: latestPeriod
      });
    },
    setSubRegions: (
      subRegions: Pick<
        ReportSettingsState,
        'premiumSubRegions' | 'standardSubRegions'
      >
    ) => {
      dispatch({
        type: ACTIONS.SET_SUB_REGIONS,
        payload: subRegions
      });
    },
    setSubRegionWithAllProjectsSelected: (subRegions: SubRegion['id'][]) => {
      dispatch({
        type: ACTIONS.SET_SUB_REGION_WITH_ALL_PROJECTS_SELECTED,
        payload: subRegions
      });
    },
    setSelectedReport: (report: Report) => {
      dispatch({
        type: ACTIONS.SET_SELECTED_REPORT,
        payload: report
      });
    },
    setFirstLoad: (payload: boolean) => {
      dispatch({
        type: ACTIONS.SET_FIRST_LOAD,
        payload
      });
    },
    setSubRegionsLoading: (payload: boolean) => {
      dispatch({
        type: ACTIONS.SET_SUB_REGIONS_LOADING,
        payload
      });
    },
    setIsOutOfBounds: (payload: boolean) => {
      dispatch({
        type: ACTIONS.SET_IS_OUT_OF_BOUNDS,
        payload
      });
    },
    setAdvanceFilters: (payload: AdvanceFilters) => {
      dispatch({
        type: ACTIONS.SET_ADVANCE_FILTERS,
        payload
      });
    }
  };

  return (
    <ReportSettingsContext.Provider value={value}>
      {children}
    </ReportSettingsContext.Provider>
  );
};

export default ReportSettingsProvider;
