import React, { PureComponent } from 'react';
import { PrimaryButton, TextButton } from 'view/components/button';
import { Grid, Column } from '@rexlabs/grid';
import { withForm, Form, FormField } from 'view/components/form';
import { createValidationRules } from 'utils/form';
import { TextInput } from '@rexlabs/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';
import Box from '@rexlabs/box';
import periodsModel from 'data/models/entities/periods';
import { withModel } from '@rexlabs/model-generator';
import ButtonBar from '../components/modal/button-bar';
import { Heading } from '../components/text';
import withError from 'view/containers/with-error';

const defaultStyles = StyleSheet({
  container: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`
  },
  title: {
    color: COLORS.GREY.DARK
  }
});

@withError.withPropName('errorModal')
@withModel(periodsModel)
@withForm({
  name: 'editPeriodForm',
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, { props, setSubmitting }) => {
    props.periods
      .updateItem({
        id: props.period.id,
        data: {
          note: values.note
        }
      })
      .then(() => props.closeModal())
      .catch((e) => {
        setSubmitting(false);
        props.errorModal.show(e);
      });
  },
  mapPropsToValues: (props) => ({}),
  asyncValuesReady: (props) => ({}),
  validate: createValidationRules({
    note: 'string'
  })
})
@styled(defaultStyles)
class EditPeriodForm extends PureComponent {
  render() {
    const {
      styles: s,
      editPeriodForm,
      errorModal: { Error }
    } = this.props;

    return (
      <Box {...s('container')}>
        <Heading>Edit Period</Heading>
        <Form name="edit-period">
          <Grid columns={1}>
            <Column width={1}>
              <FormField
                label="Notes"
                name="note"
                Input={TextInput}
                inputProps={{
                  charLimit: 20,
                  showCharLimit: true,
                  placeholder: 'Enter your notes for this Period here.'
                }}
              />
            </Column>
          </Grid>
        </Form>
        <ButtonBar style={{ paddingRight: 0 }}>
          <TextButton onClick={this.props.closeModal}>Cancel</TextButton>
          <PrimaryButton
            green
            form="edit-period"
            isLoading={editPeriodForm.isSubmitting}
          >
            Save
          </PrimaryButton>
        </ButtonBar>
        <Error />
      </Box>
    );
  }
}

export default EditPeriodForm;
