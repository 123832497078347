/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { differenceBy, isEmpty } from 'lodash';
import { Canceler, CancelToken as CancelTokenType } from 'axios';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';

import Panel from 'src/features/reports/components/panel';
import projectPositioningModel from 'src/features/reports/models/project-positioning';
import { COLORS } from 'src/features/reports/theme';
import { Project } from 'src/features/reports/components/selector/project-selector';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import {
  ALL_PROJECTS,
  NO_PROJECTS,
  POSITIONING_PAGE,
  TimeIntervalType,
  TIME_INTERVAL
} from 'src/features/reports/constants';

import withError from 'view/containers/with-error';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { ProjectPerformanceOverviewReport } from 'types/graph/project-performance-overview';

import Categories, { CategoryKey, CategoryType } from './categories';
import { Loading } from '../graph';
import { ToggleButtonGroup } from '../toggle-button';

import { ProjectSelector } from '../selector';

import { compose } from 'utils/compose';
import { numberWithCommas } from 'utils/format';
import { formatProjectPerfResponse } from 'utils/graph/project-performance';
import { ProjectPositioningModel } from 'types/models/project-positioning';
import { ProjectPerformanceOverviewChart } from './project-performance-overview-chart';
import { Auth0Context } from 'src/auth0-provider';
import { CancelToken } from '@rexlabs/api-client';
import InfoTooltip from './info-tooltip';

const styles = StyleSheet({
  gap: {
    gap: '24px'
  },
  container: {
    position: 'relative'
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1
  }
});

interface ProjectPerformanceOverviewProps {
  styles: Styles;
  errorModal: WithErrorModalProps;
  projectPositioningModel: ProjectPositioningModel;
}

const ProjectPerformanceOverview = ({
  styles: s,
  projectPositioningModel,
  errorModal: { open: openError }
}: ProjectPerformanceOverviewProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const {
    selectedProjects,
    selectedProjectIds,
    period,
    subRegionsPermissions,
    isFirstLoad
  } = useContext(ReportSettingsContext);
  const averageCanceler = useRef<Canceler | null>(null);
  const reportCanceler = useRef<Canceler | null>(null);
  const bestPerformingCanceler = useRef<Canceler | null>(null);
  const { fetchProjectPerfAverage, fetchProjectPerfData, fetchBestPerforming } =
    projectPositioningModel;
  const [timeInterval, setTimeInterval] = useState<TimeIntervalType>('monthly');
  const [category, setCategory] = useState<CategoryKey>('gross_sales');
  const [categories, setCategories] = useState<CategoryType>([]);
  const [projects, setProjects] = useState<Array<Project[]>>([
    [ALL_PROJECTS],
    [NO_PROJECTS]
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState<ProjectPerformanceOverviewReport>({
    monthly: [],
    quarterly: []
  });
  const [isBestPerformingLoading, setIsBestPerformingLoading] = useState(false);

  const formattedProjectsList = useMemo<Project[]>(
    () =>
      selectedProjects.map((project) => ({
        key: project.id,
        label: project.title
      })),
    [selectedProjects]
  );

  // Get list of projects for the two selectors
  // This will filter them as well so that we can't have same project selected
  const getProjectList = useCallback(
    (index: number) => {
      if (index === 0) {
        return differenceBy(
          [...formattedProjectsList, ALL_PROJECTS],
          projects[1],
          'key'
        );
      } else {
        return differenceBy(
          [...formattedProjectsList, NO_PROJECTS],
          projects[0],
          'key'
        );
      }
    },
    [projects, selectedProjects]
  );

  // Reset projects selected
  useEffect(() => {
    if (!selectedProjects.length) return;
    setProjects([[ALL_PROJECTS], [NO_PROJECTS]]);
  }, [selectedProjects]);

  useEffect(() => {
    if (
      !period ||
      !selectedProjectIds.length ||
      !hasPermission([...subRegionsPermissions, POSITIONING_PAGE])
    ) {
      return;
    }

    bestPerformingCanceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      bestPerformingCanceler.current = c;
    });

    setIsBestPerformingLoading(true);
    fetchBestPerforming({
      queryParams: { period, project_ids: selectedProjectIds },
      config: { cancelToken }
    })
      .then((res) => {
        // Empty when the api call got cancelled
        if (isEmpty(res.data)) {
          setIsBestPerformingLoading(false);
          return;
        }
        setProjects((prev) => [
          prev[0],
          [{ key: res.data.id, label: res.data.title }]
        ]);
        setIsBestPerformingLoading(false);
      })
      .catch((error) => {
        openError(error);
        setIsBestPerformingLoading(false);
      });

    return () => {
      bestPerformingCanceler.current?.();
    };
  }, [period, selectedProjectIds]);

  // Loads the data for FeaturedBox UI
  useEffect(() => {
    const projectIds =
      projects[0][0].key === 'all_projects'
        ? selectedProjectIds
        : [+projects[0][0].key];

    if ((!period || projectIds.length === 0) && !isFirstLoad) return;
    averageCanceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      averageCanceler.current = c;
    });

    fetchProjectPerfAverage({
      queryParams: {
        period,
        project_ids: projectIds
      },
      hasPermission:
        !isFirstLoad &&
        hasPermission([...subRegionsPermissions, POSITIONING_PAGE]),
      config: { cancelToken }
    })
      .then((response) => {
        // Empty when the api call got cancelled
        if (!response.data) return;
        const categoryValues = response.data;
        setCategories([
          {
            label: 'GROSS SALES',
            key: 'gross_sales',
            value: categoryValues.gross_sales ?? 0
          },
          {
            label: 'NET SALES',
            key: 'net_sales',
            value: categoryValues.net_sales ?? 0
          },
          {
            label: 'OUTSTANDING CONTRACTS',
            key: 'outstanding_contracts',
            value: categoryValues.outstanding_contracts ?? 0
          },
          {
            label: 'NEWLY RELEASED',
            key: 'newly_released',
            value: categoryValues.newly_released ?? 0
          },
          {
            label: 'MEDIAN SOLD PRICE',
            key: 'median_price',
            value: `$${numberWithCommas(categoryValues.median_price ?? 0)}`
          },
          {
            label: 'MEDIAN SOLD SIZE',
            key: 'median_lot_size',
            value: `${categoryValues.median_lot_size ?? 0} m2`
          },
          {
            label: 'MEDIAN SOLD $/M2',
            key: 'median_price_per_m2',
            value: `$${numberWithCommas(
              categoryValues.median_price_per_m2 ?? 0
            )}/m2`
          }
        ]);
      })
      .catch(openError);

    return () => {
      averageCanceler.current?.();
    };
  }, [selectedProjectIds, period, hasPermission, projects[0]]);

  const fetchGraph = useCallback(
    (passedProjects: Project[], cancelToken: CancelTokenType) => {
      if ((!period || selectedProjects.length === 0) && !isFirstLoad) {
        return null;
      }
      const key =
        isFirstLoad || passedProjects.length > 1
          ? 'All Projects'
          : passedProjects[0].label;

      return fetchProjectPerfData({
        queryParams: {
          period,
          project_ids: passedProjects.map((s) => s.key).map(Number),
          report: category
        },
        hasPermission:
          !isFirstLoad &&
          hasPermission([...subRegionsPermissions, POSITIONING_PAGE]),
        config: { cancelToken }
      }).then((response) => {
        // Empty when the api call got cancelled
        if (!response.data) return;
        return formatProjectPerfResponse({
          key,
          records: response.data,
          yKey: category
        });
      });
    },
    [
      formatProjectPerfResponse,
      category,
      projects,
      period,
      selectedProjects,
      hasPermission,
      isFirstLoad
    ]
  );

  useEffect(() => {
    if ((!period || !selectedProjects.length) && !isFirstLoad) return;

    setIsLoading(true);
    const promises = [];
    const newProjects = projects.map((project) => project[0]);

    const firstPromise =
      newProjects[0].key === 'all_projects'
        ? selectedProjects.map((s) => ({
            key: s.id,
            label: s.title
          }))
        : [newProjects[0]];

    reportCanceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      reportCanceler.current = c;
    });

    promises.push(fetchGraph(firstPromise, cancelToken));

    if (newProjects[1].key !== 'no_projects') {
      promises.push(fetchGraph([newProjects[1]], cancelToken));
    }

    Promise.all(promises)
      .then((response: ProjectPerformanceOverviewReport[]) => {
        // Empty when the api call got cancelled
        if (response.some((report) => !report)) {
          return;
        }

        const newMonthData = response.map((r) => r.monthly[0]);
        const newQuarterData = response.map((r) => r.quarterly[0]);

        setChartData({
          monthly: newMonthData,
          quarterly: newQuarterData
        });

        setIsLoading(false);
      })
      .catch(openError);

    return () => {
      reportCanceler.current?.();
    };
  }, [category, period, selectedProjects, projects, isFirstLoad]);

  return (
    <Panel
      title="Project Performance Overview"
      tooltip={{
        title: 'Project Performance Overview',
        description: <InfoTooltip />
      }}
      featuredValues={
        <Categories
          categories={categories}
          selected={category}
          onClick={(key: CategoryKey) => {
            setCategory(key);
          }}
          isAllProjectsSelected={projects[0][0].key === 'all_projects'}
        />
      }
    >
      <Box p="12px 0">
        <Loading isLoading={isLoading || isBestPerformingLoading} />
        <Box width="100%" flexDirection="row" justifyContent="center">
          <Box width="236px" mb="24px" mt="22px">
            <ToggleButtonGroup
              value={[timeInterval]}
              group={TIME_INTERVAL}
              onToggle={(key: string) => setTimeInterval(key)}
            />
          </Box>
        </Box>
        <Box width="100%" height="615px">
          <ProjectPerformanceOverviewChart
            category={category}
            chartData={chartData[timeInterval]}
            axisLeftLegend={
              categories.find((c) => c.key === category)?.label ?? ''
            }
          />
        </Box>
        <Box
          {...s('gap')}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <ProjectSelector
            color={COLORS.BLUE.PRIMARY}
            projects={getProjectList(0)}
            selectedProjects={projects[0]}
            onChange={(selectedProjects: Project[]) =>
              setProjects((prev) => [selectedProjects, prev[1]])
            }
          />
          <ProjectSelector
            color={COLORS.PINK.LIGHT}
            projects={getProjectList(1)}
            selectedProjects={projects[1]}
            onChange={(selectedProjects: Project[]) =>
              setProjects((prev) => [prev[0], selectedProjects])
            }
          />
        </Box>
      </Box>
    </Panel>
  );
};

export default compose(
  styled(styles),
  withError.withPropName('errorModal'),
  withModel(projectPositioningModel)
)(ProjectPerformanceOverview);
