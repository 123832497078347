import React, { Component, Fragment } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import periodsModel from 'data/models/entities/periods';
import projectsModel from 'data/models/entities/projects';
import statsModel from 'data/models/entities/stats';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import ReportFooter from 'view/components/report-footer';
import Report from 'src/view/components/reports/size-price-brackets';
import withReportSidebar from 'view/containers/with-report-sidebar';
import { toQuri } from '../../../utils/query';

function getPrimaryProjectId(props) {
  return _.get(props, 'match.query.primaryProject');
}

function getPeriod(props) {
  return _.get(props, 'match.query.period');
}

function getProjectIds(props) {
  return _.get(props, 'match.query.projects');
}

function getPeriodQuery(props) {
  return toQuri([
    {
      field: 'end_date',
      op: '==',
      value: getPeriod(props)
    }
  ]);
}

const periodsQuery = query`{
  ${periodsModel} (q: ${getPeriodQuery}) {
    id
    end_date
  }
}`;

const projectsQuery = query`{
  ${projectsModel} (ids: ${getProjectIds}) {
    id
    title
    active
    developer
    sales_office_address
    sales_agency
    local_government_area
    total_area
    net_developable_area
    suburb
    postcode
    estimated_start_date
    estimated_completion_date
    total_area
    status
    created_at
    stats
    settings
    sales_volume_offset
    estimated_lots
    precinct_structure_plan  }
}`;

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  }
});

@withReportSidebar(false)
@withQuery(projectsQuery)
@withQuery(periodsQuery)
@withModel(statsModel)
@styled(defaultStyles)
class SizePriceBrackets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Loading vars
      fetchingPriceSizeStats: true,
      fetchingMarketShareSize: true,
      fetchingMarketSharePrice: true,
      fetchingMarketShareDepth: true,
      fetchingMarketShareFrontage: true,

      // Stat vars
      priceSize: {},
      marketShareSize: {},
      marketSharePrice: {},
      marketShareDepth: {},
      marketShareFrontage: {},

      //
      priceVSizeIncrement: 20
    };

    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    const { stats, whereabouts } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (
      !_.get(query, 'period') ||
      !_.get(query, 'projects') ||
      !_.get(query, 'primaryProject')
    ) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    stats
      .fetchBracketsPriceBrackets({
        args: {
          projects: getProjectIds(this.props),
          primary: getPrimaryProjectId(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) => {
        this.setState({
          priceSize: res.data,
          fetchingPriceSizeStats: false
        });
      });

    stats
      .fetchBracketSizeStats({
        args: {
          projects: getProjectIds(this.props),
          primary: getPrimaryProjectId(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) => {
        this.setState({
          marketShareSize: res.data,
          fetchingMarketShareSize: false
        });
      });

    stats
      .fetchBracketPriceStats({
        args: {
          projects: getProjectIds(this.props),
          primary: getPrimaryProjectId(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) => {
        this.setState({
          marketSharePrice: res.data,
          fetchingMarketSharePrice: false
        });
      });

    stats
      .fetchBracketFrontageStats({
        args: {
          projects: getProjectIds(this.props),
          primary: getPrimaryProjectId(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) => {
        this.setState({
          marketShareFrontage: res.data,
          fetchingMarketShareFrontage: false
        });
      });

    stats
      .fetchBracketDepthStats({
        args: {
          projects: getProjectIds(this.props),
          primary: getPrimaryProjectId(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) => {
        this.setState({
          marketShareDepth: res.data,
          fetchingMarketShareDepth: false
        });
      });
  }

  loading() {
    const {
      fetchingPriceSizeStats,
      fetchingMarketShareSize,
      fetchingMarketSharePrice,
      fetchingMarketShareDepth,
      fetchingMarketShareFrontage
    } = this.state;
    const { projects } = this.props;

    const arr = [
      fetchingPriceSizeStats,
      fetchingMarketShareSize,
      fetchingMarketSharePrice,
      fetchingMarketShareDepth,
      fetchingMarketShareFrontage,
      projects.list.status === 'loading'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const { projects, periods } = this.props;
    const {
      priceSize,
      marketShareSize,
      marketSharePrice,
      marketShareDepth,
      marketShareFrontage
    } = this.state;

    const loading = this.loading();
    const maxRequests = 6;

    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const projectList = _.sortBy(_.get(projects, 'list.items'), (item) =>
      item.id === parseInt(getPrimaryProjectId(this.props)) ? 0 : 1
    );
    const primaryProject = _(projectList)
      .filter(
        (project) => project.id === parseInt(getPrimaryProjectId(this.props))
      )
      .head();

    const period = _.head(_.get(periods, 'list.items'));

    return (
      <Fragment>
        <Report
          period={period}
          primary={primaryProject}
          projects={projectList}
          priceSize={priceSize}
          marketShareSize={marketShareSize}
          marketSharePrice={marketSharePrice}
          marketShareDepth={marketShareDepth}
          marketShareFrontage={marketShareFrontage}
        />

        <ReportFooter
          prevText="Pricing Comparison"
          prevRoute={ROUTES.APP.REPORT_PRICING}
          nextText="Lot Availability"
          nextRoute={ROUTES.APP.REPORT_LOT}
        />
      </Fragment>
    );
  }
}

export default SizePriceBrackets;
