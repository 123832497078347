import React, { useMemo } from 'react';
import { animated } from '@react-spring/web';
import { CustomLayer } from '@nivo/line';
import { useAnimatedPath } from '@nivo/core';

// Dashed line graph for the second set of data, that is why there is a condition index === 1
const DashedLine: CustomLayer = ({ series, lineGenerator }) => {
  const path = useMemo(
    () =>
      series[1]
        ? lineGenerator(series[1].data.map((datum) => datum.position))
        : [],
    [lineGenerator, series]
  );
  const animatedPath = useAnimatedPath(path);

  return (
    <animated.path
      d={animatedPath}
      fill="none"
      strokeWidth={2}
      stroke={series[1] ? series[1].color : ''}
      style={{
        strokeDasharray: '4',
        strokeWidth: 2
      }}
    />
  );
};

export default DashedLine;
