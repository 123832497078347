import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  divider: {
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
  }
});

@styled(defaultStyles)
class Divider extends PureComponent {
  render() {
    const { styles: s, small } = this.props;

    if (small) {
      return (
        <Box pt={PADDINGS.TINY} pb={PADDINGS.TINY}>
          <Box
            {...s('divider')}
            style={{
              margin: 0,
              padding: 0
            }}
          />
        </Box>
      );
    }

    return (
      <Box pt={PADDINGS.SMALL} pb={PADDINGS.SMALL}>
        <Box
          {...s('divider')}
          style={{
            margin: 0,
            padding: 0
          }}
        />
      </Box>
    );
  }
}

export default Divider;
