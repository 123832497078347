import React from 'react';

import Box from '@rexlabs/box';
import { styled, Styles, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';

import { Check as Checkbox } from 'view/components/checkbox';
import { Small } from 'view/components/text';

import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: 500,
    color: COLORS.GREY.DARK
  },
  container: {
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
  },
  grid: {
    '> *': {
      padding: '1rem 2rem'
    }
  }
});

interface RegionListHeaderProps {
  styles: Styles;
  checked: boolean;
  onSelectAll: () => void;
}

const RegionListHeader = ({
  styles: s,
  checked,
  onSelectAll
}: RegionListHeaderProps) => {
  return (
    <Box {...s('container')}>
      <Grid
        gutter={0}
        columns={24}
        style={{ alignItems: 'center' }}
        {...s('grid')}
      >
        <Column width={1} onClick={onSelectAll}>
          <Checkbox controlled={true} value={!!checked} />
        </Column>
        <Column width={6}>
          <Small {...s('text')}>Sub-Region</Small>
        </Column>
        <Column width={6}>
          <Small {...s('text')}>Projects</Small>
        </Column>
        <Column width={10}>
          <Small {...s('text')}>Published To</Small>
        </Column>
        <Column width={1} />
      </Grid>
    </Box>
  );
};

export default styled(defaultStyles)(RegionListHeader);
