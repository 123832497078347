import React, { ReactNode, FC, useMemo, useContext } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { compose } from 'lodash/fp';
import { Tiny } from 'view/components/text';
import { COLORS } from 'src/features/reports/theme';
import InfoTooltip, {
  InfoTooltipProps
} from 'src/features/reports/components/tooltip/info-tooltip';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';

const defaultStyles = StyleSheet({
  panel: {
    borderRadius: 8,
    minHeight: 300,
    border: `1px solid ${COLORS.GREY.BORDER}`,
    backgroundColor: COLORS.WHITE,
    width: '100%',
    marginBottom: 24,
    transition: 'all 0.2s ease-in-out'
  },
  panelHeader: {
    height: 50,
    borderBottom: `1px solid ${COLORS.GREY.BORDER}`,
    backgroundColor: COLORS.GREY.LIGHT,
    paddingTop: 12,
    paddingLeft: 24,
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    gap: 5
  },
  featuredValuesSection: {
    width: '100%',
    height: 100,
    marginTop: -75,
    gap: 20
  },
  panelTitle: {
    fontFamily: 'DM Sans',
    color: COLORS.BLUE.DARK,
    fontWeight: 500,
    fontSize: '17px !important'
  },
  panelContent: {
    padding: 22,
    // For absolute component with respect to the panel content
    // E.g loading
    position: 'relative'
  }
});

interface PanelProps {
  title?: string;
  featuredValues?: ReactNode;
  styles: Styles;
  tooltip?: Omit<InfoTooltipProps, 'styles'>;
}

// Considering at this point that there are only 2 panel variant, 1) plain panel 2) panel with featured values,
// What is being done here is that whenever we detect a `featuredValues` passed, automatically
// adjust the height of the panel header and insert the featured values with a negative overalapping position.
// Incase there is a diff panel concept/variant, this might not be a good solution. One way we could fix this is
// to allow the user to do the manipulation of panel styling thru props and so they can also adjust on how
// to position the `featuredValues`.
const Panel: FC<PanelProps> = ({
  styles: s,
  title,
  featuredValues,
  children,
  tooltip
}) => {
  const { isOutOfBounds } = useContext(ReportSettingsContext);

  const headerHeight = useMemo(() => {
    return !featuredValues ? 50 : 120;
  }, [featuredValues]);

  return (
    <Box
      {...s.with('panel')({
        border: isOutOfBounds
          ? `4px solid ${COLORS.RED.PRIMARY}`
          : `1px solid ${COLORS.GREY.BORDER}`
      })}
    >
      {title && (
        <Box flex={1} {...s.with('panelHeader')({ height: headerHeight })}>
          <Tiny {...s('panelTitle')}>{title}</Tiny>
          {tooltip && <InfoTooltip {...tooltip} />}
        </Box>
      )}
      {featuredValues && (
        <Box
          {...s('featuredValuesSection')}
          flex={1}
          justifyContent="center"
          alignContent="space-between"
        >
          {featuredValues}
        </Box>
      )}
      <Box {...s('panelContent')}>{children}</Box>
    </Box>
  );
};

export default compose(styled(defaultStyles))(Panel);
