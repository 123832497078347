import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import statsModel from 'data/models/entities/stats';
import { withQuery, withModel } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import ReportTitle from 'view/components/report-title';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import PriceList from 'view/components/reports/price-list';
import withValidate from 'view/containers/with-validate-setting';
import {
  getSettingsQuery,
  periodsQuery,
  projectsQuery
} from 'data/queries/settings';
import { getSettingsId, getToken } from 'utils/reports';

@withReportSidebar()
@withQuery(projectsQuery)
@withQuery(periodsQuery)
@withQuery(getSettingsQuery(getSettingsId, getToken))
@withModel(statsModel)
@withValidate
class PriceListSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingPriceList: true,
      fetchingPrimary: true,

      priceList: {},
      primary: {}
    };

    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    const { stats, whereabouts, settingProjects } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (!_.get(query, 'period')) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    stats
      .fetchPriceListSettings({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) =>
        this.setState({
          fetchingPriceList: false,
          priceList: res.data
        })
      )
      .catch(console.error);

    settingProjects
      .fetchPrimary({
        settingId: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token')
        }
      })
      .then((res) => {
        this.setState({
          fetchingPrimary: false,
          primary: res.data
        });
      })
      .catch(console.error);
  }

  loading() {
    const { settingProjects, periods } = this.props;
    const { fetchingPriceList, fetchingPrimary } = this.state;

    const arr = [
      fetchingPriceList,
      fetchingPrimary,
      _.get(settingProjects, 'list.status') !== 'loaded',
      _.get(periods, 'list.status') !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const { periods, settingProjects, settings } = this.props;
    const { priceList, primary } = this.state;

    const loading = this.loading();
    const maxRequests = 4;
    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const projects = _.get(settingProjects, 'list.items');
    const period = _.head(_.get(periods, 'list.items'));

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle
            period={period}
            project={primary}
            settings={_.get(settings, 'item.data')}
          />
        </Portal>

        <PriceList
          period={period}
          projects={projects}
          primary={primary}
          priceList={priceList}
        />

        <ReportFooter
          prevText="Estate Detailed Stats"
          prevRoute={ROUTES.APP.REPORT_SETTINGS_DETAILED}
          nextText="Definitions Appendix"
          nextRoute={ROUTES.APP.REPORT_SETTINGS_DEFINITIONS}
          settingId={getSettingsId(this.props)}
        />
      </Fragment>
    );
  }
}

export default PriceListSettings;
