import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      fetchList: (type, { ...args }) => api.get('/projects', args),
      updateItem: (type, args, id) =>
        api.put(`projects/${id}?include=settings,stats`, args)
    }
  }
};

const actionCreators = {
  purgeItem: {
    request: ({ id, ...args }) => api.delete(`projects/${id}/purge`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  archive: {
    request: ({ id }) => api.delete(`projects/${id}/archive`),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  recoverFromArchive: {
    request: ({ id }) => api.patch(`projects/${id}/recover`),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  trash: {
    request: ({ id }) => api.delete(`projects/${id}/trash`),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ProjectsGenerator = new Generator('projects', config);
export default ProjectsGenerator.createEntityModel({ actionCreators });
