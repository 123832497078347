import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { Body } from 'view/components/text';
import { PADDINGS } from 'src/theme';

class OptionNotFound extends PureComponent {
  render() {
    const { term } = this.props;
    return (
      <Box p={PADDINGS.XXS} pl={PADDINGS.XS} pr={PADDINGS.XS}>
        <Body grey>
          {term.isLoading ? (
            <span>Loading...</span>
          ) : term.value ? (
            <span>
              Could not find anything for <b>{term.value}</b>.
            </span>
          ) : (
            <span>Start typing to trigger search...</span>
          )}
        </Body>
      </Box>
    );
  }
}

export default OptionNotFound;
