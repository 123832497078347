import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { Transition, animated, config } from 'react-spring/renderprops';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PortalTarget } from '@rexlabs/portal';
import { COLORS, PADDINGS, EASINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  backdrop: {
    position: 'fixed',
    background: COLORS.PRIMARY_DARK,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100
  },

  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 301,
    pointerEvents: 'none'
  },

  containerActive: {
    pointerEvents: 'all'
  },

  wrapModal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 102,
    padding: `0 ${PADDINGS.S}`
  }
});

@styled(defaultStyles)
class ModalTarget extends PureComponent {
  render() {
    const { styles: s, login } = this.props;
    const leftPosition = login ? 0 : 0;
    return (
      <PortalTarget name="dialogs">
        {({ children }) => (
          <Fragment>
            <Box
              {...s('container', {
                containerActive: children.length
              })}
              style={{ left: leftPosition }}
              flex={0}
            >
              <Transition
                keys={React.Children.map(children, (child, i) => i)}
                from={{ opacity: 0, transform: 'scale(0.7)' }}
                enter={{ opacity: 1, transform: 'scale(1)' }}
                leave={{ opacity: 0, transform: 'scale(0.3)' }}
                config={{ ...config.default, easing: EASINGS.MODAL_FADE }}
                items={children}
              >
                {(item) => (styles) =>
                  (
                    <animated.div>
                      <Box
                        {...s('backdrop')}
                        style={{
                          ...{
                            left: leftPosition,
                            opacity: styles.opacity / 2,
                            display: styles.opacity > 0.05 ? 'flex' : 'none'
                          }
                        }}
                      />
                      <Box
                        {...s('wrapModel')}
                        style={{
                          ...styles,
                          ...{
                            position: 'absolute',
                            width: '100%',
                            zIndex: 100
                          }
                        }}
                        flex={0}
                        justifyContent="flex-start"
                        alignItems="center"
                        flexDirection="column"
                      >
                        {item}
                      </Box>
                    </animated.div>
                  )}
              </Transition>
            </Box>
          </Fragment>
        )}
      </PortalTarget>
    );
  }
}

export default ModalTarget;
