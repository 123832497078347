import React, { CSSProperties } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT, PADDINGS } from 'theme';
import { Modal } from 'view/components/modal';
import { SubHeading } from 'view/components/text';
import RedMark from 'assets/red-exclamation.svg';
import BlackMark from 'assets/black-exclamation.svg';
import { PrimaryButton } from '../button';

const defaultStyles: Record<string, CSSProperties> = {
  container: {
    width: '100%'
  },

  title: {
    fontSize: '2rem',
    fontWeight: FONT.WEIGHTS.NORMAL
  },

  header: {
    padding: `${PADDINGS.XS} ${PADDINGS.XS}`,
    borderBottom: `.1rem solid ${COLORS.GREY.LIGHT}`,
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    flexShrink: 0,
    width: '100%'
  },

  headerTitle: {
    marginLeft: '1.5rem'
  },

  content: {
    paddingTop: PADDINGS.S,
    paddingLeft: PADDINGS.XL,
    paddingRight: PADDINGS.XL,
    overflow: 'auto',
    flexShrink: 0,
    position: 'relative'
  },

  buttons: {
    paddingBottom: PADDINGS.S,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  submitButton: {
    marginLeft: '2.5rem'
  }
};

export interface ErrorListModalProps {
  title: string;
  items: string[];
  error?: boolean;
  onSubmit?: (...args: any) => any;
  onCancel?: (...args: any) => any;
  isAction?: boolean;
}

interface DecoratorProps {
  styles: (...args: string[]) => any;
}

const ErrorListModal: React.FC<ErrorListModalProps & DecoratorProps> = ({
  title,
  items,
  styles: s,
  error,
  onSubmit,
  onCancel,
  isAction = true
}) => (
  <Modal>
    <Box {...s('container')}>
      <Box {...s('header')}>
        {error ? <RedMark /> : <BlackMark />}{' '}
        {!!title && <SubHeading {...s('headerTitle')}>{title}</SubHeading>}
      </Box>
      <Box flex={1} flexDirection="column" {...s('content')}>
        <ul style={{ listStyle: 'square' }}>
          {items.map((item, index) => (
            <li key={index}>
              <Box p={PADDINGS.TINY}>{item}</Box>
            </li>
          ))}
        </ul>
      </Box>
      <Box mt={PADDINGS.S} {...s('buttons')}>
        {isAction ? (
          <>
            <PrimaryButton style={{ width: '300px' }} onClick={onCancel} green>
              {`Don't save! & Take me back`}
            </PrimaryButton>

            <PrimaryButton
              style={{ width: '300px' }}
              {...s('submitButton')}
              onClick={onSubmit}
              red
            >
              I have supervisor approval
            </PrimaryButton>
          </>
        ) : (
          <>
            <PrimaryButton style={{ width: '300px' }} onClick={onCancel} green>
              Close
            </PrimaryButton>
          </>
        )}
      </Box>
    </Box>
  </Modal>
);
export default styled(StyleSheet(defaultStyles))(ErrorListModal);
