import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'lodash/fp';
import ReactGA from 'react-ga';
import * as FS from '@fullstory/browser';

import { match } from '@rexlabs/whereabouts';
import { withModel } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import { withWhereabouts } from '@rexlabs/react-whereabouts';

import AppLayout from 'view/layouts/app';
import ReportLayout from 'src/features/reports/layouts/report';

import { useAuth0 } from 'src/auth0-provider';

import 'react-dates/initialize';
import SwitchLayout from 'view/layouts/switch';
import { Auth0Context } from '../auth0-provider';

FS.init({
  orgId: 'o-1E8CXD-na1',
  recordCrossDomainIFrames: true,
  devMode: __DEV__
});

function CoreReportingApp(props) {
  const { whereabouts, session } = props;
  const { loginWithRedirect, guest, user } = useAuth0();
  const { permissions } = useContext(Auth0Context);

  useEffect(() => {
    ReactGA.initialize('UA-156368403-1');
  }, []);

  useEffect(() => {
    if (user) {
      FS.setUserVars({
        displayName: user.nickname,
        email: user.email,
        uid: user.email
      });
    }
  }, [user]);

  if (!guest && !session.accountId) {
    return <SwitchLayout {...props} />;
  }

  // Even guest users should be able to access reports!?!
  const isReport = match(whereabouts, {
    path: '/app/report(.*)'
  });

  if (isReport || !permissions.includes('admin-app:general-access')) {
    return <ReportLayout />;
  }

  // Guests can't access main app
  if (guest) {
    return loginWithRedirect({ uri: window.location.origin });
  }

  // AppLayout will show the login layout as long as the user
  // is not authenticated!
  return <AppLayout />;
}

export default compose(
  withModel(sessionModel),
  withWhereabouts
)(CoreReportingApp);
