import React, { PureComponent, Fragment } from 'react';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { Body } from 'view/components/text';
import Box from '@rexlabs/box';
import { PADDINGS } from 'src/theme';
import ProgressBar from 'view/components/progress-bar';

class LoadingLayout extends PureComponent {
  render() {
    const comp = this.props.max ? (
      <Box style={{ width: '50rem' }}>
        <ProgressBar {...this.props} />
      </Box>
    ) : (
      <Fragment>
        <LoadingSpinner />
        <Box style={{ marginLeft: PADDINGS.XS }}>
          <Body>Loading...</Body>
        </Box>
      </Fragment>
    );

    return (
      <Box
        flex={1}
        alignItems="center"
        justifyContent="center"
        pt={'30vh'}
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
      >
        {comp}
      </Box>
    );
  }
}

export default LoadingLayout;
