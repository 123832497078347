import React, { SVGProps } from 'react';

const SettingsIcon = (props: SVGProps<null>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0783 9.89717L14.1164 9.88766C14.6357 9.7578 15 9.29115 15 8.75583V8.2439C15 7.70856 14.6357 7.24192 14.1163 7.11206L14.0782 7.10253C13.6823 7.00332 13.3567 6.7224 13.2006 6.34525C13.0445 5.96809 13.0763 5.53928 13.2864 5.18928L13.3024 5.16259C13.5813 4.70277 13.5104 4.11194 13.1307 3.73115L12.7687 3.36916C12.3901 2.99061 11.8025 2.91826 11.3435 3.19368L11.3098 3.2139C10.9598 3.42388 10.531 3.4556 10.154 3.29942C9.77691 3.14323 9.49616 2.81761 9.39716 2.42167L9.38766 2.38366C9.2578 1.86431 8.79115 1.5 8.25582 1.5H7.74389C7.20855 1.5 6.7419 1.86432 6.61205 2.38367L6.60251 2.42178C6.50331 2.81773 6.2224 3.14327 5.84524 3.29937C5.46808 3.45547 5.03927 3.42368 4.68926 3.21365L4.66256 3.19764C4.20274 2.91869 3.61192 2.98957 3.23113 3.36936L2.86914 3.73134C2.49058 4.10982 2.4182 4.69738 2.6936 5.15641L2.71351 5.18962C2.92354 5.53963 2.95535 5.96844 2.79925 6.3456C2.64315 6.72276 2.31761 7.00368 1.92167 7.10289L1.88368 7.11239C1.36435 7.24224 1 7.70885 1 8.24417V8.75618C1 9.29141 1.36414 9.75801 1.88339 9.88784L1.92113 9.89729C2.31713 9.99626 2.64284 10.277 2.79915 10.6541C2.95547 11.0311 2.92391 11.46 2.71409 11.8101L2.69392 11.8437C2.41852 12.3027 2.49085 12.8903 2.86936 13.2688L3.23138 13.6309C3.60978 14.0094 4.19725 14.0818 4.65622 13.8064L4.68958 13.7864C5.03959 13.5764 5.4684 13.5446 5.84557 13.7007C6.22273 13.8568 6.50365 14.1823 6.60286 14.5782L6.61237 14.6163C6.74221 15.1356 7.20883 15.5 7.74416 15.5H8.25615C8.7914 15.5 9.25798 15.1358 9.38782 14.6166L9.39725 14.5788C9.49622 14.1828 9.77695 13.8571 10.154 13.7008C10.5311 13.5445 10.9599 13.5761 11.31 13.7859L11.3437 13.8061C11.8027 14.0815 12.3903 14.0091 12.7688 13.6306L13.1309 13.2686C13.5094 12.8902 13.5818 12.3027 13.3064 11.8438L13.2864 11.8104C13.0764 11.4604 13.0446 11.0316 13.2007 10.6544C13.3568 10.2773 13.6824 9.99636 14.0783 9.89717Z"
        stroke="#0B1E49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8.00015"
        cy="8.50024"
        r="1.61538"
        stroke="#0B1E49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
