import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS, BORDER_RADIUS } from '../../../theme';
import Box from '@rexlabs/box';
import { Tiny } from 'view/components/text';

const defaultStyles = StyleSheet({
  BoxStyle: {
    textAlign: 'center',
    borderRadius: BORDER_RADIUS.STATUS_BOX,
    width: 'fit-content',
    padding: `0 ${PADDINGS.XXS}`
  }
});

@styled(defaultStyles)
class StatusBox extends PureComponent {
  render() {
    const { styles: s, text, published } = this.props;
    const isPublishedBackground = published
      ? COLORS.GREEN.LIGHT
      : COLORS.RED.LIGHT;
    const isPublishedTextColor = published ? COLORS.PRIMARY_DARK : COLORS.RED;
    return (
      <Box
        {...s.with('BoxStyle')({
          backgroundColor: isPublishedBackground,
          color: isPublishedTextColor
        })}
      >
        <Tiny>{text}</Tiny>
      </Box>
    );
  }
}

export default StatusBox;
