import React, { SVGProps } from 'react';

const Star = (props: SVGProps<null>) => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.5L20.6342 11.3742L31 12.9675L23.5 20.6487L25.27 31.5L16 26.3742L6.73 31.5L8.5 20.6487L1 12.9675L11.3642 11.3742L16 1.5Z"
        fill="#FCE896"
        stroke="#FCE896"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Star;
