import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from 'view/components/text';
import { Check as Checkbox } from 'view/components/checkbox';
import { COLORS, FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500'
  },
  truncate: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  status: {
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD,
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
  },
  grid: {
    '> *': {
      padding: '1rem 2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  }
});

@styled(defaultStyles)
class RegionProjectAddHeader extends PureComponent {
  render() {
    const { styles: s, onSelectAll, checked } = this.props;
    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={12}
          style={{ alignItems: 'center ' }}
          {...s('grid')}
        >
          <Column
            width={1}
            onClick={(e) => {
              e.stopPropagation();
              onSelectAll();
            }}
            {...s('checkbox')}
          >
            <Checkbox controlled={true} value={!!checked} />
          </Column>
          <Column width={3}>
            <Small {...s('text', 'truncate')}>Title</Small>
          </Column>
          <Column width={2}>
            <Small {...s('text')}>Created At</Small>
          </Column>
          <Column width={2}>
            <Small {...s('status')}>Status</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>Summary</Small>
          </Column>
        </Grid>
      </Box>
    );
  }
}

export default RegionProjectAddHeader;
