import React, { PureComponent } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  down: {
    border: `solid ${COLORS.GREY.DARK}`,
    borderWidth: '0 1px 1px 0',
    display: 'inline-block',
    padding: 3,
    transform: 'rotate(45deg)',
    width: '0.4rem',
    height: '0.4rem',
    margin: '0.4rem'
  },

  up: {
    border: `solid ${COLORS.GREY.DARK}`,
    borderWidth: '0 1px 1px 0',
    display: 'inline-block',
    padding: 3,
    transform: 'rotate(-135deg)',
    width: '0.4rem',
    height: '0.4rem',
    margin: '0.4rem'
  },

  arrow: {
    border: `solid ${COLORS.GREY.DARK}`,
    borderWidth: '0 1px 1px 0',
    display: 'inline-block',
    padding: 3,
    width: '0.4rem',
    height: '0.4rem',
    margin: '0.4rem'
  },

  container: {}
});

@styled(defaultStyles)
class DownArrow extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return <Box {...s('down')} />;
  }
}

@styled(defaultStyles)
class UpArrow extends PureComponent {
  render() {
    const { styles: s } = this.props;
    return <Box {...s('up')} />;
  }
}

@styled(defaultStyles)
class Arrow extends PureComponent {
  render() {
    const { styles: s, ...rest } = this.props;
    return <Box {...s('arrow')} {...rest} />;
  }
}

export { Arrow, DownArrow, UpArrow };
