import React, { useEffect, useMemo, useRef, useState } from 'react';
import { animated } from '@react-spring/web';
import { ComputedBoxPlotSummary } from './types';

const BoxPlotItemLabel = ({
  layout,
  height,
  coordinates,
  x,
  y,
  data
}: ComputedBoxPlotSummary) => {
  const ref = useRef<SVGTextElement>(null);
  const [elementWidth, setElementWidth] = useState(0);

  useEffect(() => {
    if (!ref.current) return;
    setElementWidth(ref.current.getBBox().width);
  }, []);

  const transform = useMemo(() => {
    return layout === 'vertical'
      ? `translate(${x + height / 2 + 4}, ${
          coordinates.values[0] - elementWidth - 10
        })`
      : `translate(${coordinates.values[0] - elementWidth - 10}, ${
          y + height / 2 + 4
        })`;
  }, [x, height, y, coordinates, layout, elementWidth]);

  return (
    <animated.g transform={transform}>
      <animated.text
        ref={ref}
        fontSize={12}
        fontWeight={500}
        fontFamily="DM Sans"
      >
        {data.subGroup}
      </animated.text>
    </animated.g>
  );
};

export default BoxPlotItemLabel;
