import React, { Dispatch, memo, SetStateAction } from 'react';
import Box from '@rexlabs/box';
import { SelectedProjectsInSubRegion } from './sub-region-project-selector';
import { Project, SubRegionWithProjects } from 'types/resource';
import { Styles } from '@rexlabs/styling';
import SelectorCheckbox from '../selector/selector-checkbox';

interface ProjectSelector {
  selectedProjectsInSubRegion: SelectedProjectsInSubRegion | null;
  subRegion: SubRegionWithProjects;
  styles: Styles;
  setCancelExit: Dispatch<SetStateAction<boolean>>;
  isPremiumSubRegionProjectDisabled?: (
    subRegion: SubRegionWithProjects,
    project: Project
  ) => boolean;
  onToggleProject: (subRegionId: number, projectId: number) => void;
  onClose: () => void;
}

const ProjectSelector = ({
  subRegion,
  selectedProjectsInSubRegion,
  styles: s,
  setCancelExit,
  isPremiumSubRegionProjectDisabled,
  onToggleProject,
  onClose
}: ProjectSelector) => {
  return (
    <Box
      {...s('projectWrapper', 'scroll')}
      onMouseEnter={() => {
        setCancelExit(true);
      }}
      onMouseLeave={() => {
        setCancelExit(false);
        onClose();
      }}
    >
      {subRegion.projects.map((project) => (
        <SelectorCheckbox
          key={project.id}
          disabled={
            // undefined meaning it is a standard region
            isPremiumSubRegionProjectDisabled === undefined
              ? true
              : isPremiumSubRegionProjectDisabled(subRegion, project)
          }
          checked={selectedProjectsInSubRegion?.[subRegion.id]?.includes(
            project.id
          )}
          label={project.title}
          onClick={() => onToggleProject(subRegion.id, project.id)}
        />
      ))}
    </Box>
  );
};

export default memo(ProjectSelector);
