import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const WarningOutline = (props: SVGProps<null>) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7487 23.4167C16.5187 23.4167 16.332 23.6033 16.3337 23.8333C16.3337 24.0633 16.5203 24.25 16.7503 24.25C16.9803 24.25 17.167 24.0633 17.167 23.8333C17.167 23.6033 16.9803 23.4167 16.7487 23.4167"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.10667 30.7733L2.72667 24.3933C2.10167 23.7683 1.75 22.92 1.75 22.0367V11.4633C1.75 10.58 2.10167 9.73167 2.72667 9.10667L9.10667 2.72667C9.73167 2.10167 10.58 1.75 11.4633 1.75H22.035C22.9183 1.75 23.7667 2.10167 24.3917 2.72667L30.7717 9.10667C31.3983 9.73167 31.75 10.58 31.75 11.4633V22.035C31.75 22.9183 31.3983 23.7667 30.7733 24.3917L23.4167 31.75H11.4633C10.58 31.75 9.73167 31.3983 9.10667 30.7733V30.7733Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7503 17.5833V9.25"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningOutline;
