import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import { Small } from 'view/components/text';

const defaultStyles = StyleSheet({
  container: {
    width: '100%'
  },
  title: {
    color: COLORS.GREY.DARK
  },
  change: {
    marginLeft: '1rem'
  }
});

@styled(defaultStyles)
class ReportBox extends PureComponent {
  render() {
    const { styles: s, title, children } = this.props;
    return (
      <Box {...s('container')}>
        <Small {...s('title')}>{title}</Small>
        <Box style={{ display: 'inline-flex' }}>{children}</Box>
      </Box>
    );
  }
}

export default ReportBox;
