import React from 'react';
import Box from '@rexlabs/box';
import { FONT, COLORS } from 'src/features/reports/theme';

interface LegendProps {
  names: string[];
  colors: string[];
}

// This is created because nivo legends are reversed and it hasn't been fixed yet
// https://github.com/plouc/nivo/issues/839
const Legend = ({ names, colors }: LegendProps) => {
  return (
    <Box flexDirection="row" justifyContent="center">
      {names.map((name, index) => (
        <Box
          key={name}
          flexDirection="row"
          alignItems="center"
          mr={index === names.length - 1 ? 0 : '24px'}
        >
          <div
            style={{
              backgroundColor: colors[index],
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              marginRight: '8px'
            }}
          />
          <span
            style={{
              color: COLORS.BLUE.DARK,
              fontFamily: FONT.FAMILIES.DEFAULT,
              fontSize: '12px',
              fontWeight: 400
            }}
          >
            {name}
          </span>
        </Box>
      ))}
    </Box>
  );
};

export default Legend;
