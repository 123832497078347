import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      The Breakdown chart displays price and size trends over time.
      <br />
      <br />
      There are a number of toggles allowing you to adjust the chart to the data
      set you need.{' '}
      <span className="italic">
        Traditional (=&gt;300m2) or Small lots (&lt;300m2) can be deselected.
      </span>
      <br />
      <br />
      Selecting the Summary % tile displays the split between traditional &
      small lot sales.
    </>
  );
};

export default InfoTooltip;
