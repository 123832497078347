import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { Form, FormField, withForm } from 'view/components/form';
import { withModel, withQuery, query } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import accountsModel from 'data/models/entities/accounts';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS } from 'src/theme';
import { createValidationRules } from 'utils/form';
import { PrimaryButton } from 'view/components/button';
import LoadingSpinner from '@rexlabs/loading-spinner';
import withError from 'view/containers/with-error';
import ModalTarget from 'view/components/portal/modal';
import { Heading } from 'view/components/text';
import { SelectInput } from 'view/components/input/select';
import LoadingLayout from './loading';
import _ from 'lodash';
import ROUTES from 'routes';
import { push } from '@rexlabs/whereabouts';

const defaultStyles = StyleSheet({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
    background: COLORS.BACKGROUND
  },

  wrapContent: {
    padding: PADDINGS.M,
    width: '100%',
    maxWidth: '40rem'
  }
});

const accountsQuery = query`{
  ${accountsModel} {
    id
    name 
  }
}`;

@withError.withPropName('errorModal')
@withModel(sessionModel)
@withQuery(accountsQuery)
@withForm({
  name: 'switchAccountForm',
  validateOnBlur: false,
  validateOnChange: false,
  validate: createValidationRules({}),
  handleSubmit: (values, { props, setSubmitting }) => {
    return props.session
      .switchAccount({
        account_id: values.account
      })
      .then(() => {
        push(ROUTES.APP.HOME, {});
      })
      .catch((e) => {
        props.errorModal.open(e);
        setSubmitting(false);
      });
  }
})
@styled(defaultStyles)
class SwitchLayout extends PureComponent {
  render() {
    const {
      styles: s,
      switchAccountForm,
      errorModal: { Error },
      accounts
    } = this.props;

    if (_.get(accounts, 'list.status') === 'loading') {
      return <LoadingLayout />;
    }
    return (
      <Box
        {...s('container')}
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <ModalTarget login />
        <Box {...s('wrapContent')}>
          <Heading>Select Account</Heading>

          <Form>
            <FormField
              label="Account"
              name="account"
              Input={SelectInput}
              inputProps={{
                options: _.map(accounts.list.items, (item) => ({
                  value: item.id,
                  label: item.name
                }))
              }}
              useSlowField
            />
            <Box mt={PADDINGS.S}>
              <PrimaryButton
                isDisabled={switchAccountForm.isSubmitting}
                isLoading={switchAccountForm.isSubmitting}
                onClick={switchAccountForm.submit}
                Loading={() => <LoadingSpinner colors={['white']} />}
                green
              >
                Select
              </PrimaryButton>
            </Box>
          </Form>
        </Box>
        <Error />
      </Box>
    );
  }
}

export default SwitchLayout;
