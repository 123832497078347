import React from 'react';
import VividCheckbox from '@rexlabs/checkbox';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';

const checkStyles = StyleSheet({
  input: {
    '& + label:focus': {
      borderColor: COLORS.BLUE_GREEN
    },
    '& + label': {
      borderRadius: '25%'
    }
  }
});

const radioStyles = StyleSheet({
  input: {
    '& + label:focus': {
      borderColor: COLORS.BLUE_GREEN
    },
    '& + label': {
      borderRadius: '25%'
    }
  }
});

const Checkbox = ({ radio, ...props }) => {
  return (
    <VividCheckbox
      {...props}
      type={radio ? 'radio' : 'checkbox'}
      styles={radio ? radioStyles : checkStyles}
    />
  );
};

export default styled(checkStyles)(Checkbox);
