export const MARKET = [
  {
    label: 'Time on Market',
    key: 'time_on_market'
  },
  {
    label: 'Market Share',
    key: 'market_share'
  }
];

export const FRONTAGES = [8.5, 10.5, 12.5, 14, 16];

export const DEPTHS = [21, 25, 28, 30, 32];
