import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      fetchList: (type, { projectId, worksheetId, ...args }) =>
        api.get(
          `/projects/${projectId}/worksheets/${worksheetId}/listings`,
          args
        ),
      fetchItem: (type, { projectId, worksheetId, ...args }, id) =>
        api.get(
          `/projects/${projectId}/worksheets/${worksheetId}/listings/${id}`,
          args
        ),
      createItem: (type, { projectId, worksheetId, ...args }) =>
        api.post(
          `/projects/${projectId}/worksheets/${worksheetId}/listings`,
          args
        ),
      updateItem: (type, { projectId, worksheetId, ...args }, id) =>
        api.put(
          `/projects/${projectId}/worksheets/${worksheetId}/listings/${id}`,
          args
        ),
      trashItem: (type, { projectId, worksheetId, ...args }, id) =>
        api.delete(
          `/projects/${projectId}/worksheets/${worksheetId}/listings/${id}`,
          args
        )
    }
  }
};

const actionCreators = {
  fetchLastListing: {
    request: (
      { projectId, worksheetId, lotNumber, ...rest },
      action,
      dispatch,
      getState
    ) =>
      api.get(
        `/projects/${projectId}/worksheets/${worksheetId}/listings/last?lot_number=${lotNumber}`,
        rest
      ),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  massUpdate: {
    request: ({ args, data }) => {
      return api.patch(
        `/projects/${args.projectId}/worksheets/${args.worksheetId}/listings`,
        data
      );
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ProjectsGenerator = new Generator('lots', config);
export default ProjectsGenerator.createEntityModel({ actionCreators });
