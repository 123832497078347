import { isEmpty, isNumber, isObject } from 'lodash';
import moment from 'moment';
import { pickerLang } from 'view/components/calendar/month';

export function formatDate(inputDate) {
  const date =
    typeof inputDate === 'number'
      ? getDateFromTimestamp(inputDate)
      : new Date(inputDate);
  if (!isValidDate(date)) {
    return '';
  }

  return `${date.getDate()} ${date.toLocaleString('en-us', {
    month: 'long'
  })} ${date.getFullYear()}`;
}

// Sample: Aug 2022
export const getShortMonthWithFullYear = (inputDate) => {
  const date = new Date(inputDate);
  return date
    .toLocaleString('en-us', {
      month: 'short',
      year: 'numeric'
    })
    .toString();
};

// Sample: Aug ’22
export const getShortMonthWithYear = (inputDate) => {
  const date = new Date(inputDate);
  return `${date.toLocaleString('en-us', {
    month: 'short'
  })} ’${date.toLocaleString('en-us', { year: '2-digit' })}`;
};

export const getShortMonth = (date) =>
  new Date(date).toLocaleString('en-us', { month: 'short' });

export const getDateFromTimestamp = (timestamp) =>
  timestamp.toString().length === 14
    ? new Date(timestamp)
    : new Date(timestamp * 1000);

export function formatPeriod(inputDate, short) {
  const date = new Date(inputDate);
  if (!isValidDate(date)) {
    return '';
  }

  return `${date.toLocaleString('en-us', {
    month: short ? 'short' : 'long'
  })} ${date.getFullYear()}`;
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isValueValid(value) {
  return value !== 'undefined' && value !== null && value !== 'NaN';
}

export function formatNumber(number) {
  if (number == null) {
    return;
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function stripNumber(number) {
  if (number == null) {
    return 0;
  }

  return number.toString().replace(/,/g, '');
}

export const cleanNumbers = (values, format) => {
  format.forEach((field) => {
    if (values[field]) {
      values[field] = stripNumber(values[field]);
    }
  });

  return values;
};

export const getMomentOrNull = (date) => {
  if (date == null) {
    return;
  }

  return moment(date);
};

export const formatAddress = (address, suburb, postcode) => {
  let formatted = suburb || '';

  if (postcode) {
    formatted = formatted.length > 0 ? `${formatted} ${postcode}` : postcode;
  }

  if (address) {
    formatted = formatted.length > 0 ? `${address}, ${formatted}` : address;
  }

  return formatted ? formatted.trim() : '';
};

export const properCase = (str) =>
  str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());

export const formatUnderscore = (str) => properCase(str.replace(/_/g, ' '));

export const parseNumberInput = (raw) => {
  const last = raw[raw.length - 1];
  const value =
    last.toLowerCase() === 'k' ? raw.substring(0, raw.length - 1) * 1000 : raw;
  return value;
};

export const roundHundred = (value) => Math.round(value / 100) * 100;

export function formatNumericValue({
  value,
  placeholder = '-',
  suffix = '',
  prefix = ''
}) {
  if (!isNumber(value) && isEmpty(value)) {
    return placeholder;
  }

  return isValueValid(value)
    ? `${prefix}${formatNumber(value)}${suffix}`
    : placeholder;
}

export const formatCurrency = (value) => {
  return isValueValid(value) ? `$${numberWithCommas(value)}` : '-';
};

// from from: { year: string, month: string }, to: { year, month } -->  to Feb 2021 - Feb 2022
export const formatDateStringToRangeDisplay = (from, to) => {
  const fromDate = formatDateToObject(from);
  const toDate = formatDateToObject(to);

  return formatRangeDisplay({ from: fromDate, to: toDate });
};

// from { year, month } --> to Feb 2022
export const formatToMonthAndYear = (m) => {
  if (m && m.year && m.month) {
    return pickerLang.months[m.month - 1] + ' ' + m.year;
  }

  return '';
};

// from { year, quarter } --> to Feb 2022
export const formatToQuarterAndYear = (m) => {
  if (m && m.year && m.quarter) {
    return `Q${m.quarter} ${m.year}`;
  }

  return '';
};

// from { from: { year, month }, to: { year, month } } --> to Feb 2021 - Feb 2022
export const formatRangeDisplay = (value) => {
  if (value.from.month && value.from.month !== '') {
    return (
      formatToMonthAndYear(value.from) + ' - ' + formatToMonthAndYear(value.to)
    );
  }

  return '';
};

// from { year, month } --> to 2022-02-02
export const formatDateToString = (dateObject, isStartDate = false) => {
  if (isValueValid(dateObject) && isObject(dateObject)) {
    const { year, month } = dateObject;

    if (year === '' || month === '') {
      return '';
    }

    // moment's format return + 1 on the month.
    const dateObj = { year, month: month - 1 };
    const format = 'YYYY-MM-DD';

    if (isStartDate) {
      return moment(dateObj).startOf('month').format(format);
    }

    return moment(dateObj).endOf('month').format(format);
  }
};

// from 2022-02-02 --> to { year, month }
export const formatDateToObject = (stringDate) => {
  if (isValueValid(stringDate)) {
    const { years: year, months: month } = moment(stringDate).toObject();
    return { year, month: month + 1 };
  }
};

// Sample 200000 => 200k, 1000 => 1k, 300 => 300
export function abbrNum(number, decPlaces = 0) {
  // 2 decimal places => 100, 3 => 1000, etc
  const base = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = ['k', 'm', 'b', 't'];

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by base, round, and then divide by base.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * base) / size) / base;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      break;
    }
  }

  return number;
}

// Convert a number to a string with commas
export function numberWithCommas(value) {
  const splitValue = value.toString().split('.');
  if (splitValue.length > 1) {
    return (
      Math.floor(splitValue[0])
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + `.${splitValue[1]}`
    );
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
