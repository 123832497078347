export const GROUP_FILTER = [
  {
    label: 'Depth',
    key: 'depth'
  },
  {
    label: 'Frontage',
    key: 'frontage'
  }
];

export const SCOPE_FILTER = [
  {
    label: 'Exact',
    key: 'exact'
  },
  {
    label: 'Range',
    key: 'range'
  }
];

export const heatLabels = {
  lowestHeat: 'LEAST LOTS',
  highestHeat: 'MOST LOTS'
};
