import Generator from 'data/models/generator';
import { identity } from 'lodash';
import { ApiArgs } from 'types/common';
import {
  PerformanceOverviewParams,
  ProductOverviewParams,
  ReportQueryParams,
  ReportRangeQueryParams,
  SupplyOverviewParams
} from 'types/params';
import { api } from 'utils/api-client';
import {
  fetchLotJourneyMock,
  fetchPerformanceOverviewGraphMock,
  fetchPerformanceOverviewSummaryMock,
  fetchProductOverviewMock,
  fetchSalesPerformanceMock,
  fetchSupplyOverviewMock
} from '../mocks';

const actionCreators = {
  fetchLotJourney: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ReportQueryParams>) =>
      hasPermission
        ? api.get(`/reports/key-insights/lot-journey`, queryParams, config)
        : fetchLotJourneyMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchSupplyOverview: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<SupplyOverviewParams>) =>
      hasPermission
        ? api.get(`/reports/key-insights/supply-overview`, queryParams, config)
        : fetchSupplyOverviewMock({ report: queryParams.report }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchProductOverview: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ProductOverviewParams>) =>
      hasPermission
        ? api.get(`/reports/key-insights/product-overview`, queryParams, config)
        : fetchProductOverviewMock({ report: queryParams.report }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchPerformanceOverviewSummary: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ReportQueryParams>) =>
      hasPermission
        ? api.get(
            `/reports/key-insights/performance-overview`,
            queryParams,
            config
          )
        : fetchPerformanceOverviewSummaryMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchPerformanceOverviewGraph: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<PerformanceOverviewParams>) =>
      hasPermission
        ? api.get(
            `/reports/key-insights/performance-overview-graph`,
            queryParams,
            config
          )
        : fetchPerformanceOverviewGraphMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchSalesPerformance: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ReportRangeQueryParams>) =>
      hasPermission
        ? api.get(
            `/reports/key-insights/sales-performance`,
            queryParams,
            config
          )
        : fetchSalesPerformanceMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const KeyInsightsGenerator = new Generator('keyInsightsModel');
export default KeyInsightsGenerator.createModel({ actionCreators });
