import React, { PureComponent } from 'react';
import { Modal } from 'view/components/modal';
import LotForm from 'view/forms/lot';

class LotModal extends PureComponent {
  render() {
    return (
      <Modal hasButtonBar>
        <LotForm title="Create Worksheet" {...this.props} />
      </Modal>
    );
  }
}

export default LotModal;
