import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Canceler } from 'axios';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { CancelToken } from '@rexlabs/api-client';

import { BoxPlotDatum, ResponsiveBoxPlot } from '../box-plot';
import Panel from '../panel';
import MonthRangeSlider, { MonthYearType } from '../slider/month-range-slider';

import { numberWithCommas } from 'utils/format';
import { NIVO_THEME } from 'src/features/reports/theme';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import projectPositioningModel from 'src/features/reports/models/project-positioning';
import { ProjectPositioningModel } from 'types/models/project-positioning';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import withError from 'view/containers/with-error';
import { compose } from 'utils/compose';
import { Loading } from '../graph';
import useMonthRangeFilter from 'src/features/reports/hooks/use-month-range-filter';
import { Auth0Context } from 'src/auth0-provider';
import { maxBy, minBy, range } from 'lodash';
import { POSITIONING_PAGE } from 'src/features/reports/constants';
import InfoTooltip from './info-tooltip';

interface ProjectMinMaxProps {
  projectPositioningModel: ProjectPositioningModel;
  errorModal: WithErrorModalProps;
}

const ProjectMinMax = ({
  projectPositioningModel,
  errorModal: { open: openError }
}: ProjectMinMaxProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const { selectedProjectIds, period, subRegionsPermissions, isFirstLoad } =
    useContext(ReportSettingsContext);
  const canceler = useRef<Canceler | null>(null);
  const { dateFilter, setDateFilter, startPeriod, endPeriod } =
    useMonthRangeFilter();
  const [report, setReport] = useState<BoxPlotDatum[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const tickValues = useMemo(() => {
    const max = maxBy(report, 'extrema[1]')?.extrema[1] ?? 0;
    const min = minBy(report, 'extrema[0]')?.extrema[0] ?? 0;
    return range(
      Math.floor(min / 50000) * 50000,
      // Should be always rounded to nearest next 50K
      max % 50000 > 0 ? max + 50000 : max,
      50000
    );
  }, [report]);

  useEffect(() => {
    if (
      (selectedProjectIds.length === 0 || !startPeriod || !endPeriod) &&
      !isFirstLoad
    ) {
      return;
    }

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    projectPositioningModel
      .fetchProjectPriceRange({
        queryParams: {
          project_ids: selectedProjectIds,
          start_period: startPeriod,
          end_period: endPeriod
        },
        hasPermission:
          !isFirstLoad &&
          hasPermission([...subRegionsPermissions, POSITIONING_PAGE]),
        config: { cancelToken }
      })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;
        if (res.data.length > 0) {
          setReport(
            res.data.map((data) => ({
              ...data,
              extrema: [data.values[0], data.values[4]]
            }))
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        openError(error);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    projectPositioningModel,
    dateFilter,
    startPeriod,
    endPeriod,
    openError,
    period,
    hasPermission,
    subRegionsPermissions,
    isFirstLoad
  ]);

  return (
    <Panel
      title="Project Price Range"
      tooltip={{
        title: 'Project Price Range',
        description: <InfoTooltip />
      }}
    >
      <Loading isLoading={isLoading} />
      <Box width="588px" m="0 auto 34px">
        <MonthRangeSlider
          value={dateFilter}
          onChange={(value: MonthYearType) => setDateFilter(value)}
          numOfMonthsBack={11}
          latestMonth={period}
        />
      </Box>
      <Box width="100%" height="595px">
        <ResponsiveBoxPlot
          theme={NIVO_THEME}
          height={500}
          layout="horizontal"
          margin={{ top: 0, right: 70, bottom: 0, left: 70 }}
          axisBottom={{
            legend: 'PRICE',
            legendPosition: 'middle',
            legendOffset: 50,
            tickSize: 9,
            tickValues,
            format: (value) => `$${numberWithCommas(value)}`
          }}
          minValue={tickValues[0]}
          maxValue={tickValues[tickValues.length - 1]}
          percentileColor="#A2BFFF"
          whiskerColor="#CBDBFF"
          medianColor="#5A8DFF"
          medianWidth={4}
          groupBy="group"
          subGroupBy="subGroup"
          enableGridY={false}
          enableGridX={false}
          innerPadding={16}
          data={report}
        />
      </Box>
    </Panel>
  );
};

export default compose(
  withError.withPropName('errorModal'),
  withModel(projectPositioningModel)
)(ProjectMinMax);
