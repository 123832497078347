import React, { SVGProps } from 'react';

const GripLogo = (props: SVGProps<null>) => {
  return (
    <svg
      width="122"
      height="96"
      viewBox="0 0 122 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.9141 51.3811C24.0371 51.3811 20.3906 52.3721 17.2169 54.1064C16.8889 53.06 16.7116 51.9611 16.7116 50.8302C16.7116 49.3204 17.0337 47.8397 17.6306 46.4727C20.5265 48.5334 24.0785 49.7518 27.9171 49.7518C37.6392 49.7518 45.5469 41.9519 45.5469 32.3624C45.5469 22.7729 37.6392 14.973 27.9171 14.973C21.9982 14.973 16.7559 17.8674 13.5556 22.2949C12.9735 21.4758 12.3174 20.7005 11.5935 19.9864C8.75661 17.1882 5.00076 15.4161 1.02032 14.9934L0.331787 21.2951C2.89676 21.5662 5.21943 22.665 7.0486 24.4693C8.93391 26.3289 10.0893 28.7947 10.2962 31.4151H10.3139C10.2962 31.7299 10.2873 32.0476 10.2873 32.3682C10.2873 35.761 11.2802 38.9293 12.9912 41.608C11.2418 44.3595 10.2873 47.554 10.2873 50.836C10.2873 53.3689 10.8606 55.864 11.9274 58.1375C8.11537 62.0636 5.76315 67.383 5.76315 73.2359C5.76315 85.2854 15.701 95.0877 27.9171 95.0877C40.1332 95.0877 50.071 85.2854 50.071 73.2359C50.071 61.1863 40.1303 51.3811 27.9141 51.3811ZM27.9141 21.3126C34.0931 21.3126 39.1167 26.2706 39.1167 32.3624C39.1167 38.4571 34.0902 43.4151 27.9141 43.4151C21.7381 43.4151 16.7116 38.4571 16.7116 32.3624C16.7116 26.2706 21.7381 21.3126 27.9141 21.3126ZM27.9141 88.7481C19.2411 88.7481 12.1874 81.7877 12.1874 73.2359C12.1874 64.684 19.2441 57.7236 27.9141 57.7236C36.5872 57.7236 43.6409 64.684 43.6409 73.2359C43.6409 81.7877 36.5872 88.7481 27.9141 88.7481Z"
        fill="#5A8DFF"
      />
      <path
        d="M55.1773 23.4229V16.8735H48.7501V48.1632H55.1773V38.8506C55.1773 30.2201 62.296 23.1985 71.0459 23.1985V16.8589C64.8403 16.8589 59.2228 19.3743 55.1773 23.4229Z"
        fill="#5A8DFF"
      />
      <path
        d="M104.361 16.5529C100.109 16.5529 96.2051 18.0452 93.1584 20.5256V16.8735H86.7312V91.9165H93.1584V47.3617C96.2051 49.8422 100.112 51.3345 104.361 51.3345C114.083 51.3345 121.991 43.5346 121.991 33.9451C121.991 24.3556 114.083 16.5529 104.361 16.5529ZM104.361 44.9949C98.1849 44.9949 93.1584 40.037 93.1584 33.9451C93.1584 27.8504 98.1849 22.8924 104.361 22.8924C110.537 22.8924 115.564 27.8504 115.564 33.9451C115.566 40.037 110.54 44.9949 104.361 44.9949Z"
        fill="#5A8DFF"
      />
      <path
        d="M81.8051 16.8734H75.3779V48.1632H81.8051V16.8734Z"
        fill="#5A8DFF"
      />
      <path
        d="M78.5931 9.70027C81.3088 9.70027 83.5103 7.52879 83.5103 4.85013C83.5103 2.17147 81.3088 0 78.5931 0C75.8774 0 73.6759 2.17147 73.6759 4.85013C73.6759 7.52879 75.8774 9.70027 78.5931 9.70027Z"
        fill="#5A8DFF"
      />
    </svg>
  );
};

export default GripLogo;
