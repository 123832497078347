import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Box from '@rexlabs/box';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import { Link } from '@rexlabs/react-whereabouts';
import { StyleSheet, styled } from '@rexlabs/styling';
import { Portal } from '@rexlabs/portal';
import { TextInput } from '@rexlabs/text-input';

import settingsModal from 'data/models/entities/settings';
import periodsModel from 'data/models/entities/periods';
import ROUTES from 'src/routes';
import { PADDINGS } from 'src/theme';

import SectionContainer from 'view/components/section-container';
import ButtonBar from 'view/components/modal/button-bar';
import PrimaryButton from 'view/components/button/primary';
import withError from 'view/containers/with-error';
import withAction from 'view/containers/with-action';
import ExportButton from 'view/components/export-button';
import EditReportSettingsModal from 'view/modals/edit-report-settings';
import GuestTokenForm from 'view/forms/guest-token';
import MonthPicker from 'view/components/calendar/month';
import LoadingLayout from 'view/layouts/loading';
import { Heading, Label } from 'view/components/text';
import { SelectInput } from 'view/components/input/select';
import { Form, FormField, ReactForms } from 'view/components/form';
import { Modal } from 'view/components/modal';

import { createValidationRules } from 'utils/form';
import { formatPeriod } from 'utils/format';
import { toQuri } from 'utils/query';
import '@kenshooui/react-multi-select/dist/style.css';

const defaultStyles = StyleSheet({
  content: {
    width: '100%',
    maxWidth: '120rem'
  },
  wrapContent: {
    justifyContent: 'center',
    position: 'relative',
    padding: `0 ${PADDINGS.S}`,
    overflow: 'visible'
  }
});

const settingsQuery = query`{
  ${settingsModal} (q: ${getSettingQuery}) {
    id
    name
  }
}`;

function getSettingQuery() {
  return toQuri([
    {
      field: 'hidden',
      op: '==',
      value: false
    }
  ]);
}

@withError.withPropName('errorModal')
@withAction.withPropName('actionModal')
@styled(defaultStyles)
@withQuery(settingsQuery)
@withModel(periodsModel)
class ReportSettingsScreen extends PureComponent {
  state = {
    period: moment().format('YYYY-MM-01'),
    editModal: false,
    createTokenModal: false
  };

  getDateValue = (period) => {
    if (!period) {
      const date = new Date();
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1
      };
    }

    const date = new Date(period);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1
    };
  };

  submitForm = (values, props) => {
    const { periods, errorModal } = this.props;
    const { period } = this.state;
    const reportId = _.get(values, 'report');

    return periods
      .fetchList({
        id: 'fetch-period',
        args: {
          q: toQuri([
            {
              field: 'end_date',
              op: '==',
              value: period
            }
          ])
        }
      })
      .then((response) => {
        if (_.isEmpty(_.get(response, 'data'))) {
          errorModal.open(
            'Cannot run report on this period. Period not found.'
          );
          return;
        }

        push(ROUTES.APP.REPORT_SETTINGS_OVERVIEW, {
          params: {
            id: reportId
          },
          query: {
            period: period
          }
        });
      });
  };

  validate = (values, props) => {
    const validation = createValidationRules({
      report: 'required'
    })(values, props);

    return validation;
  };

  deleteSetting = (id) => {
    const { actionModal, settings } = this.props;
    settings
      .trashItem({
        id: id
      })
      .then(() => {
        settings.refreshList();
        actionModal.close();
      });
  };

  editReport = (e) => {
    e.preventDefault();
    this.setState({ editModal: true });
  };

  render() {
    const {
      styles: s,
      errorModal: { Error },
      actionModal: { Action, ...actionModal },
      settings
    } = this.props;

    const { period, editModal, createTokenModal } = this.state;

    const settingValues = _.map(_.get(settings, 'list.items'), (setting) => ({
      value: setting.id,
      label: setting.name
    }));

    const isLoading = _.get(settings, 'list.status') === 'loading';

    if (isLoading) {
      return <LoadingLayout />;
    }

    return (
      <Fragment>
        <Portal target="header">
          <Box
            style={{
              height: '100%',
              paddingLeft: PADDINGS.L
            }}
            flex={1}
            alignItems={'center'}
          >
            <Heading style={{ color: 'white' }}>Choose Setting</Heading>
          </Box>
        </Portal>

        <Box {...s('wrapContent')} flex={1}>
          <Box {...s('content')}>
            <SectionContainer p={PADDINGS.S} mt={PADDINGS.S}>
              <ReactForms
                handleSubmit={this.submitForm}
                validate={this.validate}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {(props) => {
                  const { isSubmitting, submitForm, setFieldValue } = props;
                  return (
                    <Form name="report-settings-form">
                      <FormField
                        label="Select Setting"
                        name="report"
                        Input={SelectInput}
                        inputProps={{
                          options: settingValues
                        }}
                        useSlowField
                      />
                      <Box mt={PADDINGS.XS}>
                        <Label>Period</Label>
                        <MonthPicker
                          from={2008}
                          to={2028}
                          value={this.getDateValue(this.state.period)}
                          onChange={(year, month) =>
                            this.setState({ period: `${year}-${month}-1` })
                          }
                          button={
                            <TextInput
                              value={formatPeriod(this.state.period)}
                            />
                          }
                        />
                      </Box>
                      <ButtonBar style={{ paddingRight: 0 }}>
                        <Link to={ROUTES.APP.REPORT}>
                          <PrimaryButton>Back</PrimaryButton>
                        </Link>
                        {!!_.get(props, 'values.report') && (
                          <Fragment>
                            <PrimaryButton
                              red
                              type={'button'}
                              onClick={(e) => {
                                e.preventDefault();
                                actionModal.open(
                                  () =>
                                    this.deleteSetting(
                                      _.get(props, 'values.report')
                                    ),
                                  'Are you sure you want to delete this report setting?',
                                  'Delete Report Setting'
                                );
                                setFieldValue('report', null);
                              }}
                            >
                              Delete
                            </PrimaryButton>
                            <PrimaryButton onClick={this.editReport}>
                              Edit
                            </PrimaryButton>
                          </Fragment>
                        )}
                        <PrimaryButton
                          type={'button'}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ createTokenModal: true });
                          }}
                          isDisabled={!_.get(props, 'values.report')}
                        >
                          Create Token
                        </PrimaryButton>
                        <ExportButton
                          green
                          isSetting
                          settingId={_.get(props, 'values.report')}
                          period={period}
                          isDisabled={!_.get(props, 'values.report')}
                          type={'button'}
                        />
                        <PrimaryButton
                          green
                          isLoading={isSubmitting}
                          isDisabled={isSubmitting}
                          onClick={submitForm}
                          style={{ marginRight: 0 }}
                        >
                          Submit
                        </PrimaryButton>
                      </ButtonBar>
                      {editModal && (
                        <EditReportSettingsModal
                          settingId={_.get(props, 'values.report')}
                          onClose={() => this.setState({ editModal: false })}
                          onSubmit={() => {
                            settings.refreshList();
                          }}
                        />
                      )}
                      {createTokenModal && (
                        <Modal>
                          <GuestTokenForm
                            closeModal={() =>
                              this.setState({ createTokenModal: false })
                            }
                            period={period}
                            settingId={_.get(props, 'values.report')}
                          />
                        </Modal>
                      )}
                    </Form>
                  );
                }}
              </ReactForms>
            </SectionContainer>
          </Box>
        </Box>
        <Action />
        <Error />
      </Fragment>
    );
  }
}

export default ReportSettingsScreen;
