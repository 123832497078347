import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Canceler } from 'axios';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { CancelToken } from '@rexlabs/api-client';
import { compose } from 'utils/compose';
import moment from 'moment';

import projectBreakdownModel from 'src/features/reports/models/project-breakdown';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';

import withError from 'view/containers/with-error';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { ProjectBreakdownModel } from 'types/models/project-breakdown';
import { ProjectStatsParam } from 'types/params';

import FlagTooltips from './flag-tooltips';
import Panel from '../panel';
import { ToggleButtonGroup } from '../toggle-button';
import { Loading } from '../graph';
import { FILTER } from './constants';
import { BrokenCalendar } from '../icons';

import Table from 'src/features/reports/components/table';
import { columnsConfig, ProjectStatsTypes } from './columns';
import { containsOnlyNumbers } from 'utils/validation';
import { Auth0Context } from 'src/auth0-provider';
import { BREAKDOWN_PAGE } from 'src/features/reports/constants';
import { TotalSupplyDatum } from 'types/graph';
import InfoTooltip from './info-tooltip';

interface ProjectStatsProps {
  projectBreakdownModel: ProjectBreakdownModel;
  errorModal: WithErrorModalProps;
}

const ProjectStats = ({
  projectBreakdownModel,
  errorModal: { open: openError }
}: ProjectStatsProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const {
    latestAvailablePeriod,
    period,
    selectedProjectIds,
    subRegionsPermissions,
    isFirstLoad
  } = useContext(ReportSettingsContext);
  const canceler = useRef<Canceler | null>(null);
  const [filter, setFilter] =
    useState<ProjectStatsParam['report']>('sales_summary');
  const [report, setReport] = useState<ProjectStatsTypes[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isBeyond2Months = useMemo(
    () => moment(latestAvailablePeriod).diff(period, 'months') > 2,
    [latestAvailablePeriod, period]
  );

  useEffect(() => {
    if ((!selectedProjectIds.length || !period) && !isFirstLoad) return;

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    projectBreakdownModel
      .fetchProjectStats({
        queryParams: {
          project_ids: selectedProjectIds,
          period,
          report: filter
        },
        hasPermission:
          !isFirstLoad &&
          hasPermission([...subRegionsPermissions, BREAKDOWN_PAGE]),
        config: { cancelToken }
      })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;
        setReport(
          res.data.map((d: ProjectStatsTypes) => {
            return Object.keys(d).reduce(
              (a, k) => ({
                ...a,
                [k]: containsOnlyNumbers(d[k]) ? Number(d[k]) : d[k]
              }),
              {}
            ) as ProjectStatsTypes;
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        openError(err);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    period,
    filter,
    projectBreakdownModel,
    openError,
    hasPermission,
    subRegionsPermissions,
    isFirstLoad
  ]);

  const config = useMemo(
    () => columnsConfig.find((cc) => cc.report === filter),
    [filter]
  );

  const overridenColumns = useMemo(() => {
    return config.columns.map((column) => {
      if (
        filter === 'total_supply' &&
        ['completion_percentage', 'allotments_remaining'].includes(
          column.key as string
        )
      ) {
        return {
          ...column,
          render: (row: TotalSupplyDatum) => {
            return isBeyond2Months ? (
              <Box ml={8}>
                <FlagTooltips
                  Icon={BrokenCalendar}
                  title="Information"
                  description="Please open the current month's report to see this data"
                />
              </Box>
            ) : (
              column.render(row)
            );
          }
        };
      }

      return column;
    });
  }, [config.columns, filter, isBeyond2Months]);

  return (
    <Panel
      title="Project Stats"
      tooltip={{
        title: 'Project Stats',
        description: <InfoTooltip />
      }}
    >
      <Loading isLoading={isLoading} />
      <Box width="631px" m="0 auto 34px" pt="12px">
        <ToggleButtonGroup
          group={FILTER}
          value={[filter]}
          onToggle={(key: ProjectStatsParam['report']) => setFilter(key)}
        />
      </Box>

      <Table<ProjectStatsTypes>
        data={report}
        columns={overridenColumns}
        disableLazy={true}
        defaultConfig={{
          sortField: config.defaultSortField,
          dir: config.defaultSortDir
        }}
      />
    </Panel>
  );
};

export default compose(
  withModel(projectBreakdownModel),
  withError.withPropName('errorModal')
)(ProjectStats);
