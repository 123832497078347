export const CATEGORIES = [
  {
    label: 'BREAKDOWN (SOLD)',
    key: 'breakdown',
    value: '📊'
  },
  {
    label: 'SUMMARY',
    key: 'summary',
    value: '%'
  }
];

export const CALCULATION = [
  {
    label: 'Median',
    key: 'median'
  },
  {
    label: 'Average',
    key: 'average'
  }
];

export const TIME_INTERVAL = [
  {
    label: 'Month',
    key: 'month'
  },
  {
    label: 'Quarter',
    key: 'quarter'
  }
];

export const LOT_TYPE = [
  {
    label: 'Traditional',
    key: 'traditional'
  },
  {
    label: 'Small',
    key: 'small'
  }
];

export const LOT_SIZE = [
  {
    label: '$/m2',
    key: 'price_per_m2'
  },
  {
    label: 'Size',
    key: 'size'
  }
];
