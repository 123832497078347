/*
|-------------------------------------------------------------------------------
| App Setup
|-------------------------------------------------------------------------------
|
| Connects the DOM to our App. Exposes core App data at DEV time.
|
| It also should be the only file that is importing the store - all other usages
| of store are through react connectors or middleware.
|
*/

import 'utils/globals';
import config from 'config';

import React, { PureComponent } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from 'store';
import { api } from 'utils/api-client';
import { initTheme } from 'theme';
import { PortalProvider } from '@rexlabs/portal';
import { SCOPES } from './features/admin/constants/auth0';

import CoreReportingApp from 'view/app';

// Note: Ensure this is imported last, as it needs to inject styles last.
import { Auth0Provider } from './auth0-provider';

if (__DEV__) {
  window.app = {
    store,
    api
  };
}

// Setup global parts of theme
initTheme();

const onRedirectCallback = (appState) => {
  // push(ROUTES.APP.HOME);
};

class CoreReportingAppMount extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <Auth0Provider
          domain={config.AUTH0_DOMAIN}
          client_id={config.AUTH0_CLIENT_ID}
          redirect_uri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
          scope={SCOPES.join(' ')}
        >
          <PortalProvider>
            <CoreReportingApp />
          </PortalProvider>
        </Auth0Provider>
      </Provider>
    );
  }
}

render(<CoreReportingAppMount />, document.querySelector('#app'));
