import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import Box from '@rexlabs/box';
import List from '@rexlabs/list';
import { query, withQuery } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import { Grid, Column } from '@rexlabs/grid';
import { Link } from '@rexlabs/react-whereabouts';
import { TabsStateful } from '@rexlabs/tabs';
import { styled, StyleSheet } from '@rexlabs/styling';

import SectionColumn from 'view/components/grid/section-column';
import StatBox from 'view/components/stat-box/stat-box';
import ProjectWorksheetListHeader from 'view/components/list/project-worksheet-list-header';
import WorksheetModal from 'view/modals/worksheets-new';
import NewProjectForm from 'view/forms/project-new';
import Breadcrumb from 'view/components/breadcrumb/breadcrumb';
import ProjectWorksheetListItem from 'view/components/list/project-worksheet-list-item';
import LoadingLayout from 'view/layouts/loading';
import ErrorLayout from 'view/layouts/error';
import withPermission from 'view/containers/with-permissions';
import { Tiny, Heading, SubHeading, Body } from 'view/components/text';
import { AddButton, ClearButton, PrimaryButton } from 'view/components/button';

import { Modal } from 'view/components/modal';

import projectsModel from 'data/models/entities/projects';
import worksheetsModel from 'data/models/entities/worksheets';
import ROUTES from 'src/routes';
import Cross from 'src/assets/cross-dark.svg';
import { COLORS, PADDINGS } from 'src/theme';
import { formatNumber, formatDate, formatAddress } from 'utils/format';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: 'white',
    margin: '2rem'
  },
  breadcrumb: {
    marginLeft: '0.8rem',
    marginTop: '0.8rem'
  },
  row: {
    justifyContent: 'space-between',
    ':first-child': {
      paddingBottom: '1.5rem'
    }
  },
  status: {
    color: COLORS.BLUE_GREEN,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  header: {
    justifyContent: 'space-between',
    padding: PADDINGS.XS
  },
  btnGroup: {
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  btn: {
    margin: '0.5rem'
  }
});

function getProjectId(props) {
  return _.get(props, 'match.params.projectId');
}

const projectsQuery = query`{
  ${projectsModel} (id: ${getProjectId}, include: "settings, stats, sub_region") {
    id
    title
    active
    developer
    sales_office_address
    sales_agency
    local_government_area
    total_area
    net_developable_area
    suburb
    postcode
    sub_region_id
    estimated_start_date
    estimated_completion_date
    total_area
    status
    created_at
    stats
    settings
    summary
    sales_volume_offset
    estimated_lots
    precinct_structure_plan
    sub_region
    region
    state
    type

    is_min_seven_star_nathers
    is_zero_net_carbon_certified
    is_passive_home
    is_greenstar
    is_enviro_development
  }
}`;

const worksheetsQuery = query`{
  ${worksheetsModel} (projectId: ${getProjectId}, include: "period,flags") {
    id
    created_at
    updated_at
    status
    period
    no_price_list
    supply_issue
    notes
    project_id
    flags
  }
}`;

@withPermission()
@withQuery(projectsQuery)
@withQuery(worksheetsQuery)
@styled(defaultStyles)
class ViewProjectScreen extends PureComponent {
  state = {
    showModal: false,
    showAlertModal: false,
    showErrorModal: false,
    showRecoverModal: false,
    addWorksheetModal: false,
    showEditProjectModal: false,
    checked: {}
  };

  getFormattedProjectDates = (start, end) => {
    if (start && end) {
      return `${formatDate(start)} - ${formatDate(end)}`;
    }

    if (start || end) {
      return start ? formatDate(start) : formatDate(end);
    }

    return '-';
  };

  renderWorksheetListHeader = () => {
    const { worksheets } = this.props;
    return (
      <ProjectWorksheetListHeader
        checked={
          _.filter(this.state.checked, (value) => value).length ===
          worksheets.list.items.length
        }
        onSelectAll={() => {
          if (
            _.filter(this.state.checked, (value) => value).length ===
            worksheets.list.items.length
          ) {
            this.setState({
              checked: {}
            });
          } else {
            const toggledState = worksheets.list.items.reduce((obj, item) => {
              return { ...obj, [item.id]: true };
            }, {});
            this.setState({
              checked: toggledState
            });
          }
        }}
      />
    );
  };

  renderTabsWorksheets = () => {
    const { styles: s, projects, worksheets } = this.props;

    return (
      <Box>
        <Box {...s('header')} flex={1}>
          <Heading>Worksheets</Heading>
          <Box {...s('btnGroup')} flex={1}>
            <ClearButton
              {...s('btn')}
              iconRight={<Cross style={{ marginLeft: '0.8rem' }} />}
              onClick={() => this.setState({ addWorksheetModal: true })}
            >
              Add Worksheet
            </ClearButton>
          </Box>
        </Box>
        <List
          items={_.get(worksheets, 'list.items', [])}
          isLoading={_.get(worksheets, 'list.status') === 'loading'}
          endReached={_.get(worksheets, 'list.pagination.endReached')}
          Header={this.renderWorksheetListHeader}
          renderItem={(item) => {
            return (
              <ProjectWorksheetListItem
                project={projects.item.data}
                worksheet={item}
                checked={this.state.checked[item.id]}
                toggle={() =>
                  this.setState({
                    checked: {
                      ...this.state.checked,
                      ...{
                        [item.id]: !this.state.checked[item.id]
                      }
                    }
                  })
                }
              />
            );
          }}
          onLoadMore={() => worksheets.fetchMore()}
        />
        {!_.get(worksheets, 'list.pagination.endReached', true) && (
          <Box
            flex={1}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              margin: '1rem'
            }}
          >
            <PrimaryButton
              green
              onClick={() => worksheets.fetchMore()}
              isLoading={_.get(worksheets, 'list.status') === 'fetching'}
              isDisabled={_.get(worksheets, 'list.status') === 'fetching'}
            >
              Load More
            </PrimaryButton>
          </Box>
        )}
      </Box>
    );
  };

  renderTabsProjectInfo = () => {
    const { projects } = this.props;
    const settings = _.get(projects, 'item.data.settings');

    return (
      <Box>
        <Box
          style={{
            borderBottom: `1px solid ${COLORS.GREY.LIGHER}`,
            padding: '2rem'
          }}
        >
          <SubHeading grey style={{ margin: '2rem 0' }}>
            Details
          </SubHeading>
          <Grid gutter={0} columns={3} style={{ margin: '2rem 0' }}>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Sales Agency
                </Tiny>
                <Body>{projects.item.data.sales_agency || '-'}</Body>
              </Box>
            </Column>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Local Area Government
                </Tiny>
                <Body>{projects.item.data.local_government_area || '-'}</Body>
              </Box>
            </Column>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Project Dates
                </Tiny>
                <Body>
                  {this.getFormattedProjectDates(
                    projects.item.data.estimated_start_date,
                    projects.item.data.estimated_completion_date
                  )}
                </Body>
              </Box>
            </Column>
          </Grid>
          <Grid gutter={0} columns={3} style={{ margin: '2rem 0' }}>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Estimated Total Number of Allotments
                </Tiny>
                <Body>{projects.item.data.estimated_lots || '-'}</Body>
              </Box>
            </Column>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Precinct Structure Plan
                </Tiny>
                <Body>{projects.item.data.precinct_structure_plan || '-'}</Body>
              </Box>
            </Column>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Sub-Region
                </Tiny>
                <Body>
                  {_.get(projects, 'item.data.sub_region.name') || '-'}
                </Body>
              </Box>
            </Column>
          </Grid>
          <Grid gutter={0} columns={2} style={{ margin: '2rem 0' }}>
            <Box>
              <Tiny grey style={{ margin: '1rem 0' }}>
                Summary
              </Tiny>
              <Body>{projects.item.data.summary || '-'}</Body>
            </Box>
          </Grid>
        </Box>

        <Box
          style={{
            borderBottom: `1px solid ${COLORS.GREY.LIGHER}`,
            padding: '2rem'
          }}
        >
          <SubHeading grey style={{ margin: '2rem 0' }}>
            Area
          </SubHeading>
          <Grid gutter={0} columns={4} style={{ margin: '2rem 0' }}>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Total Area
                </Tiny>
                <Body>
                  {formatNumber(_.get(settings, 'total_area', 0) || 0)} ha
                </Body>
              </Box>
            </Column>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Net Developable Area
                </Tiny>
                <Body>
                  {formatNumber(
                    _.get(settings, 'net_developable_area', 0) || 0
                  )}{' '}
                  ha
                </Body>
              </Box>
            </Column>
            <Column width={2}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Estimate Size Range
                </Tiny>
                <Body>
                  {formatNumber(
                    _.get(settings, 'expected_lot_size_min', 0) || 0
                  )}{' '}
                  m2 -{' '}
                  {formatNumber(
                    _.get(settings, 'expected_lot_size_max', 0) || 0
                  )}{' '}
                  m2
                </Body>
              </Box>
            </Column>
          </Grid>
        </Box>

        <Box style={{ padding: '2rem' }}>
          <SubHeading grey style={{ margin: '2rem 0' }}>
            Pricing
          </SubHeading>
          <Grid gutter={0} columns={4} style={{ margin: '2rem 0' }}>
            <Column width={1}>
              <Box>
                <Tiny grey style={{ margin: '1rem 0' }}>
                  Expected Price Range
                </Tiny>
                <Body>
                  $
                  {formatNumber(
                    _.get(settings, 'expected_price_range_min', 0) || 0
                  )}{' '}
                  - $
                  {formatNumber(
                    _.get(settings, 'expected_price_range_max', 0) || 0
                  )}
                </Body>
              </Box>
            </Column>
            <Column width={6} />
          </Grid>
        </Box>
      </Box>
    );
  };

  render() {
    const { styles: s, projects, worksheets, can } = this.props;
    const stats = _.get(projects, 'item.data.stats');
    const project = _.get(projects, 'item.data');

    const isLoading =
      _.get(projects, 'item.status') === 'loading' ||
      _.get(worksheets, 'list.status') === 'loading';

    const isError =
      _.get(projects, 'item.status') === 'error' ||
      _.get(worksheets, 'list.status') === 'error';

    if (isLoading) {
      return <LoadingLayout />;
    }

    if (isError) {
      const error =
        _.get(projects, 'item.status') === 'error'
          ? _.get(projects, 'item.errors[0]')
          : _.get(worksheets, 'list.errors[0]');
      return <ErrorLayout error={error} />;
    }

    const allotmentsRemaining = _.get(
      stats,
      'estimated_allotments_remaining',
      0
    );
    const estimatedAllotmentsRemaining =
      allotmentsRemaining > 0 ? allotmentsRemaining : 0;

    return (
      <Fragment>
        <Portal target="header">
          <Box
            style={{ height: '100%', alignItems: 'center', padding: '4rem' }}
            flex={1}
          >
            {can('create:projects') && (
              <Link to={ROUTES.APP.PROJECTS_CURRENT.NEW_PROJECT}>
                {({ onClick }) => (
                  <AddButton onClick={onClick}>Add Project</AddButton>
                )}
              </Link>
            )}
          </Box>
        </Portal>
        <Grid gutter={0} columns={12}>
          <Column width={12}>
            <Box {...s('breadcrumb')}>
              <Breadcrumb
                crumbs={[
                  {
                    label: 'Dashboard',
                    route: '/app/projects/current'
                  },
                  {
                    label: 'Projects',
                    route: '/app/projects/current'
                  },
                  {
                    label: projects.item.data.title
                  }
                ]}
              />
            </Box>
          </Column>
        </Grid>
        <Grid gutter={0} columns={12}>
          <SectionColumn width={12}>
            <Box {...s('container')}>
              <Box {...s('row')} flex={1}>
                <Heading styles={{ justifyContent: 'space-between' }}>
                  {projects.item.data.title}
                </Heading>
                <Body {...s('status')}>
                  {_.get(projects, 'item.data.status', '').replace(/_/, ' ')}
                </Body>
              </Box>
              <Box {...s('row')} flex={1}>
                <Box flex={1} flexDirection={'column'}>
                  <span>{projects.item.data.developer}</span>
                  <span>
                    {formatAddress(
                      _.get(projects, 'item.data.sales_office_address', ''),
                      _.get(projects, 'item.data.suburb', ''),
                      _.get(projects, 'item.data.postcode', '')
                    )}
                  </span>
                </Box>
                {can('update:projects') && (
                  <ClearButton
                    onClick={() =>
                      this.setState({ showEditProjectModal: true })
                    }
                  >
                    Edit Project
                  </ClearButton>
                )}
              </Box>
            </Box>
          </SectionColumn>
        </Grid>
        <Grid gutter={0} columns={12}>
          <SectionColumn width={4} styles={{ container: { height: '100%' } }}>
            <StatBox
              text="Allotments Sold To Date"
              number={_.get(stats, 'allotments_sold_to_date', 0)}
            />
          </SectionColumn>

          <SectionColumn width={4} styles={{ container: { height: '100%' } }}>
            <StatBox
              text="Estimated Allotments Remaining"
              number={estimatedAllotmentsRemaining}
            />
          </SectionColumn>

          <SectionColumn width={4} styles={{ container: { height: '100%' } }}>
            <StatBox
              text="Estimated % Sold"
              number={_.get(stats, 'estimated_percent_sold', 0)}
            />
          </SectionColumn>
        </Grid>

        <Grid gutter={0} columns={12}>
          <SectionColumn>
            <TabsStateful
              initialTab={'worksheets'}
              items={[
                {
                  name: 'worksheets',
                  label: 'Worksheets',
                  Tab: this.renderTabsWorksheets
                },
                {
                  name: 'projectInfo',
                  label: 'Project Information',
                  Tab: this.renderTabsProjectInfo
                }
              ]}
            />
          </SectionColumn>
        </Grid>
        {this.state.addWorksheetModal && (
          <WorksheetModal
            projectId={getProjectId(this.props)}
            onSubmit={() => worksheets.refreshList({ id: project.id })}
            closeModal={() => this.setState({ addWorksheetModal: false })}
          />
        )}

        {this.state.showEditProjectModal && (
          <Modal>
            <NewProjectForm
              title="Edit Project"
              closeModal={() => this.setState({ showEditProjectModal: false })}
              project={project}
            />
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default ViewProjectScreen;
