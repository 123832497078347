import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import projectsModel from 'data/models/entities/projects';
import { withModel } from '@rexlabs/model-generator';
import { COLORS } from 'src/theme';
import DownArrow from 'src/assets/down-arrow.svg';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500',
    color: COLORS.GREY.DARK
  },
  container: {
    padding: '1rem 0',
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
  },
  clickable: {
    cursor: 'pointer'
  }
});

@withModel(projectsModel)
@styled(defaultStyles)
class ProjectLotListHeader extends PureComponent {
  getArrow = () => {
    const { order } = this.props;
    return (
      <Fragment>
        {order.direction === 'asc' ? (
          <DownArrow style={{ transform: 'rotate(180deg)' }} />
        ) : (
          <DownArrow />
        )}
      </Fragment>
    );
  };

  render() {
    const { styles: s, checked, onSelectAll, order, onOrder } = this.props;
    return (
      <Box {...s('container')}>
        <Grid gutter={0} columns={39} style={{ alignItems: 'center' }}>
          <Column width={1} />
          <Column width={1} onClick={onSelectAll}>
            <Checkbox id={'test'} value={!!checked} />
          </Column>
          <Column width={4}>
            <Small
              {...s('text', 'clickable')}
              onClick={() => onOrder('stage_no')}
            >
              Stage No. {order.field === 'stage_no' && this.getArrow()}
            </Small>
          </Column>
          <Column width={4}>
            <Small
              {...s('text', 'clickable')}
              onClick={() => onOrder('lot_number')}
            >
              Lot No. {order.field === 'lot_number' && this.getArrow()}
            </Small>
          </Column>
          <Column width={4}>
            <Small
              {...s('text', 'clickable')}
              onClick={() => onOrder('lot_size')}
            >
              Lot Size {order.field === 'lot_size' && this.getArrow()}
            </Small>
          </Column>
          <Column width={4}>
            <Small
              {...s('text', 'clickable')}
              onClick={() => onOrder('frontage')}
            >
              Frontage {order.field === 'frontage' && this.getArrow()}
            </Small>
          </Column>
          <Column width={4}>
            <Small {...s('text', 'clickable')} onClick={() => onOrder('price')}>
              Price {order.field === 'price' && this.getArrow()}
            </Small>
          </Column>
          <Column width={4}>
            <Small
              {...s('text', 'clickable')}
              onClick={() => onOrder('status')}
            >
              Status {order.field === 'status' && this.getArrow()}
            </Small>
          </Column>
          <Column width={4}>
            <Small
              {...s('text', 'clickable')}
              onClick={() => onOrder('titled')}
            >
              Titled {order.field === 'titled' && this.getArrow()}
            </Small>
          </Column>
          <Column width={4}>
            <Small {...s('text', 'clickable')} onClick={() => onOrder('depth')}>
              Depth {order.field === 'depth' && this.getArrow()}
            </Small>
          </Column>
          <Column width={4}>
            <Small
              {...s('text', 'clickable')}
              onClick={() => onOrder('price_per_m2')}
            >
              $/m2 {order.field === 'price_per_m2' && this.getArrow()}
            </Small>
          </Column>
          <Column width={1} />
        </Grid>
      </Box>
    );
  }
}

export default ProjectLotListHeader;
