/*
|-------------------------------------------------------------------------------
| Singleton App Store (Redux)
|-------------------------------------------------------------------------------
|
| It's inclusion in other modules in the App should ideally come from dependency
| injection techniques to keep those files testable.
|
*/

import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { configureStore, apiClientMiddleware } from 'utils/redux';
import {
  connectionReducer as connection,
  connectionMiddleware
} from 'utils/connection-status';
import localForage from 'localforage';
import { combineModels } from '@rexlabs/model-generator';

/*
| Core Models
|------------------------
*/
import session from 'data/models/custom/session';

/*
 | Routing / Location Management (whereabouts)
 |------------------------
 */
import {
  whereaboutsReducer as whereabouts,
  whereaboutsMiddleware
} from '@rexlabs/whereabouts';

/*
 | Entity Models (Model Generator)
 |------------------------
 */
import projects from 'data/models/entities/projects';
import worksheets from 'data/models/entities/worksheets';
import stages from 'data/models/entities/stages';
import lots from 'data/models/entities/lots';
import periods from 'data/models/entities/periods';
import stats from 'data/models/entities/stats';
import accounts from 'data/models/entities/accounts';
import regions from 'data/models/entities/regions';
import regionProjects from 'data/models/entities/region-projects';
import settings from 'data/models/entities/settings';
import settingProjects from 'data/models/entities/setting-projects';
import settingWorksheets from 'data/models/entities/setting-worksheets';
import guestTokens from 'data/models/entities/guest-tokens';
import exports from 'data/models/entities/export';

/*
| Setup for Stores
|------------------------
*/
const entities = combineModels('entities', {
  projects,
  worksheets,
  stages,
  lots,
  periods,
  stats,
  accounts,
  regions,
  regionProjects,
  settings,
  settingProjects,
  settingWorksheets,
  guestTokens,
  exports
});

// Persistors
const persistSession = persistReducer(
  {
    key: 'core.session',
    storage: localForage,
    whitelist: ['apiToken', 'accountId']
  },
  session
);

const reducers = combineReducers({
  session: persistSession,
  connection,
  whereabouts,
  entities
});

const store = configureStore(reducers, [
  apiClientMiddleware,
  connectionMiddleware,
  whereaboutsMiddleware
]);

const persistedStore = persistStore(store, null, () => {
  // store.dispatch(session.actionCreators.init()).catch(console.warn);
});

if (__DEV__) {
  window.purgePersistedStore = persistedStore.purge;
}

export { store };
