import React from 'react';
import { Heading, SubHeading } from 'view/components/text';

export interface PopularStatProps {
  value: string | string[];
  maxLength?: number;
}

const PopularStat = ({ value, maxLength }: PopularStatProps) => {
  // Handle array
  if (Array.isArray(value) && value.length > 1) {
    if (maxLength && value.length > maxLength) {
      return <Heading>-</Heading>;
    }

    return (
      <ul>
        {value?.map?.((value: string, index: number) => (
          <li key={index}>
            <SubHeading>{value}</SubHeading>
          </li>
        ))}
      </ul>
    );
  }

  // handle Value
  return <Heading>{value}</Heading>;
};

export default PopularStat;
