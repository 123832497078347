import React, { Fragment, PureComponent } from 'react';
import Box from '@rexlabs/box';
import PropTypes from 'prop-types';
import { COLORS, PADDINGS } from 'src/theme';
import { Portal } from '@rexlabs/portal';
import { withModel } from '@rexlabs/model-generator';
import statsModel from 'data/models/entities/stats';
import lotsModel from 'data/models/entities/lots';
import withError from 'view/containers/with-error';
import { styled, StyleSheet } from '@rexlabs/styling';
import ReportTitle from 'view/components/report-title';
import OverallCorridor from './overall-corridor';
import EstateBreakdown from './estate-breakdown';
import PriceSummary from './price-summary';
import SalesPerMonth from 'view/components/reports/overview/sales-per-month';
import PriceSizeGraph from './price-size-graph';

const defaultStyles = StyleSheet({
  row: {
    padding: `${PADDINGS.XS} 0`
  },
  divider: {
    paddingTop: PADDINGS.S,
    marginBottom: PADDINGS.S,
    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`
  }
});

@withError.withPropName('errorModal')
@withModel(lotsModel)
@withModel(statsModel)
@styled(defaultStyles)
class Overview extends PureComponent {
  handleError(e) {
    const { errorModal } = this.props;
    errorModal.open(
      `The following error has ocurred: "${e}". Please check your settings and try again.`
    );
  }

  render() {
    const {
      stats,
      breakdownStats,
      overviewStats,
      projects,
      primaryProject,
      period,
      settings,
      errorModal: { Error }
    } = this.props;

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle
            period={period}
            project={primaryProject}
            settings={settings}
          />
        </Portal>

        {/* OVERALL */}
        <OverallCorridor overviewStats={overviewStats} />

        {/* ESTATE BREAKDOWN */}
        <EstateBreakdown
          breakdownStats={breakdownStats}
          primary={primaryProject}
          period={period}
        />

        {/* PRICE / SIZE DATA SNAPSHOT */}
        <PriceSummary
          priceSizeStats={stats?.['price_size_stats']}
          primary={primaryProject}
          projects={projects}
        />

        <Box flexDirection={'row'}>
          <Box width={'50%'}>
            <SalesPerMonth
              title={'Ave. Gross Sales Per Month'}
              accessor={'gross'}
              salesPerMonth={stats}
              period={period}
              primary={primaryProject}
            />
          </Box>
          <Box width={'50%'}>
            <SalesPerMonth
              title={'Ave. Net Sales Per Month'}
              accessor={'net'}
              salesPerMonth={stats}
              period={period}
              primary={primaryProject}
            />
          </Box>
        </Box>

        <PriceSizeGraph stats={stats} period={period} />

        <Error />
      </Fragment>
    );
  }
}

Overview.propTypes = {
  stats: PropTypes.array.isRequired,
  breakdownStats: PropTypes.array.isRequired,
  overviewStats: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  primaryProject: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired
};

export default Overview;
