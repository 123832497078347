import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      Time on Market displays the average number of months each traditional lot
      type takes to sell.{' '}
      <span className="italic">
        Updated outlier removal methodology increasing analysed sample size.
      </span>
      <br />
      <br />
      Market Share displays the sold popularity of each traditional lot type.{' '}
      <span className="italic">
        Percentages are based on <span className="bold">all lot types</span>{' '}
        within the period selected - including those not in the table.
      </span>
      <br />
      <br />
      To view a longer period of time, the{' '}
      <span className="bold">date slider can be dragged</span> to the left.
      <br />
      <br />
      Note: Lots that feature in the table{' '}
      <span className="bold">exactly match frontage, depth & size</span>.
      Therefore corners & irregular lots are excluded from the analysis.
    </>
  );
};

export default InfoTooltip;
