import React, { Fragment } from 'react';
import { AddButton, PrimaryButton } from 'view/components/button';
import { Heading } from 'view/components/text';
import ROUTES from 'src/routes';
import { Link } from '@rexlabs/react-whereabouts';
import projectsModel from 'data/models/entities/projects';
import sessionModel from 'data/models/custom/session';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import List from '@rexlabs/list';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { Portal } from '@rexlabs/portal';
import ProjectListItem from 'view/components/list/project-list-item';
import ProjectListHeader from 'view/components/list/project-list-header';
import { Grid, Column } from '@rexlabs/grid';
import SectionColumn from 'view/components/grid/section-column';
import { styled, StyleSheet } from '@rexlabs/styling';
import LoadingLayout from 'view/layouts/loading';
import withAction from 'view/containers/with-action';
import withError from 'view/containers/with-error';
import withPermission from 'view/containers/with-permissions';

import Breadcrumb from '../../components/breadcrumb/breadcrumb';

const defaultStyles = StyleSheet({
  header: {
    margin: '2rem 2rem',
    justifyContent: 'space-between'
  },
  breadcrumb: {
    marginLeft: '0.8rem',
    marginTop: '0.8rem'
  }
});

const projectsQuery = query`{
  ${projectsModel} (include: "settings,sub_region") {
    id
    account_id
    sub_region_id
    title
    density
    website
    developer
    sales_office_address
    suburb
    postcode
    status
    local_government_area
    sales_agency
    precinct_structure_plan
    image_id
    estimated_lots
    estimated_number_of_stages
    estimated_start_date
    estimated_completion_date
    archived_at
    trashed_at
    active
    sales_volume_offset
    summary
    settings
    created_at
    sub_region
    region
    state
  }
}`;

@withPermission()
@withAction.withPropName('actionModal')
@withError.withPropName('errorModal')
@withQuery(projectsQuery)
@withModel(sessionModel, 'session')
@styled(defaultStyles)
class CurrentProjectsScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showAlertModal: false,
      showErrorModal: false,
      showRecoverModal: false,
      nameOrderDesc: null,
      regionOrderDesc: null,
      dateOrderDesc: null,
      checked: {}
    };
    this.archiveProjects = this.archiveProjects.bind(this);
  }

  orderByName = () => {
    this.props.projects.list.items.sort((a, b) => {
      if (this.state.nameOrderDesc) {
        return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
      } else return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
    });
    this.setState({
      nameOrderDesc: !this.state.nameOrderDesc
    });
  };

  orderByRegion = () => {
    this.props.projects.list.items.sort((a, b) => {
      if (this.state.regionOrderDesc) {
        return _.get(a, 'sub_region.name', '').toLowerCase() >
          _.get(b, 'sub_region.name', '').toLowerCase()
          ? -1
          : 1;
      } else {
        return _.get(a, 'sub_region.name', '').toLowerCase() <
          _.get(b, 'sub_region.name', '').toLowerCase()
          ? -1
          : 1;
      }
    });
    this.setState({
      regionOrderDesc: !this.state.regionOrderDesc
    });
  };

  orderByDate = () => {
    this.props.projects.list.items.sort((a, b) => {
      if (this.state.dateOrderDesc) return a.created_at > b.created_at ? -1 : 1;
      else return a.created_at < b.created_at ? -1 : 1;
    });
    this.setState({
      dateOrderDesc: !this.state.dateOrderDesc
    });
  };

  archiveProjects() {
    const { projects, actionModal, errorModal } = this.props;
    // Filter down to the checked projects
    const checkedProjects = _.map(this.state.checked, (value, key) => {
      return { id: key, value: value };
    }).filter((value) => value.value);

    // Execute all the requests
    Promise.all(
      checkedProjects.map((project) =>
        projects.archive({
          id: project.id
        })
      )
    )
      .then(() => {
        projects.refreshList();
        this.setState({ checked: {} });
        actionModal.close();
      })
      .catch((e) => {
        actionModal.close();
        errorModal.open(e);
        projects.refreshList();
      });
  }

  render() {
    const {
      styles: s,
      projects,
      can,
      actionModal: { Action },
      errorModal: { Error }
    } = this.props;

    if (_.get(projects, 'list.status') !== 'loaded') {
      return <LoadingLayout />;
    }

    return (
      <Fragment>
        <Portal target="header">
          <Box
            style={{ height: '100%', alignItems: 'center', padding: '4rem' }}
            flex={1}
          >
            {can('create:projects') && (
              <Link to={ROUTES.APP.PROJECTS_CURRENT.NEW_PROJECT}>
                {({ onClick }) => (
                  <AddButton onClick={onClick}>Add Project</AddButton>
                )}
              </Link>
            )}
          </Box>
        </Portal>
        <Grid gutter={0} columns={12}>
          <Column width={12}>
            <Box {...s('breadcrumb')}>
              <Breadcrumb
                crumbs={[
                  {
                    label: 'Dashboard',
                    route: '/'
                  },
                  {
                    label: 'Projects'
                  }
                ]}
              />
            </Box>
          </Column>
        </Grid>
        <Grid gutter={0} columns={12}>
          <SectionColumn width={12}>
            <Box {...s('container')}>
              <Box flex={1} {...s('header')}>
                <Heading>Project Listings</Heading>
                {_.filter(this.state.checked, (value) => value).length > 0 && (
                  <PrimaryButton
                    red
                    onClick={() =>
                      this.props.actionModal.open(
                        this.archiveProjects,
                        'Are you sure you want to archive this Project Listing(s) and all associated worksheets?',
                        'Archive Project(s)?',
                        true
                      )
                    }
                  >
                    Archive Project(s)
                  </PrimaryButton>
                )}
              </Box>
              <List
                items={_.get(projects, 'list.items', [])}
                isLoading={_.get(projects, 'list.status') === 'loading'}
                endReached={_.get(projects, 'list.pagination.endReached')}
                Header={() => {
                  return (
                    <ProjectListHeader
                      handleOrderByName={this.orderByName}
                      handleOrderByRegion={this.orderByRegion}
                      handleOrderByDate={this.orderByDate}
                      checked={
                        _.filter(this.state.checked, (value) => value)
                          .length === projects.list.items.length
                      }
                      onSelectAll={() => {
                        if (
                          _.filter(this.state.checked, (value) => value)
                            .length === projects.list.items.length
                        ) {
                          this.setState({
                            checked: {}
                          });
                        } else {
                          const toggledState = projects.list.items.reduce(
                            (obj, item) => {
                              return { ...obj, [item.id]: true };
                            },
                            {}
                          );
                          this.setState({
                            checked: toggledState
                          });
                        }
                      }}
                    />
                  );
                }}
                renderItem={(item) => {
                  return (
                    <ProjectListItem
                      project={item}
                      projects={this.props.projects}
                      checked={this.state.checked[item.id]}
                      toggle={() =>
                        this.setState({
                          checked: {
                            ...this.state.checked,
                            ...{
                              [item.id]: !this.state.checked[item.id]
                            }
                          }
                        })
                      }
                    />
                  );
                }}
              />
              {!_.get(projects, 'list.pagination.endReached', true) && (
                <Box
                  flex={1}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '1rem'
                  }}
                >
                  <PrimaryButton
                    green
                    onClick={() => projects.fetchMore()}
                    isLoading={_.get(projects, 'list.status') === 'fetching'}
                    isDisabled={_.get(projects, 'list.status') === 'fetching'}
                  >
                    Load More
                  </PrimaryButton>
                </Box>
              )}
            </Box>
          </SectionColumn>
        </Grid>
        <Action />
        <Error />
      </Fragment>
    );
  }
}

export default CurrentProjectsScreen;
