import React, { Dispatch, SetStateAction, useContext } from 'react';
import Box from '@rexlabs/box';
import { Portal } from '@rexlabs/portal';
import { styled, StyleSheet, Styles, keyframes } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/features/reports/theme';
import Modal from './modal';
import { DefaultButton } from '../button';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import CloseIcon from '../load-report-modal/close-icon';

const fadeIn = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opactity: 1
  }
});

const styles = StyleSheet({
  title: {
    fontSize: '28px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 700
  },
  paragraph: {
    fontSize: '14px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 400
  },
  subTitle: {
    fontSize: '16px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 500,
    marginBottom: '10px'
  },
  list: {
    listStyle: 'inside'
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 320,
    animation: `${fadeIn} 0.3s ease-in-out`
  },
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: '4px',
    width: 688,
    maxHeight: '90vh',
    overflowY: 'auto'
  },
  close: {
    background: 'none',
    outline: 'none',
    border: 'none',
    display: 'flex',
    padding: 0,
    cursor: 'pointer'
  }
});

interface AccessModalProps {
  styles: Styles;
  setIsAccessOpen: Dispatch<SetStateAction<boolean>>;
}

const AccessModal = ({ styles: s, setIsAccessOpen }: AccessModalProps) => {
  const { premiumSubRegions } = useContext(ReportSettingsContext);

  return (
    <Portal target="custom-dialogs">
      <div {...s('backdrop')}>
        <Box p="24px" {...s('container')}>
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="10px"
          >
            <h3 {...s('title')}>Access</h3>
            <button {...s('close')} onClick={() => setIsAccessOpen(false)}>
              <CloseIcon />
            </button>
          </Box>
          <p {...s.with('paragraph')({ marginBottom: '12px' })}>
            As a GRIP user, you can choose to subscribe to one or more
            subregions, which will give you access to even greater insights and
            tooling.
          </p>
          <p {...s.with('paragraph')({ marginBottom: '24px' })}>
            By default, you are able to access the{' '}
            <span style={{ fontStyle: 'italic' }}>Key Insights</span> page for
            any subregion. Upgrading your subscription for a subregion will gain
            you enhanced insights in the{' '}
            <span style={{ fontStyle: 'italic' }}>Product Trends</span>,{' '}
            <span style={{ fontStyle: 'italic' }}>Project Positioning</span> and
            <span style={{ fontStyle: 'italic' }}>Project Breakdown pages</span>
            .
          </p>
          <h4 {...s('subTitle')}>Your current subscription:</h4>
          <h5 {...s.with('subTitle')({ fontSize: '12px' })}>PREMIUM REGIONS</h5>
          <ul {...s.with('list')({ marginBottom: '16px' })}>
            {premiumSubRegions.map((subRegion) => (
              <li
                key={subRegion.id}
                {...s.with('paragraph')({ fontSize: '14px' })}
              >
                {subRegion.name}
              </li>
            ))}
          </ul>
          <h5 {...s.with('subTitle')({ fontSize: '12px' })}>
            STANDARD REGIONS
          </h5>
          <p {...s.with('paragraph')({ marginBottom: '16px' })}>
            You receive access to the{' '}
            <span style={{ fontStyle: 'italic' }}>Key Insights</span> page for
            all subregions.
          </p>
          <h5 {...s.with('subTitle')({ fontSize: '12px' })}>MAP</h5>
          <p {...s.with('paragraph')({ marginBottom: '16px' })}>
            You have access to GRIP’s interactive map tool.
          </p>
          <p
            {...s.with('paragraph')({
              marginBottom: '20px',
              paddingTop: '8px'
            })}
          >
            Contact your account owner or GRIP’s dedicated support to upgrade
            your subscription level.
          </p>
        </Box>
      </div>
    </Portal>
  );
};

export default styled(styles)(AccessModal);
