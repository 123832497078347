export const SCOPES = [
  'reports:insights-page',
  'reports:trends-page',
  'reports:positioning-page',
  'reports:breakdown-page',
  'reports:manage-billing',
  'export:all-lots',
  'admin-app:delete-projects',
  'admin-app:publish-worksheets',
  'admin-app:general-access'
];
