import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import 'rc-slider/assets/index.css';

const defaultStyles = StyleSheet({
  container: {
    gap: 16
  }
});

const RangeSliderWrapper = ({ styles, children }) => {
  return (
    <Box
      alignItems="center"
      justifyContent="space-between"
      {...styles('container')}
    >
      {children}
    </Box>
  );
};

export default styled(defaultStyles)(RangeSliderWrapper);
