import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const LeafOutline = (props: SVGProps<null>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3681 9.9485C12.0258 9.9485 9.31641 12.6579 9.31641 16.0002C9.31641 19.3424 12.0258 22.0518 15.3681 22.0518C20.2981 22.0518 21.5081 15.9985 24.3331 14.3852C22.2269 11.5569 18.8942 9.90758 15.3681 9.9485V9.9485Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6667 31H9.33333C4.73096 31 1 27.269 1 22.6667V9.33333C1 4.73096 4.73096 1 9.33333 1H22.6667C27.269 1 31 4.73096 31 9.33333V22.6667C31 27.269 27.269 31 22.6667 31Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0975 15.3418C12.7132 15.908 9.7307 17.8896 7.89746 20.7901"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeafOutline;
