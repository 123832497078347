/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useCallback } from 'react';
import withError from 'view/containers/with-error';
import { withModel } from '@rexlabs/model-generator';
import statsModel from 'data/models/entities/stats';
import { StateContext } from 'view/layouts/report';

interface WithValidateProps {
  error: any;
  stats: any;
  match: {
    params: {
      id: number;
    };
    query: {
      period: string;
      token: string;
    };
  };
}

const getSettingId = (props: WithValidateProps): number =>
  props.match?.params?.id;
const getPeriod = (props: WithValidateProps): string =>
  props.match?.query?.period;
const getToken = (props: WithValidateProps): string =>
  props.match?.query?.token;

/**
 * Wrap a component to check if the current setting has valid worksheets.
 *
 * @param Component
 */
const withValidate = <T extends WithValidateProps>(
  Component: React.ComponentType
) => {
  return withModel(statsModel)(
    withError((props: T) => {
      const [{ draftModalDismissed }, dispatch] = useContext(StateContext);
      const {
        error: { Error, ...errorModal },
        stats
      } = props;

      const settingId: number = getSettingId(props);
      const period: string = getPeriod(props);
      const token: string = getToken(props);

      const handleError = useCallback(
        (e: Error) => {
          errorModal.open(e, 'Draft Worksheets', () =>
            dispatch({ type: 'dismiss', id: settingId })
          );
        },
        [errorModal, dispatch, settingId]
      );

      useEffect(() => {
        if (!token && !draftModalDismissed?.[settingId]) {
          stats
            .checkSettings({
              id: settingId,
              period: period,
              token: token
            })
            .catch(handleError);
        }
      }, [settingId, period, token, draftModalDismissed]);

      return (
        <>
          <Component {...props} />
          <Error />
        </>
      );
    })
  );
};

export default withValidate;
