import {
  CircleCheckOutline,
  MissingDocumentOutline,
  OneHundredOutline,
  PauseOutline,
  WarningOutline
} from 'src/features/reports/components/icons';
import { FlagTooltipType } from './flag-tooltips';

export const FILTER = [
  {
    label: 'Sales Summary',
    key: 'sales_summary'
  },
  {
    label: 'Available Stock',
    key: 'available_stock'
  },
  {
    label: 'Total Supply',
    key: 'total_supply'
  },
  {
    label: 'Median Price/Size',
    key: 'stock_summary'
  }
];

export const flagData: FlagTooltipType[] = [
  {
    title: 'On Hold',
    Icon: PauseOutline,
    description: 'This project is currently on-hold.'
  },
  {
    title: 'Low Confidence',
    Icon: WarningOutline,
    description: `This project’s data is currently unreliable`
  },
  {
    title: 'Tail Ending/Small Project',
    Icon: OneHundredOutline,
    description:
      'This project has less than 100 lots remaining, either because it was a small development or is approaching project completion.'
  },
  {
    title: 'Latest Land Data Unavailable',
    Icon: MissingDocumentOutline,
    description: 'Land data for this month was unavailable.'
  },
  {
    title: 'Project Completed',
    Icon: CircleCheckOutline,
    description: 'This project has been completed, meaning all lots have sold.'
  }
];
