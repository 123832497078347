import React, { SVGProps } from 'react';
import { COLORS } from '../../theme';

const BrokenCalendar = (props: SVGProps<null>) => {
  return (
    <svg
      id="Layer_3"
      data-name="Layer 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="16"
      height="16"
      {...props}
    >
      <rect y="0.00001" width="24" height="24" fill="none" />
      <line
        x1="7.5"
        y1="3.00001"
        x2="7.5"
        y2="6.00001"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="16.5"
        y1="3.00001"
        x2="16.5"
        y2="6.00001"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="17.25"
        y1="9.25001"
        x2="15.75"
        y2="10.75001"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <line
        x1="17.25"
        y1="10.75001"
        x2="15.75"
        y2="9.25001"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9.94831,13.66639H8"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M8,13.66639v1.94882"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M8.25409,13.66639a4.40985,4.40985,0,0,0,1.76412,1.76412"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M16,14.4479a4.92455,4.92455,0,0,1-5.98145,1.00424"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.45,10a.05.05,0,1,1,.05006.05006A.05.05,0,0,1,7.45,10"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11.95,10a.05.05,0,1,1,.05006.05006A.05.05,0,0,1,11.95,10"
        fill="none"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <rect
        x="3"
        y="4.50001"
        width="18"
        height="16.5"
        rx="3"
        strokeWidth="1.5"
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default BrokenCalendar;
