import React from 'react';

const MenuIcon = () => {
  return (
    <svg
      width="16"
      height="36"
      viewBox="0 0 16 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99555 21.6682C7.81214 21.6682 7.66208 21.8182 7.66408 22.0017C7.66408 22.1851 7.81414 22.3351 7.99755 22.3351C8.18096 22.3351 8.33103 22.1851 8.33103 22.0017C8.33103 21.8182 8.18096 21.6682 7.99555 21.6682"
        stroke="#0B1E49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99555 17.6665C7.81214 17.6665 7.66208 17.8166 7.66408 18C7.66408 18.1834 7.81414 18.3335 7.99755 18.3335C8.18096 18.3335 8.33103 18.1834 8.33103 18C8.33103 17.8166 8.18096 17.6665 7.99555 17.6665"
        stroke="#0B1E49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99555 13.6649C7.81214 13.6649 7.66208 13.8149 7.66408 13.9983C7.66408 14.1817 7.81414 14.3318 7.99755 14.3318C8.18096 14.3318 8.33103 14.1817 8.33103 13.9983C8.33103 13.8149 8.18096 13.6649 7.99555 13.6649"
        stroke="#0B1E49"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenuIcon;
