import React, { PureComponent } from 'react';
import Button from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/theme';
import LoadingSpinner from '@rexlabs/loading-spinner';
import _ from 'lodash';

const getStyles = _.memoize((color, fullWidth) =>
  StyleSheet(
    {
      container: {
        background: color,
        height: '4.2rem',
        width: fullWidth ? '100%' : 'auto',
        padding: '0 1.2rem',
        border: '0 none',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.5rem',
        fontWeight: FONT.WEIGHTS.SEMIBOLD
      }
    },
    `${color}-${fullWidth}`
  )
);

class PrimaryButton extends PureComponent {
  render() {
    const { green, red, fullWidth, isDisabled, isLoading } = this.props;
    const color = green
      ? COLORS.BLUE_GREEN
      : red
      ? COLORS.RED
      : COLORS.GREY.MEDIUM;

    return (
      <Button
        Loading={() => <LoadingSpinner colors={['white']} />}
        styles={getStyles(color, fullWidth)}
        isDisabled={isDisabled || isLoading}
        {...this.props}
      />
    );
  }
}

export default PrimaryButton;
