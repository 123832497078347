import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { ...args }) =>
        api.get('/regions?include=projects', args)
    }
  }
};

const RegionsGenerator = new Generator('regions', config);

export default RegionsGenerator.createEntityModel();
