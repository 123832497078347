import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500',
    color: COLORS.GREY.DARK
  },
  container: {
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
  },
  grid: {
    '> *': {
      padding: '1rem 2rem'
    }
  }
});

@styled(defaultStyles)
class PeriodWorksheetListHeader extends PureComponent {
  render() {
    const { styles: s, checked, onSelectAll } = this.props;
    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={24}
          style={{ alignItems: 'center' }}
          {...s('grid')}
        >
          <Column width={1} onClick={onSelectAll}>
            <Checkbox value={checked} />
          </Column>
          <Column width={5}>
            <Small {...s('text')}>Project Name</Small>
          </Column>
          <Column width={5}>
            <Small {...s('text')}>Date Created</Small>
          </Column>
          <Column width={5}>
            <Small {...s('text')}>Last Edited</Small>
          </Column>
          <Column width={7}>
            <Small {...s('text')}>Worksheet Status</Small>
          </Column>
          <Column width={1} />
        </Grid>
      </Box>
    );
  }
}

export default PeriodWorksheetListHeader;
