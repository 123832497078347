import React from 'react';
import Box from '@rexlabs/box';

import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { Label, Tiny } from 'view/components/text';
import { COLORS } from 'src/features/reports/theme';
import FeaturedBoxSubLabel, {
  FeaturedBoxSubLabelProps
} from './featured-box-sub-label';

const defaultStyles = StyleSheet({
  featBox: {
    height: 116,
    maxWidth: 160,
    width: 160,
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.GREY.BORDER}`,
    cursor: 'pointer',
    borderRadius: 8,
    padding: '0 10px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      border: `1px solid ${COLORS.BLUE.PRIMARY}`
    },
    textAlign: 'center'
  },
  active: {
    border: `2px solid ${COLORS.BLUE.PRIMARY}`,
    boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.24), 0px 8px 16px rgba(90, 141, 255, 0.2)`,
    '&:hover': {
      border: `2px solid ${COLORS.BLUE.PRIMARY}`
    }
  },
  featBoxLabel: {
    cursor: 'pointer',
    fontFamily: 'DM Sans',
    fontSize: '12px !important',
    color: COLORS.GREY.MEDIUM
  },
  featBoxValue: {
    cursor: 'pointer',
    fontFamily: 'DM Sans',
    fontSize: '28px !important',
    // @ts-expect-error !important is needed here to override styling
    fontWeight: '500 !important',
    color: COLORS.BLUE.DARK,
    lineHeight: 2
  },
  featBoxHeader: {
    height: '100%'
  },
  featBoxFooter: {
    padding: '0 0 13px'
  }
});

export interface FeaturedBoxProps {
  label: string;
  value: string | number;
  onClick?: () => void;
  active?: boolean;
  styles: Styles;
  leftSubLabel?: Omit<FeaturedBoxSubLabelProps, 'styles'>;
  rightSubLabel?: Omit<FeaturedBoxSubLabelProps, 'styles'>;
}

export const FeaturedBox = ({
  styles: s,
  label,
  value,
  onClick,
  active,
  leftSubLabel,
  rightSubLabel
}: FeaturedBoxProps) => {
  const hasFooter = leftSubLabel && rightSubLabel;

  return (
    <Box
      {...s('featBox', { active })}
      flexDirection="column"
      justifyContent="space-between"
      onClick={onClick}
    >
      <Box
        {...s('featBoxHeader')}
        flexDirection="column"
        justifyContent="center"
      >
        <Tiny {...s('featBoxLabel')}>{label}</Tiny>
        <Label {...s('featBoxValue')}>{value}</Label>
      </Box>
      {hasFooter && (
        <Box justifyContent="space-between" {...s('featBoxFooter')}>
          <FeaturedBoxSubLabel {...leftSubLabel} />
          <FeaturedBoxSubLabel {...rightSubLabel} />
        </Box>
      )}
    </Box>
  );
};

export default styled(defaultStyles)(FeaturedBox);
