import React, { useState } from 'react';
import SectionContainer from 'view/components/section-container';
import Box from '@rexlabs/box';
import _ from 'lodash';
import fp from 'lodash/fp';
import { COLORS, PADDINGS } from 'theme';
import { SubHeading, Body } from 'view/components/text';
import Table from 'view/components/table';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from 'view/components/arrow';
import { getGradientPoint, rgbToHex, hexToRgb } from 'utils/colors';
import { inArray, top } from 'utils/array';
import GA from 'react-ga';

export interface SoldPriceVsSizeProps {
  priceSize: any;
}

const SoldPriceVsSize = ({ priceSize }: SoldPriceVsSizeProps) => {
  const [increment, setIncrementValue] = useState<number>(20);
  const setIncrement = (value: number) => {
    GA.event({
      category: 'Price Size Brackets',
      action: 'Toggle Sold Price vs Size Increment',
      label: value.toString()
    });
    setIncrementValue(value);
  };

  const priceSizeStatsRaw = _.map(priceSize[increment], (value, key) => {
    return {
      price: key,
      brackets: _.map(value, (percent, bracket) => ({
        bracket: bracket,
        percent: percent
      }))
    };
  });

  const rowTotals = priceSizeStatsRaw?.map((stat) => ({
    sum: Math.round(fp.sumBy('percent')(stat.brackets) * 100) / 100
  }));

  const columnSums: any[] = priceSizeStatsRaw?.map(
    fp.compose(fp.mapValues('percent'), fp.keyBy('bracket'), fp.get('brackets'))
  );

  const keys = Object.keys(fp.head(columnSums));
  const columnTotals = keys?.map((key) => fp.sumBy(key)(columnSums));

  // Flatten values down to an array of percentages
  const allPercentages = fp.flatMap((row: any) =>
    fp.flatMap((column: any) => column?.percent)(row?.brackets)
  )(priceSizeStatsRaw);

  const topPercentages = top(allPercentages, 10);
  const topRowTotals = top(rowTotals, 6, 'sum', false);
  const topColumnTotals = top(columnTotals, 6, null, false);

  const priceSizeStats = fp.values(fp.merge(rowTotals)(priceSizeStatsRaw));

  const priceSizeStatColumns = priceSizeStats?.[0]?.brackets?.map(
    (value, index) => {
      return {
        Header: () => (
          <p style={{ color: COLORS.BLUE_GREEN }}>
            {value?.bracket === 'Irreg/Cnr Lots'
              ? value.bracket
              : value.bracket + ' m2'}
          </p>
        ),
        width: 150,
        accessor: `brackets.${index}.percent`,
        Cell: (item) => {
          if (item.value === 0) {
            return <Cell>-</Cell>;
          }

          const value = Math.round(item.value * 100) / 100;

          if (inArray(topPercentages, value)) {
            const color1 = fp.values(hexToRgb(COLORS.RED.NEGATIVE));
            const color2 = fp.values(hexToRgb(COLORS.YELLOW));
            const calculatedColor = getGradientPoint(
              color1,
              color2,
              value / 100
            );
            const nc = rgbToHex(
              calculatedColor[0],
              calculatedColor[1],
              calculatedColor[2]
            );
            return <Cell color={nc}>{value}%</Cell>;
          }
          return <Cell>{value}%</Cell>;
        },
        getFooterProps: (state, rowInfo, column) => {
          if (/brackets.\d.percent/.exec(column.id)) {
            return {
              style: {
                margin: 0,
                padding: 0
              }
            };
          }
          return {};
        },
        Footer: (value) => {
          const sum =
            fp.round(fp.sumBy(`brackets.${index}.percent`)(value.data) * 100) /
            100;
          if (inArray(topColumnTotals, sum)) {
            const color1 = fp.values(hexToRgb(COLORS.RED.NEGATIVE));
            const color2 = fp.values(hexToRgb(COLORS.YELLOW));
            const calculatedColor = getGradientPoint(color1, color2, sum / 100);
            const nc = rgbToHex(
              calculatedColor[0],
              calculatedColor[1],
              calculatedColor[2]
            );
            return <Cell color={nc}>{sum}%</Cell>;
          }
          return <Cell>{sum}%</Cell>;
        }
      };
    }
  );

  const columns = fp.concat([
    {
      Header: () => <p style={{ color: COLORS.BLUE_GREEN }}>Price Range</p>,
      width: 150,
      accessor: 'price',
      fixed: 'left',
      Cell: (row) => {
        const intVal = parseInt(row.value);
        if (intVal === 149) return `<$${row.value}k`;
        const upper = intVal + (increment - 1);
        return `$${row.value}k - $${upper}k`;
      },
      style: {
        background: COLORS.BLUE.LIGHT,
        boxShadow: '4px 0px 3px -3px #ccc',
        border: 'none !important',
        fontWeight: '500'
      },
      Footer: 'Total'
    },
    {
      Header: 'Total',
      fixed: 'right',
      accessor: 'sum',
      Cell: (value) => {
        const sum = _.get(value, 'row.sum');
        if (inArray(topRowTotals, sum)) {
          const color1 = fp.values(hexToRgb(COLORS.RED.NEGATIVE));
          const color2 = fp.values(hexToRgb(COLORS.YELLOW));
          const calculatedColor = getGradientPoint(
            color1,
            color2,
            parseInt(sum) / 100
          );
          const nc = rgbToHex(
            calculatedColor[0],
            calculatedColor[1],
            calculatedColor[2]
          );
          return <Cell color={nc}>{sum}%</Cell>;
        }
        return <Cell>{value.value}%</Cell>;
      },
      style: {
        background: COLORS.BLUE.LIGHT,
        boxShadow: '4px 0px 3px -3px #ccc',
        border: 'none !important',
        fontWeight: '500'
      }
    }
  ])(priceSizeStatColumns as any);

  return (
    <SectionContainer m={PADDINGS.XXS}>
      <Box
        p={PADDINGS.S}
        flex={1}
        justifyContent={'space-between'}
        alignItems={'baseline'}
      >
        <SubHeading>Sold Price Brackets vs Sold Size Brackets</SubHeading>
        <Box
          style={{
            display: 'inline-flex'
          }}
        >
          <ActionMenu
            Button={(button) => (
              <Body style={{ cursor: 'pointer', color: COLORS.GREY.DARK }}>
                Change Range Increments&nbsp;
                <span style={{ color: COLORS.BLUE_GREEN }}>{increment}k</span>
                &nbsp;{button.isOpen ? <UpArrow /> : <DownArrow />}
              </Body>
            )}
            items={[
              {
                label: '10k',
                onClick: () => setIncrement(10)
              },
              {
                label: '20k',
                onClick: () => setIncrement(20)
              },
              {
                label: '50k',
                onClick: () => setIncrement(50)
              },
              {
                label: '100k',
                onClick: () => setIncrement(100)
              }
            ]}
          />
        </Box>
      </Box>
      <Table
        getTdProps={(state, rowInfo, column) => {
          if (/brackets.\d.percent/.exec(column.id) || column.id === 'sum') {
            return {
              style: {
                margin: 0,
                padding: 0
              }
            };
          }
          return {};
        }}
        sortable={false}
        data={priceSizeStats}
        columns={columns}
        defaultPageSize={priceSizeStats.length}
        pageSize={priceSizeStats.length}
      />
    </SectionContainer>
  );
};

function Cell(props) {
  const { children, color } = props;
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        backgroundColor: color || null,
        paddingLeft: 5
      }}
    >
      {children}
    </span>
  );
}

export default SoldPriceVsSize;
