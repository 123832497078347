import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useCallback
} from 'react';
import Box from '@rexlabs/box';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import { VividTooltipStateful } from 'src/features/reports/components/vivid';
import { DefaultButton } from '../button';
import { UserIcon } from '../icons';
import { COLORS, FONT } from 'src/features/reports/theme';
import { AccessModal, StripeErrorModal } from '../modals';
import { compose } from 'utils/compose';

import { withModel } from '@rexlabs/model-generator';
import reportModel from 'src/features/reports/models/reports';
import sessionModel, { SessionModel } from 'data/models/custom/session';
import { Auth0Context } from 'src/auth0-provider';
import { ReportModel } from 'types/models/report';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { isSafari } from 'utils/validation';

const tooltipStyles = StyleSheet({
  tooltip: {
    padding: 0,
    width: '183px'
  }
});

const styles = StyleSheet({
  sectionTitleWrapper: {
    backgroundColor: COLORS.GREY.POWDER,
    padding: '4px 16px'
  },
  sectionTitle: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  container: {
    overflow: 'hidden',
    borderRadius: '0.3em'
  },
  item: {
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: FONT.FAMILIES.DEFAULT,
    width: '100%',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    textAlign: 'left',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    display: 'block',
    color: COLORS.BLACK,
    '&:hover': {
      backgroundColor: COLORS.GREY.POWDER
    }
  },
  topDivider: {
    borderTop: `1px solid ${COLORS.GREY.POWDER}`
  }
});

interface UserMenuProps {
  styles: Styles;
  isAccessOpen: boolean;
  setIsAccessOpen: Dispatch<SetStateAction<boolean>>;
  isErrorVisible: boolean;
  setIsErrorVisible: Dispatch<SetStateAction<boolean>>;
  reportModel: ReportModel;
  session: SessionModel;
}

const Content = ({
  styles: s,
  isAccessOpen,
  setIsAccessOpen,
  reportModel,
  isErrorVisible,
  setIsErrorVisible,
  session
}: UserMenuProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const context = useContext(Auth0Context);

  const fetchAndOpenStripeLink = useCallback(async () => {
    if (!context.user?.user_metadata?.stripe_customer_id) {
      setIsErrorVisible(true);
      return;
    }

    setLoading(true);
    try {
      // Safari browser blocks window when called inside an async function
      let windowReference: Window | null = null;

      if (isSafari()) {
        windowReference = window.open();
      }

      const response = await reportModel.fetchBillingUrl({
        return_url: window.location.href,
        stripe_id: context.user.user_metadata.stripe_customer_id
      });

      // Manually check here as api client doesn't go to catch when status is not 200
      if (response.status === 200) {
        if (windowReference) {
          windowReference.location.assign(response.data);
        } else {
          window.open(response.data);
        }
      } else {
        windowReference?.close();
        setIsErrorVisible(true);
      }
    } catch (error) {
      setIsErrorVisible(true);
    }

    setLoading(false);
  }, [context.user, reportModel, setIsErrorVisible]);

  return (
    <Box width="100%" {...s('container')}>
      {isErrorVisible && <StripeErrorModal setIsVisible={setIsErrorVisible} />}
      {isAccessOpen && <AccessModal setIsAccessOpen={setIsAccessOpen} />}
      <Box {...s('sectionTitleWrapper')}>
        <span {...s('sectionTitle')}>MY ACCOUNT</span>
      </Box>
      <button {...s('item')} onClick={() => setIsAccessOpen(true)}>
        Access
      </button>
      <button {...s('item')} onClick={() => fetchAndOpenStripeLink()}>
        {loading ? <LoadingSpinner size={15} strokeWidth={2} /> : 'Billing'}
      </button>
      <Box {...s('sectionTitleWrapper')}>
        <span {...s('sectionTitle')}>SUPPORT</span>
      </Box>
      <a href="mailto:support@gripinsights.com.au" {...s('item')}>
        Email Support
      </a>
      <button {...s('item')}>Ph: 1300 994 094</button>
      <a
        href="mailto:support@gripinsights.com.au?subject=GRIP Feature Request"
        {...s('item')}
      >
        Request a Feature
      </a>
      <button
        {...s('item', 'topDivider')}
        onClick={() => session.logout({ logout: context.logout })}
      >
        Logout
      </button>
    </Box>
  );
};

const UserMenu = (props: Pick<UserMenuProps, 'styles'>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAccessOpen, setIsAccessOpen] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  return (
    <VividTooltipStateful
      styles={tooltipStyles}
      distance="10px"
      placement={PLACEMENTS.BOTTOM_END}
      Content={Content}
      cancelExit={isAccessOpen}
      onChange={({ isOpen }: { isOpen: boolean }) => setIsOpen(isOpen)}
      contentProps={{
        ...props,
        isAccessOpen,
        setIsAccessOpen,
        isErrorVisible,
        setIsErrorVisible
      }}
    >
      <DefaultButton selected={isOpen}>
        <Box flexDirection="row" alignItems="center">
          <UserIcon />
        </Box>
      </DefaultButton>
    </VividTooltipStateful>
  );
};

export default compose(
  styled(styles),
  withModel(reportModel),
  withModel(sessionModel)
)(UserMenu);
