import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      updateItem: (type, args, id) => api.put(`periods/${id}`, args),
      createItem: (type, args) =>
        api.post('periods', args).then((res) => {
          return { data: res };
        })
    }
  }
};

const actionCreators = {
  fetchBracketStats: {
    request: ({ id, args }) =>
      api.get(`/periods/${id}/worksheets/stats/brackets`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchPricingStats: {
    request: ({ id, args }) => api.get('/stats/pricing/pricing', args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchStats: {
    request: ({ id, args }) => api.get(`/periods/${id}/worksheets/stats`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchWorksheets: {
    request: ({ id, args }) => api.get(`/periods/${id}/worksheets`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  fetchMissing: {
    request: ({ id, ...args }) => api.get(`/periods/${id}/missing`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  findPeriod: {
    request: (date) => api.get(`/periods?end_date=${date}`),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const ProjectsGenerator = new Generator('periods', config);
export default ProjectsGenerator.createEntityModel({ actionCreators });
