import React from 'react';
import { COLORS, NIVO_THEME } from 'src/features/reports/theme';
import { Point, ResponsiveLine, Serie } from '@nivo/line';
import {
  SolidXAxis,
  SolidCirclePoints,
  Line,
  DashedLine,
  CustomTooltip
} from '../graph';
import { abbrNum, numberWithCommas } from 'utils/format';
import { CategoryKey } from './categories';

interface ProjectPerformanceOverviewChartProps {
  chartData: Serie[];
  axisLeftLegend: string;
  category: CategoryKey;
}

export const ProjectPerformanceOverviewChart = ({
  chartData,
  axisLeftLegend,
  category
}: ProjectPerformanceOverviewChartProps) => {
  return (
    <ResponsiveLine
      data={chartData}
      theme={NIVO_THEME}
      colors={[COLORS.BLUE.PRIMARY, COLORS.PINK.LIGHT]}
      margin={{
        top: 10,
        right: 20,
        bottom: 55,
        left: category === 'median_price_per_m2' ? 80 : 70
      }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto'
      }}
      enableSlices="x"
      enableGridX={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 9,
        legend: null,
        tickPadding: 0,
        legendOffset: 45,
        legendPosition: 'middle'
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 12,
        legend: axisLeftLegend,
        legendOffset: category === 'median_price_per_m2' ? -75 : -65,
        legendPosition: 'middle',
        format: (value) =>
          `${
            ['median_price', 'median_price_per_m2'].includes(category)
              ? '$'
              : ''
          }${
            category === 'median_price'
              ? abbrNum(value).toString().toUpperCase()
              : value
          }${category === 'median_price_per_m2' ? '/m2' : ''}${
            category === 'median_lot_size' ? 'm2' : ''
          }`
      }}
      sliceTooltip={({ slice }) => (
        <CustomTooltip
          points={slice.points.map((point) => ({
            id: point.id,
            label: point.serieId as string,
            value: `${
              ['median_price', 'median_price_per_m2'].includes(category)
                ? '$'
                : ''
            }${numberWithCommas(point.data.y)}${
              category === 'median_price_per_m2' ? '/m2' : ''
            }`,
            color: point.serieColor
          }))}
          note={
            slice.points.find(
              (point) =>
                (
                  point.data as Point['data'] & {
                    excluded_lots: number;
                  }
                ).excluded_lots > 0
            )
              ? 'An allotment(s) price is unavailable within this period'
              : ''
          }
        />
      )}
      layers={[
        // Default layers
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'slices',
        'mesh',
        'legends',
        // Custom layers
        DashedLine,
        Line,
        SolidXAxis,
        SolidCirclePoints
      ]}
    />
  );
};
