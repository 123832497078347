import React, { PureComponent } from 'react';
import { Portal } from '@rexlabs/portal';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS, FONT, BORDER_RADIUS } from 'src/theme';
import { Body } from 'view/components/text';
import { PrimaryButton } from 'view/components/button';

import RedMark from 'assets/red-exclamation.svg';
import BlackMark from 'assets/black-exclamation.svg';
import { SubHeading } from '../text';

const defaultStyles = StyleSheet({
  container: {
    maxHeight: '90vh',
    width: '100%',
    overflow: 'hidden',
    textAlign: 'center',
    background: COLORS.WHITE,
    color: COLORS.BLACK,
    borderRadius: BORDER_RADIUS.INPUT,
    zIndex: 10
  },

  title: {
    fontSize: '2rem',
    fontWeight: FONT.WEIGHTS.NORMAL
  },

  header: {
    padding: `${PADDINGS.XS} ${PADDINGS.XS}`,
    borderBottom: `.1rem solid ${COLORS.GREY.LIGHT}`,
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    flexShrink: 0
  },

  headerTitle: {
    marginLeft: '1.5rem'
  },

  content: {
    paddingTop: PADDINGS.S,
    paddingLeft: PADDINGS.XL,
    paddingRight: PADDINGS.XL,
    overflow: 'auto',
    flexShrink: 0,
    position: 'relative'
  },

  buttons: {
    paddingBottom: PADDINGS.S,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  submitButton: {
    marginLeft: '2.5rem'
  }
});

@styled(defaultStyles)
class ErrorModal extends PureComponent {
  static defaultProps = {
    target: 'modals',
    width: '70rem',
    top: '9.5rem'
  };

  onCancel = () => {
    const { onClose, onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }

    if (onClose) {
      onClose();
    }
  };

  render() {
    const {
      title,
      children,
      error,
      green,
      styles: s,
      closeText,
      submitText,
      onSubmit,
      width,
      top
    } = this.props;
    const headerColor = error ? COLORS.RED : COLORS.BLACK;
    return (
      <Portal target={'dialogs'}>
        <Box
          {...s('container')}
          flexDirection="column"
          style={{ width: width, marginTop: top }}
        >
          <Box {...s('header')}>
            {error ? <RedMark /> : <BlackMark />}{' '}
            {!!title && (
              <SubHeading {...s('headerTitle')} style={{ color: headerColor }}>
                {title}
              </SubHeading>
            )}
          </Box>
          <Box flex={1} flexDirection="column" {...s('content')}>
            <Body>{children}</Body>
          </Box>
          <Box mt={PADDINGS.S} {...s('buttons')}>
            <PrimaryButton style={{ width: '300px' }} onClick={this.onCancel}>
              {closeText}
            </PrimaryButton>

            {submitText && (
              <PrimaryButton
                style={{ width: '300px' }}
                {...s('submitButton')}
                onClick={onSubmit}
                green={green}
                red={!green}
              >
                {submitText}
              </PrimaryButton>
            )}
          </Box>
        </Box>
      </Portal>
    );
  }
}

export default ErrorModal;
