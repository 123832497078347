import React, { Component, Fragment } from 'react';
import { Portal } from '@rexlabs/portal';
import _ from 'lodash';
import periodsModel from 'data/models/entities/periods';
import projectsModel from 'data/models/entities/projects';
import statsModel from 'data/models/entities/stats';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import ReportTitle from 'view/components/report-title';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import { toQuri } from '../../../utils/query';
import Definitions from '../../components/reports/definitions';

function getPrimaryProjectId(props) {
  return _.get(props, 'match.query.primaryProject');
}

function getPeriod(props) {
  return _.get(props, 'match.query.period');
}

function getProjectIds(props) {
  return _.get(props, 'match.query.projects');
}

const projectsQuery = query`{
  ${projectsModel} (ids: ${getProjectIds}) {
        id
        title
        active
        developer
        sales_office_address
        sales_agency
        local_government_area
        total_area
        net_developable_area
        suburb
        postcode
        estimated_start_date
        estimated_completion_date
        total_area
        status
        created_at
        settings
        sales_volume_offset
        estimated_lots
        precinct_structure_plan  
    }
}`;

@withReportSidebar(false)
@withQuery(projectsQuery)
@withModel(periodsModel)
@withModel(statsModel)
class DefinitionsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingWorksheets: true,
      fetchingPeriod: true,

      worksheets: {},
      period: {}
    };

    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    const { periods, whereabouts } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (
      !_.get(query, 'period') ||
      !_.get(query, 'projects') ||
      !_.get(query, 'primaryProject')
    ) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    // Get the period
    periods
      .fetchList({
        id: 'fetch-worksheets',
        args: {
          q: toQuri([
            {
              field: 'end_date',
              op: '==',
              value: getPeriod(this.props)
            }
          ])
        }
      })
      .then((res) => {
        const period = _.get(res, 'data.0.item');
        this.setState({
          period: period,
          fetchingPeriod: false
        });

        // Get the period worksheets
        periods
          .fetchWorksheets({
            id: period.id,
            args: {
              projects: getProjectIds(this.props)
            }
          })
          .then((res) => {
            // Set the worksheets
            this.setState({
              worksheets: _.get(res, 'data', {}),
              fetchingWorksheets: false
            });
          });
      });
  }

  loading() {
    const { projects } = this.props;
    const { fetchingWorksheets, fetchingPeriod } = this.state;

    const arr = [
      fetchingWorksheets,
      fetchingPeriod,
      projects.list.status !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const { projects } = this.props;
    const { worksheets, period } = this.state;

    const loading = this.loading();
    const maxRequests = 3;
    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const projectList = _.sortBy(_.get(projects, 'list.items'), (item) =>
      item.id === parseInt(getPrimaryProjectId(this.props)) ? 0 : 1
    );

    const primaryProject = _(projectList)
      .filter(
        (project) => project.id === parseInt(getPrimaryProjectId(this.props))
      )
      .head();

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle period={period} project={primaryProject} />
        </Portal>

        <Definitions
          projects={projectList}
          worksheets={worksheets}
          primary={primaryProject}
        />

        <ReportFooter
          prevText="Pricelists"
          prevRoute={ROUTES.APP.REPORT_PRICELISTS}
        />
      </Fragment>
    );
  }
}

export default DefinitionsScreen;
