import React, { memo, MouseEventHandler, useMemo } from 'react';
import { useTheme } from '@nivo/core';
import { TreeMapNodeDatum, TreeMapProps } from '@nivo/treemap';
import { animated } from '@react-spring/web';
import { AnimatedValue, ForwardedProps } from 'react-spring';

export interface NodeAnimatedProps {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  labelX: number;
  labelY: number;
  labelRotation: number;
  labelOpacity: number;
  parentLabelX: number;
  parentLabelY: number;
  parentLabelRotation: number;
  parentLabelOpacity: number;
  transform: string;
  labelTransform: string;
  parentLabelTransform: string;
}

export type ExtendedTreeMapNodeDatum = TreeMapNodeDatum & {
  fill: string;
  onMouseMove: MouseEventHandler<SVGRectElement>;
  onMouseLeave: MouseEventHandler<SVGRectElement>;
};

export interface TreeNodeProps extends Partial<TreeMapProps> {
  node: ExtendedTreeMapNodeDatum;
  animatedProps: AnimatedValue<ForwardedProps<NodeAnimatedProps>>;
}

export const TreeMapNode = memo(
  ({
    node,
    animatedProps,
    borderWidth,
    enableLabel,
    enableParentLabel,
    labelSkipSize
  }: TreeNodeProps) => {
    const theme = useTheme();

    const isWithinRange = useMemo(
      () => node.width > labelSkipSize && node.height > labelSkipSize,
      [node.width, node.height, labelSkipSize]
    );

    const showLabel = useMemo(
      () => enableLabel && node.isLeaf && isWithinRange,
      [enableLabel, isWithinRange, node.isLeaf]
    );

    const showParentLabel = useMemo(
      () => enableParentLabel && node.isParent && isWithinRange,
      [enableParentLabel, isWithinRange, node.isParent]
    );

    const parentName = node.pathComponents[1];

    const fittedChars = useMemo(
      () => Math.floor(node.width / 5.7),
      [node.width]
    );

    const parentNameLabel = useMemo(
      () =>
        parentName?.length > fittedChars
          ? parentName.slice(0, fittedChars - 4) + '...'
          : parentName,
      [fittedChars, parentName]
    );

    return (
      <animated.g transform={animatedProps.transform}>
        <animated.rect
          data-testid={`node.${node.id}`}
          width={animatedProps.width}
          height={animatedProps.height}
          fill={node.fill ? node.fill : animatedProps.color}
          strokeWidth={borderWidth}
          stroke={node.borderColor}
          fillOpacity={node.opacity}
          onMouseMove={
            !parentName ? (e) => e.preventDefault() : node.onMouseMove
          }
          onMouseLeave={node.onMouseLeave}
        />
        {showLabel && (
          <animated.text
            dominantBaseline="central"
            textAnchor="middle"
            style={{
              ...theme.labels.text,
              fill: node.labelTextColor,
              fontSize: 15,
              fontWeight: 'bold',
              pointerEvents: 'none'
            }}
            fillOpacity={animatedProps.labelOpacity}
            transform={animatedProps.labelTransform}
          >
            {node.label}
          </animated.text>
        )}
        {showParentLabel && (
          <animated.text
            dominantBaseline="central"
            style={{
              ...theme.labels.text,
              fill: node.parentLabelTextColor,
              pointerEvents: 'none'
            }}
            fillOpacity={animatedProps.parentLabelOpacity}
            transform={animatedProps.parentLabelTransform}
          >
            {parentNameLabel}
          </animated.text>
        )}
      </animated.g>
    );
  }
);
