import React, { useState } from 'react';
import Box from '@rexlabs/box';
import fp from 'lodash/fp';
import { COLORS, PADDINGS } from 'src/theme';
import { SubHeading, Body } from 'view/components/text';
import { getShortMonth, properCase } from 'utils/format';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from 'view/components/arrow';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Tooltip,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis
} from 'recharts';
import SectionContainer from 'view/components/section-container';
import { Project } from 'data/types/projects';
import GA from 'react-ga';

export interface SalesOverTimeProps {
  primary: Project;
  projects: Project[];
  salesOverTime: any;
}

const SalesOverTime = ({
  primary,
  projects,
  salesOverTime
}: SalesOverTimeProps) => {
  const [currentSalePeriod, setCurrentSalePeriod] = useState<string>('gross');
  const nonPrimaryProjects: Project[] = fp.compose(
    fp.sortBy((p: Project) => p.title),
    fp.filter((p: Project) => p.id !== primary?.id)
  )(projects);
  const [currentSalesComparison, setCurrentSalesComparison] = useState<number>(
    nonPrimaryProjects?.[0]?.id
  );
  const currentSalesComparisonProject = fp.find(
    (p: Project) => p.id === currentSalesComparison
  )(projects);
  const actionMenuItems = nonPrimaryProjects?.map((p: Project) => {
    return {
      label:
        p.id === currentSalesComparison ? (
          <span style={{ color: COLORS.BLUE_GREEN }}>{p.title}</span>
        ) : (
          p.title
        ),
      onClick: () => {
        GA.event({
          category: 'Estate Detailed Stats',
          action: 'Toggle Sales Over Time Estate',
          label: p.title.toLowerCase()
        });
        setCurrentSalesComparison(p.id);
      }
    };
  });

  const togglePeriod = () => {
    const newVal = currentSalePeriod === 'gross' ? 'net' : 'gross';
    GA.event({
      category: 'Estate Detailed Stats',
      action: 'Toggle Sales Over Time Net/Gross',
      label: newVal
    });
    setCurrentSalePeriod(newVal);
  };

  return (
    <SectionContainer m={PADDINGS.XXS}>
      <Box
        p={PADDINGS.S}
        flex={1}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        justifyContent={'space-between'}
      >
        <SubHeading>
          {properCase(currentSalePeriod)} Sales Figures Over Time
        </SubHeading>
        <Box flexDirection={'row'} alignItems={'baseline'}>
          <Body
            style={{
              cursor: 'pointer',
              color: COLORS.GREY.DARK,
              userSelect: 'none'
            }}
            onClick={togglePeriod}
          >
            Toggle {currentSalePeriod === 'gross' ? 'Net' : 'Gross'}
          </Body>
          <Box pl={PADDINGS.XS} pr={PADDINGS.XS}>
            |
          </Box>
          <ActionMenu
            Button={(button) => (
              <Body
                style={{
                  cursor: 'pointer',
                  color: COLORS.GREY.DARK,
                  userSelect: 'none'
                }}
              >
                Change Estate Comparison &nbsp;
                {button.isOpen ? <UpArrow /> : <DownArrow />}
              </Body>
            )}
            items={actionMenuItems}
          />
        </Box>
      </Box>
      <Box>
        <ResponsiveContainer width={'100%'} height={300}>
          <LineChart
            data={salesOverTime}
            margin={{
              right: 24
            }}
          >
            <XAxis
              dataKey={'period'}
              tickFormatter={(date) =>
                new Date(date).toLocaleString('en-us', { month: 'short' })
              }
            />
            <YAxis />
            <CartesianGrid vertical={false} />
            <Tooltip labelFormatter={getShortMonth} />
            <Legend
              verticalAlign="top"
              align="left"
              iconType="line"
              height={40}
              wrapperStyle={{
                marginLeft: 24
              }}
            />
            {primary && (
              <Line
                name={primary?.title}
                dataKey={`projects.${primary?.id}.${currentSalePeriod}`}
                stroke={COLORS.GRAPH.GREEN.DARK}
              />
            )}
            <Line
              name={currentSalesComparisonProject?.title}
              dataKey={`projects.${currentSalesComparisonProject?.id}.${currentSalePeriod}`}
              stroke={COLORS.GRAPH.GREEN.LIGHT}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </SectionContainer>
  );
};

export default SalesOverTime;
