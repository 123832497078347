import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Canceler } from 'axios';

import { CancelToken } from '@rexlabs/api-client';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';

import Panel from '../panel';
import { ToggleButtonGroup } from '../toggle-button';
import MonthRangeSlider, { MonthYearType } from '../slider/month-range-slider';
import { HeatIndicator } from '../heat-map';
import ProductTypePerformanceTable from './product-type-performance-table';
import { Loading } from '../graph';

import { MARKET } from './constants';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import useMonthRangeFilter from 'src/features/reports/hooks/use-month-range-filter';
import { compose } from 'utils/compose';
import productTrendsModel from 'src/features/reports/models/product-trends';
import { ProductTrendsModel } from 'types/models/product-trends';
import { ProductTypePerformanceReport } from 'types/graph';
import withError from 'view/containers/with-error';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { Auth0Context } from 'src/auth0-provider';
import { TRENDS_PAGE } from 'src/features/reports/constants';
import InfoTooltip from './info-tooltip';

interface ProductTypePerformanceProps {
  productTrendsModel: ProductTrendsModel;
  errorModal: WithErrorModalProps;
}

const ProductTypePerformance = ({
  productTrendsModel,
  errorModal: { open: openError }
}: ProductTypePerformanceProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const { period, selectedProjectIds, subRegionsPermissions, isFirstLoad } =
    useContext(ReportSettingsContext);
  const canceler = useRef<Canceler | null>(null);
  const { dateFilter, setDateFilter, startPeriod, endPeriod } =
    useMonthRangeFilter();
  const [market, setMarket] =
    useState<typeof MARKET[number]['key']>('time_on_market');
  const [report, setReport] = useState<ProductTypePerformanceReport[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const heatLabels = useMemo(() => {
    if (market === 'time_on_market') {
      return {
        lowestHeat: 'MOST TIME ON MARKET',
        highestHeat: 'LEAST TIME ON MARKET'
      };
    }

    return {
      lowestHeat: 'LEAST SHARE',
      highestHeat: 'MOST SHARE'
    };
  }, [market]);

  useEffect(() => {
    if (
      (!selectedProjectIds.length || !startPeriod || !endPeriod) &&
      !isFirstLoad
    ) {
      return;
    }

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    productTrendsModel
      .fetchProductTypePerformance({
        queryParams: {
          project_ids: selectedProjectIds,
          start_period: startPeriod,
          end_period: endPeriod
        },
        hasPermission:
          !isFirstLoad &&
          hasPermission([...subRegionsPermissions, TRENDS_PAGE]),
        config: { cancelToken }
      })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;
        setReport(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        openError(err);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    startPeriod,
    endPeriod,
    productTrendsModel,
    openError,
    hasPermission,
    subRegionsPermissions,
    isFirstLoad
  ]);

  return (
    <Panel
      title="Product Type Performance"
      tooltip={{
        title: 'Product Type Performance',
        description: <InfoTooltip />
      }}
    >
      <Loading isLoading={isLoading} />
      <Box width="262px" m="0 auto 34px" pt="12px">
        <ToggleButtonGroup
          group={MARKET}
          value={[market]}
          onToggle={(key: typeof MARKET[number]['key']) => setMarket(key)}
        />
      </Box>
      <Box width="588px" m="0 auto 34px">
        <MonthRangeSlider
          value={dateFilter}
          onChange={(value: MonthYearType) => setDateFilter(value)}
          numOfMonthsBack={11}
          latestMonth={period}
        />
      </Box>
      <ProductTypePerformanceTable report={report} market={market} />
      <HeatIndicator {...heatLabels} />
    </Panel>
  );
};

export default compose(
  withModel(productTrendsModel),
  withError.withPropName('errorModal')
)(ProductTypePerformance);
