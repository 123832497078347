import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { Column } from '@rexlabs/grid';
import { styled } from '@rexlabs/styling';

@styled()
class SectionColumn extends PureComponent {
  render() {
    const { styles: s, children } = this.props;
    return (
      <Column flexWrap={'nowrap'} {...this.props}>
        <Box {...s('container')}>{children}</Box>
      </Column>
    );
  }
}

export default SectionColumn;
