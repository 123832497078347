import Generator from 'data/models/generator';
import _ from 'lodash';
import { api, setAccountId, setAuthToken } from 'utils/api-client';
import jwtDecode from 'jwt-decode';

export const TYPE = 'session';

const initialState = {
  ready: false,
  apiToken: null,
  userId: null,
  accountId: null,
  guest: false,
  guestLevel: null,
  admin: false,
  error: null,
  permissions: []
};

const selectors = {
  ready: (state) => state.session.ready,
  apiToken: (state) => state.session.apiToken,
  userId: (state) => state.session.userId,
  accountId: (state) => state.session.accountId,
  guest: (state) => state.session.guest,
  guestLevel: (state) => state.session.guestLevel,
  admin: (state) => state.session.admin,
  error: (state) => state.session.error,
  permissions: (state) => state.session.permissions
};

const actionCreators = {
  login: {
    request: (payload, actions, dispatch, getState) =>
      // todo: can be re-constructed to not have a need to use async executor
      // as it may possibly causes unhandled issues when the Promise fails.
      // https://bit.ly/3LOiU8C, https://eslint.org/docs/rules/no-async-promise-executor
      // eslint-disable-next-line
      new Promise(async (resolve, reject) => {
        const loginReq = await api
          .post('/login', {
            token: payload.token
          })
          .catch(reject);

        const login = loginReq.data;

        if (!login || !login.token || !login.token) {
          reject(new Error('Login failed'));
          return;
        }

        setAuthToken(login.token);

        resolve({
          apiToken: login.token,
          userId: login.user_id,
          accountId: login.account_id,
          admin: login.admin,
          permissions:
            login.token_type === 'auth0'
              ? jwtDecode(login.token)?.permissions
              : []
        });
      }),
    reduce: {
      initial: _.identity,
      success: (state, action) => ({
        ...state,
        userId: action.payload.userId,
        accountId: action.payload.accountId,
        apiToken: action.payload.apiToken,
        admin: action.payload.admin,
        permissions: action.payload.permissions
      }),
      failure: (state) => ({
        ...state,
        apiToken: null
      })
    }
  },

  logout: {
    request: (payload, actions, dispatch, getState) =>
      // todo: can be re-constructed to not have a need to use async executor
      // as it may possibly causes unhandled issues when the Promise fails.
      // https://bit.ly/3LOiU8C, https://eslint.org/docs/rules/no-async-promise-executor
      // eslint-disable-next-line
      new Promise(async (resolve, reject) => {
        payload?.logout?.();
        setAuthToken(null);
        setAccountId(null);

        resolve();
      }),
    reduce: (state, action) => ({
      ...state,
      userId: null,
      accountId: null,
      apiToken: null,
      guest: false,
      guestLevel: null,
      admin: false
    })
  },

  logoutAccount: {
    reduce: (state, action) => {
      setAccountId(null);
      return {
        ...state,
        accountId: null
      };
    }
  },

  updateToken: {
    reduce: (state, action) => ({
      ...state,
      apiToken: action.payload.apiToken
    })
  },

  switchAccount: {
    request: (payload, actions, dispatch, getState) =>
      // todo: can be re-constructed to not have a need to use async executor
      // as it may possibly causes unhandled issues when the Promise fails.
      // https://bit.ly/3LOiU8C, https://eslint.org/docs/rules/no-async-promise-executor
      // eslint-disable-next-line
      new Promise(async (resolve, reject) => {
        const switchAccount = await api
          .post('/accounts/switch', {
            account_id: payload.account_id
          })
          .catch(reject);

        if (
          !switchAccount ||
          !switchAccount.data.token ||
          !switchAccount.data.account_id
        ) {
          reject(new Error('Account switch failed'));
          return;
        }

        setAuthToken(switchAccount.data.token);
        setAccountId(switchAccount.data.account_id);

        resolve({
          apiToken: switchAccount.data.token,
          accountId: switchAccount.data.account_id,
          admin: switchAccount.data.admin,
          permissions: jwtDecode(switchAccount.data.token)?.permissions
        });
      }),
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          accountId: action.payload.accountId,
          apiToken: action.payload.apiToken,
          admin: action.payload.admin,
          permissions: action.payload.permissions
        };
      },
      failure: (state) => ({
        ...state,
        apiToken: null
      })
    }
  },

  init: {
    request: (payload, actions, dispatch, getState) =>
      new Promise((resolve, reject) => {
        const apiToken = getState().session.apiToken ?? payload.token;
        const accountId = getState().session.accountId ?? 9;
        const urlParams = new URLSearchParams(window.location.search);
        const guestToken = urlParams.get('token');
        if (!apiToken && !guestToken) {
          reject(new Error('No API token found!'));
          return;
        }

        if (accountId) {
          setAccountId(accountId);
        }

        if (guestToken) {
          api.get(`/users/me?token=${guestToken}`).then(resolve).catch(reject);
          return;
        }
        api
          .get('/users/me')
          .then((r) => {
            // api client doesn't treat other status as error so we need to reject manually here
            if (r.status !== 200) reject(r);
            return resolve({
              ...r,
              permissions: jwtDecode(apiToken)?.permissions ?? []
            });
          })
          .catch(reject);
      }),
    reduce: {
      initial: _.identity,
      success: (state, action) => {
        return {
          ...state,
          userId: action.payload.data.id,
          accountId: action.payload.data.account_id,
          guest: action.payload.data.guest,
          guestLevel: action.payload.data.guest_level,
          admin: action.payload.data.admin,
          ready: true,
          permissions: action.payload.permissions
          // Enable when we start to populate user data
          // user: action.payload.user
        };
      },
      failure: async (state, action) => {
        return {
          ...state,
          ready: true,
          apiToken: null,
          guest: false,
          guestLevel: null,
          admin: false,
          accountId: null,
          permissions: [],
          error: _.get(action, 'error', false)
            ? _.get(action, 'payload.error')
            : null
        };
      }
    }
  }
};

const SessionModel = new Generator(TYPE);
const session = SessionModel.createModel({
  initialState,
  selectors,
  actionCreators
});

export default session;
