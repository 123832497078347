export const STATUS = [
  {
    label: 'Available',
    key: 'available'
  },
  {
    label: 'Sold',
    key: 'sold'
  }
];

export const BONUS = [
  {
    label: 'Incentives',
    key: 'incentives'
  },
  {
    label: 'Buffer',
    key: 'buffer'
  }
];
