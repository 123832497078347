import React, { Dispatch, SetStateAction } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { FONT } from 'src/features/reports/theme';
import Modal from './modal';
import { DefaultButton } from '../button';

const styles = StyleSheet({
  title: {
    fontSize: '28px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 700,
    marginBottom: '10px'
  },
  paragraph: {
    fontSize: '14px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 400
  },
  highlight: {
    color: '#2376D6',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

interface StripeErrorModalProps {
  styles: Styles;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const StripeErrorModal = ({
  styles: s,
  setIsVisible
}: StripeErrorModalProps) => {
  return (
    <Modal width="404px">
      <Box p="24px">
        <h3 {...s('title')}>Error</h3>
        <p {...s.with('paragraph')({ marginBottom: '12px' })}>
          Unable to open the Stripe store.
        </p>
        <p {...s.with('paragraph')({ marginBottom: '12px' })}>
          You may not have permission to manage billing.
        </p>
        <p {...s.with('paragraph')({ marginBottom: '20px' })}>
          If you believe this is a mistake, please get in touch with GRIP
          support via{' '}
          <a
            href="mailto:support@gripinsights.com.au"
            target="blank"
            {...s('highlight')}
          >
            support@gripinsights.com.au
          </a>
        </p>
        <Box flexDirection="row" justifyContent="flex-end">
          <DefaultButton
            style={{ width: '91px' }}
            onClick={() => setIsVisible(false)}
          >
            Close
          </DefaultButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default styled(styles)(StripeErrorModal);
