import React, { FC } from 'react';

import { Portal } from '@rexlabs/portal';
import Box from '@rexlabs/box';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';

import { COLORS, BORDER_RADIUS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    background: COLORS.WHITE,
    borderRadius: BORDER_RADIUS.DIALOGS,
    overflow: 'visible',
    position: 'relative',
    maxHeight: '94vh',
    top: 40,
    width: '100%'
  }
});

interface ModalProps {
  width: number | string;
  styles: Styles;
}

const Modal: FC<ModalProps> = ({ width = '65vw', styles: s, children }) => {
  return (
    <Portal target="dialogs">
      <Box {...s.with('container')({ maxWidth: width })}>{children}</Box>
    </Portal>
  );
};

export default styled(defaultStyles)(Modal);
