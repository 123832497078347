import React, { PureComponent } from 'react';
import _ from 'lodash';
import Box from '@rexlabs/box';
import { api } from 'utils/api-client';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TextInput, TextArea } from '@rexlabs/text-input';
import { Grid, Column } from '@rexlabs/grid';
import { withModel } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';

import ButtonBar from 'view/components/modal/button-bar';
import withError from 'view/containers/with-error';
import MonthPicker from 'view/components/calendar/month';
import withPermission from 'view/containers/with-permissions';
import Select, { colourStyles } from 'view/components/react-select';
import Checkbox from 'view/components/checkbox';
import { Form, ReactForms, FormField } from 'view/components/form';
import { Heading, Label } from 'view/components/text';
import { ClearButton, PrimaryButton, TextButton } from 'view/components/button';
import { SelectInput } from 'view/components/input/select';

import worksheetsModel from 'data/models/entities/worksheets';
import sessionsModel from 'data/models/custom/session';
import periodsModel from 'data/models/entities/periods';
import { createValidationRules } from 'utils/form';
import { formatPeriod } from 'utils/format';
import { PADDINGS } from 'src/theme';
import ROUTES from 'src/routes';

const defaultStyle = StyleSheet({
  container: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttons: {
    marginTop: '5rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonGroup: {
    display: 'flex',
    '> *': {
      margin: '0.5rem'
    }
  }
});

@withPermission()
@withError.withPropName('errorModal')
@withModel(sessionsModel)
@withModel(worksheetsModel)
@withModel(periodsModel)
@styled(defaultStyle)
class WorksheetForm extends PureComponent {
  state = {
    missingProjects: [],
    x: new Date(),
    submitAs: 'published',
    fetchingLastSheet: false,
    supplyIssue: false,
    priceList: false,
    copyFrom: null,
    copyFromLast: true,
    period: '',
    periodError: false,
    flagOptions: [],
    worksheetFlags: []
  };

  componentWillMount() {
    const { projectId, worksheet } = this.props;

    if (!projectId) {
      const { periods, period } = this.props;
      periods
        .fetchMissing({
          id: period.id
        })
        .then((missingProjects) => {
          this.setState({
            missingProjects: _.get(missingProjects, 'data')
          });
        });
    }

    if (worksheet) {
      const worksheetFlags = worksheet?.flags?.data.map((flag) => flag.id);

      this.setState({
        supplyIssue: worksheet.supply_issue,
        priceList: worksheet.no_price_list,
        worksheetFlags
      });
    }
  }

  componentDidMount() {
    const { worksheet } = this.props;
    if (worksheet) {
      this.setState({ period: worksheet.period.end_date });
    } else {
      this.getLastWorksheet();
    }

    this.fetchWorksheetFlags();
  }

  fetchWorksheetFlags = () => {
    api.get('/flags?type=worksheets').then((result) => {
      const flagOptions = this.getLabelValue(result.data);
      this.setState({ flagOptions });
    });
  };

  toggle = (field) => this.setState({ [field]: !this.state[field] });

  getLastWorksheet = () => {
    const { worksheets, projectId } = this.props;

    this.setState({ fetchingLastSheet: true });

    worksheets
      .fetchLastWorksheet({ projectId: projectId })
      .then((worksheet) => {
        this.setState({
          supplyIssue: _.get(worksheet, 'data.supply_issue', false),
          fetchingLastSheet: false,
          copyFrom: _.get(worksheet, 'data.id')
        });
      })
      .catch((e) => {
        this.setState({ fetchingLastSheet: false });
        console.error(e);
      });
  };

  getDraftText = () => {
    const { worksheet } = this.props;
    const isUpdate = !!worksheet;
    if (!isUpdate) {
      return 'Save As Draft';
    }

    if (worksheet.status === 'published') {
      return 'Revert To Draft';
    }

    return 'Update Draft';
  };

  getDateValue = (period) => {
    if (!period) {
      const date = new Date();
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1
      };
    }

    const date = new Date(period);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1
    };
  };

  onWorksheetFlagChange = (selectedFlags) => {
    const flags = selectedFlags?.map((flag) => flag.value) || [];

    if (flags) {
      this.setState({ worksheetFlags: flags });
    }
  };

  getLabelValue = (options) => {
    return _.map(options, (option) => {
      const hasColor = !!option.color;
      return {
        label: option.name,
        value: option.id,
        color: hasColor ? option.color : 'black'
      };
    });
  };

  render() {
    const {
      styles: s,
      worksheet,
      errorModal: { Error },
      can
    } = this.props;
    const { fetchingLastSheet, periodError, flagOptions } = this.state;

    const isUpdate = !!worksheet;
    const published = isUpdate && _.get(worksheet, 'status') === 'published';

    const defaultValues = this.getLabelValue(
      _.get(worksheet, 'flags.data', [])
    );

    return (
      <Box {...s('container')}>
        <ReactForms
          validateOnBlur={false}
          validateOnChange={false}
          validate={(values) => {
            const rules = !this.props.projectId ? { project: 'required' } : {};
            return createValidationRules(rules)(values);
          }}
          handleSubmit={(values) => {
            const {
              worksheets,
              worksheet,
              projectId,
              onSubmit,
              closeModal,
              errorModal
            } = this.props;

            const { submitAs } = this.state;

            // Check if period is set
            if (projectId && !this.state.period) {
              this.setState({
                periodError: true
              });
              return;
            }

            const period = this.props.period
              ? this.props.period.end_date
              : this.state.period;

            const promise = isUpdate
              ? worksheets.updateItem({
                  id: worksheet.id,
                  data: {
                    account_id: this.props.session.accountId,
                    projectId: worksheet.project_id,
                    notes: values.notes,
                    supply_issue: this.state.supplyIssue || false,
                    no_price_list: this.state.priceList || false,
                    period: period,
                    status: submitAs,
                    flags: this.state.worksheetFlags || []
                  },
                  args: {
                    projectId: worksheet.project_id
                  }
                })
              : worksheets.createItem({
                  data: {
                    account_id: this.props.session.accountId,
                    period: period,
                    notes: values.notes,
                    status: submitAs,
                    supply_issue: this.state.supplyIssue || false,
                    no_price_list: this.state.priceList || false,
                    project_id: values.project || projectId,
                    flags: this.state.worksheetFlags || [],
                    copy_from: this.state.copyFromLast
                  }
                });

            return promise
              .then((response) => {
                if (isUpdate) {
                  onSubmit();
                  closeModal();
                } else {
                  push(ROUTES.APP.WORKSHEET, {
                    params: {
                      projectId: response.data.project_id,
                      worksheetId: response.data.id
                    }
                  });
                }
              })
              .catch((e) => {
                errorModal.open(e);
              });
          }}
        >
          {(props) => {
            return (
              <Box>
                <Box {...s('header')} flex={1}>
                  <Heading>{worksheet ? 'Save' : 'Create'} Worksheet</Heading>
                </Box>
                <Form>
                  {/* This section of the form is slightly different to the ux due to the date input not working completely yet. */}
                  {/* This will need to change sometime in the future once the date input has been updated. */}
                  {!this.props.projectId && (
                    <Grid columns={1}>
                      <Column width={1}>
                        <FormField
                          label="Project"
                          name="project"
                          Input={SelectInput}
                          inputProps={{
                            options: this.state.missingProjects.map(
                              (project) => {
                                return {
                                  value: project.id,
                                  label: project.title
                                };
                              }
                            )
                          }}
                          useSlowField
                        />
                      </Column>
                    </Grid>
                  )}
                  {!this.props.period && (
                    <Grid columns={1}>
                      <Column width={1}>
                        <Label>Period</Label>
                        <MonthPicker
                          from={2008}
                          to={2028}
                          disabled={published}
                          value={this.getDateValue(
                            this.state.period ||
                              _.get(worksheet, 'period.end_date')
                          )}
                          onChange={(year, month) => {
                            this.setState({ period: `${year}-${month}-1` });
                          }}
                          button={
                            <TextInput
                              value={formatPeriod(this.state.period)}
                              disabled={published}
                            />
                          }
                        />
                        {periodError && (
                          <Box style={{ fontSize: '1.1rem', color: '#e30000' }}>
                            The Period field is required.
                          </Box>
                        )}
                      </Column>
                    </Grid>
                  )}
                  <Grid columns={1}>
                    <Column width={1} />
                  </Grid>
                  <Grid columns={1}>
                    <Column width={1}>
                      <FormField
                        isDisabled={false}
                        label="Notes"
                        placeholder="Max 255 Characters"
                        name="notes"
                        Input={TextArea}
                        inputProps={{
                          charLimit: 255,
                          showCharLimit: true,
                          placeholder: 'Max 255 Characters',
                          disabled: published
                        }}
                        initialValue={_.get(worksheet, 'notes')}
                      />
                    </Column>
                  </Grid>

                  <Grid columns={12}>
                    <Column
                      width={12}
                      onClick={() => {
                        if (published) return;
                        if (!this.state.priceList) {
                          this.setState({
                            priceList: true
                          });
                        } else {
                          this.toggle('priceList');
                        }
                      }}
                    >
                      <Checkbox
                        value={this.state.priceList}
                        label="No Price List Received"
                      />
                    </Column>
                  </Grid>
                  <Grid columns={12}>
                    <Column
                      width={12}
                      onClick={() => {
                        if (published) return;
                        this.toggle('supplyIssue');
                      }}
                    >
                      <Checkbox
                        value={this.state.supplyIssue}
                        label="Supply Issue (If checked excludes data when calculating average sales rates)"
                      />
                    </Column>
                  </Grid>

                  <Grid columns={12}>
                    <Column width={12}>
                      <Label>Flags</Label>
                      <Select
                        isMulti
                        name="flags"
                        defaultValue={defaultValues}
                        options={flagOptions}
                        styles={colourStyles}
                        onChange={this.onWorksheetFlagChange}
                        isDisabled={published}
                      />
                    </Column>
                  </Grid>

                  <ButtonBar {...s('buttons')}>
                    <Box
                      onClick={() => {
                        if (!isUpdate) {
                          if (!this.state.copyFromLast) {
                            this.setState({
                              copyFromLast: true
                            });
                          } else {
                            this.toggle('copyFromLast');
                          }
                        }
                      }}
                    >
                      {!isUpdate && (
                        <Checkbox
                          value={this.state.copyFromLast}
                          label="Copy from Last Sheet"
                        />
                      )}
                    </Box>

                    <Box {...s('buttonGroup')}>
                      <TextButton
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.closeModal();
                        }}
                      >
                        Cancel
                      </TextButton>
                      <ClearButton
                        submit={false}
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({ submitAs: 'draft' });
                          props.submitForm();
                        }}
                        isLoading={props.isSubmitting || fetchingLastSheet}
                        isDisabled={props.isSubmitting || fetchingLastSheet}
                      >
                        {this.getDraftText()}
                      </ClearButton>
                      {!published && can('publish:worksheets') && (
                        <PrimaryButton
                          green
                          onClick={props.submitForm}
                          isLoading={props.isSubmitting || fetchingLastSheet}
                          isDisabled={props.isSubmitting || fetchingLastSheet}
                        >
                          Publish
                        </PrimaryButton>
                      )}
                    </Box>
                  </ButtonBar>
                </Form>
              </Box>
            );
          }}
        </ReactForms>
        <Error />
      </Box>
    );
  }
}

export default WorksheetForm;
