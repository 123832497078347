import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      Month’s supply is a key indicator for the ‘health’ of the market. It is
      assessed by comparing <span className="bold">overall sales volumes</span>{' '}
      to <span className="bold">remaining supply</span> for the period selected.
      <br />
      <br />
      Selecting the Project Count tile will change the chart to show you how
      many active projects are currently operating in the corridor.
      <br />
      <br />
      Flagged projects have &lt;100 allotments remaining, currently have
      unreliable data and or their land data is unavailable.{' '}
      <span className="italic">
        For further details see ‘Project Breakdown’ report.
      </span>
    </>
  );
};

export default InfoTooltip;
