import React, { PureComponent } from 'react';
import { Heading, Tiny } from 'view/components/text';
import { PrimaryButton, TextButton } from 'view/components/button';
import { Grid, Column } from '@rexlabs/grid';
import { Form, FormField, ReactForms } from 'view/components/form';
import { createValidationRules } from 'utils/form';
import { TextInput } from '@rexlabs/text-input';
import { SelectInput } from 'view/components/input/select';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';
import Box from '@rexlabs/box';
import ButtonBar from 'view/components/modal/button-bar';
import StageModal from 'view/modals/stage';
import _ from 'lodash';
import { Check as Checkbox } from 'view/components/checkbox';
import withError from 'view/containers/with-error';
import { Body } from '../components/text';
import { inArray } from '../../utils/array';
import { formatNumber, stripNumber } from 'utils/format';

const defaultStyles = StyleSheet({
  input: {
    paddingLeft: PADDINGS.S
  },
  target: {
    color: COLORS.BLUE_GREEN
  },
  checkbox: {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    alignItems: 'center',
    padding: `${PADDINGS.XXS} 0`,
    color: COLORS.GREY.DARK,
    '> p': {
      margin: PADDINGS.TINY
    }
  }
});

// Need to extract these options on a separate file to reduce code lines.
// Some options also are being used on other files.
// https://rexsoftware.atlassian.net/browse/BLKCORE-156
const lotOrientationOptions = [
  {
    value: 'north',
    label: 'North'
  },
  {
    value: 'south',
    label: 'South'
  },
  {
    value: 'east',
    label: 'East'
  },
  {
    value: 'west',
    label: 'West'
  }
];

const changeTypeOptions = [
  {
    value: 'stage_id',
    label: 'Stage'
  },
  {
    value: 'price',
    label: 'Price'
  },
  {
    value: 'status',
    label: 'Status'
  },
  {
    value: 'titled',
    label: 'Titled'
  },
  {
    value: 'negotiation_buffer',
    label: 'Negotiation Buffer'
  },
  {
    value: 'rebate',
    label: 'Incentives'
  },
  {
    value: 'characteristics',
    label: 'Characteristics'
  }
];

const statusOptions = [
  {
    value: 'Available',
    label: 'Available'
  },
  {
    value: 'On Hold',
    label: 'On Hold'
  },
  {
    value: 'Deposit',
    label: 'Deposit'
  },
  {
    value: 'Sold',
    label: 'Sold'
  },
  {
    value: 'Back on Market',
    label: 'Back on Market'
  },
  {
    value: 'NFS',
    label: 'NFS'
  },
  {
    value: 'BOM/Sold',
    label: 'BOM/Sold'
  }
];

@withError.withPropName('errorModal')
@styled(defaultStyles)
class MassEditLotForm extends PureComponent {
  state = {
    field: null,
    stageModal: false,

    corner: false,
    irregular: false,
    powerlines: false,
    nearbyAmenity: false,
    parkWetlands: false,
    substation: false,
    lotOrientation: '',
    fill: false,
    fall: false,
    doubleStorey: false
  };

  handleKeyPress = (e, field) => {
    if (e.key === ' ') {
      this.toggle(field);
    }
  };

  toggle = (field) => this.setState({ [field]: !this.state[field] });

  validate = (values, props) => {
    const { field } = this.state;

    if (_.get(field, 'value') === 'characteristics') {
      return {};
    }

    const rules = {
      status: 'required',
      stage_id: 'required|integer',
      titled: 'required|boolean',

      price: 'integer|min:1|required',
      rebate: 'integer|min:0|required',
      negotiation_buffer: 'integer|required|min:0',
      priceChange: 'integer',
      dollarPerM2: 'integer|min:1',

      lotSize: 'integer|min:1|required',
      frontage: 'numeric|min:1|required'
    };

    const validation = createValidationRules({
      [field.value]: rules[field.value]
    })(values, props);

    return validation;
  };

  submitCharacteristics = () => {
    const { lots, closeModal, errorModal, ids, projectId, worksheetId } =
      this.props;
    const {
      corner,
      irregular,
      powerlines,
      nearbyAmenity,
      parkWetlands,
      substation,
      lotOrientation,
      fill,
      fall,
      doubleStorey
    } = this.state;

    const payload = {
      ids: ids,
      data: {
        lot_orientation: lotOrientation || '',
        is_corner: corner || false,
        is_irregular: irregular || false,
        is_powerlines: powerlines || false,
        is_nearby_amenity: nearbyAmenity || false,
        is_park_wetlands: parkWetlands || false,
        is_substation: substation || false,
        is_fill: fill || false,
        is_fall: fall || false,
        is_double_storey: doubleStorey || false
      }
    };

    lots
      .massUpdate({
        data: payload,
        args: {
          projectId: projectId,
          worksheetId: worksheetId
        }
      })
      .then(() => {
        closeModal(true);
      })
      .catch((e) => {
        errorModal.open(e);
      });
  };

  submitForm = (values) => {
    const { lots, closeModal, errorModal, ids, projectId, worksheetId } =
      this.props;
    const { field } = this.state;
    const targetField = _.get(field, 'value');

    const isNumber = inArray(
      ['rebate', 'negotiation_buffer', 'price'],
      targetField
    );

    if (isNumber) {
      _.set(
        values,
        targetField,
        parseInt(stripNumber(_.get(values, targetField)))
      );
    }

    const payload = {
      ids: ids,
      data: values
    };

    lots
      .massUpdate({
        data: payload,
        args: {
          projectId: projectId,
          worksheetId: worksheetId
        }
      })
      .then(() => {
        closeModal(true);
      })
      .catch((e) => {
        errorModal.open(e);
      });
  };

  handleSubmit = (values, props) => {
    const { field } = this.state;

    if (_.get(field, 'value') === 'characteristics') {
      this.submitCharacteristics();
    } else {
      this.submitForm(values, props);
    }
  };

  handleFieldChange = (e) => {
    this.setState({ field: e });
  };

  render() {
    const {
      styles: s,
      projectId,
      worksheetId,
      stages,
      errorModal: { Error }
    } = this.props;

    const {
      field,
      stageModal,
      corner,
      irregular,
      powerlines,
      nearbyAmenity,
      parkWetlands,
      substation,
      fill,
      fall,
      doubleStorey
    } = this.state;

    const select = inArray(
      ['status', 'characteristics', 'titled'],
      _.get(field, 'value')
    );
    const textBox = inArray(
      ['rebate', 'negotiation_buffer'],
      _.get(field, 'value')
    );
    const status = _.get(field, 'value') === 'status';
    const characteristics = _.get(field, 'value') === 'characteristics';
    const stage = _.get(field, 'value') === 'stage_id';
    const titled = _.get(field, 'value') === 'titled';
    const price = _.get(field, 'value') === 'price';

    const stageNumbers = _.get(stages, 'list.items', []).slice();

    return (
      <Box>
        <ReactForms
          handleSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ isSubmitting, isValidating, setFieldValue, submitForm }) => {
            return (
              <Form name="mass-edit-lot-form">
                <Box
                  p={PADDINGS.M}
                  style={{ borderBottom: `1px solid ${COLORS.GREY.LIGHT}` }}
                >
                  <Heading>Edit Lot(s)</Heading>
                  <Box flex={1} flexDirection={'row'} alignItems={'baseline'}>
                    <Body>What do you want to change?</Body>
                    <Box {...s('input')}>
                      <SelectInput
                        options={changeTypeOptions}
                        onSelect={this.handleFieldChange}
                      />
                    </Box>
                  </Box>
                </Box>

                {field && (
                  <Box pl={PADDINGS.M} pt={PADDINGS.M} pr={PADDINGS.M} pb={0}>
                    <Box flex={1} flexDirection={'row'} alignItems={'baseline'}>
                      <Body>
                        {select ? 'Select' : 'Enter'} the{' '}
                        <span {...s('target')}>{_.get(field, 'label')}</span>{' '}
                        that will appear on selected lots.
                      </Body>
                      <Box {...s('input')}>
                        {textBox && (
                          <FormField
                            sendImmediate
                            name={_.get(field, 'value')}
                            Input={TextInput}
                            onBlur={(e) => {
                              setFieldValue(
                                _.get(field, 'value'),
                                formatNumber(e.target.value),
                                false
                              );
                            }}
                            onFocus={(e) => {
                              setFieldValue(
                                _.get(field, 'value'),
                                stripNumber(e.target.value),
                                false
                              );
                            }}
                            inputProps={{
                              placeholder: 'Enter Value'
                            }}
                          />
                        )}
                        {stage && (
                          <FormField
                            sendImmediate
                            name="stage_id"
                            Input={SelectInput}
                            onChange={(e) => {
                              if (e.target.value === 'add_stage') {
                                setFieldValue('stage_id', '', false);
                                this.toggle('stageModal');
                              }
                            }}
                            inputProps={{
                              options: stageNumbers
                                .sort((a, b) => a.stage_no - b.stage_no)
                                .map((val) => {
                                  return {
                                    value: val.id,
                                    label: val.stage_no.toString()
                                  };
                                })
                                .concat({
                                  value: 'add_stage',
                                  label: '+ Add Stage'
                                })
                            }}
                          />
                        )}
                        {price && (
                          <FormField
                            sendImmediate
                            name={'price'}
                            Input={TextInput}
                          />
                        )}
                        {titled && (
                          <FormField
                            sendImmediate
                            name="titled"
                            Input={SelectInput}
                            inputProps={{
                              options: [
                                {
                                  value: true,
                                  label: 'True'
                                },
                                {
                                  value: false,
                                  label: 'False'
                                }
                              ]
                            }}
                          />
                        )}
                        {status && (
                          <FormField
                            sendImmediate
                            name="status"
                            Input={SelectInput}
                            inputProps={{
                              options: statusOptions
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    {characteristics && (
                      <Box pt={PADDINGS.XS}>
                        {/* Characteristics row 1 */}
                        <Grid columns={5}>
                          <Column
                            onKeyPress={(e) => this.handleKeyPress(e, 'corner')}
                            width={1}
                            onClick={() => this.toggle('corner')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={corner} />
                              <Tiny>Corner</Tiny>
                            </Box>
                          </Column>
                          <Column
                            onKeyPress={(e) => this.handleKeyPress(e, 'fall')}
                            width={1}
                            onClick={() => this.toggle('fall')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={fall} />
                              <Tiny>Fall</Tiny>
                            </Box>
                          </Column>
                          <Column
                            onKeyPress={(e) => this.handleKeyPress(e, 'fill')}
                            width={1}
                            onClick={() => this.toggle('fill')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={fill} />
                              <Tiny>Fill</Tiny>
                            </Box>
                          </Column>
                          <Column
                            width={1}
                            onKeyPress={(e) =>
                              this.handleKeyPress(e, 'irregular')
                            }
                            onClick={() => this.toggle('irregular')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={irregular} />
                              <Tiny>Irregular</Tiny>
                            </Box>
                          </Column>
                          <Column
                            onKeyPress={(e) =>
                              this.handleKeyPress(e, 'nearbyAmenity')
                            }
                            width={1}
                            onClick={() => this.toggle('nearbyAmenity')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={nearbyAmenity} />
                              <Tiny>Nearby Amenity</Tiny>
                            </Box>
                          </Column>
                        </Grid>

                        {/* Characteristics row 2 */}
                        <Grid columns={5}>
                          <Column
                            onKeyPress={(e) =>
                              this.handleKeyPress(e, 'parkWetlands')
                            }
                            width={1}
                            onClick={() => this.toggle('parkWetlands')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={parkWetlands} />
                              <Tiny>Park / Wetlands</Tiny>
                            </Box>
                          </Column>
                          <Column
                            onKeyPress={(e) =>
                              this.handleKeyPress(e, 'powerlines')
                            }
                            width={1}
                            onClick={() => this.toggle('powerlines')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={powerlines} />
                              <Tiny>Powerlines</Tiny>
                            </Box>
                          </Column>
                          <Column
                            onKeyPress={(e) =>
                              this.handleKeyPress(e, 'substation')
                            }
                            width={1}
                            onClick={() => this.toggle('substation')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={substation} />
                              <Tiny>Substation</Tiny>
                            </Box>
                          </Column>

                          <Column
                            onKeyPress={(e) =>
                              this.handleKeyPress(e, 'doubleStorey')
                            }
                            width={1}
                            onClick={() => this.toggle('doubleStorey')}
                          >
                            <Box flex={1} {...s('checkbox')}>
                              <Checkbox value={doubleStorey} />
                              <Tiny>Double Storey</Tiny>
                            </Box>
                          </Column>
                          <Column width={1} />
                        </Grid>

                        <FormField
                          sendImmediate
                          label="Orientation"
                          name="lotOrientation"
                          onChange={(e) =>
                            this.setState({ lotOrientation: e.target.value })
                          }
                          Input={SelectInput}
                          inputProps={{
                            options: lotOrientationOptions
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                )}

                <ButtonBar>
                  <TextButton
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.closeModal();
                    }}
                  >
                    Cancel
                  </TextButton>
                  <PrimaryButton
                    green
                    isDisabled={!field || isSubmitting}
                    isSubmitting={isSubmitting || isValidating}
                    onClick={(e) => {
                      e.preventDefault();
                      submitForm();
                    }}
                  >
                    Save
                  </PrimaryButton>
                </ButtonBar>
                {stageModal && (
                  <StageModal
                    projectId={projectId}
                    worksheetId={worksheetId}
                    closeModal={() => {
                      this.setState({ stageModal: false });
                      stages.refreshList();
                    }}
                    onSubmit={(stage) => {
                      setFieldValue('stage_id', _.get(stage, 'id'), true);
                    }}
                  />
                )}
              </Form>
            );
          }}
        </ReactForms>
        <Error />
      </Box>
    );
  }
}

export default MassEditLotForm;
