import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      Product Price Movement displays pricing of commonly sold lot types.{' '}
      <span className="bold">Select from the dropdown list of lot types.</span>
      <br />
      <br />
      There are a number of toggles allowing you to adjust the chart to the data
      set you need.
      <br />
      <br />
      When selecting <span className="bold">Incentives and or Buffers</span>,
      the price shown becomes the net price by subtracting these from the list
      price.{' '}
      <span className="italic">
        Incentives and buffers are sourced through a variety of methods and
        should be used as a gauge only.
      </span>
      <br />
      <br />
      The dropdown lists at the bottom of the chart allow you to select a
      specific project/s - displaying a{' '}
      <span className="bold">live side-by-side comparison</span> of your chosen
      data set.
    </>
  );
};

export default InfoTooltip;
