import { Serie } from '@nivo/line';
import { getShortMonthWithYear } from 'utils/format';

export function formatForLineChart(records: MonthsSupplyDatum[]): Serie[] {
  if (records.length === 0) return [{ id: null, data: [] }];

  return [
    {
      id: 'months-supply',
      data: records.map((record) => {
        return { x: getShortMonthWithYear(record.end_date), y: record.average };
      })
    }
  ];
}
