import React from 'react';
import { DatumValue } from '@nivo/line';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import { Label, Tiny } from 'view/components/text';
import Box from '@rexlabs/box';
import {
  COLORS,
  BORDER_RADIUS,
  MARGINS,
  FONT
} from 'src/features/reports/theme';
import { numberWithCommas } from 'utils/format';

const styles = StyleSheet({
  container: {
    backgroundColor: COLORS.BLUE.DARK,
    padding: '10px',
    borderRadius: BORDER_RADIUS.DIALOGS
  },
  text: {
    color: COLORS.WHITE,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  count: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  legend: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginRight: MARGINS.TINY,
    backgroundColor: COLORS.BLUE.PRIMARY
  }
});

interface Project {
  serieColor: string;
  name: DatumValue;
  count: DatumValue;
}

interface TrendBoxToolTipProps {
  styles: Styles;
  date: DatumValue;
  projects: Project[];
}

const Tooltip = ({ styles: s, date, projects }: TrendBoxToolTipProps) => {
  return (
    <Box {...s('container')}>
      <Label {...s('text')}>{date}</Label>
      {/* Points are reversed from sliceTooltip prop of line graph so we need reverse here */}
      <Box mt="10px" flexDirection="column-reverse">
        {projects.map((project, index) => (
          <Box
            key={project.name}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={index === 0 ? '0' : MARGINS.TINY}
          >
            <Box flexDirection="row" alignItems="center" mr={MARGINS.TINY}>
              <div
                {...s('legend')}
                style={{ backgroundColor: project.serieColor }}
              />
              <Tiny {...s('text')}>{project.name}:</Tiny>
            </Box>
            <Tiny {...s('text', 'count')}>
              ${numberWithCommas(project.count)}
            </Tiny>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default styled(styles)(Tooltip);
