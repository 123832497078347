import React, { PureComponent } from 'react';
import Button from '@rexlabs/button';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/theme';

const textButtonStyles = StyleSheet({
  container: {
    background: 'transparent',
    color: COLORS.PRIMARY_DARK,
    height: '4.2rem',
    padding: '0 1.2rem',
    border: '0 none',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    textDecoration: 'underline'
  }
});

class TextButton extends PureComponent {
  render() {
    return <Button isInverted styles={textButtonStyles} {...this.props} />;
  }
}

export default TextButton;
