import React, { PureComponent } from 'react';
import { Modal, ButtonBar } from 'view/components/modal';
import { Heading, Body } from 'view/components/text';
import { PrimaryButton } from 'view/components/button';

// Test
class ExampleModal extends PureComponent {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal hasButtonBar>
        <Heading>Example</Heading>
        <Body>Hello World</Body>
        <ButtonBar>
          <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
        </ButtonBar>
      </Modal>
    );
  }
}

export default ExampleModal;
