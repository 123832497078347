import { Serie } from '@nivo/line';
import { ProjectPerformanceOverviewReport } from 'types/graph/project-performance-overview';
import { getShortMonthWithYear } from 'utils/format';

interface LineChartFormatProps {
  key: string;
  records: ProjectPerformanceOverviewReport;
  yKey: string;
}

export function formatProjectPerfResponse({
  key,
  records,
  yKey
}: LineChartFormatProps): { monthly: Serie[]; quarterly: Serie[] } {
  if (records.monthly.length === 0 || records.quarterly.length === 0) {
    return {
      monthly: [{ id: null, data: [] }],
      quarterly: [{ id: null, data: [] }]
    };
  }

  // 1. We assume here that we have a common `key` for x-axis data which is
  // `end_date` for monthly, and `quarter` for quarterly.
  // 2. We assume that `end_date` is a valid Date.
  return {
    monthly: [
      {
        id: key,
        data: records.monthly.map((record) => {
          return {
            x: getShortMonthWithYear(record['end_date']),
            y: record[yKey],
            excluded_lots: record.excluded_lots ?? 0
          };
        })
      }
    ],
    quarterly: [
      {
        id: key,
        data: records.quarterly.map((record) => {
          return {
            x: record['quarter'],
            y: record[yKey],
            excluded_lots: record.excluded_lots ?? 0
          };
        })
      }
    ]
  };
}
