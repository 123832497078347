import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  CSSProperties
} from 'react';
import { chain, isNumber, uniqueId } from 'lodash';

import { COLORS, FONT } from 'src/features/reports/theme';

import TableHeaderRow from './table-header-row';
import TableRow from './table-row';
import TableTitle from './table-title';
import { StyleSheet } from '@rexlabs/styling';
import moment from 'moment';

const styles = StyleSheet({
  table: {
    border: `1px solid ${COLORS.GREY.POWDER}`,
    borderBottom: 'none',
    width: '100%',
    maxHeight: '500px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      backgroundColor: COLORS.GREY.DARK
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: `${COLORS.GREY.DARK}D9` // 0.85 opacity
    },
    fontFamily: FONT.FAMILIES.DEFAULT
  }
});

export interface TableSortProps {
  sortField: string | number | symbol;
  dir: 'asc' | 'desc';
}

export type DataColumn<T> = {
  key: keyof T;
  title: string;
  sortable?: boolean;
  width?: CSSProperties['width'];
  render?: (row: T) => React.ReactNode;
};

export type TableProps<T> = {
  title?: string;
  data: T[];
  columns: DataColumn<T>[];
  defaultConfig?: TableSortProps;
  disableLazy?: boolean;
  tableStyle?: CSSProperties;
  titleStyle?: CSSProperties;
};

function Table<T>({
  title,
  data,
  columns,
  defaultConfig,
  disableLazy,
  tableStyle,
  titleStyle
}: TableProps<T>) {
  const { sortField, dir } = defaultConfig || {};

  const [sort, setSort] = useState<TableSortProps>({
    sortField: sortField || columns[0].key,
    dir: dir || 'asc'
  });

  useEffect(() => {
    setSort({
      sortField,
      dir
    });
  }, [sortField, dir]);

  const onSortAction = useCallback(
    (selectedSort: TableSortProps) => {
      const isAlreadyCurrentSort = sort.sortField === selectedSort.sortField;
      const isAscending = sort.dir === 'asc';

      setSort({
        sortField: selectedSort.sortField,
        dir: isAlreadyCurrentSort ? (isAscending ? 'desc' : 'asc') : 'desc'
      });
    },
    [sort.dir, sort.sortField]
  );

  const sortedData = useMemo(
    () =>
      chain(data)
        .map((datum) => ({ ...datum, uniqueIdKey: uniqueId() }))
        // Some values are null so we just set those to 0 for sorting
        .orderBy(
          (datum) => {
            if (sort.sortField === 'latest_release') {
              return moment(datum[sort.sortField], 'MMMM YY');
            }
            if (sort.sortField === 'estimated_completion_date') {
              return moment(datum[sort.sortField], "MMMM 'YY");
            }
            return datum[sort.sortField] ?? 0;
          },
          [sort.dir]
        )
        .value(),
    [data, sort.dir, sort.sortField]
  );

  // what this does is it traverses thru the columns and creates a value for `grid-template-columns`.
  // Example: 3 columns results to `grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)`
  // If there is a `width` on any column, it will use that `width` instead of `minmax(0, 1fr)`
  // Example: 3 columns (no width, has width, no width) results to
  // `grid-template-columns: minmax(0, 1fr) 150px minmax(0, 1fr);`
  const divisionStyle = useMemo(
    () =>
      columns
        .map((c) => (isNumber(c.width) ? `${c.width}px` : c.width) || '1fr')
        .join(' '),
    [columns]
  );

  return (
    <div className={styles.table} style={tableStyle}>
      {title && <TableTitle title={title} containerStyle={titleStyle} />}
      <TableHeaderRow
        columns={columns}
        onSort={onSortAction}
        sort={sort}
        divisionStyle={divisionStyle}
      />

      {sortedData.length > 0 &&
        sortedData.map((rowData, index) => (
          <TableRow
            divisionStyle={divisionStyle}
            key={rowData.uniqueIdKey}
            rowData={rowData}
            columns={columns}
            disableLazy={disableLazy}
          />
        ))}
    </div>
  );
}

export default Table;
