/**
 * PLEASE NOTE
 * This Component is a copy of tooltip component from rexlabs/tooltip:3.0.9
 * We modified it by adding a contentProps that will be passed in the
 * Content component.
 *
 * This is needed to avoid the declaring a component inside a component when using
 * the tooltip component because it will cause issues like, Content component remounts
 * instead of rerenders, will lose focus on the element, flashing of the element,
 * useEffect running everytime which causes infinite loop, etc..
 *
 * Example of declaring new component inside the component:
 * const Filter = () => {
 *  const [filters, setFilters] = useState([]);
 *  return <Tooltip Content={() => <Content filters={filters} setFilters={setFilters} />} />;
 * };
 *
 * To avoid it:
 * const Filter = () => {
 *  const [filters, setFilters] = useState([]);
 *  return <Tooltip Content={Content} contentProps={{ filters, setFilters }} />;
 * };
 */
import React, { Component, PureComponent } from 'react';
import types from 'prop-types';
import _, { noop, isArray, includes, get, isString } from 'lodash';
import { autobind } from 'core-decorators';
import { withState } from 'recompose';

import Tether, { PLACEMENTS } from '@rexlabs/tether';
import { styled, StyleSheet, keyframes } from '@rexlabs/styling';

const enterAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale3d(0.7,0.7,0.7)'
  },
  '100%': {
    opacity: 1,
    transform: 'scale3d(1,1,1)'
  }
});

const exitAnimation = keyframes({
  '0%': {
    opacity: 1,
    transform: 'scale3d(1,1,1)'
  },
  '100%': {
    opacity: 0,
    transform: 'scale3d(0.7,0.7,0.7)'
  }
});

const INTERACTIONS = {
  CLICK: 'CLICK',
  HOVER: 'HOVER',
  NONE: 'NONE'
};

const InteractionsPropType = types.oneOfType([
  types.arrayOf(types.oneOf(Object.values(INTERACTIONS))),
  types.oneOf(Object.values(INTERACTIONS))
]);

const tetherStyles = StyleSheet({
  opening: {
    animation: `${enterAnimation} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
  },
  closing: {
    animation: `${exitAnimation} forwards normal 0.25s cubic-bezier(.24,.9,.27,1)`
  },

  content: {
    transition: 'none',
    transformOrigin: 'center'
  },

  contentTop: {
    transform: 'translateY(-.8rem)'
  },

  contentBottom: {
    transform: 'translateY(.8rem)'
  },

  contentLeft: {
    transform: 'translateX(-.8rem)'
  },

  contentRight: {
    transform: 'translateX(.8rem)'
  }
});

const defaultStyles = StyleSheet({
  tooltip: {
    display: 'block',
    padding: '1rem',
    minWidth: '10rem',
    boxSizing: 'border-box',
    margin: 0,
    background: 'white',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    position: 'relative',
    zIndex: 3
  },

  clickable: {
    cursor: 'pointer',
    position: 'relative',

    '&:active:focus': {
      outline: 'none'
    },
    display: 'flex'
  },

  overlay: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: 2,
    left: 0,
    top: 0
  },
  container: {
    display: 'inline-block'
  }
});

@styled(defaultStyles)
@autobind
class Tooltip extends Component {
  /** Enum values for the `placement` prop. */
  static PLACEMENTS = PLACEMENTS;
  /** Enum values for the `openOn` / `closeOn` prop. */
  static INTERACTIONS = INTERACTIONS;

  _refs = {};
  state = {
    isClosing: false
  };

  /** @deprecated */
  static CLICK = INTERACTIONS.CLICK;
  /** @deprecated */
  static HOVER = INTERACTIONS.HOVER;
  /** @deprecated */
  static NONE = INTERACTIONS.NONE;

  static propTypes = {
    /**
     * Content to render inside the tooltip.
     */
    content: types.node,
    /**
     * Props that will be passed in the Content Component
     * This will be useful when we don't want to create a component
     * inside the component where we use the tooltip
     * Because that causes rerendering and remounting problems
     * which will cause performance issues as well
     */
    contentProps: types.object,
    /**
     * Opening trigger - click or mouse over/out.
     */
    openOn: InteractionsPropType,
    /**
     * Closing trigger - click or mouse over/out;
     */
    closeOn: InteractionsPropType,
    /**
     * Tether placement.
     */
    placement: types.oneOf(Object.values(PLACEMENTS)),
    /**
     * Mouse out delay in ms.
     * Increase to keep the tooltip open for longer when mouse has left.
     */
    hoverTimeout: types.number,
    /**
     * Offset arrow to move it closer towards the middle of the tooltip. Any CSS unit.
     */
    arrowMargin: types.string,
    /**
     * Tooltip distance from button.
     */
    distance: types.string,
    /**
     * Offset the tooltip on its primary axis
     */
    offset: types.string,
    /**
     * Where or not to automatically reposition
     */
    autoFlip: types.bool,
    /**
     * Whether or not the Tether is about to close. Allows you to hook into
     * Tether to display closing animations.
     */
    isClosing: types.bool,
    /**
     * Whether or not to stop propagation when clicking on the tooltip button
     * Useful if you dont want your tooltip triggering other click events
     * higher in the DOM hierarchy!
     */
    stopPropagation: types.bool,
    /**
     * Function that will be run when the tooltip open/closed/closing state
     * changes. Receives an object with `isOpen` and `isClosing` properties.
     */
    onChange: types.func,
    /**
     * This is useful when you don't want to close the tooltip for some reason
     */
    cancelExit: types.bool
  };

  static defaultProps = {
    arrowMargin: '1.4rem',
    distance: '20px',
    offset: '0px',
    openOn: INTERACTIONS.CLICK,
    closeOn: INTERACTIONS.CLICK,
    hoverTimeout: 250,
    closeDuration: 250,
    tetherStyles,
    stopPropagation: false,
    Arrow: null,
    onChange: noop,
    contentProps: {},
    cancelExit: false
  };

  constructor() {
    super();
    this.contentProps = {
      toggle: this.toggleTooltip,
      open: this.openTooltip,
      close: this.closeTooltip
    };
    this.state = {
      isOpen: false
    };
    this.interactionSuspended = false;
  }

  componentDidMount() {
    document.body.addEventListener('mousedown', this.onBodyMouseDown);
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.onBodyMouseDown);
  }

  preventBodyMouseDown = false;
  onBodyMouseDown(e) {
    if (
      !this.preventBodyMouseDown &&
      this.props.isOpen &&
      this.tooltip &&
      !this.tooltip.contains(e.target)
    ) {
      this.closeTooltip();
    }
  }

  onEnterPress(e, callback) {
    if (e.key === 'Enter') {
      this.toggleTooltip();
    }
    if (callback) callback(e);
  }

  _canPropOn(prop, type) {
    const propVal = this.props[prop];
    return isArray(propVal) ? includes(propVal, type) : propVal === type;
  }

  canCloseOn(interactionType) {
    return this._canPropOn('closeOn', interactionType);
  }

  canOpenOn(interactionType) {
    return this._canPropOn('openOn', interactionType);
  }

  closeTooltip() {
    const { setOpen, closeDuration, onChange, cancelExit } = this.props;
    if (cancelExit) return;
    if (!this.state.isClosing || this.props.isOpen) {
      const setClosingToFalse = () => {
        setOpen(false);
        onChange({ isOpen: false, isClosing: false });
        this.setState(() => ({ isClosing: false, isOpen: false }));
      };

      clearTimeout(this.closeTimer);
      if (closeDuration > 0) {
        this.setState(() => ({ isClosing: true }));
        onChange({ isOpen: false, isClosing: true });
        this.closeTimer = setTimeout(setClosingToFalse, closeDuration);
      } else {
        setClosingToFalse();
      }
    }
  }

  openTooltip() {
    clearTimeout(this.closeTimer);
    this.setState(
      () => ({ isClosing: false, isOpen: true }),
      () => {
        this.interactionSuspended = true;
        this.suspendedTimer = setTimeout(() => {
          this.interactionSuspended = false;
        }, 500);
        this.props.setOpen(true);
        this.props.onChange({ isOpen: true, isClosing: false });
      }
    );
  }

  toggleTooltip() {
    if (this.props.isOpen) {
      this.closeTooltip();
    } else {
      this.openTooltip();
    }
  }

  onMouseOver(e) {
    if (this.state.isOpen) return;
    if (this.canCloseOn(INTERACTIONS.HOVER)) {
      // Bail on closing the tooltip from hovering out,
      // if we can.
      clearTimeout(this.mouseOutTimer);
    }
    if (this.canOpenOn(INTERACTIONS.HOVER)) {
      this.openTooltip();
    }
  }

  onMouseLeave(e) {
    if (this.canCloseOn(INTERACTIONS.HOVER)) {
      clearTimeout(this.mouseOutTimer);
      this.mouseOutTimer = setTimeout(
        this.closeTooltip,
        this.props.hoverTimeout
      );
    }
  }

  onKeyPress(e) {
    const onlyChild = React.Children.only(this.props.children);
    this.onEnterPress(e, get(onlyChild, 'props.onKeyPress'));
  }

  onClick(e) {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }

    // HACK: this is pretty Rex specific, when having a tooltip within an
    // iframe the iframe body will receive the click after the iframe content,
    // triggering the `onBodyMouseDown` which immediately closes the tooltip
    this.preventBodyMouseDown = true;
    setTimeout(() => {
      this.preventBodyMouseDown = false;
    }, 50);

    const onlyChild = React.Children.only(this.props.children);
    if (get(onlyChild, 'props.onClick')) {
      onlyChild.props.onClick(e);
    }

    if (this.props.isOpen && this.canCloseOn(INTERACTIONS.CLICK)) {
      this.closeTooltip();
    }

    if (!this.props.isOpen && this.canOpenOn(INTERACTIONS.CLICK)) {
      clearTimeout(this.closeTimer);
      clearTimeout(this.mouseOutTimer);
      this.openTooltip();
    }
  }

  renderContent({ placement }) {
    const { Content, styles: s } = this.props;
    return (
      <div ref={this.setRef}>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%'
          }}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
        />
        <div
          {...s('tooltip')}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
        >
          {isString(Content) ? (
            <Content {...this.contentProps} {...this.props.contentProps} />
          ) : (
            <Content
              {...this.contentProps}
              {...this.props.contentProps}
              placement={placement}
            />
          )}
        </div>
      </div>
    );
  }

  setRef(ref) {
    this.tooltip = ref;
  }

  renderChild({ ref }) {
    const { children, styles: s, isOpen } = this.props;
    const onlyChild = React.Children.only(children);
    return this.canOpenOn(INTERACTIONS.NONE) &&
      this.canCloseOn(INTERACTIONS.NONE) ? (
      onlyChild
    ) : (
      <div
        ref={ref}
        tabIndex={0}
        {...s('clickable')}
        style={{
          zIndex: isOpen ? 3 : 0
        }}
        onKeyPress={this.onKeyPress}
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
      >
        {onlyChild}
      </div>
    );
  }

  render() {
    const {
      styles: s,
      style,
      className,
      children,
      openOn,
      closeOn,
      arrowMargin,
      placement,
      offset,
      autoFlip,
      Arrow,
      Content,
      tetherStyles,
      isOpen,
      hoverTimeout,
      distance,
      ...rest
    } = this.props;

    const { isClosing } = this.state;

    return (
      <div>
        {isOpen ? (
          <Tether
            {...rest}
            offset={offset}
            styles={tetherStyles}
            arrowMargin={arrowMargin}
            distance={distance}
            placement={placement}
            Content={this.renderContent}
            autoFlip={autoFlip}
            Arrow={Arrow}
            isClosing={isClosing}
          >
            {this.renderChild}
          </Tether>
        ) : (
          this.renderChild({})
        )}
      </div>
    );
  }
}

export default Tooltip;
const TooltipStateful = withState('isOpen', 'setOpen', false)(Tooltip);
export { TooltipStateful };
