import React, { Component } from 'react';
import DateInput from '@rexlabs/date-input';
import CalendarSvg from 'assets/calendar.svg';

class DefaultDateSelect extends Component {
  render() {
    return <DateInput uncontrolled suffix={<CalendarSvg />} {...this.props} />;
  }
}

export default DefaultDateSelect;
