import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      fetchList: (type, { ...args }) => api.get('/stats/settings', args),
      fetchItem: (type, args, id) => api.get(`/stats/settings/${id}`, args),
      createItem: (type, { ...args }) => api.post('/stats/settings', args),
      updateItem: (type, { ...args }, id) =>
        api.put(`/stats/settings/${id}`, args),
      trashItem: (type, { ...args }, id) =>
        api.delete(`/stats/settings/${id}`, args)
    }
  }
};

const actionCreators = {
  fetchWorksheets: {
    request: ({ id, ...args }) =>
      api.get(`/stats/settings/${id}/worksheets`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  },
  patchItem: {
    request: ({ id, ...args }) => api.patch(`/stats/settings/${id}`, args),
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const SettingsGenerator = new Generator('settings', config);
export default SettingsGenerator.createEntityModel({ actionCreators });
