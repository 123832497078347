import React, { PureComponent } from 'react';

import Box from '@rexlabs/box/lib/box';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { withModel } from '@rexlabs/model-generator';
import { styled, StyleSheet } from '@rexlabs/styling';

import withError from 'view/containers/with-error';
import { Heading } from 'view/components/text';
import { PrimaryButton, TextButton } from 'view/components/button';
import { Modal } from 'view/components/modal';

import { PADDINGS } from 'src/theme';
import statsModel from 'data/models/entities/stats';

const defaultStyles = StyleSheet({
  downloadButton: {
    marginRight: 5
  }
});

@withError.withPropName('errorModal')
@withModel(statsModel)
@styled(defaultStyles)
class ExportReport extends PureComponent {
  state = {
    isLoading: false,
    downloadUrl: ''
  };

  componentDidMount() {
    this.generateReport();
  }

  generateReport = () => {
    const { stats, errorModal } = this.props;
    this.setState({ isLoading: true });

    stats
      .exportSoldLots()
      .then((response) => {
        if (response.ok) {
          const { result } = response.data;
          this.setState({ downloadUrl: result, isLoading: false });
        }
      })
      .catch(errorModal.open);
  };

  downloadReport = () => {
    if (this.state.downloadUrl) {
      window.open(this.state.downloadUrl, '_blank');
    }
  };

  render() {
    const { styles: s } = this.props;
    const { isLoading, downloadUrl } = this.state;

    return (
      <Modal width={'fit-content'} {...this.props}>
        <Box p={PADDINGS.S}>
          <Heading>Export Report</Heading>

          {!isLoading ? (
            <Box flex={1} pt={PADDINGS.XS}>
              {downloadUrl && (
                <PrimaryButton
                  green
                  onClick={this.downloadReport}
                  {...s('downloadButton')}
                >
                  Download
                </PrimaryButton>
              )}

              <PrimaryButton onClick={this.generateReport}>
                Regenerate Export
              </PrimaryButton>
            </Box>
          ) : (
            <Box flex={1} alignContent={'flex-end'} justifyContent={'center'}>
              <LoadingSpinner />
            </Box>
          )}
        </Box>

        <Box flex={1} justifyContent={'flex-end'}>
          <TextButton onClick={this.props.onClose}>Close</TextButton>
        </Box>
      </Modal>
    );
  }
}

export default ExportReport;
