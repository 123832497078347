export const FILTER = [
  {
    label: 'Gross',
    key: 'gross'
  },
  {
    label: 'Net',
    key: 'net'
  }
];
