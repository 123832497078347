import React, { ComponentProps } from 'react';
import Button from '@rexlabs/button';
import { StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/features/reports/theme';
import LoadingSpinner from '@rexlabs/loading-spinner';
import _ from 'lodash';

const getStyles = _.memoize((color) =>
  StyleSheet({
    container: {
      transition: 'all 0.2s ease-in-out',
      backgroundColor: color,
      borderColor: color
    },
    content: {
      color: COLORS.WHITE,
      fontFamily: FONT.FAMILIES.DEFAULT,
      transition: 'all 0.2s ease-in-out',
      // Styling for svg icon
      '& svg > path': {
        stroke: COLORS.WHITE
      }
    }
  })
);

interface PrimaryButtonProps extends ComponentProps<typeof Button> {
  color?: string;
  fullWidth?: boolean;
  isDisabled?: boolean;
}

export default function PrimaryButton({
  color,
  fullWidth,
  isDisabled,
  ...props
}: PrimaryButtonProps) {
  return (
    <Button
      Loading={() => (
        <LoadingSpinner colors={[COLORS.WHITE]} size={20} strokeWidth={4} />
      )}
      styles={getStyles(color ?? COLORS.BLUE.PRIMARY)}
      isDisabled={isDisabled}
      {...props}
    />
  );
}
