import React from 'react';
import { BarLayer, ResponsiveBar } from '@nivo/bar';
import { SolidXAxis } from 'src/features/reports/components/graph';
import { NIVO_THEME } from 'src/features/reports/theme';

export type GroupModeType = 'stacked' | 'grouped';

export type BarDataItem = Omit<
  BarDataItemColored,
  'activeColor' | 'onHoldColor' | 'flaggedColor'
>;

export interface BarDataItemColored {
  monthDate: string;
  Active: number;
  ActiveColor: string;
  'On-Hold': number;
  'On-HoldColor': string;
  Flagged: number;
  FlaggedColor: string;
}

export interface BarData {
  keys: string[];
  data: BarDataItem[];
}

interface BarChartProps {
  barData: BarData;
  groupMode: GroupModeType;
}

const ProjectCountBarChart = ({ barData, groupMode }: BarChartProps) => {
  return (
    <ResponsiveBar
      data={barData?.data ?? []}
      theme={NIVO_THEME}
      keys={barData?.keys ?? []}
      indexBy="monthDate"
      groupMode={groupMode}
      colors={(bar) => bar.data[`${bar.id}Color`]}
      margin={{ top: 50, right: 50, bottom: 75, left: 50 }}
      padding={0.5}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 9,
        tickPadding: 0,
        legend: 'MONTH',
        legendPosition: 'middle',
        legendOffset: 40
      }}
      enableLabel={false}
      axisLeft={{
        format: (e) => Math.floor(e) === e && e,
        legend: 'NUMBER OF PROJECTS',
        legendPosition: 'middle',
        legendOffset: -40,
        tickSize: 0
      }}
      legends={[
        {
          dataFrom: 'keys',
          data: barData.keys.map((id, index) => ({
            color: barData.data[index][`${id}Color`],
            id,
            label: id
          })),
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 75,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          symbolSize: 12,
          symbolShape: 'circle'
        }
      ]}
      layers={[
        // Default layers
        'grid',
        'axes',
        'bars',
        'markers',
        'legends',
        'annotations',
        // Custom layers
        SolidXAxis as unknown as BarLayer<BarDataItem>
      ]}
    />
  );
};

export default ProjectCountBarChart;
