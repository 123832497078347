import React, { useContext, useEffect, useRef, useState } from 'react';
import { Canceler } from 'axios';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { CancelToken } from '@rexlabs/api-client';

import Panel from '../panel';
import { ToggleButtonGroup } from '../toggle-button';
import MonthRangeSlider, { MonthYearType } from '../slider/month-range-slider';
import { HeatIndicator } from '../heat-map';

import { STATUS, BONUS } from './constants';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import useMonthRangeFilter from 'src/features/reports/hooks/use-month-range-filter';
import { compose } from 'utils/compose';
import projectPositioningModel from 'src/features/reports/models/project-positioning';
import withError from 'view/containers/with-error';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import PricingMatrixTable from './pricing-matrix-table';
import { ProjectPositioningModel } from 'types/models/project-positioning';
import { PricingMatrixReport } from 'types/graph';
import { Loading } from '../graph';
import { Auth0Context } from 'src/auth0-provider';
import { POSITIONING_PAGE } from 'src/features/reports/constants';
import InfoTooltip from './info-tooltip';

interface PricingMatrixProps {
  errorModal: WithErrorModalProps;
  projectPositioningModel: ProjectPositioningModel;
}

interface Filters {
  status: Array<'available' | 'sold'>;
  bonus: typeof BONUS[number]['key'][];
}

const PricingMatrix = ({
  errorModal: { open: openError },
  projectPositioningModel
}: PricingMatrixProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const { period, selectedProjectIds, subRegionsPermissions, isFirstLoad } =
    useContext(ReportSettingsContext);
  const canceler = useRef<Canceler | null>(null);
  const { dateFilter, setDateFilter, startPeriod, endPeriod } =
    useMonthRangeFilter();
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState<PricingMatrixReport[]>([]);
  const [filters, setFilters] = useState<Filters>({
    status: ['sold'],
    bonus: []
  });

  useEffect(() => {
    if (
      (selectedProjectIds.length === 0 || !startPeriod || !endPeriod) &&
      !isFirstLoad
    ) {
      return;
    }

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    projectPositioningModel
      .fetchPricingMatrix({
        queryParams: {
          project_ids: selectedProjectIds,
          start_period: startPeriod,
          end_period: endPeriod,
          report: filters.status[0]
        },
        hasPermission:
          !isFirstLoad &&
          hasPermission([...subRegionsPermissions, POSITIONING_PAGE]),
        config: { cancelToken }
      })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;
        setReport(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        openError(error);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    projectPositioningModel,
    dateFilter,
    startPeriod,
    endPeriod,
    openError,
    period,
    filters.status,
    hasPermission,
    subRegionsPermissions,
    isFirstLoad
  ]);

  return (
    <Panel
      title="Pricing Matrix"
      tooltip={{
        title: 'Pricing Matrix',
        description: <InfoTooltip />
      }}
    >
      <Loading isLoading={isLoading} />
      <Box flexDirection="row" justifyContent="center" mb="34px">
        <Box width="236px" mr="12px" pt="12px">
          <ToggleButtonGroup
            group={STATUS}
            value={filters.status}
            onToggle={(key: string) =>
              setFilters((prev) => ({
                ...prev,
                status: [key] as Filters['status']
              }))
            }
          />
        </Box>
        <Box width="236px" ml="12px" pt="12px">
          <ToggleButtonGroup
            collapsed
            value={filters.bonus}
            group={BONUS}
            onToggle={(key: string) =>
              setFilters((prev) => {
                if (prev.bonus.includes(key)) {
                  return {
                    ...prev,
                    bonus: prev.bonus.filter((selected) => selected !== key)
                  };
                }

                return {
                  ...prev,
                  bonus: [...prev.bonus, key]
                };
              })
            }
          />
        </Box>
      </Box>
      <Box width="588px" m="0 auto 34px">
        <MonthRangeSlider
          value={dateFilter}
          onChange={(value: MonthYearType) => setDateFilter(value)}
          numOfMonthsBack={11}
          latestMonth={period}
          disabled={filters.status.includes('available')}
        />
      </Box>
      <PricingMatrixTable
        report={report}
        status={filters.status[0]}
        bonus={filters.bonus}
      />
      <HeatIndicator
        lowestHeat="LEAST EXPENSIVE"
        highestHeat="MOST EXPENSIVE"
      />
    </Panel>
  );
};

export default compose(
  withModel(projectPositioningModel),
  withError.withPropName('errorModal')
)(PricingMatrix);
