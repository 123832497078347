import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import { Canceler } from 'axios';
import Box from '@rexlabs/box';
import { CancelToken } from '@rexlabs/api-client';
import { isEmpty } from 'lodash';
import { withModel } from '@rexlabs/model-generator';
import { compose } from 'utils/compose';
import withError from 'view/containers/with-error';
import Panel from 'src/features/reports/components/panel';

import FeaturedBox from 'src/features/reports/components/panel/featured-box';
import keyInsightsModel from 'src/features/reports/models/key-insights';
import { ToggleButtonGroup } from 'src/features/reports/components/toggle-button';
import { ButtonGroupKeyLabelType } from 'src/features/reports/components/toggle-button/toggle-button-group';

import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { formatForBarChart, formatForLineChart } from 'utils/graph/format';
import MonthsSupplyLineChart from './months-supply-line-chart';
import ProjectCountBarChart, { GroupModeType } from './project-count-bar-chart';
import { Loading } from '../graph';
import { KeyInsightsModel } from 'types/models/key-insights';
import { MonthsSupplyResponse, ProjectCountResponse } from 'types/graph';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { Auth0Context } from 'src/auth0-provider';
import { INSIGHTS_PAGE } from 'src/features/reports/constants';
import InfoTooltip from './info-tooltip';

export enum SupplyOverviewViewTypes {
  MonthSupply,
  ProjectCount
}

const buttonFilters: ButtonGroupKeyLabelType[] = [
  {
    label: 'Stacked',
    key: 'stacked'
  },
  {
    label: 'Grouped',
    key: 'grouped'
  }
];

interface SupplyOverviewProps {
  keyInsightsModel: KeyInsightsModel;
  errorModal: WithErrorModalProps;
}

function SupplyOverview({
  keyInsightsModel,
  errorModal: { open: openError }
}: SupplyOverviewProps) {
  const { hasPermission } = useContext(Auth0Context);
  const canceler = useRef<Canceler | null>(null);
  const { selectedProjectIds, period, isFirstLoad } = useContext(
    ReportSettingsContext
  );
  const [view, setView] = useState<SupplyOverviewViewTypes>(
    SupplyOverviewViewTypes.MonthSupply
  );
  const [filter, setFilter] = useState<GroupModeType>('stacked');
  const [monthsSupply, setMonthsSupply] = useState<MonthsSupplyResponse | null>(
    null
  );
  const [projectCount, setProjectCount] = useState<ProjectCountResponse | null>(
    null
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const { fetchSupplyOverview } = keyInsightsModel;

  useEffect(() => {
    // Do not call api when there is no project selected;
    if ((!selectedProjectIds.length || !period) && !isFirstLoad) return;

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setLoading(true);
    Promise.all([
      fetchSupplyOverview({
        queryParams: {
          project_ids: selectedProjectIds,
          period,
          report: 'months_supply'
        },
        hasPermission: !isFirstLoad && hasPermission([INSIGHTS_PAGE]),
        config: { cancelToken }
      }),
      fetchSupplyOverview({
        queryParams: {
          project_ids: selectedProjectIds,
          period,
          report: 'project_count'
        },
        hasPermission: !isFirstLoad && hasPermission([INSIGHTS_PAGE]),
        config: { cancelToken }
      })
    ])
      .then(([monthsSupplyResponse, projectCountResponse]) => {
        // Empty when the api call got cancelled
        if (!monthsSupplyResponse.data && !projectCountResponse.data) return;
        setMonthsSupply(monthsSupplyResponse.data as MonthsSupplyResponse);
        setProjectCount(projectCountResponse.data as ProjectCountResponse);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        openError(error);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    openError,
    fetchSupplyOverview,
    period,
    hasPermission,
    isFirstLoad
  ]);

  const onChangeChartType = (key: GroupModeType) => {
    setFilter(key);
  };

  const onChangeView = (key: SupplyOverviewViewTypes) => {
    setView(key);
  };

  const monthsSupplyRecords = useMemo(() => {
    if (isEmpty(monthsSupply?.records)) return [];
    return formatForLineChart(monthsSupply.records);
  }, [monthsSupply]);

  const projectCountRecords = useMemo(() => {
    if (isEmpty(projectCount?.records)) return [];
    return formatForBarChart(projectCount.records);
  }, [projectCount]);

  const featuredValuesContent = useMemo(() => {
    if (!isLoading && projectCount && monthsSupply) {
      return (
        <Fragment>
          <FeaturedBox
            onClick={() => onChangeView(SupplyOverviewViewTypes.MonthSupply)}
            label="MONTH'S SUPPLY"
            value={monthsSupply.average || '-'}
            active={view === SupplyOverviewViewTypes.MonthSupply}
          />
          <FeaturedBox
            onClick={() => onChangeView(SupplyOverviewViewTypes.ProjectCount)}
            label="PROJECT COUNT"
            value={projectCount.total || '-'}
            active={view === SupplyOverviewViewTypes.ProjectCount}
          />
        </Fragment>
      );
    }

    return null;
  }, [isLoading, monthsSupply, projectCount, view]);

  return (
    <Panel
      title="Supply Overview"
      featuredValues={featuredValuesContent}
      tooltip={{
        title: 'Supply Overview',
        description: <InfoTooltip />
      }}
    >
      <Loading isLoading={isLoading} />
      {view === SupplyOverviewViewTypes.ProjectCount && (
        <Box justifyContent="center" mt={25}>
          <Box width={300}>
            <ToggleButtonGroup
              group={buttonFilters}
              value={[filter]}
              onToggle={onChangeChartType}
            />
          </Box>
        </Box>
      )}
      {view === SupplyOverviewViewTypes.MonthSupply ? (
        <Box width="100%" height={610} mt={24}>
          <MonthsSupplyLineChart data={monthsSupplyRecords} />
        </Box>
      ) : (
        <Box width="100%" height={610}>
          <ProjectCountBarChart
            groupMode={filter}
            barData={{
              data: projectCountRecords,
              keys: ['Active', 'Flagged', 'On-Hold']
            }}
          />
        </Box>
      )}
    </Panel>
  );
}

export default compose(
  withError.withPropName('errorModal'),
  withModel(keyInsightsModel)
)(SupplyOverview);
