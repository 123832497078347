import React, { PureComponent } from 'react';
import { Modal } from 'view/components/modal';
import RegionForm from 'view/forms/sub-region';

class RegionModal extends PureComponent {
  render() {
    return (
      <Modal width={500} hasButtonBar>
        <RegionForm {...this.props} />
      </Modal>
    );
  }
}

export default RegionModal;
