import _ from 'lodash';
import Validator from 'validatorjs';

// Define available logical operators
const logicalOperators = ['and', 'or'];

// Define available field operators and rules
const operators = {
  eq: {
    operators: ['eq', '=='],
    rules: ['required']
  },
  neq: {
    operators: ['neq', '!='],
    rules: ['required']
  },
  gt: {
    operators: ['gt', '>'],
    rules: ['required']
  },
  gte: {
    operators: ['gte', '>='],
    rules: ['required']
  },
  lt: {
    operators: ['lt', '<'],
    rules: ['required']
  },
  lte: {
    operators: ['lte', '<='],
    rules: ['required']
  },
  in: {
    operators: ['in'],
    rules: ['required', 'array']
  },
  nin: {
    operators: ['nin'],
    rules: ['required', 'array']
  },
  match: {
    operators: ['match'],
    rules: ['required']
  },
  nmatch: {
    operators: ['nmatch'],
    rules: ['required']
  },
  between: {
    operators: ['between'],
    rules: ['required', 'regex:/^(-?[^,]+),([^,]+)$/']
  },
  nbetween: {
    operators: ['nbetween'],
    rules: ['required', 'regex:/^(-?[^,]+),([^,]+)$/']
  },
  empty: {
    operators: ['empty'],
    rules: []
  },
  nempty: {
    operators: ['nempty'],
    rules: []
  }
};

// Convert array to quri string
export const toQuri = (input) => {
  if (!Array.isArray(input)) {
    throw new Error('Expected array input');
  }

  return _(input)
    .map((obj) => {
      if (logicalOperators.indexOf(obj.op) !== -1) {
        return `${obj.op}(${toQuri(obj.value)})`;
      }

      return encodeOne(obj);
    })
    .join(',');
};

// Get an operator
export const getOp = (op) => {
  const found = Object.keys(operators).find((key) => {
    return operators[key].operators.indexOf(op) !== -1;
  });

  if (_.isEmpty(found)) {
    return op;
  }

  return found;
};

// Convert object to single quri string
export const encodeOne = (obj) => {
  // Get the operator
  const operator = getOp(obj.op);

  if (operator[0] !== '_') {
    // Validate the value
    const validation = new Validator(
      { value: obj.value },
      { value: operators[operator].rules }
    );
    if (validation.fails()) {
      throw new Error('Validation failed');
    }
  }

  return `${obj.field}.${operator}(${obj.value})`;
};
