import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Transition, animated } from 'react-spring/renderprops';
import Header from './collapsible-header';
import _ from 'lodash';
import Box from '@rexlabs/box';
import { Small } from '../text';
import { PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    width: '100%'
  },
  pointer: {
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'sans-serif',
    cursor: 'pointer',
    '&:hover': {
      color: 'aqua'
    }
  },
  item: {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    textTransform: 'uppercase',
    paddingTop: PADDINGS.TINY
  }
});

@styled(defaultStyles)
class Collapsible extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
  };

  state = {
    items: []
  };

  componentDidMount() {
    const { items } = this.props;

    this.setState({
      items: items.map((item) => ({
        ...item,
        enabled: false
      }))
    });
  }

  updateItems = (items, key) => {
    const newItems = _.assign({}, items);
    _.updateWith(newItems, `[${key}].enabled`, () => !newItems[key].enabled);

    this.setState({
      items: _.values(newItems)
    });
  };

  render() {
    const { styles: s } = this.props;
    const { items } = this.state;

    return (
      <Box {...s('container')}>
        {items.map((item, key) => {
          const enabledItem = item.enabled ? item.content : [];
          return (
            <div key={key}>
              <Header onClick={() => this.updateItems(items, key)}>
                {item.header}
              </Header>
              <Transition
                items={enabledItem}
                from={{ overflow: 'hidden', height: 0, opacity: 0 }}
                enter={{ height: 'auto', opacity: 1 }}
                leave={{ height: 0, opacity: 0 }}
                trail={0}
              >
                {(renderItem) => (styles) => {
                  return (
                    <animated.div {...s('item')} style={{ ...styles }}>
                      {React.isValidElement(renderItem) ? (
                        renderItem
                      ) : (
                        <Small>{renderItem}</Small>
                      )}
                    </animated.div>
                  );
                }}
              </Transition>
            </div>
          );
        })}
      </Box>
    );
  }
}

export default Collapsible;
