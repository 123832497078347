import Generator from 'data/models/generator';
import { identity } from 'lodash';
import { ApiArgs } from 'types/common';
import { CheckReportParams, ReportQueryParams } from 'types/params';
import { api } from 'utils/api-client';

const actionCreators = {
  fetchAvailableDates: {
    request: ({
      queryParams,
      config
    }: Omit<ApiArgs<Pick<ReportQueryParams, 'project_ids'>>, 'permissions'>) =>
      api.get(`/reports/available-dates`, queryParams, config),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchSubRegions: {
    request: () => api.get('/sub-regions/list-access'),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  checkReportPeriodLimit: {
    request: ({ queryParams, config }: ApiArgs<CheckReportParams>) =>
      api.get('/reports/check-report-period-limit', queryParams, config),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const ReportSettings = new Generator('reportSettingsModel');
export default ReportSettings.createModel({ actionCreators });
