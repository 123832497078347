import Generator from 'data/models/generator';
import { identity } from 'lodash';
import { ApiArgs } from 'types/common';
import {
  PricingMatrixParams,
  ProjectPerformanceParams,
  ReportQueryParams,
  ReportRangeQueryParams
} from 'types/params';
import { api } from 'utils/api-client';
import {
  fetchPricingMatrixMock,
  fetchProjectPerfAverageMock,
  fetchProjectPerfDataMock,
  fetchProjectPriceRangeMock
} from '../mocks/project-positioning';

const actionCreators = {
  fetchProjectPerfData: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ProjectPerformanceParams>) =>
      hasPermission
        ? api.get(
            `/reports/project-positioning/project-performance-overview-graph`,
            queryParams,
            config
          )
        : fetchProjectPerfDataMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchBestPerforming: {
    request: ({ queryParams, config }: ApiArgs<ReportQueryParams>) =>
      api.get(
        `/reports/project-positioning/best-performing-project`,
        queryParams,
        config
      ),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchProjectPerfAverage: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ReportQueryParams>) =>
      hasPermission
        ? api.get(
            `/reports/project-positioning/project-performance-overview`,
            queryParams,
            config
          )
        : fetchProjectPerfAverageMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchProjectPriceRange: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ReportRangeQueryParams>) =>
      hasPermission
        ? api.get(
            `/reports/project-positioning/project-price-range`,
            queryParams,
            config
          )
        : fetchProjectPriceRangeMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchPricingMatrix: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<PricingMatrixParams>) =>
      hasPermission
        ? api.get(
            `/reports/project-positioning/pricing-matrix`,
            queryParams,
            config
          )
        : fetchPricingMatrixMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const ProjectPositioningGenerator = new Generator('projectPositioningModel');
export default ProjectPositioningGenerator.createModel({ actionCreators });
