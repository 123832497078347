import Generator from 'data/models/generator';
import { identity } from 'lodash';
import { ApiArgs } from 'types/common';
import {
  MarketShareQueryParams,
  ProductPriceMovementParams,
  ReportQueryParams,
  ReportRangeQueryParams
} from 'types/params';
import { api } from 'utils/api-client';
import {
  fetchLotTypesMock,
  fetchMarketShareMock,
  fetchProductPriceMovementMock,
  fetchProductTypePerformanceMock
} from '../mocks';

const actionCreators = {
  fetchLotTypes: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ReportQueryParams>) =>
      hasPermission
        ? api.get(
            '/reports/product-trends/lot-type-by-popularity',
            queryParams,
            config
          )
        : fetchLotTypesMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchProductPriceMovement: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ProductPriceMovementParams>) =>
      hasPermission
        ? api.get(
            '/reports/product-trends/product-price-movement',
            queryParams,
            config
          )
        : fetchProductPriceMovementMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchProductTypePerformance: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ReportRangeQueryParams>) =>
      hasPermission
        ? api.get(
            '/reports/product-trends/product-type-performance',
            queryParams,
            config
          )
        : fetchProductTypePerformanceMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchMarketShare: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<MarketShareQueryParams>) =>
      hasPermission
        ? api.get(
            '/reports/product-trends/market-share-by-frontage-and-depth',
            queryParams,
            config
          )
        : fetchMarketShareMock(),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const ProductTrendsGenerator = new Generator('productTrendsModel');
export default ProductTrendsGenerator.createModel({ actionCreators });
