import React, { Fragment, PureComponent } from 'react';
import _ from 'lodash';
import statsModel from 'data/models/entities/stats';
import { withQuery, withModel } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import withError from 'view/containers/with-error';
import ReportTitle from 'view/components/report-title';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import Report from 'src/view/components/reports/lot-availability';
import {
  getSettingsQuery,
  periodsQuery,
  projectsQuery
} from 'data/queries/settings';
import withValidate from 'view/containers/with-validate-setting';
import { getSettingsId, getToken } from 'utils/reports';

@withReportSidebar()
@withQuery(projectsQuery)
@withQuery(periodsQuery)
@withQuery(getSettingsQuery(getSettingsId, getToken))
@withModel(statsModel)
@withError.withPropName('errorModal')
@withValidate
class LotAvailability extends PureComponent {
  state = {
    fetchingStock: true,
    fetchingSoldCharacteristics: true,
    fetchingAvailableCharacteristics: true,
    fetchingAvailableSupply: true,
    fetchingPrimary: true,

    stock: {},
    soldCharacteristics: {},
    availableCharacteristics: {},
    availableSupply: {},
    primary: {}
  };

  handleError = (e) => {
    const { errorModal } = this.props;
    errorModal.open(e.message, null, () => {});
  };

  componentDidMount() {
    const { stats, whereabouts, settingProjects } = this.props;
    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (!_.get(query, 'period') && !_.get(query, 'token')) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    const args = {
      id: getSettingsId(this.props),
      args: {
        token: _.get(whereabouts, 'query.token'),
        period: _.get(whereabouts, 'query.period')
      }
    };

    stats
      .fetchAvailabilityStockLevelsSettings(args)
      .then((res) => {
        this.setState({
          stock: res.data,
          fetchingStock: false
        });
      })
      .catch(this.handleError);

    stats
      .fetchAvailabilitySoldCharacteristicsSettings(args)
      .then((res) => {
        this.setState({
          soldCharacteristics: res.data,
          fetchingSoldCharacteristics: false
        });
      })
      .catch(this.handleError);

    stats
      .fetchAvailabilityAvailableCharacteristicsSettings(args)
      .then((res) => {
        this.setState({
          availableCharacteristics: res.data,
          fetchingAvailableCharacteristics: false
        });
      });

    stats
      .fetchAvailabilitySupplySettings(args)
      .then((res) => {
        this.setState({
          availableSupply: res.data,
          fetchingAvailableSupply: false
        });
      })
      .catch(this.handleError);

    settingProjects
      .fetchPrimary({
        settingId: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token')
        }
      })
      .then((res) => {
        this.setState({
          fetchingPrimary: false,
          primary: res.data
        });
      })
      .catch(this.handleError);
  }

  loading = () => {
    const { settingProjects, periods } = this.props;
    const {
      fetchingStock,
      fetchingSoldCharacteristics,
      fetchingAvailableCharacteristics,
      fetchingAvailableSupply
    } = this.state;

    const arr = [
      fetchingStock,
      fetchingSoldCharacteristics,
      fetchingAvailableCharacteristics,
      fetchingAvailableSupply,
      settingProjects.list.status !== 'loaded',
      periods.list.status !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  };

  render() {
    const { settingProjects, periods, settings } = this.props;
    const {
      stock,
      soldCharacteristics,
      availableCharacteristics,
      availableSupply,
      primary
    } = this.state;

    const loading = this.loading();
    const maxRequests = 6;
    if (loading !== 0) {
      return (
        <Fragment>
          <LoadingLayout current={maxRequests - loading} max={maxRequests} />
        </Fragment>
      );
    }

    const period = _.get(periods, 'list.items.0');

    const projectList = _.sortBy(_.get(settingProjects, 'list.items'), (item) =>
      item.id === parseInt(_.get(primary, 'id')) ? 0 : 1
    );
    const primaryProject = _(projectList)
      .filter((project) => project.id === parseInt(_.get(primary, 'id')))
      .head();

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle
            period={period}
            project={primaryProject}
            settings={_.get(settings, 'item.data')}
          />
        </Portal>

        <Report
          period={period}
          primaryProject={primaryProject}
          projects={projectList}
          stock={stock}
          soldCharacteristics={soldCharacteristics}
          availableCharacteristics={availableCharacteristics}
          availableSupply={availableSupply}
        />

        <ReportFooter
          prevText="Size-price Brackets"
          prevRoute={ROUTES.APP.REPORT_SETTINGS_BRACKETS}
          nextText="Estate Detailed Stats"
          nextRoute={ROUTES.APP.REPORT_SETTINGS_DETAILED}
          settingId={getSettingsId(this.props)}
        />
      </Fragment>
    );
  }
}

export default LotAvailability;
