/*
|-------------------------------------------------------------------------------
| App "Theme" for Internal Components
|-------------------------------------------------------------------------------
|
| These theming configurations are suppose to be consumed by `element-style`s
| "StylesProvider". See https://git.io/vNyT4 for more details.
|
| They are kept in a separate module, so we can do fine-grained import-order for
| the App as well as for Storybook Stories.
|
*/

import { StyleSheet } from '@rexlabs/styling';
import * as t from 'src/theme';

const textStyles = {
  container: {
    borderRadius: t.BORDER_RADIUS.INPUT,
    border: `.1rem solid ${t.COLORS.GREY.LIGHT}`,
    width: '100%'
  },

  containerFocused: {
    borderColor: t.COLORS.BLUE_GREEN,
    boxShadow: 'none',

    '& input, & input:focus': {
      outline: 'none'
    }
  }
};

const COMPONENTS = {
  // Tether.wrapContent
  Tether: StyleSheet({
    wrapContent: {
      zIndex: 200
    }
  }),

  DayPicker: StyleSheet({
    display: 'none'
  }),

  PasswordToggle: StyleSheet({
    toggle: {
      width: 'auto',
      height: 'auto'
    }
  }),

  TextInput: StyleSheet({
    ...textStyles,
    ...{
      container: {
        height: '4.2rem',
        padding: `0 ${t.PADDINGS.XXS}`,
        borderColor: t.COLORS.GREY.LIGHT
      },
      prefix: {
        display: 'flex',
        marginRight: '1rem'
      },
      suffix: {
        display: 'flex'
      },
      cosmeticWrapper: {
        display: 'flex'
      },
      wrapper: {
        marginTop: '1rem'
      },
      input: {
        '::placeholder': {
          color: t.COLORS.GREY
        }
      }
    }
  }),

  TextArea: StyleSheet({
    ...textStyles,
    ...{
      container: {
        padding: `${t.PADDINGS.XXS}`,
        borderColor: t.COLORS.GREY.LIGHT
      },
      inputTextArea: {
        height: '100%'
      }
    }
  }),

  InnerField: StyleSheet({
    field: {
      marginTop: t.PADDINGS.XS
    },
    inlineFieldLabel: {
      color: t.COLORS.GREY.DARK,
      fontWeight: `${t.FONT.WEIGHTS.NORMAL} !important`
    },
    error: {
      position: 'relative'
    }
  }),

  DropdownButton: StyleSheet({
    icon: {
      height: '1.25rem',
      width: '1.25rem'
    }
  }),

  SelectMenu: StyleSheet({
    container: {
      transform: 'translateY(1rem)',
      borderRadius: '0.5rem',
      border: 'none',
      overflowY: 'auto',
      boxShadow: t.SHADOWS.CONTAINER,
      position: 'absolute',
      width: '100%'
    }
  }),

  SelectOption: StyleSheet({
    container: {
      border: 'none',
      padding: `${t.PADDINGS.XXS} ${t.PADDINGS.XS}`,
      fontWeight: t.FONT.WEIGHTS.SEMIBOLD,
      ':first-child': {
        padding: `${t.PADDINGS.XS} ${t.PADDINGS.XS} ${t.PADDINGS.XXS} ${t.PADDINGS.XS}`
      },
      ':last-child': {
        padding: `${t.PADDINGS.XXS} ${t.PADDINGS.XS} ${t.PADDINGS.XS} ${t.PADDINGS.XS}`
      },
      ':only-child': {
        padding: t.PADDINGS.XS
      }
    },
    isActive: {
      color: t.COLORS.BLUE_GREEN,
      background: t.COLORS.WHITE
    },
    isSelected: {
      color: t.COLORS.BLUE_GREEN,
      background: t.COLORS.WHITE
    }
  }),

  Button: StyleSheet({
    containerDisabled: {
      '& *': {
        cursor: 'not-allowed !important'
      }
    }
  }),

  Tab: StyleSheet({
    container: {
      borderBottom: '2px solid ' + t.COLORS.GREY.LIGHER,
      textTransform: 'none',
      fontSize: '2.4rem',
      color: t.COLORS.GREY.MEDIUM_DARK
    },
    containerActive: {
      color: t.COLORS.BLUE_GREEN
    }
  }),

  TabBar: StyleSheet({
    indicator: {
      marginTop: '0.1rem',
      background: t.COLORS.BLUE_GREEN
    }
  }),

  SectionColumn: StyleSheet({
    container: {
      backgroundColor: t.COLORS.WHITE,
      boxShadow: t.SHADOWS.CONTAINER,
      margin: t.PADDINGS.XXS,
      borderRadius: t.BORDER_RADIUS.CONTAINER,
      flexWrap: 'nowrap'
    }
  }),

  Tooltip: StyleSheet({
    arrow: {
      // border: '0.3rem',
      // margin: '-0.3rem'
    },

    tooltip: {
      minWidth: '5rem',
      borderRadius: '.8rem',
      maxHeight: '50vh',
      overflowY: 'auto'
    },

    clickable: {
      cursor: 'default'
    }
  }),

  ActionMenu: StyleSheet({
    menu: {
      minWidth: '5rem'
    },

    menuItem: {
      '> a': {
        fontWeight: t.FONT.WEIGHTS.SEMIBOLD,
        color: t.COLORS.GREY.DARK,
        padding: `${t.PADDINGS.TINY} ${t.PADDINGS.TINY}`
      },

      '> a:hover': {
        textDecoration: 'none',
        color: t.COLORS.BLUE_GREEN
      }
    }
  }),

  List: StyleSheet({
    wrapItem: {
      cursor: 'pointer',
      ':hover': {
        backgroundColor: t.COLORS.BLUE.LIGHT
      },
      ':first-child': {
        borderTop: `1px solid ${t.COLORS.GREY.LIGHT}`
      },
      ':last-child': {
        borderBottom: `1px solid ${t.COLORS.GREY.LIGHT}`
      }
    },
    separator: {
      backgroundColor: t.COLORS.GREY.LIGHT
    },
    wrapEmpty: {
      padding: t.PADDINGS.M
    }
  }),

  Checkbox: StyleSheet({
    container: {
      flexDirection: 'column'
    },
    input: {
      '& + label': {
        cursor: 'default',
        outline: 'none',
        height: '1.6rem',
        width: '1.6rem',
        border: `2px solid ${t.COLORS.GREY.MEDIUM}`
      },
      '&:checked + label': {
        backgroundColor: `${t.COLORS.BLUE_GREEN}`,
        borderColor: `${t.COLORS.BLUE_GREEN}`,
        '&:before': {
          borderBottom: '0.16rem solid white',
          borderRight: '0.16rem solid white',
          top: '40%',
          left: '0.6rem',
          height: '1.2rem',
          width: '0.5rem'
        }
      }
    }
  })
};

export default COMPONENTS;
