import React, { useContext } from 'react';

import { PagePermission } from 'src/features/reports/hoc';
import { ProjectStats } from '../components/project-stats';
import { ReportSettingsContext } from '../providers/report-settings-provider';
import { BREAKDOWN_PAGE } from '../constants';

function ReportProjectBreakdown() {
  const { subRegionsPermissions } = useContext(ReportSettingsContext);

  return (
    <PagePermission
      permissionsNeeded={[...subRegionsPermissions, BREAKDOWN_PAGE]}
    >
      <ProjectStats />
    </PagePermission>
  );
}

export default ReportProjectBreakdown;
