import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { push, RouteDefinition } from '@rexlabs/whereabouts';
import ROUTES from 'src/features/reports/routes';
import { Whereabouts } from 'types/common/whereabouts';

export interface SettingsHookProps<Query> {
  whereabouts: Pick<Whereabouts<Query>, 'query' | 'path'>;
}

function useRouteConfig<Query>({ whereabouts }: SettingsHookProps<Query>) {
  const routeConfig: RouteDefinition = useMemo(() => {
    const whatKey = Object.keys(ROUTES.APP).find((key) => {
      return ROUTES.APP[key].config.path === whereabouts.path;
    });

    return ROUTES.APP[whatKey];
  }, [whereabouts]);

  const updateParams = useCallback(
    (newParams: Record<string, unknown>) => {
      push(routeConfig, {
        query: { ...whereabouts.query, ...newParams }
      });
    },
    [whereabouts.query, routeConfig]
  );

  return {
    updateParams
  };
}

export default useRouteConfig;
