import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import RangeSlider, { RangeSliderProps } from './range-slider';

import RangeSliderWrapper from './range-slider-wrapper';
import BoxedText from '../text/boxed-text';

import { formatToMonthAndYear } from 'utils/format';
import 'rc-slider/assets/index.css';

type MonthRangeSliderType = Omit<RangeSliderProps, 'sliderData' | 'type'> & {
  numOfMonthsBack: number;
  latestMonth: string;
};

export interface MonthYearType {
  year: number | string;
  month: number | string;
}

const getRangeOfMonthYear = (numOfMonths: number, latestMonth: string) => {
  const monthRange: MonthYearType[] = [];
  for (let i = 0; i < numOfMonths; i++) {
    const date = moment(latestMonth).subtract(i, 'months');
    monthRange.push({ year: date.year(), month: date.month() + 1 });
  }
  return monthRange;
};

function MonthRangeSlider({
  value,
  onChange,
  numOfMonthsBack,
  latestMonth,
  disabled
}: MonthRangeSliderType) {
  const rangeOfMonths = useMemo(
    () => getRangeOfMonthYear(numOfMonthsBack + 1, latestMonth),
    [numOfMonthsBack, latestMonth]
  );

  const onMonthValueChange = useCallback(
    (monthData) => {
      onChange(monthData);
    },
    [onChange]
  );

  const maxRangeValue = useMemo(
    () => formatToMonthAndYear(rangeOfMonths[0]),
    [rangeOfMonths]
  );

  return (
    <RangeSliderWrapper>
      <BoxedText text={formatToMonthAndYear(value)} />
      <RangeSlider
        sliderData={rangeOfMonths}
        value={value}
        onChange={onMonthValueChange}
        disabled={disabled}
      />
      <BoxedText text={maxRangeValue} />
    </RangeSliderWrapper>
  );
}

export default MonthRangeSlider;
