import React, { Fragment, PureComponent } from 'react';
import { api } from 'utils/api-client';
import _ from 'lodash';
import { PrimaryButton, TextButton } from './button';
import { ButtonBar, Modal } from './modal';
import Box from '@rexlabs/box';
import { Heading, Tiny } from './text';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS } from 'src/theme';
import PropTypes from 'prop-types';
import exportsModel from 'data/models/entities/export';
import { withModel } from '@rexlabs/model-generator';
import withError from 'view/containers/with-error';
import Checkbox from './checkbox/check';

function getSettingId(props) {
  return _.get(props, 'match.params.id');
}

const defaultStyles = StyleSheet({
  btn: {
    // width: '100% !important',
    // marginTop: '1rem'
  },

  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    marginBottom: '0.5rem'
  }
});

@withError.withPropName('errorModal')
@styled(defaultStyles)
@withModel(exportsModel)
class ExportButton extends PureComponent {
  state = {
    exportModal: false,
    downloadUrl: null,
    isExporting: false,
    exportId: null,
    reports: {
      overview: true,
      pricing: true,
      sizeprice: true,
      availability: true,
      detailed: true,
      pricelist: true
    }
  };

  export = () => {
    const { match, isSetting, settingId, period } = this.props;
    this.setState({
      isExporting: true
    });

    const promise = isSetting
      ? api.post(
          `/stats/settings/${settingId || getSettingId(this.props)}/export`,
          {
            period: period || _.get(match, 'query.period'),
            reports: this.state.reports
          }
        )
      : api.post('/stats/export', {
          projects: _.get(match, 'query.projects'),
          period: _.get(match, 'query.period'),
          primary: _.get(match, 'query.primaryProject'),
          reports: this.state.reports
        });

    promise
      .then((res) => {
        const exportId = _.get(res, 'data.id');

        this.setState({
          exportId: exportId
        });

        setTimeout(this.poll, 2000);
      })
      .catch(this.handleError);
  };

  poll = () => {
    const { exports } = this.props;
    const { exportId } = this.state;

    this.setState({
      isExporting: true
    });

    exports
      .fetchItem({
        id: exportId
      })
      .then((res) => {
        const status = _.get(res, 'data.status');
        const result = _.get(res, 'data.result');

        if (status === 'finished') {
          this.setState({
            downloadUrl: result,
            isExporting: false
          });
        } else if (status === 'error') {
          this.handleError();
        } else {
          setTimeout(this.poll, 2000);
        }
      })
      .catch(this.handleError);
  };

  handleError = () => {
    const { errorModal } = this.props;
    errorModal.open('An error occurred while exporting. Please try again.');
    this.setState({
      downloadUrl: null,
      isExporting: false,
      exportId: null
    });
  };

  toggleReport = (name) => {
    this.setState({
      reports: {
        ...this.state.reports,
        [name]: !this.state.reports[name]
      }
    });
  };

  render() {
    const { exportModal, downloadUrl, isExporting } = this.state;
    const {
      styles: s,
      errorModal: { Error },
      ...rest
    } = this.props;

    return (
      <Fragment>
        <PrimaryButton
          {...s('btn')}
          onClick={() => this.setState({ exportModal: true })}
          {...rest}
        >
          Export Report
        </PrimaryButton>

        {exportModal && (
          <Modal width={400}>
            <Box pt={PADDINGS.S} pl={PADDINGS.S} pr={PADDINGS.S}>
              <Heading>Export Report</Heading>
              <Box flex={1} justifyContent={'center'} pt={PADDINGS.S}>
                {!isExporting && !downloadUrl && (
                  <Box flexDirection="column">
                    <Box
                      flex={1}
                      {...s('checkbox')}
                      onClick={() => this.toggleReport('overview')}
                    >
                      <Checkbox value={this.state.reports.overview} />
                      <Tiny>Overview</Tiny>
                    </Box>

                    <Box
                      flex={1}
                      {...s('checkbox')}
                      onClick={() => this.toggleReport('pricing')}
                    >
                      <Checkbox value={this.state.reports.pricing} />
                      <Tiny>Pricing Comparison</Tiny>
                    </Box>

                    <Box
                      flex={1}
                      {...s('checkbox')}
                      onClick={() => this.toggleReport('sizeprice')}
                    >
                      <Checkbox value={this.state.reports.sizeprice} />
                      <Tiny>Size-price Brackets</Tiny>
                    </Box>

                    <Box
                      flex={1}
                      {...s('checkbox')}
                      onClick={() => this.toggleReport('availability')}
                    >
                      <Checkbox value={this.state.reports.availability} />
                      <Tiny>Lot Availability</Tiny>
                    </Box>

                    <Box
                      flex={1}
                      {...s('checkbox')}
                      onClick={() => this.toggleReport('detailed')}
                    >
                      <Checkbox value={this.state.reports.detailed} />
                      <Tiny>Detailed</Tiny>
                    </Box>

                    <Box
                      flex={1}
                      {...s('checkbox')}
                      onClick={() => this.toggleReport('pricelist')}
                    >
                      <Checkbox value={this.state.reports.pricelist} />
                      <Tiny>Pricelist</Tiny>
                    </Box>

                    <PrimaryButton onClick={this.export} green>
                      Export
                    </PrimaryButton>
                  </Box>
                )}
                {isExporting && <LoadingSpinner />}
                {!isExporting && downloadUrl && (
                  <Box>
                    <a
                      href={downloadUrl}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      download
                    >
                      <PrimaryButton green>Download</PrimaryButton>
                    </a>
                    <PrimaryButton onClick={this.export}>
                      Regenerate Export
                    </PrimaryButton>
                  </Box>
                )}
              </Box>
            </Box>
            <ButtonBar>
              <TextButton
                type={'button'}
                onClick={() => this.setState({ exportModal: false })}
              >
                Close
              </TextButton>
            </ButtonBar>
          </Modal>
        )}
        <Error />
      </Fragment>
    );
  }
}

ExportButton.PropTypes = {
  match: PropTypes.object.isRequired,
  isSetting: PropTypes.bool.isRequired
};

export default ExportButton;
