import React, { useState } from 'react';
import Box from '@rexlabs/box';
import { keyframes, styled, Styles, StyleSheet } from '@rexlabs/styling';
import { Portal } from '@rexlabs/portal';
import { COLORS, FONT } from 'src/features/reports/theme';
import { DefaultButton, PrimaryButton } from '../button';
import { compose } from 'utils/compose';

const fadeIn = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opactity: 1
  }
});

const styles = StyleSheet({
  title: {
    fontSize: 28,
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontWeight: 700,
    lineHeight: '35px',
    color: COLORS.BLACK,
    marginBottom: 10
  },
  question: {
    fontSize: 14,
    fontFamily: FONT.FAMILIES.DEFAULT,
    color: COLORS.BLACK
  },
  delete: {
    width: 98,
    marginLeft: 16
  },
  cancel: {
    width: 101
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 320,
    animation: `${fadeIn} 0.3s ease-in-out`
  },
  container: {
    backgroundColor: COLORS.WHITE,
    borderRadius: '4px'
  }
});

interface DeleteSavedReportProps {
  styles: Styles;
  onHide: () => void;
  onDelete: () => Promise<void>;
}

const DeleteSavedReport = ({
  styles: s,
  onHide,
  onDelete
}: DeleteSavedReportProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Portal target="custom-dialogs">
      <div {...s('backdrop')}>
        <Box p="24px" width="572px" {...s('container')}>
          <h3 {...s('title')}>Delete Saved Report</h3>
          <p {...s('question')}>
            Are you sure you want to delete this saved report? This action
            cannot be undone.
          </p>
          <Box flexDirection="row" justifyContent="flex-end" mt="24px">
            <DefaultButton {...s('cance')} onClick={onHide}>
              Cancel
            </DefaultButton>
            <PrimaryButton
              color={COLORS.RED.DELETE}
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                await onDelete();
                setIsLoading(false);
              }}
              {...s('delete')}
            >
              Delete
            </PrimaryButton>
          </Box>
        </Box>
      </div>
    </Portal>
  );
};

export default compose<Omit<DeleteSavedReportProps, 'styles'>>(styled(styles))(
  DeleteSavedReport
);
