import React from 'react';
import { useSpring, animated } from 'react-spring';
import { COLORS, BORDER_RADIUS } from 'src/theme';
import { Body } from 'view/components/text';
import Box from '@rexlabs/box';

function ProgressBar({ current, max }) {
  const normaliseValue = (value) => {
    if (!value) {
      return 1;
    }
    if (value > 100) {
      return 100;
    }

    if (value < 1) {
      return 1;
    }

    return value;
  };

  const calculation = parseInt((current / max) * 100);
  const percent = normaliseValue(calculation).toString() + '%';
  const springProps = useSpring({
    from: {
      width: '0%',
      height: '100%',
      background: COLORS.BLUE_GREEN,
      borderRadius: BORDER_RADIUS.STATUS_BOX
    },
    to: { width: percent }
  });

  return (
    <Box flex={1} flexDirection={'column'} style={{ width: '100%' }}>
      <Box style={{ height: 25 }}>
        <animated.div style={springProps} />
      </Box>
      <Body style={{ textAlign: 'center' }}>
        {calculation > 100 ? '100' : calculation}%
      </Body>
    </Box>
  );
}

export default ProgressBar;
