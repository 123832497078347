import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      Project Price Range displays the cheapest & most expensive lot price for
      each project.
      <br />
      <br />
      Although this is nice to know from a price leader perspective, more
      valuable data is obtained by hovering over and focusing on the darker
      shade of blue – this shows where the primary spread of lot pricing sits,
      allowing a rapid analysis of multiple projects.
      <br />
      <br />
      To view a longer period of time, the{' '}
      <span className="bold">date slider can be dragged</span> to the left.
    </>
  );
};

export default InfoTooltip;
