import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      Sales Performance is displayed by showing each projects % market share.
      You can toggle between Gross and Net.
      <br />
      <br />
      To view a longer period of time, the{' '}
      <span className="bold">date slider can be dragged</span> to the left.
    </>
  );
};

export default InfoTooltip;
