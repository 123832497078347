import React, { PureComponent } from 'react';
import { Heading, Body } from 'view/components/text';
import Box from '@rexlabs/box';
import { PrimaryButton } from 'view/components/button';
import { Link } from '@rexlabs/react-whereabouts';
import ROUTES from 'src/routes';
import _ from 'lodash';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    marginTop: '20vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '> *': {
      margin: '1rem'
    }
  }
});

@styled(defaultStyles)
class ErrorLayout extends PureComponent {
  render() {
    const { styles: s, error } = this.props;
    return (
      <Box {...s('container')}>
        <Heading>An Error Occurred</Heading>
        <Body>{JSON.stringify(_.get(error, 'message'), null, 2)}</Body>
        <Box flex={1} flexDirection={'row'}>
          <Box p={4}>
            <Link to={ROUTES.APP.HOME}>
              <PrimaryButton green>Return Home</PrimaryButton>
            </Link>
          </Box>
          <Box p={4}>
            <PrimaryButton green onClick={() => location.reload()}>
              Try Again
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default ErrorLayout;
