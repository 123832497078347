import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import projectsModel from 'data/models/entities/projects';
import { withModel } from '@rexlabs/model-generator';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500',
    color: COLORS.GREY.DARK
  },
  container: {
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
  },
  grid: {
    '> *': {
      padding: '1rem 2rem'
    }
  }
});

@withModel(projectsModel)
@styled(defaultStyles)
class MissingWorksheetListHeader extends PureComponent {
  render() {
    const { styles: s, checked, onSelectAll } = this.props;
    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={24}
          style={{ alignItems: 'center' }}
          {...s('grid')}
        >
          <Column width={2} onClick={onSelectAll}>
            <Checkbox value={checked} />
          </Column>
          <Column width={6}>
            <Small {...s('text')}>Project Name</Small>
          </Column>
          <Column width={6}>
            <Small {...s('text')}>Date Created</Small>
          </Column>
          <Column width={10}>
            <Small {...s('text')}>Other Worksheets</Small>
          </Column>
        </Grid>
      </Box>
    );
  }
}

export default MissingWorksheetListHeader;
