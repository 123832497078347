import React, { MouseEvent, useState } from 'react';
import _ from 'lodash';

import Box from '@rexlabs/box';
import { styled, Styles, StyleSheet } from '@rexlabs/styling';
import { push } from '@rexlabs/whereabouts';
import { Grid, Column } from '@rexlabs/grid';

import { Check as Checkbox } from 'view/components/checkbox';
import { Small } from 'view/components/text';
import { HorizontalActionMenu } from 'view/components/action-menu';
import RegionModal from 'view/modals/sub-region';
import withAction from 'view/containers/with-action';

import { SubRegion } from 'types/resource';
import { SubRegionModel } from 'types/models/sub-region';
import { WithActionModalProps } from 'types/hoc/with-action-modal';

import ROUTES from 'src/routes';
import { formatDate } from 'utils/format';
import { FONT } from 'src/theme';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: 500
  },
  status: {
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  }
});

interface RegionListItemProps {
  regions: SubRegionModel;
  region: SubRegion;
  actionModal: WithActionModalProps;
  checked: boolean;
  styles: Styles;
  toggle: () => void;
}

const RegionListItem = ({
  regions,
  region,
  actionModal,
  checked,
  styles: s,
  toggle
}: RegionListItemProps) => {
  const [edit, setEdit] = useState(false);
  const { Action } = actionModal;

  const view = () => {
    push(ROUTES.APP.REGION_PROJECTS, {
      params: {
        regionId: region.id
      }
    });
  };

  const toggleHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggle();
  };

  const deleteItem = () => {
    regions.trashItem({
      id: _.get(region, 'id')
    });
  };

  const openDeleteModal = () => {
    actionModal.open(
      deleteItem,
      'Are you sure you want to delete this Sub-Region?',
      'Delete Sub-Region',
      true
    );
  };

  return (
    <Box {...s('container')} onClick={view}>
      <Grid
        gutter={0}
        columns={24}
        style={{ alignItems: 'center' }}
        {...s('grid')}
      >
        <Column {...s('checkbox')} width={1} onClick={toggleHandler}>
          <Checkbox controlled={true} value={!!checked} />
        </Column>
        <Column width={6}>
          <Small {...s('text')}>{_.get(region, 'name')}</Small>
        </Column>
        <Column width={6}>
          <Small {...s('text')}>{_.get(region, 'project_count')}</Small>
        </Column>
        <Column width={10}>
          <Small {...s('text')}>
            {formatDate(_.get(region, 'published_to'))}
          </Small>
        </Column>
        <Column
          {...s('checkbox')}
          width={1}
          onClick={(e) => e.stopPropagation()}
        >
          <HorizontalActionMenu
            items={[
              {
                label: 'View',
                onClick: view
              },
              {
                label: 'Edit',
                onClick: () => setEdit(true)
              },
              {
                label: 'Delete',
                onClick: openDeleteModal
              }
            ]}
          />
        </Column>
      </Grid>
      <Action />
      {edit && (
        <RegionModal
          region={region}
          closeModal={() => {
            setEdit(false);
          }}
          onSubmit={() => {
            regions.refreshList({ id: 'edit-refresh' });
          }}
        />
      )}
    </Box>
  );
};

export default _.flow(
  withAction.withPropName('actionModal'),
  styled(defaultStyles)
)(RegionListItem);
