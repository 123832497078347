import React, { useState } from 'react';
import ErrorListModal, {
  ErrorListModalProps
} from 'view/components/modal/error-list';

const withErrorList = <T,>(Component: React.ComponentType<T>) => {
  return (props: T) => {
    const defaultConfig: ErrorListModalProps = {
      items: [],
      title: '',
      isAction: false
    };

    const [isOpen, setOpen] = useState<boolean>(false);
    const [config, setConfig] = useState<ErrorListModalProps>(defaultConfig);

    const open = (newConfig: ErrorListModalProps): void => {
      if (isOpen) return;
      setConfig(newConfig);
      setOpen(true);
    };

    const close = (): void => {
      if (!isOpen) return;
      setOpen(false);
    };

    const getProps = (): object => {
      return {
        open: open,
        close: close
      };
    };

    return (
      <>
        <Component
          {...props}
          {...{
            errorList: getProps()
          }}
        />
        {isOpen && (
          <ErrorListModal
            items={config.items}
            title={config.title}
            isAction={config.isAction}
            onCancel={close}
          />
        )}
      </>
    );
  };
};

export default withErrorList;
