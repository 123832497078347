import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { Body } from '../text';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'src/theme';
import { Link } from '@rexlabs/react-whereabouts';

const defaultStyle = StyleSheet({
  container: {
    margin: '1.5rem 0'
  },

  crumb: {
    color: COLORS.GREY,
    textTransform: 'capitalize',
    fontSize: '1.8rem !important',
    fontWeight: '500'
  },

  active: {
    color: COLORS.BLUE_GREEN,
    textTransform: 'capitalize',
    fontSize: '1.8rem !important',
    fontWeight: '500'
  }
});

@styled(defaultStyle)
class Breadcrumb extends PureComponent {
  render() {
    const { styles: s } = this.props;

    const crumbs = this.props.crumbs
      .map((t, i, o) => {
        if (i === o.length - 1) {
          return (
            <Body is="span" {...s('active')} key={i}>
              {t.label}
            </Body>
          );
        }
        return (
          <Link to={t.route} {...s('crumb')} key={i}>
            <Body is="span" {...s('crumb')}>
              {t.label}
            </Body>
          </Link>
        );
      })
      .reduce((prev, curr, i) => [
        prev,
        <Body is="span" {...s('crumb')} key={`crumb-sep-${i}`}>
          &nbsp;&rsaquo;&nbsp;
        </Body>,
        curr
      ]);

    return (
      <Box s {...s('container')}>
        {crumbs}
      </Box>
    );
  }
}

export default Breadcrumb;
