import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import { HorizontalActionMenu } from 'view/components/action-menu';
import projectsModel from 'data/models/entities/projects';
import { withModel } from '@rexlabs/model-generator';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import { formatPeriod, formatDate } from '../../../utils/format';
import _ from 'lodash';
import { FONT } from 'src/theme';

import ActionModal from '../../modals/action';
import EditPeriodModal from '../../modals/period-edit';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500'
  },
  status: {
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  }
});

@withModel(projectsModel)
@styled(defaultStyles)
class PeriodListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      archiveModal: false,
      editModal: false
    };
  }

  archive = () => {
    this.props.projects
      .archive({
        id: this.props.project.id
      })
      .then(() => {
        this.setState({
          archiveModal: false
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  view = (period) => {
    push(ROUTES.APP.PERIOD_VIEW, {
      params: {
        periodId: period.id
      }
    });
  };

  render() {
    const { styles: s, period, checked, toggle } = this.props;
    const project = _.get(period, 'projects.data');
    return (
      <Box {...s('container')}>
        {this.state.archiveModal && (
          <ActionModal
            onClose={() => this.setState({ archiveModal: false })}
            onSubmit={this.archive}
            title={'Archive Project Listing(s)?'}
            submitText="Archive"
          >
            Are you sure you want to archive this Project Listing(s) and all
            associated worksheets?
          </ActionModal>
        )}
        <Grid
          gutter={0}
          columns={24}
          style={{ alignItems: 'center' }}
          {...s('grid')}
          onClick={() => this.view(period)}
        >
          <Column
            width={1}
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
            {...s('checkbox')}
          >
            <Checkbox controlled={true} value={!!checked} />
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{formatPeriod(period.end_date)}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>{_.get(project, 'length')}</Small>
          </Column>
          <Column width={4}>
            <Small {...s('status')}>{formatDate(period.updated_at)}</Small>
          </Column>
          <Column width={10}>
            <Small {...s('text')}>{period.note}</Small>
          </Column>
          <Column width={1} onClick={(e) => e.stopPropagation()}>
            <HorizontalActionMenu
              items={[
                {
                  label: 'View',
                  onClick: () => this.view(period)
                },
                {
                  label: 'Edit',
                  onClick: () => this.setState({ editModal: true })
                }
              ]}
            />
          </Column>
        </Grid>

        {this.state.editModal && (
          <EditPeriodModal
            period={period}
            closeModal={() => this.setState({ editModal: false })}
          />
        )}
      </Box>
    );
  }
}

export default PeriodListItem;
