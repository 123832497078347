import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { Tiny } from 'view/components/text';
import { COLORS } from 'src/features/reports/theme';

const defaultStyles = StyleSheet({
  featBoxLabel: {
    cursor: 'pointer',
    fontFamily: 'DM Sans',
    fontSize: '12px !important',
    color: COLORS.GREY.MEDIUM
  },
  featBoxSubLabel: {
    fontFamily: 'DM Sans',
    fontSize: '12px !important',
    color: COLORS.GREY.MEDIUM,
    display: 'flex',
    gap: 7,
    '& > p:last-child': {
      fontWeight: 700
    }
  }
});

export interface FeaturedBoxSubLabelProps {
  label: string;
  value: number | string;
  styles: Styles;
}

export const FeaturedBoxSubLabel = ({
  styles: s,
  label,
  value
}: FeaturedBoxSubLabelProps) => {
  return (
    <Box {...s('featBoxSubLabel')}>
      <Tiny {...s('featBoxLabel')}>{label}</Tiny>
      <Tiny {...s('featBoxLabel')}>{value}</Tiny>
    </Box>
  );
};

export default styled(defaultStyles)(FeaturedBoxSubLabel);
