import React, { PureComponent, Fragment } from 'react';
import { COLORS, PADDINGS } from 'src/theme';
import _ from 'lodash';
import { withModel, withQuery } from '@rexlabs/model-generator';
import statsModel from 'data/models/entities/stats';
import lotsModel from 'data/models/entities/lots';
import {
  getSettingsQuery,
  periodsQuery,
  projectsQuery
} from 'data/queries/settings';
import LoadingLayout from 'view/layouts/loading';
import withError from 'view/containers/with-error';
import withReportSidebar from 'view/containers/with-report-sidebar';
import { styled, StyleSheet } from '@rexlabs/styling';
import ROUTES from 'src/routes';
import Overview from 'view/components/reports/overview';
import ReportFooter from 'view/components/report-footer';
import { push } from '@rexlabs/whereabouts';
import withValidate from 'view/containers/with-validate-setting';
import { getSettingsId, getToken } from 'utils/reports';

const defaultStyles = StyleSheet({
  row: {
    padding: `${PADDINGS.XS} 0`
  },
  divider: {
    paddingTop: PADDINGS.S,
    marginBottom: PADDINGS.S,
    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`
  }
});

@withReportSidebar()
@withError.withPropName('errorModal')
@withQuery(periodsQuery)
@withQuery(projectsQuery)
@withQuery(getSettingsQuery(getSettingsId, getToken))
@withModel(lotsModel)
@withModel(statsModel)
@styled(defaultStyles)
@withValidate
class OverviewSettingsScreen extends PureComponent {
  state = {
    stats: {},
    breakdownStats: {},
    overviewStats: {},
    primaryProject: {},

    fetchingStats: true,
    fetchingBreakdownStats: true,
    fetchingOverviewStats: true,
    fetchingPrimary: true
  };

  handleError = (e) => {
    const { errorModal } = this.props;
    errorModal.open(e.message);
  };

  componentDidMount() {
    const { stats, settingProjects, whereabouts } = this.props;

    // Check that settings were supplied
    if (
      !_.get(whereabouts, 'query.period') &&
      !_.get(whereabouts, 'query.token')
    ) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    // Get stats
    stats
      .fetchOverviewSettingsOverview({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          fetchingOverviewStats: false,
          overviewStats: res.data
        });
      })
      .catch(this.handleError);

    stats
      .fetchOverviewSettingsEstateBreakdown({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          fetchingBreakdownStats: false,
          breakdownStats: res.data
        });
      })
      .catch(this.handleError);

    stats
      .fetchOverviewSettingsPriceSizeSnapshot({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          fetchingStats: false,
          stats: res.data
        });
      })
      .catch(this.handleError);

    settingProjects
      .fetchPrimary({
        settingId: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token')
        }
      })
      .then((res) => {
        this.setState({
          fetchingPrimary: false,
          primary: res.data
        });
      })
      .catch(this.handleError);
    // })
    // .catch(this.handleError);
  }

  isLoading() {
    const { settingProjects, settingsPrimary, periods } = this.props;
    const arr = [
      _.get(settingProjects, 'list.status') === 'loading',
      _.get(settingsPrimary, 'list.status') === 'loading',
      _.get(periods, 'list.status') === 'loading',
      this.state.fetchingOverviewStats,
      this.state.fetchingBreakdownStats,
      this.state.fetchingWorksheets,
      this.state.fetchingStats,
      this.state.fetchingPrimary
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc), 0) || 0;
  }

  render() {
    const { periods, settingProjects, settings } = this.props;

    const { primary, stats, breakdownStats, overviewStats } = this.state;

    const projectList = _.get(settingProjects, 'list.items');
    const period = _.head(_.get(periods, 'list.items'));
    const loading = this.isLoading();

    const currentRequests = loading ? 8 - loading : 0;
    if (loading !== 0) {
      return (
        <Fragment>
          <LoadingLayout current={currentRequests} max={8} />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Overview
          settings={_.get(settings, 'item.data')}
          stats={stats}
          breakdownStats={breakdownStats}
          overviewStats={overviewStats}
          projects={projectList}
          primaryProject={primary}
          period={period}
        />
        <ReportFooter
          nextText="Pricing Comparison"
          nextRoute={ROUTES.APP.REPORT_SETTINGS_PRICING}
          settingId={getSettingsId(this.props)}
        />
      </Fragment>
    );
  }
}

export default OverviewSettingsScreen;
