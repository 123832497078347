import React, { useContext, useEffect, useRef, useState } from 'react';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { Canceler } from 'axios';
import { CancelToken } from '@rexlabs/api-client';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';

import { COLORS, NIVO_THEME } from 'src/features/reports/theme';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { compose } from 'utils/compose';
import keyInsightsModel from 'src/features/reports/models/key-insights';
import { ProductSummary } from 'types/graph/product-overview';
import withError from 'view/containers/with-error';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { KeyInsightsModel } from 'types/models/key-insights';
import { Loading, CustomTooltip } from '../graph';
import { Auth0Context } from 'src/auth0-provider';
import { INSIGHTS_PAGE } from 'src/features/reports/constants';
import CustomLegend from './custom-layers/custom-legend';
import { round } from 'lodash';

interface SummaryProps {
  keyInsightsModel: KeyInsightsModel;
  errorModal: WithErrorModalProps;
}

const Summary = ({
  keyInsightsModel,
  errorModal: { open: openError }
}: SummaryProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const canceler = useRef<Canceler>(null);
  const { selectedProjectIds, period } = useContext(ReportSettingsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState<ProductSummary>({
    available_traditional_lots: 0,
    sold_traditional_lots: 0,
    available_small_lots: 0,
    sold_small_lots: 0,
    traditional_lots: 0,
    small_lots: 0
  });

  useEffect(() => {
    if (!period || selectedProjectIds.length === 0) return;

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    keyInsightsModel
      .fetchProductOverview({
        queryParams: {
          project_ids: selectedProjectIds,
          period,
          report: 'summary'
        },
        hasPermission: hasPermission([INSIGHTS_PAGE]),
        config: { cancelToken }
      })
      .then((res) => {
        if (res.data) {
          setReport(res.data as ProductSummary);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        openError(error);
        setIsLoading(false);
      });

    return () => {
      canceler.current?.();
    };
  }, [selectedProjectIds, period, keyInsightsModel, openError, hasPermission]);

  return (
    <Box width="100%" height="460px">
      <Loading isLoading={isLoading} />
      <ResponsiveSunburst
        theme={{
          ...NIVO_THEME,
          labels: {
            text: {
              fill: COLORS.BLUE.DARK,
              fontFamily: 'DM Sans',
              fontWeight: 700,
              fontSize: '10px'
            }
          }
        }}
        data={{
          id: 'Lots',
          children: [
            {
              id: 'Traditional Lots',
              children: [
                {
                  id: 'Available',
                  color: '#B9CFFF',
                  value: report.available_traditional_lots
                },
                {
                  id: 'Sold',
                  color: '#B9CFFF',
                  value: report.sold_traditional_lots
                }
              ]
            },
            {
              id: 'Small Lots',
              children: [
                {
                  id: 'Available',
                  color: '#FDE0CB',
                  value: report.available_small_lots
                },
                {
                  id: 'Sold',
                  color: '#FDE0CB',
                  value: report.sold_small_lots
                }
              ]
            }
          ]
        }}
        margin={{ top: 40, right: 10, bottom: 20, left: 10 }}
        cornerRadius={0}
        borderWidth={4}
        enableArcLabels={false}
        colors={['#83AAFF', '#FCC7A2']}
        childColor={(_parent, child) => {
          // @ts-expect-error same implementation with nivo
          return child.data.color;
        }}
        layers={['arcs', CustomLegend]}
        tooltip={({ percentage, id, color }) => (
          <CustomTooltip
            points={[
              {
                id: id as string,
                value: `${round(percentage, 1)}%`,
                label: id as string,
                color
              }
            ]}
          />
        )}
      />
    </Box>
  );
};

export default compose(
  withModel(keyInsightsModel),
  withError.withPropName('errorModal')
)(Summary);
