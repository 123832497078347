import React, { PureComponent } from 'react';

import { Portal } from '@rexlabs/portal';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { COLORS, BORDER_RADIUS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    background: COLORS.WHITE,
    borderRadius: BORDER_RADIUS.DIALOGS,
    width: '100%',
    overflow: 'auto',
    position: 'relative'
  }
});

@styled(defaultStyles)
class Modal extends PureComponent {
  static defaultProps = {
    width: '90rem',
    top: 20
  };

  render() {
    // by default, overflow style is set to 'auto' which causes some overflowing controls like
    // dropdowns or date pickers to be cut-off on the bottom of the modal. Here we add the `visibleOverflow` prop
    // to override it to be `visible`.
    const {
      width,
      top,
      style,
      styles: s,
      children,
      visibleOverflow
    } = this.props;

    return (
      <Portal target="dialogs">
        <Box
          {...s.with('container')({
            ...style,
            maxWidth: width,
            marginTop: top,
            maxHeight: '94vh',
            overflow: visibleOverflow ? 'visible' : 'auto'
          })}
        >
          {children}
        </Box>
      </Portal>
    );
  }
}

export default Modal;
