import React, { PureComponent } from 'react';
import ErrorModal from '../components/modal/error';
import PropTypes from 'prop-types';

class ActionModal extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <ErrorModal closeText="Cancel" {...this.props}>
        {children}
      </ErrorModal>
    );
  }
}

ActionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  green: PropTypes.bool
};

export default ActionModal;
