import Generator from '../generator';
import { api } from 'utils/api-client';
import _ from 'lodash';

const config = {
  entities: {
    api: {
      fetchList: (type, { settingId, token, ...args }) => {
        return api.get(
          `/stats/settings/${settingId}/projects${
            token ? '?token=' + token : ''
          }`,
          args
        );
      }
    }
  }
};

const actionCreators = {
  fetchPrimary: {
    request: ({ settingId, args }) => {
      const { token, ...rest } = args;
      return api.get(
        `/stats/settings/${settingId}/primary${token ? '?token=' + token : ''}`,
        rest
      );
    },
    reduce: {
      initial: _.identity,
      success: _.identity,
      failure: _.identity
    }
  }
};

const RegionProjectsGenerator = new Generator('settingProjects', config);
export default RegionProjectsGenerator.createEntityModel({ actionCreators });
