import {
  BoxPlotSummaryFormatted,
  BoxPlotTooltipProps,
  BoxPlotSummary
} from './types';
import React, { memo } from 'react';
import { useTheme, CompleteTheme } from '@nivo/core';
import { numberWithCommas } from 'utils/format';

interface BoxPlotSummaryTooltipProps {
  label: string;
  formatted: BoxPlotSummaryFormatted;
  color?: string;
  enableChip?: boolean;
}

interface Translation {
  [key: string]: number | string;
}

export const defaultTranslation = {
  n: 'n',
  median: 'median',
  min: 'min',
  max: 'max',
  Summary: 'Summary',
  Quantiles: 'Quantiles'
};

type ExtendedTheme = CompleteTheme & {
  translation: Translation;
};

const hasTranslation = (
  theme: CompleteTheme | ExtendedTheme
): theme is ExtendedTheme => {
  return 'translation' in theme;
};

export const BoxPlotSummaryTooltip = memo<BoxPlotSummaryTooltipProps>(
  ({ label, formatted }) => {
    const theme = useTheme();
    let translation = defaultTranslation;
    if (hasTranslation(theme)) {
      translation = {
        ...defaultTranslation,
        ...theme.translation
      };
    }

    return (
      <div style={theme.tooltip.container}>
        <div style={theme.tooltip.basic}>{label}</div>
        <div style={{ marginTop: '1rem' }}>
          <div>
            <p>Summary:</p>
            <p>
              Sales:{' '}
              <span style={{ fontWeight: 500 }}>
                {numberWithCommas(parseFloat(formatted.n))}
              </span>
            </p>
            <p>
              Min:{' '}
              <span style={{ fontWeight: 500 }}>
                ${numberWithCommas(parseFloat(formatted.values[0]))}
              </span>
            </p>
            <p>
              25%:{' '}
              <span style={{ fontWeight: 500 }}>
                ${numberWithCommas(parseFloat(formatted.values[1]))}
              </span>
            </p>
            <p>
              Median:{' '}
              <span style={{ fontWeight: 500 }}>
                ${numberWithCommas(parseFloat(formatted.values[2]))}
              </span>
            </p>
            <p>
              75%:{' '}
              <span style={{ fontWeight: 500 }}>
                ${numberWithCommas(parseFloat(formatted.values[3]))}
              </span>
            </p>
            <p>
              Max:{' '}
              <span style={{ fontWeight: 500 }}>
                ${numberWithCommas(parseFloat(formatted.values[4]))}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
);

export const BoxPlotTooltip = ({
  color,
  label,
  formatted
}: BoxPlotTooltipProps) => {
  return (
    <BoxPlotSummaryTooltip
      label={label}
      formatted={formatted}
      enableChip={true}
      color={color}
    />
  );
};

export const BoxPlotTooltipLabel = (datum: BoxPlotSummary) => {
  if (datum.subGroup) {
    return datum.group + ' - ' + datum.subGroup;
  }
  return datum.group;
};
