import React from 'react';
import { ProjectPerformanceParams } from 'types/params';
import FeaturedBox, { FeaturedBoxProps } from '../panel/featured-box';

export type CategoryKey = ProjectPerformanceParams['report'];
export type CategoryType = Array<
  Pick<FeaturedBoxProps, 'label' | 'value'> & { key: CategoryKey }
>;
interface CategoriesProps {
  categories: CategoryType;
  selected: CategoryKey;
  onClick: (key: CategoryKey) => void;
  isAllProjectsSelected: boolean;
}

const Categories = ({
  categories,
  selected,
  onClick,
  isAllProjectsSelected
}: CategoriesProps) => {
  return (
    <>
      {categories.map((category, index) => (
        <FeaturedBox
          key={category.key}
          active={category.key === selected}
          onClick={() => onClick(category.key)}
          // Remove SOLD for the feature boxes as it will only appear in the y axis legend
          label={`${
            isAllProjectsSelected && index < 4 ? 'AVERAGE ' : ''
          }${category.label.replace('SOLD ', '')}`}
          value={category.value}
        />
      ))}
    </>
  );
};

export default Categories;
