import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import statsModel from 'data/models/entities/stats';
import { withQuery, withModel } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import ReportTitle from 'view/components/report-title';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import Detailed from 'view/components/reports/detailed';
import withValidate from 'view/containers/with-validate-setting';
import {
  getSettingsQuery,
  periodsQuery,
  projectsQuery
} from 'data/queries/settings';
import { getSettingsId, getToken, getPeriod } from 'utils/reports';

@withReportSidebar()
@withQuery(projectsQuery)
@withQuery(periodsQuery)
@withQuery(getSettingsQuery(getSettingsId, getToken))
@withModel(statsModel)
@withValidate
class DetailedReport extends PureComponent {
  state = {
    fetchingSalesOverTime: true,
    fetchingMonthByMonth: true,
    fetchingSalesPerformance: true,
    fetchingYearToDate: true,
    fetchingPrimary: true,

    salesOverTime: {},
    monthByMonth: {},
    salesPerformance: {},
    yearToDate: {},
    primary: {}
  };

  componentDidMount() {
    const { stats, whereabouts, settingProjects } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (!_.get(query, 'period') && !_.get(query, 'token')) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    const args = {
      id: getSettingsId(this.props),
      args: {
        token: _.get(whereabouts, 'query.token'),
        period: getPeriod(this.props)
      }
    };

    stats
      .fetchDetailedSalesOverTimeSettings(args)
      .then((res) => {
        this.setState({
          fetchingSalesOverTime: false,
          salesOverTime: res.data
        });
      })
      .catch(console.error);

    stats
      .fetchDetailedMonthByMonthSettings(args)
      .then((res) => {
        this.setState({
          fetchingMonthByMonth: false,
          monthByMonth: res.data
        });
      })
      .catch(console.error);

    stats
      .fetchDetailedSalesPerformanceSettings(args)
      .then((res) => {
        this.setState({
          fetchingSalesPerformance: false,
          salesPerformance: res.data
        });
      })
      .catch(console.error);

    stats
      .fetchDetailedYearToDateSettings(args)
      .then((res) => {
        this.setState({
          fetchingYearToDate: false,
          yearToDate: res.data
        });
      })
      .catch(console.error);

    settingProjects
      .fetchPrimary({
        settingId: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token')
        }
      })
      .then((res) => {
        this.setState({
          fetchingPrimary: false,
          primary: res.data
        });
      })
      .catch(console.error);
  }

  loading() {
    const { settingProjects, periods } = this.props;

    const {
      fetchingSalesOverTime,
      fetchingMonthByMonth,
      fetchingSalesPerformance,
      fetchingYearToDate,
      fetchingPrimary
    } = this.state;

    const arr = [
      fetchingSalesOverTime,
      fetchingMonthByMonth,
      fetchingSalesPerformance,
      fetchingYearToDate,
      fetchingPrimary,
      _.get(settingProjects, 'list.status') !== 'loaded',
      _.get(periods, 'list.status') !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const { settingProjects, periods, settings } = this.props;
    const {
      salesOverTime,
      monthByMonth,
      salesPerformance,
      yearToDate,
      primary
    } = this.state;

    const loading = this.loading();
    const maxRequests = 7;
    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const period = _.get(periods, 'list.items.0');
    const projects = _.get(settingProjects, 'list.items');
    const isGuest = !!getToken(this.props);

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle
            period={period}
            project={primary}
            settings={_.get(settings, 'item.data')}
          />
        </Portal>

        <Detailed
          projects={projects}
          primaryProject={primary}
          salesOverTime={salesOverTime}
          monthByMonth={monthByMonth}
          salesPerformance={salesPerformance}
          yearToDate={yearToDate}
        />

        <ReportFooter
          prevText="Lot Availability"
          prevRoute={ROUTES.APP.REPORT_SETTINGS_AVAILABILITY}
          nextText={isGuest ? 'Definitions Appendix' : 'Pricelists'}
          nextRoute={
            isGuest
              ? ROUTES.APP.REPORT_SETTINGS_DEFINITIONS
              : ROUTES.APP.REPORT_SETTINGS_PRICELIST
          }
          settingId={getSettingsId(this.props)}
        />
      </Fragment>
    );
  }
}

export default DetailedReport;
