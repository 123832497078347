import { formatNumber } from 'utils/format';

export const MIN_LOT_SIZE = 200;

export const MAX_LOT_SIZE = 700;

export const MIN_PRICE_RANGE = 200000;

export const MAX_PRICE_RANGE = 600000;

export const OUT_OF_LOTSIZE_RANGE_ERROR = `You have entered an allotment outside of the expected size range: ${MIN_LOT_SIZE} - ${MAX_LOT_SIZE} m2`;

export const OUT_OF_PRICE_RANGE_ERROR = `You have entered an allotment outside of the expected price range: $${formatNumber(
  MIN_PRICE_RANGE
)} - $${formatNumber(MAX_PRICE_RANGE)}`;
