import React, { PureComponent } from 'react';
import ActionMenu from '@rexlabs/action-menu';
import HorizontalActionButton from 'assets/horizontal-action-button.svg';

class HorizontalActionMenu extends PureComponent {
  renderButton = () => <HorizontalActionButton />;
  render() {
    return (
      <ActionMenu
        placement="left-start"
        distance="20rem"
        offset="-40rem"
        arrowMargin="5.3rem"
        Button={this.renderButton}
        {...this.props}
      />
    );
  }
}

export default HorizontalActionMenu;
