import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

class CompetitionLineChart extends PureComponent {
  static defaultProps = {
    isAnimationActive: false,
    labelLine: false,
    outerRadius: 100
  };

  render() {
    const { data, pdk, dk, pdkName, pkName, xk, width, height, yAxisProps } =
      this.props;
    return (
      <Box>
        <ResponsiveContainer width={width} height={height}>
          <LineChart
            data={data}
            margin={{ top: 0, right: 40, left: 0, bottom: 0 }}
          >
            <XAxis dataKey={xk} />
            <YAxis {...yAxisProps} />
            <CartesianGrid vertical={false} />
            <Tooltip />
            <Legend verticalAlign="top" iconType="line" height={40} />
            <Line
              name={pdkName}
              dataKey={pdk}
              stroke="#ACEEDF"
              strokeWidth={2}
              activeDot={{ r: 6 }}
            />
            <Line
              name={pkName}
              dataKey={dk}
              stroke="#00A093"
              strokeWidth={2}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    );
  }
}

export default CompetitionLineChart;
