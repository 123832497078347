import React, { useState } from 'react';
import { find, get } from 'lodash';

import { PLACEMENTS } from '@rexlabs/tooltip';
import Box from '@rexlabs/box';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';

import { VividTooltipStateful } from 'src/features/reports/components/vivid';
import SelectorCheckbox from './selector-checkbox';
import { Label } from 'view/components/text';
import { ArrowDown } from '../icons';

import { COLORS, FONT, MARGINS } from 'src/features/reports/theme';

const styles = StyleSheet({
  container: {
    gap: MARGINS.XS,
    maxHeight: '248px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: COLORS.GREY.DARK
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: `${COLORS.GREY.DARK}D9` // 0.85 opacity
    }
  },
  textInputContainer: {
    position: 'relative'
  },
  textInputLabel: {
    backgroundColor: COLORS.WHITE,
    fontFamily: FONT.FAMILIES.DEFAULT,
    position: 'absolute',
    top: '0px',
    left: '12px',
    transform: 'translateY(-50%)',
    zIndex: 1,
    padding: '0 4px',
    color: COLORS.BLUE.DARK,
    fontWeigth: 700
  }
});

const inputStyles = StyleSheet({
  wrapper: {
    width: '185px',
    marginTop: '0'
  },
  container: {
    borderColor: `${COLORS.BLUE.DARK}`,
    borderRadius: '4px'
  },
  input: {
    fontSize: '1.5rem',
    fontFamily: FONT.FAMILIES.DEFAULT
  }
});

const tooltipStyles = StyleSheet({
  tooltip: {
    padding: 0,
    width: '185px'
  }
});

export interface LotArea {
  /**
   * This can be used for determining if checkbox is checked or not
   */
  key: string;
  /**
   * Label for the checkbox
   */
  label: string;
  /**
   * Number of lots sold, this will be used in the subtext of the checkbox
   */
  noOfLotsSold: string;
}

interface LotAreaSelectorProps {
  styles: Styles;
  lotAreas: LotArea[];
  selectedLotAreas: LotArea[];
  onChange: (value: LotArea[]) => void;
}

const Content = ({
  selectedLotAreas,
  onChange,
  lotAreas,
  styles: s
}: LotAreaSelectorProps) => {
  return (
    <Box {...s('container')}>
      {lotAreas.map((lotArea) => (
        <SelectorCheckbox
          withoutCheckbox
          key={lotArea.key}
          checked={!!find(selectedLotAreas, lotArea)}
          label={lotArea.label}
          subText={lotArea.noOfLotsSold}
          onClick={() => onChange([lotArea])}
        />
      ))}
    </Box>
  );
};

const LotAreaSelector = (props: LotAreaSelectorProps) => {
  const { selectedLotAreas, styles: s } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <VividTooltipStateful
      styles={tooltipStyles}
      distance="10px"
      Arrow={null}
      onChange={({ isOpen }: { isOpen: boolean }) => setIsOpen(isOpen)}
      placement={PLACEMENTS.BOTTOM}
      Content={Content}
      contentProps={props}
    >
      <Box {...s('textInputContainer')}>
        <Label {...s('textInputLabel')}>Lot types</Label>
        <TextInput
          styles={inputStyles}
          controlled
          readOnly
          value={get(selectedLotAreas, '[0].label', '')}
          suffix={
            <ArrowDown
              style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          }
        />
      </Box>
    </VividTooltipStateful>
  );
};

export default styled(styles)(LotAreaSelector);
