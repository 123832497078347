import React, { useState, useMemo, FC } from 'react';

import Box from '@rexlabs/box';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { withWhereabouts } from '@rexlabs/react-whereabouts';

import { COLORS } from 'src/features/reports/theme';
import { ROUTE_NAMES } from 'src/features/reports/routes';
import Sidebar from 'src/features/reports/components/sidebar/sidebar';
import { ReportTitle } from '../components/report-title';

import { compose } from 'utils/compose';

import { ReportQueryParams } from 'types/params';
import { Whereabouts } from 'types/common';

const OPEN_SIDEBAR_WIDTH = 245;
const CLOSED_SIDEBAR_WIDTH = 60;
const MAX_WIDTH = 1400;

const defaultStyles = StyleSheet({
  container: {
    height: '100%',
    position: 'relative',
    width: '100%'
  },
  sidebar: {
    transition: 'width 0.3s',
    height: '100%',
    position: 'fixed',
    backgroundColor: COLORS.BLUE.DARK,
    zIndex: 1
  },
  main: { zIndex: 0, width: '100%', minHeight: '100vh' },
  content: {
    width: '100%',
    maxWidth: MAX_WIDTH,
    paddingLeft: 25,
    paddingRight: 25
  }
});

interface SidebarCoreProps {
  whereabouts: Whereabouts<ReportQueryParams>;
  styles: Styles;
}

const SideBar: FC<SidebarCoreProps> = ({
  styles: s,
  whereabouts,
  children
}) => {
  const q = whereabouts?.query;
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    window.innerWidth > MAX_WIDTH
  );

  const sidebarWidth = useMemo(
    () => (isSidebarOpen ? OPEN_SIDEBAR_WIDTH : CLOSED_SIDEBAR_WIDTH),
    [isSidebarOpen]
  );

  const onToggle = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <Box flexDirection="row" {...s('container')}>
      <Box width={sidebarWidth} {...s('sidebar')}>
        <Sidebar isSidebarOpen={isSidebarOpen} onToggle={onToggle} />
      </Box>
      <Box {...s('main')} paddingLeft={sidebarWidth} justifyContent="center">
        <Box {...s('content')}>
          <ReportTitle text={ROUTE_NAMES[whereabouts.path]} />
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default compose(styled(defaultStyles), withWhereabouts)(SideBar);
