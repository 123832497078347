import React, { CSSProperties } from 'react';
import Box from '@rexlabs/box';

import { COLORS } from 'src/features/reports/theme';
import HeaderCell from './table-header-cell';
import { DataColumn, TableSortProps } from '.';

const styles = {
  headerRow: {
    height: 34,
    minHeight: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: COLORS.BLUE.LIGHT,
    display: 'grid'
  }
};

export type TableHeaderRowProps<T> = {
  columns: DataColumn<T>[];
  onSort: (sort: TableSortProps) => void;
  sort: TableSortProps;
  divisionStyle: CSSProperties['gridTemplateColumns'];
};

function TableHeaderRow<T>({
  columns,
  onSort,
  sort,
  divisionStyle
}: TableHeaderRowProps<T>) {
  return (
    <Box style={{ ...styles.headerRow, gridTemplateColumns: divisionStyle }}>
      {columns.map((column, idx) => (
        <HeaderCell
          key={`header${idx}`}
          column={column}
          onSort={onSort}
          sort={sort}
        />
      ))}
    </Box>
  );
}

export default TableHeaderRow;
