import React from 'react';
import Box from '@rexlabs/box';
import { SubHeading } from 'view/components/text';
import { PADDINGS } from 'src/theme';
import _ from 'lodash';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { formatPeriod, properCase } from 'utils/format';
import SectionContainer from 'view/components/section-container';
import { COLORS } from 'theme';

interface SalesPerMonthProps {
  title: string;
  accessor: 'gross' | 'net';
  salesPerMonth: any;
  primary?: any;
  period: any;
}

const SalesPerMonth = ({
  title,
  accessor,
  salesPerMonth,
  period,
  primary
}: SalesPerMonthProps) => {
  const statsWithAverages = _.get(salesPerMonth, 'historic_by_date');

  const grossSales = _.map(statsWithAverages, (stat, key) => ({
    ...stat,
    period: formatPeriod(key, true)
  }));
  const date = new Date(_.get(period, 'end_date'));
  const dateRange = _.range(0, 12).map((offset) => {
    const newDate = new Date(date.getFullYear(), date.getMonth() - offset, 1);
    return formatPeriod(newDate, true);
  });

  const salesPerMonthData = _.map(dateRange, (date) => {
    const dateObj = { period: date };
    const result = _.find(grossSales, dateObj);
    if (result) {
      return { ...result, dateObj };
    }
    return dateObj;
  }).reverse();

  const minGrossPrimary = _.min(
    _.map(grossSales, (x) => _.get(x, 'primary.gross_sales_for_month'))
  );
  const minGrossAverage = _.min(
    _.map(grossSales, (x) => _.get(x, 'average.gross_sales_for_month'))
  );
  const minNetPrimary = _.min(
    _.map(grossSales, (x) => _.get(x, 'primary.net_sales_for_month'))
  );
  const minNetAverage = _.min(
    _.map(grossSales, (x) => _.get(x, 'average.net_sales_for_month'))
  );

  const min = _.min([
    minGrossPrimary,
    minGrossAverage,
    minNetPrimary,
    minNetAverage
  ]);

  const maxGrossPrimary = _.max(
    _.map(grossSales, (x) => _.get(x, 'primary.gross_sales_for_month'))
  );
  const maxGrossAverage = _.max(
    _.map(grossSales, (x) => _.get(x, 'average.gross_sales_for_month'))
  );
  const maxNetPrimary = _.max(
    _.map(grossSales, (x) => _.get(x, 'primary.net_sales_for_month'))
  );
  const maxNetAverage = _.max(
    _.map(grossSales, (x) => _.get(x, 'average.net_sales_for_month'))
  );

  const max = _.max([
    maxGrossPrimary,
    maxGrossAverage,
    maxNetPrimary,
    maxNetAverage
  ]);
  return (
    <SectionContainer p={PADDINGS.TINY} m={PADDINGS.XXS}>
      <Box m={PADDINGS.S}>
        <SubHeading>{title}</SubHeading>
      </Box>
      <ResponsiveContainer width={'100%'} height={300}>
        <LineChart
          data={salesPerMonthData}
          margin={{ top: 0, right: 40, left: 0, bottom: 0 }}
        >
          <XAxis dataKey={'period'} />
          <YAxis domain={[min < 0 ? min : 0, max]} />
          <CartesianGrid vertical={false} />
          <Tooltip />
          <Legend verticalAlign="top" iconType="line" height={40} />
          {primary && (
            <Line
              name={properCase(accessor) + ' for Primary'}
              dataKey={`primary.${accessor}_sales_for_month`}
              stroke={COLORS.ORANGE.REPORT}
              strokeWidth={2}
              activeDot={{ r: 6 }}
            />
          )}
          <Line
            name={'Ave. ' + properCase(accessor) + ' for Corridor'}
            dataKey={`average.${accessor}_sales_for_month`}
            stroke={COLORS.BLUE_GREEN}
            strokeWidth={2}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </SectionContainer>
  );
};

export default SalesPerMonth;
