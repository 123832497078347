/* eslint-disable max-lines */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { withModel } from '@rexlabs/model-generator';
import { compose } from 'utils/compose';
import { COLORS, FONT } from 'src/features/reports/theme';
import MenuIcon from './menu-icon';
import { VividTooltipStateful } from 'src/features/reports/components/vivid';
import reportModel from 'src/features/reports/models/reports';
import { ReportModel } from 'types/models/report';
import { Report, ReportSettings } from 'types/resource';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import withError from 'view/containers/with-error';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { flatMap } from 'lodash';
import LoadingSpinner from '@rexlabs/loading-spinner';
import SaveReport from '../modals/save-report';
import DeleteSavedReport from './delete-saved-report';
import { Whereabouts } from 'types/common';
import { ReportParams } from 'types/params';
import useRouteConfig from 'src/features/reports/hooks/use-route-config';
import { buildQueryString } from '@rexlabs/whereabouts';
import { useAdvanceFilters } from 'src/features/reports/hooks/use-advance-filters';

const styles = StyleSheet({
  title: {
    fontSize: 12,
    marginBottom: 14
  },
  report: {
    backgroundColor: COLORS.GREY.POWDER,
    borderRadius: 4,
    padding: '10px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    marginBottom: 10,
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: 0
    }
  },
  name: {
    fontSize: 14,
    color: COLORS.BLUE.DARK,
    fontWeight: 500,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  projects: {
    fontSize: 10,
    color: COLORS.BLUE.DARK,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  btn: {
    background: 'none',
    outline: 'none',
    border: 'none',
    display: 'flex',
    padding: 0,
    cursor: 'pointer'
  },
  list: {
    maxHeight: 420,
    overflowY: 'auto'
  },
  menuBtn: {
    padding: '8px 16px',
    color: COLORS.BLUE.DARK,
    fontSize: 14,
    width: '100%'
  },
  delete: {
    color: COLORS.RED.DELETE,
    borderTop: `1px solid ${COLORS.GREY.DIVIDER}`
  },
  empty: {
    color: COLORS.BLUE.DARK,
    fontSize: 10,
    fontFamily: FONT.FAMILIES.DEFAULT,
    textAlign: 'center'
  }
});

const tooltipStyles = StyleSheet({
  tooltip: {
    padding: 0,
    width: '129px'
  }
});

interface SavedReportsProps {
  styles: Styles;
  reportModel: ReportModel;
  errorModal: WithErrorModalProps;
  whereabouts: Whereabouts<ReportParams>;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

interface MenuProps {
  styles: Styles;
  setEditReport: Dispatch<SetStateAction<Report | null>>;
  setDeleteReport: Dispatch<SetStateAction<Report | null>>;
  report: Report;
  runReport: (report: Report) => void;
}

const Menu = ({
  styles: s,
  setEditReport,
  report,
  setDeleteReport,
  runReport
}: MenuProps) => {
  return (
    <div>
      <button
        {...s('btn', 'menuBtn')}
        onClick={(e) => {
          e.stopPropagation();
          setEditReport(report);
        }}
      >
        Edit
      </button>
      <button
        {...s('btn', 'menuBtn')}
        onClick={(e) => {
          e.stopPropagation();
          runReport(report);
        }}
      >
        Run Report
      </button>
      <button
        {...s('btn', 'menuBtn', 'delete')}
        onClick={(e) => {
          e.stopPropagation();
          setDeleteReport(report);
        }}
      >
        Delete
      </button>
    </div>
  );
};

const SavedReports = ({
  styles: s,
  reportModel,
  errorModal: { open: openError },
  whereabouts,
  setIsVisible
}: SavedReportsProps) => {
  const {
    standardSubRegions,
    premiumSubRegions,
    setSelectedReport,
    setSelectedProjectsInSubRegion,
    selectedReport,
    selectedProjectsInSubRegion,
    subRegionWithAllProjectsSelected,
    advanceFilters,
    isFirstLoad,
    setFirstLoad,
    setAdvanceFilters
  } = useContext(ReportSettingsContext);
  const {
    getReports,
    deleteReport: deleteReportHandler,
    updateReport
  } = reportModel;
  const { getFilteredProjectsAndSelectedProjects } = useAdvanceFilters();
  const { updateParams } = useRouteConfig({ whereabouts });
  const [list, setList] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editReport, setEditReport] = useState<Report | null>(null);
  const [deleteReport, setDeleteReport] = useState<Report | null>(null);

  const getSettings = useCallback(
    (jsonString: string): ReportSettings | null => {
      try {
        return JSON.parse(jsonString) as ReportSettings;
      } catch (error) {
        openError(error);
        return null;
      }
    },
    [openError]
  );

  const getNumberOfProjects = useCallback(
    (jsonString: string) => {
      const settings = getSettings(jsonString);
      const subRegions = [...premiumSubRegions, ...standardSubRegions];

      if (!settings || !subRegions || subRegions?.length === 0) return '-';

      const { selectedProjectsInSubRegion, subRegionWithAllProjectsSelected } =
        settings;

      if (subRegionWithAllProjectsSelected?.length > 0) {
        // Update selected projects id's when subregion selected is in all projects
        subRegionWithAllProjectsSelected.forEach((id) => {
          const matchedSubRegion = subRegions.find(
            (subRegion) => subRegion.id === +id
          );
          if (!matchedSubRegion) return;
          Object.assign(selectedProjectsInSubRegion, {
            [id]: matchedSubRegion.projects.map((project) => project.id)
          });
        });
      }

      // Get ID's of each project
      const projectIds = flatMap(
        Object.keys(selectedProjectsInSubRegion).map(
          (subRegion) => selectedProjectsInSubRegion[subRegion]
        )
      );

      return `${projectIds?.length ?? 0} project${
        projectIds?.length > 1 ? 's' : ''
      }`;
    },
    [standardSubRegions, premiumSubRegions, getSettings]
  );

  const onEdit = async (newName: string) => {
    if (!editReport) return;
    try {
      await updateReport({
        queryParams: { id: editReport.id, name: newName }
      });
      setList((prev) =>
        prev.map((report) =>
          editReport.id === report.id ? { ...report, name: newName } : report
        )
      );
      if (selectedReport?.id === editReport.id) {
        setSelectedReport({ ...selectedReport, name: newName });
      }
      setEditReport(null);
    } catch (error) {
      openError(error);
    }
  };

  const onDelete = async () => {
    if (!deleteReport) return;
    try {
      await deleteReportHandler({
        queryParams: { id: deleteReport.id }
      });
      setList((prev) => prev.filter((report) => report.id !== deleteReport.id));
      setDeleteReport(null);
      if (selectedReport?.id === deleteReport.id) {
        setSelectedReport(null);
        updateParams({
          ...advanceFilters,
          project_ids: buildQueryString(selectedProjectsInSubRegion),
          sub_region_with_all_projects_selected:
            subRegionWithAllProjectsSelected,
          saved: undefined
        });
      }
    } catch (error) {
      openError(error);
    }
  };

  const runReport = (report: Report) => {
    const settings = getSettings(report.settings);

    if (!settings) return;

    updateParams({
      saved: report.id,
      project_ids: undefined,
      sub_region_with_all_projects_selected: undefined,
      regions: undefined,
      state: undefined,
      developer: undefined,
      suburb: undefined,
      local_government_area: undefined
    });

    const {
      selectedProjectsInSubRegion,
      advanceFilters,
      subRegionWithAllProjectsSelected
    } = settings;

    const selectedProjects = getFilteredProjectsAndSelectedProjects(
      advanceFilters,
      selectedProjectsInSubRegion,
      subRegionWithAllProjectsSelected
    );

    setSelectedProjectsInSubRegion(selectedProjects);
    setAdvanceFilters(advanceFilters);
    setSelectedReport(report);

    setIsVisible(false);
    if (isFirstLoad) {
      setFirstLoad(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getReports()
      .then((res) => {
        setList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        openError(err);
        setIsLoading(false);
      });
  }, [getReports, openError]);

  return (
    <div>
      {deleteReport && (
        <DeleteSavedReport
          onDelete={onDelete}
          onHide={() => setDeleteReport(null)}
        />
      )}
      {editReport && (
        <SaveReport
          onHide={() => setEditReport(null)}
          editReport={editReport}
          onEdit={onEdit}
        />
      )}
      <h4 {...s('title')}>SAVED REPORTS</h4>
      {isLoading ? (
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          height={420}
        >
          <LoadingSpinner />
        </Box>
      ) : !list || list?.length === 0 ? (
        <Box
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={420}
        >
          <p {...s('empty')} style={{ marginBottom: 24 }}>
            You have not saved any reports yet
          </p>
          <p {...s('empty')}>Customize a report and save it to see it here!</p>
        </Box>
      ) : (
        <div {...s('list')}>
          {list.map((report) => (
            <div
              key={report.id}
              {...s('report')}
              onClick={() => runReport(report)}
            >
              <div>
                <p {...s('name')}>{report.name}</p>
                <p {...s('projects')}>{getNumberOfProjects(report.settings)}</p>
              </div>
              <VividTooltipStateful
                styles={tooltipStyles}
                distance="0"
                contentProps={{
                  styles: s,
                  setEditReport,
                  report,
                  setDeleteReport,
                  runReport
                }}
                Content={Menu}
                placement={PLACEMENTS.BOTTOM_END}
                stopPropagation
              >
                <button {...s('btn')}>
                  <MenuIcon />
                </button>
              </VividTooltipStateful>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default compose<Pick<SavedReportsProps, 'setIsVisible'>>(
  styled(styles),
  withModel(reportModel),
  withError.withPropName('errorModal'),
  withWhereabouts
)(SavedReports);
