import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const MissingDocumentOutline = (props: SVGProps<null>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.33301 9.77783V26.8889C5.33301 28.6078 6.72523 30.0001 8.44412 30.0001H20.8886"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1108 29.6109L5.33301 2.38867"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1112 22.2222V9.51022C27.1112 8.68578 26.783 7.894 26.1997 7.31067L21.8006 2.91156C21.2172 2.32822 20.4254 2 19.601 2H11.5557"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1108 9.77778H20.8886C20.0299 9.77778 19.333 9.08089 19.333 8.22222V2"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MissingDocumentOutline;
