import React, { Fragment, useState } from 'react';
import { find, get, includes } from 'lodash';

import { PLACEMENTS } from '@rexlabs/tooltip';
import Box from '@rexlabs/box';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';

import { VividTooltipStateful } from 'src/features/reports/components/vivid';
import Divider from 'view/components/divider';
import SelectorCheckbox from './selector-checkbox';
import { ArrowDown } from '../icons';

import { MARGINS, COLORS, FONT } from 'src/features/reports/theme';

const styles = StyleSheet({
  container: {
    gap: MARGINS.XS,
    maxHeight: '227px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: COLORS.GREY.DARK
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: `${COLORS.GREY.DARK}D9` // 0.85 opacity
    }
  },
  legend: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    marginRight: '12px'
  }
});

const inputStyles = StyleSheet({
  wrapper: {
    width: '185px',
    height: '33px',
    marginTop: '0'
  },
  container: {
    borderColor: `${COLORS.BLUE.DARK}`,
    borderRadius: '4px'
  },
  input: {
    fontSize: '14px',
    fontFamily: FONT.FAMILIES.DEFAULT
  }
});

const tooltipStyles = StyleSheet({
  tooltip: {
    padding: 0,
    width: '181px'
  }
});

export interface Project {
  /**
   * This can be used for determining if checkbox is checked or not
   * There will be fixed keys which are 'all_projects' and 'no_projects'
   */
  key: string | number;
  /**
   * Label for the checkbox
   */
  label: string;
}

interface ProjectSelectorProps {
  styles: Styles;
  selectedProjects: Project[];
  onChange: (value: Project[]) => void;
  color?: string;
  projects: Project[];
}

const Content = ({
  selectedProjects,
  onChange,
  styles: s,
  projects
}: ProjectSelectorProps) => {
  return (
    <Box {...s('container')}>
      {projects.map((project) => (
        <Fragment key={project.key}>
          {includes(['all_projects', 'no_projects'], project.key) && (
            <Divider />
          )}
          <SelectorCheckbox
            withoutCheckbox
            checked={!!find(selectedProjects, project)}
            label={project.label}
            onClick={() => onChange([project])}
          />
        </Fragment>
      ))}
    </Box>
  );
};

const ProjectSelector = (props: ProjectSelectorProps) => {
  const { styles: s, color, selectedProjects } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box flexDirection="row" alignItems="center">
      {color && <div {...s.with('legend')({ backgroundColor: color })} />}
      <VividTooltipStateful
        styles={tooltipStyles}
        distance="10px"
        Arrow={null}
        onChange={({ isOpen }: { isOpen: boolean }) => setIsOpen(isOpen)}
        placement={PLACEMENTS.TOP}
        Content={Content}
        contentProps={props}
      >
        <TextInput
          styles={inputStyles}
          controlled
          readOnly
          value={get(selectedProjects, '[0].label', '')}
          suffix={
            <ArrowDown
              style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          }
        />
      </VividTooltipStateful>
    </Box>
  );
};

export default styled(styles)(ProjectSelector);
