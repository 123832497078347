import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const BurgerIcon = (props: SVGProps<null>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 12.0001H22"
        stroke={COLORS.WHITE}
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2 18.2996H22"
        stroke={COLORS.WHITE}
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2 5.70026H22"
        stroke={COLORS.WHITE}
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BurgerIcon;
