import React from 'react';
import { StyleSheet } from '@rexlabs/styling';
import { DefaultLink, DefaultNode, SankeyNodeDatum } from '@nivo/sankey';
import { FONT } from 'src/features/reports/theme';
import SquareLegend from '../icons/square-legend';

const tooltipStyles = StyleSheet({
  container: {
    background: 'rgb(238, 238, 238)',
    borderRadius: 2,
    fontFamily: FONT.FAMILIES.DEFAULT,
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'fit-content',
    gap: 5,
    '& > p': {
      fontWeight: 'bold'
    },
    padding: 5,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
  }
});

const CustomTooltip = ({
  node
}: {
  node: SankeyNodeDatum<DefaultNode, DefaultLink>;
}) => {
  return (
    <div className={tooltipStyles.container}>
      <SquareLegend fill={node.color} />
      <p>{node.label}</p>
    </div>
  );
};

export default CustomTooltip;
