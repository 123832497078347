import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { differenceBy } from 'lodash';
import { Canceler } from 'axios';

import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import { CancelToken } from '@rexlabs/api-client';

import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import useMonthRangeFilter from 'src/features/reports/hooks/use-month-range-filter';
import productTrendsModel from 'src/features/reports/models/product-trends';
import {
  ALL_PROJECTS,
  NO_PROJECTS,
  TRENDS_PAGE
} from 'src/features/reports/constants';
import { ProjectSelector } from 'src/features/reports/components/selector';

import withError from 'view/containers/with-error';
import { compose } from 'utils/compose';
import { Auth0Context } from 'src/auth0-provider';

import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { ProductTrendsModel } from 'types/models/product-trends';
import { MarketShareDatum } from 'types/graph';

import Panel from '../panel';
import MonthRangeSlider, { MonthYearType } from '../slider/month-range-slider';
import { ToggleButtonGroup } from '../toggle-button';
import { HeatIndicator } from '../heat-map';
import { Loading } from '../graph';
import { GROUP_FILTER, heatLabels, SCOPE_FILTER } from './constants';
import { Project } from '../selector/project-selector';
import MarketShareTable from './market-share-table';
import moment from 'moment';
import InfoTooltip from './info-tooltip';

interface MarketShareProps {
  productTrendsModel: ProductTrendsModel;
  errorModal: WithErrorModalProps;
}

const MarketShare = ({
  productTrendsModel,
  errorModal: { open: openError }
}: MarketShareProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const {
    selectedProjects,
    period,
    selectedProjectIds,
    subRegionsPermissions,
    isFirstLoad
  } = useContext(ReportSettingsContext);
  const canceler = useRef<Canceler>(null);
  const { dateFilter, setDateFilter, startPeriod, endPeriod } =
    useMonthRangeFilter();
  const [groupFilter, setGroupFilter] =
    useState<typeof GROUP_FILTER[number]['key']>('depth');
  const [scopeFilter, setScopeFilter] =
    useState<typeof SCOPE_FILTER[number]['key']>('exact');
  const [reports, setReports] = useState<MarketShareDatum[][]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [projects, setProjects] = useState<Array<Project[]>>([
    [ALL_PROJECTS],
    [NO_PROJECTS]
  ]);

  // Reset projects selected
  useEffect(() => {
    if (!selectedProjects.length) return;
    setProjects([[ALL_PROJECTS], [NO_PROJECTS]]);
  }, [selectedProjects]);

  const duration = useMemo(() => {
    const startDate = moment(startPeriod);
    const endDate = moment(endPeriod);

    return Math.round(moment.duration(endDate.diff(startDate)).asMonths());
  }, [startPeriod, endPeriod]);

  const formattedListOfProjects = useMemo(() => {
    return selectedProjects.map((project) => ({
      label: project.title,
      key: project.id
    }));
  }, [selectedProjects]);

  const getProjectList = useCallback(
    (index: number) => {
      if (index === 0) {
        return differenceBy(
          [...formattedListOfProjects, ALL_PROJECTS],
          projects[1],
          'key'
        );
      } else {
        return differenceBy(
          [...formattedListOfProjects, NO_PROJECTS],
          projects[0],
          'key'
        );
      }
    },
    [projects, formattedListOfProjects]
  );

  useEffect(() => {
    if (
      (!selectedProjectIds.length || !startPeriod || !endPeriod) &&
      !isFirstLoad
    ) {
      return;
    }

    const promises = [];
    const firstProjectIds = projects[0].find(
      (project) => project.key === 'all_projects'
    )
      ? selectedProjectIds
      : (projects[0].map((project) => project.key) as number[]);

    if (!firstProjectIds.length && !isFirstLoad) return;

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    promises.push(
      productTrendsModel
        .fetchMarketShare({
          queryParams: {
            project_ids: firstProjectIds,
            start_period: startPeriod,
            end_period: endPeriod,
            group: groupFilter,
            scope: scopeFilter
          },
          hasPermission:
            !isFirstLoad &&
            hasPermission([...subRegionsPermissions, TRENDS_PAGE]),
          config: { cancelToken }
        })
        .then((res) => res.data)
    );

    if (!projects[1].find((project) => project.key === 'no_projects')) {
      const secondProjectIds = projects[1].map(
        (project) => project.key
      ) as number[];

      if (!secondProjectIds.length) return;

      promises.push(
        productTrendsModel
          .fetchMarketShare({
            queryParams: {
              project_ids: secondProjectIds,
              start_period: startPeriod,
              end_period: endPeriod,
              group: groupFilter,
              scope: scopeFilter
            },
            hasPermission: hasPermission([
              ...subRegionsPermissions,
              TRENDS_PAGE
            ]),
            config: { cancelToken }
          })
          .then((res) => res.data)
      );
    }
    setIsLoading(true);
    Promise.all(promises)
      .then((reports) => {
        // Empty when the api call got cancelled
        if (reports.filter((report) => report).length === 0) return;
        setReports(reports);
        setIsLoading(false);
      })
      .catch((error) => {
        openError(error);
        setIsLoading(false);
      });

    return () => {
      canceler.current?.();
    };
  }, [
    selectedProjectIds,
    projects,
    period,
    productTrendsModel,
    openError,
    hasPermission,
    startPeriod,
    endPeriod,
    groupFilter,
    scopeFilter,
    subRegionsPermissions,
    isFirstLoad
  ]);

  return (
    <Panel
      title="Market Share by Frontage & Depth"
      tooltip={{
        title: 'Market Share by Frontage & Depth',
        description: <InfoTooltip />
      }}
    >
      <Loading isLoading={isLoading} />
      <Box justifyContent="center" mb={34} style={{ gap: 16 }}>
        <ToggleButtonGroup
          width={200}
          group={GROUP_FILTER}
          value={[groupFilter]}
          onToggle={(key: typeof GROUP_FILTER[number]['key']) =>
            setGroupFilter(key)
          }
        />

        <ToggleButtonGroup
          width={200}
          group={SCOPE_FILTER}
          value={[scopeFilter]}
          onToggle={(key: typeof SCOPE_FILTER[number]['key']) =>
            setScopeFilter(key)
          }
        />
      </Box>
      <Box width="588px" m="0 auto 34px">
        <MonthRangeSlider
          value={dateFilter}
          onChange={(value: MonthYearType) => setDateFilter(value)}
          numOfMonthsBack={11}
          latestMonth={period}
        />
      </Box>

      <MarketShareTable
        reports={reports}
        projects={projects}
        reportInterval={duration}
        period={period}
      />

      <HeatIndicator {...heatLabels} />

      <Box
        mt={34}
        style={{ gap: 24 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
      >
        <ProjectSelector
          projects={getProjectList(0)}
          selectedProjects={projects[0]}
          onChange={(selectedProjects: Project[]) =>
            setProjects((prev) => [selectedProjects, prev[1]])
          }
        />
        <ProjectSelector
          projects={getProjectList(1)}
          selectedProjects={projects[1]}
          onChange={(selectedProjects: Project[]) =>
            setProjects((prev) => [prev[0], selectedProjects])
          }
        />
      </Box>
    </Panel>
  );
};

export default compose(
  withModel(productTrendsModel),
  withError.withPropName('errorModal')
)(MarketShare);
