import { ProjectStatsTypes } from '../components/project-stats/columns';

export const fetchProjectStatsMock = (report) => {
  if (report === 'available_stock') return fetchSalesSummaryMock();
  else if (report === 'total_supply') return fetchAvailableStockMock();
  else if (report === 'stock_summary') {
    return fetchStockSummaryMock();
  } else {
    return fetchSalesSummaryMock();
  }
};

export const fetchSalesSummaryMock = (): Promise<{
  data: ProjectStatsTypes[];
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          id: 867,
          gross_sales: 6,
          titled_sold_stock: 0,
          net_sales: 5,
          back_on_market: 1,
          outstanding_contracts: 0,
          average_3_months: 0,
          average_12_months: 0.83,
          title: 'Project 1',
          flags: ['On Hold', 'Tail Ending/Small Project'],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        },
        {
          id: 1265,
          gross_sales: 0,
          titled_sold_stock: 0,
          net_sales: 0,
          back_on_market: 0,
          outstanding_contracts: 0,
          average_3_months: 0,
          average_12_months: 0.33,
          title: 'Project 2',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        },
        {
          id: 1278,
          gross_sales: 0,
          titled_sold_stock: 0,
          net_sales: 0,
          back_on_market: 0,
          outstanding_contracts: 0,
          average_3_months: 0,
          average_12_months: 2.67,
          title: 'Project 3',
          flags: ['Latest Land Data Unavailable'],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        }
      ]
    });
  });
};

export const fetchAvailableStockMock = (): Promise<{
  data: ProjectStatsTypes[];
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          id: 867,
          entry_price: 352900,
          titled_available_stock: 0,
          untitled_available_stock: 7,
          available_stock: 7,
          new_lots_this_month: 9,
          latest_release: 'Sep 21 (67A)',
          months_to_title: '-',
          title: 'Project 1',
          suburb: 'Suburb 1',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        },
        {
          id: 1265,
          entry_price: 410000,
          titled_available_stock: 0,
          untitled_available_stock: 3,
          available_stock: 3,
          new_lots_this_month: 4,
          latest_release: '-',
          months_to_title: '-',
          title: 'Project 2',
          suburb: 'Suburb 2',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        },
        {
          id: 1278,
          entry_price: 291000,
          titled_available_stock: 0,
          untitled_available_stock: 27,
          available_stock: 27,
          new_lots_this_month: 27,
          latest_release: 'Oct 21 (1A)',
          months_to_title: '-',
          title: 'Project 3',
          suburb: 'Suburb 3',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        }
      ]
    });
  });
};

export const fetchTotalSupplyMock = (): Promise<{
  data: ProjectStatsTypes[];
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          id: 867,
          estimated_completion_date: null,
          allotments_remaining: 156,
          estimated_total_allotments: 2540,
          completion_percentage: 94,
          title: 'Project 1',
          suburb: 'Suburb 1',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        },
        {
          id: 1265,
          estimated_completion_date: null,
          allotments_remaining: 15,
          estimated_total_allotments: 21,
          completion_percentage: 29,
          title: 'Project 2',
          suburb: 'Suburb 2',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        },
        {
          id: 1278,
          estimated_completion_date: null,
          allotments_remaining: 75,
          estimated_total_allotments: 75,
          completion_percentage: 0,
          title: 'Project 3',
          suburb: 'Suburb 3',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        }
      ]
    });
  });
};

export const fetchStockSummaryMock = (): Promise<{
  data: ProjectStatsTypes[];
}> => {
  return new Promise((resolve) => {
    resolve({
      data: [
        {
          id: 867,
          size_sold_median: 289,
          price_sold_median: 349000,
          psqm_sold_median: 0,
          size_available_median: 336,
          price_available_median: 354900,
          psqm_available_median: 912,
          title: 'Project 1',
          suburb: 'Suburb 1',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        },
        {
          id: 1265,
          size_sold_median: 304,
          price_sold_median: 396000,
          psqm_sold_median: 1303,
          size_available_median: 304,
          price_available_median: 410000,
          psqm_available_median: 1349,
          title: 'Project 2',
          suburb: 'Suburb 2',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        },
        {
          id: 1278,
          size_sold_median: null,
          price_sold_median: null,
          psqm_sold_median: null,
          size_available_median: 367,
          price_available_median: 338000,
          psqm_available_median: 921,
          title: 'Project 3',
          suburb: 'Suburb 3',
          flags: [],
          is_min_seven_star_nathers: false,
          is_zero_net_carbon_certified: false,
          is_passive_home: false,
          is_greenstar: false,
          is_enviro_development: false
        }
      ]
    });
  });
};
