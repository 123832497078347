import React, { useContext, useEffect, useState } from 'react';
import Box from '@rexlabs/box';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { DefaultButton } from '../button';
import { ArrowDown } from '../icons';
import { LoadReportModal } from '../load-report-modal';
import { compose } from 'utils/compose';
import { Whereabouts } from 'types/common';
import { ReportParams } from 'types/params';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { Auth0Context } from 'src/auth0-provider';

interface LoadReportProps {
  whereabouts: Whereabouts<ReportParams>;
}

const LoadReport = ({ whereabouts }: LoadReportProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const { setFirstLoad, isFirstLoad } = useContext(ReportSettingsContext);
  const [isVisible, setIsVisible] = useState(false);
  // This is used to ensure we only check once
  const [isCheckDone, setIsCheckDone] = useState(true);

  useEffect(() => {
    /*
     ** This check is needed because after login the url has 'state' parameter
     ** and it can be mistaken to an advance filter
     */
    if (!whereabouts.path.includes('app/report') || !isCheckDone) return;
    const {
      project_ids,
      saved,
      regions,
      state,
      suburb,
      developer,
      local_government_area
    } = whereabouts.query;
    setIsCheckDone(false);
    if (
      project_ids ||
      saved ||
      ((regions || state || suburb || developer || local_government_area) &&
        hasPermission(['reports:advanced-filtering']))
    ) {
      setFirstLoad(false);
      return;
    }

    setFirstLoad(true);
    setIsVisible(true);
    // Only run in first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whereabouts.path]);

  return (
    <>
      {isVisible && (
        <LoadReportModal setIsVisible={setIsVisible} closable={!isFirstLoad} />
      )}
      <DefaultButton selected={isVisible} onClick={() => setIsVisible(true)}>
        <Box flexDirection="row" alignItems="center">
          <span style={{ marginRight: 12 }}>Load Report</span>
          <ArrowDown
            width="12"
            height="6"
            style={{ transform: 'rotate(-90deg)' }}
          />
        </Box>
      </DefaultButton>
    </>
  );
};

export default compose(withWhereabouts)(LoadReport);
