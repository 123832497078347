import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
import moment from 'moment';

import Box from '@rexlabs/box';
import { Grid } from '@rexlabs/grid';
import { Portal } from '@rexlabs/portal';
import { push } from '@rexlabs/whereabouts';
import { Link, withWhereabouts } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';
import { query, withQuery, withModel } from '@rexlabs/model-generator';

import PrimaryButton from 'view/components/button/primary';
import LoadingLayout from 'view/layouts/loading';
import SectionColumn from 'view/components/grid/section-column';
import MonthPicker from 'view/components/calendar/month';
import withError from 'view/containers/with-error';
import ExportButton from 'view/components/export-button';
import Select from 'view/components/react-select';
import { Body, Heading, Label } from 'view/components/text';
import { Modal, ButtonBar } from 'view/components/modal';
import { FormField, Form, ReactForms } from 'view/components/form';
import { SelectInput } from 'view/components/input/select';

import { toQuri } from 'utils/query';
import { createValidationRules } from 'utils/form';
import { formatPeriod } from 'utils/format';
import projectsModel from 'data/models/entities/projects';
import periodsModel from 'data/models/entities/periods';
import ROUTES from 'src/routes';
import ReportSettingsFrom from 'src/view/forms/report-setting';
import GuestTokenForm from 'src/view/forms/guest-token';
import { PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  btn: {
    padding: `0 ${PADDINGS.L}`
  },
  content: {
    width: '100%',
    maxWidth: '120rem'
  },
  wrapContent: {
    justifyContent: 'center',
    position: 'relative',
    padding: `0 ${PADDINGS.S}`,
    overflow: 'visible'
  }
});

const projectsQuery = query`{
  ${projectsModel} (include: "sub_region", published: true) {
    id
    title
    status
    summary
    sub_region
  }
}`;

// @withReportSidebar(false)
@withWhereabouts
@withModel(periodsModel)
@withQuery(projectsQuery)
@withError.withPropName('errorModal')
@styled(defaultStyles)
class ReportSettingsScreen extends PureComponent {
  state = {
    currentTab: 'period',
    checkedPeriod: null,
    allProjects: {},
    selectedProjects: {},
    primaryProject: null,
    primaryQuri: [],
    sortDirection: null,
    sortField: null,
    selectedItems: [],
    period: moment().format('YYYY-MM-01'),
    saveSettingModal: false,
    createTokenModal: false
  };

  getDateValue = (period) => {
    if (!period) {
      const date = new Date();
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1
      };
    }

    const date = new Date(period);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1
    };
  };

  save = () => {
    this.setState({ saveSettingModal: true });
  };

  submitForm = (values) => {
    const { periods } = this.props;
    const { selectedItems, period } = this.state;

    return periods
      .fetchList({
        id: 'fetch-period',
        args: {
          q: toQuri([
            {
              field: 'end_date',
              op: '==',
              value: period
            }
          ])
        }
      })
      .then((response) => {
        const { errorModal } = this.props;

        if (_.isEmpty(_.get(response, 'data'))) {
          errorModal.open(
            'Cannot run report on this period. Period not found.'
          );
          return;
        }

        const primaryId = _.get(values, 'primary');
        const projectIds = _.map(selectedItems, (item) => item.id);

        if (primaryId) {
          push(ROUTES.APP.REPORT_OVERVIEW, {
            query: {
              primaryProject: primaryId,
              period: period,
              projects: _.union(projectIds, [primaryId])
            }
          });
        } else {
          push(ROUTES.APP.REPORT_OVERVIEW, {
            query: {
              period: period,
              projects: projectIds
            }
          });
        }
      });
  };

  validate = createValidationRules({
    // primary: 'required',
  });

  handleChange = (selectedItems) => {
    if (!selectedItems) {
      this.setState({ selectedItems: [] });
      return;
    }
    this.setState({ selectedItems });
  };

  isLoading = () => {
    const { projects } = this.props;
    return _.get(projects, 'list.status') !== 'loaded';
  };

  render() {
    const {
      styles: s,
      projects,
      errorModal: { Error }
    } = this.props;
    const {
      filterModal,
      selectedItems,
      period,
      saveSettingModal,
      createTokenModal
    } = this.state;
    const canSubmit = selectedItems.length > 0;

    if (this.isLoading()) {
      return <LoadingLayout />;
    }

    const items = _(_.get(projects, 'list.items')).map((item) => {
      return {
        id: _.get(item, 'id'),
        value: _.get(item, 'id'),
        label: _.get(item, 'title'),
        group: _.get(item, 'sub_region.name')
      };
    });

    const groupedItems = fp.compose(
      fp.map((group) => ({
        label: group,
        options: fp.filter((x) => x.group === group)(items.value())
      })),
      fp.sortBy(fp.identity),
      fp.uniq,
      fp.map((val) => val.group)
    )(items.value());

    return (
      <Fragment>
        <Portal target="header">
          <Box
            style={{
              height: '100%',
              paddingLeft: PADDINGS.L
            }}
            flex={1}
            alignItems={'center'}
          >
            <Heading style={{ color: 'white' }}>Configure Report</Heading>
          </Box>
        </Portal>

        <Box {...s('wrapContent')} flex={1}>
          <Box {...s('content')}>
            <Grid gutter={0} columns={12}>
              <SectionColumn width={12}>
                <Box>
                  <ReactForms
                    handleSubmit={this.submitForm}
                    validate={this.validate}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {({ isSubmitting, values, isValid, ...rest }) => {
                      return (
                        <Form>
                          <Box p={PADDINGS.XS}>
                            <Heading {...s('header')}>New Report</Heading>
                            <Box mt={PADDINGS.XS}>
                              <Label>Period</Label>
                              <MonthPicker
                                from={2008}
                                to={2028}
                                value={this.getDateValue(this.state.period)}
                                onChange={(year, month) =>
                                  this.setState({
                                    period: `${year}-${month}-1`
                                  })
                                }
                                button={
                                  <TextInput
                                    value={formatPeriod(this.state.period)}
                                  />
                                }
                              />
                            </Box>
                            <FormField
                              label="Primary Project"
                              name="primary"
                              Input={SelectInput}
                              inputProps={{
                                options: items.value()
                              }}
                            />
                            <Box pt={PADDINGS.XS} pb={PADDINGS.XS}>
                              <Label>Projects</Label>
                            </Box>
                            <Select
                              options={groupedItems}
                              value={selectedItems}
                              onChange={this.handleChange}
                              closeMenuOnSelect={false}
                              isMulti
                            />
                          </Box>
                          <ButtonBar style={{ paddingRight: PADDINGS.XS }}>
                            <Link to={ROUTES.APP.REPORT}>
                              <PrimaryButton>Back</PrimaryButton>
                            </Link>
                            <ExportButton
                              type={'button'}
                              match={{
                                query: {
                                  projects: _.map(
                                    selectedItems,
                                    (item) => item.id
                                  ),
                                  period: period,
                                  primaryProject: values.primary
                                }
                              }}
                              isLoading={isSubmitting}
                              isDisabled={isSubmitting || !canSubmit}
                            />
                            <PrimaryButton
                              isLoading={isSubmitting}
                              isDisabled={isSubmitting || !canSubmit}
                              type={'button'}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ createTokenModal: true });
                              }}
                            >
                              Create Token
                            </PrimaryButton>
                            <PrimaryButton
                              isLoading={isSubmitting}
                              isDisabled={isSubmitting || !canSubmit}
                              type={'button'}
                              onClick={(e) => {
                                e.preventDefault();
                                if (isValid) {
                                  this.save(values);
                                }
                              }}
                            >
                              Save Setting
                            </PrimaryButton>
                            <PrimaryButton
                              isLoading={isSubmitting}
                              isDisabled={isSubmitting || !canSubmit}
                              green
                            >
                              Run Report
                            </PrimaryButton>
                          </ButtonBar>

                          {saveSettingModal && (
                            <Modal>
                              <ReportSettingsFrom
                                closeModal={() =>
                                  this.setState({ saveSettingModal: false })
                                }
                                projects={_.map(
                                  selectedItems,
                                  (item) => item.id
                                )}
                                primary={values.primary}
                                period={period}
                              />
                            </Modal>
                          )}

                          {createTokenModal && (
                            <Modal>
                              <GuestTokenForm
                                closeModal={() =>
                                  this.setState({ createTokenModal: false })
                                }
                                isNew
                                projects={_.map(
                                  selectedItems,
                                  (item) => item.id
                                )}
                                primary={values.primary}
                                period={period}
                              />
                            </Modal>
                          )}
                        </Form>
                      );
                    }}
                  </ReactForms>
                </Box>
              </SectionColumn>
            </Grid>
          </Box>
        </Box>

        {filterModal && (
          <Modal>
            <Box p={PADDINGS.S} flex={1} flexDirection={'column'}>
              <Heading>Filter Projects</Heading>
              <Box flex={1} flexDirection={'row'}>
                <Body>one</Body>
                <Body>two</Body>
                <Body>three</Body>
              </Box>
              <PrimaryButton
                onClick={() => this.setState({ filterModal: false })}
              >
                Close
              </PrimaryButton>
            </Box>
          </Modal>
        )}

        <Error />
      </Fragment>
    );
  }
}

export default ReportSettingsScreen;
