import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import { HorizontalActionMenu } from 'view/components/action-menu';
import worksheetsModel from 'data/models/entities/worksheets';
import projectsModel from 'data/models/entities/projects';
import { withModel } from '@rexlabs/model-generator';
import { FONT } from 'src/theme';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import { formatDate } from '../../../utils/format';
import StatusBox from 'view/components/status-box/status-box';

import ActionModal from '../../modals/action';
import { Modal } from 'view/components/modal';
import WorksheetForm from '../../forms/worksheet-new';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500'
  },
  status: {
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  container: {
    fontWeight: FONT.WEIGHTS.BOLD
  },
  grid: {
    '> *': {
      padding: '2rem'
    }
  },
  checkbox: {
    cursor: 'default'
  }
});

@withModel(worksheetsModel)
@withModel(projectsModel)
@styled(defaultStyles)
class ProjectWorksheetListItem extends PureComponent {
  state = {
    editModal: false,
    deleteModal: false,
    draftModal: false
  };

  deleteWorksheet = () => {
    const { worksheets, worksheet } = this.props;
    worksheets
      .trashItem({
        id: worksheet.id,
        args: {
          projectId: worksheet.project.id
        }
      })
      .then(() => this.setState({ deleteModal: false }))
      .catch((e) => console.error(e));
  };

  changeStatus = () => {
    const { worksheets, worksheet, refresh } = this.props;
    worksheets
      .updateItem({
        id: worksheet.id,
        data: {
          projectId: worksheet.project_id,
          status: worksheet.status === 'draft' ? 'published' : 'draft'
        }
      })
      .then(() => {
        refresh();
        this.setState({ draftModal: false });
      });
  };

  view = (worksheet) => {
    push(ROUTES.APP.WORKSHEET, {
      params: {
        projectId: worksheet.project.id,
        worksheetId: worksheet.id
      }
    });
  };

  render() {
    const { styles: s, worksheet, checked, toggle, refresh } = this.props;
    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={24}
          style={{ alignItems: 'center' }}
          onClick={() => this.view(worksheet)}
          {...s('grid')}
        >
          <Column
            width={1}
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
            {...s('checkbox')}
          >
            <Checkbox value={checked || false} />
          </Column>
          <Column width={5}>
            <Small {...s('text')}>{worksheet.project.title}</Small>
          </Column>
          <Column width={5}>
            <Small {...s('text')}>{formatDate(worksheet.created_at)}</Small>
          </Column>
          <Column width={5}>
            <Small {...s('text')}>{formatDate(worksheet.updated_at)}</Small>
          </Column>
          <Column width={7}>
            {worksheet.status === 'draft' ? (
              <StatusBox text={'Draft'} />
            ) : (
              <StatusBox published text={'Published'} />
            )}
          </Column>
          <Column width={1} onClick={(e) => e.stopPropagation()}>
            <HorizontalActionMenu
              items={[
                {
                  label: 'View',
                  onClick: () => this.view(worksheet)
                },
                {
                  label: 'Edit',
                  onClick: () => this.setState({ editModal: true })
                },
                {
                  label: 'Delete',
                  onClick: () => this.setState({ deleteModal: true })
                },
                {
                  label: `Change to ${
                    worksheet.status === 'draft' ? 'Published' : 'Draft'
                  }`,
                  onClick: () => this.setState({ draftModal: true })
                }
              ]}
            />
          </Column>
        </Grid>
        {this.state.deleteModal && (
          <ActionModal
            onClose={() => this.setState({ deleteModal: false })}
            onSubmit={this.deleteWorksheet}
            title={'Delete Worksheet'}
            submitText={'Delete'}
          >
            Are you sure you want to delete this worksheet?
          </ActionModal>
        )}

        {this.state.draftModal && (
          <ActionModal
            green
            onClose={() => this.setState({ draftModal: false })}
            onSubmit={this.changeStatus}
            title={'Change State'}
            submitText={'Yes'}
          >
            Are you sure you want to change the status to{' '}
            {worksheet.status === 'draft' ? 'Published' : 'Draft'}?
          </ActionModal>
        )}

        {this.state.editModal && (
          <Modal visibleOverflow>
            <WorksheetForm
              closeModal={() => this.setState({ editModal: false })}
              projectId={worksheet.project_id}
              period={worksheet.period_id}
              worksheet={worksheet}
              onSubmit={refresh}
            />
          </Modal>
        )}
      </Box>
    );
  }
}

export default ProjectWorksheetListItem;
