import React, { useMemo } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { CustomLayer } from '@nivo/line';
import { sumBy, sum } from 'lodash';
import { COLORS } from 'src/features/reports/theme';

const Average: CustomLayer = ({ innerWidth, series, yScale }) => {
  const average = useMemo(() => {
    if (series.length === 0) return 0;
    const length = sumBy(series, 'data.length') / series.length;
    const sums = series.map((serie) => sumBy(serie.data, 'data.y'));
    const total = sum(sums);
    // @ts-expect-error wrong type from nivo
    return yScale(total / length);
  }, [series, yScale]);

  const animatedProps = useSpring({
    lineX2: innerWidth,
    lineY: average,
    textTransform: `translate(${innerWidth - 72}, ${average - 6})`
  });

  if (series.length === 0) return null;

  return (
    <>
      <animated.line
        strokeWidth={1}
        stroke={COLORS.GREY.MEDIUM}
        x1={0}
        x2={animatedProps.lineX2}
        y1={animatedProps.lineY}
        y2={animatedProps.lineY}
      />
      <animated.text
        fill={COLORS.GREY.MEDIUM}
        fontSize="10px"
        fontWeight={700}
        fontFamily="DM Sans"
        transform={animatedProps.textTransform}
      >
        2 year average
      </animated.text>
    </>
  );
};

export default Average;
