import React, { PureComponent } from 'react';
import { Modal } from 'view/components/modal';
import NewProjectForm from 'view/forms/project-new';

class NewProjectModal extends PureComponent {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal hasButtonBar>
        <NewProjectForm title="Create Project" closeModal={closeModal} />
      </Modal>
    );
  }
}

export default NewProjectModal;
