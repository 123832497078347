import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      The Pricing Matrix displays traditional lot typology pricing broken down
      by project. This allows a direct comparison of pricing between key
      competitors. You can toggle between{' '}
      <span className="bold">Available & Sold</span> lots.
      <br />
      <br />
      <span className="bold">Tip:</span> To narrow down your comparison - select
      a small number of competitors from the sub-region projects selector at the
      top of the tool.{' '}
      <span className="italic">Premium sub-region access is required.</span>
      <br />
      <br />
      When selecting <span className="bold">Incentives and or Buffers</span>,
      the price shown becomes the net price by subtracting these from the list
      price.{' '}
      <span className="italic">
        Incentives and buffers are sourced through a variety of methods and
        should be used as a gauge only.
      </span>
      <br />
      <br />
      To view a longer period of time, the{' '}
      <span className="bold">date slider can be dragged</span> to the left.
    </>
  );
};

export default InfoTooltip;
