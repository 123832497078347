import {
  BarDataItem,
  BarDataItemColored
} from 'src/features/reports/components/supply-overview/project-count-bar-chart';
import { COLORS } from 'src/features/reports/theme';
import { ProjectCountResponseDatum } from 'types/graph';
import { getShortMonthWithYear } from 'utils/format';

export const projectCountDataTheme = {
  ActiveColor: COLORS.BLUE.PRIMARY,
  'On-HoldColor': COLORS.PINK.LIGHT,
  FlaggedColor: COLORS.RED.PRIMARY
};

export const infuseColorToData = (data: BarDataItem[]) => {
  return data.map((datum) => {
    return {
      ...datum,
      ...projectCountDataTheme
    };
  });
};

export function formatForBarChart(
  records: ProjectCountResponseDatum[]
): BarDataItemColored[] {
  if (records.length === 0) return [];

  return records.map((record) => {
    const numFields = {
      Active: Number(record.active),
      Flagged: Number(record.flagged),
      'On-Hold': Number(record.on_hold)
    };

    return {
      monthDate: getShortMonthWithYear(record.end_date),
      ...numFields,
      ...projectCountDataTheme
    };
  });
}
