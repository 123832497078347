import React, { Fragment, Component } from 'react';
import Box from '@rexlabs/box';
import { ActionButton, ClearButton } from 'view/components/button';
import MonthPicker from 'view/components/calendar/month';
import { Portal } from '@rexlabs/portal';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import { Grid, Column } from '@rexlabs/grid';
import SectionColumn from 'view/components/grid/section-column';
import { styled, StyleSheet } from '@rexlabs/styling';
import LoadingSpinner from '@rexlabs/loading-spinner';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import periodsModel from 'data/models/entities/periods';
import worksheetsModel from 'data/models/entities/worksheets';
import { Heading } from 'view/components/text';
import List from '@rexlabs/list';
import PeriodWorksheetListItem from 'view/components/list/period-worksheet-list-item';
import PeriodWorksheetListHeader from 'view/components/list/period-worksheet-list-header';
import _ from 'lodash';
import { formatPeriod } from '../../../utils/format';
import Cross from 'src/assets/cross-dark.svg';
import AddMissingWorksheetsModal from '../../modals/add-missing-worksheets';
import NewWorksheetModal from '../../modals/worksheets-new';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import withAction from 'view/containers/with-action';
import withError from 'view/containers/with-error';
import { MARGINS } from 'src/theme';
import PrimaryButton from '../../components/button/primary';
import LoadingLayout from '../../layouts/loading';

const defaultStyles = StyleSheet({
  breadcrumb: {
    marginLeft: '0.8rem',
    marginTop: '0.8rem'
  },
  row: {
    margin: '2rem 2rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':first-child': {
      paddingBottom: '1.5rem'
    }
  },
  buttons: {
    display: 'inline-flex',
    '> *': {
      margin: MARGINS.TINY
    }
  }
});

function getPeriodId(props) {
  return _.get(props, 'match.params.periodId');
}

const periodsQuery = query`{
  ${periodsModel} (id: ${getPeriodId}, include: "worksheets, projects") {
    id
    end_date
    updated_at
    projects
    worksheets
  }
}`;

@withAction.withPropName('actionModal')
@withError.withPropName('errorModal')
@withQuery(periodsQuery)
@withModel(worksheetsModel)
@styled(defaultStyles)
class ViewPeriod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addMissingModal: false,
      addWorksheetModal: false,
      checked: {}
    };

    this.deleteWorksheets = this.deleteWorksheets.bind(this);
  }

  findPeriodAndRedirect(year, month) {
    const { periods, actionModal, errorModal } = this.props;
    const endDate = `${year}-${month}-1`;

    periods.findPeriod(endDate).then((res) => {
      if (res.data.length < 1) {
        actionModal.open(
          () => {
            // Create the period
            periods
              .createItem({
                data: {
                  account_id: this.props.session.accountId,
                  end_date: endDate
                }
              })
              .then((response) => {
                periods.refreshList();
                const id = _.get(response, 'data.id');
                push(ROUTES.APP.PERIOD_VIEW, {
                  params: {
                    periodId: id
                  }
                });
              })
              .catch((err) => {
                errorModal.open(err);
                actionModal.close();
              });
          },
          'Period does not exist. Would you like to create it?',
          'Period not found'
        );
      } else {
        // Redirect to worksheet
        push(ROUTES.APP.PERIOD_VIEW, {
          params: {
            periodId: res.data[0].id
          }
        });
      }
    });
  }

  deleteWorksheets() {
    const { worksheets, actionModal, periods } = this.props;

    const checkedSheets = _.map(this.state.checked, (value, key) => {
      return { id: key, value: value.checked, projectId: value.projectId };
    }).filter((value) => value.value);

    Promise.all(
      checkedSheets.map((sheet) =>
        worksheets.trashItem({
          id: sheet.id,
          args: {
            projectId: sheet.projectId
          }
        })
      )
    ).then(() => {
      periods.refreshItem({ id: periods.item.data.id });
      actionModal.close();
    });
  }

  render() {
    const {
      styles: s,
      periods,
      actionModal: { Action },
      errorModal: { Error }
    } = this.props;
    const period = _.get(periods, 'item.data');
    const projects = _.get(period, 'projects.data');
    const isLoading = _.get(periods, 'item.status') === 'loading';

    if (isLoading) {
      return <LoadingLayout />;
    }

    const worksheets =
      _.get(period, 'worksheets.data') != null
        ? _.get(period, 'worksheets.data').map((worksheet) => {
            return {
              ...worksheet,
              ...{
                project: projects.filter(
                  (project) => project.id === worksheet.project_id
                )[0]
              }
            };
          })
        : [];

    return (
      <Fragment>
        <Portal target="header">
          <Box
            {...s('headerButtons')}
            style={{ height: '100%', alignItems: 'center', padding: '4rem' }}
            flex={1}
          >
            <MonthPicker
              from={2000}
              to={2050}
              onChange={(year, month) =>
                this.findPeriodAndRedirect(year, month)
              }
              button={<ActionButton>Select Period</ActionButton>}
            />
          </Box>
        </Portal>
        <Grid gutter={0} columns={12}>
          <Column width={12}>
            <Box {...s('breadcrumb')}>
              <Breadcrumb
                crumbs={[
                  {
                    label: 'Dashboard',
                    route: '/'
                  },
                  {
                    label: 'Periods',
                    route: '/app/periods'
                  },
                  {
                    label: formatPeriod(period.end_date)
                  }
                ]}
              />
            </Box>
          </Column>
        </Grid>
        <Grid gutter={0} columns={12}>
          <SectionColumn width={12}>
            <Box {...s('container')}>
              <Box {...s('row')} flex={1}>
                <Heading {...s('header')}>
                  {formatPeriod(period.end_date)} Worksheets
                </Heading>
                <Box {...s('buttons')}>
                  {_.filter(this.state.checked, (value) => value.checked)
                    .length > 0 && (
                    <PrimaryButton
                      red
                      onClick={() => {
                        this.props.actionModal.open(
                          this.deleteWorksheets,
                          'Are you sure you want to delete these worksheet(s) & all associated lots?',
                          'Delete Worksheet(s)',
                          true
                        );
                      }}
                    >
                      Delete
                    </PrimaryButton>
                  )}
                  <ClearButton
                    {...s('btn')}
                    iconRight={<Cross style={{ marginLeft: '0.8rem' }} />}
                    onClick={() => this.setState({ addMissingModal: true })}
                  >
                    Add Missing Worksheet
                  </ClearButton>
                  <ClearButton
                    {...s('btn')}
                    iconRight={<Cross style={{ marginLeft: '0.8rem' }} />}
                    onClick={() => this.setState({ addWorksheetModal: true })}
                  >
                    Add Worksheet
                  </ClearButton>
                </Box>
              </Box>
              <List
                items={worksheets}
                isLoading={_.get(periods, 'list.status') === 'loading'}
                endReached={_.get(periods, 'list.pagination.endReached')}
                Header={() => {
                  return (
                    <PeriodWorksheetListHeader
                      checked={
                        _.filter(this.state.checked, (value) => value.checked)
                          .length === worksheets.length
                      }
                      onSelectAll={() => {
                        if (
                          _.filter(this.state.checked, (value) => value)
                            .length === worksheets.length
                        ) {
                          this.setState({
                            checked: {}
                          });
                        } else {
                          const toggledState = worksheets.reduce(
                            (obj, item) => {
                              return {
                                ...obj,
                                [item.id]: {
                                  checked: true,
                                  projectId: item.project_id
                                }
                              };
                            },
                            {}
                          );
                          this.setState({
                            checked: toggledState
                          });
                        }
                      }}
                    />
                  );
                }}
                LoadingView={LoadingSpinner}
                renderItem={(item) => {
                  return (
                    <PeriodWorksheetListItem
                      refresh={() =>
                        periods.refreshItem({ id: periods.item.data.id })
                      }
                      worksheet={{
                        ...item,
                        period: period
                      }}
                      checked={
                        this.state.checked[item.id]
                          ? this.state.checked[item.id].checked
                          : false
                      }
                      toggle={() =>
                        this.setState({
                          checked: {
                            ...this.state.checked,
                            ...{
                              [item.id]: {
                                checked: !_.get(
                                  this.state,
                                  `checked.${item.id}.checked`,
                                  false
                                ),
                                projectId: item.project_id
                              }
                            }
                          }
                        })
                      }
                    />
                  );
                }}
              />
            </Box>
          </SectionColumn>
        </Grid>
        {this.state.addMissingModal && (
          <AddMissingWorksheetsModal
            period={period}
            periodWorksheets={worksheets}
            onSubmit={() => periods.refreshItem({ id: periods.item.data.id })}
            closeModal={() => this.setState({ addMissingModal: false })}
          />
        )}

        {this.state.addWorksheetModal && (
          <NewWorksheetModal
            period={period}
            onSubmit={() => periods.refreshItem({ id: periods.item.data.id })}
            closeModal={() => this.setState({ addWorksheetModal: false })}
          />
        )}
        <Action />
        <Error />
      </Fragment>
    );
  }
}

export default ViewPeriod;
