import React, { Component, Fragment } from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import periodsModel from 'data/models/entities/periods';
import projectsModel from 'data/models/entities/projects';
import statsModel from 'data/models/entities/stats';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import { Portal } from '@rexlabs/portal';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import withError from 'view/containers/with-error';
import ReportTitle from 'view/components/report-title';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import Report from 'src/view/components/reports/lot-availability';
import { toQuri } from 'src/utils/query';

function getPrimaryProjectId(props) {
  return _.get(props, 'match.query.primaryProject');
}

function getPeriod(props) {
  return _.get(props, 'match.query.period');
}

function getProjectIds(props) {
  return _.get(props, 'match.query.projects');
}

function getPeriodQuery(props) {
  return toQuri([
    {
      field: 'end_date',
      op: '==',
      value: getPeriod(props)
    }
  ]);
}

const periodsQuery = query`{
  ${periodsModel} (q: ${getPeriodQuery}) {
    id
    end_date
  }
}`;

const projectsQuery = query`{
  ${projectsModel} (ids: ${getProjectIds}) {
    id
    title
    active
    developer
    sales_office_address
    sales_agency
    local_government_area
    total_area
    net_developable_area
    suburb
    postcode
    estimated_start_date
    estimated_completion_date
    total_area
    status
    created_at

    settings
    sales_volume_offset
    estimated_lots
    precinct_structure_plan  }
}`;

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch'
  }
});

@withReportSidebar(false)
@withQuery(projectsQuery)
@withQuery(periodsQuery)
@withModel(statsModel)
@withError.withPropName('errorModal')
@styled(defaultStyles)
class LotAvailability extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingStock: true,
      fetchingSoldCharacteristics: true,
      fetchingAvailableCharacteristics: true,
      fetchingAvailableSupply: true,

      stock: {},
      soldCharacteristics: {},
      availableCharacteristics: {},
      availableSupply: {},

      currentSoldCharacteristic: 'current',
      currentAvailableCharacteristic: 'current'
    };

    this.loading = this.loading.bind(this);
  }

  handleError = (e) => {
    const { errorModal } = this.props;
    errorModal.open(e.message, null, () => {});
  };

  componentDidMount() {
    const { stats, whereabouts } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (
      !_.get(query, 'period') ||
      !_.get(query, 'projects') ||
      !_.get(query, 'primaryProject')
    ) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    const args = {
      args: {
        projects: getProjectIds(this.props),
        primary: getPrimaryProjectId(this.props),
        period: getPeriod(this.props)
      }
    };

    stats
      .fetchAvailabilityStockLevels(args)
      .then((res) => {
        this.setState({
          stock: res.data,
          fetchingStock: false
        });
      })
      .catch(this.handleError);

    stats
      .fetchAvailabilitySoldCharacteristics(args)
      .then((res) => {
        this.setState({
          soldCharacteristics: res.data,
          fetchingSoldCharacteristics: false
        });
      })
      .catch(this.handleError);

    stats.fetchAvailabilityAvailableCharacteristics(args).then((res) => {
      this.setState({
        availableCharacteristics: res.data,
        fetchingAvailableCharacteristics: false
      });
    });

    stats
      .fetchAvailabilitySupply(args)
      .then((res) => {
        this.setState({
          availableSupply: res.data,
          fetchingAvailableSupply: false
        });
      })
      .catch(this.handleError);
  }

  loading() {
    const { projects, periods } = this.props;
    const {
      fetchingStock,
      fetchingSoldCharacteristics,
      fetchingAvailableCharacteristics,
      fetchingAvailableSupply
    } = this.state;

    const arr = [
      fetchingStock,
      fetchingSoldCharacteristics,
      fetchingAvailableCharacteristics,
      fetchingAvailableSupply,
      projects.list.status !== 'loaded',
      periods.list.status !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const {
      projects,
      periods,
      errorModal: { Error }
    } = this.props;
    const {
      stock,
      soldCharacteristics,
      availableCharacteristics,
      availableSupply
    } = this.state;

    const loading = this.loading();
    const maxRequests = 6;
    if (loading !== 0) {
      return (
        <Fragment>
          <Error />
          <LoadingLayout current={maxRequests - loading} max={maxRequests} />
        </Fragment>
      );
    }

    const period = _.get(periods, 'item.data');

    const projectList = _.sortBy(_.get(projects, 'list.items'), (item) =>
      item.id === parseInt(getPrimaryProjectId(this.props)) ? 0 : 1
    );

    const primaryProject = _(projectList)
      .filter(
        (project) => project.id === parseInt(getPrimaryProjectId(this.props))
      )
      .head();

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle period={period} project={primaryProject} />
        </Portal>

        <Report
          period={period}
          primaryProject={primaryProject}
          projects={projectList}
          stock={stock}
          soldCharacteristics={soldCharacteristics}
          availableCharacteristics={availableCharacteristics}
          availableSupply={availableSupply}
        />

        <ReportFooter
          prevText="Size-price Brackets"
          prevRoute={ROUTES.APP.REPORT_BRACKETS}
          nextText="Estate Detailed Stats"
          nextRoute={ROUTES.APP.REPORT_DETAILED}
        />
      </Fragment>
    );
  }
}

export default LotAvailability;
