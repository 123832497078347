export const CATEGORIES = [
  {
    label: 'Gross Sales',
    key: 'gross_sales',
    leftSubLabel: {
      label: 'Titled',
      key: 'titled'
    },
    rightSubLabel: {
      label: 'Untitled',
      key: 'untitled'
    }
  },
  {
    label: 'Available',
    key: 'available',
    leftSubLabel: {
      label: 'Titled',
      key: 'titled'
    },
    rightSubLabel: {
      label: 'Untitled',
      key: 'untitled'
    }
  },
  {
    label: 'Average Sales',
    key: 'average_sales'
  },
  {
    label: 'Outstanding Contracts',
    key: 'outstanding_contracts'
  },
  {
    label: 'Net Sales',
    key: 'net_sales'
  },
  {
    label: 'Back On Market',
    key: 'back_on_market'
  },
  {
    label: 'Newly Released',
    key: 'newly_released'
  }
];
