import React, { Component, Fragment } from 'react';
import { AddButton, PrimaryButton } from 'view/components/button';
import { Heading } from 'view/components/text';
import ROUTES from 'src/routes';
import { Link } from '@rexlabs/react-whereabouts';
import projectsModel from 'data/models/entities/projects';
import sessionsModel from 'data/models/custom/session';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import List from '@rexlabs/list';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { Portal } from '@rexlabs/portal';
import ProjectTrashedListItem from 'view/components/list/project-trashed-list-item';
import ProjectTrashedListHeader from 'view/components/list/project-trashed-list-header';
import { Grid, Column } from '@rexlabs/grid';
import SectionColumn from 'view/components/grid/section-column';
import { styled, StyleSheet } from '@rexlabs/styling';
import LoadingLayout from 'view/layouts/loading';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import withAction from 'view/containers/with-action';
import withError from 'view/containers/with-error';
import { MARGINS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    backgroundColor: 'white',
    width: '100%'
  },
  header: {
    margin: '2rem',
    justifyContent: 'space-between'
  },
  breadcrumb: {
    marginLeft: '0.8rem',
    marginTop: '0.8rem'
  },
  buttons: {
    display: 'inline-flex',
    '> *': {
      margin: MARGINS.TINY
    }
  }
});

const projectsQuery = query`{
  ${projectsModel} (trashed: true, include: "settings") {
    id
    account_id
    sub_region_id
    title
    density
    website
    developer
    sales_office_address
    suburb
    postcode
    status
    local_government_area
    sales_agency
    precinct_structure_plan
    image_id
    estimated_lots
    estimated_number_of_stages
    estimated_start_date
    estimated_completion_date
    archived_at
    trashed_at
    active
    sales_volume_offset
    summary
    settings
    created_at
  }
}`;

@withAction.withPropName('actionModal')
@withError.withPropName('errorModal')
@withQuery(projectsQuery)
@withModel(sessionsModel)
@styled(defaultStyles)
class TrashedProjectsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showAlertModal: false,
      showErrorModal: false,
      showRecoverModal: false,
      checked: {}
    };

    this.archiveProjects = this.archiveProjects.bind(this);
    this.purgeProjects = this.purgeProjects.bind(this);
  }

  archiveProjects() {
    const { projects, actionModal, errorModal } = this.props;

    // Filter down to the checked projects
    const checkedProjects = _.map(this.state.checked, (value, key) => {
      return { id: key, value: value };
    }).filter((value) => value.value);

    // Execute all the requests
    Promise.all(
      checkedProjects.map((project) =>
        projects.archive({
          id: project.id
        })
      )
    )
      .then(() => {
        projects.refreshList();
        this.setState({ checked: {} });
        actionModal.close();
      })
      .catch((e) => {
        actionModal.close();
        errorModal.open(e);
        projects.refreshList();
      });
  }

  purgeProjects() {
    const { projects, actionModal, errorModal } = this.props;

    // Filter down to the checked projects
    const checkedProjects = _.map(this.state.checked, (value, key) => {
      return { id: key, value: value };
    }).filter((value) => value.value);

    // Execute all the requests
    Promise.all(
      checkedProjects.map((project) =>
        projects.purgeItem({
          id: project.id
        })
      )
    )
      .then(() => {
        projects.refreshList();
        actionModal.close();
      })
      .catch((e) => {
        actionModal.close();
        errorModal.open(e);
        projects.refreshList();
      });
  }

  render() {
    const {
      styles: s,
      projects,
      session,
      actionModal: { Action },
      errorModal: { Error }
    } = this.props;
    return _.get(projects, 'list.status') === 'loading' ? (
      <LoadingLayout />
    ) : (
      <Fragment>
        <Portal target="header">
          <Box
            style={{ height: '100%', alignItems: 'center', padding: '4rem' }}
            flex={1}
          >
            <Link to={ROUTES.APP.PROJECTS_CURRENT.NEW_PROJECT}>
              {({ onClick }) => (
                <AddButton onClick={onClick}>Add Project</AddButton>
              )}
            </Link>
          </Box>
        </Portal>
        <Grid gutter={0} columns={12}>
          <Column width={12}>
            <Box {...s('breadcrumb')}>
              <Breadcrumb
                crumbs={[
                  {
                    label: 'Dashboard',
                    route: '/'
                  },
                  {
                    label: 'Trashed'
                  }
                ]}
              />
            </Box>
          </Column>
        </Grid>
        <Grid gutter={0} columns={12}>
          <SectionColumn width={12}>
            <Box {...s('container')}>
              <Box flex={1} {...s('header')}>
                <Heading>Trashed Projects</Heading>
                <Box {...s('buttons')}>
                  {(_.filter(this.state.checked, (value) => value).length &&
                    _.get(session, 'admin')) > 0 && (
                    <PrimaryButton
                      red
                      onClick={() =>
                        this.props.actionModal.open(
                          this.purgeProjects,
                          'Are you sure you want to purge this/these Project(s) and all associated worksheets? This CANNOT be undone.',
                          'Purge Project(s)',
                          true
                        )
                      }
                    >
                      Purge Project(s)
                    </PrimaryButton>
                  )}
                  {_.filter(this.state.checked, (value) => value).length >
                    0 && (
                    <PrimaryButton
                      green
                      onClick={() =>
                        this.props.actionModal.open(
                          this.archiveProjects,
                          'Are you sure you want to recover this Project Listing(s) and all associated worksheets to the archive?',
                          'Recover Project(s)?'
                        )
                      }
                    >
                      Recover Project(s)
                    </PrimaryButton>
                  )}
                </Box>
              </Box>
              <List
                items={_.get(projects, 'list.items', [])}
                isLoading={_.get(projects, 'list.status') === 'loading'}
                endReached={_.get(projects, 'list.pagination.endReached')}
                Header={() => (
                  <ProjectTrashedListHeader
                    checked={
                      _.filter(this.state.checked, (value) => value).length ===
                      projects.list.items.length
                    }
                    onSelectAll={() => {
                      if (
                        _.filter(this.state.checked, (value) => value)
                          .length === projects.list.items.length
                      ) {
                        this.setState({
                          checked: {}
                        });
                      } else {
                        const toggledState = projects.list.items.reduce(
                          (obj, item) => {
                            return { ...obj, [item.id]: true };
                          },
                          {}
                        );
                        this.setState({
                          checked: toggledState
                        });
                      }
                    }}
                  />
                )}
                renderItem={(item) => {
                  return (
                    <ProjectTrashedListItem
                      project={item}
                      projects={this.props.projects}
                      checked={this.state.checked[item.id]}
                      toggle={() =>
                        this.setState({
                          checked: {
                            ...this.state.checked,
                            ...{
                              [item.id]: !this.state.checked[item.id]
                            }
                          }
                        })
                      }
                    />
                  );
                }}
              />
              {!_.get(projects, 'list.pagination.endReached', true) && (
                <Box
                  flex={1}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '1rem'
                  }}
                >
                  <PrimaryButton
                    green
                    onClick={() => projects.fetchMore()}
                    isLoading={_.get(projects, 'list.status') === 'fetching'}
                    isDisabled={_.get(projects, 'list.status') === 'fetching'}
                  >
                    Load More
                  </PrimaryButton>
                </Box>
              )}
            </Box>
          </SectionColumn>
        </Grid>
        <Action />
        <Error />
      </Fragment>
    );
  }
}

export default TrashedProjectsScreen;
