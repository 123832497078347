import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import { Heading, SubHeading, Body } from 'view/components/text';
import { Portal } from '@rexlabs/portal';
import Table from 'view/components/table';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from 'view/components/arrow';
import ReportTitle from 'view/components/report-title';
import { getColorFromValue, rgbToHex } from 'src/utils/colors';
import { properCase } from 'utils/format';
import SoldPriceVsSize from './sold-price-vs-size';
import Gradient from 'view/components/gradient';
import GA from 'react-ga';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  }
});

@styled(defaultStyles)
class SizePriceBrackets extends PureComponent {
  state = {
    marketShareSizePeriod: 'current',
    marketSharePricePeriod: 'current',
    marketShareFrontagePeriod: 'current',
    marketShareDepthPeriod: 'current'
  };

  getPercentageCell = (value, max) => {
    if (!value || _.get(value, 'value', 0) <= 0) {
      return <Cell>-</Cell>;
    }

    const calculatedColor = getColorFromValue(
      _.get(value, 'value', 0),
      0,
      max || 100
    );
    const nc = rgbToHex(
      calculatedColor[0],
      calculatedColor[1],
      calculatedColor[2]
    );
    return (
      <Cell color={nc}>
        {_.get(value, 'value', 0)}% ({_.get(value, 'count', 0)})
      </Cell>
    );
  };

  render() {
    const {
      styles: s,
      primary,
      period,
      priceSize,
      marketShareSize,
      marketSharePrice,
      marketShareDepth,
      marketShareFrontage,
      settings
    } = this.props;

    const {
      marketShareSizePeriod,
      marketSharePricePeriod,
      marketShareFrontagePeriod,
      marketShareDepthPeriod
    } = this.state;

    const marketShareSizeStats = _.map(marketShareSize, (stat, key) => {
      return {
        ...{
          category: key
        },
        ...stat
      };
    });

    const maxSize = _.get(_.maxBy(marketShareSizeStats, 'max'), 'max', 0);

    const marketShareSizeColumns = (title, max, period) => {
      const periodLabel =
        period === 'yearly'
          ? ' - Year'
          : period === 'quarterly'
          ? ' - Quarter'
          : '';
      return [
        {
          Header: title,
          accessor: 'category',
          fixed: 'left',
          style: {
            background: COLORS.BLUE.LIGHT,
            boxShadow: '4px 0px 3px -3px #ccc',
            border: 'none !important',
            fontWeight: '500'
          }
        },
        {
          Header: 'Available Corridor',
          accessor: 'available_area',
          Cell: (item) =>
            this.getPercentageCell(
              _.get(item, `original.${item.column.id}`),
              max
            )
        },
        {
          Header: 'Sold Corridor' + periodLabel,
          accessor: 'sold_area',
          Cell: (item) =>
            this.getPercentageCell(
              _.get(item, `original.${item.column.id}.${period}`),
              max
            )
        },
        {
          Header: 'Available Primary',
          accessor: 'available_primary',
          Cell: (item) =>
            this.getPercentageCell(
              _.get(item, `original.${item.column.id}`),
              max
            )
        },
        {
          Header: 'Sold Primary' + periodLabel,
          accessor: 'sold_primary',
          Cell: (item) =>
            this.getPercentageCell(
              _.get(item, `original.${item.column.id}.${period}`),
              max
            )
        }
      ];
    };

    const marketSharePriceStats = _.map(marketSharePrice, (stat, key) => {
      return {
        ...{
          category: key
        },
        ...stat
      };
    });

    const maxPrice = _.get(_.maxBy(marketSharePriceStats, 'max'), 'max', 0);
    const maxFrontage = _.get(_.maxBy(marketShareFrontage, 'max'), 'max', 0);
    const maxDepth = _.get(_.maxBy(marketShareDepth, 'max'), 'max', 0);

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle period={period} project={primary} settings={settings} />
        </Portal>

        {/* Legend */}
        <Box {...s('sectionContainer')} style={{ padding: PADDINGS.S }}>
          <Heading>Allotment Volume Legend</Heading>
          <Box style={{ height: '5.5rem ' }} mt={15} mb={15}>
            <Gradient />
          </Box>
          <Box flex={1} justifyContent={'space-between'}>
            <Body grey>High</Body>
            <Body grey>Low</Body>
          </Box>
        </Box>

        <SoldPriceVsSize priceSize={priceSize} />

        <Box {...s('sectionContainer')}>
          <Box
            p={PADDINGS.S}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <SubHeading>Market Share per Size Bracket</SubHeading>
            <ActionMenu
              Button={(button) => (
                <Body
                  onClick={() => this.setState({ actionMenu: true })}
                  style={{
                    cursor: 'pointer',
                    color: COLORS.GREY.DARK,
                    userSelect: 'none'
                  }}
                >
                  {properCase(this.state.marketShareSizePeriod)}
                  &nbsp;
                  {button.isOpen ? <UpArrow /> : <DownArrow />}
                </Body>
              )}
              items={_.map(['Current', 'Quarterly', 'Yearly'], (value) => ({
                label:
                  value.toLowerCase() === this.state.marketShareSizePeriod ? (
                    <span style={{ color: COLORS.BLUE_GREEN }}>{value}</span>
                  ) : (
                    value
                  ),
                onClick: () => {
                  GA.event({
                    category: 'Price Size Brackets',
                    action: 'Toggle Market Share Size Period',
                    label: value.toLowerCase()
                  });
                  this.setState({
                    marketShareSizePeriod: value.toLowerCase()
                  });
                }
              }))}
            />
          </Box>
          <Table
            sortable={false}
            data={marketShareSizeStats}
            columns={marketShareSizeColumns(
              'Total Area (m2)',
              maxSize,
              marketShareSizePeriod
            )}
            getTdProps={(state, rowInfo, column) => {
              if (column.id !== 'category') {
                return {
                  style: {
                    margin: 0,
                    padding: 0
                  }
                };
              }
              return {};
            }}
          />
          <Box p={PADDINGS.TINY}>
            Quarterly and yearly periods only affect sold columns
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box
            p={PADDINGS.S}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <SubHeading>Market Share per Price Bracket</SubHeading>
            <ActionMenu
              Button={(button) => (
                <Body
                  style={{
                    cursor: 'pointer',
                    color: COLORS.GREY.DARK,
                    userSelect: 'none'
                  }}
                >
                  {properCase(marketSharePricePeriod)}
                  &nbsp;
                  {button.isOpen ? <UpArrow /> : <DownArrow />}
                </Body>
              )}
              items={_.map(['Current', 'Quarterly', 'Yearly'], (value) => ({
                label:
                  value.toLowerCase() === marketSharePricePeriod ? (
                    <span style={{ color: COLORS.BLUE_GREEN }}>{value}</span>
                  ) : (
                    value
                  ),
                onClick: () => {
                  GA.event({
                    category: 'Price Size Brackets',
                    action: 'Toggle Market Share Price Period',
                    label: value.toLowerCase()
                  });
                  this.setState({
                    marketSharePricePeriod: value.toLowerCase()
                  });
                }
              }))}
            />
          </Box>
          <Table
            sortable={false}
            data={marketSharePriceStats}
            columns={marketShareSizeColumns(
              'Price',
              maxPrice,
              marketSharePricePeriod
            )}
            getTdProps={(state, rowInfo, column) => {
              if (column.id !== 'category') {
                return {
                  style: {
                    margin: 0,
                    padding: 0
                  }
                };
              }
              return {};
            }}
            pageSize={marketSharePriceStats.length || 5}
          />
          <Box p={PADDINGS.TINY} flexDirection={'column'}>
            <span>Quarterly and yearly periods only affect sold columns</span>
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box
            p={PADDINGS.S}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <SubHeading>Market Share per Frontage Type</SubHeading>
            <ActionMenu
              Button={(button) => (
                <Body
                  style={{
                    cursor: 'pointer',
                    color: COLORS.GREY.DARK,
                    userSelect: 'none'
                  }}
                >
                  {properCase(marketShareFrontagePeriod)}
                  &nbsp;
                  {button.isOpen ? <UpArrow /> : <DownArrow />}
                </Body>
              )}
              items={_.map(['Current', 'Quarterly', 'Yearly'], (value) => ({
                label:
                  value.toLowerCase() === marketShareFrontagePeriod ? (
                    <span style={{ color: COLORS.BLUE_GREEN }}>{value}</span>
                  ) : (
                    value
                  ),
                onClick: () => {
                  GA.event({
                    category: 'Price Size Brackets',
                    action: 'Toggle Market Share Frontage Period',
                    label: value.toLowerCase()
                  });
                  this.setState({
                    marketShareFrontagePeriod: value.toLowerCase()
                  });
                }
              }))}
            />
          </Box>
          <Table
            sortable={false}
            data={marketShareFrontage}
            columns={marketShareSizeColumns(
              'Frontage (m)',
              maxFrontage,
              marketShareFrontagePeriod
            )}
            getTdProps={(state, rowInfo, column) => {
              if (column.id !== 'category') {
                return {
                  style: {
                    margin: 0,
                    padding: 0
                  }
                };
              }
              return {};
            }}
            pageSize={marketShareFrontage.length || 5}
          />
          <Box p={PADDINGS.TINY}>
            <ul>
              <li>Quarterly and yearly periods only affect sold columns</li>
              <li>Categories are a range (e.g. 12.5 includes 12.5 - &lt;14)</li>
            </ul>
          </Box>
        </Box>

        <Box {...s('sectionContainer')}>
          <Box
            p={PADDINGS.S}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <SubHeading>Market Share per Lot Depth</SubHeading>
            <ActionMenu
              Button={(button) => (
                <Body
                  style={{
                    cursor: 'pointer',
                    color: COLORS.GREY.DARK,
                    userSelect: 'none'
                  }}
                >
                  {properCase(marketShareDepthPeriod)}
                  &nbsp;
                  {button.isOpen ? <UpArrow /> : <DownArrow />}
                </Body>
              )}
              items={_.map(['Current', 'Quarterly', 'Yearly'], (value) => ({
                label:
                  value.toLowerCase() === marketShareDepthPeriod ? (
                    <span style={{ color: COLORS.BLUE_GREEN }}>{value}</span>
                  ) : (
                    value
                  ),
                onClick: () => {
                  GA.event({
                    category: 'Price Size Brackets',
                    action: 'Toggle Market Share Depth Period',
                    label: value.toLowerCase()
                  });
                  this.setState({
                    marketShareDepthPeriod: value.toLowerCase()
                  });
                }
              }))}
            />
          </Box>
          <Table
            sortable={false}
            data={marketShareDepth}
            columns={marketShareSizeColumns(
              'Depth (m)',
              maxDepth,
              marketShareDepthPeriod
            )}
            getTdProps={(state, rowInfo, column) => {
              if (column.id !== 'category') {
                return {
                  style: {
                    margin: 0,
                    padding: 0
                  }
                };
              }
              return {};
            }}
            pageSize={marketShareDepth.length || 5}
          />
          <Box p={PADDINGS.TINY}>
            <ul>
              <li>Quarterly and yearly periods only affect sold columns</li>
              <li>Categories are a range (e.g. 28 includes 28 - &lt;30)</li>
            </ul>
          </Box>
        </Box>
      </Fragment>
    );
  }
}

function Cell(props) {
  const { children, color } = props;
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        backgroundColor: color || null,
        paddingLeft: 5
      }}
    >
      {children}
    </span>
  );
}

export default SizePriceBrackets;
