import React, { useState } from 'react';
import Panel from '../panel';
import Categories, { CategoryKey } from './categories';
import Breakdown from './breakdown';
import Summary from './summary';
import InfoTooltip from './info-tooltip';

const ProductOverview = () => {
  const [category, setCategory] = useState<CategoryKey>('breakdown');

  return (
    <Panel
      title="Product Overview"
      tooltip={{
        title: 'Product Overview',
        description: <InfoTooltip />
      }}
      featuredValues={
        <Categories
          selected={category}
          onClick={(key: CategoryKey) => setCategory(key)}
        />
      }
    >
      {category === 'summary' ? <Summary /> : <Breakdown />}
    </Panel>
  );
};

export default ProductOverview;
