import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Canceler } from 'axios';
import { styled, Styles, StyleSheet } from '@rexlabs/styling';
import { CancelToken } from '@rexlabs/api-client';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { withModel } from '@rexlabs/model-generator';
import Box from '@rexlabs/box';
import LoadingSpinner from '@rexlabs/loading-spinner';
import useRouteConfig from 'src/features/reports/hooks/use-route-config';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { SnackbarContext } from 'src/features/reports/providers/snackbar-provider';
import moment from 'moment';
import MonthPicker from 'view/components/calendar/month';
import { compose } from 'utils/compose';
import { getShortMonthWithFullYear, formatPeriod } from 'utils/format';
import { Whereabouts } from 'types/common';
import { ReportParams } from 'types/params';
import reportSettingsModel from 'src/features/reports/models/report-settings';
import { DefaultButton } from '../button';
import { CalendarIcon } from '../icons';
import { ReportSettingsModel } from 'types/models/report-settings';
import { COLORS, FONT } from 'src/features/reports/theme';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import withError from 'view/containers/with-error';

interface PeriodFilterProps {
  whereabouts: Pick<Whereabouts<ReportParams>, 'query' | 'path'>;
  reportSettingsModel: ReportSettingsModel;
  useLatestPeriod?: boolean;
  styles: Styles;
  errorModal: WithErrorModalProps;
}

const today = new Date();

const styles = StyleSheet({
  blocker: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 5,
    display: 'none'
  },
  popout: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: COLORS.WHITE,
    borderRadius: '0.3em',
    padding: '6px',
    fontSize: 10,
    lineHeight: '14px',
    fontFamily: FONT.FAMILIES.DEFAULT,
    textAlign: 'left',
    whiteSpace: 'normal',
    width: 158,
    transition: 'all 0.2s ease-in-out',
    opacity: 0,
    pointerEvents: 'none',
    zIndex: 5,
    boxShadow: '0 0.15rem 1rem rgba(0, 0, 0, 0.2)',
    color: COLORS.GREY.MEDIUM
  },
  container: {
    '& .rmp-pad ul .rmp-btn': {
      position: 'relative',
      overflow: 'visible !important'
    },
    '& .disable-date': {
      cursor: 'not-allowed !important',
      '& div': {
        display: 'block'
      }
    }
  }
});

const PeriodFilter = ({
  whereabouts,
  reportSettingsModel,
  useLatestPeriod,
  styles: s,
  errorModal: { open: openError }
}: PeriodFilterProps) => {
  const canceler = useRef<Canceler | null>(null);
  const { updateParams } = useRouteConfig<ReportParams>({ whereabouts });
  const { addMessage } = useContext(SnackbarContext);
  const { period, setPeriod, setLatestAvailablePeriod, selectedProjectIds } =
    useContext(ReportSettingsContext);
  const pickerRef = useRef<MonthPicker>(null);
  const pickerBtnRef = useRef<HTMLDivElement>(null);
  const popoutRef = useRef<HTMLSpanElement>(null);
  const blockerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [availablePeriods, setAvailablePeriods] = useState<string[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const monthValue = useMemo(() => {
    const momentInstance = moment(period);
    return {
      year: momentInstance.year(),
      month: momentInstance.month() + 1
    };
  }, [period]);

  useEffect(() => {
    if (!availablePeriods.length || useLatestPeriod) return;

    const dateBtns = pickerBtnRef.current?.parentNode
      .querySelector('.rmp-popup ul')
      .getElementsByClassName('rmp-btn');

    for (let i = 0; i < dateBtns.length; i++) {
      const elem = dateBtns[i] as HTMLElement;
      // Clear added nodes before if there is
      Array.from(elem.children).forEach((child) => child.remove());

      // Add tooltip and blocker for each month
      const cloneNode = popoutRef.current?.cloneNode(true);
      const blockerCloneNode = blockerRef.current?.cloneNode();
      dateBtns[i].appendChild(cloneNode);
      dateBtns[i].appendChild(blockerCloneNode);

      elem.onmouseenter = () => {
        const picker = pickerRef.current?.pickerRef.current;
        const currentShowingYearIndex = picker?.state.yearIndexes[0];
        const currentYear = picker?.state.years[currentShowingYearIndex] as {
          year: number;
        };
        const formattedPeriod = `${currentYear.year}-${i + 1 < 10 ? '0' : ''}${
          i + 1
        }-01`;
        // If date is not available show the tooltip
        if (!availablePeriods.includes(formattedPeriod)) {
          (cloneNode as HTMLSpanElement).style.opacity = '1';
          elem.classList.add('disable-date');
        }
      };

      elem.onmouseleave = () => {
        (cloneNode as HTMLSpanElement).style.opacity = '0';
        elem.classList.remove('disable-date');
      };
    }

    // Remove tooltip and blocker because we are done copying them
    blockerRef.current?.remove();
    popoutRef.current?.remove();
  }, [availablePeriods, useLatestPeriod]);

  useEffect(() => {
    if (!selectedProjectIds.length) return;

    // If first load use the same period from the url
    if (whereabouts.query.period && isFirstLoad) {
      setPeriod(whereabouts.query.period);
    }

    canceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      canceler.current = c;
    });

    setIsLoading(true);
    reportSettingsModel
      .fetchAvailableDates({
        queryParams: {
          project_ids: selectedProjectIds
        },
        config: { cancelToken }
      })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;

        setAvailablePeriods(res.data.periods);
        setLatestAvailablePeriod(res.data.latest);

        if (isFirstLoad) {
          setIsFirstLoad(false);
        }
        // If not first load or no period yet select the latest available
        if (!isFirstLoad || !whereabouts.query.period) {
          updateParams({
            period: res.data.latest
          });

          setPeriod(res.data.latest);
          addMessage(
            `Viewing report for ${formatPeriod(
              moment(res.data.latest, 'YYYY-MM-DD')
            )}`
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        openError(error);
        setIsLoading(false);
      });

    return () => {
      canceler.current?.();
    };
    // No need to add whereabouts.query.period as we are updating it as well here
    // whereabouts.query.project_ids is needed because it will use the previous projects_ids when not added
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectIds, whereabouts.query.project_ids]);

  const onPeriodChange = (year: number, month: number) => {
    const formattedPeriod = `${year}-${month < 10 ? '0' : ''}${month}-01`;
    if (!availablePeriods.includes(formattedPeriod)) return;
    setIsOpen(false);
    updateParams({ period: formattedPeriod });
    setPeriod(formattedPeriod);
    addMessage(
      `Viewing report for ${formatPeriod(
        moment(formattedPeriod, 'YYYY-MM-DD')
      )}`
    );
  };

  // Reason for useMemo here is that when snackbar message dissappears the month picker rerenders
  // because it uses the addMessage from the snackbar context, so it goes back to the selected date
  return useMemo(
    () =>
      useLatestPeriod ? null : (
        <div {...s('container')}>
          <MonthPicker
            ref={pickerRef}
            from={2000}
            to={today.getFullYear() + 10}
            value={monthValue}
            onChange={onPeriodChange}
            onShow={() => setIsOpen(true)}
            show={isOpen}
            onHide={() => setIsOpen(false)}
            button={
              <div ref={pickerBtnRef}>
                <span ref={popoutRef} {...s('popout')}>
                  No report is available for this period. New months are usually
                  published 5 business days after the month start.
                </span>
                <div ref={blockerRef} {...s('blocker')} />
                <DefaultButton
                  selected={isOpen}
                  isLoading={isLoading}
                  Loading={() => (
                    <LoadingSpinner
                      colors={[COLORS.BLUE.DARK]}
                      size={20}
                      strokeWidth={4}
                    />
                  )}
                >
                  <Box flexDirection="row" alignItems="center">
                    <span style={{ marginRight: '12px' }}>
                      {period ? getShortMonthWithFullYear(period) : ''}
                    </span>
                    <CalendarIcon width="14" height="auto" />
                  </Box>
                </DefaultButton>
              </div>
            }
          />
        </div>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [monthValue, period, isOpen, s, useLatestPeriod, isLoading]
  );
};

export default compose<
  Omit<
    PeriodFilterProps,
    'whereabouts' | 'reportSettingsModel' | 'styles' | 'errorModal'
  >
>(
  withWhereabouts,
  withModel(reportSettingsModel),
  styled(styles),
  withError.withPropName('errorModal')
)(PeriodFilter);
