import Generator from '../generator';

const config = {
  entities: {
    api: {}
  }
};

const AccountsGenerator = new Generator('accounts', config);
export default AccountsGenerator.createEntityModel();
