import React, { Component } from 'react';
import _ from 'lodash';
import uuid from 'uuid';

import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';
import { TextInput } from '@rexlabs/text-input';
import { TooltipStateful } from '@rexlabs/tooltip';
import { withWhereabouts } from '@rexlabs/react-whereabouts';

import ButtonBar from 'view/components/modal/button-bar';
import Modal from 'view/components/modal/modal';
import withError from 'view/containers/with-error';
import MonthPicker from 'view/components/calendar/month';
import { PrimaryButton, TextButton } from 'view/components/button';
import { Form, FormField, ReactForms } from 'view/components/form';
import { Heading, Label, Tiny } from 'view/components/text';
import { DatePicker } from 'view/components/input/date';

import guestTokenModel from 'data/models/entities/guest-tokens';
import settingModel from 'data/models/entities/settings';
import { formatPeriod } from 'utils/format';
import { createValidationRules } from 'utils/form';
import { COLORS, PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`
  },
  title: {
    color: COLORS.GREY.DARK
  },
  section: {
    padding: `${PADDINGS.S} ${PADDINGS.XL}`,
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`,
    ':last-child': {
      borderBottom: 'none'
    }
  },
  header: {
    marginBottom: `${PADDINGS.XS}`
  },
  checkbox: {
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    alignItems: 'center',
    padding: `${PADDINGS.XXS} 0`,
    color: COLORS.GREY.DARK,
    '> p': {
      margin: PADDINGS.TINY
    }
  }
});

@withError.withPropName('errorModal')
@styled(defaultStyles)
@withModel(guestTokenModel)
@withModel(settingModel)
@withWhereabouts
class GuestTokenForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      successModal: false,
      reportLink: '',
      period: null
    };

    this.result = React.createRef();
  }

  submitForm = (values, props) => {
    const { guestTokens, settingId, period } = this.props;
    const rawDate = _.get(values, 'expiryDate');
    const date = rawDate ? rawDate.format('Y-MM-DD H:mm:ss') : null;

    const payload = {
      email: _.get(values, 'email'),
      max_uses: _.get(values, 'maxUses'),
      expire_time: date,
      report_setting_id: settingId,
      period: this.state.period || period
    };

    return guestTokens
      .createItem({ data: payload })
      .then((res) => {
        const tokenLink = `${window.location.origin}/app/report/${settingId}/overview?token=${res.data.token}`;
        this.setState({
          reportLink: tokenLink,
          successModal: true
        });
      })
      .catch(console.error);
  };

  submitNewSetting = (values, props) => {
    const { guestTokens, settings, primary, projects, errorModal } = this.props;

    // Create new setting
    return settings
      .createItem({
        data: {
          primary_project: primary,
          projects: projects,
          name: uuid(),
          hidden: true
        }
      })
      .then((response) => {
        // Create the guest token
        const settingId = _.get(response, 'data.id');

        const rawDate = _.get(values, 'expiryDate');
        const date = rawDate ? rawDate.format('Y-MM-DD H:mm:ss') : null;

        const payload = {
          email: _.get(values, 'email'),
          max_uses: _.get(values, 'maxUses'),
          expire_time: date,
          report_setting_id: settingId,
          period: this.state.period || this.props.period
        };

        return guestTokens
          .createItem({ data: payload })
          .then((res) => {
            // Display the token
            const tokenLink = `${window.location.origin}/app/report/${settingId}/overview?token=${res.data.token}`;
            this.setState({
              reportLink: tokenLink,
              successModal: true
            });
          })
          .catch((e) => errorModal.open(e));
      })
      .catch((e) => errorModal.open(e));
  };

  validate = (values, props) => {
    const validation = createValidationRules({
      email: 'email|required',
      maxUses: 'integer|required'
    })(values, props);

    return validation;
  };

  close = (e) => {
    const { closeModal } = this.props;
    e.preventDefault();
    closeModal();
  };

  copyText = () => {
    this.result.current.select();
    document.execCommand('copy');
  };

  getDateValue = (period) => {
    if (!period) {
      const date = new Date();
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1
      };
    }

    const date = new Date(period);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1
    };
  };

  render() {
    const { successModal } = this.state;
    const {
      styles: s,
      errorModal: { Error },
      isNew
    } = this.props;

    const submitMethod = isNew ? this.submitNewSetting : this.submitForm;

    return (
      <Box {...s('container')}>
        <ReactForms
          handleSubmit={submitMethod}
          validate={this.validate}
          validateOnChange={false}
        >
          {(props) => {
            const { isSubmitting } = props;

            return (
              <Form name={'guest-token-form'}>
                <Box flex={1} flexDirection={'column'}>
                  <Heading>Create Guest Token</Heading>
                  <Box>
                    <Label>Period</Label>
                    <MonthPicker
                      from={2008}
                      to={2028}
                      value={this.getDateValue(
                        this.state.period || this.props.period
                      )}
                      onChange={(year, month) => {
                        this.setState({ period: `${year}-${month}-1` });
                      }}
                      button={
                        <TextInput
                          value={formatPeriod(
                            this.state.period || this.props.period
                          )}
                        />
                      }
                    />
                  </Box>
                  <FormField
                    label="Expiry Date"
                    name="expiryDate"
                    Input={DatePicker}
                    inputProps={{
                      placeholder: 'None'
                    }}
                  />
                  <FormField
                    sendImmediate
                    label={'Max Uses'}
                    name={'maxUses'}
                    Input={TextInput}
                    initialValue={20}
                  />
                  <FormField
                    sendImmediate
                    label={'Email'}
                    name={'email'}
                    Input={TextInput}
                    inputProps={{
                      placeholder: 'Guest user email'
                    }}
                  />
                </Box>
                <ButtonBar style={{ paddingRight: 0 }}>
                  <TextButton type="button" onClick={this.close}>
                    Cancel
                  </TextButton>
                  <PrimaryButton
                    isDisabled={isSubmitting}
                    isLoading={isSubmitting}
                    green
                  >
                    Create Token
                  </PrimaryButton>
                </ButtonBar>
              </Form>
            );
          }}
        </ReactForms>

        {successModal && (
          <Modal>
            <Box {...s('container')}>
              <Heading>Your Guest Token</Heading>
              <Box flex={1} flexDirection={'row'} alignItems={'baseline'}>
                <TextInput
                  setRef={this.result}
                  defaultValue={this.state.reportLink}
                  styles={{
                    wrapper: {
                      width: '100%'
                    }
                  }}
                />
                <TooltipStateful
                  styles={{
                    wrapContent: {
                      zIndex: 200
                    }
                  }}
                  placement={'top'}
                  openOn={['CLICK']}
                  closeOn={['NONE']}
                  hoverTimeout={250}
                  closeDuration={250}
                  Content={() => <Tiny>Copied</Tiny>}
                >
                  <PrimaryButton green onClick={this.copyText}>
                    Copy
                  </PrimaryButton>
                </TooltipStateful>
              </Box>
              <ButtonBar style={{ paddingRight: 0 }}>
                <TextButton type="button" onClick={this.close}>
                  Close
                </TextButton>
              </ButtonBar>
            </Box>
          </Modal>
        )}
        <Error />
      </Box>
    );
  }
}

export default GuestTokenForm;
