import React from 'react';
import { DataColumn } from '../table';
import {
  AvailableStockDatum,
  SalesSummaryDatum,
  StockSummaryDatum,
  TotalSupplyDatum
} from 'types/graph';
import { formatCurrency } from 'utils/format';
import ProjectStatIcons from './project-stat-icons';

interface ColumnConfigProps<T> {
  report: string;
  defaultSortField: string;
  defaultSortDir: 'asc' | 'desc';
  columns: DataColumn<T>[];
}

export type ProjectStatsTypes =
  | SalesSummaryDatum
  | AvailableStockDatum
  | TotalSupplyDatum
  | StockSummaryDatum;

export const columnsConfig: ColumnConfigProps<ProjectStatsTypes>[] = [
  {
    report: 'sales_summary',
    defaultSortField: 'gross_sales',
    defaultSortDir: 'desc',
    columns: [
      {
        key: 'title',
        title: 'PROJECT',
        width: 150,
        render: (row: SalesSummaryDatum) => {
          return <ProjectStatIcons {...row} />;
        }
      },
      {
        key: 'gross_sales',
        title: 'GROSS SALES'
      },
      {
        key: 'net_sales',
        title: 'NET SALES'
      },
      {
        key: 'back_on_market',
        title: 'BACK ON MARKET'
      },
      {
        key: 'outstanding_contracts',
        title: 'PENDING CONTRACTS'
      },
      {
        key: 'average_3_months',
        title: '3 MTH AVG SALES'
      },
      {
        key: 'average_12_months',
        title: '12 MTH AVG SALES'
      },
      {
        key: 'titled_sold_stock',
        title: 'TITLED SALES'
      }
    ] as DataColumn<ProjectStatsTypes>[]
  },
  {
    report: 'available_stock',
    defaultSortField: 'new_lots_this_month',
    defaultSortDir: 'desc',
    columns: [
      {
        key: 'title',
        title: 'PROJECT',
        width: 150,
        render: (row: AvailableStockDatum) => {
          return <ProjectStatIcons {...row} />;
        }
      },
      {
        key: 'new_lots_this_month',
        title: 'NEWLY RELEASED'
      },
      {
        key: 'entry_price',
        title: 'ENTRY PRICE',
        render: (row: AvailableStockDatum) => {
          return row.entry_price ? formatCurrency(row.entry_price) : '-';
        }
      },
      {
        key: 'titled_available_stock',
        title: 'TITLED LOTS'
      },
      {
        key: 'untitled_available_stock',
        title: 'UNTITLED LOTS'
      },
      {
        key: 'available_stock',
        title: 'TOTAL AVAILABLE'
      },
      {
        key: 'latest_release',
        title: 'LATEST RELEASE'
      },
      {
        key: 'months_to_title',
        title: 'MONTHS TO TITLE'
      }
    ] as DataColumn<ProjectStatsTypes>[]
  },
  {
    report: 'total_supply',
    defaultSortField: 'allotments_remaining',
    defaultSortDir: 'desc',
    columns: [
      {
        key: 'title',
        title: 'PROJECT',
        width: 150,
        render: (row: TotalSupplyDatum) => {
          return <ProjectStatIcons {...row} />;
        }
      },
      {
        key: 'suburb',
        title: 'SUBURB'
      },
      {
        key: 'allotments_remaining',
        title: 'LOTS REMAINING',
        render: (row: TotalSupplyDatum) => {
          // Return "-" if null, '0' if 0 and '-' if null
          const value = row.allotments_remaining;
          if (value === 0) {
            return '0';
          } else if (value === null) {
            return '-';
          }
          return row.allotments_remaining;
        }
      },
      {
        key: 'estimated_total_allotments',
        title: 'ESTIMATED TOTAL LOTS'
      },
      {
        key: 'completion_percentage',
        title: '% OF PROJECT REMAINING',
        render: (row: TotalSupplyDatum) => {
          // Return "-" if null, '0' if 0
          const value = row.completion_percentage;
          if (value === 0) {
            return '0';
          } else if (value === null) {
            return '-';
          }
          return row.completion_percentage;
        }
      },
      {
        key: 'estimated_completion_date',
        title: 'FORECASTED COMPLETION',
        render: (row: TotalSupplyDatum) => {
          return row.estimated_completion_date
            ? row.estimated_completion_date
            : '-';
        }
      }
    ] as DataColumn<Partial<ProjectStatsTypes>>[]
  },
  {
    report: 'stock_summary',
    defaultSortField: 'price_sold_median',
    defaultSortDir: 'desc',
    columns: [
      {
        key: 'title',
        title: 'PROJECT',
        width: 150,
        render: (row: StockSummaryDatum) => {
          return <ProjectStatIcons {...row} />;
        }
      },
      {
        key: 'size_sold_median',
        title: 'SIZE SOLD'
      },
      {
        key: 'price_sold_median',
        title: 'PRICE SOLD',
        render: (row: StockSummaryDatum) => {
          return row.price_sold_median && formatCurrency(row.price_sold_median);
        }
      },
      {
        key: 'psqm_sold_median',
        title: '$/M2 SOLD'
      },
      {
        key: 'size_available_median',
        title: 'SIZE AVAILABLE'
      },
      {
        key: 'price_available_median',
        title: 'PRICE AVAILABLE',
        render: (row: StockSummaryDatum) => {
          return (
            row.price_available_median &&
            formatCurrency(row.price_available_median)
          );
        }
      },
      {
        key: 'psqm_available_median',
        title: '$/M2 AVAILABLE'
      }
    ] as DataColumn<Partial<ProjectStatsTypes>>[]
  }
];
