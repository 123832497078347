/*
|-------------------------------------------------------------------------------
| API Client
|-------------------------------------------------------------------------------
|
| The singleton API client is created with @rexlabs/api-client. It includes:
|  - Base URL sourced from the env config
|  - Default headers for laravel servers
|  - Middleware (interceptors) for common tasks
|
*/

import { create } from '@rexlabs/api-client';
import config from 'config';
import _ from 'lodash';

const clientConfig = {
  baseURL: config.API_URL
};
const api = create(clientConfig);
api.setHeader('Accept', 'application/json');
api.setHeader('Content-Type', 'application/json');
api.addResponseInterceptor(flattenResponseData);
api.addResponseInterceptor(printError);

function setAuthToken(apiToken) {
  api.setHeader('Authorization', `Bearer ${apiToken}`);
}

function setAccountId(accountId) {
  api.setHeader('X-Account-Id', accountId);
}

export { api, setAuthToken, setAccountId };

/**
 * Flattens res.data.error or res.data.data to res.data. Also hoists
 * res.data.meta to res.meta.
 */
function flattenResponseData(response) {
  if (_.isPlainObject(response.data) && response.data.data) {
    response.pagination = response.data.pagination;
    response.data = response.data.data;
    if (response.data.error) {
      response.data = response.data.error;
    }
  }

  return response;
}

function printError(response) {
  if (response && response.data && response.data.error) {
    const error = new Error(response.data.error.display_message);
    error.code = response.data.error.code;
    console.error(error);
    if (__DEV__) {
      // eslint-disable-next-line
      console.log('API Error:', response.data);
    }
    throw error;
  }
  return response;
}
