import React, { PureComponent } from 'react';
import ActionModal from 'view/modals/action';
import { autobind, decorate } from 'core-decorators';
import _ from 'lodash';

const withActionCore = (propName) => (WrappedComponent) => {
  @autobind
  class WithAction extends PureComponent {
    state = {
      open: false,
      title: 'Oops!',
      submitText: 'Confirm',
      closeText: 'Cancel'
    };

    notify({
      title = 'Oops!',
      message,
      onSubmit = null,
      onClose = null,
      onCancel = null,
      submitText = 'Confirm',
      closeText = 'Cancel',
      red = false
    }) {
      /*
        This function is an improved version of `.open`. Compared to `.open` function,
        this allows us to send an object arg (readable keys) instead of writing a sequence of
        required sequence of args.
      */
      if (__DEV__) {
        console.warn('open action modal', message, title);
      }

      const hasMessage = message && message.message;
      const contentMessage = hasMessage
        ? message.message
        : message || this.state.message;

      this.setState({
        open: true,
        title,
        message: contentMessage,
        onSubmit,
        onClose,
        onCancel,
        submitText,
        closeText,
        red
      });
    }

    open(onSubmit, message, title, red, onClose, onCancel) {
      if (__DEV__) {
        console.warn('open action modal', message, title);
      }
      this.setState({
        open: true,
        title: title || this.state.title,
        onSubmit: onSubmit || null,
        onClose: onClose || null,
        message:
          message && message.message
            ? message.message
            : message || this.state.message,
        red: red || false,
        onCancel: onCancel || null
      });
    }

    close() {
      const { onClose } = this.state;
      if (onClose) {
        onClose();
      }
      this.setState({ open: false });
    }

    @decorate(_.memoize)
    getProps(isOpen) {
      return {
        isOpen,
        notify: this.notify,
        open: this.open,
        close: this.close,
        Action: () =>
          this.state.open && (
            <ActionModal
              title={this.state.title}
              onSubmit={this.state.onSubmit}
              onClose={this.close}
              onCancel={this.state.onCancel}
              submitText={this.state.submitText}
              closeText={this.state.closeText}
              green={!this.state.red}
            >
              {this.state.message}
            </ActionModal>
          )
      };
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...{
            [propName]: this.getProps(this.state.open)
          }}
        />
      );
    }
  }

  return WithAction;
};
const withAction = withActionCore('action');
withAction.withPropName = (propName) => {
  return withActionCore(propName);
};

export default withAction;
