/* eslint-disable max-lines */
import React, {
  Dispatch,
  Fragment,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { omit, sum } from 'lodash';

import { StyleSheet } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import Text from '@rexlabs/text';
import { PLACEMENTS } from '@rexlabs/tooltip';

import { VividTooltipStateful } from 'src/features/reports/components/vivid';
import Divider from 'view/components/divider';
import SelectorCheckbox from 'src/features/reports/components/selector/selector-checkbox';

import { COLORS } from 'src/features/reports/theme';
import { Project, SubRegionWithProjects } from 'types/resource';
import { SubRegionProjectSelectorProps } from './sub-region-project-selector';
import ProjectSelector from './project-selector';
import { Auth0Context } from 'src/auth0-provider';

const tooltipStyles = StyleSheet({
  tooltip: {
    padding: 0,
    width: '205px'
  }
});

const SubRegions = ({
  styles: s,
  selectedProjectsInSubRegion,
  subRegionWithAllProjectsSelected,
  onChange,
  setCancelExit,
  standardSubRegions,
  premiumSubRegions
}: SubRegionProjectSelectorProps & {
  setCancelExit: Dispatch<SetStateAction<boolean>>;
}) => {
  const { hasPermission } = useContext(Auth0Context);
  // This is used so that there will be one tooltip only
  const [activeProjectSelector, setActiveProjectSelector] = useState<
    null | number
  >(null);

  // Sample output: 50 projects (20 selected)
  const getSubText = useCallback(
    (subRegion: SubRegionWithProjects) =>
      `${subRegion.projects.length} project${
        subRegion.projects.length > 1 ? 's' : ''
      }${
        selectedProjectsInSubRegion?.[subRegion.id]?.length > 0
          ? ` (${selectedProjectsInSubRegion[subRegion.id].length} selected)`
          : ''
      }`,
    [selectedProjectsInSubRegion]
  );

  // Disable selecting more projects
  // Max projects selected = 50
  const canAddProjects = useCallback(
    (projectsToAdd: number) => {
      if (!selectedProjectsInSubRegion) return true;
      const projects = Object.keys(selectedProjectsInSubRegion).map(
        (subRegionKey) => selectedProjectsInSubRegion[subRegionKey]?.length ?? 0
      );

      if (sum(projects) + projectsToAdd > 50) return false;

      return true;
    },
    [selectedProjectsInSubRegion]
  );

  const isPremiumSubRegionDisabled = useCallback(
    (subRegion: SubRegionWithProjects) => {
      if (!selectedProjectsInSubRegion) return false;

      // Is already selected
      if (selectedProjectsInSubRegion[subRegion.id]?.length > 0) return false;

      // Disabled when there is a standard sub region selected
      if (
        standardSubRegions.find(
          (subRegion) => selectedProjectsInSubRegion[subRegion.id]?.length > 0
        )
      ) {
        return true;
      }

      // Disabled when selected projects will exceed 50 if the projects will be added
      if (
        !canAddProjects(subRegion.projects.length) &&
        !hasPermission(['reports:ignore-project-limit'])
      ) {
        return true;
      }

      return false;
    },
    [
      canAddProjects,
      selectedProjectsInSubRegion,
      standardSubRegions,
      hasPermission
    ]
  );

  const isStandardSubRegionDisabled = useCallback(
    (subRegion: SubRegionWithProjects) => {
      if (!selectedProjectsInSubRegion) return false;
      // Is already selected
      if (selectedProjectsInSubRegion[subRegion.id]?.length > 0) return false;

      // Disabled when there is a premium sub region selected
      if (
        premiumSubRegions.find(
          (premiumSubRegion) =>
            selectedProjectsInSubRegion[premiumSubRegion.id]?.length
        )
      ) {
        return true;
      }

      // Disabled when there is already a standard sub region selected
      if (
        standardSubRegions.find(
          (standardSubRegion) =>
            selectedProjectsInSubRegion[standardSubRegion.id]?.length > 0
        )
      ) {
        return true;
      }

      // Disabled when selected projects will exceed 50 if the projects will be added
      if (!canAddProjects(subRegion.projects.length)) return true;

      return false;
    },
    [
      selectedProjectsInSubRegion,
      canAddProjects,
      premiumSubRegions,
      standardSubRegions
    ]
  );

  const isPremiumSubRegionProjectDisabled = useCallback(
    (subRegion: SubRegionWithProjects, project: Project) => {
      if (!selectedProjectsInSubRegion) return false;

      // Is already selected
      if (selectedProjectsInSubRegion[subRegion.id]?.includes(project.id)) {
        return false;
      }

      // Disabled when there is a standard sub region selected
      if (
        standardSubRegions.find(
          (subRegion) => selectedProjectsInSubRegion[subRegion.id]?.length > 0
        )
      ) {
        return true;
      }

      // Disabled when selected projects will exceed 50 if the project will be added
      if (
        !canAddProjects(1) &&
        !hasPermission(['reports:ignore-project-limit'])
      ) {
        return true;
      }

      return false;
    },
    [
      canAddProjects,
      selectedProjectsInSubRegion,
      standardSubRegions,
      hasPermission
    ]
  );

  const hasReachLimit = useMemo(() => {
    // If the selected sub region is premium
    // The user can select more than 50 if the user has this permission 'reports:ignore-project-limit'
    if (
      premiumSubRegions.find(
        (premiumSubRegion) =>
          selectedProjectsInSubRegion?.[premiumSubRegion.id]?.length
      ) &&
      hasPermission(['reports:ignore-project-limit'])
    ) {
      return false;
    }

    return !canAddProjects(1);
  }, [
    premiumSubRegions,
    hasPermission,
    canAddProjects,
    selectedProjectsInSubRegion
  ]);

  // Toggle handler when sub region is clicked
  const onToggleSubRegion = (subRegionId: number) => {
    let newSelectedProjects = selectedProjectsInSubRegion;
    let newAllProjects = subRegionWithAllProjectsSelected;
    // Deselect all projects of the sub region
    if (selectedProjectsInSubRegion?.[subRegionId]?.length > 0) {
      newSelectedProjects = omit(newSelectedProjects, subRegionId);
      newAllProjects = newAllProjects.filter((id) => id !== subRegionId);
    } else {
      const subRegion = [...premiumSubRegions, ...standardSubRegions].find(
        (subRegion) => subRegion.id === subRegionId
      );

      // Add project to selected projects in the sub region
      newSelectedProjects = {
        ...selectedProjectsInSubRegion,
        [subRegionId]: subRegion.projects.map((project) => project.id)
      };
      newAllProjects = [...newAllProjects, subRegionId];
    }

    onChange(newSelectedProjects, newAllProjects);
  };

  // Toggle handler when a project is selected in a premium sub region
  const onToggleProject = (subRegionId: number, projectId: number) => {
    let newSelectedProjects = selectedProjectsInSubRegion;
    let newAllProjects = subRegionWithAllProjectsSelected;
    const subRegion = selectedProjectsInSubRegion[subRegionId];
    // Remove project
    if (subRegion?.includes(projectId)) {
      newSelectedProjects = {
        ...selectedProjectsInSubRegion,
        [subRegionId]: subRegion.filter(
          (prevProjectId) => projectId !== prevProjectId
        )
      };
      if (newSelectedProjects[subRegionId].length === 0) {
        newSelectedProjects = omit(newSelectedProjects, subRegionId);
      }
      newAllProjects = newAllProjects.filter((id) => id !== subRegionId);
    } else {
      // Add project
      newSelectedProjects = {
        ...selectedProjectsInSubRegion,
        [subRegionId]: subRegion ? [...subRegion, projectId] : [projectId]
      };
    }

    onChange(newSelectedProjects, newAllProjects);
  };

  return (
    <Box {...s('subRegionWrapper', 'scroll')}>
      <Box {...s('scrollWrapper')}>
        {premiumSubRegions.length > 0 && (
          <Fragment>
            <Box {...s('sectionTitleWrapper')}>
              <Text {...s('sectionTitle')} is="span">
                PREMIUM REGIONS
              </Text>
            </Box>
            {premiumSubRegions.map((subRegion) =>
              activeProjectSelector === subRegion.id ? (
                <VividTooltipStateful
                  key={subRegion.id}
                  styles={tooltipStyles}
                  distance="0px"
                  placement={PLACEMENTS.RIGHT_START}
                  openOn="HOVER"
                  closeOn="HOVER"
                  hoverTimeout={50}
                  closeDuration={0}
                  cancelExit={activeProjectSelector === subRegion.id}
                  Content={ProjectSelector}
                  contentProps={{
                    subRegion,
                    styles: s,
                    selectedProjectsInSubRegion,
                    setCancelExit,
                    isPremiumSubRegionProjectDisabled,
                    onToggleProject,
                    onClose: () => setActiveProjectSelector(null)
                  }}
                >
                  <Box width="254px">
                    <SelectorCheckbox
                      key={subRegion.id}
                      disabled={isPremiumSubRegionDisabled(subRegion)}
                      checked={
                        selectedProjectsInSubRegion?.[subRegion.id]?.length > 0
                      }
                      onClick={() => onToggleSubRegion(subRegion.id)}
                      useSubtract
                      withArrow
                      label={subRegion.name}
                      subText={getSubText(subRegion)}
                    />
                  </Box>
                </VividTooltipStateful>
              ) : (
                <Box
                  width="254px"
                  onMouseEnter={() => setActiveProjectSelector(subRegion.id)}
                >
                  <SelectorCheckbox
                    key={subRegion.id}
                    disabled={isPremiumSubRegionDisabled(subRegion)}
                    checked={
                      selectedProjectsInSubRegion?.[subRegion.id]?.length > 0
                    }
                    onClick={() => onToggleSubRegion(subRegion.id)}
                    useSubtract
                    withArrow
                    label={subRegion.name}
                    subText={getSubText(subRegion)}
                  />
                </Box>
              )
            )}
          </Fragment>
        )}
        {standardSubRegions.length > 0 && (
          <Fragment>
            <Box {...s('sectionTitleWrapper')}>
              <Text {...s('sectionTitle')} is="span">
                STANDARD REGIONS
              </Text>
            </Box>
            {standardSubRegions.map((subRegion) =>
              activeProjectSelector === subRegion.id ? (
                <VividTooltipStateful
                  key={subRegion.id}
                  styles={tooltipStyles}
                  distance="0px"
                  placement={PLACEMENTS.RIGHT_START}
                  openOn="HOVER"
                  closeOn="HOVER"
                  hoverTimeout={50}
                  closeDuration={0}
                  cancelExit={activeProjectSelector === subRegion.id}
                  Content={ProjectSelector}
                  contentProps={{
                    subRegion,
                    styles: s,
                    selectedProjectsInSubRegion,
                    setCancelExit,
                    onToggleProject,
                    onClose: () => setActiveProjectSelector(null)
                  }}
                >
                  <Box width="254px">
                    <SelectorCheckbox
                      disabled={isStandardSubRegionDisabled(subRegion)}
                      checked={
                        selectedProjectsInSubRegion?.[subRegion.id]?.length > 0
                      }
                      onClick={() => onToggleSubRegion(subRegion.id)}
                      useSubtract
                      withArrow
                      label={subRegion.name}
                      subText={getSubText(subRegion)}
                    />
                  </Box>
                </VividTooltipStateful>
              ) : (
                <Box
                  width="254px"
                  onMouseEnter={() => setActiveProjectSelector(subRegion.id)}
                >
                  <SelectorCheckbox
                    disabled={isStandardSubRegionDisabled(subRegion)}
                    checked={
                      selectedProjectsInSubRegion?.[subRegion.id]?.length > 0
                    }
                    onClick={() => onToggleSubRegion(subRegion.id)}
                    useSubtract
                    withArrow
                    label={subRegion.name}
                    subText={getSubText(subRegion)}
                  />
                </Box>
              )
            )}
          </Fragment>
        )}
      </Box>
      <Divider />
      <Box {...s('noteWrapper')}>
        <p
          {...s.with('note')({
            color: hasReachLimit ? COLORS.RED.PRIMARY : COLORS.GREY.MEDIUM
          })}
        >
          {hasReachLimit
            ? 'You have reached the maximum number of projects allowed in a report (50 projects). Please adjust your selection and try again.'
            : 'Maximum one standard subregion or 50 projects from premium subregions can be selected at a time.'}
        </p>
      </Box>
    </Box>
  );
};

export default memo(SubRegions);
