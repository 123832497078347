import React from 'react';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { COLORS, FONT } from 'src/features/reports/theme';

const styles = StyleSheet({
  gradient: {
    width: '100%',
    height: '25px',
    background:
      'linear-gradient(90deg, rgba(41, 204, 151, 0.75) 0%, rgba(252, 232, 150, 1) 52.6%, rgba(247, 74, 60, 0.75) 100%)',
    marginBottom: '24px'
  },
  label: {
    fontWeight: 500,
    fontSize: '12px',
    color: COLORS.BLUE.DARK,
    opacity: 0.4,
    letterSpacing: '0.05em',
    fontFamily: FONT.FAMILIES.DEFAULT
  }
});

interface HeatIndicatorProps {
  styles: Styles;
  lowestHeat: string;
  averageHeat?: string;
  highestHeat: string;
}

const HeatIndicator = ({
  styles: s,
  lowestHeat,
  averageHeat,
  highestHeat
}: HeatIndicatorProps) => {
  return (
    <Box m="24px auto 0" width="341px">
      <div {...s('gradient')} />
      <Box width="100%" flexDirection="row" justifyContent="space-between">
        <span {...s('label')}>{lowestHeat}</span>
        {averageHeat && <span {...s('label')}>{averageHeat}</span>}
        <span {...s('label')}>{highestHeat}</span>
      </Box>
    </Box>
  );
};

export default styled(styles)(HeatIndicator);
