import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const CircleCheckOutline = (props: SVGProps<null>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.6667 11.0234L16 17.6668L13.5 15.1668"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0006 9.00829C16.2087 8.4548 14.2672 8.67409 12.6439 9.61329C10.553 10.712 9.1125 12.7433 8.76726 15.08C8.34655 18.9751 11.0839 22.5046 14.9611 23.0665C18.8384 23.6283 22.465 21.021 23.1673 17.1666"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="9"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleCheckOutline;
