import React, { Component, Fragment } from 'react';
import { StyleSheet, styled } from '@rexlabs/styling';
import { COLORS, SHADOWS, PADDINGS, BORDER_RADIUS } from 'src/theme';
import { Portal } from '@rexlabs/portal';
import _ from 'lodash';
import worksheetModel from 'data/models/entities/setting-worksheets';
import statsModel from 'data/models/entities/stats';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import ReportTitle from 'view/components/report-title';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import Definitions from '../../components/reports/definitions';
import withValidate from 'view/containers/with-validate-setting';
import {
  getSettingsQuery,
  periodsQuery,
  projectsQuery
} from 'data/queries/settings';
import { getSettingsId, getToken, getPeriod } from 'utils/reports';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  }
});

const worksheetQuery = query`{
  ${worksheetModel} (settingId: ${getSettingsId}, period: ${getPeriod}, token: ${getToken}) {
        id
        project_id
        supply_issue
        no_price_list
        notes
    }
}`;

@withReportSidebar()
@styled(defaultStyles)
@withQuery(projectsQuery)
@withQuery(worksheetQuery)
@withQuery(getSettingsQuery(getSettingsId, getToken))
@withQuery(periodsQuery)
@withModel(statsModel)
@withValidate
class DefinitionsScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingPeriod: true,
      fetchingPrimary: true,

      period: {},
      primary: {}
    };

    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    const { whereabouts, settingProjects } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (!_.get(query, 'period') && !_.get(query, 'token')) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    settingProjects
      .fetchPrimary({
        settingId: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token')
        }
      })
      .then((res) => {
        this.setState({
          fetchingPrimary: false,
          primary: res.data
        });
      })
      .catch(this.handleError);
  }

  loading() {
    const { settingProjects, settingWorksheets, periods } = this.props;
    const { fetchingPrimary } = this.state;

    const arr = [
      fetchingPrimary,
      _.get(periods, 'list.status') !== 'loaded',
      _.get(settingProjects, 'list.status') !== 'loaded',
      _.get(settingWorksheets, 'list.status') !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? acc + 1 : acc)) || 0;
  }

  render() {
    const { settingProjects, settingWorksheets, settings, periods } =
      this.props;
    const { primary } = this.state;

    const loading = this.loading();
    const maxRequests = 4;
    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const projectList = _.sortBy(_.get(settingProjects, 'list.items'), (item) =>
      item.id === parseInt(primary.id) ? 0 : 1
    );

    const worksheets = _.get(settingWorksheets, 'list.items');
    const isGuest = !!getToken(this.props);
    const period = _.get(periods, 'list.items.0');

    return (
      <Fragment>
        <Portal target="header">
          <ReportTitle
            period={period}
            project={primary}
            settings={_.get(settings, 'item.data')}
          />
        </Portal>

        <Definitions
          projects={projectList}
          worksheets={worksheets}
          primary={primary}
        />

        <ReportFooter
          prevText={isGuest ? 'Estate Detailed Stats' : 'Pricelists'}
          prevRoute={
            isGuest
              ? ROUTES.APP.REPORT_SETTINGS_DETAILED
              : ROUTES.APP.REPORT_SETTINGS_PRICELIST
          }
          settingId={getSettingsId(this.props)}
        />
      </Fragment>
    );
  }
}

export default DefinitionsScreen;
