import React, { useContext } from 'react';

import { PagePermission } from 'src/features/reports/hoc';
import { ProductPriceMovement } from 'src/features/reports/components/product-price-movement';
import { ProductTypePerformance } from 'src/features/reports/components/product-type-performance';
import { MarketShare } from '../components/market-share-frontage-depth';
import { ReportSettingsContext } from '../providers/report-settings-provider';
import { TRENDS_PAGE } from '../constants';

function ReportProductTrends() {
  const { subRegionsPermissions } = useContext(ReportSettingsContext);

  return (
    <PagePermission permissionsNeeded={[...subRegionsPermissions, TRENDS_PAGE]}>
      <ProductPriceMovement />
      <ProductTypePerformance />
      <MarketShare />
    </PagePermission>
  );
}

export default ReportProductTrends;
