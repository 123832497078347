import React, { ReactNode } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, Styles, styled } from '@rexlabs/styling';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { VividTooltipStateful } from 'src/features/reports/components/vivid';
import { InfoCircleOutline } from 'src/features/reports/components/icons';
import { COLORS } from 'src/features/reports/theme';

export interface InfoTooltipProps {
  title: string;
  description: ReactNode;
  styles: Styles;
}

const defaultStyles = StyleSheet({
  content: {
    width: 'auto',
    minWidth: 100,
    maxWidth: 700,
    padding: 5
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: 'DM Sans',
    color: COLORS.BLUE.DARK,
    marginBottom: 16
  },
  description: {
    fontSize: 14,
    fontFamily: 'DM Sans',
    color: COLORS.BLUE.DARK,
    '& .bold': {
      fontWeight: 'bold'
    },
    '& .italic': {
      fontStyle: 'italic'
    }
  },
  infoTooltip: {
    marginTop: 6
  }
});

const Content = styled(defaultStyles)(
  ({ title, description, styles: s }: InfoTooltipProps) => {
    return (
      <Box {...s('content')}>
        <p {...s('title')}>{title}</p>
        <p {...s('description')}>{description}</p>
      </Box>
    );
  }
);

const InfoTooltip = (props: InfoTooltipProps) => {
  return (
    <VividTooltipStateful
      distance={5}
      Arrow={null}
      openOn="HOVER"
      closeOn="HOVER"
      offset={250}
      placement={PLACEMENTS.BOTTOM}
      Content={Content}
      contentProps={props}
    >
      <InfoCircleOutline {...props.styles('infoTooltip')} />
    </VividTooltipStateful>
  );
};

export default styled(defaultStyles)(InfoTooltip);
