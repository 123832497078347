import React from 'react';
import _ from 'lodash';
import VividCheckbox from '@rexlabs/checkbox';
import Box from '@rexlabs/box';
import Text from '@rexlabs/text';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import { FONT, MARGINS, COLORS } from 'src/features/reports/theme';
import { Tiny } from 'view/components/text';
import Subtract from 'src/assets/selectors/subtract.svg';
import RightArrow from 'src/assets/selectors/right-arrow.svg';
import Check from 'src/assets/selectors/check.svg';

const getCheckboxStyles = _.memoize(
  ({ useSubtract }: { useSubtract: boolean }) =>
    StyleSheet({
      input: {
        '& + label': {
          margin: `0 ${MARGINS.XXS} 0 0`
        },
        '&:checked + label': {
          '&:before': {
            display: useSubtract ? 'none' : 'initial'
          }
        }
      }
    })
);

const styles = StyleSheet({
  container: {
    position: 'relative',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: COLORS.GREY.POWDER
    }
  },
  label: {
    fontWeight: FONT.WEIGHTS.SEMIBOLD,
    fontFamily: FONT.FAMILIES.DEFAULT,
    marginTop: '0.2rem'
  },
  secondaryText: {
    fontSize: '1rem',
    fontFamily: FONT.FAMILIES.DEFAULT,
    opacity: 0.6
  }
});

interface SelectorCheckboxProps {
  styles: Styles;
  checked: boolean;
  label: string;
  disabled?: boolean;
  /**
   * Subtext that is rendered below the label
   */
  subText?: string;
  /**
   * This will render the right arrow which will be used when there is nested popout
   */
  withArrow?: boolean;
  /**
   * This will used the check icon instead of the vivid checkbox
   */
  withoutCheckbox?: boolean;
  /**
   * This will replace the check icon of the checkbox to '-'
   */
  useSubtract?: boolean;
  /**
   * Triggered when checkbox is clicked
   */
  onClick?: () => void;
}

const SelectorCheckbox = ({
  styles: s,
  checked,
  label,
  subText,
  withArrow,
  withoutCheckbox,
  useSubtract,
  onClick,
  disabled
}: SelectorCheckboxProps) => {
  return (
    <Box
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        if (disabled) return;
        onClick?.();
      }}
      {...s('container')}
    >
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        padding="8px 16px"
        style={{ opacity: disabled ? 0.6 : 1 }}
      >
        <Box flexDirection="row" alignItems="center">
          {!withoutCheckbox && (
            <VividCheckbox
              checkIcon={useSubtract && checked ? <Subtract /> : undefined}
              styles={getCheckboxStyles({ useSubtract })}
              value={checked}
              type="checkbox"
            />
          )}
          <Box flexDirection="column">
            <Tiny {...s('label')}>{label}</Tiny>
            {subText && <Text {...s('secondaryText')}>{subText}</Text>}
          </Box>
        </Box>
        {withArrow && <RightArrow />}
        {withoutCheckbox && checked && <Check />}
      </Box>
    </Box>
  );
};

export default styled(styles)(SelectorCheckbox);
