import React from 'react';
import FeaturedBox from '../panel/featured-box';
import { CATEGORIES } from './filters';

export type CategoryKey = typeof CATEGORIES[number]['key'];

interface CategoriesProps {
  selected: CategoryKey;
  onClick: (key: CategoryKey) => void;
}

const Categories = ({ selected, onClick }: CategoriesProps) => {
  return (
    <>
      {CATEGORIES.map((category) => (
        <FeaturedBox
          key={category.key}
          active={category.key === selected}
          onClick={() => onClick(category.key)}
          label={category.label}
          value={category.value}
        />
      ))}
    </>
  );
};

export default Categories;
