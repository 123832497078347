import React, { useContext } from 'react';

import { PagePermission } from 'src/features/reports/hoc';
import ProjectPerformanceOverview from 'src/features/reports/components/project-performance-overview';
import { ProjectPriceRange } from 'src/features/reports/components/project-price-range';
import { PricingMatrix } from 'src/features/reports/components/pricing-matrix';
import { ReportSettingsContext } from '../providers/report-settings-provider';
import { POSITIONING_PAGE } from '../constants';

function ReportProjectPositioning() {
  const { subRegionsPermissions } = useContext(ReportSettingsContext);

  return (
    <PagePermission
      permissionsNeeded={[...subRegionsPermissions, POSITIONING_PAGE]}
    >
      <ProjectPerformanceOverview />
      <ProjectPriceRange />
      <PricingMatrix />
    </PagePermission>
  );
}

export default ReportProjectPositioning;
