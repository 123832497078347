import React, { CSSProperties, useState } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import _ from 'lodash';
import { COLORS, PADDINGS } from 'theme';
import { Heading, SubHeading, Body, Tiny } from 'view/components/text';
import ActionMenu from '@rexlabs/action-menu';
import { DownArrow, UpArrow } from 'view/components/arrow';
import ReportBox from 'view/components/summary/report-box';
import SectionContainer from 'view/components/section-container';
import { properCase } from 'utils/format';
import { Project } from 'types';
import GA from 'react-ga';

export interface YTDProps {
  projects: Project[];
  primaryProject?: Project;
  yearToDate: any;
  period: 'period' | 'financial';
}

interface DecoratorProps {
  styles: any;
}

const defaultStyles: Record<string, CSSProperties> = {
  container: {
    minWidth: '35rem'
  },
  reportRow: {
    borderBottom: `1px solid ${COLORS.GREY.MEDIUM}`,
    padding: `${PADDINGS.XS} 0`
  }
};

const YearToDate = ({
  styles: s,
  projects,
  primaryProject,
  yearToDate,
  period
}: YTDProps & DecoratorProps) => {
  // Default to the primary project.
  // Use the first project in the list if there is no primary.
  const [currentProject, setCurrentProjectValue] = useState<number>(
    primaryProject?.id ?? projects[0]?.id
  );
  const setCurrentProject = (project: Project) => {
    GA.event({
      category: 'Estate Detailed Stats',
      action: `Toggle ${properCase(period)} YTD Estate`,
      label: project.title.toLowerCase()
    });
    setCurrentProjectValue(project.id);
  };

  const supplyIssue = yearToDate[currentProject]?.[period].supply_issue;

  return (
    <>
      <SectionContainer
        style={{ flex: '1 1 0', minWidth: '35rem' }}
        p={PADDINGS.S}
        m={PADDINGS.XXS}
      >
        <Box
          flex={1}
          flexDirection={'row'}
          flexWrap={'nowrap'}
          justifyContent={'space-between'}
        >
          <SubHeading>{properCase(period)} YTD</SubHeading>
          <Box>
            <ActionMenu
              Button={(button) => (
                <Body
                  style={{
                    cursor: 'pointer',
                    color: COLORS.GREY.DARK,
                    userSelect: 'none'
                  }}
                >
                  {_.get(
                    _.find(projects, {
                      id: currentProject
                    }),
                    'title',
                    ''
                  )}
                  &nbsp;
                  {button.isOpen ? <UpArrow /> : <DownArrow />}
                </Body>
              )}
              items={_.map(projects, (project) => ({
                label:
                  project.id === currentProject ? (
                    <span style={{ color: COLORS.BLUE_GREEN }}>
                      {project.title}
                    </span>
                  ) : (
                    project.title
                  ),
                onClick: () => setCurrentProject(project)
              }))}
            />
          </Box>
        </Box>

        <Box
          flex={1}
          flexDirection={'row'}
          flexWrap={'noWrap'}
          {...s('reportRow')}
        >
          <ReportBox title={'Total Gross'}>
            <Heading>
              {_.get(yearToDate, `${currentProject}.${period}.total_gross`, 0)}
            </Heading>
          </ReportBox>

          <ReportBox title={'Average Gross'}>
            <Heading>
              {_.get(yearToDate, `${currentProject}.${period}.average_gross`) ||
                '0'}
              {supplyIssue && '^'}
            </Heading>
          </ReportBox>
        </Box>

        <Box
          flex={1}
          flexDirection={'row'}
          flexWrap={'noWrap'}
          {...s('reportRow')}
        >
          <ReportBox title={'Total Net'}>
            <Heading>
              {_.get(yearToDate, `${currentProject}.${period}.total_net`, 0)}
            </Heading>
          </ReportBox>

          <ReportBox title={'Average Net'}>
            <Heading>
              {_.get(yearToDate, `${currentProject}.${period}.average_net`) ||
                '0'}
              {supplyIssue && '^'}
            </Heading>
          </ReportBox>
        </Box>

        <Box pt={PADDINGS.S} pb={PADDINGS.S}>
          <ReportBox title={'Back on Market'}>
            <Heading>
              {_.get(
                yearToDate,
                `${currentProject}.${period}.contract_fallovers`,
                '0'
              )}
            </Heading>
          </ReportBox>
        </Box>
        <Box>
          <Tiny>^ = Supply Issue</Tiny>
        </Box>
      </SectionContainer>
    </>
  );
};

export default styled(StyleSheet(defaultStyles))(YearToDate);
