import React from 'react';

const InfoTooltip = () => {
  return (
    <>
      The most popular sold frontages and depth are displayed in this table.
      Exact match or a range can be selected.
      <br />
      <br />
      To view a longer period of time, the{' '}
      <span className="bold">date slider can be dragged</span> to the left.
      <br />
      <br />
      When using the date slider the ‘available’ columns remain static with the
      current period selected.{' '}
      <span className="bold">
        This allows the assessment of over/under supplied typologies.
      </span>
      <br />
      <br />
      The dropdown lists at the bottom of the table allow you to select a
      specific project/s - displaying a{' '}
      <span className="bold">live side-by-side comparison</span> of your chosen
      data set.
    </>
  );
};

export default InfoTooltip;
