import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Grid, Column } from '@rexlabs/grid';
import { Small } from '../text';
import { Check as Checkbox } from 'view/components/checkbox';
import projectsModel from 'data/models/entities/projects';
import { withModel } from '@rexlabs/model-generator';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  text: {
    fontWeight: '500',
    color: COLORS.GREY.DARK
  },
  link: {
    cursor: 'pointer'
  },
  container: {
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
  },
  grid: {
    '> *': {
      padding: '1rem 2rem'
    }
  }
});

@withModel(projectsModel)
@styled(defaultStyles)
class ProjectListHeader extends PureComponent {
  render() {
    const {
      styles: s,
      handleOrderByName,
      handleOrderByRegion,
      handleOrderByDate,
      onSelectAll,
      checked
    } = this.props;
    return (
      <Box {...s('container')}>
        <Grid
          gutter={0}
          columns={24}
          style={{ alignItems: 'center' }}
          {...s('grid')}
        >
          <Column width={1} onClick={onSelectAll}>
            <Checkbox controlled={true} value={!!checked} />
          </Column>
          <Column width={4}>
            <Small onClick={handleOrderByName} {...s('text', 'link')}>
              Project Name
            </Small>
          </Column>
          <Column width={4}>
            <Small onClick={handleOrderByRegion} {...s('text', 'link')}>
              Sub-Region
            </Small>
          </Column>
          <Column width={4}>
            <Small onClick={handleOrderByDate} {...s('text', 'link')}>
              Date Created
            </Small>
          </Column>
          <Column width={4}>
            <Small {...s('text')}>Project Status</Small>
          </Column>
          <Column width={6}>
            <Small {...s('text')}>Summary</Small>
          </Column>
          <Column width={1} />
        </Grid>
      </Box>
    );
  }
}

export default ProjectListHeader;
