import React, { PureComponent } from 'react';
import { Modal } from 'view/components/modal';
import StageForm from 'view/forms/stage';

class StageModal extends PureComponent {
  render() {
    return (
      <Modal width={500} hasButtonBar visibleOverflow>
        <StageForm title="Create Stage" {...this.props} />
      </Modal>
    );
  }
}

export default StageModal;
