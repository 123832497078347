import React, { PureComponent, Fragment } from 'react';
import Box from '@rexlabs/box';
import { StyleSheet, styled } from '@rexlabs/styling';
import { COLORS, SHADOWS, PADDINGS, BORDER_RADIUS } from 'src/theme';
import { Heading, Small } from 'view/components/text';
import _ from 'lodash';
import Table from 'view/components/table';
import Collapsible from 'view/components/collapsible';

const defaultStyles = StyleSheet({
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    margin: PADDINGS.XXS,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  }
});

@styled(defaultStyles)
class Definitions extends PureComponent {
  render() {
    const { styles: s, projects, worksheets, primary } = this.props;

    const projectList = _.sortBy(projects, (item) =>
      item.id === parseInt(primary.id) ? 0 : 1
    );

    const specifications = _.map(worksheets, (worksheet) => {
      return {
        id: worksheet.id,
        supply_issue: worksheet.supply_issue,
        price_list_received: !worksheet.no_price_list,
        notes: worksheet.notes,
        title: _.find(
          projectList,
          (project) => project.id === worksheet.project_id
        ).title
      };
    });

    const characteristics = [
      {
        characteristic: 'Corner',
        abbreviation: 'CNR',
        removed: 'No'
      },
      {
        characteristic: 'Park / Wetlands',
        abbreviation: 'PRK',
        removed: 'No'
      },
      {
        characteristic: 'Substation',
        abbreviation: 'SUB',
        removed: 'No'
      },
      {
        characteristic: 'Powerlines',
        abbreviation: 'PWL',
        removed: 'No'
      },
      {
        characteristic: 'Irregular',
        abbreviation: 'IRR',
        removed: 'No'
      },
      {
        characteristic: 'Nearby Amenity',
        abbreviation: 'AME',
        removed: 'No'
      },
      {
        characteristic: 'Fall',
        abbreviation: 'FAL',
        removed: 'No'
      },
      {
        characteristic: 'Fill',
        abbreviation: 'FIL',
        removed: 'No'
      },
      {
        characteristic: 'Double Storey',
        abbreviation: 'DST',
        removed: 'No'
      }
    ];

    return (
      <Fragment>
        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <Heading>Report Specifications</Heading>
          </Box>
          <Table
            data={specifications}
            columns={[
              {
                Header: () => (
                  <p style={{ color: COLORS.BLUE_GREEN }}>Estate</p>
                ),
                accessor: 'title',
                fixed: 'left',
                width: 250,
                style: {
                  background: COLORS.BLUE.LIGHT,
                  boxShadow: '4px 0px 3px -3px #ccc',
                  border: 'none !important'
                }
              },
              {
                Header: 'Price List Received',
                Cell: (row) => {
                  return (
                    <span style={{ textTransform: 'capitalize' }}>
                      {_.get(row, 'original.price_list_received').toString()}
                    </span>
                  );
                },
                width: 150
              },
              {
                Header: 'Supply Issue^',
                Cell: (row) => {
                  return (
                    <span style={{ textTransform: 'capitalize' }}>
                      {_.get(row, 'original.supply_issue').toString()}
                    </span>
                  );
                },
                width: 150
              },
              {
                Header: 'Notes',
                Cell: () => '-',
                width: 350
              }
            ]}
            pageSize={specifications.length}
          />
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <Heading>Characteristic Abbreviations</Heading>
          </Box>
          <Table
            data={characteristics}
            columns={[
              {
                Header: () => (
                  <p style={{ color: COLORS.BLUE_GREEN }}>Characteristic</p>
                ),
                accessor: 'characteristic',
                fixed: 'left',
                width: 150,
                style: {
                  background: COLORS.BLUE.LIGHT,
                  boxShadow: '4px 0px 3px -3px #ccc',
                  border: 'none !important'
                }
              },
              {
                Header: 'Abbreviation',
                accessor: 'abbreviation',
                width: 150
              },
              {
                Header: 'Removed from Analysis',
                accessor: 'removed',
                width: 150
              }
            ]}
            pageSize={characteristics.length}
          />
        </Box>

        <Box {...s('sectionContainer')}>
          <Box p={PADDINGS.S}>
            <Heading>Definitions</Heading>
            <Collapsible
              items={[
                {
                  header: 'Available Allotment Data Analysis',
                  content:
                    'Any allotment that is not marked as SOLD is considered AVAILABLE for the purposes of assessing supply. Calculations therefore include lot status: Available, Hold, Deposit, Back on Market'
                },
                {
                  header: 'Standard Lot Size Price Change',
                  content:
                    'Average price of size category this month MINUS average price of size category in the previous month'
                },
                {
                  header: 'No Price List Received*',
                  content:
                    '0 sales figure are still counted toward averages because when a price list is obtained sales figures may be artificially inflated in proceeding months'
                },
                {
                  header: 'Supply Issue^',
                  content: (
                    <Box flex={1} style={{ flexDirection: 'column' }}>
                      <Small>
                        Data has been excluded in Average Sales Rates = ^
                      </Small>
                      <Small>
                        Estate^ = Current worksheet has a supply issue
                      </Small>
                      <Small>
                        3 or 12 month average ^ = Project specific long term
                        average has been affected by a supply issue within the
                        last 12 month.
                      </Small>
                      <Small>
                        This is selected when supply issue causing sales figures
                        of 0 may artificially effect estate specific and overall
                        sales averages. It is selected when an estate is tail
                        ending or has stopped selling for an extended period of
                        time.
                      </Small>
                      <Box p={PADDINGS.TINY}>
                        <Small>
                          Tail Ending = less than 10 allotments remain
                        </Small>
                        <Small>
                          Extended Period of Time = 3 or more months off market
                        </Small>
                      </Box>
                    </Box>
                  )
                },
                {
                  header: 'Months Supply',
                  content: (
                    <Box flex={1} style={{ flexDirection: 'column' }}>
                      <Small>
                        Available Allotments at end of month divided by Gross
                        overall sales this month
                      </Small>
                      <Box p={PADDINGS.TINY}>
                        <Small>0-2 Months Hot Market</Small>
                        <Small>3-5 Months Healthy Market</Small>
                        <Small>6+ Months Oversupply</Small>
                      </Box>
                    </Box>
                  )
                },
                {
                  header: 'Forecast Completion Date',
                  content: (
                    <Small>
                      Lots Remaining{' '}
                      <span style={{ textDecoration: 'underline' }}>
                        divided by
                      </span>{' '}
                      Ave gross sales / Month (last 12 months)
                    </Small>
                  )
                },
                {
                  header: 'Back On Market (BOM)',
                  content:
                    'This status is applied when an allotment previously considered SOLD returns to the market'
                },
                {
                  header: 'Not For Sale (NFS)',
                  content:
                    'This allotment status is ignored by all calculations'
                }
              ]}
            />
          </Box>
        </Box>
      </Fragment>
    );
  }
}

export default Definitions;
