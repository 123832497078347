import React from 'react';
import ReactSelect from 'react-select';
import { COLORS } from 'src/theme';
import chroma from 'chroma-js';

// `colourStyles` can be used when you want to add color themes to your select options.
// please note that you need to add `color` property for this to work. { ...., color: 'red' }
export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    const transparentColor = color.alpha(0.1).css();

    // For background color
    const baseColor = isSelected
      ? data.color
      : isFocused
      ? transparentColor
      : undefined;
    const backgroundColor = !isDisabled ? baseColor : undefined;
    const activeColor = !isDisabled
      ? isSelected
        ? data.color
        : color.alpha(0.3).css()
      : undefined;

    // For font color
    // checks if the background color is too dark, then we use white, vice versa.
    const isBgTooDark = chroma.contrast(color, COLORS.WHITE) > 2;
    const baseFontColor = isBgTooDark ? COLORS.WHITE : COLORS.BLACK;
    const selectedColor = isSelected ? baseFontColor : data.color;
    const fontColor = isDisabled ? COLORS.GREY.MEDIUM_DARK : selectedColor;

    return {
      ...styles,
      backgroundColor,
      color: fontColor,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: activeColor
      }
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css()
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white'
    }
  })
};

const Select = (props) => (
  <ReactSelect
    styles={{
      control: (inherited, { isFocused }) => {
        return {
          ...inherited,
          minHeight: '4.2rem',
          boxShadow: 'none',
          borderColor: isFocused ? COLORS.BLUE_GREEN : COLORS.GREY.LIGHT,
          '&:hover': {
            borderColor: isFocused ? COLORS.BLUE_GREEN : COLORS.GREY.LIGHT
          }
        };
      }
    }}
    {...props}
  />
);

export default Select;
