import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
import types from 'prop-types';

import Box from '@rexlabs/box';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { styled, StyleSheet } from '@rexlabs/styling';
import { TextInput } from '@rexlabs/text-input';
import { withModel, withQuery, query } from '@rexlabs/model-generator';

import PrimaryButton from 'view/components/button/primary';
import ButtonBar from 'view/components/modal/button-bar';
import Select from 'view/components/react-select';
import { FormField, Form, ReactForms } from 'view/components/form';
import { SelectInput } from 'view/components/input/select';
import { Heading, Body, Label } from 'view/components/text';

import projectsModel from 'data/models/entities/projects';
import settingsModel from 'data/models/entities/settings';
import { PADDINGS } from 'src/theme';

const projectsQuery = query`{
  ${projectsModel} (include: "sub_region", published: true) {
    id
    title
    status
    summary
    sub_region
  }
}`;

const defaultStyles = StyleSheet({});

@withQuery(projectsQuery)
@styled(defaultStyles)
@withModel(settingsModel)
class EditSettingsForm extends PureComponent {
  static propTypes = {
    onClose: types.func.isRequired,
    onSubmit: types.func.isRequired,
    settingId: types.number.isRequired
  };

  state = {
    selectedItems: [],
    loading: true
  };

  componentDidMount() {
    const { settings, settingId } = this.props;

    // withQuery wasn't working so I manually had to make the API calls.
    // TODO: Fix the queries
    settings
      .fetchItem({
        id: settingId,
        args: {
          include: 'projects, primary_project'
        }
      })
      .then((res) => {
        const items = _(_.get(res, 'data.projects.data'))
          .map((item) => {
            const project = item;
            return {
              id: _.get(project, 'id'),
              value: _.get(project, 'id'),
              label: _.get(project, 'title')
            };
          })
          .value();
        this.setState({
          setting: _.get(res, 'data'),
          primary: _.get(res, 'data.primary_project'),
          selectedItems: items,
          loading: false
        });
      });
  }

  submitForm = (values, props) => {
    const { settings, settingId, onClose, onSubmit } = this.props;
    const { selectedItems } = this.state;

    const primaryId = _.get(values, 'primary');
    const projectIds = _.map(selectedItems, (item) => item.id);

    return settings
      .patchItem({
        id: settingId,
        name: _.get(values, 'name'),
        primary_project: primaryId,
        projects: projectIds
      })
      .then(() => {
        onSubmit();
        onClose();
      });
  };

  handleChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  isLoading = () => {
    const { projects } = this.props;
    const { loading } = this.state;
    return loading || _.get(projects, 'list.status') === 'loading';
  };

  closeForm = (e) => {
    e.preventDefault();

    const { onClose } = this.props;

    onClose();
  };

  render() {
    const { styles: s, projects } = this.props;
    const { setting, primary, selectedItems } = this.state;

    if (this.isLoading()) {
      return (
        <Box
          flex={1}
          alignItems="center"
          justifyContent="center"
          p={PADDINGS.M}
          style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        >
          <Fragment>
            <LoadingSpinner />
            <Box style={{ marginLeft: PADDINGS.XS }}>
              <Body>Loading...</Body>
            </Box>
          </Fragment>
        </Box>
      );
    }

    const items = _(_.get(projects, 'list.items')).map((item) => {
      return {
        id: _.get(item, 'id'),
        value: _.get(item, 'id'),
        label: _.get(item, 'title'),
        group: _.get(item, 'sub_region.name')
      };
    });

    const groupedItems = fp.compose(
      fp.map((group) => ({
        label: group,
        options: fp.filter((x) => x.group === group)(items.value())
      })),
      fp.sortBy(fp.identity),
      fp.uniq,
      fp.map((val) => val.group)
    )(items.value());

    return (
      <ReactForms handleSubmit={this.submitForm}>
        {({ isSubmitting }) => {
          return (
            <Form>
              <Box p={PADDINGS.XS}>
                <Heading {...s('header')}>Update Settings</Heading>
                <FormField
                  label={'Name'}
                  name={'name'}
                  Input={TextInput}
                  initialValue={_.get(setting, 'name')}
                />
                <FormField
                  label="Primary Project"
                  name="primary"
                  initialValue={_.get(primary, 'id')}
                  Input={SelectInput}
                  inputProps={{
                    options: items.value()
                  }}
                />
                <Box pt={PADDINGS.XS} pb={PADDINGS.XS}>
                  <Label>Projects</Label>
                </Box>
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      maxHeight: '300px',
                      overflow: 'scroll'
                    })
                  }}
                  options={groupedItems}
                  value={selectedItems}
                  onChange={this.handleChange}
                  closeMenuOnSelect={false}
                  isMulti
                />
              </Box>
              <ButtonBar>
                <PrimaryButton onClick={this.closeForm}>Close</PrimaryButton>
                <PrimaryButton
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  green
                >
                  Submit
                </PrimaryButton>
              </ButtonBar>
            </Form>
          );
        }}
      </ReactForms>
    );
  }
}

export default EditSettingsForm;
