import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { settingId, token, ...args }) => {
        return api.get(
          `/stats/settings/${settingId}/worksheets${
            token ? '?token=' + token : ''
          }`,
          args
        );
      }
    }
  }
};

const actionCreators = {};

const SettingWorksheetGenerator = new Generator('settingWorksheets', config);
export default SettingWorksheetGenerator.createEntityModel({ actionCreators });
