import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { Small } from '../text';
import { Grid, Column } from '@rexlabs/grid';
import { Check as Checkbox } from 'view/components/checkbox';
import { COLORS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    borderBottom: `1px solid ${COLORS.GREY.LIGHT}`
  },
  grid: {
    '> *': {
      padding: '1rem 2rem'
    }
  },
  text: {
    fontWeight: 500
  }
});

@styled(defaultStyles)
class ProjectStageListHeader extends PureComponent {
  render() {
    const { styles: s, checked, onSelectAll } = this.props;
    return (
      <Box {...s('container')}>
        <Grid gutter={0} columns={24} {...s('grid')}>
          <Column width={1} onClick={onSelectAll}>
            <Checkbox controlled={true} value={!!checked} />
          </Column>
          <Column width={3}>
            <Small grey {...s('text')}>
              Stage No.
            </Small>
          </Column>
          <Column width={6}>
            <Small grey {...s('text')}>
              Exp. Title Release
            </Small>
          </Column>
          <Column width={6}>
            <Small grey {...s('text')}>
              Actual Title Release
            </Small>
          </Column>
          <Column width={7}>
            <Small grey {...s('text')}>
              Related Lots
            </Small>
          </Column>
          <Column width={1} />
        </Grid>
      </Box>
    );
  }
}

export default ProjectStageListHeader;
