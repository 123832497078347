import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import periodsModel from 'data/models/entities/periods';
import projectsModel from 'data/models/entities/projects';
import { query, withQuery } from '@rexlabs/model-generator';
import LoadingLayout from 'view/layouts/loading';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import ReportFooter from 'view/components/report-footer';
import withReportSidebar from 'view/containers/with-report-sidebar';
import { toQuri } from '../../../utils/query';
import PricingComparisonReport from 'view/components/reports/pricing-comparison';

function getPrimaryProjectId(props) {
  return _.get(props, 'match.query.primaryProject');
}

function getPeriod(props) {
  return _.get(props, 'match.query.period');
}

function getProjectIds(props) {
  return _.get(props, 'match.query.projects');
}

function getPeriodQuery(props) {
  return toQuri([
    {
      field: 'end_date',
      op: '==',
      value: getPeriod(props)
    }
  ]);
}

const periodsQuery = query`{
  ${periodsModel} (q: ${getPeriodQuery}) {
    id
    end_date
  }
}`;

const projectsQuery = query`{
  ${projectsModel} (ids: ${getProjectIds}) {
    id
    title
    active
    developer
    sales_office_address
    sales_agency
    local_government_area
    total_area
    net_developable_area
    suburb
    postcode
    estimated_start_date
    estimated_completion_date
    total_area
    status
    created_at
    stats
    settings
    sales_volume_offset
    estimated_lots
    precinct_structure_plan
  }
}`;

@withReportSidebar(false, 'none')
@withQuery(projectsQuery)
@withQuery(periodsQuery)
class PricingComparison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Loading vars
      fetchingStats: true,

      //
      stats: {}
    };
  }

  componentDidMount() {
    const { periods, whereabouts } = this.props;

    // Check that settings were supplied
    const query = _.get(whereabouts, 'query');
    if (!_.get(query, 'period') || !_.get(query, 'projects')) {
      push(ROUTES.APP.REPORT, {});
      return;
    }

    // Get pricing stats
    periods
      .fetchPricingStats({
        args: {
          projects: getProjectIds(this.props),
          period: getPeriod(this.props)
        }
      })
      .then((res) => {
        this.setState({
          fetchingStats: false,
          stats: res.data
        });
      });
  }

  loading() {
    const { fetchingStats } = this.state;
    const { projects } = this.props;

    const arr = [fetchingStats, projects.list.status !== 'loaded'];

    return _.reduce(arr, (acc, val) => (val ? val + 1 : acc)) || 0;
  }

  render() {
    const { projects, periods } = this.props;
    const { stats } = this.state;

    const loading = this.loading();
    const maxRequests = 2;
    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const projectList = _.sortBy(_.get(projects, 'list.items'), (item) =>
      item.id === parseInt(getPrimaryProjectId(this.props)) ? 0 : 1
    );
    const primaryProject = _(projectList)
      .filter(
        (project) => project.id === parseInt(getPrimaryProjectId(this.props))
      )
      .head();
    const period = _.head(_.get(periods, 'list.items'));

    return (
      <Fragment>
        <PricingComparisonReport
          period={period}
          primaryProject={primaryProject}
          projects={projectList}
          stats={stats}
        />
        <ReportFooter
          prevText="Overview"
          prevRoute={ROUTES.APP.REPORT_OVERVIEW}
          nextText="Size-price Brackets"
          nextRoute={ROUTES.APP.REPORT_BRACKETS}
        />
      </Fragment>
    );
  }
}

export default PricingComparison;
