import React, { useMemo } from 'react';
import Box from '@rexlabs/box';
import FlagTooltips, { FlagTooltipType } from './flag-tooltips';
import { Styles, StyleSheet, styled } from '@rexlabs/styling';
import { COLORS, FONT } from 'src/features/reports/theme';
import { ProjectStatsDatum } from 'types/graph';
import { flagData } from './constants';
import LeafOutline from '../icons/project-flags-outline/leaf-outline';

const styles = StyleSheet({
  container: {
    fontWeight: 800,
    fontSize: 12,
    color: COLORS.BLUE.DARK,
    fontFamily: FONT.FAMILIES.DEFAULT,
    gap: 2,
    padding: 8,
    flexFlow: 'wrap'
  },
  ul: {
    '& li': {
      fontSize: '1.4rem',
      fontFamily: 'DM Sans',
      color: COLORS.BLUE.DARK,
      listStyle: 'inside'
    }
  },
  iconBoxes: {
    '& div': {
      height: '100%'
    },
    gap: 4
  }
});

interface ProjectStatIconsProps extends ProjectStatsDatum {
  styles?: Styles;
}

const ProjectStatIcons = ({
  id,
  title,
  flags,
  is_min_seven_star_nathers: min,
  is_zero_net_carbon_certified: zero,
  is_passive_home: pass,
  is_greenstar: green,
  is_enviro_development: enviro,
  styles
}: Partial<ProjectStatIconsProps>) => {
  const environmentStat = useMemo<FlagTooltipType>(() => {
    if (min || zero || pass || green || enviro) {
      return {
        title: 'Environmental Sustainable Design',
        description:
          'This project has been marked as having a Environmental Sustainable Design requirements on one or more of its stage releases.',
        Icon: LeafOutline,
        extra: (
          <ul {...styles('ul')}>
            {min && <li>Minimum 7 star NetHERS energy rating</li>}
            {zero && <li>Zero Net Carbon Ceritifcation</li>}
            {pass && <li>Passive Home</li>}
            {green && <li>Greenstar</li>}
            {enviro && <li>Envirodevelopment</li>}
          </ul>
        )
      };
    }

    return null;
  }, [enviro, green, min, pass, styles, zero]);

  return (
    <Box
      {...styles('container')}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      {title}
      <Box {...styles('iconBoxes')} flexDirection="row" alignItems="center">
        {environmentStat && (
          <FlagTooltips key={`enviro${id}`} {...environmentStat} />
        )}

        {flags.length > 0 &&
          flags.map((flagName, idx) => {
            const flag = flagData.find((f) => f.title === flagName);
            return <FlagTooltips key={`flag${idx}`} {...flag} />;
          })}
      </Box>
    </Box>
  );
};

export default styled(styles)(ProjectStatIcons);
