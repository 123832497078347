import React, { useEffect, useRef, SyntheticEvent } from 'react';
import Box from '@rexlabs/box';
import { Heading } from 'view/components/text';
import { COLORS, PADDINGS } from 'theme';
import _ from 'lodash';
import { formatNumber, roundHundred } from 'utils/format';
import { Grid } from '@rexlabs/grid';
import SectionColumn from 'view/components/grid/section-column';
import Table from 'view/components/table/react-table';
import { Project } from 'data/types/projects';

export interface PriceSummaryProps {
  priceSizeStats: any;
  primary: Project;
  projects: Project[];
}

const PriceSummary = ({
  priceSizeStats,
  primary,
  projects
}: PriceSummaryProps) => {
  const mainRef = useRef<HTMLDivElement>();
  const summaryRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleScroll = (element?: Element) => (e: SyntheticEvent<any>) => {
      if (e.type !== 'scroll') return;

      element?.scrollTo(e.currentTarget?.scrollLeft ?? 0, element.scrollTop);
    };

    // Get the tables
    const mainTable = mainRef?.current?.getElementsByClassName('rt-table')?.[0];
    const summaryTable =
      summaryRef?.current?.getElementsByClassName('rt-table')?.[0];

    // Add the listeners
    const mainFn = handleScroll(summaryTable);
    mainTable?.addEventListener('scroll', mainFn);

    const summaryFn = handleScroll(mainTable);
    summaryTable?.addEventListener('scroll', summaryFn);

    return () => {
      // Clean up the listeners
      mainTable?.removeEventListener('scroll', mainFn);
      summaryTable?.removeEventListener('scroll', summaryFn);
    };
  }, []);

  const priceSizeStatsOrdered = _(priceSizeStats.projects)
    .map((stat: any, id: string) => ({
      ...{
        project: _.find(projects, (project) => project.id === parseInt(id))
      },
      ...stat
    }))
    .sortBy((value: any) => (value?.project?.id === primary?.id ? 0 : 1))
    .value();

  const priceSizeStatsSummary = [
    {
      project: {
        title: 'Average'
      },
      ...priceSizeStats.average
    },
    {
      project: {
        title: 'Median'
      },
      ...priceSizeStats.median
    }
  ];
  return (
    <>
      <Grid gutter={0} columns={1}>
        <SectionColumn width={1}>
          <Heading style={{ padding: PADDINGS.S }}>
            Project Price Summary
          </Heading>
          <div ref={mainRef}>
            <Table
              getTdProps={(state, rowInfo) => ({
                style: {
                  color:
                    _.get(rowInfo, 'original.project.id') === primary?.id
                      ? COLORS.BLUE_GREEN
                      : 'inherit'
                }
              })}
              data={priceSizeStatsOrdered}
              columns={[
                {
                  Header: () => (
                    <p style={{ color: COLORS.BLUE_GREEN }}>Estate</p>
                  ),
                  accessor: 'project.title',
                  fixed: 'left',
                  width: 150,
                  style: {
                    background: COLORS.BLUE.LIGHT,
                    boxShadow: '4px 0px 3px -3px #ccc',
                    border: 'none !important'
                  }
                },
                {
                  Header: 'Ave. size (sold)',
                  width: 150,
                  id: 'soldSize',
                  accessor: (stat) =>
                    stat.average_sold_size
                      ? formatNumber(Math.round(stat.average_sold_size))
                      : '-'
                },
                {
                  Header: 'Ave. price (sold)',
                  width: 150,
                  id: 'soldPrice',
                  accessor: (stat) =>
                    stat.average_sold_price
                      ? '$' +
                        formatNumber(roundHundred(stat.average_sold_price))
                      : '-'
                },
                {
                  Header: 'Ave. PSQM (sold)',
                  width: 150,
                  id: 'soldPSQM',
                  accessor: (stat) =>
                    stat.average_sold_ppsm
                      ? '$' + formatNumber(Math.round(stat.average_sold_ppsm))
                      : '-'
                },
                {
                  Header: 'Ave. size (available)',
                  width: 150,
                  id: 'availableSize',
                  accessor: (stat) =>
                    stat.average_available_size
                      ? formatNumber(Math.round(stat.average_available_size))
                      : '-'
                },
                {
                  Header: 'Ave. price (available)',
                  width: 150,
                  id: 'availablePrice',
                  accessor: (stat) =>
                    stat.average_available_price
                      ? '$' +
                        formatNumber(roundHundred(stat.average_available_price))
                      : '-'
                },
                {
                  Header: 'Ave. PSQM (available)',
                  width: 150,
                  id: 'availablePSQM',
                  accessor: (stat) =>
                    stat.average_available_ppsm
                      ? '$' +
                        formatNumber(Math.round(stat.average_available_ppsm))
                      : '-'
                }
              ]}
              defaultPageSize={priceSizeStatsOrdered.length}
            />
          </div>
          <div ref={summaryRef}>
            <Table
              data={priceSizeStatsSummary}
              getTdProps={() => ({
                style: {
                  fontWeight: 500,
                  backgroundColor: COLORS.BLUE.LIGHT
                }
              })}
              getTrProps={() => ({
                style: {
                  backgroundColor: COLORS.BLUE.LIGHT
                }
              })}
              getTheadThProps={() => ({
                style: { display: 'none' }
              })}
              columns={[
                {
                  accessor: 'project.title',
                  fixed: 'left',
                  width: 150,
                  style: {
                    background: COLORS.BLUE.LIGHT,
                    boxShadow: '4px 0px 3px -3px #ccc',
                    border: 'none !important',
                    color: COLORS.BLUE_GREEN
                  }
                },
                {
                  Header: 'Ave. size (sold)',
                  width: 150,
                  id: 'soldSize',
                  accessor: (stat) =>
                    stat.average_sold_size
                      ? formatNumber(Math.round(stat.average_sold_size))
                      : '-'
                },
                {
                  Header: 'Ave. price (sold)',
                  width: 150,
                  id: 'soldPrice',
                  accessor: (stat) =>
                    stat.average_sold_price
                      ? '$' +
                        formatNumber(roundHundred(stat.average_sold_price))
                      : '-'
                },
                {
                  Header: 'Ave. PSQM (sold)',
                  width: 150,
                  id: 'soldPSQM',
                  accessor: (stat) =>
                    stat.average_sold_ppsm
                      ? '$' + formatNumber(Math.round(stat.average_sold_ppsm))
                      : '-'
                },
                {
                  Header: 'Ave. size (available)',
                  width: 150,
                  id: 'availableSize',
                  accessor: (stat) =>
                    stat.average_available_size
                      ? formatNumber(Math.round(stat.average_available_size))
                      : '-'
                },
                {
                  Header: 'Ave. price (available)',
                  width: 150,
                  id: 'availablePrice',
                  accessor: (stat) =>
                    stat.average_available_price
                      ? '$' +
                        formatNumber(roundHundred(stat.average_available_price))
                      : '-'
                },
                {
                  Header: 'Ave. PSQM (available)',
                  width: 150,
                  id: 'availablePSQM',
                  accessor: (stat) =>
                    stat.average_available_ppsm
                      ? '$' +
                        formatNumber(Math.round(stat.average_available_ppsm))
                      : '-'
                }
              ]}
              defaultPageSize={2}
            />
          </div>
          <Box p={PADDINGS.XXS} flex={1} flexDirection={'column'}>
            <span>
              Allotments without a specified price are ignored from size
              averages
            </span>
          </Box>
        </SectionColumn>
      </Grid>
    </>
  );
};

export default PriceSummary;
