import React, { SVGProps } from 'react';

const SquareLegend = (props: SVGProps<null>) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        fill={props.fill}
        stroke="transparent"
        width="14"
        height="14"
      ></rect>
    </svg>
  );
};

export default SquareLegend;
