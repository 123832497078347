import React, { ReactNode } from 'react';
import { Link } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet, Styles } from '@rexlabs/styling';
import { Tiny } from 'view/components/text';
import { PathConfig } from 'src/features/reports/routes';
import { ReportQueryParams } from 'types/params';

const defaultStyles = StyleSheet({
  label: {
    marginLeft: 20,
    fontFamily: 'DM Sans',
    fontWeight: 500,
    opacity: 1,
    transition: 'opacity 2s ease-in',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
});

interface SidebarOptionProps {
  styles: Styles;
  text: string;
  IconComponent: ReactNode;
  iconOnly: boolean;
  to: PathConfig;
  query: ReportQueryParams;
}

function SidebarOption({
  styles: s,
  text,
  IconComponent,
  iconOnly,
  ...props
}: SidebarOptionProps) {
  const style = iconOnly ? { justifyContent: 'center' } : { paddingLeft: 20 };
  return (
    <Link {...props} style={style}>
      {IconComponent}
      {!iconOnly && <Tiny {...s('label')}>{text}</Tiny>}
    </Link>
  );
}

export default styled(defaultStyles)(SidebarOption);
