import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

import { COLORS } from 'src/features/reports/theme';
import { Tiny } from 'view/components/text';

import 'rc-slider/assets/index.css';

const defaultStyles = StyleSheet({
  textBox: {
    borderRadius: 4,
    padding: '10px 12px',
    gap: 10,
    backgroundColor: COLORS.GREY.POWDER
  },
  boxText: {
    fontFamily: 'DM Sans',
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: 'nowrap'
  }
});

const BoxedText = ({ text, styles }) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width={105}
      height={40}
      {...styles('textBox')}
    >
      <Tiny {...styles('boxText')}>{text}</Tiny>
    </Box>
  );
};

export default styled(defaultStyles)(BoxedText);
