import React, { FC, HTMLProps, useMemo } from 'react';
import chroma from 'chroma-js';
import { COLORS } from 'src/features/reports/theme';

/*
 * lowestHeat can be greater than highest heat
 * It depends on the comparison you want to do
 */
export interface HeatMapItemProps extends HTMLProps<HTMLDivElement> {
  lowestHeat: number;
  highestHeat: number;
  heatValue: number;
  disabled?: boolean;
}

const HeatMapItem: FC<HeatMapItemProps> = ({
  lowestHeat,
  highestHeat,
  heatValue,
  disabled,
  children,
  ...rest
}) => {
  const backgroundColor = useMemo(() => {
    if (disabled) return COLORS.WHITE;

    // Percentage of heat value from the highest value
    let percentage = (heatValue - lowestHeat) / (highestHeat - lowestHeat);

    // Special case because this will throw NaN
    if (highestHeat === lowestHeat) {
      percentage = 0;
    }

    const scale = chroma.scale([
      'rgba(41, 204, 151, 0.75)',
      'rgba(252, 232, 150, 1)',
      'rgba(247, 74, 60, 0.75)'
    ]);

    return scale(percentage).hex();
  }, [lowestHeat, highestHeat, heatValue, disabled]);

  return (
    <div
      {...rest}
      style={{
        backgroundColor,
        transition: 'background-color 0.5s ease-in-out',
        height: '100%'
      }}
    >
      {children}
    </div>
  );
};

export default HeatMapItem;
