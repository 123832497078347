import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { Link, withWhereabouts } from '@rexlabs/react-whereabouts';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS, FONT, PADDINGS } from 'src/theme';
import ROUTES from 'src/routes';
import { withModel } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import statsModel from 'data/models/entities/stats';
import exportsModel from 'data/models/entities/export';
import { PrimaryButton } from 'view/components/button';
import _ from 'lodash';
import { Modal } from 'view/components/modal';
import ReportSettingsFrom from '../forms/report-setting';
import ModalTarget from './portal/modal';
import ExportButton from './export-button';
import { Auth0Context } from '../../auth0-provider';

const defaultStyles = StyleSheet({
  container: {
    height: '100%'
  },

  wrapLogo: {
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },

  menu: {
    marginTop: PADDINGS.S,

    '& a': {
      color: COLORS.GREY,
      fontWeight: FONT.WEIGHTS.SEMIBOLD,
      paddingBottom: PADDINGS.XXS,
      width: '100%',
      display: 'flex',
      fontSize: '1.8rem',
      textDecoration: 'none'
    },

    '& a:hover, & a.active': {
      color: COLORS.BLUE_GREEN
    },

    '& li': {
      marginBottom: PADDINGS.XS
    },

    '& li ul': {
      paddingLeft: PADDINGS.XS
    },

    '& li li a': {
      fontWeight: FONT.WEIGHTS.NORMAL
    }
  },

  btn: {
    width: '100% !important',
    marginTop: '1rem'
  }
});

@styled(defaultStyles)
@withModel(sessionModel)
@withModel(statsModel)
@withModel(exportsModel)
@withWhereabouts
class Sidebar extends PureComponent {
  static contextType = Auth0Context;
  state = {
    saveModal: false
  };

  toggleSave = () => {
    this.setState({ saveModal: true });
  };

  closeSave = () => this.setState({ saveModal: false });

  render() {
    const { styles: s, whereabouts, match, session } = this.props;
    const { saveModal } = this.state;
    return (
      <Box
        {...s('container')}
        flex={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <ModalTarget />
        <Box>
          <menu {...s('menu')}>
            <ul>
              <li>
                <Link to={ROUTES.APP.REPORT_OVERVIEW} query={whereabouts.query}>
                  Overview
                </Link>
              </li>
              <li>
                <Link to={ROUTES.APP.REPORT_PRICING} query={whereabouts.query}>
                  Pricing Comparison
                </Link>
              </li>
              <li>
                <Link to={ROUTES.APP.REPORT_BRACKETS} query={whereabouts.query}>
                  Size-price Brackets
                </Link>
              </li>
              <li>
                <Link to={ROUTES.APP.REPORT_LOT} query={whereabouts.query}>
                  Lot Availability
                </Link>
              </li>
              <li>
                <Link to={ROUTES.APP.REPORT_DETAILED} query={whereabouts.query}>
                  Estate Detailed Stats
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.APP.REPORT_PRICELISTS}
                  query={whereabouts.query}
                >
                  Pricelists
                </Link>
              </li>
              <li>
                <Link
                  to={ROUTES.APP.REPORT_DEFINITIONS}
                  query={whereabouts.query}
                >
                  Definitions Appendix
                </Link>
              </li>
            </ul>
          </menu>
        </Box>

        <Box>
          <PrimaryButton {...s('btn')} onClick={this.toggleSave}>
            Save Setting
          </PrimaryButton>
          <ExportButton
            match={match}
            style={{ width: '100%', marginTop: '1rem' }}
          />
          <PrimaryButton
            {...s('btn')}
            onClick={() => {
              session.logout({ logout: this.context.logout });
            }}
          >
            Logout
          </PrimaryButton>
        </Box>

        {saveModal && (
          <Modal>
            <ReportSettingsFrom
              closeModal={this.closeSave}
              projects={_.get(whereabouts, 'query.projects')}
              primary={_.get(whereabouts, 'query.primaryProject')}
              period={_.get(whereabouts, 'query.period')}
            />
          </Modal>
        )}
      </Box>
    );
  }
}

export default Sidebar;
