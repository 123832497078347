import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import statsModel from 'data/models/entities/stats';
import {
  getSettingsQuery,
  periodsQuery,
  projectsQuery
} from 'data/queries/settings';
import { withQuery, withModel } from '@rexlabs/model-generator';
import LoadingLayout from 'view/layouts/loading';
import ROUTES from 'src/routes';
import ReportFooter from 'view/components/report-footer';
import PricingComparison from '../../components/reports/pricing-comparison';
import withReportSidebar from 'view/containers/with-report-sidebar';
import withValidate from 'view/containers/with-validate-setting';
import { getSettingsId, getToken } from 'utils/reports';

@withReportSidebar(true, 'none')
@withQuery(projectsQuery)
@withQuery(periodsQuery)
@withQuery(getSettingsQuery(getSettingsId, getToken))
@withModel(statsModel)
@withValidate
class PricingComparisonSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Loading vars
      fetchingStats: true,

      //
      stats: {},

      fetchingPrimary: false,

      primary: {}
    };
  }

  componentDidMount() {
    const { stats, whereabouts, settingProjects } = this.props;

    // Get pricing stats
    stats
      .fetchPricingSettingsStats({
        id: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token'),
          period: _.get(whereabouts, 'query.period')
        }
      })
      .then((res) => {
        this.setState({
          fetchingStats: false,
          stats: res.data
        });
      });

    settingProjects
      .fetchPrimary({
        settingId: getSettingsId(this.props),
        args: {
          token: _.get(whereabouts, 'query.token')
        }
      })
      .then((res) => {
        this.setState({
          fetchingPrimary: false,
          primary: res.data
        });
      })
      .catch(this.handleError);
  }

  loading() {
    const { fetchingStats, fetchingPrimary } = this.state;
    const { settingProjects } = this.props;

    const arr = [
      fetchingStats,
      fetchingPrimary,
      _.get(settingProjects, 'list.status') !== 'loaded'
    ];

    return _.reduce(arr, (acc, val) => (val ? val + 1 : acc)) || 0;
  }

  render() {
    const { settingProjects, periods, settings } = this.props;
    const { stats, primary } = this.state;

    const loading = this.loading();
    const maxRequests = 3;
    if (loading !== 0) {
      return (
        <LoadingLayout current={maxRequests - loading} max={maxRequests} />
      );
    }

    const projectList = _.sortBy(_.get(settingProjects, 'list.items'), (item) =>
      item.id === primary?.id ? 0 : 1
    );

    const period = _.get(periods, 'list.items.0');

    return (
      <Fragment>
        <PricingComparison
          settings={_.get(settings, 'item.data')}
          period={period}
          primaryProject={primary}
          projects={projectList}
          stats={stats}
        />
        <ReportFooter
          prevText="Overview"
          prevRoute={ROUTES.APP.REPORT_SETTINGS_OVERVIEW}
          nextText="Size-price Brackets"
          nextRoute={ROUTES.APP.REPORT_SETTINGS_BRACKETS}
          settingId={getSettingsId(this.props)}
        />
      </Fragment>
    );
  }
}

export default PricingComparisonSettings;
