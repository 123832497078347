import React, { useCallback, useEffect, useRef, useState } from 'react';
import Picker from 'react-month-picker';
import Box from '@rexlabs/box';
import TextInput from '@rexlabs/text-input/lib/text-input';
import { styled, StyleSheet } from '@rexlabs/styling';

import { Label, Tiny } from 'view/components/text';
import { pickerLang } from 'view/components/calendar/month';
import { PADDINGS } from 'src/theme';
import { formatRangeDisplay } from 'utils/format';
import 'react-month-picker/css/month-picker.css';

export const DEFAULT_RANGE_OBJ = { year: '', month: '' };

export const EMPTY_RANGE = {
  from: DEFAULT_RANGE_OBJ,
  to: DEFAULT_RANGE_OBJ
};

const defaultStyles = StyleSheet({
  error: { fontSize: '1.1rem', color: '#e30000' }
});

interface DecoratorProps {
  styles: any;
}

interface RangePickerProps {
  onRangeChange?: (any) => void;
  onChange?: (any) => void;
  onBlur?: (any) => void;
  label: string;
  subLabel?: string;
  disabled: boolean;
  value?: PickerRangeProps;
  error?: boolean;
}

interface PickerRangeProps {
  from: { year: string; month: string };
  to: { year: string; month: string };
}

function MonthRangePicker({
  styles: s,
  onRangeChange,
  onChange,
  onBlur,
  disabled,
  label,
  subLabel,
  value,
  error,
  ...props
}: RangePickerProps & DecoratorProps) {
  const pickerRef = useRef<any>();

  const dateDisplayValue = formatRangeDisplay(value);

  const handleClick = useCallback(() => {
    if (pickerRef) pickerRef.current.show();
  }, [pickerRef]);

  const dateRangeChange = useCallback(
    (e) => {
      onChange({
        target: {
          value: e
        }
      });
    },
    [onChange]
  );

  return (
    <Box mt={PADDINGS.XS}>
      <Label>{label}</Label>
      {subLabel && <Tiny>{subLabel}</Tiny>}
      <Picker
        ref={pickerRef}
        years={{ min: 2011, max: 2050 }}
        value={value}
        lang={pickerLang}
        onDismiss={dateRangeChange}
        {...props}
      >
        <TextInput
          value={dateDisplayValue}
          placeholder={'Select month range'}
          onClick={handleClick}
          disabled={disabled}
        />
      </Picker>
    </Box>
  );
}

export default styled(defaultStyles)(MonthRangePicker);
