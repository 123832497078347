import React from 'react';
import { styled, StyleSheet } from '@rexlabs/styling';
import PropTypes from 'prop-types';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { Heading, Body, Tiny } from 'view/components/text';
import { formatPeriod } from 'utils/format';
import { COLORS, PADDINGS } from 'src/theme';

const defaultStyles = StyleSheet({
  container: {
    height: '100%',
    paddingLeft: PADDINGS.M
  }
});

function ReportTitle(props) {
  const { styles: s, project, period, settings } = props;

  return (
    <Box {...s('container')} flex={1} alignItems={'center'}>
      <Box flex={1} flexDirection={'column'}>
        <Body style={{ color: 'white', fontWeight: 'bold' }}>
          {formatPeriod(_.get(period, 'end_date'), true)}
        </Body>
        <Heading style={{ color: 'white', paddingBottom: PADDINGS.XXS }}>
          Competition Report
        </Heading>
        <Box flex={1} flexDirection={'row'}>
          <Box>
            <Tiny style={{ color: COLORS.GREY.LIGHT }}>Primary Estate</Tiny>
            <Body style={{ color: 'white', fontWeight: 'bold' }}>
              {_.get(project, 'title', 'No Specified Primary')}
            </Body>
          </Box>
          {settings && (
            <Box ml={PADDINGS.XXL}>
              <Tiny style={{ color: COLORS.GREY.LIGHT }}>Report Name</Tiny>
              <Body style={{ color: 'white', fontWeight: 'bold' }}>
                {_.get(settings, 'hidden')
                  ? 'Custom Report'
                  : _.get(settings, 'name')}
              </Body>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

ReportTitle.PropTypes = {
  period: PropTypes.object.isRequired,
  project: PropTypes.object,
  setting: PropTypes.object
};

export default _.flow([styled(defaultStyles)])(ReportTitle);
