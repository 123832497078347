import React from 'react';
import { TooltipStateful, PLACEMENTS } from '@rexlabs/tooltip';
import { COLORS } from 'src/theme';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';

const defaultStyles = StyleSheet({
  container: {
    width: '100%',
    height: '100%'
  },
  dot: {
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    background: COLORS.BLUE_GREEN
  }
});

function NewDot(props) {
  const { styles: s, tooltip } = props;

  return (
    <TooltipStateful
      placement={PLACEMENTS.RIGHT}
      openOn="HOVER"
      closeOn="HOVER"
      hoverTimeout={250}
      closeTimeout={250}
      Content={() => <span>{tooltip}</span>}
    >
      <Box
        {...s('container')}
        flex={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <div {...s('dot')} />
      </Box>
    </TooltipStateful>
  );
}

export default styled(defaultStyles)(NewDot);
