import React, { PureComponent } from 'react';
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import { styled, StyleSheet } from '@rexlabs/styling';
import { PADDINGS, COLORS } from 'src/theme';
import { PrimaryButton, TextButton, ClearButton } from 'view/components/button';
import logoPng from 'assets/core-logo-transparent.png';
import dropdownPng from 'assets/dropdown-preview.png';
import withError from 'view/containers/with-error';
import ModalTarget from 'view/components/portal/modal';
import { Heading, SubHeading } from 'view/components/text';
import { Tabs } from '@rexlabs/tabs';
import _ from 'lodash';

const defaultStyles = StyleSheet({
  container: {
    background: COLORS.WHITE,
    position: 'absolute',
    width: '100%',
    height: '100%'
  },

  wrapLogo: {
    textAlign: 'center',
    '& img': {
      width: '25rem',
      height: 'auto'
    }
  },

  wrapContent: {
    padding: PADDINGS.L,
    width: '100%',
    maxWidth: '70rem',
    height: '100%'
  },

  wrapMain: {
    textAlign: 'center',
    '> *': {
      padding: PADDINGS.XS
    }
  },

  skip: {
    margin: 'auto'
  }
});

@withError.withPropName('errorModal')
@withModel(sessionModel)
@styled(defaultStyles)
class SplashLayout extends PureComponent {
  state = {
    tab: 'gettingStarted'
  };

  skip = () => {
    localStorage.setItem('splash', 'false');
    location.reload();
  };

  render() {
    const {
      styles: s,
      errorModal: { Error }
    } = this.props;
    return (
      <Box {...s('container')} flex={1} justifyContent="center">
        <Box
          {...s('wrapContent')}
          flex={1}
          style={{
            flexDirection: 'column'
          }}
        >
          <Box {...s('wrapLogo')}>
            <img src={logoPng} alt="Core | Projects" />
          </Box>
          <Box
            flex={1}
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box
              flex={1}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
              {...s('wrapMain')}
            >
              <Tabs
                activeTab={this.state.tab}
                onChange={_.noop}
                TabBar={Box}
                items={[
                  {
                    name: 'gettingStarted',
                    label: 'Getting Started',
                    Tab: () => (
                      <Box
                        flex={1}
                        alignItems={'center'}
                        justifyContent={'center'}
                        flexDirection={'column'}
                        {...s('wrapMain')}
                      >
                        <Heading style={{ color: COLORS.BLUE_GREEN }}>
                          Before You Get Started
                        </Heading>
                        <SubHeading grey>
                          Here are some tips just to make your time using the
                          dashboard easier and quicker
                        </SubHeading>
                        <Box>
                          <PrimaryButton
                            green
                            style={{ width: '20rem' }}
                            onClick={() => this.setState({ tab: 'dataRanges' })}
                          >
                            Continue
                          </PrimaryButton>
                        </Box>
                      </Box>
                    )
                  },
                  {
                    name: 'dataRanges',
                    label: 'Data Ranges',
                    Tab: () => (
                      <Box
                        flex={1}
                        alignItems={'center'}
                        justifyContent={'center'}
                        flexDirection={'column'}
                        {...s('wrapMain')}
                      >
                        <Heading style={{ color: COLORS.BLUE_GREEN }}>
                          Data Ranges & Changing Views
                        </Heading>
                        <img
                          src={dropdownPng}
                          alt="Dropdown Preview"
                          style={{ width: '40.5rem', height: '33.5rem' }}
                        />
                        <SubHeading grey>
                          Using these drop down menus and toggles will allow you
                          to view the data that is being displayed in a variety
                          of ways.
                        </SubHeading>
                        <Box>
                          <ClearButton
                            green
                            style={{
                              width: '20rem',
                              borderColor: COLORS.BLUE_GREEN,
                              color: COLORS.BLUE_GREEN
                            }}
                            onClick={this.skip}
                          >
                            Get Started
                          </ClearButton>
                        </Box>
                      </Box>
                    )
                  }
                ]}
              />
            </Box>
            <Box {...s('skip')} onClick={this.skip}>
              <TextButton style={{ color: COLORS.GREY }}>
                Skip To Dashboard
              </TextButton>
            </Box>
          </Box>
        </Box>
        <ModalTarget login />
        <Error />
      </Box>
    );
  }
}

export default SplashLayout;
