import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { sum } from 'lodash';

import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { PLACEMENTS } from '@rexlabs/tooltip';
import { withModel } from '@rexlabs/model-generator';
import { withWhereabouts } from '@rexlabs/react-whereabouts';
import { buildQueryString } from '@rexlabs/whereabouts';

import { VividTooltipStateful } from 'src/features/reports/components/vivid';
import SubRegions from './sub-regions';
import { DefaultButton } from '../button';

import { COLORS, FONT } from 'src/features/reports/theme';
import { ReportSettingsModel } from 'types/models/report-settings';
import reportSettingsModel from 'src/features/reports/models/report-settings';
import { compose } from 'utils/compose';
import { Project, SubRegion, SubRegionWithProjects } from 'types/resource';
import withError from 'view/containers/with-error';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import { Whereabouts } from 'types/common';
import { ReportParams } from 'types/params';
import useRouteConfig from 'src/features/reports/hooks/use-route-config';
import { ArrowDown } from '../icons';
import LoadingSpinner from '@rexlabs/loading-spinner';

const styles = StyleSheet({
  sectionTitleWrapper: {
    backgroundColor: COLORS.GREY.POWDER,
    padding: '4px 16px',
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  sectionTitle: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: FONT.FAMILIES.DEFAULT
  },
  noteWrapper: {
    padding: '8px 16px'
  },
  note: {
    fontSize: '10px'
  },
  projectWrapper: {
    maxHeight: `${37.59 * 5}px`, // Equivalent to 5 projects
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  subRegionWrapper: {
    overflow: 'hidden',
    borderRadius: '0.3em'
  },
  scrollWrapper: {
    maxHeight: '478px',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  scroll: {
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: COLORS.GREY.DARK
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: `${COLORS.GREY.DARK}D9` // 0.85 opacity
    }
  }
});

const tooltipStyles = StyleSheet({
  tooltip: {
    padding: 0,
    width: '254px'
  }
});

export interface SelectedProjectsInSubRegion {
  [subRegionKey: SubRegion['id']]: Project['id'][];
}

export interface SubRegionProjectSelectorProps {
  styles: Styles;
  selectedProjectsInSubRegion: SelectedProjectsInSubRegion | null;
  subRegionWithAllProjectsSelected: SubRegion['id'][];
  reportSettingsModel: ReportSettingsModel;
  standardSubRegions: SubRegionWithProjects[];
  premiumSubRegions: SubRegionWithProjects[];
  errorModal: WithErrorModalProps;
  whereabouts: Pick<Whereabouts<ReportParams>, 'query' | 'path'>;
  // This can be used so that parent component can access the selected projects
  onChange: (
    selectedProjectsInSubRegion: SelectedProjectsInSubRegion,
    subRegionWithAllProjectsSelected: SubRegion['id'][]
  ) => void;
}

const SubRegionProjectSelector = (
  props: Pick<
    SubRegionProjectSelectorProps,
    'reportSettingsModel' | 'styles' | 'errorModal' | 'whereabouts'
  >
) => {
  const {
    reportSettingsModel,
    errorModal: { open: openError },
    whereabouts
  } = props;
  const { updateParams } = useRouteConfig<ReportParams>({ whereabouts });
  const {
    selectedProjectsInSubRegion,
    setSelectedProjectsInSubRegion,
    standardSubRegions,
    setSubRegions,
    premiumSubRegions,
    subRegionWithAllProjectsSelected,
    setSubRegionWithAllProjectsSelected,
    setSelectedReport,
    isSubRegionsLoading,
    setSubRegionsLoading,
    advanceFilters
  } = useContext(ReportSettingsContext);
  const [cancelExit, setCancelExit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const totalSelected = useMemo(() => {
    if (!selectedProjectsInSubRegion) return 0;
    const selectedProjectsPerSubRegion = Object.keys(
      selectedProjectsInSubRegion
    ).map((key) => selectedProjectsInSubRegion[key].length);

    return sum(selectedProjectsPerSubRegion);
  }, [selectedProjectsInSubRegion]);

  useEffect(() => {
    setSubRegionsLoading(true);
    reportSettingsModel
      .fetchSubRegions()
      .then((res) => {
        const { standard, premium } = res.data;
        setSubRegions({
          standardSubRegions: standard,
          premiumSubRegions: premium ?? []
        });
        setSubRegionsLoading(false);
      })
      .catch((error) => {
        openError(error);
        setSubRegionsLoading(false);
      });
    // Need to disable because if we add subRegions it will have an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (
    selectedProjectsInSubRegion: SelectedProjectsInSubRegion,
    subRegionWithAllProjectsSelected: SubRegion['id'][]
  ) => {
    setSelectedProjectsInSubRegion(selectedProjectsInSubRegion);
    setSubRegionWithAllProjectsSelected(subRegionWithAllProjectsSelected);

    // Remove selected report when changing selected project ids
    // and use previous advance filters
    updateParams({
      ...advanceFilters,
      project_ids: buildQueryString(selectedProjectsInSubRegion),
      sub_region_with_all_projects_selected: subRegionWithAllProjectsSelected,
      saved: undefined
    });
    setSelectedReport(null);
  };

  return (
    <VividTooltipStateful
      styles={tooltipStyles}
      distance="10px"
      placement={PLACEMENTS.BOTTOM}
      Content={SubRegions}
      cancelExit={cancelExit}
      onChange={({ isOpen }: { isOpen: boolean }) => setIsOpen(isOpen)}
      contentProps={{
        ...props,
        standardSubRegions,
        premiumSubRegions,
        setCancelExit,
        selectedProjectsInSubRegion,
        subRegionWithAllProjectsSelected,
        onChange
      }}
    >
      <DefaultButton
        selected={isOpen}
        isLoading={isSubRegionsLoading}
        Loading={() => (
          <LoadingSpinner
            colors={[COLORS.BLUE.DARK]}
            size={20}
            strokeWidth={4}
          />
        )}
      >
        <Box flexDirection="row" alignItems="center">
          <span style={{ marginRight: '12px' }}>
            Projects ({totalSelected})
          </span>
          <ArrowDown
            width="12"
            height="6"
            style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }}
          />
        </Box>
      </DefaultButton>
    </VividTooltipStateful>
  );
};

export default compose(
  withWhereabouts,
  styled(styles),
  withModel(reportSettingsModel),
  withError.withPropName('errorModal'),
  memo
)(SubRegionProjectSelector);
