import { ScaleBand, computeScale, ScaleSpec } from '@nivo/scales';

/**
 * Generates indexed scale.
 */
export const getIndexScale = (
  groups: string[],
  padding: number,
  indexScale: ScaleSpec,
  size: number,
  axis: 'x' | 'y'
) => {
  return (
    computeScale(
      indexScale,
      { all: groups, min: 0, max: 0 },
      size,
      axis
    ) as ScaleBand<string> & { padding: (padding: number) => void }
  ).padding(padding);
};
