import React, { Fragment, PureComponent } from 'react';
import Box from '@rexlabs/box';
import { ActionButton, PrimaryButton } from 'view/components/button';
import MonthPicker from 'view/components/calendar/month';
import { Portal } from '@rexlabs/portal';
import { query, withQuery, withModel } from '@rexlabs/model-generator';
import { Grid, Column } from '@rexlabs/grid';
import SectionColumn from 'view/components/grid/section-column';
import { styled, StyleSheet } from '@rexlabs/styling';
import LoadingSpinner from '@rexlabs/loading-spinner';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import periodsModel from 'data/models/entities/periods';
import { Heading } from 'view/components/text';
import List from '@rexlabs/list';
import PeriodListItem from 'view/components/list/period-list-item';
import PeriodListHeader from 'view/components/list/period-list-header';
import _ from 'lodash';
import { push } from '@rexlabs/whereabouts';
import ROUTES from 'src/routes';
import withAction from 'view/containers/with-action';
import withError from 'view/containers/with-error';
import sessionModel from 'data/models/custom/session';
import LoadingLayout from 'view/layouts/loading';

const defaultStyles = StyleSheet({
  breadcrumb: {
    marginLeft: '0.8rem',
    marginTop: '0.8rem'
  },
  header: {
    padding: '2rem'
  }
});

const periodsQuery = query`{
  ${periodsModel} (include:"projects") {
    id
    end_date
    updated_at
    projects
    note
  }
}`;

@withAction.withPropName('actionModal')
@withError.withPropName('errorModal')
@withQuery(periodsQuery)
@withModel(sessionModel)
@styled(defaultStyles)
class ListPeriodsScreen extends PureComponent {
  state = {
    checked: {}
  };

  findPeriodAndRedirect(year, month) {
    const { periods, actionModal, errorModal } = this.props;
    const endDate = `${year}-${month}-1`;

    periods.findPeriod(endDate).then((res) => {
      if (res.data.length < 1) {
        actionModal.open(
          () => {
            // Create the period
            periods
              .createItem({
                data: {
                  account_id: this.props.session.accountId,
                  end_date: endDate
                }
              })
              .then((response) => {
                periods.refreshList();
                const id = _.get(response, 'data.id');
                push(ROUTES.APP.PERIOD_VIEW, {
                  params: {
                    periodId: id
                  }
                });
              })
              .catch((err) => {
                errorModal.open(err);
                actionModal.close();
              });
          },
          'Period does not exist. Would you like to create it?',
          'Period not found'
        );
      } else {
        // Redirect to worksheet
        push(ROUTES.APP.PERIOD_VIEW, {
          params: {
            periodId: res.data[0].id
          }
        });
      }
    });
  }

  render() {
    const {
      styles: s,
      periods,
      actionModal: { Action },
      errorModal: { Error }
    } = this.props;
    const periodsList = _.get(periods, 'list.items', []);
    const isLoading = _.get(periods, 'list.status') === 'loading';

    return isLoading ? (
      <LoadingLayout />
    ) : (
      <Fragment>
        <Portal target="header">
          <Box
            {...s('headerButtons')}
            style={{ height: '100%', alignItems: 'center', padding: '4rem' }}
            flex={1}
          >
            <MonthPicker
              from={2000}
              to={2050}
              onChange={(year, month) =>
                this.findPeriodAndRedirect(year, month)
              }
              button={<ActionButton>Select Period</ActionButton>}
            />
          </Box>
        </Portal>
        <Grid gutter={0} columns={12}>
          <Column width={12}>
            <Box {...s('breadcrumb')}>
              <Breadcrumb
                crumbs={[
                  {
                    label: 'Dashboard',
                    route: '/'
                  },
                  {
                    label: 'Periods'
                  }
                ]}
              />
            </Box>
          </Column>
        </Grid>
        <Grid gutter={0} columns={12}>
          <SectionColumn width={12}>
            <Box {...s('container')}>
              <Heading {...s('header')}>Periods</Heading>
              <List
                items={_.get(periods, 'list.items', [])}
                isLoading={_.get(periods, 'list.status') === 'loading'}
                endReached={_.get(periods, 'list.pagination.endReached')}
                Header={() => {
                  return (
                    <PeriodListHeader
                      checked={
                        _.filter(this.state.checked, (value) => value)
                          .length === periodsList.length
                      }
                      onSelectAll={() => {
                        if (
                          _.filter(this.state.checked, (value) => value)
                            .length === periodsList.length
                        ) {
                          this.setState({
                            checked: {}
                          });
                        } else {
                          const toggledState = periodsList.reduce(
                            (obj, item) => {
                              return { ...obj, [item.id]: true };
                            },
                            {}
                          );
                          this.setState({
                            checked: toggledState
                          });
                        }
                      }}
                    />
                  );
                }}
                LoadingView={LoadingSpinner}
                renderItem={(item) => {
                  return (
                    <PeriodListItem
                      period={item}
                      refresh={() =>
                        periods.refreshItem({ id: periods.item.data.id })
                      }
                      worksheet={item}
                      checked={this.state.checked[item.id]}
                      toggle={() =>
                        this.setState({
                          checked: {
                            ...this.state.checked,
                            ...{
                              [item.id]: !this.state.checked[item.id]
                            }
                          }
                        })
                      }
                    />
                  );
                }}
                onLoadMore={() => periods.fetchMore()}
                autoLoadMore={true}
              />

              {!_.get(periods, 'list.pagination.endReached', true) && (
                <Box
                  flex={1}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '1rem'
                  }}
                >
                  <PrimaryButton
                    green
                    onClick={() => periods.fetchMore()}
                    isLoading={periods.list.status === 'fetching'}
                    isDisabled={periods.list.status === 'fetching'}
                  >
                    Load More
                  </PrimaryButton>
                </Box>
              )}
            </Box>
          </SectionColumn>
        </Grid>
        <Action />
        <Error />
      </Fragment>
    );
  }
}

export default ListPeriodsScreen;
