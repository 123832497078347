import React, { PureComponent, Fragment } from 'react';
import { AddButton, PrimaryButton } from 'view/components/button';
import Box from '@rexlabs/box';
import _ from 'lodash';
import { query, withQuery } from '@rexlabs/model-generator';
import { StyleSheet, styled } from '@rexlabs/styling';
import regionsModel from 'data/models/entities/regions';
import LoadingLayout from 'view/layouts/loading';
import Breadcrumb from 'view/components/breadcrumb/breadcrumb';
import { COLORS, SHADOWS, BORDER_RADIUS } from 'src/theme';
import { Heading } from 'view/components/text';
import List from '@rexlabs/list';
import LoadingSpinner from '@rexlabs/loading-spinner';
import RegionListHeader from 'view/components/list/region-list-header';
import RegionListItem from 'view/components/list/region-list-item';
import { Portal } from '@rexlabs/portal';
import { Link } from '@rexlabs/react-whereabouts';
import ROUTES from 'src/routes';
import withAction from 'view/containers/with-action';
import withError from 'view/containers/with-error';

const regionsQuery = query`{
  ${regionsModel} {
    id
    name
    project_count
    updated_at
    published_to
    is_hidden
  }
}`;

const defaultStyles = StyleSheet({
  header: {
    padding: '2rem'
  },
  sectionContainer: {
    backgroundColor: COLORS.WHITE,
    boxShadow: SHADOWS.CONTAINER,
    borderRadius: BORDER_RADIUS.CONTAINER,
    flexWrap: 'nowrap'
  }
});

@withAction.withPropName('actionModal')
@withError.withPropName('errorModal')
@styled(defaultStyles)
@withQuery(regionsQuery)
class Regions extends PureComponent {
  state = {
    checked: {}
  };

  renderHeader = () => {
    const { regions } = this.props;
    const regionsList = _.get(regions, 'list.items', []);

    return (
      <RegionListHeader
        onSelectAll={this.selectAll}
        checked={
          _.filter(this.state.checked, (value) => value).length ===
          regionsList.length
        }
      />
    );
  };

  renderItem = (item) => {
    const { regions } = this.props;
    const { checked } = this.state;
    return (
      <RegionListItem
        regions={regions}
        region={item}
        checked={_.get(checked, item.id)}
        toggle={() => this.toggleItem(item.id)}
      />
    );
  };

  selectAll = () => {
    const { regions } = this.props;
    const regionsList = _.get(regions, 'list.items', []);

    if (
      _.filter(this.state.checked, (value) => value).length ===
      regionsList.length
    ) {
      this.setState({
        checked: {}
      });
    } else {
      const toggledState = regionsList.reduce((obj, item) => {
        return { ...obj, [item.id]: true };
      }, {});
      this.setState({
        checked: toggledState
      });
    }
  };

  toggleItem = (id) => {
    this.setState({
      checked: {
        ...this.state.checked,
        ...{
          [id]: !this.state.checked[id]
        }
      }
    });
  };

  deleteRegions = () => {
    const { regions, actionModal, errorModal } = this.props;
    const { checked } = this.state;

    // Filter down to the checked regions
    const checkedRegions = _.map(checked, (value, key) => {
      return { id: key, value: value };
    }).filter((value) => value.value);

    Promise.all(
      _.map(checkedRegions, (region) =>
        regions.trashItem({ id: region.id }).catch((e) => errorModal.open(e))
      )
    ).then(() => {
      this.setState({ checked: {} });
      regions.refreshList();
      actionModal.close();
    });
  };

  isLoading() {
    const { regions } = this.props;

    return _.some([_.get(regions, 'list.status') === 'loading']);
  }

  componentDidUpdate(prevProps) {
    const { regions } = this.props;

    // Refresh regions on modal close
    if (
      _.get(prevProps, 'whereabouts.path') === '/app/sub-regions/new' &&
      _.get(this.props, 'whereabouts.path') === '/app/sub-regions'
    ) {
      regions.refreshList({ id: 'refresh-on-close' });
    }
  }

  render() {
    const {
      styles: s,
      regions,
      actionModal: { Action, ...actionModal },
      errorModal: { Error }
    } = this.props;

    if (this.isLoading()) {
      return <LoadingLayout />;
    }

    return (
      <Fragment>
        <Portal target="header">
          <Box
            style={{ height: '100%', alignItems: 'center', padding: '4rem' }}
            flex={1}
          >
            <Link to={ROUTES.APP.REGIONS.NEW_REGION}>
              {({ onClick }) => (
                <AddButton onClick={onClick}>Add Sub-Region</AddButton>
              )}
            </Link>
          </Box>
        </Portal>
        <Box>
          <Breadcrumb
            crumbs={[
              {
                label: 'Dashboard',
                route: '/'
              },
              {
                label: 'Sub-Regions'
              }
            ]}
          />
        </Box>

        <Box {...s('sectionContainer')}>
          <Box
            p={'2rem'}
            flex={1}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Heading>Sub-Regions</Heading>
            {_.filter(this.state.checked, (value) => value).length > 0 && (
              <PrimaryButton
                red
                onClick={() =>
                  actionModal.open(
                    this.deleteRegions,
                    'Are you sure you want to delete this Sub-Region(s)?',
                    'Delete Sub-Region(s)?',
                    true
                  )
                }
              >
                Delete Sub-Region(s)
              </PrimaryButton>
            )}
          </Box>
          <List
            items={_.get(regions, 'list.items', [])}
            isLoading={_.get(regions, 'list.status') === 'loading'}
            endReached={_.get(regions, 'list.pagination.endReached')}
            LoadingView={LoadingSpinner}
            onLoadMore={() => regions.fetchMore()}
            autoLoadMore={true}
            Header={this.renderHeader}
            renderItem={this.renderItem}
          />
          {!_.get(regions, 'list.pagination.endReached', true) && (
            <Box
              flex={1}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: '1rem'
              }}
            >
              <PrimaryButton
                green
                onClick={() => regions.fetchMore()}
                isLoading={_.get(regions, 'list.status') === 'fetching'}
                isDisabled={_.get(regions, 'list.status') === 'fetching'}
              >
                Load More
              </PrimaryButton>
            </Box>
          )}
        </Box>
        <Action />
        <Error />
      </Fragment>
    );
  }
}

export default Regions;
