import React from 'react';
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { compose } from 'lodash/fp';

const defaultStyles = StyleSheet({
  emptyIcon: {
    width: 100,
    height: 100,
    opacity: 0.5
  }
});

function EmptyView({ styles: s }: { styles: any }) {
  return (
    <Box
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="auto"
    >
      <img src="/src/assets/empty-box.png" {...s('emptyIcon')} />
    </Box>
  );
}

export default compose(styled(defaultStyles))(EmptyView);
