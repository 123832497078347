import Generator from '../generator';
import { api } from 'utils/api-client';

const config = {
  entities: {
    api: {
      fetchList: (type, { projectId, worksheetId, ...args }) =>
        api.get(
          `/projects/${projectId}/worksheets/${worksheetId}/stages`,
          args
        ),
      fetchItem: (type, { projectId, worksheetId }, id) =>
        api.get(
          `/projects/${projectId}/worksheets/${worksheetId}/stages/${id}`
        ),
      createItem: (type, { projectId, worksheetId, ...args }) =>
        api.post(
          `/projects/${projectId}/worksheets/${worksheetId}/stages`,
          args
        ),
      updateItem: (type, { projectId, worksheetId, ...args }, id) =>
        api.put(
          `/projects/${projectId}/worksheets/${worksheetId}/stages/${id}`,
          args
        ),
      trashItem: (type, { projectId, worksheetId, ...args }, id) =>
        api.delete(
          `/projects/${projectId}/worksheets/${worksheetId}/stages/${id}`
        )
    }
  }
};

const StagesGenerator = new Generator('stages', config);
export default StagesGenerator.createEntityModel();
