import React, { SVGProps } from 'react';
import { COLORS } from 'src/features/reports/theme';

const OneHundredOutline = (props: SVGProps<null>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.35341 19.2111V11.7124"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14191 19.2827H7.71289"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.85613 13.0843L6.37109 11.5693"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6667 31H9.33333C4.73096 31 1 27.269 1 22.6667V9.33333C1 4.73096 4.73096 1 9.33333 1H22.6667C27.269 1 31 4.73096 31 9.33333V22.6667C31 27.269 27.269 31 22.6667 31Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7694 12.8106C17.0244 14.2748 17.0244 16.6487 15.7694 18.1129C14.5143 19.5771 12.4795 19.5771 11.2245 18.1129C9.96944 16.6487 9.96944 14.2748 11.2245 12.8106C12.4795 11.3464 14.5143 11.3464 15.7694 12.8106"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7684 12.8106C27.0234 14.2748 27.0234 16.6487 25.7684 18.1129C24.5134 19.5771 22.4785 19.5771 21.2235 18.1129C19.9685 16.6487 19.9685 14.2748 21.2235 12.8106C22.4785 11.3464 24.5134 11.3464 25.7684 12.8106Z"
        stroke={COLORS.BLUE.DARK}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OneHundredOutline;
