import Generator from 'data/models/generator';
import { identity } from 'lodash';
import { ApiArgs } from 'types/common';
import { BillingPortalParams } from 'types/params';
import { Report } from 'types/resource';
import { api } from 'utils/api-client';

const actionCreators = {
  addReport: {
    request: ({ queryParams }: ApiArgs<Omit<Report, 'id'>>) =>
      api.post(`/reports`, queryParams),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  updateReport: {
    request: ({ queryParams }: ApiArgs<Omit<Report, 'settings'>>) =>
      api.put(`/reports/${queryParams.id}`, { name: queryParams.name }),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  deleteReport: {
    request: ({ queryParams }: ApiArgs<Pick<Report, 'id'>>) =>
      api.delete(`/reports/${queryParams.id}`),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  loadReport: {
    request: ({ queryParams }: ApiArgs<Pick<Report, 'id'>>) =>
      api.get(`/reports/${queryParams.id}`),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  getReports: {
    request: () => api.get('/reports'),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  },
  fetchBillingUrl: {
    request: (args: BillingPortalParams) =>
      api.post(`/reports/billing-portal`, args),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const ReportModel = new Generator('reportModel');
export default ReportModel.createModel({ actionCreators });
