import Generator from 'data/models/generator';
import { identity } from 'lodash';
import { ApiArgs } from 'types/common';
import { ProjectStatsParam } from 'types/params';
import { api } from 'utils/api-client';
import { fetchProjectStatsMock } from '../mocks';

const actionCreators = {
  fetchProjectStats: {
    request: ({
      queryParams,
      hasPermission,
      config
    }: ApiArgs<ProjectStatsParam>) =>
      hasPermission
        ? api.get(
            `/reports/project-breakdown/project-stats`,
            queryParams,
            config
          )
        : fetchProjectStatsMock(queryParams.report || 'sales_summary'),
    reduce: {
      initial: identity,
      success: identity,
      failure: identity
    }
  }
};

const ProjectBreakdownGenerator = new Generator('projectBreakdownModel');
export default ProjectBreakdownGenerator.createModel({ actionCreators });
