/*
|-------------------------------------------------------------------------------
| App Routes
|-------------------------------------------------------------------------------
|
| The main routes for the App should be centralised, configured & exposed here.
|
| Documentation for configuring routes can be found in the `react-whereabouts`
| readme (https://git.io/vNyTN).
|
*/

import React from 'react';

export default {
  APP: {
    HOME: {
      config: {
        path: '/',
        exact: true,
        redirect: {
          path: '/app/projects/current'
        }
      }
    },

    PROJECTS_CURRENT: {
      config: {
        path: '/app/projects/current',
        Component: require('view/screens/projects/current').default
      },

      NEW_PROJECT: {
        config: {
          path: '/app/projects/current/new',
          Component: require('view/modals/project-new').default
        }
      },

      EXAMPLE: {
        config: {
          path: '/app/projects/current/example',
          Component: require('view/modals/example').default
        }
      }
    },

    PROJECTS_ARCHIVED: {
      config: {
        path: '/app/projects/archived',
        Component: require('view/screens/projects/archived').default
      }
    },

    PROJECTS_TRASHED: {
      config: {
        path: '/app/projects/trashed',
        Component: require('view/screens/projects/trashed').default
      }
    },

    PROJECT: {
      config: {
        path: '/app/project/:projectId',
        exact: true,
        Component: require('view/screens/projects/view').default
      }
    },

    WORKSHEET: {
      config: {
        path: '/app/project/:projectId/worksheet/:worksheetId',
        Component: require('view/screens/projects/worksheet').default
      }
    },

    PERIODS: {
      config: {
        path: '/app/periods',
        exact: true,
        Component: require('view/screens/periods/periods').default
      }
    },

    PERIOD_VIEW: {
      config: {
        path: '/app/periods/:periodId',
        Component: require('view/screens/periods/view').default
      }
    },

    // View region
    REGION_PROJECTS: {
      config: {
        path: '/app/sub-region/:regionId',
        Component: require('view/screens/sub-regions/view').default
      },

      // New region project modal
      NEW_REGION_PROJECT: {
        config: {
          path: '/app/sub-region/:regionId/new',
          Component: require('view/modals/sub-region-projects').default
        }
      }
    },

    // Region list
    REGIONS: {
      config: {
        path: '/app/sub-regions',
        Component: require('view/screens/sub-regions/sub-regions').default
      },

      // Region modal
      NEW_REGION: {
        config: {
          path: '/app/sub-regions/new',
          Component: require('view/modals/sub-region').default
        }
      }
    },

    REPORT: {
      config: {
        path: '/app/report',
        exact: true,
        Component: require('view/screens/reports/menu').default
      }
    },

    REPORT_NEW: {
      config: {
        path: '/app/report/new',
        exact: true,
        Component: require('view/screens/reports/settings').default
      }
    },

    REPORT_SETTINGS: {
      config: {
        path: '/app/report/settings',
        exact: true,
        Component: require('view/screens/reports/report-settings').default
      }
    },

    REPORT_OVERVIEW: {
      config: {
        path: '/app/report/overview',
        Component: require('view/screens/reports/overview').default
      }
    },

    REPORT_PRICING: {
      config: {
        path: '/app/report/pricing',
        Component: require('view/screens/reports/pricing-comparison').default
      }
    },

    REPORT_BRACKETS: {
      config: {
        path: '/app/report/brackets',
        Component: require('view/screens/reports/size-price-brackets').default
      }
    },

    REPORT_LOT: {
      config: {
        path: '/app/report/availability',
        Component: require('view/screens/reports/lot-availability').default
      }
    },

    REPORT_DETAILED: {
      config: {
        path: '/app/report/detailed',
        Component: require('view/screens/reports/detailed').default
      }
    },

    REPORT_PRICELISTS: {
      config: {
        path: '/app/report/pricelists',
        Component: require('view/screens/reports/price-list').default
      }
    },

    REPORT_DEFINITIONS: {
      config: {
        path: '/app/report/definitions',
        Component: require('view/screens/reports/definitions').default
      }
    },

    REPORT_SETTINGS_OVERVIEW: {
      config: {
        path: '/app/report/:id/overview',
        Component: require('view/screens/reports/overview-settings').default
      }
    },

    REPORT_SETTINGS_PRICING: {
      config: {
        path: '/app/report/:id/pricing',
        Component: require('view/screens/reports/pricing-comparison-settings')
          .default
      }
    },

    REPORT_SETTINGS_BRACKETS: {
      config: {
        path: '/app/report/:id/brackets',
        Component: require('view/screens/reports/size-price-brackets-settings')
          .default
      }
    },

    REPORT_SETTINGS_AVAILABILITY: {
      config: {
        path: '/app/report/:id/availability',
        Component: require('view/screens/reports/lot-availability-settings')
          .default
      }
    },

    REPORT_SETTINGS_DETAILED: {
      config: {
        path: '/app/report/:id/detailed',
        Component: require('view/screens/reports/detailed-settings').default
      }
    },

    REPORT_SETTINGS_PRICELIST: {
      config: {
        path: '/app/report/:id/pricelist',
        Component: require('view/screens/reports/price-list-settings').default
      }
    },

    REPORT_SETTINGS_DEFINITIONS: {
      config: {
        path: '/app/report/:id/definitions',
        Component: require('view/screens/reports/definitions-settings').default
      }
    },

    CHANGE_LOG: {
      config: {
        path: '/app/changes',
        Component: require('view/screens/changes').default
      }
    },

    ADMIN: {
      config: {
        path: '/app/admin',
        Component: () => <p>Admin</p>
      }
    }
  }
};
