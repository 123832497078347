import React from 'react';
import { CustomLayer } from '@nivo/line';
import { COLORS } from 'src/features/reports/theme';

// X axis which has a custom color
const SolidXAxis: CustomLayer = ({ innerWidth, innerHeight }) => {
  return (
    <line
      stroke={COLORS.GREY['900']}
      strokeWidth={1}
      x1={0}
      x2={innerWidth}
      y1={innerHeight}
      y2={innerHeight}
    />
  );
};

export default SolidXAxis;
