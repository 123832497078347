import React, { PureComponent } from 'react';
import DownArrow from 'src/assets/down-arrow.svg';
import ClearButton from './clear';

class ActionButton extends PureComponent {
  render() {
    const { onClick, children } = this.props;
    return (
      <ClearButton
        green
        iconRight={<DownArrow style={{ marginLeft: '0.8rem' }} />}
        onClick={onClick}
      >
        {children}
      </ClearButton>
    );
  }
}

export default ActionButton;
