import React from 'react';
import Box from '@rexlabs/box';
import LoadingSpinner from '@rexlabs/loading-spinner';
import { StyleSheet } from '@rexlabs/styling';

const style = StyleSheet({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 4
});

interface LoadingProps {
  isLoading: boolean;
}

const Loading = ({ isLoading }: LoadingProps) => {
  if (!isLoading) return null;
  return (
    <Box style={style}>
      <LoadingSpinner />
    </Box>
  );
};

export default Loading;
