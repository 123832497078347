import React, { PureComponent } from 'react';
import { PrimaryButton, TextButton } from 'view/components/button';
import { Modal, ButtonBar } from 'view/components/modal';
import { StyleSheet, styled } from '@rexlabs/styling';
import { PADDINGS, COLORS } from 'src/theme';
import Box from '@rexlabs/box';
import { Heading } from '../components/text';
import periods from 'data/models/entities/periods';
import worksheets from 'data/models/entities/worksheets';
import sessionModel from 'data/models/custom/session';
import { withModel } from '@rexlabs/model-generator';
import _ from 'lodash';
import MissingWorksheetListHeader from '../components/list/missing-worksheet-list-header';
import List from '@rexlabs/list';
import MissingWorksheetListItem from '../components/list/missing-worksheet-list-item';
import withError from 'view/containers/with-error';
import LoadingSpinner from '@rexlabs/loading-spinner';

const defaultStyles = StyleSheet({
  container: {
    margin: PADDINGS.XS
  }
});

@withError.withPropName('errorModal')
@withModel(sessionModel)
@withModel(periods)
@withModel(worksheets)
@styled(defaultStyles)
class AddMissingWorksheetsModal extends PureComponent {
  state = {
    missingProjects: [],
    checked: {},
    loading: true
  };

  componentWillMount() {
    const { periods, period } = this.props;
    periods
      .fetchMissing({
        id: period.id
      })
      .then((missingProjects) => {
        this.setState({
          missingProjects: _.get(missingProjects, 'data'),
          loading: false
        });
      });
  }

  createMissing(state) {
    const { worksheets, onSubmit, closeModal } = this.props;
    _.forEach(state.checked, (value, key) => {
      if (value) {
        worksheets
          .createItem({
            data: {
              account_id: this.props.session.accountId,
              project_id: key,
              period: new Date(this.props.period.end_date).getTime() / 1000
            }
          })
          .then(() => {
            onSubmit();
            closeModal();
          })
          .catch((e) => this.props.errorModal.open(e));
      }
    });
  }

  render() {
    const {
      styles: s,
      closeModal,
      errorModal: { Error }
    } = this.props;
    const { loading } = this.state;

    return (
      <Modal>
        <Box {...s('container')}>
          <Heading>Add Missing Worksheets</Heading>
        </Box>
        {!loading && (
          <List
            isLoading={this.state.loading}
            items={this.state.missingProjects}
            Header={() => (
              <MissingWorksheetListHeader
                checked={
                  _.filter(this.state.checked, (value) => value).length ===
                  this.state.missingProjects.length
                }
                onSelectAll={() => {
                  if (
                    _.filter(this.state.checked, (value) => value).length ===
                    this.state.missingProjects.length
                  ) {
                    this.setState({
                      checked: {}
                    });
                  } else {
                    const toggledState = this.state.missingProjects.reduce(
                      (obj, item) => {
                        return { ...obj, [item.id]: true };
                      },
                      {}
                    );
                    this.setState({
                      checked: toggledState
                    });
                  }
                }}
              />
            )}
            renderItem={(item) => (
              <MissingWorksheetListItem
                project={item}
                checked={this.state.checked[item.id]}
                toggle={() =>
                  this.setState({
                    checked: {
                      ...this.state.checked,
                      ...{
                        [item.id]: !this.state.checked[item.id]
                      }
                    }
                  })
                }
              />
            )}
          />
        )}
        {loading && (
          <Box flex={1} style={{ justifyContent: 'center' }}>
            <LoadingSpinner colors={[COLORS.BLUE_GREEN]} />
          </Box>
        )}
        <Box {...s('container')}>
          <ButtonBar>
            <TextButton onClick={closeModal}>Cancel</TextButton>
            <PrimaryButton
              green
              onClick={() => this.createMissing(this.state)}
              isDisabled={loading}
            >
              Add Worksheets
            </PrimaryButton>
          </ButtonBar>
        </Box>
        <Error />
      </Modal>
    );
  }
}

export default AddMissingWorksheetsModal;
