/* eslint-disable max-lines */
// Disable due to many calculations
import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef
} from 'react';
import { Canceler } from 'axios';
import { ResponsiveLine } from '@nivo/line';
import { differenceBy } from 'lodash';
import { Grid, Column } from '@rexlabs/grid';
import { withModel } from '@rexlabs/model-generator';
import { StyleSheet, styled, Styles } from '@rexlabs/styling';
import Box from '@rexlabs/box';
import { CancelToken } from '@rexlabs/api-client';

import Panel from 'src/features/reports/components/panel';
import { ToggleButtonGroup } from 'src/features/reports/components/toggle-button';
import {
  LotAreaSelector,
  ProjectSelector
} from 'src/features/reports/components/selector';

import { BONUS, CALCULATION, STATUS } from './filters';
import Tooltip from './tooltip';
import { ReportSettingsContext } from 'src/features/reports/providers/report-settings-provider';
import {
  SolidCirclePoints,
  SolidXAxis,
  DashedLine,
  Line,
  Loading
} from '../graph';

import { COLORS, NIVO_THEME } from 'src/features/reports/theme';
import { abbrNum, getShortMonthWithYear } from 'utils/format';
import withError from 'view/containers/with-error';
import productTrendsModel from 'src/features/reports/models/product-trends';
import { WithErrorModalProps } from 'types/hoc/with-error-modal';
import {
  ALL_PROJECTS,
  NO_PROJECTS,
  TIME_INTERVAL,
  TRENDS_PAGE
} from 'src/features/reports/constants';
import { compose } from 'utils/compose';
import { ProductTrendsModel } from 'types/models/product-trends';
import { LotTypeList, ProductPriceMovementReport } from 'types/graph';
import { LotArea } from '../selector/lot-area-selector';
import { Project } from '../selector/project-selector';
import { Auth0Context } from 'src/auth0-provider';
import InfoTooltip from './info-tooltip';

const styles = StyleSheet({
  gap: {
    gap: '24px'
  }
});

interface Filters {
  calculation: string[];
  status: string[];
  date: string[];
  lotType: LotArea[];
  bonus: string[];
}

interface ProductPriceMovementProps {
  styles: Styles;
  productTrendsModel: ProductTrendsModel;
  errorModal: WithErrorModalProps;
}

const ProductPriceMovement = ({
  styles: s,
  productTrendsModel,
  errorModal: { open: openError }
}: ProductPriceMovementProps) => {
  const { hasPermission } = useContext(Auth0Context);
  const {
    selectedProjects,
    selectedProjectIds,
    period,
    subRegionsPermissions,
    isFirstLoad
  } = useContext(ReportSettingsContext);
  const lotCanceler = useRef<Canceler | null>(null);
  const reportCanceler = useRef<Canceler | null>(null);
  const [filters, setFilters] = useState<Filters>({
    calculation: ['median'],
    status: ['all'],
    date: ['quarterly'],
    bonus: [],
    lotType: []
  });
  const [projects, setProjects] = useState<Array<Project[]>>([
    [ALL_PROJECTS],
    [NO_PROJECTS]
  ]);
  const [lotTypes, setLotTypes] = useState<LotTypeList>([]);
  const [reports, setReports] = useState<ProductPriceMovementReport[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formattedListOfProjects = useMemo(() => {
    return selectedProjects.map((project) => ({
      label: project.title,
      key: project.id
    }));
  }, [selectedProjects]);

  // Get list of projects for the two selectors
  // This will filter them as well so that we can't have same project selected
  const getProjectList = useCallback(
    (index: number) => {
      if (index === 0) {
        return differenceBy(
          [...formattedListOfProjects, ALL_PROJECTS],
          projects[1],
          'key'
        );
      } else {
        return differenceBy(
          [...formattedListOfProjects, NO_PROJECTS],
          projects[0],
          'key'
        );
      }
    },
    [projects, formattedListOfProjects]
  );

  const graphData = useMemo(() => {
    if (!reports.length) return [];
    const filteredReports = reports.filter((report) => !!report);
    if (!filteredReports.length) return [];
    const data = filteredReports.map((report) => {
      const filtered = report[filters.date[0]][filters.status[0]];
      const formatted = Object.keys(filtered).map((key) => {
        let priceKey = 'price';
        if (filters.bonus.length === 1) {
          priceKey = `price_${filters.bonus[0]}`;
        } else if (filters.bonus.length === 2) {
          priceKey = 'price_incentives_buffer';
        }

        const y = filtered[key][filters.calculation[0]][priceKey];
        return {
          x: filters.date.includes('monthly')
            ? getShortMonthWithYear(key)
            : key,
          y
        };
      });
      return formatted;
    });

    return data.map((list, index) => ({
      id: projects[index][0].label,
      data: list
    }));
  }, [
    reports,
    filters.bonus,
    filters.calculation,
    filters.date,
    filters.status,
    projects
  ]);

  useEffect(() => {
    // Do not call api when there is no project selected;
    if ((!selectedProjectIds.length || !period) && !isFirstLoad) return;

    lotCanceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      lotCanceler.current = c;
    });

    productTrendsModel
      .fetchLotTypes({
        queryParams: { project_ids: selectedProjectIds, period },
        hasPermission:
          !isFirstLoad &&
          hasPermission([...subRegionsPermissions, TRENDS_PAGE]),
        config: { cancelToken }
      })
      .then((res) => {
        // Empty when the api call got cancelled
        if (!res.data) return;

        // Set first lot type to be default selected
        const firstLotType = res.data[0];
        setFilters((prev) => ({
          ...prev,
          lotType: [
            {
              key: firstLotType.id,
              label: firstLotType.lot_type,
              noOfLotsSold: firstLotType.number_of_lots_sold
            }
          ]
        }));

        // Store list of lot types
        setLotTypes(res.data);
      })
      .catch(openError);

    return () => {
      lotCanceler.current?.();
    };
  }, [
    selectedProjectIds,
    period,
    openError,
    productTrendsModel,
    hasPermission,
    subRegionsPermissions,
    isFirstLoad
  ]);

  // Reset projects selected
  useEffect(() => {
    if (!selectedProjects.length) return;
    setProjects([[ALL_PROJECTS], [NO_PROJECTS]]);
  }, [selectedProjects]);

  // Get the trend box reports
  useEffect(() => {
    if (
      (!filters.lotType[0] || !selectedProjectIds.length || !period) &&
      !isFirstLoad
    ) {
      return;
    }

    const promises = [];

    const firstProjectIds = projects[0].find(
      (project) => project.key === 'all_projects'
    )
      ? selectedProjectIds
      : (projects[0].map((project) => project.key) as number[]);

    if (!firstProjectIds.length && !isFirstLoad) return;

    reportCanceler.current?.();
    const cancelToken = new CancelToken((c: Canceler) => {
      reportCanceler.current = c;
    });

    promises.push(
      productTrendsModel
        .fetchProductPriceMovement({
          queryParams: {
            project_ids: firstProjectIds as number[],
            period,
            lot_type: filters.lotType[0]?.key
          },
          hasPermission:
            !isFirstLoad &&
            hasPermission([...subRegionsPermissions, TRENDS_PAGE]),
          config: { cancelToken }
        })
        .then((res) => res.data)
    );

    if (!projects[1].find((project) => project.key === 'no_projects')) {
      const secondProjectIds = projects[1].map(
        (project) => project.key
      ) as number[];

      if (!secondProjectIds.length) return;

      promises.push(
        productTrendsModel
          .fetchProductPriceMovement({
            queryParams: {
              project_ids: secondProjectIds,
              period,
              lot_type: filters.lotType[0].key
            },
            hasPermission: hasPermission([
              ...subRegionsPermissions,
              TRENDS_PAGE
            ]),
            config: { cancelToken }
          })
          .then((res) => res.data)
      );
    }

    setIsLoading(true);
    Promise.all(promises)
      .then((reports) => {
        // Empty when the api call got cancelled
        if (reports.filter((report) => report).length === 0) return;

        setReports(reports);
        setIsLoading(false);
      })
      .catch((error) => {
        openError(error);
        setIsLoading(false);
      });

    return () => {
      reportCanceler.current?.();
    };
  }, [
    selectedProjectIds,
    filters.lotType,
    projects,
    period,
    productTrendsModel,
    openError,
    hasPermission,
    subRegionsPermissions,
    isFirstLoad
  ]);

  return (
    <Panel
      title="Product Price Movement"
      tooltip={{
        title: 'Product Price Movement',
        description: <InfoTooltip />
      }}
    >
      <Box p="12px 0">
        <Loading isLoading={isLoading} />
        <Box {...s('gap')} flexDirection="row" justifyContent="center">
          <Grid columns={4} style={{ width: '100%' }}>
            <Column width={1}>
              <ToggleButtonGroup
                value={filters.calculation}
                group={CALCULATION}
                onToggle={(key: string) =>
                  setFilters((prev) => ({ ...prev, calculation: [key] }))
                }
              />
            </Column>
            <Column width={1}>
              <ToggleButtonGroup
                value={filters.status}
                group={STATUS}
                onToggle={(key: string) =>
                  setFilters((prev) => ({ ...prev, status: [key] }))
                }
              />
            </Column>
            <Column width={1}>
              <ToggleButtonGroup
                collapsed
                value={filters.bonus}
                group={BONUS}
                onToggle={(key: string) =>
                  setFilters((prev) => {
                    if (prev.bonus.includes(key)) {
                      return {
                        ...prev,
                        bonus: prev.bonus.filter((selected) => selected !== key)
                      };
                    }

                    return {
                      ...prev,
                      bonus: [...prev.bonus, key]
                    };
                  })
                }
              />
            </Column>
            <Column width={1}>
              <ToggleButtonGroup
                value={filters.date}
                group={TIME_INTERVAL}
                onToggle={(key: string) =>
                  setFilters((prev) => ({ ...prev, date: [key] }))
                }
              />
            </Column>
          </Grid>

          <LotAreaSelector
            lotAreas={lotTypes.map((lot) => ({
              key: lot.id,
              label: lot.lot_type,
              noOfLotsSold: lot.number_of_lots_sold
            }))}
            selectedLotAreas={filters.lotType}
            onChange={(selectedLotAreas: LotArea[]) =>
              setFilters((prev) => ({ ...prev, lotType: selectedLotAreas }))
            }
          />
        </Box>
        <Box width="100%" height="615px">
          <ResponsiveLine
            data={graphData}
            theme={NIVO_THEME}
            colors={[COLORS.BLUE.PRIMARY, COLORS.PINK.LIGHT]}
            margin={{ top: 50, right: 20, bottom: 70, left: 70 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto'
            }}
            enableSlices="x"
            enableGridX={false}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 9,
              legend: filters.date.includes('quarterly') ? 'QUARTER' : 'MONTH',
              tickPadding: 0,
              legendOffset: 45,
              legendPosition: 'middle'
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 12,
              legend: `${
                filters.calculation.includes(CALCULATION[0].key)
                  ? 'MEDIAN'
                  : 'AVERAGE'
              } PRICE`,
              legendOffset: -65,
              legendPosition: 'middle',
              format: (value) => `$${abbrNum(value)}`.toUpperCase()
            }}
            sliceTooltip={({ slice }) => {
              return (
                <Tooltip
                  date={slice.points[0].data.x}
                  projects={slice.points.map((point) => ({
                    name: point.serieId,
                    count: point.data.y,
                    serieColor: point.serieColor
                  }))}
                />
              );
            }}
            layers={[
              // Default layers
              'grid',
              'markers',
              'axes',
              'areas',
              'crosshair',
              'slices',
              'mesh',
              'legends',
              // Custom layers
              DashedLine,
              Line,
              SolidXAxis,
              SolidCirclePoints
            ]}
          />
        </Box>
        <Box
          {...s('gap')}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <ProjectSelector
            color={COLORS.BLUE.PRIMARY}
            projects={getProjectList(0)}
            selectedProjects={projects[0]}
            onChange={(selectedProjects: Project[]) =>
              setProjects((prev) => [selectedProjects, prev[1]])
            }
          />
          <ProjectSelector
            color={COLORS.PINK.LIGHT}
            projects={getProjectList(1)}
            selectedProjects={projects[1]}
            onChange={(selectedProjects: Project[]) =>
              setProjects((prev) => [prev[0], selectedProjects])
            }
          />
        </Box>
      </Box>
    </Panel>
  );
};

export default compose(
  withError.withPropName('errorModal'),
  styled(styles),
  withModel(productTrendsModel)
)(ProductPriceMovement);
